import * as CONSTANTS from './constants'
import {
    ErrorViewModel,
    PEXAWorkspaceCreationRequestWithActionstepResponse,
    WorkspaceCreationRequest,
    CreatePexaWorkspaceCommand,
    ActionstepMatterInfo,
    CreatePexaWorkspaceResponse,
    LandTitleReferenceAndJurisdiction,
    LandTitleReferenceVerificationResponseType,
    WorkspaceSummaryResponseType,
    RetrieveWorkspaceSummaryParameters,
    CreateWorkspaceInvitationResponseType,
    CreateWorkspaceInvitationRequestType,
    RetrieveSettlementAvailabilityParams,
    RetrieveSettlementAvailabilityResponseType,
    PexaWorkspaceInfo,
    UpdateSettlementDateTimeActionstepCommand,
    UpdateSettlementDateAndTimeRequestCommand,
    OrgKeyAndMatter
} from 'utils/wcaApiTypes'

import {
    GetDataFromActionstep,
    GetDataFromActionstepSuccess,
    ActionFailed,
    SetPexaWorkspaceCreation,
    SendDataToPexa,
    SendDataToPexaSuccess,
    ClearPexaState,
    WorkspaceCreationRequestWithMatterInfo,
    SavePexaFormData,
    ClearPexaFormData,
    ValidateLandTitle,
    ValidateLandTitleSuccess,
    GetPexaWorkspaceSummary,
    GetPexaWorkspaceSummarySuccess,
    GetPexaWorkspaceSummaryFailed,
    CreateInvitation,
    CreateInvitationSuccess,
    GetAvailableSettlementTimes,
    GetAvailableSettlementTimesSuccess,
    GetAvailableSettlementTimesFailed,
    GetPexaWorkspaceInformationFromKonekta,
    GetPexaWorkspaceInformationFromKonektaSuccess,
    GetPexaWorkspaceInformationFromKonektaFailed,
    MaintainSettlementDateTime,
    MaintainSettlementDateTimeSuccess,
    MaintainSettlementDateTimeFailed,
    UpdateSettlementDateTimeActionstep,
    UpdateSettlementDateTimeActionstepSuccess,
    UpdateSettlementDateTimeActionstepFailed,
    GetInvitationUri,
    GetInvitationUriSuccess,
    GetInvitationUriFailed,
    SetSettlementDateTimeData,
    ClearStatesForSettlementDateTime,
    ClearPexaStateAndError,
    CreatePexaKey,
    CreatePexaKeySuccess,
    CreatePexaKeyFailed,
    GetPexaKeySuccess,
    GetPexaKeyFailed,
    GetPexaKey,
    GetPexaKeyIviteInformationFromKonekta,
    GetPexaKeyIviteInformationFromKonektaSuccess,
    GetPexaKeyIviteInformationFromKonektaFailed,
    CopyLodgedDocumentsToActionStep,
    CopyLodgedDocumentsToActionStepSuccess,
    CopyLodgedDocumentsToActionStepFailed,
    SavePexaAudit,
    SavePexaAuditSuccess,
    SavePexaAuditFailed,
    GetActionstepPropertyDetails,
    GetActionstepPropertyDetailsSuccess,
    GetActionstepPropertyDetailsFailed,
    UpdateDealingNumberActionstep,
    UpdateDealingNumberActionstepSuccess,
    UpdateDealingNumberActionstepFailed,
    ClearPexaWorkspaceDealingNumberData,
    GetFSSLineItems,
    GetFSSLineItemsSuccess,
    GetFSSLineItemsFailed,
    SetFSSLineItems,
    SetFSSLineItemsSuccess,
    SetFSSLineItemsFailed,
    ClearFSSLineItemsState

} from './actionTypes'
import {
    CreatePexaKeyInviteCommandParams,
    PexaWorkspaceIdAndRole,
    UpdateSettlementDateTimeData,
    PexaKeyWorkspacePartyIdAndRole,
    CreatePexaKeyInviteResponse,
    CopyDocumentsToActionStepResponse,
    SavePexaAuditParams,
    DealingNumberViewModel,
    UpdateDealingNumberActionstepCommand
} from '../../../utils/newWcaApiTypes'
import { ActionstepFinancialLineItems, UpdateFSSLineItems } from '../pexaApiTypes'

export function getDataFromActionstep(matterInfo: ActionstepMatterInfo): GetDataFromActionstep {
    return {
        type: CONSTANTS.GET_DATA_FROM_ACTIONSTEP_REQUESTED,
        matterInfo
    }
}

export function getDataFromActionstepSuccess(data: PEXAWorkspaceCreationRequestWithActionstepResponse): GetDataFromActionstepSuccess {
    return {
        type: CONSTANTS.GET_DATA_FROM_ACTIONSTEP_SUCCESS,
        data
    }
}

export function getDataFromActionstepFailed(error: ErrorViewModel): ActionFailed {
    return {
        type: CONSTANTS.GET_DATA_FROM_ACTIONSTEP_FAILED,
        error
    }
}

export function setPexaWorkspaceCreation(data: WorkspaceCreationRequest): SetPexaWorkspaceCreation {
    return {
        type: CONSTANTS.SET_PEXA_WORKSPACE_CREATION_REQUEST,
        data
    }
}

export function validateLandTitle(params: LandTitleReferenceAndJurisdiction): ValidateLandTitle {
    return {
        type: CONSTANTS.VALIDATE_LAND_TITLE_REQUESTED,
        params
    }
}

export function validateLandTitleSuccess(data: LandTitleReferenceVerificationResponseType): ValidateLandTitleSuccess {
    return {
        type: CONSTANTS.VALIDATE_LAND_TITLE_SUCCESS,
        data
    }
}

export function validateLandTitleFailed(error: ErrorViewModel): ActionFailed {
    return {
        type: CONSTANTS.VALIDATE_LAND_TITLE_FAILED,
        error
    }
}

export function getPexaWorkspaceSummary(params: RetrieveWorkspaceSummaryParameters): GetPexaWorkspaceSummary {
    return {
        type: CONSTANTS.GET_PEXA_WORKSPACE_SUMMARY_REQUESTED,
        params
    }
}

export function getPexaWorkspaceSummarySuccess(workspaceId: string, data: WorkspaceSummaryResponseType): GetPexaWorkspaceSummarySuccess {
    return {
        type: CONSTANTS.GET_PEXA_WORKSPACE_SUMMARY_SUCCESS,
        workspaceId,
        data
    }
}

export function getPexaWorkspaceSummaryFailed(workspaceId: string, error: ErrorViewModel): GetPexaWorkspaceSummaryFailed {
    return {
        type: CONSTANTS.GET_PEXA_WORKSPACE_SUMMARY_FAILED,
        workspaceId,
        error
    }
}

export function getAvailableSettlementTimes(params: RetrieveSettlementAvailabilityParams): GetAvailableSettlementTimes {
    return {
        type: CONSTANTS.GET_AVAILABLE_SETTLEMENT_TIMES_REQUESETD,
        params
    }
}

export function getAvailableSettlementTimesSuccess(jurisdiction: string, settlementDate: string, data: RetrieveSettlementAvailabilityResponseType): GetAvailableSettlementTimesSuccess {
    return {
        type: CONSTANTS.GET_AVAILABLE_SETTLEMENT_TIMES_SUCCESS,
        data,
        jurisdiction,
        settlementDate
    }
}

export function getAvailableSettlementTimesFailed(jurisdiction: string, settlementDate: string, error: ErrorViewModel): GetAvailableSettlementTimesFailed {
    return {
        type: CONSTANTS.GET_AVAILABLE_SETTLEMENT_TIMES_FAILED,
        error,
        jurisdiction,
        settlementDate
    }
}

export function sendDataToPexa(params: CreatePexaWorkspaceCommand): SendDataToPexa {
    return {
        type: CONSTANTS.SEND_DATA_TO_PEXA_REQUESTED,
        params
    }
}

export function sendDataToPexaSuccess(data: CreatePexaWorkspaceResponse): SendDataToPexaSuccess {
    return {
        type: CONSTANTS.SEND_DATA_TO_PEXA_SUCCESS,
        data
    }
}

export function sendDataToPexaFailed(error: ErrorViewModel): ActionFailed {
    return {
        type: CONSTANTS.SEND_DATA_TO_PEXA_FAILED,
        error
    }
}

export function createInvitation(params: CreateWorkspaceInvitationRequestType[]): CreateInvitation {
    return {
        type: CONSTANTS.CREATE_INVITATION_REQUESTED,
        params
    }
}

export function createInvitationSuccess(data: CreateWorkspaceInvitationResponseType[]): CreateInvitationSuccess {
    return {
        type: CONSTANTS.CREATE_INVITATION_SUCCESS,
        data
    }
}

export function createInvitationFailed(error: ErrorViewModel): ActionFailed {
    return {
        type: CONSTANTS.CREATE_INVITATION_FAILED,
        error
    }
}

export function savePexaFormData(pexaFormData: WorkspaceCreationRequestWithMatterInfo): SavePexaFormData {
    return {
        type: CONSTANTS.SAVE_PEXA_FORM_DATA,
        pexaFormData
    }
}

export function clearPexaFormData(): ClearPexaFormData {
    return {
        type: CONSTANTS.CLEAR_PEXA_FORM_DATA
    }
}

export function clearPexaState(): ClearPexaState {
    return {
        type: CONSTANTS.CLEAR_PEXA_STATE
    }
}

export function clearPexaStateAndError(): ClearPexaStateAndError {
    return {
        type: CONSTANTS.CLEAR_PEXA_STATE_AND_ERROR
    }
}

export function getPexaWorkspaceInformationFromKonekta(matterInfo: ActionstepMatterInfo): GetPexaWorkspaceInformationFromKonekta {
    return {
        type: CONSTANTS.GET_PEXA_WORKSPACE_INFORMATION_FROM_KONEKTA_REQUESTED,
        matterInfo
    }
}

export function getPexaWorkspaceInformationFromKonektaSuccess(data: PexaWorkspaceInfo): GetPexaWorkspaceInformationFromKonektaSuccess {
    return {
        type: CONSTANTS.GET_PEXA_WORKSPACE_INFORMATION_FROM_KONEKTA_SUCCESS,
        data
    }
}

export function getPexaWorkspaceInformationFromKonektaFailed(error: ErrorViewModel): GetPexaWorkspaceInformationFromKonektaFailed {
    return {
        type: CONSTANTS.GET_PEXA_WORKSPACE_INFORMATION_FROM_KONEKTA_FAILED,
        error
    }
}

export function maintainSettlementDateTime(params: UpdateSettlementDateAndTimeRequestCommand): MaintainSettlementDateTime {
    return {
        type: CONSTANTS.MAINTAIN_SETTLEMENT_DATE_TIME_REQUESTED,
        params
    }
}

export function maintainSettlementDateTimeSuccess(data: boolean): MaintainSettlementDateTimeSuccess {
    return {
        type: CONSTANTS.MAINTAIN_SETTLEMENT_DATE_TIME_SUCCESS,
        data
    }
}

export function maintainSettlementDateTimeFailed(error: ErrorViewModel): MaintainSettlementDateTimeFailed {
    return {
        type: CONSTANTS.MAINTAIN_SETTLEMENT_DATE_TIME_FAILED,
        error
    }
}


export function updateSettlementDateTimeActionstep(params: UpdateSettlementDateTimeActionstepCommand): UpdateSettlementDateTimeActionstep {
    return {
        type: CONSTANTS.UPDATE_SETTLEMENT_DATE_TIME_ACTIONSTEP_REQUESTED,
        params
    }
}

export function updateSettlementDateTimeActionstepSuccess(data: boolean): UpdateSettlementDateTimeActionstepSuccess {
    return {
        type: CONSTANTS.UPDATE_SETTLEMENT_DATE_TIME_ACTIONSTEP_SUCCESS,
        data
    }
}

export function updateSettlementDateTimeActionstepFailed(error: ErrorViewModel): UpdateSettlementDateTimeActionstepFailed {
    return {
        type: CONSTANTS.UPDATE_SETTLEMENT_DATE_TIME_ACTIONSTEP_FAILED,
        error
    }
}

export function getInvitationUri(params: PexaWorkspaceIdAndRole): GetInvitationUri {
    return {
        type: CONSTANTS.GET_INVITATION_URI_REQUESTED,
        params
    }
}

export function getInvitationUriSuccess(data: string): GetInvitationUriSuccess {
    return {
        type: CONSTANTS.GET_INVITATION_URI_SUCCESS,
        data
    }
}

export function getInvitationUriFailed(error: ErrorViewModel): GetInvitationUriFailed {
    return {
        type: CONSTANTS.GET_INVITATION_URI_FAILED,
        error
    }
}

export function setSettlementDateTimeData(data: UpdateSettlementDateTimeData): SetSettlementDateTimeData {
    return {
        type: CONSTANTS.SET_SETTLEMENT_DATE_TIME_DATA_REQUEST,
        data
    }
}

export function clearStatesForSettlementDateTime(): ClearStatesForSettlementDateTime {
    return {
        type: CONSTANTS.CLEAR_STATES_FOR_SETTLEMENT_DATE_TIME,
    }
}
export function createPexaKey(params: CreatePexaKeyInviteCommandParams): CreatePexaKey {
    return {
        type: CONSTANTS.CREATE_PEXA_KEY_REQUESTED,
        params
    }
}

export function createPexaKeySuccess(data: CreatePexaKeyInviteResponse): CreatePexaKeySuccess {
    return {
        type: CONSTANTS.CREATE_PEXA_KEY_SUCCESS,
        data
    }
}

export function createPexaKeyFailed(error: ErrorViewModel): CreatePexaKeyFailed {
    return {
        type: CONSTANTS.CREATE_PEXA_KEY_FAILED,
        error
    }
}

export function getPexaKey(params: PexaKeyWorkspacePartyIdAndRole): GetPexaKey {
    return {
        type: CONSTANTS.GET_PEXA_KEY_REQUESTED,
        params
    }
}

export function getPexaKeySuccess(data: string): GetPexaKeySuccess {
    return {
        type: CONSTANTS.GET_PEXA_KEY_SUCCESS,
        data
    }
}

export function getPexaKeyFailed(error: ErrorViewModel): GetPexaKeyFailed {
    return {
        type: CONSTANTS.GET_PEXA_KEY_FAILED,
        error
    }
}

export function getPexaKeyIviteInformationFromKonekta(matterInfo: ActionstepMatterInfo): GetPexaKeyIviteInformationFromKonekta {
    return {
        type: CONSTANTS.GET_PEXA_KEY_INVITE_FROM_KONEKTA_REQUESTED,
        matterInfo
    }
}

export function getPexaKeyIviteInformationFromKonektaSuccess(data: string): GetPexaKeyIviteInformationFromKonektaSuccess {
    return {
        type: CONSTANTS.GET_PEXA_KEY_INVITE_FROM_KONEKTA_SUCCESS,
        data
    }
}

export function getPexaKeyIviteInformationFromKonektaFailed(error: ErrorViewModel): GetPexaKeyIviteInformationFromKonektaFailed {
    return {
        type: CONSTANTS.GET_PEXA_KEY_INVITE_FROM_KONEKTA_FAILED,
        error
    }
}

export function copyLodgedDocumentsToActionStep(matterId: number, orgKey: string,): CopyLodgedDocumentsToActionStep {
    return {
        type: CONSTANTS.COPY_LODGED_DOCUMENTS_TO_ACTIONSTEP_REQUESTED,
        matterId,
        orgKey
    }
}

export function copyLodgedDocumentsToActionStepSuccess(data: CopyDocumentsToActionStepResponse): CopyLodgedDocumentsToActionStepSuccess {
    return {
        type: CONSTANTS.COPY_LODGED_DOCUMENTS_TO_ACTIONSTEP_SUCCESS,
        data
    }
}

export function copyLodgedDocumentsToActionStepFailed(error: ErrorViewModel): CopyLodgedDocumentsToActionStepFailed {
    return {
        type: CONSTANTS.COPY_LODGED_DOCUMENTS_TO_ACTIONSTEP_FAILED,
        error
    }
}

export function savePexaAudit(data: SavePexaAuditParams): SavePexaAudit {
    return {
        type: CONSTANTS.SAVE_PEXA_AUDIT_REQUESTED,
        data
    }
}
export function savePexaAuditSuccess(data: boolean): SavePexaAuditSuccess {
    return {
        type: CONSTANTS.SAVE_PEXA_AUDIT_SUCCESS,
        data
    }
}
export function savePexaAuditFailed(error: ErrorViewModel): SavePexaAuditFailed {
    return {
        type: CONSTANTS.SAVE_PEXA_AUDIT_FAILED,
        error
    }
}

export function getActionstepPropertyDetails(params: OrgKeyAndMatter): GetActionstepPropertyDetails {
    return {
        type: CONSTANTS.GET_ACTIONSTEP_PROPERTY_DETAILS_REQUESTED,
        params
    }
}

export function getActionstepPropertyDetailsSuccess(data: DealingNumberViewModel[]): GetActionstepPropertyDetailsSuccess {
    return {
        type: CONSTANTS.GET_ACTIONSTEP_PROPERTY_DETAILS_SUCCESS,
        data
    }
}

export function getActionstepPropertyDetailsFailed(error: ErrorViewModel): GetActionstepPropertyDetailsFailed {
    return {
        type: CONSTANTS.GET_ACTIONSTEP_PROPERTY_DETAILS_FAILED,
        error
    }
}

export function updateDealingNumberActionstep(params: UpdateDealingNumberActionstepCommand): UpdateDealingNumberActionstep {
    return {
        type: CONSTANTS.UPDATE_DEALING_NUMBER_ACTIONSTEP_REQUESTED,
        params
    }
}

export function updateDealingNumberActionstepSuccess(data: string): UpdateDealingNumberActionstepSuccess {
    return {
        type: CONSTANTS.UPDATE_DEALING_NUMBER_ACTIONSTEP_SUCCESS,
        data
    }
}

export function updateDealingNumberActionstepFailed(error: ErrorViewModel): UpdateDealingNumberActionstepFailed {
    return {
        type: CONSTANTS.UPDATE_DEALING_NUMBER_ACTIONSTEP_FAILED,
        error
    }
}

export function clearPexaWorkspaceDealingNumberData(): ClearPexaWorkspaceDealingNumberData {
    return {
        type: CONSTANTS.CLEAR_PEXA_WORKSPACE_DEALING_NUMBER_INFO_STATE
    }
}
export function getFSSLineItems(matterId: number, orgKey: string): GetFSSLineItems {
    return {
        type: CONSTANTS.GET_FSS_LINEITEMS_REQUESTED,
        matterId,
        orgKey
    }
}
export function getFSSLineItemsSuccess(data: ActionstepFinancialLineItems[]): GetFSSLineItemsSuccess {
    return {
        type: CONSTANTS.GET_FSS_LINEITEMS_SUCCESS,
        data
    }
}
export function getFSSLineItemsFailed(error: ErrorViewModel): GetFSSLineItemsFailed {
    return {
        type: CONSTANTS.GET_FSS_LINEITEMS_FAILED,
        error
    }
}
export function setFSSLineItems(fssLineItems: UpdateFSSLineItems): SetFSSLineItems {
    return {
        type: CONSTANTS.SET_FSS_LINEITEMS_REQUESTED,
        fssLineItems
    }
}
export function setFSSLineItemsSuccess(data: string): SetFSSLineItemsSuccess {
    return {
        type: CONSTANTS.SET_FSS_LINEITEMS_SUCCESS,
        data
    }
}
export function setFSSLineItemsFailed(error: ErrorViewModel): SetFSSLineItemsFailed {
    return {
        type: CONSTANTS.SET_FSS_LINEITEMS_FAILED,
        error
    }
}
export function clearFSSLineItemsState(): ClearFSSLineItemsState {
    return {
        type: CONSTANTS.CLEAR_FSS_LINEITEMS_STATE,
    }
}