import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Button, InputLabel, Link, RadioGroup, TextField, FormControlLabel, Radio } from '@material-ui/core';
import './PexaKeyInvite.css';
import { SubwayNavNodeState } from 'components/SubwayNav';
import { MessageBar, MessageBarType } from 'office-ui-fabric-react'
import { AppState } from 'app.types';
import 'containers/pexa/steps/components/PexaKeyInvite/PexaKeyInviteForm';
import { createPexaKey, getPexaWorkspaceSummary } from 'containers/pexa/redux/actions';
import { CreatePexaKeyInviteCommandParams, CreatePexaKeyInviteResponse } from 'utils/newWcaApiTypes';
import * as CONSTANTS from 'containers/pexa/redux/constants';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import { PartyDetailsType } from 'utils/wcaApiTypes';

interface IAppProps {
    onChangeStep: (newState: SubwayNavNodeState, newStep?: number) => void;
}

type AppProps = IAppProps & ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

type AppStates = {
    isLoading: boolean;
    ispexaKeyCreated: CreatePexaKeyInviteResponse | undefined;
    matterId: number;
    actionstepOrg: string;
    redirect: string;
    workspaceId: string,
    contactGivenName: string,
    contactFamilyName: string,
    phoneNumber: string,
    emailAddress: string,
    partyId: string,
    partyType: string,
    selectedIndex: number,
    isPartySelectionChange: boolean,
    controlValidation: {
        phoneNumberValid: boolean,
        emailAddressValid: boolean,
        givenNameValid: boolean,
        lastNameValid: boolean
    }
}
export class PexaKeyInvite extends React.Component<AppProps, AppStates> {
    readonly IDENTITY_TYPE_INDIVIDUAL = "Individual";
    constructor(props: Readonly<AppProps>) {
        super(props);
        const actionstepContext = props.actionstepContext!;
        this.state = {
            isLoading: false,
            ispexaKeyCreated: undefined,
            matterId: actionstepContext.matterContext!.id,
            actionstepOrg: actionstepContext.orgKey,
            redirect: "",
            workspaceId: "",
            contactGivenName: "",
            contactFamilyName: "",
            phoneNumber: "",
            emailAddress: "",
            partyId: "",
            selectedIndex: 0,
            partyType: "",
            isPartySelectionChange: false,
            controlValidation: {
                phoneNumberValid: false,
                emailAddressValid: false,
                givenNameValid: false,
                lastNameValid: false
            }
        }
    }

    public componentDidMount(): void {
        this.initializeStates();
    }

    private initializeStates(): void {
        const { pexaWorkspaceCreationData } = this.props;
        let workspaceId: string;
        workspaceId = pexaWorkspaceCreationData.existingPexaWorkspace?.pexaWorkspaceId ?? "";
        this.setState((prevState) => {
            return {
                ...prevState,
                workspaceId: workspaceId
            }
        })
    }

    private isFormValid(): boolean {
        const { controlValidation, partyType } = this.state;
        let formValid = controlValidation.emailAddressValid && controlValidation.phoneNumberValid;
        if (partyType !== this.IDENTITY_TYPE_INDIVIDUAL) {
            return (
                formValid &&
                controlValidation.givenNameValid &&
                controlValidation.lastNameValid)
        }
        return formValid;
    }


    private validateControl(key: string): boolean {
        const { phoneNumber, emailAddress, contactGivenName, contactFamilyName, partyType } = this.state;
        let controlValid = false;
        if (key === 'phoneNumber') {
            controlValid = /^(\+61)\d{9}/.test(phoneNumber);
            this.setState((prevState) => {
                return {
                    ...prevState,
                    controlValidation: {
                        ...prevState.controlValidation,
                        phoneNumberValid: controlValid
                    }
                }
            })
        }
        if (key === 'emailAddress') {
            controlValid = /\S+@\S+\.\S+/.test(emailAddress);
            this.setState((prevState) => {
                return {
                    ...prevState,
                    controlValidation: {
                        ...prevState.controlValidation,
                        emailAddressValid: controlValid
                    }
                }
            })
        }
        if (key === 'contactgivenname') {
            controlValid = contactGivenName.length > 0;
            if (partyType == this.IDENTITY_TYPE_INDIVIDUAL) {
                controlValid = true;
            }
            this.setState((prevState) => {
                return {
                    ...prevState,
                    controlValidation: {
                        ...prevState.controlValidation,
                        givenNameValid: controlValid
                    }
                }
            })
        }
        if (key === 'contactfamilyname') {
            controlValid = contactFamilyName.length > 0;
            if (partyType === this.IDENTITY_TYPE_INDIVIDUAL) {
                controlValid = true;
            }
            this.setState((prevState) => {
                return {
                    ...prevState,
                    controlValidation: {
                        ...prevState.controlValidation,
                        lastNameValid: controlValid
                    }
                }
            })
        }
        return controlValid;
    }
    private _handleOnChange(newValue: any, key: string, extraKey?: string): void {
        if (key === 'phoneNumber') {
            this.setState((prevState) => {
                return {
                    ...prevState,
                    phoneNumber: newValue
                }
            })
        }
        if (key === 'emailAddress') {
            this.setState((prevState) => {
                return {
                    ...prevState,
                    emailAddress: newValue,
                }
            })
        }
        if (key === 'contactgivenname') {
            this.setState((prevState) => {
                return {
                    ...prevState,
                    contactGivenName: newValue
                }
            })
        }
        if (key === 'contactfamilyname') {
            this.setState((prevState) => {
                return {
                    ...prevState,
                    contactFamilyName: newValue
                }
            })
        }
    }
    private _handlerCreatePexaKey(): void {

        if (!this.isFormValid()) {
            return;
        }
        const { workspaceSummaryList } = this.props;
        const { workspaceId } = this.state;
        if (workspaceSummaryList[workspaceId] !== undefined && workspaceSummaryList[workspaceId].data !== undefined) {
            let partyDetails = workspaceSummaryList[workspaceId].data?.partyDetails?.filter(x => x.partyId == this.state.partyId)[0];
            let partyType = partyDetails?.partyType ?? "";

            this.props.createPexaKey(new CreatePexaKeyInviteCommandParams({
                workspaceId: workspaceId,
                workspaceRole: partyDetails?.partyRole ?? "",
                actionstepOrg: this.state.actionstepOrg,
                matterId: this.state.matterId,
                partyId: this.state.partyId,
                partyType: partyType,
                partyName: partyDetails?.fullName?.givenName?.[0].value ?? "",
                pexaKeyConsent: "YES",
                email: this.state.emailAddress,
                mobileNumber: this.state.phoneNumber,
                contactGivenName: partyType !== this.IDENTITY_TYPE_INDIVIDUAL ? this.state.contactGivenName : "",
                contactFamilyName: partyType !== this.IDENTITY_TYPE_INDIVIDUAL ? this.state.contactFamilyName : ""
            }));
        }
        this.setState((prevState) => {
            return {
                ...prevState,
                isPartySelectionChange: false
            }
        })
    }
    private _handlerRedirectToHome = () => {
        this.setState((prevState) => {
            return {
                ...prevState,
                redirect: "/matter"
            }
        });
    }
    static getDerivedStateFromProps(nextProps: AppProps, prevState: AppStates): AppStates {
        let nextState = {} as AppStates;
        if (nextProps.gotResponse) {
            switch (nextProps.requestType) {
                case CONSTANTS.CREATE_PEXA_KEY_REQUESTED: {
                    nextState.isLoading = true;
                    nextState.ispexaKeyCreated = undefined;
                    break;
                }
                case CONSTANTS.CREATE_PEXA_KEY_SUCCESS:
                    nextState.isLoading = false;
                    nextState.ispexaKeyCreated = nextProps.ispexaKeyCreated ?? undefined;
                    break;
                case CONSTANTS.CREATE_PEXA_KEY_FAILED:
                    nextState.isLoading = false;
                    nextState.ispexaKeyCreated = nextProps.ispexaKeyCreated ?? undefined;
                    break;
                default:
                    return nextState;
            }
        }
        return nextState;
    }

    private renderParties = () => {
        const { workspaceSummaryList } = this.props;
        const { workspaceId } = this.state;
        return (
            <>
                {workspaceSummaryList[workspaceId] !== undefined && workspaceSummaryList[workspaceId].data !== undefined &&
                    <div>
                        <span className="party-details-heading"><b>Please select a client to send the pexa key invitation.</b></span>
                        <table className="party-details">
                            <tbody>
                                {workspaceSummaryList[workspaceId].data!.partyDetails!.map((partyDetail, index) => {
                                    return (
                                        <tr key={index} className="md-Grid-row">
                                            <td className="md-Grid-col ms-md4">
                                                <RadioGroup
                                                    value={this.state.partyId}
                                                    onChange={() => this._handlerPartyDetails(partyDetail)} >
                                                    <FormControlLabel key={partyDetail.partyId ?? ""}
                                                        label={this.renderPartyLabel(partyDetail)}
                                                        control={<Radio />} value={partyDetail.partyId ?? ""}
                                                        className="  party-details-label" />
                                                </RadioGroup>
                                            </td>
                                        </tr>)
                                })}
                            </tbody>
                        </table>
                    </div>
                }
            </>
        )
    }

    private renderUserDetails(): JSX.Element {
        const { partyType, controlValidation, emailAddress, phoneNumber, contactGivenName, contactFamilyName } = this.state;
        return (
            <>
                {partyType != undefined && partyType != '' ?
                    <>
                        {partyType != this.IDENTITY_TYPE_INDIVIDUAL &&
                            <>
                                <InputLabel>First name</InputLabel>
                                <TextField id="first-name" variant="outlined" margin="normal" fullWidth
                                    required defaultValue={this.state.contactGivenName}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => this._handleOnChange(event.target.value, "contactgivenname")}
                                    onBlur={(event: React.FocusEvent<HTMLInputElement>) => this.validateControl("contactgivenname")}
                                    error={!controlValidation.givenNameValid && contactGivenName !== ''}
                                    helperText={!controlValidation.givenNameValid && contactGivenName !== '' ? 'Invalid First name' : ''} />
                                <InputLabel>Last name</InputLabel>
                                <TextField id="last-name" variant="outlined" margin="normal" fullWidth
                                    required defaultValue={this.state.contactFamilyName}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => this._handleOnChange(event.target.value, "contactfamilyname")}
                                    onBlur={(event: React.FocusEvent<HTMLInputElement>) => this.validateControl("contactfamilyname")}
                                    error={!controlValidation.lastNameValid && contactFamilyName !== ''}
                                    helperText={!controlValidation.lastNameValid && contactFamilyName !== '' ? 'Invalid Last name' : ''} />
                            </>}
                        <InputLabel>Mobile Phone</InputLabel><TextField id="mobile-phone" variant="outlined" margin="normal" fullWidth required
                            defaultValue={this.state.phoneNumber}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => this._handleOnChange(event.target.value, "phoneNumber")}
                            onBlur={(event: React.FocusEvent<HTMLInputElement>) => this.validateControl("phoneNumber")}
                            error={!controlValidation.phoneNumberValid && phoneNumber !== ''}
                            helperText={!controlValidation.phoneNumberValid && phoneNumber !== '' ? 'Invalid Mobile Phone - expected +614xxxxxxxx' : ''} />
                        <InputLabel>Email Address</InputLabel><TextField id="email-address" variant="outlined" margin="normal" fullWidth required
                            defaultValue={this.state.emailAddress}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => this._handleOnChange(event.target.value, "emailAddress")}
                            onBlur={(event: React.FocusEvent<HTMLInputElement>) => this.validateControl("emailAddress")}
                            error={!controlValidation.emailAddressValid && emailAddress !== ''}
                            helperText={!controlValidation.emailAddressValid && emailAddress !== '' ? 'Invalid Email Address' : ''} />
                    </>
                    : ""}
            </>
        )
    }

    private _handlerPartyDetails(partyDetail: PartyDetailsType): void {
        this.setState((prevState) => {
            return {
                ...prevState,
                partyId: partyDetail.partyId ?? "",
                partyType: partyDetail.partyType ?? "",
                ispexaKeyCreated: undefined,
                isPartySelectionChange: true
            }
        })
    }

    private renderSaveButton() {
        const { ispexaKeyCreated, isPartySelectionChange } = this.state;
        const isEnabled = this.isFormValid() && ((ispexaKeyCreated === undefined || ispexaKeyCreated.status === 'FAILED') || isPartySelectionChange);
        return (
            <Button id="saveButton" className="saveButton" variant="contained" onClick={() => this._handlerCreatePexaKey()} disabled={!isEnabled} >Save</Button>
        )
    }
    private renderPartyLabel = (partyDetail: PartyDetailsType) => {
        return (
            <>
                {partyDetail.partyType === this.IDENTITY_TYPE_INDIVIDUAL ?
                    <>
                        {partyDetail.fullName && partyDetail.fullName.givenName?.length
                            && partyDetail.fullName.familyName && partyDetail.fullName!.givenName.length > 1 ?
                            <b>   {(partyDetail.fullName!.givenName![0].value! + " " + partyDetail.fullName!.givenName![1].value! + " " + partyDetail.fullName.familyName)}</b>
                            : <b>{(partyDetail.fullName!.givenName![0].value)}</b>
                        }
                    </>
                    :
                    <b>{partyDetail.business?.legalEntityName}</b>
                }
            </>
        )
    }

    render() {
        const { isLoading, ispexaKeyCreated,isPartySelectionChange } = this.state;
        if (this.state.redirect) {
            return (<Redirect to={this.state.redirect} />)
        }
        return (
            <form onSubmit={this._handlerCreatePexaKey}>
                <div>
                    {isLoading ?
                        <Spinner size={SpinnerSize.large} label="Sending Pexa Key Invitation" />
                        :
                        <>
                            {ispexaKeyCreated !== undefined &&
                                ispexaKeyCreated.status === "FAILED" ?
                                <MessageBar messageBarType={MessageBarType.error}>
                                    Error: An error occured. {ispexaKeyCreated.message}.
                                </MessageBar>
                                : ispexaKeyCreated != undefined &&
                                ispexaKeyCreated.status !== "FAILED" && !isPartySelectionChange &&
                                <MessageBar messageBarType={MessageBarType.success}>
                                    Pexa Key Invitation sent successfully.
                                </MessageBar>
                            }
                            <Link href="https://www.pexa.com.au/pexa-key" target="_blank">
                                <img src="/images/pexa-key-logo-lockup.svg" alt="Pexa Key" height="40" />
                            </Link>

                            <div className="modal-header">
                                <input type="checkbox" id="pexaKeyConsent" checked disabled />
                                <label><>Send my client an email and SMS on my behalf inviting them to monitor their settlement and securely share account details through
                                    <a target="_blank" href="https://community.pexa.com.au/t5/Help-Centre/Introducing-PEXA-Key-pilot/ba-p/14381"> PEXA Key</a></></label>
                            </div>
                            <div className="modal-body">
                                {this.renderParties()}
                                {this.renderUserDetails()}
                            </div>
                            <div className="modal-footer">
                                {this.renderSaveButton()}
                                <Button className="button" variant="contained"
                                    onClick={() => this._handlerRedirectToHome()}>Back</Button>
                            </div>
                        </>
                    }
                </div>
            </form>
        );
    }
}
const mapStateToProps = (state: AppState) => {
    return {
        actionstepContext: state.common.actionstepContext,
        ispexaKeyCreated: state.pexa.createPexaKeyInviteResponse,
        gotResponse: state.pexa.gotResponse,
        requestType: state.pexa.requestType,
        //pexaWorkspaceResponse: state.pexa.createPexaWorkspaceResponse,
        pexaWorkspaceCreationData: state.pexa.pexaWorkspaceCreationData,
        workspaceSummaryList: state.pexa.workspaceSummaryList,
       // pexaWorkspaceInfoFromKonekta: state.pexa.pexaWorkspaceInfoFromKonekta
    }
}

const mapDispatchToProps = {
    getPexaWorkspaceSummary,
    createPexaKey
}

export default connect(mapStateToProps, mapDispatchToProps)(PexaKeyInvite);