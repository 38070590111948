import { IDropdownOption } from 'office-ui-fabric-react';

export const ECAFPaymentOptions: IDropdownOption[] = [
    {
        key: "",
        text: "Please select..."
    },
    {
        key: "AccountHolderPayes",
        text: "Client Pays"
    },
    {
        key: "UserPays",
        text: "Charge as Disbursement to Matter"
    }
]

export const ECAFTransferTypes: IDropdownOption[] = [
    {
        key: "",
        text: "Please select..."
    },
    {
        key: "1",
        text: "Transfer"
    },
    {
        key: "2",
        text: "Mortgate"
    },
    {
        key: "3",
        text: "Caveat"
    },
    {
        key: "4",
        text: "PriorityNotice"
    },
    {
        key: "5",
        text: "DischargeReleaseMortgate"
    },
    {
        key: "6",
        text: "WithdrawalOfCaveat"
    },
    {
        key: "7",
        text: "Other"
    }
]

// Notifiation type options
export const NotificationTypeOptions: IDropdownOption[] = [
    { key: 'Email', text: 'Email' },
    { key: 'Sms', text: 'Sms' },
    { key: 'EmailAndSms', text: 'Email and Sms' }
];

//Party mapper options
export const PartyMapperOptions: IDropdownOption[] = [
    { key: 'Buyer', text: 'Buyer' },
    { key: 'Client', text: 'Client' },
    { key: 'Seller', text: 'Seller' },
];