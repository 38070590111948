import * as React from 'react';
import { connect } from 'react-redux';

import { Link } from 'office-ui-fabric-react/lib/Link'
import { DefaultButton } from 'office-ui-fabric-react/lib/Button'
import { Modal } from 'office-ui-fabric-react/lib/Modal'
import { SubwayNavNodeState } from 'components/SubwayNav';
import { AppState } from 'app.types';
import {
    SaveUserAuditTrailDataCommand,
    UserAuditTrail
} from 'utils/wcaApiTypes';
import 'containers/pexa/createWorkspace.css'
import { saveUserAuditTrailData } from 'redux/common/actions';
import ShowPexaWorkspaceSummary from 'containers/pexa/showWorkspaceSummary';
import InviteParticipants from '../inviteParticipants';
import 'containers/pexa/createWorkspace.css'


import {
    clearPexaStateAndError
} from 'containers/pexa/redux/actions';

interface IAppProps {
    onChangeStep: (newState: SubwayNavNodeState) => void;
}

type AppProps = IAppProps & ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

type AppStates = {
    showInviteModal: boolean,
    showWorkspaceSummaryModal: boolean
}

export class WorkspaceCreated extends React.Component<AppProps, AppStates> {

    constructor(props: AppProps) {
        super(props);

        this.state = {
            showInviteModal: false,
            showWorkspaceSummaryModal: false,
        }
    }

    componentDidMount() {
        this.props.clearPexaStateAndError();
        this.saveUserAuditTrailDataIntoDb();
    }

    private saveUserAuditTrailDataIntoDb(): void {
        let userAuditTrail = new UserAuditTrail();
        userAuditTrail.actionstepOrg = this.props.actionstepContext!.orgKey;
        userAuditTrail.matterId = this.props.actionstepContext!.matterContext!.id;
        userAuditTrail.integration = "PEXA";
        userAuditTrail.integrationLink = "Create New Workspace";
        userAuditTrail.description = "New Pexa Workspace Created : " + this.props.pexaWorkspaceId;

        this.props.saveUserAuditTrailData(new SaveUserAuditTrailDataCommand({
            userAuditTrailData: userAuditTrail
        }));

    }

    render() {
        const { pexaWorkspaceUri } = this.props;
        const { showInviteModal, showWorkspaceSummaryModal } = this.state;

        return (
            <div>
                <>
                    <h4>
                        You may now <Link href={pexaWorkspaceUri} className="text-info" target="_blank" rel="noopener noreferrer">go to the workspace summary in PEXA</Link> for this matter.
                    </h4>
                    <h4>
                        Or  <Link onClick={this.showWorkspaceSummaryModal} className="text-info" rel="noopener noreferrer"> retrieve workspace summary </Link>
                    </h4>

                    <DefaultButton
                        className="button md-Grid-col ms-md4 ms-smPush4"
                        data-automation-id="show_invite_modal"
                        data-cy="show_invite_modal"
                        text="Invite Participants"
                        onClick={this.showInviteModal}
                        allowDisabledFocus={true}
                        disabled={false}
                    />
                    <br />

                    <Modal isOpen={showInviteModal}
                        isBlocking={true}
                        onDismiss={this.closeInviteModal}
                        containerClassName="invite-participants-modal"
                        className={showInviteModal !== null ? "animated fadeIn" : "animated fadeOut"}
                    >
                        <InviteParticipants closeInviteModal={this.closeInviteModal} openFromIframe={false} />

                    </Modal>

                    <Modal isOpen={showWorkspaceSummaryModal}
                        isBlocking={true}
                        onDismiss={this.closeWorkspaceSummaryModal}
                        containerClassName="workspace-summary-modal"
                        className={showWorkspaceSummaryModal !== null ? "animated fadeIn" : "animated fadeOut"}
                    >
                        <ShowPexaWorkspaceSummary />

                        <div className="modal-footer">
                            <DefaultButton
                                className="button"
                                data-automation-id="close_modal"
                                data-cy="close_modal"
                                text="Close"
                                onClick={this.closeWorkspaceSummaryModal}
                                allowDisabledFocus={true}
                            />
                        </div>
                    </Modal>
                </>
            </div>
        );
    }

    showInviteModal = async () => {
        await this.setState({
            showInviteModal: true
        })
    }
    showWorkspaceSummaryModal = async () => {
        await this.setState({
            showWorkspaceSummaryModal: true
        })
    }

    closeInviteModal = () => {
        this.setState({
            showInviteModal: false
        })
    }

    closeWorkspaceSummaryModal = () => {
        this.setState({
            showWorkspaceSummaryModal: false
        })
    }
}

const mapStateToProps = (state: AppState) => {
    return {
        pexaWorkspaceId: 'PEXA230192196',
        pexaWorkspaceUri: 'https://api-tst.pexalabs.com.au/pexa_web/dl/workspaces/PEXA230192196?role=Incoming Mortgagee',
        actionstepContext: state.common.actionstepContext
    }
}


const mapDispatchToProps = {
    saveUserAuditTrailData,
    clearPexaStateAndError
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceCreated);