import { TextField } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { createMuiTheme } from "@material-ui/core";

export const DisabledTextField = styled(TextField)({
    '& .Mui-disabled': {
        backgroundColor: '#42494900 !important',
    },
});
export const theme = createMuiTheme({
    overrides: {

        MuiFormHelperText: {
            root: {
                '&$error': {
                    color: '#F83E3E'
                }
            }
        },
        MuiFormControlLabel: {
            label: {
                fontSize: 14,
                color: '#333',
                //marginLeft: '10px',
                fontFamily: "'Poppins', Arial, sans-serif"
            },

        },
        MuiFormLabel: {
            root: {
                //  padding: '10px',
                color: '#004989',
                marginLeft: '12px',
                fontSize: '19px',
                fontWeight: 'bold',
                fontFamily: "'Poppins', Arial, sans-serif"
            }
        },
        MuiIconButton: {
            label: {
                paddingLeft: '70px'
            }
        }
    }
});