import { IDropdownOption } from "@fluentui/react";

export const DateTitles: IDropdownOption[] = [
	{
		key: "day",
		text: "Day/s"
	},
	{
		key: "week",
		text: "Week/s"
	},
	{
		key: "month",
		text: "Month/s"
	},
	{
		key: "year",
		text: "Year/s"
	},
];

export type IState = {
	startingDate: Date | undefined,
	coolingOffPeriod: string | undefined,
	financeDate: number | undefined,
	financeDateType: string | undefined,
	financeDateDue: string | undefined,
	buildingAndPest: number | undefined,
	buildingAndPestType: string | undefined,
	buildingAndPestDue: string | undefined,
	otherCondition: number | undefined,
	otherConditionType: string | undefined,
	otherConditionDue: string | undefined,
	settlementDate: number | undefined,
	settlementDateType: string | undefined,
	settlementDateDue: string | undefined
}

export const daysOfWeek = new Array('Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday');
export const monthNames = new Array("January", "February", "March", "April", "May", "June",
	"July", "August", "September", "October", "November", "December");