import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router/immutable'

import App from 'App';
import * as serviceWorker from 'utils/serviceWorker';
import { store, history } from 'redux/store'

import "office-ui-fabric-react/dist/css/fabric.min.css"

import { registerIcons } from '@uifabric/styling';
import { Customizer } from 'office-ui-fabric-react';
import konektaTheme from './konekta-theme';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faSms, faSync, faFileAlt, faExclamationTriangle, faCode } from '@fortawesome/free-solid-svg-icons';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';

//// For future - to more easily use icons through the app.
//// See: https://www.npmjs.com/package/@fortawesome/react-fontawesome
import { library } from '@fortawesome/fontawesome-svg-core';
library.add(faSms);
library.add(faSync);
library.add(faFileAlt);
library.add(faExclamationTriangle);
library.add(faCode);

registerIcons({
    icons: {
      'ErrorBadge': <FontAwesomeIcon icon={ faTimesCircle } />,
      'CheckMark': <FontAwesomeIcon icon={ faCheck } />,
    }
  });

const Customizations = {
    settings: {
      theme: konektaTheme
    }
  };

ReactDOM.render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <Customizer {...Customizations}>
                <App />
            </Customizer>
        </ConnectedRouter>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
