
export const GET_DATA_FROM_ACTIONSTEP_REQUESTED = "PexaReducer/GET_DATA_FROM_ACTIONSTEP_REQUESTED";
export const GET_DATA_FROM_ACTIONSTEP_SUCCESS = "PexaReducer/GET_DATA_FROM_ACTIONSTEP_SUCCESS";
export const GET_DATA_FROM_ACTIONSTEP_FAILED = "PexaReducer/GET_DATA_FROM_ACTIONSTEP_FAILED";

export const SET_PEXA_WORKSPACE_CREATION_REQUEST = "PexaReducer/SET_PEXA_WORKSPACE_CREATION_REQUEST"

export const VALIDATE_LAND_TITLE_REQUESTED = "PexaReducer/VALIDATE_LAND_TITLE_REQUESTED"
export const VALIDATE_LAND_TITLE_SUCCESS = "PexaReducer/VALIDATE_LAND_TITLE_SUCCESS"
export const VALIDATE_LAND_TITLE_FAILED = "PexaReducer/VALIDATE_LAND_TITLE_FAILED"

export const GET_PEXA_WORKSPACE_SUMMARY_REQUESTED = "PexaReducer/GET_PEXA_WORKSPACE_SUMMARY_REQUESTED"
export const GET_PEXA_WORKSPACE_SUMMARY_SUCCESS = "PexaReducer/GET_PEXA_WORKSPACE_SUMMARY_SUCCESS"
export const GET_PEXA_WORKSPACE_SUMMARY_FAILED = "PexaReducer/GET_PEXA_WORKSPACE_SUMMARY_FAILED"

export const GET_AVAILABLE_SETTLEMENT_TIMES_REQUESETD = "PexaReducer/GET_AVAILABLE_SETTLEMENT_TIMES_REQUESETD"
export const GET_AVAILABLE_SETTLEMENT_TIMES_SUCCESS = "PexaReducer/GET_AVAILABLE_SETTLEMENT_TIMES_SUCCESS"
export const GET_AVAILABLE_SETTLEMENT_TIMES_FAILED = "PexaReducer/GET_AVAILABLE_SETTLEMENT_TIMES_FAILED"

export const SEND_DATA_TO_PEXA_REQUESTED = "PexaReducer/SEND_DATA_TO_PEXA_REQUESTED";
export const SEND_DATA_TO_PEXA_SUCCESS = "PexaReducer/SEND_DATA_TO_PEXA_SUCCESS";
export const SEND_DATA_TO_PEXA_FAILED = "PexaReducer/SEND_DATA_TO_PEXA_FAILED";

export const CREATE_INVITATION_REQUESTED = "PexaReducer/CREATE_INVITATION_REQUESTED";
export const CREATE_INVITATION_SUCCESS = "PexaReducer/CREATE_INVITATION_SUCCESS";
export const CREATE_INVITATION_FAILED = "PexaReducer/CREATE_INVITATION_FAILED";

export const SAVE_PEXA_FORM_DATA = "PexaReducer/SAVE_PEXA_FORM_DATA";
export const CLEAR_PEXA_FORM_DATA = "PexaReducer/CLEAR_PEXA_FORM_DATA"

export const CLEAR_PEXA_STATE = "PexaReducer/CLEAR_PEXA_STATE";
export const CLEAR_PEXA_STATE_AND_ERROR = "PexaReducer/CLEAR_PEXA_STATE_AND_ERROR";

export const GET_PEXA_WORKSPACE_INFORMATION_FROM_KONEKTA_REQUESTED = "PexaReducer/GET_PEXA_WORKSPACE_INFORMATION_FROM_KONEKTA_REQUESTED";
export const GET_PEXA_WORKSPACE_INFORMATION_FROM_KONEKTA_SUCCESS = "PexaReducer/GET_PEXA_WORKSPACE_INFORMATION_FROM_KONEKTA_SUCCESS";
export const GET_PEXA_WORKSPACE_INFORMATION_FROM_KONEKTA_FAILED = "PexaReducer/GET_PEXA_WORKSPACE_INFORMATION_FROM_KONEKTA_FAILED";

export const MAINTAIN_SETTLEMENT_DATE_TIME_REQUESTED = "PexaReducer/MAINTAIN_SETTLEMENT_DATE_TIME_REQUESTED";
export const MAINTAIN_SETTLEMENT_DATE_TIME_SUCCESS = "PexaReducer/MAINTAIN_SETTLEMENT_DATE_TIME_SUCCESS";
export const MAINTAIN_SETTLEMENT_DATE_TIME_FAILED = "PexaReducer/MAINTAIN_SETTLEMENT_DATE_TIME_FAILED";

export const UPDATE_SETTLEMENT_DATE_TIME_ACTIONSTEP_REQUESTED = "PexaReducer/UPDATE_DATA_COLLECTION_RECORD_VALUES_REQUESTED";
export const UPDATE_SETTLEMENT_DATE_TIME_ACTIONSTEP_SUCCESS = "PexaReducer/UPDATE_DATA_COLLECTION_RECORD_VALUES_SUCCESS";
export const UPDATE_SETTLEMENT_DATE_TIME_ACTIONSTEP_FAILED = "PexaReducer/UPDATE_DATA_COLLECTION_RECORD_VALUES_FAILED";

export const GET_INVITATION_URI_REQUESTED = "PexaReducer/GET_INVITATION_URI_REQUESTED";
export const GET_INVITATION_URI_SUCCESS = "PexaReducer/GET_INVITATION_URI_SUCCESS";
export const GET_INVITATION_URI_FAILED = "PexaReducer/GET_INVITATION_URI_FAILED";

export const SET_SETTLEMENT_DATE_TIME_DATA_REQUEST = "PexaReducer/SET_SETTLEMENT_DATE_TIME_DATA_REQUEST"

export const CLEAR_STATES_FOR_SETTLEMENT_DATE_TIME = "PexaReducer/CLEAR_STATES_FOR_SETTLEMENT_DATE_TIME";

export const CREATE_PEXA_KEY_REQUESTED = "PexaReducer/CREATE_PEXA_KEY_REQUESTED";
export const CREATE_PEXA_KEY_SUCCESS = "PexaReducer/CREATE_PEXA_KEY_SUCCESS";
export const CREATE_PEXA_KEY_FAILED = "PexaReducer/CREATE_PEXA_KEY_FAILED";

export const GET_PEXA_KEY_REQUESTED = "PexaReducer/GET_PEXA_KEY_REQUESTED";
export const GET_PEXA_KEY_SUCCESS = "PexaReducer/GET_PEXA_KEY_SUCCESS";
export const GET_PEXA_KEY_FAILED = "PexaReducer/GET_PEXA_KEY_FAILED";

export const GET_PEXA_KEY_INVITE_FROM_KONEKTA_REQUESTED = "PexaReducer/GET_PEXA_KEY_INVITE_FROM_KONEKTA_REQUESTED";
export const GET_PEXA_KEY_INVITE_FROM_KONEKTA_SUCCESS = "PexaReducer/GET_PEXA_KEY_INVITE_FROM_KONEKTA_SUCCESS";
export const GET_PEXA_KEY_INVITE_FROM_KONEKTA_FAILED = "PexaReducer/GET_PEXA_KEY_INVITE_FROM_KONEKTA_FAILED";

export const COPY_LODGED_DOCUMENTS_TO_ACTIONSTEP_REQUESTED = "PexaReducer/COPY_LODGED_DOCUMENTS_TO_ACTIONSTEP_REQUESTED";
export const COPY_LODGED_DOCUMENTS_TO_ACTIONSTEP_SUCCESS = "PexaReducer/COPY_LODGED_DOCUMENTS_TO_ACTIONSTEP_SUCCESS";
export const COPY_LODGED_DOCUMENTS_TO_ACTIONSTEP_FAILED = "PexaReducer/COPY_LODGED_DOCUMENTS_TO_ACTIONSTEP_FAILED";

export const SAVE_PEXA_AUDIT_REQUESTED = "PexaReducer/SAVE_PEXA_AUDIT_REQUESTED";
export const SAVE_PEXA_AUDIT_SUCCESS = "PexaReducer/SAVE_PEXA_AUDIT_SUCCESS";
export const SAVE_PEXA_AUDIT_FAILED = "PexaReducer/SAVE_PEXA_AUDIT_FAILED";

export const GET_ACTIONSTEP_PROPERTY_DETAILS_REQUESTED = "PexaReducer/GET_ACTIONSTEP_PROPERTY_DETAILS_REQUESTED";
export const GET_ACTIONSTEP_PROPERTY_DETAILS_SUCCESS = "PexaReducer/GET_ACTIONSTEP_PROPERTY_DETAILS_SUCCESS";
export const GET_ACTIONSTEP_PROPERTY_DETAILS_FAILED = "PexaReducer/GET_ACTIONSTEP_PROPERTY_DETAILS_FAILED";

export const UPDATE_DEALING_NUMBER_ACTIONSTEP_REQUESTED = "PexaReducer/UPDATE_DEALING_NUMBER_ACTIONSTEP_REQUESTED";
export const UPDATE_DEALING_NUMBER_ACTIONSTEP_SUCCESS = "PexaReducer/UPDATE_DEALING_NUMBER_ACTIONSTEP_SUCCESS";
export const UPDATE_DEALING_NUMBER_ACTIONSTEP_FAILED = "PexaReducer/UPDATE_DEALING_NUMBER_ACTIONSTEP_FAILED";
export const CLEAR_PEXA_WORKSPACE_DEALING_NUMBER_INFO_STATE = "PexaReducer/CLEAR_PEXA_WORKSPACE_DEALING_NUMBER_INFO_STATE";

export const GET_FSS_LINEITEMS_REQUESTED = "PexaReducer/GET_FSS_LINEITEMS_REQUESTED";
export const GET_FSS_LINEITEMS_SUCCESS = "PexaReducer/GET_FSS_LINEITEMS_SUCCESS";
export const GET_FSS_LINEITEMS_FAILED = "PexaReducer/GET_FSS_LINEITEMS_FAILED";

export const SET_FSS_LINEITEMS_REQUESTED = "PexaReducer/SET_FSS_LINEITEMS_REQUESTED";
export const SET_FSS_LINEITEMS_SUCCESS = "PexaReducer/SET_FSS_LINEITEMS_SUCCESS";
export const SET_FSS_LINEITEMS_FAILED = "PexaReducer/SET_FSS_LINEITEMS_FAILED";
export const CLEAR_FSS_LINEITEMS_STATE = "PexaReducer/CLEAR_FSS_LINEITEMS_STATE";

