
import * as React from 'react'
import { connect } from 'react-redux';

import { AppState } from 'app.types';

import { getRequest } from '../../utils/request'

import "./index.css"
import { Card, ICardSectionTokens } from '@uifabric/react-cards';
import { Link, Text, Image } from 'office-ui-fabric-react';
import { TooltipHost } from 'office-ui-fabric-react/lib/Tooltip';
import { getId } from 'office-ui-fabric-react/lib/Utilities';
import { Link as ReactLink } from 'react-router-dom';
import { IIntegration, LinkType, ActionstepMatterInfo, UserAuditTrail, SaveUserAuditTrailDataCommand } from 'utils/wcaApiTypes';
import { Link as IntegrationLink } from 'utils/wcaApiTypes';
import LoadingWidget from 'components/common/loadingWidget';
import DataKonektaMatterLink from 'components/dataKonektaMatterLink';
import { getPexaWorkspaceInformationFromKonekta } from 'containers/pexa/redux/actions';
import { getSecurexchangeWorkspaceInformationFromKonekta } from 'containers/securexchange/redux/actions';
import { saveUserAuditTrailData } from 'redux/common/actions';
import { ActionstepMatterInfoState } from '../../utils/newWcaApiTypes';
import { UpdateMatterWithWorkspaceComponent } from '../pexa/components/updateMatterWithWorkspace';
import Promotions from 'containers/promotions/components/promotion';

interface IAppProps { }

type AppProps = IAppProps & ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

type AppStates = {
    matterId: number;
    jurisdiction: string | null;
    actionstepOrg: string;
    integrations: IIntegration[];
    integrationsRetrieved: boolean;
    hideUpdateMatterWithPEXADialog: boolean;
}

export class MatterPage extends React.Component<AppProps, AppStates> {
    private _hostId: string = getId('tooltipHost');

    constructor(props: AppProps) {
        super(props);

        const actionstepContext = props.actionstepContext!;

        this.state = {
            matterId: actionstepContext.matterContext!.id,
            jurisdiction: null,
            actionstepOrg: actionstepContext.orgKey,
            integrations: [],
            integrationsRetrieved: false,
            hideUpdateMatterWithPEXADialog: true
        }
    }

    public async componentDidMount(): Promise<void> {
        const integrations = (await getRequest(`/api/integrations/integration-links/${this.state.actionstepOrg}/${this.state.matterId}`)) as IIntegration[];
        let matterInfo = new ActionstepMatterInfo({
            orgKey: this.state.actionstepOrg,
            matterId: this.state.matterId,
            termsEverAccepted: false,
            latestTermsAccepted: false
        });

        this.props.getPexaWorkspaceInformationFromKonekta(matterInfo);
        this.props.getSecurexchangeWorkspaceInformationFromKonekta(matterInfo);
        this.setState({
            integrations: integrations,
            integrationsRetrieved: true
        });

        const matterInformationWithType = (await getRequest(`/api/actionstep/matter-with-state/${this.state.actionstepOrg}/${this.state.matterId}`)) as ActionstepMatterInfoState;

        if (matterInformationWithType && matterInformationWithType.state && matterInformationWithType.state != "") {
            this.setState({
                jurisdiction: matterInformationWithType.state
            })
        }
    }

    public usingGlobalXCertificates(link: IntegrationLink): boolean {
        var returnValue: boolean = false;

        if (link && link.title) {
            returnValue = (link.title == "QLD Certificates" || link.title == "NSW Certificates" || link.title == "SA Certificates" || link.title == "VIC Certificates");
        }

        return returnValue;
    }

    private isDisplay(link: IntegrationLink): boolean {
        // If no jurisdiction is found
        if (!this.state.jurisdiction) {
            if (this.usingGlobalXCertificates(link)) {
                return false;
            }
        }

        if (this.props.securexchangeInfoFromKonekta) {
            if (link.title === "Create Transaction") return false;
        } else {
            if (link.title == "Open Transaction") return false;
        }

        if (this.props.pexaWorkspaceInfoFromKonekta) {
            if (link.title === "Create New Workspace") return false;
            if (link.title == "Link Existing Workspace") return false;
        } else {
            if (link.title === "Open Workspace In PEXA" ||
                link.title === "Show Workspace Summary" ||
                link.title === "Show Pexa Workspace Summary" ||
                link.title === "Invite Participants" ||
                link.title === "PEXA Notifications - Beta" ||
                link.title === "Refresh Data") {
                return false;
            }
        }

        if (this.state.jurisdiction && this.state.jurisdiction != "") {
            // Checking for GlobalX certificate links
            if (link && link.title && this.usingGlobalXCertificates(link)) {
                var stateOfGlobalXCert = ""; // The state of the GlobalX Certificate
                var stateOfActionstepMatter = ""; // The state of the Actionstep matter the user is currently in
                var allowGlobalXCertificate = false; // This variable checks if we should add the certificate

                if (link.title.toLowerCase().includes("nsw")) stateOfGlobalXCert = "NSW";
                if (link.title.toLowerCase().includes("qld")) stateOfGlobalXCert = "QLD";
                if (link.title.toLowerCase().includes("vic")) stateOfGlobalXCert = "VIC";

                if (this.state.jurisdiction.toLowerCase().includes("nsw")) stateOfActionstepMatter = "NSW";
                if (this.state.jurisdiction.toLowerCase().includes("qld") || this.state.jurisdiction.toLowerCase().includes("queensland")) stateOfActionstepMatter = "QLD";
                if (this.state.jurisdiction.toLowerCase().includes("vic") || this.state.jurisdiction.toLowerCase().includes("victoria")) stateOfActionstepMatter = "VIC";

                if (stateOfGlobalXCert != "" && stateOfActionstepMatter != "") {
                    if (stateOfActionstepMatter.toUpperCase() == stateOfGlobalXCert) allowGlobalXCertificate = true;
                }

                return allowGlobalXCertificate;
            } else {
                if (this.usingGlobalXCertificates(link)) {
                    return false;
                }
            }
        }

        return true;
    }

    private isDisable(link: IntegrationLink): boolean {
        if (!this.props.pexaWorkspaceInfoFromKonekta) {
            if (link.title === "Maintain and Update Settlement Date/Time" ||
                link.title === "Invite Client to PEXA Key" ||
                link.title === "Retrieve Documents") {
                return true;
            }
        }
        return false;
    }

    private saveUserAuditTrailDataIntoDb(integration: string, integrationLink: string): void {
        let userAuditTrail = new UserAuditTrail();

        userAuditTrail.actionstepOrg = this.props.actionstepContext!.orgKey;
        userAuditTrail.matterId = this.props.actionstepContext!.matterContext!.id;

        userAuditTrail.description = "Visited";

        // use integrationLink name instead of integration name for Konekta integration
        if (integration === "Konekta") {
            userAuditTrail.integration = integrationLink;
        } else {
            userAuditTrail.integration = integration;
        }

        if (integrationLink === "Stamp Duty Calculator") {
            userAuditTrail.integrationLink = "QLD Stamp Duty Calculator";
        } else {
            userAuditTrail.integrationLink = integrationLink;
        }

        this.props.saveUserAuditTrailData(new SaveUserAuditTrailDataCommand({
            userAuditTrailData: userAuditTrail
        }));
    }

    render(): JSX.Element {
        const { integrations, integrationsRetrieved } = this.state;
        const cardSectionTokens: ICardSectionTokens = {
            childrenGap: 2
        }

        return (
            <div className="matter-page">
                <div className="ms-Grid-row parter-cards-wrapper">
                    <Promotions />
                    {!integrationsRetrieved ?
                        <LoadingWidget message="Retrieving your integrations..." />
                        :
                        (integrations.length < 1) ?
                            <div>No integrations were found for your account.</div>
                            :
                            integrations.map(integration => (
                                <>
                                    <div className="ms-Grid-col ms-sm4" key={integration.title}>
                                        <Card className="partner-card">
                                            <Card.Item className={`partner-logo ${integration.title?.toLowerCase() == "pexa" ? "pexa-logo" : ""}`} >
                                                <Link target="_blank" href={integration.logo?.href}>   <Image src={integration.logo?.src} alt={integration.logo?.alt} title={integration.title} width={integration.logo?.width} /> </Link>
                                            </Card.Item>

                                            <Card.Section className="partner-integrations" tokens={cardSectionTokens}>
                                                {integration.links && integration.links.length > 0 ?
                                                    integration.links?.map(link => (
                                                        <TooltipHost
                                                            content={link.toolTip}
                                                            id={this._hostId}
                                                            calloutProps={{ gapSpace: 0 }}
                                                            styles={{ root: { display: 'inline-block', textAlign: 'center' } }}
                                                            key={link.title}>

                                                            {link.disabled ?
                                                                <Link style={{ display: link.title !== "PEXA Notifications - Beta" ? "initial" : "none" }} href="#" disabled aria-describedby={this._hostId}><Text variant="medium">{link.title}{MatterPage.getBetaTag(link.isBeta)}</Text></Link>
                                                                :

                                                                <>  {link.linkType === LinkType.ReactLink && this.isDisplay(link) &&
                                                                    <ReactLink
                                                                        to={this.parseHref(link.value)}
                                                                        target={MatterPage.parseTarget(link.openInNewWindow)}
                                                                        aria-describedby={this._hostId}
                                                                        onClick={() => { this.saveUserAuditTrailDataIntoDb(integration.title!, link.title!) }}
                                                                        className={this.isDisable(link) ? "integration-link-disable" : ""}
                                                                    >
                                                                        <Text variant="medium">{link.title}{MatterPage.getBetaTag(link.isBeta)}</Text>
                                                                    </ReactLink>}
                                                                    {link.linkType === LinkType.Href && this.isDisplay(link) &&
                                                                        <Link
                                                                            href={this.parseHref(link.value)}
                                                                            target={MatterPage.parseTarget(link.openInNewWindow)}
                                                                            aria-describedby={this._hostId}
                                                                            onClick={() => {
                                                                                if (link.title == "Link Existing Workspace") {
                                                                                    this.setState({ hideUpdateMatterWithPEXADialog: false })
                                                                                } else {
                                                                                    this.saveUserAuditTrailDataIntoDb(integration.title!, link.title!)
                                                                                }
                                                                            }}
                                                                        >
                                                                            <Text variant="medium">{link.title}{MatterPage.getBetaTag(link.isBeta)}</Text>
                                                                        </Link>}

                                                                    {(link.linkType === LinkType.ReactComponent && link.value === "DataKonektaMatterLink") &&
                                                                        <DataKonektaMatterLink />}
                                                                </>
                                                            }
                                                        </TooltipHost>
                                                    ))
                                                    :
                                                    integration.comingSoon ?
                                                        <Link href="#" disabled aria-describedby={this._hostId}><Text variant="medium">Coming Soon</Text></Link>
                                                        :
                                                        <ReactLink target="_blank" to="/contactus" aria-describedby={this._hostId}><Text variant="medium">Get started</Text></ReactLink>
                                                }
                                            </Card.Section>
                                        </Card>
                                    </div>
                                </>
                            ))
                    }
                </div>

                <UpdateMatterWithWorkspaceComponent
                    hideDialog={this.state.hideUpdateMatterWithPEXADialog}
                    cancelOnClickHandler={() => {
                        this.setState({
                            hideUpdateMatterWithPEXADialog: true
                        })
                    }}
                    actionstepOrg={this.state.actionstepOrg}
                    matterId={this.state.matterId + ""}
                />
            </div>
        )
    }

    private parseHref(href: string | undefined): string {
        return href?.replace('{actionstepOrg}', this.state.actionstepOrg)?.replace('{matterId}', this.state.matterId.toString()) ?? '#';
    }

    private static parseTarget(openInNewWindow: boolean) {
        return openInNewWindow ? '_blank' : '_self';
    }

    private static getBetaTag(isBeta: boolean) {
        return <sup>{isBeta ? 'beta' : ''}</sup> ?? '';
    }
}

const mapStateToProps = (state: AppState) => {
    return {
        actionstepContext: state.common.actionstepContext,
        pexaWorkspaceInfoFromKonekta: state.pexa.pexaWorkspaceInfoFromKonekta,
        securexchangeInfoFromKonekta: state.securexchange.securexchangeWorkspaceInfoFromKonekta
    }
}

const mapDispatchToProps = {
    getPexaWorkspaceInformationFromKonekta,
    getSecurexchangeWorkspaceInformationFromKonekta,
    saveUserAuditTrailData
}


export default connect(mapStateToProps, mapDispatchToProps)(MatterPage);