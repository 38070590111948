import * as React from 'react';
import { Router, Switch, Redirect } from 'react-router-dom'
import { connect } from 'react-redux';
import history from 'utils/history'
import { initializeIcons } from '@uifabric/icons'

import { AppState } from 'app.types';
import { setUIDefinitions } from 'redux/common/actions'
import { getUIDefinitions } from 'utils/commonInfo';

import { KonektaRoute, RequiredContext } from 'components/konektaRoute';
import HomePage from 'containers/home/index'
import ManageAddOns from 'containers/manage-add-ons';
//import SignupPage from 'containers/signup';
import Webfrom from 'containers/webform/EnhancedWebform';
//import ContactUsPage from 'containers/contactus';
import ContactUsPageV2 from 'containers/contact';
import RequestPolicy from 'containers/firsttitle/requestPolicy'
import CreatePexaWorkspace from 'containers/pexa/createWorkspace'
import ShowPexaWorkspaceSummary from 'containers/pexa/showWorkspaceSummary'
import InviteParticipants from 'containers/pexa/inviteParticipants'
import MaintainUpdateSettlementDateTime from 'containers/pexa/maintainUpdateSettlementDateTime'
import PexaKeyInviteForm from 'containers/pexa/steps/components/PexaKeyInvite/PexaKeyInviteForm';
import RetrieveDocuments from 'containers/pexa/steps/components/RetrieveDocuments';
import CreateOrder from 'containers/ecaf/createOrder'
import ECAFStatusPage from 'containers/ecaf/statusPage';
import ECAFSummaryPage from 'containers/ecaf/summaryPage';
import ECAFOrderlist from 'containers/ecaf/orderlistPage';
import ECAFReport from 'containers/ecaf/reportPage';

import SettlementCalculator from 'containers/calculators/settlement/settlementCalculator';
import SettlementCalculatorBasic from 'containers/calculators/settlement/settlementCalculatorBasic';
import DateCalculator from 'containers/calculators/dateCalculator/dateCalculator';
import SpecialMeterReadingCalculator from 'containers/calculators/specialMeterReading/SpecialMeterReadingCalculator';
import VicWaterUsage from 'containers/calculators/vicWaterUsage/VicWaterUsage';
import MatterPage from 'containers/matter';
import GXOnline from 'containers/globalx/pages/gxOnline';
import ManageGlobalX from 'containers/manage-add-ons/pages/globalx';
import { DataKonekta } from 'containers/manage-add-ons/pages/dataKonekta';

import UserAuditTrailTable from 'containers/konekta/userAuditTrailTable';

import EmailArchiver from 'containers/email-archiver/emailArchiver';
import EmailArchiverTransactionStatus from 'containers/email-archiver/emailArchiverTransactionStatus';

import NavHeader from 'components/layout/navHeader'
import Footer from 'components/layout/footer'

import TelemetryProvider from 'components/telemetry-provider';

import "./App.css"
import { Stack, IStackTokens } from 'office-ui-fabric-react';
import ECAFAuth from './containers/ecaf/auth/ecafAuth';
import RapidBuildingInspections from 'containers/rapidBuildingInspections/rapidBuildingInspections';
import RbiConfirmationSection from 'containers/rapidBuildingInspections/components/rbiConfirmation';
import StewartTitleInsurance from 'containers/stewartTitle/stewartTitleInsurance';
import StewartTitleOrderDetail from 'containers/stewartTitle/stewartTitleOrderDetail'


import { UISettings } from 'utils/wcaApiTypes';
import DataRefresh from 'containers/pexa/steps/components/DataRefresh';
import LegalAidReport from 'containers/legalaid/legalaidReport';


type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps

type State = {
	fullPage: boolean;
	isLoading: boolean;

}

export class App extends React.Component<Props, State> {

	constructor(props: Props) {
		super(props);

		initializeIcons();

		this.state = {
			fullPage: false,
			isLoading: false
		}
	}

	public async componentDidMount(): Promise<void> {
		if (this.props.uiDefinitions === undefined) {
			try {
				var uiDefinitions: UISettings;
				uiDefinitions = new UISettings;
				getUIDefinitions(new URLSearchParams(window.location.search).get('jwt') ?? "").then(value => {
					uiDefinitions = value;
					if (uiDefinitions?.iFramseSrc != "") {
						this.setState({
							isLoading: true
						});
					}
					this.props.setUIDefinitions(uiDefinitions);
				});

			} catch (e) {
				console.log('Konekta: Unknown error getting UI Definitions:', e);
			}
		}
	}

	hideSpinner = () => {
		this.setState({
			isLoading: false
		});
	};

	render() {
		const { fullPage, uiDefinitions } = this.props;
		const { isLoading } = this.state;
		const MainStackTokens: IStackTokens = {
			childrenGap: 'm',
			padding: 10,
		};

		return (
			<div className={`rootDiv ${uiDefinitions?.iFramseSrc != "" ? 'rootDivEmbededIframe' : ""}`}>
				{uiDefinitions == undefined || isLoading && <div className="loading-message">
					<h1>Konekta is Loading...</h1>
					<img src="/images/Konekta_loading.svg" alt="Konekta is loading..." />
				</div>}
				{uiDefinitions?.iFramseSrc != "" ? <iframe onLoad={this.hideSpinner} width="100%" height="100%" frameBorder="0" src={uiDefinitions?.iFramseSrc} /> :
					<div className={fullPage ? 'full-page-wrapper' : 'normal-page-wrapper'}>
						<Router history={history}>
							<TelemetryProvider instrumentationKey={(window as any).appInsightsInstrumentationKey}>
								<NavHeader />
								<Stack tokens={MainStackTokens}>
									<Switch>
										{/* Anonymous pages */}
										{/* <KonektaRoute allowAnonymous path="/signup" component={SignupPage} /> */}
										<Redirect path="/signup" to="/contactus" />
										<KonektaRoute fullPage allowAnonymous path="/EnhencedWebform" component={Webfrom} />
										<KonektaRoute fullPage allowAnonymous path="/contactus" component={ContactUsPageV2} />
										{/*<KonektaRoute fullPage allowAnonymous path="/contactus" component={ContactUsPage}/>*/}
										{/* Matter - specific (usually used from the Actionstep iframe) */}
										<KonektaRoute requiredContext={RequiredContext.OrgAndMatter} path="/ecaf/create-order" component={CreateOrder} />
										<KonektaRoute requiredContext={RequiredContext.OrgAndMatter} path="/pexa/create-workspace" component={CreatePexaWorkspace} />
										<KonektaRoute requiredContext={RequiredContext.OrgAndMatter} path="/pexa/show-workspace-summary" component={ShowPexaWorkspaceSummary} />
										<KonektaRoute requiredContext={RequiredContext.OrgAndMatter} path="/pexa/invite-participants" component={InviteParticipants} />
										<KonektaRoute requiredContext={RequiredContext.OrgAndMatter} path="/pexa/maintain-update-settlement-date-time" component={MaintainUpdateSettlementDateTime} />
										<KonektaRoute requiredContext={RequiredContext.OrgAndMatter} path="/pexa/retrieve-documents" component={RetrieveDocuments} />
										<KonektaRoute requiredContext={RequiredContext.OrgAndMatter} path="/pexa/invite-client-pexa-key" component={PexaKeyInviteForm} />
										<KonektaRoute requiredContext={RequiredContext.OrgAndMatter} path="/pexa/show-invite-client-pexa-key" component={ShowPexaWorkspaceSummary} />
										<KonektaRoute requiredContext={RequiredContext.OrgAndMatter} path="/calculators/settlement-advanced" component={SettlementCalculator} />
										<KonektaRoute requiredContext={RequiredContext.OrgAndMatter} path="/calculators/settlement" component={SettlementCalculatorBasic} />
										<KonektaRoute requiredContext={RequiredContext.OrgAndMatter} path="/calculators/specialMeterReading" component={SpecialMeterReadingCalculator} />
										<KonektaRoute requiredContext={RequiredContext.OrgAndMatter} path="/calculators/vicWaterUsage" component={VicWaterUsage} />
										<KonektaRoute requiredContext={RequiredContext.OrgAndMatter} path="/firsttitle/request-policy" component={RequestPolicy} />
										<KonektaRoute requiredContext={RequiredContext.OrgAndMatter} path="/matter" component={MatterPage} />
										<KonektaRoute requiredContext={RequiredContext.OrgAndMatter} path="/globalx/property-information" component={GXOnline} />
										<KonektaRoute requiredContext={RequiredContext.OrgAndMatter} path="/konekta/user-audit-trail" component={UserAuditTrailTable} />
										<KonektaRoute exact requiredContext={RequiredContext.OrgAndMatter} path="/konekta/rbiconfirmation" component={RbiConfirmationSection} />
										<KonektaRoute requiredContext={RequiredContext.OrgAndMatter} path="/ecaf/connect" component={ECAFAuth} />
										<KonektaRoute requiredContext={RequiredContext.OrgAndMatter} path="/ecaf/summary" component={ECAFSummaryPage} />
										<KonektaRoute requiredContext={RequiredContext.OrgAndMatter} path="/ecaf/orderlist" component={ECAFOrderlist} />
										<KonektaRoute path="/ecaf/envelope-status" component={ECAFStatusPage} />
										<KonektaRoute path="/ecaf/onboard" component={ECAFAuth} />
										<KonektaRoute path="/calculators/dateCalculator" component={DateCalculator} />
										<KonektaRoute requiredContext={RequiredContext.OrgAndMatter} path="/ecaf/webvoi" component={CreateOrder} />
										<KonektaRoute requiredContext={RequiredContext.OrgAndMatter} path="/ecaf/report" component={ECAFReport} />

										<KonektaRoute path="/email-archiver" exact component={EmailArchiver} />
										<KonektaRoute path="/email-archiver/transaction-status" component={EmailArchiverTransactionStatus} />

										{/* Actionstep org specific (requires an org to be selected) */}
										<KonektaRoute requiredContext={RequiredContext.Org} path="/manage-add-ons/data-konekta" component={DataKonekta} />
										<KonektaRoute requiredContext={RequiredContext.Org} path="/manage-add-ons/globalx" component={ManageGlobalX} />
										<KonektaRoute requiredContext={RequiredContext.Org} path="/manage-add-ons" component={ManageAddOns} />
										<KonektaRoute path="/rbi/rbiQuoteForm" component={RapidBuildingInspections} />
										<KonektaRoute path="/pexa/pexa-refresher" component={DataRefresh} />
										<KonektaRoute requiredContext={RequiredContext.OrgAndMatter} path="/stewartTitle/order-policy" component={StewartTitleInsurance} />
										<KonektaRoute requiredContext={RequiredContext.OrgAndMatter} path="/stewartTitle/order-detail" component={StewartTitleOrderDetail} />
										<KonektaRoute requiredContext={RequiredContext.OrgAndMatter} path="/legalaid/legalaidpdf" component={LegalAidReport} />

										{/* General standalone pages (own tab/window) */}
										<KonektaRoute path="/" exact component={HomePage} />

										{/* Fallback */}
										<Redirect to="/" />
									</Switch>
								</Stack>
								<Footer />
							</TelemetryProvider>
						</Router>
					</div>
				}
			</div>
		);
	}
}

const mapStateToProps = (state: AppState) => {
	return {
		fullPage: state.common.fullPage,
		uiDefinitions: state.common.uiDefinitions,
	}
}

const mapDispatchToProps = {
	setUIDefinitions,
}

export default connect(mapStateToProps, mapDispatchToProps)(App);