
export const GET_CONNECTED_ORGS_REQUESTED = "EmailArchiverReducer/GET_CONNECTED_ORGS_REQUESTED";
export const GET_CONNECTED_ORGS_SUCCESS = "EmailArchiverReducer/GET_CONNECTED_ORGS_SUCCESS";
export const GET_CONNECTED_ORGS_FAILED = "EmailArchiverReducer/GET_CONNECTED_ORGS_FAILED";

export const GET_LIST_MATTERS_REQUESTED = "EmailArchiverReducer/GET_LIST_MATTERS_REQUESTED";
export const GET_LIST_MATTERS_SUCCESS = "EmailArchiverReducer/GET_LIST_MATTERS_SUCCESS";
export const GET_LIST_MATTERS_FAILED = "EmailArchiverReducer/GET_LIST_MATTERS_FAILED";

export const DELETE_EMAILS_AND_ATTACHMENTS_IN_SRC_ORG_REQUESTED = "EmailArchiverReducer/DELETE_EMAILS_AND_ATTACHMENTS_IN_SRC_ORG_REQUESTED";
export const DELETE_EMAILS_AND_ATTACHMENTS_IN_SRC_ORG_SUCCESS = "EmailArchiverReducer/DELETE_EMAILS_AND_ATTACHMENTS_IN_SRC_ORG_SUCCESS";
export const DELETE_EMAILS_AND_ATTACHMENTS_IN_SRC_ORG_FAILED = "EmailArchiverReducer/DELETE_EMAILS_AND_ATTACHMENTS_IN_SRC_ORG_FAILED";

export const VALIDATE_DATA_INPUT_REQUESTED = "EmailArchiverReducer/VALIDATE_DATA_INPUT_REQUESTED";
export const VALIDATE_DATA_INPUT_SUCCESS = "EmailArchiverReducer/VALIDATE_DATA_INPUT_SUCCESS";
export const VALIDATE_DATA_INPUT_FAILED = "EmailArchiverReducer/VALIDATE_DATA_INPUT_FAILED";

export const TRIGGER_EMAIL_ARCHIVER_PROCESS_REQUESTED = "EmailArchiverReducer/TRIGGER_EMAIL_ARCHIVER_PROCESS_REQUESTED";
export const TRIGGER_EMAIL_ARCHIVER_PROCESS_SUCCESS = "EmailArchiverReducer/TRIGGER_EMAIL_ARCHIVER_PROCESS_SUCCESS";
export const TRIGGER_EMAIL_ARCHIVER_PROCESS_FAILED = "EmailArchiverReducer/TRIGGER_EMAIL_ARCHIVER_PROCESS_FAILED";

export const STORE_USER_INPUT_STATE = "EmailArchiverReducer/STORE_USER_INPUT_STATE";

export const CLEAR_EMAIL_ARCHIVER_STATE = "EmailArchiverReducer/CLEAR_EMAIL_ARCHIVER_STATE";
export const CLEAR_EMAIL_ARCHIVER_RESPONSE = "EmailArchiverReducer/CLEAR_EMAIL_ARCHIVER_RESPONSE";

export const GET_TRANSACTION_STATUS_REQUESTED = "EmailArchiverReducer/GET_TRANSACTION_STATUS_REQUESTED";
export const GET_TRANSACTION_STATUS_SUCCESS = "EmailArchiverReducer/GET_TRANSACTION_STATUS_SUCCESS";
export const GET_TRANSACTION_STATUS_FAILED = "EmailArchiverReducer/GET_TRANSACTION_STATUS_FAILED";

export const CANCEL_TRANSACTION_REQUESTED = "EmailArchiverReducer/CANCEL_TRANSACTION_REQUESTED";
export const CANCEL_TRANSACTION_SUCCESS = "EmailArchiverReducer/CANCEL_TRANSACTION_SUCCESS";
export const CANCEL_TRANSACTION_FAILED = "EmailArchiverReducer/CANCEL_TRANSACTION_FAILED";

export const DOWNLOAD_REPORTS_REQUESTED = "EmailArchiverReducer/DOWNLOAD_REPORTS_REQUESTED";
export const DOWNLOAD_REPORTS_SUCCESS = "EmailArchiverReducer/DOWNLOAD_REPORTS_SUCCESS";
export const DOWNLOAD_REPORTS_FAILED = "EmailArchiverReducer/DOWNLOAD_REPORTS_FAILED";


/*export const DOWNLOAD_REPORTS_REQUESTED = "EmailArchiverReducer/DOWNLOAD_REPORTS_REQUESTED";
export const DOWNLOAD_REPORTS_SUCCESS = "EmailArchiverReducer/DOWNLOAD_REPORTS_SUCCESS";
export const DOWNLOAD_REPORTS_FAILED = "EmailArchiverReducer/DOWNLOAD_REPORTS_FAILED";*/




