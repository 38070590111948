
import { call, put, takeLatest } from 'redux-saga/effects'

import * as CONSTANTS from 'containers/rapidBuildingInspections/redux/constants'
import * as ACTIONS from 'containers/rapidBuildingInspections/redux/actions'

import {
    getRequest,
    postRequest
} from 'utils/request'

import {
    ActionstepClientInfo
} from 'containers/rapidBuildingInspections/utils/rapidBuildingInspectionAPITypes';

import {
    GetClientDetails,
    SendRBIData
} from 'containers/rapidBuildingInspections/redux/actionTypes';


export function* getClientDetails(action: GetClientDetails) {
    try {
        const response = yield call(getRequest, "/api/rapid-building/rapibuilding-inspections/" + action.params.matterId + "/" + action.params.orgKey);
        const data: ActionstepClientInfo = ActionstepClientInfo.fromJS(response);
        yield put(ACTIONS.getClientDetailSuccess(data));
    }
    catch (error) {
        yield put(ACTIONS.getClientDetailFailed(error?.response?.data));
    }
}
export function* sendRBIData(action: SendRBIData) {
    try {
        const response = yield call(postRequest, "/api/rapid-building/send-rbi-datainfo/", action.params);
        const data: string = response;
        yield put(ACTIONS.sendRBIRequestSuccess(data));
    }
    catch (error) {
        yield put(ACTIONS.sendRBIDataFailed(error?.response?.data));
    }
}

export default function* rapidBuildingInspectionsSaga() {
    yield takeLatest(CONSTANTS.GET_CLIENT_DETAILS_REQUESTED, getClientDetails);
    yield takeLatest(CONSTANTS.SEND_RBI_DETAILS_REQUESTED, sendRBIData);
} 