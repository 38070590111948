import * as React from 'react'

import {
	TooltipHost
} from 'office-ui-fabric-react';
import { useId } from '@uifabric/react-hooks';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { FunctionComponent } from 'react';

import './helpTip.css';

const HelpTip: FunctionComponent<{ helpContent: string | JSX.Element | JSX.Element[] }> = ({ helpContent }) => {
	const helpTipId = useId('helpTip');

	return (
		<TooltipHost
			id={helpTipId}
			className="helpTip"
			content={helpContent}>
			<FontAwesomeIcon aria-describedby={helpTipId} icon={faQuestionCircle} />
		</TooltipHost>
	);
}

export default HelpTip;