import * as React from 'react';
import { TextField, Dropdown, ChoiceGroup, Separator } from 'office-ui-fabric-react/lib';
import CustomDatePickers from 'components/common/customDatePickers';
import { _onFormatDateDDMMYYYY } from 'utils/dataFormatter';
import { apportionmentOption, stateName } from '../common';
import { getCTSOptions } from '../Helper';
import { MatterDetails as MatterDetailsModel } from 'utils/wcaApiTypes';

interface IMapStateToProps { }
interface IMapDispatchToProps { }

interface IProps {
    updatedState: any;
    updateValue: (newValue: any, whichValue: string, needRefresh?: boolean) => void;
    modalName: any;
    matterDetails: MatterDetailsModel;
}

type AppProps = IMapStateToProps & IProps & IMapDispatchToProps;
type AppStates = {}

export default class WaterUsage extends React.Component<AppProps, AppStates> {

    public render(): JSX.Element {

        const stateOfCounty = this.props.matterDetails.state;

        const updatedState = this.props.updatedState;
        const ctsOptions = getCTSOptions().sort((a, b) => (a.key > b.key) ? 1 : -1);

        if (updatedState['method'] === "search-reading") {
            this.props.updateValue(0, 'averageDailyAmount');
            updatedState['averageDailyAmount'] = 0
        }
        else if ((updatedState['method'] === "daily-average") && (stateOfCounty !== stateName.VIC)) {
            this.props.updateValue('', "searchDate");
            updatedState['searchDate'] = null
            this.props.updateValue(0, "searchReadingAmount");
            updatedState['searchReadingAmount'] = 0
        }

        return (
            <div className="modal-body">
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row modal-row">
                        <div className="ms-Grid-col ms-sm6">
                            <div className="ms-Grid-col ms-sm3">Method :</div>
                            <div className="ms-Grid-col ms-sm9">
                                <ChoiceGroup
                                    className="defaultChoiceGroup"
                                    defaultSelectedKey={updatedState['method']}
                                    options={[
                                        { key: 'search-reading', text: 'Search Reading' },
                                        { key: 'daily-average', text: 'Daily Average' }
                                    ]}
                                    required={true}
                                    onChange={(ev, item) => this.props.updateValue(item!.key, 'method', true)}
                                />
                            </div>
                        </div>

                        <div className="ms-Grid-col ms-sm6">
                            <div className="ms-Grid-col ms-sm4">{(updatedState['method'] === 'daily-average') && (stateOfCounty === stateName.VIC) ? "Average Daily Usage by KL :" : "Average Daily Amount :"}</div>
                            <div className="ms-Grid-col ms-sm8">
                                <TextField
                                    type="number"
                                    data-cy="average_Daily_Amount_Input"
                                    placeholder='0 kl'
                                    disabled={updatedState['method'] === 'search-reading'}
                                    defaultValue={updatedState['averageDailyAmount']}
                                    onChange={(ev, newText) => this.props.updateValue(newText, 'averageDailyAmount')}
                                />
                            </div>
                        </div>
                    </div>

                    {(updatedState['method'] === 'daily-average' && stateOfCounty === stateName.VIC) &&
                        <>
                            <div className="ms-Grid-row modal-row">
                                <div className="ms-Grid-col ms-sm6"></div>
                                <div className="ms-Grid-col ms-sm6">
                                    <div className="ms-Grid-col ms-sm4">Average Daily Amount charged :</div>
                                    <div className="ms-Grid-col ms-sm8">
                                        <TextField
                                            type="number"
                                            data-cy="average_Daily_Amount_Charged_Input"
                                            placeholder='0 kl'
                                            disabled={updatedState['method'] === 'search-reading'}
                                            defaultValue={updatedState['averageDailyAmountCharge']}
                                            onChange={(ev, newText) => this.props.updateValue(newText, 'averageDailyAmountCharge')}
                                        />
                                    </div>
                                </div>
                            </div>
                        </>}

                    <Separator>
                        <b>Readings</b>
                    </Separator>
                    <div className='ms-Grid-row modal-row'>
                        <div className='ms-Grid-col ms-sm4'>Last Billed Reading :</div>
                        <div className='ms-Grid-col ms-sm4'>
                            <CustomDatePickers
                                disabled={false}
                                updateValue={this.props.updateValue}
                                updatedState={this.props.updatedState}
                                datacy="paid_to_date_input"
                                datePickerName="paidDate"
                            />
                        </div>
                        <div className='ms-Grid-col ms-sm4'>
                            <TextField
                                type="number"
                                data-cy="last_Billed_Reading_Amount_Input"
                                disabled={updatedState['method'] === 'daily-average' && stateOfCounty === stateName.NSW}
                                defaultValue={updatedState['paidReadingAmount']}
                                onChange={(ev, newText) => this.props.updateValue(newText, 'paidReadingAmount')}
                            />
                        </div>
                    </div>

                    <div className='ms-Grid-row modal-row'>
                        {(updatedState['method'] === 'daily-average') && (stateOfCounty === stateName.VIC) ?
                            <>
                                <div className='ms-Grid-col ms-sm4'>Paid To Date :</div>
                                <div className='ms-Grid-col ms-sm4'>
                                    <CustomDatePickers
                                        disabled={false}
                                        updateValue={this.props.updateValue}
                                        updatedState={this.props.updatedState}
                                        datacy="paid_to_date_input"
                                        datePickerName="searchDate"
                                    />
                                </div>
                            </>
                            :
                            <>
                                <div className='ms-Grid-col ms-sm4'>Search Reading :</div>
                                <div className='ms-Grid-col ms-sm4'>
                                    <CustomDatePickers
                                        disabled={updatedState['method'] === 'daily-average'}
                                        updateValue={this.props.updateValue}
                                        updatedState={this.props.updatedState}
                                        datacy="search_date_input"
                                        datePickerName="searchDate"
                                    />
                                </div>
                            </>
                        }

                        <div className='ms-Grid-col ms-sm4'>
                            <TextField
                                type="number"
                                data-cy="search_reading_amount_input"
                                placeholder='0 kl'
                                disabled={updatedState['method'] === 'daily-average'}
                                defaultValue={updatedState['searchReadingAmount']}
                                onChange={(ev, newText) => this.props.updateValue(newText, 'searchReadingAmount')}
                            />
                        </div>
                    </div>

                    <Separator>
                        <b>Charges</b>
                    </Separator>
                    <div className='ms-Grid-row modal-row'>
                        <div className='ms-Grid-col ms-sm3'></div>
                        <div className='ms-Grid-col ms-sm3'>Cost (Per KL)</div>
                        <div className='ms-Grid-col ms-sm3'>KL Count</div>
                        <div className='ms-Grid-col ms-sm3'>Increase (If app from 1st july)</div>
                    </div>

                    <div className='ms-Grid-row modal-row'>
                        <div className='ms-Grid-col ms-sm3'>Base Rate:</div>
                        <div className='ms-Grid-col ms-sm3'>
                            <TextField
                                type="number"
                                data-cy='base_Rate_Cost_Per_KL_Input'
                                placeholder='0'
                                defaultValue={updatedState['baseRateCostPerKL']}
                                onChange={(ev, nextText) => this.props.updateValue(nextText, 'baseRateCostPerKL')}
                            />
                        </div>
                        <div className='ms-Grid-col ms-sm3'>
                            <TextField
                                type="number"
                                data-cy='base_Rate_KL_Count_Input'
                                placeholder='0 KL'
                                defaultValue={updatedState['baseRateKLCount']}
                                onChange={(ev, nextText) => this.props.updateValue(nextText, 'baseRateKLCount')}
                            />
                        </div>
                        <div className='ms-Grid-col ms-sm3'>
                            <TextField
                                type="number"
                                data-cy='base_Rate_Increase_Input'
                                placeholder='0'
                                defaultValue={updatedState['baseRateIncrease']}
                                onChange={(ev, nextText) => this.props.updateValue(nextText, 'baseRateIncrease')}
                            />
                        </div>
                    </div>

                    <div className='ms-Grid-row modal-row'>
                        <div className='ms-Grid-col ms-sm3'>Tier 2 (If app)</div>
                        <div className='ms-Grid-col ms-sm3'>
                            <TextField
                                type="number"
                                data-cy='tier2_Cost_Per_KL_Input'
                                placeholder='0'
                                defaultValue={updatedState['tier2CostPerKL']}
                                onChange={(ev, nextText) => this.props.updateValue(nextText, 'tier2CostPerKL')}
                            />
                        </div>
                        <div className='ms-Grid-col ms-sm3'>
                            <TextField
                                type="number"
                                data-cy='tier2_KL_Count_Input'
                                placeholder='0 KL'
                                defaultValue={updatedState['tier2KLCount']}
                                onChange={(ev, nextText) => this.props.updateValue(nextText, 'tier2KLCount')}
                            />
                        </div>
                        <div className='ms-Grid-col ms-sm3'>
                            <TextField
                                type="number"
                                data-cy='tier2_Fee_Increase_Input'
                                placeholder='0'
                                defaultValue={updatedState['tier2FeeIncrease']}
                                onChange={(ev, nextText) => this.props.updateValue(nextText, 'tier2FeeIncrease')}
                            />
                        </div>
                    </div>

                    <div className='ms-Grid-row modal-row'>
                        <div className='ms-Grid-col ms-sm3'>Tier 3 (If app)</div>
                        <div className='ms-Grid-col ms-sm3'>
                            <TextField
                                type="number"
                                data-cy='tier3_Cost_Per_KL_Input'
                                placeholder='0'
                                defaultValue={updatedState['tier3CostPerKL']}
                                onChange={(ev, nextText) => this.props.updateValue(nextText, 'tier3CostPerKL')}
                            />
                        </div>
                        <div className='ms-Grid-col ms-sm3'>
                            <TextField
                                type="number"
                                data-cy='tier3_KL_Count_Input'
                                placeholder='0 KL'
                                defaultValue={updatedState['tier3KLCount']}
                                onChange={(ev, nextText) => this.props.updateValue(nextText, 'tier3KLCount')}
                            />
                        </div>
                        <div className='ms-Grid-col ms-sm3'>
                            <TextField
                                type="number"
                                data-cy='tier3_Fee_Increase_Input'
                                placeholder='0'
                                defaultValue={updatedState['tier3FeeIncrease']}
                                onChange={(ev, nextText) => this.props.updateValue(nextText, 'tier3FeeIncrease')}
                            />
                        </div>
                    </div>

                    <div className='ms-Grid-row modal-row'>
                        <div className='ms-Grid-col ms-sm3'>Balance Water Charges: </div>
                        <div className='ms-Grid-col ms-sm3'>
                            <TextField
                                type="number"
                                data-cy='balance_Water_Charges_Input'
                                placeholder='0'
                                defaultValue={updatedState['balanceWaterCharges']}
                                onChange={(ev, nextText) => this.props.updateValue(nextText, 'balanceWaterCharges')}
                            />
                        </div>
                        <div className='ms-Grid-col ms-sm3'></div>
                        <div className='ms-Grid-col ms-sm3'>
                            <TextField
                                type="number"
                                data-cy="balance_Water_Charges_Fee_Increase_Input"
                                defaultValue={updatedState['balanceWaterChargesFeeIncrease']}
                                onChange={(ev, newText) => this.props.updateValue(newText, 'balanceWaterChargesFeeIncrease')}
                            />
                        </div>
                    </div>

                    <div className='ms-Grid-row modal-row'>
                        <div className='ms-Grid-col ms-sm3'>State Bulk Water</div>
                        <div className='ms-Grid-col ms-sm3'>
                            <TextField
                                type="number"
                                data-cy='state_Bulk_Water_Charges_Input'
                                placeholder='0'
                                defaultValue={updatedState['stateBulkWaterCharges']}
                                onChange={(ev, nextText) => this.props.updateValue(nextText, 'stateBulkWaterCharges')}
                            />
                        </div>
                        <div className='ms-Grid-col ms-sm3'></div>
                        <div className='ms-Grid-col ms-sm3'>
                            <TextField
                                type="number"
                                data-cy='state_Bulk_Water_Fee_Increase_Input'
                                placeholder='0'
                                defaultValue={updatedState['stateBulkWaterFeeIncrease']}
                                onChange={(ev, nextText) => this.props.updateValue(nextText, 'stateBulkWaterFeeIncrease')}
                            />
                        </div>
                    </div>

                    <Separator>
                        <b>Apportionment</b>
                    </Separator>

                    <div className="ms-Grid-row modal-row">
                        <div className="ms-Grid-col ms-sm3">CTS Option:</div>
                        <div className="ms-Grid-col ms-sm3">
                            <Dropdown
                                data-cy="cts_option_select"
                                id="cts_option_select"
                                options={ctsOptions}
                                defaultSelectedKey={updatedState['ctsOption']}
                                onChange={(ev, item) => this.props.updateValue(item!.key, 'ctsOption', true)}
                            />
                        </div>

                        {updatedState['ctsOption'] === apportionmentOption.sharedPercentage &&

                            <div className="ms-Grid-col ms-sm3">
                                <TextField
                                    type="number"
                                    data-cy="percentage_input"
                                    defaultValue={updatedState['percentage']}
                                    onChange={(ev, newText) => this.props.updateValue(newText, 'percentage')}
                                />
                            </div>
                        }

                        {updatedState['ctsOption'] === apportionmentOption.entitlement &&
                            <div className="ms-Grid-col ms-sm3">
                                <TextField
                                    defaultValue={updatedState['entitlementValue']}
                                    placeholder='Lot Allocation/Aggregate'
                                    onChange={(ev, newText) => this.props.updateValue(newText, 'entitlementValue')}
                                />
                            </div>
                        }
                    </div>

                    <div className='ms-Grid-row modal-row'>
                        <div className='ms-Grid-col ms-sm3'>Special Water Meter Reading Charges (to appear on future bill)</div>
                        <div className='ms-Grid-col ms-sm3'>
                            <TextField
                                data-cy='special_Water_Meter_Reading_Charges'
                                placeholder='Lot Allocation/Aggregate'
                                defaultValue={updatedState['specialWaterMeterReadingCharges']}
                                onChange={(ev, nextText) => this.props.updateValue(nextText, 'specialWaterMeterReadingCharges')}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}