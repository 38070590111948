import React from "react";
import { AppState } from 'app.types';
import { DatePicker } from 'office-ui-fabric-react/lib/DatePicker';
import { connect } from 'react-redux';
import { getReportData, reportDataClearState } from './redux/actions';
import { ECAFReportQuery, ECAFReportResponse, ECAFReportResponseEntry } from "utils/newWcaApiTypes";
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import * as CONSTANTS from 'containers/ecaf/redux/constants';
import { PrimaryButton } from "@fluentui/react";

interface IAppProps {
}

type AppStates = {
	startDate: Date,
	endDate: Date,
	error: string | undefined,
	isLoading: boolean,
}

type AppProps = IAppProps & ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

class ReportPage extends React.Component<AppProps, AppStates> {
	constructor(props: Readonly<AppProps>) {
		super(props);
		let today = new Date();
		let lastMonth = new Date();
		lastMonth.setMonth(lastMonth.getMonth() - 1);

		this.state = {
			startDate: lastMonth,
			endDate: today,
			error: undefined,
			isLoading: false,
		}

		this.onGenerateReport = this.onGenerateReport.bind(this);
	}

	static getDerivedStateFromProps(nextProps: AppProps, prevState: AppStates): AppStates {
		let nextState = {} as AppStates;

		if (nextProps.gotResponse) {
			switch (nextProps.requestType) {
				case CONSTANTS.GET_REPORT_DATA_REQUESTED: {
					break;
				}
				case CONSTANTS.GET_REPORT_DATA_SUCCESS: {
					nextState.isLoading = false;
					nextState.error = ReportPage.saveAsCSV(nextProps.reportData, prevState.startDate.toLocaleDateString('en-GB'), prevState.endDate.toLocaleDateString('en-GB'));
					nextProps.reportDataClearState();
					break;
				}
				case CONSTANTS.GET_REPORT_DATA_FAILED: {
					nextState.isLoading = false;
					nextState.error = "Generate csv failed";
					nextProps.reportDataClearState();
					break;
				}
				case CONSTANTS.GET_REPORT_DATA_CLEAR_STATE: {
					nextState.isLoading = false;
					break;
				}
				default:
					return nextState;
			}
		}
		return nextState;
	}

	private static saveAsCSV(data: ECAFReportResponse | undefined, start: string, end: string | undefined): string | undefined {
		if (!data || data.reportEntries.length <= 0) {
			return 'No orders available during this time period';
		}

		let csvContent = "data:text/csv;charset=utf-8,";
		let csvBody = "";

		csvBody += ReportPage.renderHeader();

		for (const entry of data!.reportEntries) {
			csvBody += ReportPage.renderRows(entry);
		}

		let filename = `User report ${start} to ${end}.csv`;

		// use encode uri component for the body only to allow downloading csv with special characters like #
		csvContent += encodeURIComponent(csvBody);

		const link = document.createElement('a');
		link.setAttribute('href', csvContent);
		link.setAttribute('download', filename);
		document.body.appendChild(link); // Required for FF
		link.click();
		document.body.removeChild(link);
		return undefined;
	}

	private static renderHeader() {
		const dataArray = new Array;

		dataArray.push("Matter ID");
		dataArray.push("Envelope ID");
		dataArray.push("Description");
		dataArray.push("Disbursement Amount");

		return dataArray.join(',') + '\r\n';
	}

	private static renderRows(entry: ECAFReportResponseEntry): string {
		const dataArray = new Array;

		dataArray.push(entry.matterId ? entry.matterId : "");
		dataArray.push(entry.envelopeId ? entry.envelopeId : "");
		// dataArray.push(entry.orderDescription ? entry.orderDescription : "");
		dataArray.push(`"${entry.customers?.map(customer => `${customer.firstName} ${customer.lastName}`).join(", ")}"` ?? "");
		dataArray.push(entry.disbursementAmount ? entry.disbursementAmount : "");

		return dataArray.join(',') + '\r\n';
	}

	private onGenerateReport() {
		const { actionstepContext } = this.props;
		if (this.state.startDate > this.state.endDate) {
			this.setState({
				error: "Start date cannot be after end date"
			});
			return;
		}

		this.setState({
			error: undefined,
			isLoading: true
		});

		this.props.getReportData(new ECAFReportQuery({
			dateStartUtc: this.state.startDate,
			dateEndUtc: this.state.endDate,
			orgKey: actionstepContext?.orgKey ?? ''
		}));
	};

	render() {
		return <div>
			<table>
				<tbody>
					<tr>
						<td><b>Generate user report</b></td>
					</tr>
					<tr>
						<td>Select a start date:</td>
						<td><DatePicker value={this.state.startDate} onSelectDate={date => this.setState({ startDate: date ?? new Date() })} /></td>
					</tr>
					<tr>
						<td>Select a end date:</td>
						<td><DatePicker value={this.state.endDate} onSelectDate={date => this.setState({ endDate: date ?? new Date() })} /></td>
					</tr>

				</tbody>
			</table>
			<div>
				{this.state.isLoading ? <Spinner size={SpinnerSize.large} /> : <PrimaryButton
					className="button"
					data-automation-id="use_new_button"
					data-cy="use_new_button"
					text="Generate"
					onClick={this.onGenerateReport}
					allowDisabledFocus={true}
				/>}
				<span style={{ marginLeft: '20px' }}>{this.state.error}</span>
			</div>
		</div>
	}
}

const mapStateToProps = (state: AppState) => {
	return {
		gotResponse: state.eCaf.gotResponse,
		requestType: state.eCaf.requestType,
		reportData: state.eCaf.reportData,
		actionstepContext: state.common.actionstepContext,
	}
}

const mapDispatchToProps = {
	getReportData,
	reportDataClearState
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportPage);