import * as React from 'react'
import { formatToLocaleDateString, toFormatLocalString } from './Helper';
import { stateName, waterUsagesMethod } from './common';

interface IMapStateToProps { }
interface IMapDispatchToProps { }
interface IProps {
    waterUsage: { [key: string]: any; };
    state: any;
}

type AppProps = IMapStateToProps & IProps & IMapDispatchToProps;
type AppStates = {}

export default class WaterUsageSection extends React.Component<AppProps, AppStates> {

    public render(): JSX.Element {
        const { waterUsage } = this.props;

        if (waterUsage["showJulyCalculation"] === true && waterUsage["method"] === waterUsagesMethod.DailyAverage && this.props.state === stateName.NSW) {
            return (
                <>
                    {getHeadSection(this.props.state, waterUsage)}

                    <div className="section">
                        <div className="section-body">
                            <div className="ms-Grid-row state-row-no-padding">
                                <div className="ms-Grid-col ms-sm4">
                                    <b>Water Adjustment</b>
                                </div>
                            </div>

                            <div className="ms-Grid-row state-row-no-padding">
                                <div className="ms-Grid-col ms-sm6">
                                    <span>Base Rate</span>
                                </div>
                                <div className="ms-Grid-col ms-sm4">
                                    ${waterUsage['baseRateCostPerKL']} x {waterUsage['dailyUsage']}kl x {waterUsage['numberOfDaysToJune']} days
                                </div>
                                <div className="ms-Grid-col ms-sm2  content-right-align">
                                    {toFormatLocalString(waterUsage["baseRateResult30June"], "en-AU", 2, 2)}
                                </div>
                            </div>

                            <div className="ms-Grid-row state-row-no-padding">
                                <div className="ms-Grid-col ms-sm4">
                                    <b>from 1 July</b>
                                </div>
                            </div>

                            <div className="ms-Grid-row state-row-no-padding">
                                <div className="ms-Grid-col ms-sm6">
                                    <span>Base Rate</span>
                                </div>
                                <div className="ms-Grid-col ms-sm4">
                                    ${waterUsage['baseRateIncrease']} x {waterUsage['dailyUsage']}kl x {waterUsage['numberOfDaysToJuly']} days
                                </div>
                                <div className="ms-Grid-col ms-sm2  content-right-align">
                                    {toFormatLocalString(waterUsage["baseRateResult"], "en-AU", 2, 2)}
                                </div>
                            </div>

                            <div className="ms-Grid-col ms-sm12 separator">
                                --------------<br />
                                <b>{waterUsage['ctsOption'] === "entitlement"
                                    ? `$${toFormatLocalString(waterUsage['someOfResultIncludingJuly'], "en-AU", 2, 2)} x ${waterUsage['entitlementValue']}  = $${toFormatLocalString(waterUsage['finalWaterUsageResult'], "en-AU", 2, 2)}`
                                    : waterUsage['ctsOption'] === "shared-percentage"
                                        ? `$${toFormatLocalString(waterUsage['someOfResultIncludingJuly'], "en-AU", 2, 2)} x ${waterUsage['percentage']}% = $${toFormatLocalString(waterUsage['finalWaterUsageResult'], "en-AU", 2, 2)}`
                                        : `$${toFormatLocalString(waterUsage['finalWaterUsageResult'], "en-AU", 2, 2)}`
                                }</b><br />
                                --------------
                            </div>
                        </div>
                    </div>
                </>
            )
        }

        else if (waterUsage["showJulyCalculation"] === true && waterUsage["method"] === waterUsagesMethod.SearchReading && this.props.state === stateName.NSW) {
            return (
                <>
                    {getHeadSection(this.props.state, waterUsage)}

                    <div className="section">
                        <div className="section-body">
                            <div className="ms-Grid-row state-row-no-padding">
                                <div className="ms-Grid-col ms-sm4">
                                    <b>Water Adjustment</b>
                                </div>
                            </div>

                            <div className="ms-Grid-row state-row-no-padding">
                                <div className="ms-Grid-col ms-sm6">
                                    <span>Base Rate</span>
                                </div>
                                <div className="ms-Grid-col ms-sm4">
                                    {waterUsage["dailyAndDays"]}kl x ${waterUsage['baseRateCostPerKL']} x {waterUsage['numberOfDaysToJune']}/{waterUsage['numberOfDaysFromJune']} days
                                </div>
                                <div className="ms-Grid-col ms-sm2 content-right-align">
                                    {toFormatLocalString(waterUsage["baseRateResult30June"], "en-AU", 2, 2)}
                                </div>
                            </div>

                            <div className="ms-Grid-row state-row-no-padding">
                                <div className="ms-Grid-col ms-sm4">
                                    <b>from 1 July</b>
                                </div>
                            </div>

                            <div className="ms-Grid-row state-row-no-padding">
                                <div className="ms-Grid-col ms-sm6">
                                    <span>Base Rate</span>
                                </div>
                                <div className="ms-Grid-col ms-sm4">
                                    {waterUsage["dailyAndDays"]}kl x ${waterUsage['baseRateIncrease']} x {waterUsage['numberOfDaysToJuly']}/{waterUsage['numberOfDaysFromJune']} days
                                </div>
                                <div className="ms-Grid-col ms-sm2 content-right-align">
                                    {toFormatLocalString(waterUsage["baseRateResult"], "en-AU", 2, 2)}
                                </div>
                            </div>

                            <div className="ms-Grid-col ms-sm12 separator">
                                --------------<br />
                                <b>{waterUsage['ctsOption'] === "entitlement"
                                    ? `$${toFormatLocalString(waterUsage['someOfResultIncludingJuly'], "en-AU", 2, 2)} x ${waterUsage['entitlementValue']}  = $${toFormatLocalString(waterUsage['finalWaterUsageResult'], "en-AU", 2, 2)}`
                                    : waterUsage['ctsOption'] === "shared-percentage"
                                        ? `$${toFormatLocalString(waterUsage['someOfResultIncludingJuly'], "en-AU", 2, 2)} x ${waterUsage['percentage']}% = $${toFormatLocalString(waterUsage['finalWaterUsageResult'], "en-AU", 2, 2)}`
                                        : `$${toFormatLocalString(waterUsage['finalWaterUsageResult'], "en-AU", 2, 2)}`
                                }</b><br />
                                --------------
                            </div>
                        </div>
                    </div>
                </>
            )
        }

        else if (waterUsage["showJulyCalculation"] === true) {
            return (
                <>
                    {getHeadSection(this.props.state, waterUsage)}

                    <div className="section">
                        <div className="section-body">
                            <div className="ms-Grid-row state-row-no-padding">
                                <div className="ms-Grid-col ms-sm4">
                                    <b>Water Adjustment</b>
                                </div>
                            </div>

                            <div className="ms-Grid-row state-row-no-padding">
                                <div className="ms-Grid-col ms-sm6">
                                    <span>Base Rate</span>
                                </div>
                                <div className="ms-Grid-col ms-sm4">
                                    {waterUsage["adjustedBaseRateKLCount"]}kl x {waterUsage['numberOfDaysToJune']}/{waterUsage['numberOfDaysFromJune']} days x ${waterUsage['baseRateCostPerKL']}
                                </div>
                                <div className="ms-Grid-col ms-sm2 content-right-align">
                                    {toFormatLocalString(waterUsage["baseRateResult30June"], "en-AU", 2, 2)}
                                </div>
                            </div>

                            <div className="ms-Grid-row state-row-no-padding">
                                <div className="ms-Grid-col ms-sm6">
                                    <span>Tier 2</span>
                                </div>
                                <div className="ms-Grid-col ms-sm4">
                                    {waterUsage["adjustedTier2KLCount"]}kl x {waterUsage['numberOfDaysToJune']}/{waterUsage['numberOfDaysFromJune']} days x ${waterUsage['tier2CostPerKL']}
                                </div>
                                <div className="ms-Grid-col ms-sm2 content-right-align">
                                    {toFormatLocalString(waterUsage["tier2Result30June"], "en-AU", 2, 2)}
                                </div>
                            </div>

                            <div className="ms-Grid-row state-row-no-padding">
                                <div className="ms-Grid-col ms-sm6">
                                    <span>Tier 3</span>
                                </div>
                                <div className="ms-Grid-col ms-sm4">
                                    {waterUsage["adjustedTier3KLCount"]}kl x {waterUsage['numberOfDaysToJune']}/{waterUsage['numberOfDaysFromJune']} days x ${waterUsage['tier3CostPerKL']}
                                </div>
                                <div className="ms-Grid-col ms-sm2 content-right-align">
                                    {toFormatLocalString(waterUsage["tier3Result30June"], "en-AU", 2, 2)}
                                </div>
                            </div>

                            <div className="ms-Grid-row state-row-no-padding">
                                <div className="ms-Grid-col ms-sm6">
                                    <span>Balance</span>
                                </div>
                                <div className="ms-Grid-col ms-sm4">
                                    {waterUsage["balanceCalc"]}kl x {waterUsage['numberOfDaysToJune']}/{waterUsage['numberOfDaysFromJune']} days x ${waterUsage['balanceWaterCharges']}
                                </div>
                                <div className="ms-Grid-col ms-sm2 content-right-align">
                                    {toFormatLocalString(waterUsage["balanceResult30June"], "en-AU", 2, 2)}
                                </div>
                            </div>

                            <div className="ms-Grid-row state-row-no-padding">
                                <div className="ms-Grid-col ms-sm6">
                                    <span>Bulk water</span>
                                </div>
                                <div className="ms-Grid-col ms-sm4">
                                    {waterUsage["stateBulkWaterUsagesKLJune"]}kl x {waterUsage['numberOfDaysToJune']}/{waterUsage['numberOfDaysFromJune']} days x ${waterUsage['stateBulkWaterCharges']}
                                </div>
                                <div className="ms-Grid-col ms-sm2 content-right-align">
                                    {toFormatLocalString(waterUsage["bulkResult30June"], "en-AU", 2, 2)}
                                </div>
                            </div>

                            <div className="ms-Grid-row state-row-no-padding">
                                <div className="ms-Grid-col ms-sm4">
                                    <b>from 1 July</b>
                                </div>
                            </div>

                            <div className="ms-Grid-row state-row-no-padding">
                                <div className="ms-Grid-col ms-sm6">
                                    <span>Base Rate</span>
                                </div>
                                <div className="ms-Grid-col ms-sm4">
                                    {waterUsage["adjustedBaseRateKLCount"]}kl x {waterUsage['numberOfDaysToJuly']}/{waterUsage['numberOfDaysFromJune']} days x ${waterUsage['baseRateIncrease']}
                                </div>
                                <div className="ms-Grid-col ms-sm2 content-right-align">
                                    {toFormatLocalString(waterUsage["baseRateResult"], "en-AU", 2, 2)}
                                </div>
                            </div>

                            <div className="ms-Grid-row state-row-no-padding">
                                <div className="ms-Grid-col ms-sm6">
                                    <span>Tier 2</span>
                                </div>
                                <div className="ms-Grid-col ms-sm4">
                                    {waterUsage["adjustedTier2KLCount"]}kl x {waterUsage['numberOfDaysToJuly']}/{waterUsage['numberOfDaysFromJune']} days x ${waterUsage['tier2FeeIncrease']}
                                </div>
                                <div className="ms-Grid-col ms-sm2 content-right-align">
                                    {toFormatLocalString(waterUsage["tier2Result"], "en-AU", 2, 2)}
                                </div>
                            </div>

                            <div className="ms-Grid-row state-row-no-padding">
                                <div className="ms-Grid-col ms-sm6">
                                    <span>Tier 3</span>
                                </div>
                                <div className="ms-Grid-col ms-sm4">
                                    {waterUsage["adjustedTier3KLCount"]}kl x {waterUsage['numberOfDaysToJuly']}/{waterUsage['numberOfDaysFromJune']} days x ${waterUsage['tier3FeeIncrease']}
                                </div>
                                <div className="ms-Grid-col ms-sm2 content-right-align">
                                    {toFormatLocalString(waterUsage["tier3Result"], "en-AU", 2, 2)}
                                </div>
                            </div>

                            <div className="ms-Grid-row state-row-no-padding">
                                <div className="ms-Grid-col ms-sm6">
                                    <span>Balance</span>
                                </div>
                                <div className="ms-Grid-col ms-sm4">
                                    {waterUsage["balanceCalc"]}kl x {waterUsage['numberOfDaysToJuly']}/{waterUsage['numberOfDaysFromJune']} days x ${waterUsage['balanceWaterChargesFeeIncrease']}
                                </div>
                                <div className="ms-Grid-col ms-sm2 content-right-align">
                                    {toFormatLocalString(waterUsage["balanceResult"], "en-AU", 2, 2)}
                                </div>
                            </div>

                            <div className="ms-Grid-row state-row-no-padding">
                                <div className="ms-Grid-col ms-sm6">
                                    <span>Bulk water</span>
                                </div>
                                <div className="ms-Grid-col ms-sm4">
                                    {waterUsage["stateBulkWaterUsagesKLJuly"]}kl x {waterUsage['numberOfDaysToJuly']}/{waterUsage['numberOfDaysFromJune']} days x ${waterUsage['stateBulkWaterFeeIncrease']}
                                </div>
                                <div className="ms-Grid-col ms-sm2 content-right-align">
                                    {toFormatLocalString(waterUsage["bulkResult"], "en-AU", 2, 2)}
                                </div>
                            </div>

                            <div className="ms-Grid-col ms-sm12 separator">
                                --------------<br />
                                <b>{waterUsage['ctsOption'] === "entitlement"
                                    ? `$${toFormatLocalString(waterUsage['someOfResultIncludingJuly'], "en-AU", 2, 2)} x ${waterUsage['entitlementValue']}  = $${toFormatLocalString(waterUsage['finalWaterUsageResult'], "en-AU", 2, 2)}`
                                    : waterUsage['ctsOption'] === "shared-percentage"
                                        ? `$${toFormatLocalString(waterUsage['someOfResultIncludingJuly'], "en-AU", 2, 2)} x ${waterUsage['percentage']}% = $${toFormatLocalString(waterUsage['finalWaterUsageResult'], "en-AU", 2, 2)}`
                                        : `$${toFormatLocalString(waterUsage['finalWaterUsageResult'], "en-AU", 2, 2)}`
                                }</b><br />
                                --------------
                            </div>
                        </div>
                    </div>
                </>
            )
        }

        else if (waterUsage["showJulyCalculation"] === false && waterUsage["method"] === waterUsagesMethod.DailyAverage && this.props.state === stateName.NSW) {
            return (
                <>
                    {getHeadSection(this.props.state, waterUsage)}

                    <div className="section">
                        <div className="section-body">
                            <div className="ms-Grid-row state-row-no-padding">
                                <div className="ms-Grid-col ms-sm4">
                                    <b>Water Adjustment</b>
                                </div>
                            </div>

                            <div className="ms-Grid-row state-row-no-padding">
                                <div className="ms-Grid-col ms-sm6">
                                    <span>Base Rate</span>
                                </div>
                                <div className="ms-Grid-col ms-sm4">
                                    ${waterUsage['baseRateCostPerKL']} x {waterUsage['dailyUsage']} x {waterUsage['partDays']} days
                                </div>
                                <div className="ms-Grid-col ms-sm2 content-right-align">
                                    {toFormatLocalString(waterUsage["baseRateResult"], "en-AU", 2, 2)}
                                </div>
                            </div>

                            <div className="ms-Grid-col ms-sm12 separator">
                                --------------<br />
                                <b>{waterUsage['ctsOption'] === "entitlement"
                                    ? `$${toFormatLocalString(waterUsage['someOfResultExcludingJuly'], "en-AU", 2, 2)} x ${waterUsage['entitlementValue']}  = $${toFormatLocalString(waterUsage['finalWaterUsageResult'], "en-AU", 2, 2)}`
                                    : waterUsage['ctsOption'] === "shared-percentage"
                                        ? `$${toFormatLocalString(waterUsage['someOfResultExcludingJuly'], "en-AU", 2, 2)} x ${waterUsage['percentage']}% = $${toFormatLocalString(waterUsage['finalWaterUsageResult'], "en-AU", 2, 2)}`
                                        : `$${toFormatLocalString(waterUsage['finalWaterUsageResult'], "en-AU", 2, 2)}`
                                }</b><br />
                                --------------
                            </div>
                        </div>
                    </div>
                </>
            )
        }

        else if (waterUsage["showJulyCalculation"] === false && waterUsage["method"] === waterUsagesMethod.SearchReading && this.props.state === stateName.NSW) {
            return (
                <>
                    {getHeadSection(this.props.state, waterUsage)}

                    <div className="section">
                        <div className="section-body">
                            <div className="ms-Grid-row state-row-no-padding">
                                <div className="ms-Grid-col ms-sm4">
                                    <b>Water Adjustment</b>
                                </div>
                            </div>

                            <div className="ms-Grid-row state-row-no-padding">
                                <div className="ms-Grid-col ms-sm6">
                                    <span>Base Rate</span>
                                </div>
                                <div className="ms-Grid-col ms-sm4">
                                    ${waterUsage['baseRateCostPerKL']} x {waterUsage['dailyAndDays']}kl
                                </div>
                                <div className="ms-Grid-col ms-sm2 content-right-align">
                                    {toFormatLocalString(waterUsage["baseRateResult"], "en-AU", 2, 2)}
                                </div>
                            </div>

                            <div className="ms-Grid-col ms-sm12 separator">
                                --------------<br />
                                <b>{waterUsage['ctsOption'] === "entitlement"
                                    ? `$${toFormatLocalString(waterUsage['someOfResultExcludingJuly'], "en-AU", 2, 2)} x ${waterUsage['entitlementValue']}  = $${toFormatLocalString(waterUsage['finalWaterUsageResult'], "en-AU", 2, 2)}`
                                    : waterUsage['ctsOption'] === "shared-percentage"
                                        ? `$${toFormatLocalString(waterUsage['someOfResultExcludingJuly'], "en-AU", 2, 2)} x ${waterUsage['percentage']}% = $${toFormatLocalString(waterUsage['finalWaterUsageResult'], "en-AU", 2, 2)}`
                                        : `$${toFormatLocalString(waterUsage['finalWaterUsageResult'], "en-AU", 2, 2)}`
                                }</b><br />
                                --------------
                            </div>
                        </div>
                    </div>
                </>
            )
        }

        else if (waterUsage["showJulyCalculation"] === false && waterUsage["method"] === waterUsagesMethod.DailyAverage && this.props.state === stateName.QLD) {
            return (
                <>
                    {getHeadSection(this.props.state, waterUsage)}

                    <div className="section">
                        <div className="section-body">
                            <div className="ms-Grid-row state-row-no-padding">
                                <div className="ms-Grid-col ms-sm4">
                                    <b>Water Adjustment</b>
                                </div>
                            </div>
                            <div className="ms-Grid-row state-row-no-padding">
                                <div className="ms-Grid-col ms-sm6">
                                    <span>Base Rate</span>
                                </div>
                                <div className="ms-Grid-col ms-sm4">
                                    {waterUsage["adjustedBaseRateKLCount"]}kl x ${waterUsage['baseRateCostPerKL']}
                                </div>
                                <div className="ms-Grid-col ms-sm2 content-right-align">
                                    {toFormatLocalString(waterUsage["baseRateResult"], "en-AU", 2, 2)}
                                </div>
                            </div>

                            <div className="ms-Grid-row state-row-no-padding">
                                <div className="ms-Grid-col ms-sm6">
                                    <span>Tier 2</span>
                                </div>
                                <div className="ms-Grid-col ms-sm4">
                                    {waterUsage["adjustedTier2KLCount"]}kl x ${waterUsage['tier2CostPerKL']}
                                </div>
                                <div className="ms-Grid-col ms-sm2 content-right-align">
                                    {toFormatLocalString(waterUsage["tier2Result"], "en-AU", 2, 2)}
                                </div>
                            </div>

                            <div className="ms-Grid-row state-row-no-padding">
                                <div className="ms-Grid-col ms-sm6">
                                    <span>Tier 3</span>
                                </div>
                                <div className="ms-Grid-col ms-sm4">
                                    {waterUsage["adjustedTier3KLCount"]}kl x ${waterUsage['tier3CostPerKL']}
                                </div>
                                <div className="ms-Grid-col ms-sm2 content-right-align">
                                    {toFormatLocalString(waterUsage["tier3Result"], "en-AU", 2, 2)}
                                </div>
                            </div>

                            <div className="ms-Grid-row state-row-no-padding">
                                <div className="ms-Grid-col ms-sm6">
                                    <span>Balance</span>
                                </div>
                                <div className="ms-Grid-col ms-sm4">
                                    {waterUsage["balanceCalc"]}kl x ${waterUsage['balanceWaterCharges']}
                                </div>
                                <div className="ms-Grid-col ms-sm2 content-right-align">
                                    {toFormatLocalString(waterUsage["balanceResult"], "en-AU", 2, 2)}
                                </div>
                            </div>

                            <div className="ms-Grid-row state-row-no-padding">
                                <div className="ms-Grid-col ms-sm6">
                                    <span>Bulk water</span>
                                </div>
                                <div className="ms-Grid-col ms-sm4">
                                    {waterUsage["stateBulkWaterUsagesKLJune"]}kl x ${waterUsage['stateBulkWaterCharges']}
                                </div>
                                <div className="ms-Grid-col ms-sm2 content-right-align">
                                    {toFormatLocalString(waterUsage["bulkResult"], "en-AU", 2, 2)}
                                </div>
                            </div>
                            <div className="ms-Grid-col ms-sm12 separator">
                                --------------<br />
                                <b>{waterUsage['ctsOption'] === "entitlement"
                                    ? `$${toFormatLocalString(waterUsage['someOfResultExcludingJuly'], "en-AU", 2, 2)} x ${waterUsage['entitlementValue']}  = $${toFormatLocalString(waterUsage['finalWaterUsageResult'], "en-AU", 2, 2)}`
                                    : waterUsage['ctsOption'] === "shared-percentage"
                                        ? `$${toFormatLocalString(waterUsage['someOfResultExcludingJuly'], "en-AU", 2, 2)} x ${waterUsage['percentage']}% = $${toFormatLocalString(waterUsage['finalWaterUsageResult'], "en-AU", 2, 2)}`
                                        : `$${toFormatLocalString(waterUsage['finalWaterUsageResult'], "en-AU", 2, 2)}`
                                }</b><br />
                                --------------
                            </div>
                        </div>
                    </div>
                </>
            )
        }

        else {
            return (
                <>
                    {getHeadSection(this.props.state, waterUsage)}

                    <div className="section">
                        <div className="section-body">
                            <div className="ms-Grid-row state-row-no-padding">
                                <div className="ms-Grid-col ms-sm4">
                                    <b>Water Adjustment</b>
                                </div>
                            </div>
                            <div className="ms-Grid-row state-row-no-padding">
                                <div className="ms-Grid-col ms-sm6">
                                    <span>Base Rate</span>
                                </div>
                                <div className="ms-Grid-col ms-sm4">
                                    {waterUsage["adjustedBaseRateKLCount"]}kl x ${waterUsage['baseRateCostPerKL']}
                                </div>
                                <div className="ms-Grid-col ms-sm2 content-right-align">
                                    {toFormatLocalString(waterUsage["baseRateResult"], "en-AU", 2, 2)}
                                </div>
                            </div>

                            <div className="ms-Grid-row state-row-no-padding">
                                <div className="ms-Grid-col ms-sm6">
                                    <span>Tier 2</span>
                                </div>
                                <div className="ms-Grid-col ms-sm4">
                                    {waterUsage["adjustedTier2KLCount"]}kl x ${waterUsage['tier2CostPerKL']}
                                </div>
                                <div className="ms-Grid-col ms-sm2 content-right-align">
                                    {toFormatLocalString(waterUsage["tier2Result"], "en-AU", 2, 2)}
                                </div>
                            </div>

                            <div className="ms-Grid-row state-row-no-padding">
                                <div className="ms-Grid-col ms-sm6">
                                    <span>Tier 3</span>
                                </div>
                                <div className="ms-Grid-col ms-sm4">
                                    {waterUsage["adjustedTier3KLCount"]}kl x ${waterUsage['tier3CostPerKL']}
                                </div>
                                <div className="ms-Grid-col ms-sm2 content-right-align">
                                    {toFormatLocalString(waterUsage["tier3Result"], "en-AU", 2, 2)}
                                </div>
                            </div>

                            <div className="ms-Grid-row state-row-no-padding">
                                <div className="ms-Grid-col ms-sm6">
                                    <span>Balance</span>
                                </div>
                                <div className="ms-Grid-col ms-sm4">
                                    {waterUsage["balanceCalc"]}kl x ${waterUsage['balanceWaterCharges']}
                                </div>
                                <div className="ms-Grid-col ms-sm2 content-right-align">
                                    {toFormatLocalString(waterUsage["balanceResult"], "en-AU", 2, 2)}
                                </div>
                            </div>

                            <div className="ms-Grid-row state-row-no-padding">
                                <div className="ms-Grid-col ms-sm6">
                                    <span>Bulk water</span>
                                </div>
                                <div className="ms-Grid-col ms-sm4">
                                    {waterUsage["stateBulkWaterUsagesKLJune"]}kl x ${waterUsage['stateBulkWaterCharges']}
                                </div>
                                <div className="ms-Grid-col ms-sm2 content-right-align">
                                    {toFormatLocalString(waterUsage["bulkResult"], "en-AU", 2, 2)}
                                </div>
                            </div>
                            <div className="ms-Grid-col ms-sm12 separator">
                                --------------<br />
                                <b>{waterUsage['ctsOption'] === "entitlement"
                                    ? `$${toFormatLocalString(waterUsage['someOfResultExcludingJuly'], "en-AU", 2, 2)} x ${waterUsage['entitlementValue']}  = $${toFormatLocalString(waterUsage['finalWaterUsageResult'], "en-AU", 2, 2)}`
                                    : waterUsage['ctsOption'] === "shared-percentage"
                                        ? `$${toFormatLocalString(waterUsage['someOfResultExcludingJuly'], "en-AU", 2, 2)} x ${waterUsage['percentage']}% = $${toFormatLocalString(waterUsage['finalWaterUsageResult'], "en-AU", 2, 2)}`
                                        : `$${toFormatLocalString(waterUsage['finalWaterUsageResult'], "en-AU", 2, 2)}`
                                }</b><br />
                                --------------
                            </div>
                        </div>
                    </div>
                </>
            )
        }
    }
}

const getHeadSection = (stateOfCountry: string, waterUsage: any) => {
    var isNotNsw = stateOfCountry !== stateName.NSW;
    return (
        <>
            <div className="section">
                <div className="left-align-section-header section-body">
                    <b>{waterUsage["type"] === "Water Usage" ? "WATER USAGE" : "RECYCLED WATER"} CALCULATION</b>
                </div>

                <div className="ms-Grid-row state-row-no-padding">
                    <div className="ms-Grid-col ms-sm6">
                        Date Water Paid To: {formatToLocaleDateString(waterUsage["paidDate"])}
                    </div>
                    <div className="ms-Grid-col ms-sm6">
                        Reading: {waterUsage["paidReadingAmount"]}kl
                    </div>
                </div>

                <div className="ms-Grid-row state-row-no-padding">
                    <div className="ms-Grid-col ms-sm6">
                        Date Of Search Reading: {waterUsage['searchDate'] && formatToLocaleDateString(waterUsage['searchDate'])}
                    </div>
                    <div className="ms-Grid-col ms-sm6">
                        Reading: {waterUsage["searchReadingAmount"]}kl
                    </div>
                </div>
            </div>

            <div className="section">
                <div className="ms-Grid-row state-row-no-padding">
                    <div className="ms-Grid-col ms-sm6">
                        <b>Average Daily Usage</b>
                    </div>
                    <div className="ms-Grid-col ms-sm6">
                        <b>Charge Per kl</b>
                    </div>
                </div>

                {isNotNsw && <div className="ms-Grid-row state-row-no-padding">
                    <div className="ms-Grid-col ms-sm6"></div>
                    <div className="ms-Grid-col ms-sm6">
                        ${waterUsage['baseRateCostPerKL']} for first {waterUsage['baseRateKLCount']}kl
                    </div>
                </div>}

                <div className="ms-Grid-row state-row-no-padding">
                    {waterUsage["method"] !== "daily-average" ?
                        <div className="ms-Grid-col ms-sm6">
                            Days between readings: {waterUsage['numberOfDays'].toLocaleString("en-AU", { maximumFractionDigits: 0 })}
                        </div>
                        :
                        <div className="ms-Grid-col ms-sm6"></div>
                    }
                    <div className="ms-Grid-col ms-sm6">
                        {isNotNsw && <span>${waterUsage['tier2CostPerKL']} for next {waterUsage['tier2KLCount']}kl</span>}
                    </div>
                </div>

                <div className="ms-Grid-row state-row-no-padding">
                    <div className="ms-Grid-col ms-sm6">
                        {waterUsage["method"] === "daily-average"
                            // ? <b> ${toFormatLocalString(waterUsage["averageDailyAmount"], "en-AU", 3, 3)}</b>
                            // : <b> ${toFormatLocalString(waterUsage["searchReadingAmount"], "en-AU", 3, 3)}kl - ${toFormatLocalString(waterUsage["paidReadingAmount"], "en-AU", 3, 3)}kl = ${toFormatLocalString(waterUsage["diffAmountReading"], "en-AU", 3, 3)}kl / ${waterUsage["numberOfDays"].toLocaleString("en-AU", { maximumFractionDigits: 0 })} = ${toFormatLocalString(waterUsage["dailyUsage"], "en-AU", 3, 3)}kl</b>
                            ? <> Average Daily Amount: <b> {`${waterUsage["averageDailyAmount"]}`}kl </b></>
                            : <> Search Reading Amount: <br /><b> {`${waterUsage["searchReadingAmount"]}kl - ${waterUsage["paidReadingAmount"]}kl = ${waterUsage["diffAmountReading"]}kl / ${waterUsage["numberOfDays"].toLocaleString("en-AU", { maximumFractionDigits: 0 })} = ${waterUsage["dailyUsage"]}`}kl </b></>
                        }
                    </div>
                    <div className="ms-Grid-col ms-sm6">
                        {isNotNsw ? <span> ${waterUsage['tier3CostPerKL']} for next {waterUsage['tier3KLCount']}kl</span> : <span>${waterUsage['baseRateCostPerKL']} for first {waterUsage['baseRateKLCount']}kl</span>}
                    </div>
                </div>

                <div className="ms-Grid-row state-row-no-padding">
                    <div className="ms-Grid-col ms-sm6">
                        Days from Date Paid to Settlement = {waterUsage['partDays'].toLocaleString("en-AU", { maximumFractionDigits: 0 })}
                    </div>
                    <div className="ms-Grid-col ms-sm6">
                        {isNotNsw && <span>${waterUsage['balanceWaterCharges']} for the Balance</span>}
                    </div>
                </div>

                <div className="ms-Grid-row state-row-no-padding">
                    <div className="ms-Grid-col ms-sm6">
                        {waterUsage["dailyUsage"]}kl x {waterUsage["partDays"].toLocaleString("en-AU", { maximumFractionDigits: 0 })} days = <b>{waterUsage["dailyAndDays"]}kl</b>
                    </div>
                    <div className="ms-Grid-col ms-sm6">
                        {isNotNsw && <span>Bulk water ${waterUsage['stateBulkWaterCharges']}</span>}
                    </div>
                </div>

                {/* <div className="ms-Grid-row state-row-no-padding">
                    <div className="ms-Grid-col ms-sm6">
                        (All kl results are rounded to whole litres ie 3 decimal places)
                    </div>
                </div> */}
            </div>
        </>
    )
}