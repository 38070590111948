import * as React from "react";
import { connect } from "react-redux";
import SpecialMeterReading from "./modals/SpecialMeterReading";
import WaterUsagesCalculation from "./components/WaterUsageCalculation";

import { Modal } from "office-ui-fabric-react/lib/Modal";
import {
    savePDF, generatePDF, changeState, saveSpecialMeterReadingMatter,
    getSpecialMeterReadingMatter, deleteSpecialMeterReadingMatter,
    clearSpecialMeterReadingInfoState
} from "./redux/actions";
import * as toastr from "toastr";

import { MatterDetails, ActionstepMatterInfo } from "utils/wcaApiTypes";
import { SpecialMeterReadingViewModel, SpecialMeterReadingInfo } from "./utils/specialMeterReadingWCAApiTypes";

import { AppState } from "app.types";
import { PrimaryButton } from "@fluentui/react/lib/Button";
import * as CONSTANTS from "containers/calculators/specialMeterReading/redux/constants";
import "./SpecialMeterReadingCalculator.css";
import SettlementInvalidMatter from "../settlement/settlementInvalidMatter";
import { MessageBar, MessageBarType, Icon, Link } from "office-ui-fabric-react/lib";
import SettlementMatter from "./components/SettlementMatter";
import { generateUIData } from "./Helper";
import { errorMessages } from "./constants";
import { apportionmentOption } from "../settlement/common";
import { formatToLocaleDateTimeString } from "../settlement/Helper";

type IProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;
type IState = {
    loadData: boolean | null;
    refresh: boolean;
    matterDetails: MatterDetails;
    matterInfo: ActionstepMatterInfo | null;
    isInvalidMatter: boolean;
    specialMeterReadingData: SpecialMeterReadingInfo;
    isWaiting: boolean;
    hasError: boolean | null;
    orgConnected: boolean | null;
    hasWaterUsage: boolean;
    dataInputError: string;
    CommencementDateError: string;
    searchReadingError: string;
    entitlementValueError: string;
    percentageError: string;

};

export class specialMeterReading extends React.Component<IProps, IState> {
    constructor(props: any) {
        super(props);
        this.state = {
            matterDetails: new MatterDetails(),
            specialMeterReadingData: new SpecialMeterReadingInfo(),
            loadData: null,
            refresh: false,
            isInvalidMatter: false,
            matterInfo: null,
            isWaiting: false,
            hasError: null,
            orgConnected: null,
            hasWaterUsage: false,
            dataInputError: "",
            CommencementDateError: "",
            searchReadingError: "",
            entitlementValueError: "",
            percentageError: ""
        };
    }

    updatedState: any = {
        method: "search-reading",
        paidDate: null,
        searchDate: null,
        searchReadingAmount: 0,

        baseRateCostPerKL: 0,
        baseRateKLCount: 0,
        baseRateIncrease: 0,

        tier2CostPerKL: 0,
        tier2KLCount: 0,
        tier2FeeIncrease: 0,

        adjustedBaseRateKLCount: 0,
        adjustedTier2KLCount: 0,
        adjustedTier3KLCount: 0,

        tier3CostPerKL: 0,
        tier3KLCount: 0,
        tier3FeeIncrease: 0,

        balanceWaterCharges: 0,
        balanceWaterChargesFeeIncrease: 0,

        ctsOption: "do-not-apportion",
        status: "less"
    };

    public componentDidMount(): void {
        this.loadActionstepMatter();
    }

    private loadActionstepMatter(): void {
        const { actionstepContext } = this.props;
        if (
            actionstepContext === undefined ||
            actionstepContext.matterContext === undefined ||
            actionstepContext.termsEverAccepted === undefined ||
            actionstepContext.latestTermsAccepted === undefined
        ) {
            this.setState({
                isInvalidMatter: true,
            });
            return;
        }
        const matterInfo = new ActionstepMatterInfo({
            orgKey: actionstepContext.orgKey,
            matterId: actionstepContext.matterContext?.id,
            termsEverAccepted: actionstepContext.termsEverAccepted,
            latestTermsAccepted: actionstepContext.latestTermsAccepted,
        });

        this.setState({
            matterInfo,
        });
        this.props.getSpecialMeterReadingMatter(matterInfo);
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>): void {
        const { loadData } = this.state;
        if (loadData !== null)
            this.loadData(false);
    }

    private updateValue(newValue: any, whichValue: string, needRefresh: boolean = false): void {
        
        if (whichValue !== "paidDate" && whichValue !== "searchDate" && whichValue !== "ctsOption" && whichValue !== "percentage" && whichValue !== "entitlementValue") {
            newValue = (newValue === "" || isNaN(newValue)) ? 0 : newValue;
        }

        this.updatedState[whichValue] = newValue;

        if (needRefresh) {
            this.setState({
                refresh: !this.state.refresh,
            });
        }
    }

    public async loadData(useNewValue = false): Promise<void> {
        const { specialMeterReadingData } = this.state;

        const matterDetails: MatterDetails = !useNewValue && specialMeterReadingData.matterDetails
            ? specialMeterReadingData.matterDetails
            : this.state.matterDetails;

        matterDetails.adjustmentDate = new Date(matterDetails.adjustmentDate);
        matterDetails.settlementDate = new Date(matterDetails.settlementDate);

        await this.setState({
            matterDetails: MatterDetails.fromJS(matterDetails),
            loadData: null,
        });

        this.props.clearSpecialMeterReadingInfoState();
        this.props.changeState(matterDetails.state || "SA");
    }

    static getDerivedStateFromProps(nextProps: IProps, prevState: IState): IState {
        let nextState = {} as IState;

        if (nextProps.gotResponse === true) {
            switch (nextProps.requestType) {
                case CONSTANTS.GENERATE_PDF_REQUESTED:
                    nextState = {
                        ...nextState,
                        isWaiting: false,
                    };

                    if (nextProps.gotResponse === true && nextProps.success === false) {
                        toastr.error(
                            nextProps.error!.message || "Unexpected Error occured..."
                        );
                        nextState = {
                            ...nextState,
                            hasError: true,
                        };
                    }
                    break;

                case CONSTANTS.GET_SPECIAL_METER_READING_REQUESTED:
                    if (nextProps.success === true) {
                        if (nextProps.specialMeterReadingMatter !== undefined) {
                            let specialMeterReadingMatter: SpecialMeterReadingViewModel = nextProps.specialMeterReadingMatter;

                            let specialMeterReadingData: SpecialMeterReadingInfo = specialMeterReadingMatter.specialMeterReadingData || new SpecialMeterReadingInfo();
                            let isNew: boolean = specialMeterReadingMatter.version === 0;

                            nextState = {
                                ...nextState,
                                orgConnected: true,
                                specialMeterReadingData
                            };

                            if (isNew) {
                                specialMeterReadingData.waterUsage = undefined;
                                nextState = {
                                    ...nextState,
                                    hasError: false,
                                    loadData: false,
                                    specialMeterReadingData,
                                    hasWaterUsage: false
                                };
                            } else {
                                nextState = {
                                    ...nextState,
                                    loadData: true,
                                    hasWaterUsage: true
                                };
                            }
                        }
                    } else if (nextProps.actionstepContext === undefined || nextProps.actionstepContext.matterContext === undefined) {
                        nextState = {
                            ...nextState,
                            orgConnected: false,
                            hasError: false,
                        };
                    } else {
                        toastr.error(
                            nextProps.error!.message || "Unexpected Error occured..."
                        );
                        nextState = {
                            ...nextState,
                            hasError: true,
                        };
                    }
                    break;

                case CONSTANTS.SAVE_SPECIAL_METER_READING_REQUESTED:
                    nextState = {
                        ...nextState,
                        isWaiting: false,
                    };

                    if (nextProps.gotResponse === true && nextProps.success === false) {
                        toastr.error(
                            nextProps.error!.message || "Unexpected Error occured..."
                        );
                        nextState = {
                            ...nextState,
                            hasError: true,
                        };
                    }
                    break;

                case CONSTANTS.DELETE_SPECIAL_METER_READING_REQUESTED:
                    nextState = {
                        ...nextState,
                        isWaiting: false,
                    };

                    if (nextProps.gotResponse === true && nextProps.success === false) {
                        toastr.error(
                            nextProps.error!.message || "Unexpected Error occured..."
                        );
                        nextState = {
                            ...nextState,
                            hasError: true,
                        };
                    } else {
                        nextState = {
                            ...nextState,
                            specialMeterReadingData: new SpecialMeterReadingInfo()
                        };
                    }
                    break;

                default:
                    return nextState;
            }
            nextProps.clearSpecialMeterReadingInfoState();
        }
        return nextState;
    }

    public render(): JSX.Element {
        const { specialMeterReadingData, isInvalidMatter, orgConnected, hasError, hasWaterUsage, isWaiting } = this.state;
        const { matterDetails, waterUsage, additionalInfo } = specialMeterReadingData;

        if (waterUsage && Object.keys(waterUsage).length > 0) {
            this.updatedState = this.state.specialMeterReadingData.waterUsage;
            this.updatedState["paidDate"] = new Date(waterUsage["paidDate"]);
            this.updatedState["searchDate"] = new Date(waterUsage["searchDate"]);
        }
        else if (!hasWaterUsage && additionalInfo) {
            this.updateValue(additionalInfo!["BaseRateCharges"], "baseRateCostPerKL");
            this.updateValue(additionalInfo!["Tier2Charges"], "tier2CostPerKL");
            this.updateValue(additionalInfo!["Balance"], "balanceWaterCharges");
        }

        return (
            <div className="settlement-calculator">
                {isInvalidMatter ?
                    (<SettlementInvalidMatter />
                    ) : orgConnected === true ? (
                        <div className="wrapper-content animated fadeIn vertical-container">
                            <div className="ibox">
                                <div className="ibox-content">
                                    <SettlementMatter matterDetails={matterDetails} />
                                    {hasWaterUsage ?
                                        <WaterUsagesCalculation waterUsage={waterUsage!} edit={() => this.edit()} />
                                        :
                                        <SpecialMeterReading updateValue={(newValue: any, whichValue: string, needRefresh = false) => this.updateValue(newValue, whichValue, needRefresh)}
                                            updatedState={this.updatedState}
                                            stateValue={this.state}
                                        />
                                    }
                                </div>

                                <div className="ibox-footers">
                                    {hasWaterUsage ?
                                        <PrimaryButton
                                            className="button"
                                            data-automation-id="save_button"
                                            data-cy="generate_button"
                                            text="Generate a PDF"
                                            onClick={() => this.generatePDF()}
                                            allowDisabledFocus={true}
                                        />
                                        :
                                        <>
                                            {this.state.dataInputError !== "" &&
                                                <div className="display-error">
                                                    <MessageBar
                                                        messageBarType={MessageBarType.severeWarning}>
                                                        {this.state.dataInputError}
                                                    </MessageBar>
                                                </div>
                                            }

                                            <PrimaryButton
                                                className="button"
                                                data-automation-id="cancel_button"
                                                data-cy="save_button"
                                                text="Cancel"
                                                onClick={() => this.cancel()}
                                                allowDisabledFocus={true}
                                            />

                                            <PrimaryButton
                                                className="button"
                                                data-automation-id="save_button"
                                                data-cy="save_button"
                                                text="OK"
                                                onClick={() => this.save()}
                                                allowDisabledFocus={true}
                                            />
                                        </>
                                    }
                                </div>
                            </div >

                            <div data-cy="modal-header" />
                            <Modal
                                isOpen={isWaiting}
                                isBlocking={true}
                                className={isWaiting !== null ? "animated fadeIn settlement-calculator" : "animated fadeOut settlement-calculator"}
                            >
                                <div className="modal-body">
                                    <div className="ms-Grid-row loading-modal">
                                        <img
                                            src="/images/Konekta_loading.svg" alt="Logo" height="150" />
                                        <h2>Please wait...</h2>
                                    </div>
                                </div>
                            </Modal>
                        </div >
                    ) : hasError === true ? (
                        <div className="wrapper wrapper-content animated fadeInRight vertical-container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="panel panel-danger">
                                        <div className="panel-heading">
                                            <Icon iconName="StatsCircleExclamation" />{" "}
                                            <span className="m-l-xs">Sorry, something went wrong</span>
                                        </div>
                                        <div className="panel-body p-v-xs">
                                            <p className="text-danger">
                                                Please contact{" "}
                                                <Link
                                                    href={
                                                        "mailto:support@konekta.com.au?subject=SA%20Special%20Meter%20Reading%20Calculator"
                                                    }
                                                >
                                                    {" "}
                                                    support@konekta.com.au
                                                </Link>
                                                .
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : hasError === false ? (
                        <div className="wrapper wrapper-content animated fadeInRight vertical-container">
                            <MessageBar messageBarType={MessageBarType.error}>
                                You're not connected to <strong>Konekta</strong>.
                            </MessageBar>
                        </div>
                    ) : (
                        <div className="loading-widget">
                            <img src="/images/Konekta_loading.svg" alt="Logo" height="150" width="150" />{" "}
                            Loading...
                        </div>
                    )
                }
            </div>
        );
    }

    private save() {

        const isFormValid = this.validateForm();
        if (isFormValid) {

            this.updatedState["adjustedBaseRateKLCount"] = this.updatedState["baseRateKLCount"];
            this.updatedState["adjustedTier2KLCount"] = this.updatedState["tier2KLCount"];
            this.updatedState["adjustedTier3KLCount"] = this.updatedState["tier3KLCount"];

            let specialReadingData = generateUIData(this.updatedState, this.state.matterDetails, this.state.specialMeterReadingData);

            const { matterInfo } = this.state;

            const params: SpecialMeterReadingViewModel = new SpecialMeterReadingViewModel({
                actionstepOrg: matterInfo!.orgKey || "",
                matterId: matterInfo!.matterId || 0,
                version: 0,
                specialMeterReadingData: specialReadingData,
                localDateTime: ""
            });

            this.props.saveSpecialMeterReadingMatter(params);

            this.setState({
                specialMeterReadingData: specialReadingData,
                hasWaterUsage: true,
                CommencementDateError: "",
                searchReadingError: "",
                entitlementValueError: "",
                percentageError: ""
            });
        }
    }

    private generatePDF() {
        let specialReadingData = generateUIData(this.updatedState, this.state.matterDetails, this.state.specialMeterReadingData);

        const { matterInfo } = this.state;

        var dateValue = new Date();
        var localDateTile = formatToLocaleDateTimeString(dateValue);

        const params: SpecialMeterReadingViewModel = new SpecialMeterReadingViewModel({
            actionstepOrg: matterInfo!.orgKey || "",
            matterId: matterInfo!.matterId || 0,
            version: 0,
            specialMeterReadingData: specialReadingData,
            localDateTime: localDateTile
        });

        this.props.generatePDF(params);

        this.setState({
            isWaiting: true,
        });

    }

    private edit() {
        this.setState({
            hasWaterUsage: false,
            dataInputError: ""
        })
    }

    private cancel() {
        let hasWaterUsages = this.state.specialMeterReadingData.waterUsage ? true : false;
        this.setState({
            hasWaterUsage: hasWaterUsages,
            dataInputError: ""
        })
    }

    private validateForm(): boolean {

        let contentLength = this.updatedState["entitlementValue"] ? this.updatedState["entitlementValue"].toString().split('/').length : 0

        if (this.updatedState["paidDate"] === null) {
            this.setState({
                dataInputError: errorMessages.dateInputError,
                CommencementDateError: "error-red-border",
                searchReadingError: "",
                entitlementValueError: "",
                percentageError: ""
            });
            return false;
        }
        if (this.updatedState["searchDate"] === null) {
            this.setState({
                dataInputError: errorMessages.dateInputError,
                CommencementDateError: "",
                searchReadingError: "error-red-border",
                entitlementValueError: "",
                percentageError: ""
            });
            return false;
        }
        if (this.updatedState["ctsOption"] === apportionmentOption.entitlement && (this.updatedState["entitlementValue"] ? false : true)) {
            this.setState({
                dataInputError: errorMessages.entitlementValueFormatError,
                CommencementDateError: "",
                searchReadingError: "",
                entitlementValueError: "error-red-border",
                percentageError: ""
            });
            return false;
        }
        else if (this.updatedState["ctsOption"] === apportionmentOption.entitlement && (contentLength == 1 || contentLength > 2)) {
            this.setState({
                dataInputError: errorMessages.entitlementValueFormatError,
                CommencementDateError: "",
                searchReadingError: "",
                entitlementValueError: "error-red-border",
                percentageError: ""
            });
            return false;
        }
        else if (this.updatedState["ctsOption"] === apportionmentOption.sharedPercentage && (this.updatedState["percentage"] ? false : true)) {
            this.setState({
                dataInputError: errorMessages.sharedPercentageError,
                CommencementDateError: "",
                searchReadingError: "",
                entitlementValueError: "",
                percentageError: "error-red-border"
            });
            return false;
        }
        return true;
    }
}

const mapStateToProps = (state: AppState) => {
    return {
        state: state.specialMeterReadingInfo.state,
        actionstepContext: state.common.actionstepContext,
        success: state.specialMeterReadingInfo.success,
        gotResponse: state.specialMeterReadingInfo.gotResponse,
        specialMeterReadingMatter: state.specialMeterReadingInfo.specialMeterReadingMatter,
        actionstepPDF: state.specialMeterReadingInfo.actionstepPDF,
        requestType: state.specialMeterReadingInfo.requestType,
        error: state.specialMeterReadingInfo.error,
    };
};

const mapDispatchToProps = {
    generatePDF,
    savePDF,
    getSpecialMeterReadingMatter,
    saveSpecialMeterReadingMatter,
    deleteSpecialMeterReadingMatter,
    changeState,
    clearSpecialMeterReadingInfoState
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(specialMeterReading);