import { FirstTitleEvents } from './events'

import {
    ActionstepMatterInfo,
    FirstTitlePolicyRequestFromActionstepResponse,
    ErrorViewModel,
    OrderFirstTitlePolicyCommandResponse,
    FirstTitleCredential,
} from 'utils/wcaApiTypes';

import { IActionUnion } from 'utils/reduxHelpers';

export const makeAction = <T extends FirstTitleEvents, P>(type: T) => (payload: P) => {
    return {
        type,
        payload,
    }
};

export const makeActionWithoutPayload = <T extends FirstTitleEvents>(type: T) => () => {
    return {
        type,
    }
};

export const getFirstTitlePolicyRequestFromActionstep = makeAction<FirstTitleEvents.GET_FIRST_TITLE_POLICY_REQUEST_FROM_ACTIONSTEP_REQUESTED, ActionstepMatterInfo>(FirstTitleEvents.GET_FIRST_TITLE_POLICY_REQUEST_FROM_ACTIONSTEP_REQUESTED);
export const getFirstTitlePolicyRequestFromActionstepSuccess = makeAction<FirstTitleEvents.GET_FIRST_TITLE_POLICY_REQUEST_FROM_ACTIONSTEP_SUCCESS, FirstTitlePolicyRequestFromActionstepResponse>(FirstTitleEvents.GET_FIRST_TITLE_POLICY_REQUEST_FROM_ACTIONSTEP_SUCCESS);
export const getFirstTitlePolicyRequestFromActionstepFailed = makeAction<FirstTitleEvents.GET_FIRST_TITLE_POLICY_REQUEST_FROM_ACTIONSTEP_FAILED, ErrorViewModel>(FirstTitleEvents.GET_FIRST_TITLE_POLICY_REQUEST_FROM_ACTIONSTEP_FAILED);
export const sendDataToFirstTitle = makeAction<FirstTitleEvents.SEND_DATA_TO_FIRST_TITLE_REQUESTED, FirstTitlePolicyRequestFromActionstepResponse>(FirstTitleEvents.SEND_DATA_TO_FIRST_TITLE_REQUESTED);
export const sendDataToFirstTitleSuccess = makeAction<FirstTitleEvents.SEND_DATA_TO_FIRST_TITLE_SUCCESS, OrderFirstTitlePolicyCommandResponse>(FirstTitleEvents.SEND_DATA_TO_FIRST_TITLE_SUCCESS);
export const sendDataToFirstTitleFailed = makeAction<FirstTitleEvents.SEND_DATA_TO_FIRST_TITLE_FAILED, ErrorViewModel>(FirstTitleEvents.SEND_DATA_TO_FIRST_TITLE_FAILED);
export const checkFirstTitleCredentials = makeAction<FirstTitleEvents.CHECK_FIRST_TITLE_CREDENTIALS_REQUESTED, FirstTitleCredential>(FirstTitleEvents.CHECK_FIRST_TITLE_CREDENTIALS_REQUESTED);
export const checkFirstTitleCredentialsSuccess = makeAction<FirstTitleEvents.CHECK_FIRST_TITLE_CREDENTIALS_SUCCESS, boolean>(FirstTitleEvents.CHECK_FIRST_TITLE_CREDENTIALS_SUCCESS);
export const checkFirstTitleCredentialsFailed = makeAction<FirstTitleEvents.CHECK_FIRST_TITLE_CREDENTIALS_FAILED, ErrorViewModel>(FirstTitleEvents.CHECK_FIRST_TITLE_CREDENTIALS_FAILED);

// Logout Actions
export const firstTitleCredentialsLogout = makeActionWithoutPayload<FirstTitleEvents.FIRST_TITLE_CREDENTIALS_LOGOUT>(FirstTitleEvents.FIRST_TITLE_CREDENTIALS_LOGOUT);
export const firstTitleCredentialsLogoutSuccess = makeAction<FirstTitleEvents.FIRST_TITLE_CREDENTIALS_LOGOUT_SUCCESS, boolean>(FirstTitleEvents.FIRST_TITLE_CREDENTIALS_LOGOUT_SUCCESS);
export const firstTitleCredentialsLogoutFailed = makeAction<FirstTitleEvents.FIRST_TITLE_CREDENTIALS_LOGOUT_FAILED, ErrorViewModel>(FirstTitleEvents.FIRST_TITLE_CREDENTIALS_LOGOUT_FAILED);

const firstTitleActions = {
    getFirstTitlePolicyRequestFromActionstep,
    getFirstTitlePolicyRequestFromActionstepSuccess,
    getFirstTitlePolicyRequestFromActionstepFailed,
    sendDataToFirstTitle,
    sendDataToFirstTitleSuccess,
    sendDataToFirstTitleFailed,
    checkFirstTitleCredentials,
    checkFirstTitleCredentialsSuccess,
    checkFirstTitleCredentialsFailed,
    firstTitleCredentialsLogout,
    firstTitleCredentialsLogoutSuccess,
    firstTitleCredentialsLogoutFailed
};

export type IFirstTitleAction = IActionUnion<typeof firstTitleActions>;
