import * as React from 'react';
import { AppState } from 'app.types';
import { connect } from 'react-redux';
import { IconButton, DefaultButton, Stack, IStackTokens, IStackStyles, MessageBar, MessageBarType } from '@fluentui/react/lib';
import { EnvelopeDetailsCustomerViewModel } from 'utils/wcaApiTypes';
import { TextField } from '@material-ui/core';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import { editEnvelopeCustomer, getCountryPhoneCodes, resendCustomerNotificationClearstate } from 'containers/ecaf/redux/actions';
import * as CONSTANTS from 'containers/ecaf/redux/constants';
import 'containers/ecaf/editOrder.css';

import { ECAFEditEnvelopeCustomerViewModel, ECAFCustomerEditModel } from '../../utils/newWcaApiTypes';

interface IAppProps {
  closeEditOrderModal: () => void;
  envelopeId: string | undefined,
  customer: EnvelopeDetailsCustomerViewModel
}

type AppStates = {
  isLoading: boolean;
  pageError: string | undefined;
  ecafOrderCustomer: EnvelopeDetailsCustomerViewModel;
  ecafOrderCustomerUnmodified: EnvelopeDetailsCustomerViewModel | undefined;
  saveStatus: boolean | undefined
  dissableSaveButton: boolean;
  controlValidation: {
    mobileNumberValid: boolean,
    emailAddressValid: boolean,
    countryCodeValid: boolean
  }
}

type AppProps = IAppProps & ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;
class EditOrder extends React.Component<AppProps, AppStates> {

  constructor(props: Readonly<AppProps>) {
    super(props);
    this.state = {
      isLoading: false,
      pageError: '',
      ecafOrderCustomer: this.props.customer,
      ecafOrderCustomerUnmodified: this.props.customer,
      saveStatus: undefined,
      dissableSaveButton: false,
      controlValidation: {
        mobileNumberValid: true,
        emailAddressValid: true,
        countryCodeValid: true
      }
    }
    //re-initialze the validation array
    var validationControls = this.state.controlValidation;

    validationControls.mobileNumberValid = true;
    validationControls.emailAddressValid = true;
    validationControls.countryCodeValid = true;



    this.setState((prevState) => {
      return {
        ...prevState,
        controlValidation: validationControls
      }
    })
  }

  public componentDidMount(): void {
    const { actionstepContext } = this.props;
    this.props.getCountryPhoneCodes(actionstepContext?.orgKey ?? '');
  }


  private _handleOnChange(newValue: string, key: string): void {

    var ecafCustomerInfo = new EnvelopeDetailsCustomerViewModel(this.state.ecafOrderCustomer);
    if (key === 'countryCode') {
      var countryCodeFound = this.props.countryPhoneCodeList?.find(x => x.phoneCode === newValue)?.id?.toString();
      ecafCustomerInfo.countryCodeId = this.props.countryPhoneCodeList?.find(x => x.phoneCode === newValue)?.id?.toString();
      this.setState((prevState) => {
        var validationControls = prevState.controlValidation;
        validationControls.countryCodeValid = countryCodeFound !== undefined;
        return {
          ...prevState,
          controlValidation: validationControls
        }
      })
    }
    if (key === 'mobileNumber') {
      var mobileValid = /^\d{9}$/.test(newValue);
      ecafCustomerInfo.mobileNumber = newValue;
      this.setState((prevState) => {
        var validationControls = prevState.controlValidation;
        validationControls.mobileNumberValid = mobileValid;
        return {
          ...prevState,
          controlValidation: validationControls
        }
      })
    }
    if (key === 'emailAddress') {
      var emailAddressValid = /\S+@\S+\.\S+/.test(newValue);
      ecafCustomerInfo.emailAddress = newValue;
      this.setState((prevState) => {
        var validationControls = prevState.controlValidation;
        validationControls.emailAddressValid = emailAddressValid;
        return {
          ...prevState,
          controlValidation: validationControls
        }
      })
    }

    var client = this.state.ecafOrderCustomer ?? new EnvelopeDetailsCustomerViewModel();
    client = ecafCustomerInfo;

    this.setState((prevState) => {
      return {
        ...prevState,
        dissableSaveButton: false,
        ecafOrderCustomer: client
      }
    });
  }


  private editOrder(customerId: string, envelopeId: string): void {
    const { actionstepContext } = this.props;
    let command = new ECAFEditEnvelopeCustomerViewModel({
      customerFirstName: this.state.ecafOrderCustomer?.firstName,
      customerLastName: this.state.ecafOrderCustomer?.lastName,
      customerCountryCode: this.state.ecafOrderCustomer?.countryCodeId,
      customerEmailAddress: this.state.ecafOrderCustomer?.emailAddress,
      customerId: customerId,
      customerMobileNumber: this.state.ecafOrderCustomer?.mobileNumber,
      envelopeId: envelopeId,
      orgKey: actionstepContext?.orgKey
    });

    this.props.editEnvelopeCustomer(new ECAFCustomerEditModel({
      newData: command,
      oldData: this.state.ecafOrderCustomerUnmodified ?? new EnvelopeDetailsCustomerViewModel
    }));

    this.setState({
      dissableSaveButton: true
    });
  }



  static getDerivedStateFromProps(nextProps: AppProps, prevState: AppStates): AppStates {
    let nextState = {} as AppStates;

    switch (nextProps.requestType) {
      case CONSTANTS.EDIT_ENVELOPE_CUSTOMER_REQUESTED: {
        nextState.isLoading = true;
        nextState.saveStatus = undefined;
        break;
      }
      case CONSTANTS.EDIT_ENVELOPE_CUSTOMER_SUCCESS:
        nextState.isLoading = false;
        nextState.saveStatus = nextProps.editCustomerResponse;
        nextProps.resendCustomerNotificationClearstate();
        break;
      case CONSTANTS.EDIT_ENVELOPE_CUSTOMER_FAILED:
        nextState.isLoading = false;
        nextState.pageError = 'Unable to save the customer data.Please try again later.'
        break;

      default:
        return nextState;
    }
    return nextState;
  }
  render() {
    const { isLoading, pageError, controlValidation, saveStatus } = this.state;
    const { countryPhoneCodeList } = this.props;
    let customer = this.state.ecafOrderCustomer
    // Tokens definition
    const sectionStackTokens: IStackTokens = { childrenGap: 10 };
    // Styles definition
    const stackStyles: IStackStyles = {
      root: {
        width: 800,
      },
    };
    const numericalSpacingStackTokens: IStackTokens = {
      childrenGap: 10,
      padding: 10,
    };

    return (
      <div>
        <div>
          <Stack tokens={sectionStackTokens} className='modal-header' >
            <Stack horizontal disableShrink horizontalAlign="space-between">
              <span>Client Details</span>
              <IconButton
                className='modal-close-button'
                ariaLabel="Close popup modal"
                iconProps={{ iconName: 'Cancel' }}
                onClick={this.props.closeEditOrderModal}
                allowDisabledFocus={true} />
            </Stack>
          </Stack>


          <div className='modal-row-items' key={customer.id}>
            <Stack tokens={sectionStackTokens}>
              <Stack horizontal disableShrink horizontalAlign="space-between">
                <Stack horizontal styles={stackStyles} tokens={numericalSpacingStackTokens}>
                  <span>
                    {customer.firstName} {customer.lastName}
                  </span>
                  <span> <TextField label="Country Code" required variant="outlined" className="text-field" size="small" InputLabelProps={{ shrink: true }}
                    inputProps={{ minLength: 1, style: { textAlign: 'center' } }} value={countryPhoneCodeList?.find(x => x.id == customer.countryCodeId)?.phoneCode}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => this._handleOnChange(event.target.value, "countryCode")}
                    error={!controlValidation.countryCodeValid}
                    helperText={!controlValidation.countryCodeValid ? 'Invalid country code. Expected format +xx..' : ''} />
                  </span>
                  <span >
                    <TextField label="Mobile" required variant="outlined" className="text-field" size="small"
                      InputLabelProps={{ shrink: true }} value={customer.mobileNumber}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => this._handleOnChange(event.target.value, "mobileNumber")}
                      error={!controlValidation.mobileNumberValid}
                      helperText={!controlValidation.mobileNumberValid ? 'Invalid mobile number.Expected format 4xxxxxxxx' : ''} />
                  </span>
                  <span>
                    <TextField label="Email" required variant="outlined" className="text-field" size="small"
                      InputLabelProps={{ shrink: true }} value={customer.emailAddress}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => this._handleOnChange(event.target.value, "emailAddress")}
                      error={!controlValidation.emailAddressValid}
                      helperText={!controlValidation.emailAddressValid ? 'Invalid email address' : ''} />
                  </span>
                  <span >
                    {isLoading &&
                      <Spinner size={SpinnerSize.large} label="editing customer..." />}
                  </span>
                  <span>
                    {!isLoading && <DefaultButton
                      disabled={!controlValidation.countryCodeValid || !controlValidation.emailAddressValid || !controlValidation.mobileNumberValid || this.state.dissableSaveButton || isLoading}
                      onClick={() => this.editOrder(customer.id ?? '', this.props.envelopeId ?? '')} text={isLoading ? 'Save in progress' : 'Save'} />}
                    {pageError &&
                      <MessageBar messageBarType={MessageBarType.error}>
                        Error: {pageError}
                      </MessageBar>
                    }
                  </span>
                  <span>
                    {saveStatus != undefined && saveStatus &&
                      <MessageBar messageBarType={MessageBarType.success}>
                        Customer details saved successfully. A notification will be sent to the updated customer details.
                      </MessageBar>
                    }
                    {saveStatus != undefined && !saveStatus &&
                      <MessageBar messageBarType={MessageBarType.error}>
                        No information updated. Unable to save the customer details and re-send the notification.
                      </MessageBar>}
                  </span>
                </Stack>
              </Stack>
            </Stack>
          </div>
        </div>
      </div>)
  }
}


const mapStateToProps = (state: AppState) => {
  return {
    error: state.eCaf.error,
    gotResponse: state.eCaf.gotResponse,
    requestType: state.eCaf.requestType,
    actionstepContext: state.common.actionstepContext,
    countryPhoneCodeList: state.eCaf.countryPhoneCodeList,
    editCustomerResponse: state.eCaf.editCustomerResponse
  }
}

const mapDispatchToProps = {
  editEnvelopeCustomer,
  getCountryPhoneCodes,
  resendCustomerNotificationClearstate

}

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(EditOrder));


