import { call, put, takeLatest, takeEvery } from 'redux-saga/effects'

import * as CONSTANTS from './constants'
import * as ACTIONS from './actions'
import {
    PEXAWorkspaceCreationRequestWithActionstepResponse,
    CreatePexaWorkspaceResponse,
    LandTitleReferenceVerificationResponseType,
    WorkspaceSummaryResponseType,
    CreateWorkspaceInvitationResponseType,
    RetrieveSettlementAvailabilityResponseType,
    PexaWorkspaceInfo,

} from 'utils/wcaApiTypes'

import { CopyDocumentsToActionStepResponse, CreatePexaKeyInviteResponse, DealingNumberViewModel } from 'utils/newWcaApiTypes'
import {
    GetDataFromActionstep,
    SendDataToPexa,
    ValidateLandTitle,
    GetPexaWorkspaceSummary,
    CreateInvitation,
    GetAvailableSettlementTimes,
    GetPexaWorkspaceInformationFromKonekta,
    MaintainSettlementDateTime,
    UpdateSettlementDateTimeActionstep,
    GetInvitationUri,
    CreatePexaKey,
    GetPexaKey,
    GetPexaKeyIviteInformationFromKonekta,
    CopyLodgedDocumentsToActionStep,
    SavePexaAudit,
    GetActionstepPropertyDetails,
    UpdateDealingNumberActionstep,
    GetFSSLineItems,
    SetFSSLineItems
} from 'containers/pexa/redux/actionTypes'
import { getRequest, postRequest } from 'utils/request'
import { ActionstepFinancialLineItems } from '../pexaApiTypes'


export function* getDataFromActionstep(action: GetDataFromActionstep) {
    try {
        const response = yield call(getRequest, `/api/conveyancing/pexa-workspace-creation-request-from-matter?actionsteporg=${action.matterInfo.orgKey!}&matterid=${action.matterInfo.matterId}`);

        let data: PEXAWorkspaceCreationRequestWithActionstepResponse = PEXAWorkspaceCreationRequestWithActionstepResponse.fromJS(response);

        yield put(ACTIONS.getDataFromActionstepSuccess(data));

    } catch (error) {
        yield put(ACTIONS.getDataFromActionstepFailed(error?.response?.data));
    }
}

export function* getAvailableSettlementTimes(action: GetAvailableSettlementTimes) {
    const { params } = action;
    try {
        const response = yield call(postRequest, `/api/conveyancing/get-available-settlement-times`, params);

        let data: RetrieveSettlementAvailabilityResponseType = RetrieveSettlementAvailabilityResponseType.fromJS(response);

        yield put(ACTIONS.getAvailableSettlementTimesSuccess(params.jurisdiction!, params.settlementDate!, data));

    } catch (error) {
        yield put(ACTIONS.getAvailableSettlementTimesFailed(params.jurisdiction!, params.settlementDate!, error?.response?.data));
    }
}

export function* validateLandTitle(action: ValidateLandTitle) {
    try {
        const requestData = action.params.toJSON();
        const response = yield call(postRequest, `/api/conveyancing/validate-land-title-reference`, requestData);

        let data: LandTitleReferenceVerificationResponseType = LandTitleReferenceVerificationResponseType.fromJS(response);

        yield put(ACTIONS.validateLandTitleSuccess(data));

    } catch (error) {
        yield put(ACTIONS.validateLandTitleFailed(error?.response?.data));
    }
}

export function* getPexaWorkspaceSummary(action: GetPexaWorkspaceSummary) {
    const { params } = action;
    try {
        const response = yield call(postRequest, `/api/conveyancing/get-pexa-workspace-summary`, params);

        let data: WorkspaceSummaryResponseType = WorkspaceSummaryResponseType.fromJS(response);

        yield put(ACTIONS.getPexaWorkspaceSummarySuccess(params.workspaceId!, data));
    } catch (error) {
        yield put(ACTIONS.getPexaWorkspaceSummaryFailed(params.workspaceId!, error?.response?.data));
    }
}

export function* sendDataToPexa(action: SendDataToPexa) {
    try {
        const requestData = action.params.toJSON();

        const response = yield call(postRequest, `/api/conveyancing/pexa-workspace-creation-request-from-matter`, requestData);

        let data: CreatePexaWorkspaceResponse = response;

        yield put(ACTIONS.sendDataToPexaSuccess(data));

    } catch (error) {
        yield put(ACTIONS.sendDataToPexaFailed(error?.response?.data));
    }
}

export function* inviteSubscribers(action: CreateInvitation) {
    try {
        const { params } = action;
        const response = yield call(postRequest, `/api/conveyancing/invite-subscribers`, params);

        let data: CreateWorkspaceInvitationResponseType[] = response;

        yield put(ACTIONS.createInvitationSuccess(data));
    } catch (error) {
        yield put(ACTIONS.createInvitationFailed(error?.response?.data));
    }
}

export function* getPexaWorkspaceInformationFromKonekta(action: GetPexaWorkspaceInformationFromKonekta) {
    try {
        const response = yield call(getRequest, `/api/conveyancing/get-pexa-workspace-information-from-konekta?actionsteporg=${action.matterInfo.orgKey!}&matterid=${action.matterInfo.matterId}`);

        let data: PexaWorkspaceInfo = response;

        yield put(ACTIONS.getPexaWorkspaceInformationFromKonektaSuccess(data));

    } catch (error) {
        yield put(ACTIONS.getPexaWorkspaceInformationFromKonektaFailed(error?.response?.data));
    }
}

export function* maintainSettlementDateTime(action: MaintainSettlementDateTime) {
    const { params } = action;
    try {
        const response = yield call(postRequest, `/api/conveyancing/pexa-maintain-settlement-date-time`, params);

        let succeed: boolean = response

        yield put(ACTIONS.maintainSettlementDateTimeSuccess(succeed));

    } catch (error) {
        yield put(ACTIONS.maintainSettlementDateTimeFailed(error?.response?.data));
    }
}

export function* updateSettlementDateTimeActionstep(action: UpdateSettlementDateTimeActionstep) {
    const { params } = action;
    try {
        const response = yield call(postRequest, `/api/actionstep/update-settlement-date-time`, params);

        let succeed: boolean = response

        yield put(ACTIONS.updateSettlementDateTimeActionstepSuccess(succeed));

    } catch (error) {
        yield put(ACTIONS.updateSettlementDateTimeActionstepFailed(error?.response?.data));
    }
}

export function* getInvitationUri(action: GetInvitationUri) {
    const { params } = action;
    try {
        const response = yield call(getRequest, `/api/conveyancing/pexa-get-invitation-uri?workspaceId=${params.workspaceId!}&workspaceRole=${params.workspaceRole!}`);

        let data: string = response;

        yield put(ACTIONS.getInvitationUriSuccess(data));

    } catch (error) {
        yield put(ACTIONS.getInvitationUriFailed(error?.response?.data));
    }
}

export function* createPexaKey(action: CreatePexaKey) {
    const { params } = action;
    try {
        const response = yield call(postRequest, `/api/conveyancing/create-pexa-keyInvitation`, params);

        let data: CreatePexaKeyInviteResponse = CreatePexaKeyInviteResponse.fromJS(response);

        yield put(ACTIONS.createPexaKeySuccess(data));

    } catch (error) {
        yield put(ACTIONS.createPexaKeyFailed(error?.response?.data));
    }
}

export function* getPexaKey(action: GetPexaKey) {
    const { params } = action;
    try {
        const response = yield call(getRequest, `/api/conveyancing/get-pexa-keyInvitation?workspaceId=${params.workspaceId!}&workspaceRole=${params.workspaceRole!}&partyId=${params.partyId!}`);

        let data: string = response;

        yield put(ACTIONS.getPexaKeySuccess(data));

    } catch (error) {
        yield put(ACTIONS.getPexaKeyFailed(error?.response?.data));
    }
}

export function* checkPexaKeyInvitationStatusfromKonekta(action: GetPexaKeyIviteInformationFromKonekta) {

    try {
        const response = yield call(getRequest, `/api/conveyancing/check-pexa-keyInvitation-status-from-konekta?matterId=${action.matterInfo.matterId!}&orgKey=${action.matterInfo.orgKey!}`);

        let data: string = response;

        yield put(ACTIONS.getPexaKeyIviteInformationFromKonektaSuccess(data));

    } catch (error) {
        yield put(ACTIONS.getPexaKeyIviteInformationFromKonektaFailed(error?.response?.data));
    }
}

export function* copyLodgedDocumentsToActionStep(action: CopyLodgedDocumentsToActionStep) {
    try {
        const response = yield call(postRequest, `/api/conveyancing/copy-lodged-documents-to-actionstep?matterId=${action.matterId!}&orgKey=${action.orgKey!}`);

        let data: CopyDocumentsToActionStepResponse = CopyDocumentsToActionStepResponse.fromJS(response);

        yield put(ACTIONS.copyLodgedDocumentsToActionStepSuccess(data));

    } catch (error) {
        yield put(ACTIONS.copyLodgedDocumentsToActionStepFailed(error?.response?.data));
    }
}

export function* savePexaAudit(action: SavePexaAudit) {
    try {
        const response = yield call(postRequest, `/api/conveyancing/save-pexa-audit`, action.data);
        let data: boolean = response;
        yield put(ACTIONS.savePexaAuditSuccess(data));

    } catch (error) {
        yield put(ACTIONS.savePexaAuditFailed(error?.response?.data));
    }
}

export function* getActionstepPropertyDetails(action: GetActionstepPropertyDetails) {
    try {
        const response = yield call(getRequest, "/api/pexa/get-actionstep-property-details/" + action.params.matterId + "/" + action.params.orgKey);
        let data: DealingNumberViewModel[] = response;
        yield put(ACTIONS.getActionstepPropertyDetailsSuccess(data));

    } catch (error) {
        yield put(ACTIONS.getActionstepPropertyDetailsFailed(error?.response?.data));
    }
}

export function* updateDealingNumberActionstep(action: UpdateDealingNumberActionstep) {
    const { params } = action;
    try {
        const response = yield call(postRequest, `/api/pexa/update-actionstep-dealing-number`, params);

        let succeed: string = response


        yield put(ACTIONS.updateDealingNumberActionstepSuccess(succeed));

    } catch (error) {
        yield put(ACTIONS.updateDealingNumberActionstepFailed(error?.response?.data));
    }
}
export function* getFSSLineItems(action: GetFSSLineItems) {
    try {
        const response = yield call(getRequest, `/api/pexa/get-fss-actionstep?matterId=${action.matterId}&orgKey=${action.orgKey}`);
        let data: ActionstepFinancialLineItems[] = response;
        yield put(ACTIONS.getFSSLineItemsSuccess(data));

    } catch (error) {
        yield put(ACTIONS.getFSSLineItemsFailed(error?.response?.data));
    }
}
export function* setFSSLineItems(action: SetFSSLineItems) {
    try {
        const response = yield call(postRequest, `/api/conveyancing/set-pexa-fss`, action.fssLineItems);
        let data: string = response;
        yield put(ACTIONS.setFSSLineItemsSuccess(data));

    } catch (error) {
        yield put(ACTIONS.setFSSLineItemsFailed(error?.response?.data));
    }
}


export default function* pexaSaga() {
    yield takeLatest(CONSTANTS.GET_DATA_FROM_ACTIONSTEP_REQUESTED, getDataFromActionstep);
    yield takeLatest(CONSTANTS.GET_AVAILABLE_SETTLEMENT_TIMES_REQUESETD, getAvailableSettlementTimes);
    yield takeLatest(CONSTANTS.VALIDATE_LAND_TITLE_REQUESTED, validateLandTitle);
    yield takeLatest(CONSTANTS.SEND_DATA_TO_PEXA_REQUESTED, sendDataToPexa);
    yield takeLatest(CONSTANTS.CREATE_INVITATION_REQUESTED, inviteSubscribers);
    yield takeEvery(CONSTANTS.GET_PEXA_WORKSPACE_SUMMARY_REQUESTED, getPexaWorkspaceSummary);
    yield takeLatest(CONSTANTS.GET_PEXA_WORKSPACE_INFORMATION_FROM_KONEKTA_REQUESTED, getPexaWorkspaceInformationFromKonekta);
    yield takeLatest(CONSTANTS.MAINTAIN_SETTLEMENT_DATE_TIME_REQUESTED, maintainSettlementDateTime);
    yield takeLatest(CONSTANTS.UPDATE_SETTLEMENT_DATE_TIME_ACTIONSTEP_REQUESTED, updateSettlementDateTimeActionstep);
    yield takeLatest(CONSTANTS.GET_INVITATION_URI_REQUESTED, getInvitationUri);
    yield takeLatest(CONSTANTS.CREATE_PEXA_KEY_REQUESTED, createPexaKey);
    yield takeEvery(CONSTANTS.GET_PEXA_KEY_REQUESTED, getPexaKey);
    yield takeLatest(CONSTANTS.GET_PEXA_KEY_INVITE_FROM_KONEKTA_REQUESTED, checkPexaKeyInvitationStatusfromKonekta);
    yield takeLatest(CONSTANTS.COPY_LODGED_DOCUMENTS_TO_ACTIONSTEP_REQUESTED, copyLodgedDocumentsToActionStep);
    yield takeLatest(CONSTANTS.SAVE_PEXA_AUDIT_REQUESTED, savePexaAudit);
    yield takeLatest(CONSTANTS.GET_ACTIONSTEP_PROPERTY_DETAILS_REQUESTED, getActionstepPropertyDetails);
    yield takeLatest(CONSTANTS.UPDATE_DEALING_NUMBER_ACTIONSTEP_REQUESTED, updateDealingNumberActionstep);
    yield takeLatest(CONSTANTS.GET_FSS_LINEITEMS_REQUESTED, getFSSLineItems);
    yield takeLatest(CONSTANTS.SET_FSS_LINEITEMS_REQUESTED, setFSSLineItems);



} 