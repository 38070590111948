import * as React from 'react'

import { PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import {
    MessageBar,
    MessageBarType,
    DatePicker,
    Text,
    Stack,
    Separator,
    Label,
    ChoiceGroup,
    Toggle,
} from 'office-ui-fabric-react';
import { TextField } from 'office-ui-fabric-react/lib/TextField';

import { TitleInfoTypes, StreetTypes, Countries, Jurisdictions } from './constants'

import { RequestPolicyOptions, FTActionstepMatter, IdentityType, FTParty, FTTitle, TitleInfoType } from 'utils/wcaApiTypes';
import FTAddressInput from 'containers/firsttitle/components/fTAddressInput';
import { _onFormatDate } from 'utils/dataFormatter';
import {
    LabelColumnStackItemStyles,
    DefaultStackTokens,
    FieldColumnStackItemStyles,
    FormFieldStackItemStyles,
    DoubleWidthFormFieldStackItemStyles,
} from 'konekta-theme';
import Tools from 'utils/tools';

import moment from 'moment';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import { AddBuyerComponent } from 'components/FirstTitle/AddBuyerComponent';
import { IndividualBuyer } from 'components/FirstTitle/IndividualBuyer';
import { CompanyBuyer } from 'components/FirstTitle/CompanyBuyer';

interface IProps {
    requestPolicyOptions: RequestPolicyOptions;
    policyReference: string;
    actionstepMatter: FTActionstepMatter;
    isCommercial: boolean;

    setPolicyReference: (newValue: any) => void;
    reviewForm: () => void;
    placeOrder: () => void;
    logoutHandler: () => void;
    setKnownRiskPolicyProperty: (key: string, val: any) => void;
    setRequestPolicyOptions: (key: string, val: boolean) => void;
    setMatterInformation: (keyPath: string, newValue: any) => void;
    setIsCommercial: (val: boolean) => void;
    createNewBuyer: (type: IdentityType) => void;
    removeBuyer: (id: string) => void;
}

interface IState {
    buyerIsCompany?: boolean;
    logoutBtnClicked: boolean
}

export default class QuestionnaireComponent extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            buyerIsCompany: undefined,
            logoutBtnClicked: false
        };
    }

    private checkValidation(): string[] {
        const { requestPolicyOptions, actionstepMatter } = this.props;
        let errors: string[] = [];

        if (actionstepMatter.buyers === undefined || actionstepMatter.buyers?.length < 1) {
            errors.push('Must have at least one buyer.');
        }

        if (actionstepMatter.settlementDate == undefined) {
            errors.push('Must have a Settlement Date');
        }

        errors.push(...this.validateRequestOptions(requestPolicyOptions));
        errors.push(...this.validateLandTitle(actionstepMatter.title));
        errors.push(...this.validateAddress(actionstepMatter.sourceProperty));
        errors.push(...this.validateCommercialPolicy(requestPolicyOptions, actionstepMatter));

        if (actionstepMatter.buyers) {
            if (this.state.buyerIsCompany == null) {
                const hasCompanyBuyer = actionstepMatter.buyers.some(el => el.identityType == IdentityType.Company);

                this.setState({
                    buyerIsCompany: hasCompanyBuyer
                });
            }

            for (let i = 0; i < actionstepMatter.buyers.length; i++) {
                errors.push(...this.validateParty(actionstepMatter.buyers[i]).map((v) => `Buyer ${i + 1} - ${v}`));
            }
        } else {
            errors.push('At least one buyer must be supplied.');
        }

        return errors;
    }

    private validateLandTitle(landTitle: FTTitle | undefined): string[] {
        if (landTitle === undefined) {
            return ['Land title information has not been supplied.'];
        }

        let errors: string[] = [];

        if (Tools.isNullOrEmptyOrUndefined(landTitle.legalDescription)) {
            errors.push('Legal description must be supplied.');
        }

        if (landTitle.titleInfoType === TitleInfoType.Reference) {
            if (!landTitle.titleReference) {
                errors.push('Title Reference type selected, but title reference has not been supplied.');
            }
        } else {
            if (!landTitle.titleVolume || !landTitle.titleFolio) {
                errors.push('Volume/Folio type selected, but volume and/or folio have not been supplied.');
            }
        }

        return errors;
    }

    private validateRequestOptions(requestPolicyOptions: RequestPolicyOptions): string[] {
        if (!requestPolicyOptions.requestKnownRiskPolicies) {
            return [];
        }

        let errors: string[] = [];

        const { riskInformation } = this.props.requestPolicyOptions;

        let countYes = 0;
        for (let key in riskInformation) {
            const value = (riskInformation as any)[key];

            if (value === true) {
                countYes++;
            }
        }

        if (countYes <= 0) {
            errors.push('You have specified that there are known risks, but no risk types have been selected.');
        }

        if (countYes > 3) {
            errors.push('A maximum of three known risk types can be been selected.');
        }

        return errors;
    }

    private validateParty(party: FTParty | undefined): string[] {
        if (party === undefined) {
            return ['Party is missing.'];
        }

        let errors: string[] = [];

        errors.push(...this.validateAddress(party));

        if (party.identityType === IdentityType.Company) {
            if (!party.companyName) {
                errors.push('Because party is a company, company name must be supplied.');
            }

            errors.push(...this.validatePartyBusinessDetails(party));
        } else {
            if (!party.title) {
                errors.push('Party is an individual, title must be supplied.');
            }

            if (!party.firstName) {
                errors.push('Party is an individual, first name must be supplied.');
            }

            if (!party.lastName) {
                errors.push('Party is an individual, last name must be supplied.');
            }

            if (!party.emailAddress) {
                errors.push('Party is an individual, email address must be supplied.');
            }
        }

        return errors;
    }

    private validatePartyBusinessDetails(party: FTParty): string[] {
        let errors: string[] = [];

        if (party.identityType === IdentityType.Company) {
            if (!party.abn && !party.acn && !party.abrn) {
                errors.push('Party is a company, so one of ABN, ACN, or ABRN must be supplied.');
            }

            if (party.abn && party.abn.length !== 11) {
                errors.push('Party ABN must be 11 digits.');
            }

            if (party.acn && party.acn.length !== 9) {
                errors.push('Party ACN must be 9 digits.');
            }
        }

        return errors;
    }

    private validateAddress(party: FTParty | undefined): string[] {
        if (party === undefined) {
            return ['Party information is missing.'];
        }

        let errors: string[] = [];

        if (!party.city) {
            errors.push('Address city must be supplied.');
        }

        if (!party.postCode) {
            errors.push('Address postcode must be supplied.');
        }

        if (!Tools.valueIsInList(party.streetType, StreetTypes)) {
            errors.push('Address street type must be selected from one of the available options.');
        }

        if (!Tools.valueIsInList(party.stateProvince, Jurisdictions) && party.country == "AU") {
            errors.push('Address state must be selected from one of the available options.');
        }

        if (!Tools.valueIsInList(party.country, Countries)) {
            errors.push('Country must be selected from one of the available options.');
        }

        return errors;
    }

    private validateCommercialPolicy(requestPolicyOptions: RequestPolicyOptions, actionstepMatter: FTActionstepMatter): string[] {
        const errors: string[] = [];

        if (requestPolicyOptions.isCommercialPolicy && actionstepMatter.settlementDate != null) {
            var currentDate = moment(new Date().toDateString()).utcOffset("+00:00", true);
            console.log("Current Date Selected (no UTC) = ", currentDate);
            console.log("Settlement date (no UTC) = ", actionstepMatter.settlementDateNoUTC);
            console.log("Difference between current date and new date (in days) = ", moment(actionstepMatter.settlementDateNoUTC).diff(currentDate, 'days'));
            if (moment(actionstepMatter.settlementDateNoUTC).diff(currentDate, 'days') < 0) {
                errors.push("Settlement date cannot be in past for commercial policy");
            }
        }

        return errors;
    }

    public render(): JSX.Element {
        const { 
            actionstepMatter, 
            requestPolicyOptions,
            setKnownRiskPolicyProperty, 
            setRequestPolicyOptions, 
            setMatterInformation, 
            setPolicyReference,
            removeBuyer
        } = this.props;

        const { riskInformation } = requestPolicyOptions;

        const errors = this.checkValidation();

        return (
            <>
                <Stack horizontal horizontalAlign={'end'} verticalAlign={'center'} tokens={DefaultStackTokens}>
                    <Stack.Item align="end">
                        <PrimaryButton
                            data-cy="ft_logout_button"
                            text="Logout"
                            disabled={this.state.logoutBtnClicked}
                            onClick={() => {
                                this.setState({
                                    logoutBtnClicked: true
                                });
                                this.props.logoutHandler();
                            }}
                            allowDisabledFocus={true}
                        />
                    </Stack.Item>
                    {this.state.logoutBtnClicked ?
                        <Stack.Item>
                            <Spinner size={SpinnerSize.small} />
                        </Stack.Item> : null}
                </Stack>

                {(actionstepMatter.buyers === undefined || actionstepMatter.buyers?.length < 1) &&
                    <MessageBar messageBarType={MessageBarType.blocked}>
                        <Text>
                            There are no buyers on this matter. You will not be able to request a policy
                            without any buyers. Please add one or more buyers on the participants tab
                            before continuing.
                        </Text>
                    </MessageBar>
                }

                <h2>Key Information</h2>

                <Stack horizontal tokens={DefaultStackTokens}>
                    <Stack.Item disableShrink styles={LabelColumnStackItemStyles}>
                        <Label htmlFor="requestKnownRiskPolicies">Known Risks?</Label>
                    </Stack.Item>
                    <Stack.Item shrink styles={FieldColumnStackItemStyles}>
                        <Text>
                            A Known Risk is any knowledge of an issue with the property that could lead
                            or is likely to lead to a claim under the title insurance policy. Any Known
                            Risks disclosed in any written report or documentation will be excluded from
                            coverage unless extended coverage is requested. Known Risks include but are
                            not limited to:
                            <ul>
                                <li>illegal structures,</li>
                                <li>survey/boundary defects,</li>
                                <li>encroachments,</li>
                                <li>non-compliance with zoning & development laws,</li>
                                <li>non-compliance with easements and covenants,</li>
                                <li>lack of legal access,</li>
                                <li>lack of a legal right of water supply or drainage,</li>
                                <li>fraud, forgery & identity theft and</li>
                                <li>outstanding rates, charges & taxes that will not be paid or reimbursed on settlement.</li>
                            </ul>
                        </Text>
                        <Toggle
                            id="requestKnownRiskPolicies"
                            checked={requestPolicyOptions.requestKnownRiskPolicies}
                            onText="Are you aware of any Known Risks affecting the property"
                            offText="Are you aware of any Known Risks affecting the property"
                            onChange={(event, item) => setRequestPolicyOptions("requestKnownRiskPolicies", item ? item : false)}
                            disabled={this.props.isCommercial}
                        />

                        {requestPolicyOptions.requestKnownRiskPolicies &&
                            <Stack tokens={DefaultStackTokens} >
                                <Separator />
                                <Text variant={"large"}>Please select which risks are known</Text>

                                <MessageBar messageBarType={MessageBarType.info}>
                                    You can only select 1-3 known risks.
                                </MessageBar>

                                <Toggle
                                    checked={riskInformation!.unapprovedWorks}
                                    onText="Unapproved Works"
                                    offText="Unapproved Works"
                                    onChange={(event, item) => setKnownRiskPolicyProperty("unapprovedWorks", item ? item : false)}
                                />

                                <Toggle
                                    checked={riskInformation!.encroachingStructures}
                                    onText="Encroaching Structures"
                                    offText="Encroaching Structures"
                                    onChange={(event, item) => setKnownRiskPolicyProperty("encroachingStructures", item ? item : false)}
                                />

                                <Toggle
                                    checked={riskInformation!.structureOverSewerageAndDrainage}
                                    onText="Structure Over Sewerage and Drainage"
                                    offText="Structure Over Sewerage and Drainage"
                                    onChange={(event, item) => setKnownRiskPolicyProperty("structureOverSewerageAndDrainage", item ? item : false)}
                                />


                                <Toggle
                                    checked={riskInformation!.sewerAndDrainageConnectionWithoutAnEasement}
                                    onText="Sewer and Drainage Connection Without an Easement"
                                    offText="Sewer and Drainage Connection Without an Easement"
                                    onChange={(event, item) => setKnownRiskPolicyProperty("sewerAndDrainageConnectionWithoutAnEasement", item ? item : false)}
                                />

                                <Toggle
                                    checked={riskInformation!.incompleteOrInaccurateSewerAndDrainageDiagram}
                                    onText="Incomplete or Inaccurate Sewer and Drainage Diagram"
                                    offText="Incomplete or Inaccurate Sewer and Drainage Diagram"
                                    onChange={(event, item) => setKnownRiskPolicyProperty("incompleteOrInaccurateSewerAndDrainageDiagram", item ? item : false)}
                                />

                                <TextField
                                    label="Other Known Risk"
                                    value={riskInformation!.knownRisk || ""}
                                    onChange={(ev, newValue) => setKnownRiskPolicyProperty(`knownRisk`, newValue)}
                                />
                            </Stack>
                        }
                    </Stack.Item>
                </Stack>

                <Separator />
                <Stack horizontal tokens={DefaultStackTokens}>
                    <Stack disableShrink styles={LabelColumnStackItemStyles}>
                        <Label htmlFor="policyCommercialType">Commercial Policy?</Label>
                    </Stack>
                    <Stack.Item styles={FieldColumnStackItemStyles}>
                        <Toggle
                            id="policyCommercialType"
                            checked={requestPolicyOptions.isCommercialPolicy}
                            onText="Yes"
                            offText="No"
                            onChange={(event, item) => this.props.setIsCommercial(item ?? false)}
                        />
                    </Stack.Item>
                </Stack>

                <Separator />
                <Stack horizontal tokens={DefaultStackTokens}>
                    <Stack disableShrink styles={LabelColumnStackItemStyles}>
                        <Label htmlFor="requestPoliciesForVacantLand">Is the property vacant land?</Label>
                    </Stack>
                    <Stack.Item styles={FieldColumnStackItemStyles}>
                        <Toggle
                            id="requestPoliciesForVacantLand"
                            checked={requestPolicyOptions.requestPoliciesForVacantLand}
                            onText="Yes"
                            offText="No"
                            onChange={(event, item) => setRequestPolicyOptions("requestPoliciesForVacantLand", item ? item : false)}
                        />
                    </Stack.Item>
                </Stack>

                <Separator />
                <Stack horizontal tokens={DefaultStackTokens}>
                    <Stack disableShrink styles={LabelColumnStackItemStyles}>
                        <Label htmlFor="propertyTitleType">Does the property have a Strata or Community Title?</Label>
                    </Stack>
                    <Stack.Item styles={FieldColumnStackItemStyles}>
                        <Toggle
                            id="propertyTitleType"
                            checked={actionstepMatter.isStrataOrCommunityTitle}
                            onText="Yes"
                            offText="No"
                            onChange={(event, item) => setMatterInformation(`isStrataOrCommunityTitle`, item)}
                            disabled={this.props.isCommercial}
                        />
                    </Stack.Item>
                </Stack>

                <Separator />

                <Stack horizontal tokens={DefaultStackTokens}>
                    <Stack disableShrink styles={LabelColumnStackItemStyles}>
                        <Label htmlFor="policyReference">Policy Reference</Label>
                    </Stack>
                    <Stack.Item styles={FieldColumnStackItemStyles}>
                        <TextField
                            id="policyReference"
                            value={this.props.policyReference}
                            onChange={(ev, newValue) => setPolicyReference(newValue)}
                            type="text"
                        />
                    </Stack.Item>
                </Stack>

                <Stack horizontal tokens={DefaultStackTokens}>
                    <Stack disableShrink styles={LabelColumnStackItemStyles}>
                        <Label htmlFor="settlementDate">Anticipated Settlement Date</Label>
                    </Stack>

                    <Stack.Item styles={FieldColumnStackItemStyles}>
                        <DatePicker
                            id="settlementDate"
                            showMonthPickerAsOverlay={true}
                            allowTextInput={true}
                            isRequired
                            value={new Date(actionstepMatter.settlementDate!)}
                            placeholder="Please select..."
                            formatDate={_onFormatDate}
                            onSelectDate={(newDate) => {
                                var currentDate = moment(new Date());
                                var settlementDate = moment(newDate?.toDateString());
                                settlementDate.set("hour", currentDate.get("hour"));
                                settlementDate.set("minute", currentDate.get("minute"));
                                settlementDate.set("second", currentDate.get("second"));

                                setMatterInformation("settlementDate", settlementDate);
                                setMatterInformation("settlementDateNoUTC", moment(newDate?.toDateString()).utcOffset("+00:00", true))
                            }}
                        />
                    </Stack.Item>
                </Stack>

                <Stack horizontal tokens={DefaultStackTokens}>
                    <Stack disableShrink styles={LabelColumnStackItemStyles}>
                        <Label htmlFor="purchasePrice">Purchase Price</Label>
                    </Stack>
                    <Stack.Item styles={FieldColumnStackItemStyles}>
                        <TextField
                            id="purchasePrice"
                            value={actionstepMatter.purchasePrice?.toString()}
                            onChange={(ev, newValue) => setMatterInformation("purchasePrice", newValue)}
                            type="number"
                        />
                    </Stack.Item>
                </Stack>

                <Separator />
                <h2>Property Information</h2>

                <Stack horizontal tokens={DefaultStackTokens}>
                    <Stack disableShrink styles={LabelColumnStackItemStyles}>
                        <Label htmlFor="titleInfoType">Land Title Type</Label>
                    </Stack>
                    <Stack tokens={DefaultStackTokens} styles={FormFieldStackItemStyles}>
                        <Stack styles={FormFieldStackItemStyles}>
                            <ChoiceGroup
                                id="titleInfoType"
                                options={TitleInfoTypes}
                                selectedKey={actionstepMatter.title!.titleInfoType}
                                required={true}
                                onChange={(event, item) => setMatterInformation(`title.titleInfoType`, item!.key.toString())}
                            />
                        </Stack>
                    </Stack>
                    <Stack tokens={DefaultStackTokens} styles={FieldColumnStackItemStyles} wrap>
                        <Stack styles={FormFieldStackItemStyles}>
                            <TextField
                                label="Title Reference"
                                id="titleReference"
                                disabled={actionstepMatter.title!.titleInfoType !== "Reference"}
                                value={actionstepMatter.title!.titleReference || ""}
                                errorMessage={(actionstepMatter.title!.titleInfoType !== "Reference" || !Tools.isNullOrEmptyOrUndefined(actionstepMatter.title!.titleReference)) ? "" : "Required"}
                                onChange={(ev, newValue) => setMatterInformation(`title.titleReference`, newValue)}
                            />
                        </Stack>

                        <Stack styles={FormFieldStackItemStyles}>
                            <TextField
                                label="Title Volume"
                                disabled={actionstepMatter.title!.titleInfoType !== "VolumeFolio"}
                                value={actionstepMatter.title!.titleVolume || ""}
                                errorMessage={(actionstepMatter.title!.titleInfoType !== "VolumeFolio" || !Tools.isNullOrEmptyOrUndefined(actionstepMatter.title!.titleVolume)) ? "" : "Required"}
                                onChange={(ev, newValue) => setMatterInformation(`title.titleVolume`, newValue)}
                            />
                        </Stack>

                        <Stack styles={FormFieldStackItemStyles}>
                            <TextField
                                label="Title Folio"
                                disabled={actionstepMatter.title!.titleInfoType !== "VolumeFolio"}
                                value={actionstepMatter.title!.titleFolio || ""}
                                errorMessage={(actionstepMatter.title!.titleInfoType !== "VolumeFolio" || !Tools.isNullOrEmptyOrUndefined(actionstepMatter.title!.titleFolio)) ? "" : "Required"}
                                onChange={(ev, newValue) => setMatterInformation(`title.titleFolio`, newValue)}
                            />
                        </Stack>

                    </Stack>
                </Stack>

                <Separator />
                <Stack horizontal tokens={DefaultStackTokens}>
                    <Stack disableShrink styles={LabelColumnStackItemStyles}>
                        <Label htmlFor="legalDescription">Legal Description</Label>
                    </Stack>
                    <Stack.Item styles={DoubleWidthFormFieldStackItemStyles}>
                        <TextField
                            id="legalDescription"
                            value={actionstepMatter.title!.legalDescription || ""}
                            errorMessage={!Tools.isNullOrEmptyOrUndefined(actionstepMatter.title!.legalDescription) ? "" : "Required"}
                            onChange={(ev, newValue) => setMatterInformation(`title.legalDescription`, newValue)}
                        />
                    </Stack.Item>
                </Stack>

                <Separator />
                <FTAddressInput
                    prefix="source_property_"
                    party={actionstepMatter.sourceProperty!}
                    setMatterInformation={(keyPath, newValue) => setMatterInformation("sourceProperty." + keyPath, newValue)}
                    disableCountrySelection={true}
                />

                <Separator />
                <h2>Related Parties</h2>

                {actionstepMatter.buyers != null && actionstepMatter.buyers.map((buyer, index) => {
                    return (
                        <div key={index}>
                            <Separator />
                            {buyer.identityType === IdentityType.Individual &&
                                <IndividualBuyer
                                    buyer={buyer}
                                    index={index}
                                    setMatterInformation={(key, value) => setMatterInformation(key, value)}
                                    removeBuyer={removeBuyer}
                                />
                            }

                            {buyer.identityType === IdentityType.Company &&
                                <CompanyBuyer
                                    buyer={buyer}
                                    index={index}
                                    setMatterInformation={(key, value) => setMatterInformation(key, value)}
                                    validatePartyBusinessDetails={(party) => this.validatePartyBusinessDetails(party)}
                                    removeBuyer={removeBuyer}
                                />
                            }
                        </div>
                    )
                })}

                {this.state.buyerIsCompany != null && <AddBuyerComponent isCompany={this.state.buyerIsCompany} onClickHandler={this.props.createNewBuyer} />}

                <Separator />

                {errors.length > 0
                    ?
                    <>
                        <MessageBar messageBarType={MessageBarType.blocked}>
                            Some information is missing. Please check the form above and fill in all required fields.
                        </MessageBar>
                        <ul>
                            {errors.map(error => {
                                return (
                                    <li key={error} className="errorMessage">{error}</li>
                                )
                            })}
                        </ul>
                    </>
                    :
                    <MessageBar messageBarType={MessageBarType.info}>
                        Note:
                        Your order will be placed immediately, so please review the above information before proceeding.
                        If you need to make any changes after your order is placed, please contact First Title directly.
                    </MessageBar>
                }

                <Stack.Item>
                    <PrimaryButton
                        data-cy="submit_button"
                        text="Place Order"
                        onClick={this.props.placeOrder}
                        allowDisabledFocus={true}
                        disabled={errors.length > 0}
                    />
                </Stack.Item>
            </>
        )
    }
}