import * as React from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../app.types';
import { Checkbox, Paper, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { MessageBar, MessageBarType } from '@fluentui/react';
import { ActionstepFinancialLineItems } from '../../pexaApiTypes';
import { Table } from '@material-ui/core';


const FSSLineItems: React.FunctionComponent = () => {
    const fssLineItems = useSelector((state: AppState) => state.pexa.fssLineItems);
    return (
        <div id='fssLineItems'  >
            <TableContainer component={Paper}>
                <Table ria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell className='fssLineItems-th'>Send To Pexa</TableCell>
                            <TableCell className='fssLineItems-th' >Type</TableCell>
                            <TableCell className='fssLineItems-th' >Description</TableCell>
                            <TableCell className='fssLineItems-th' >Destination Amount</TableCell>
                            <TableCell className='fssLineItems-th' >Source Amount</TableCell>
                            <TableCell className='fssLineItems-th' >Category</TableCell>
                            <TableCell className='fssLineItems-th' >Source Default Account</TableCell>
                            <TableCell className='fssLineItems-th' >Payment Method</TableCell>
                            <TableCell className='fssLineItems-th' >Saved Account - Pre Verified Account</TableCell>
                            <TableCell className='fssLineItems-th' >Account Name</TableCell>
                            <TableCell className='fssLineItems-th' >BSB No</TableCell>
                            <TableCell className='fssLineItems-th' >Account #</TableCell>
                            <TableCell className='fssLineItems-th' >BPAY Biller Code</TableCell>
                            <TableCell className='fssLineItems-th' >BPAY Reference Number</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {fssLineItems && fssLineItems.length > 0 ? fssLineItems?.map((element: ActionstepFinancialLineItems, index) =>
                            <TableRow key={index}>
                                <TableCell component="th" scope="row">
                                    <Checkbox id="fss-checkbox" checked={element.sendToPexa != undefined && element.sendToPexa === "on"} color="default" />
                                </TableCell>
                                <TableCell >{element.distribType ?? ""}</TableCell>
                                <TableCell >{element.distribDescription ?? ""}</TableCell>
                                <TableCell >{element.amount ?? ""}</TableCell>
                                <TableCell >{element.sourceAmount ?? ""}</TableCell>
                                <TableCell >{element.category ?? ""}</TableCell>
                                <TableCell >{element.defaultAc ?? ""}</TableCell>
                                <TableCell >{element.paymentMethod ?? ""}</TableCell>
                                <TableCell >{element.preVerifiedAccount ?? ""}</TableCell>
                                <TableCell >{element.accountname ?? ""}</TableCell>
                                <TableCell >{element.bsbNo ?? ""}</TableCell>
                                <TableCell >{element.account ?? ""}</TableCell>
                                <TableCell >{element.bpayBillerCode ?? ""}</TableCell>
                                <TableCell >{element.bpayReferenceNumber ?? ""}</TableCell>
                            </TableRow>
                        ) : <TableRow><TableCell colSpan={5}><MessageBar messageBarType={MessageBarType.info}> No Financial Line Items found in actionstep.</MessageBar></TableCell></TableRow>}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>

    );
}
export default FSSLineItems;