import * as React from "react";
import { connect } from "react-redux";
import VicWaterUsageModal from "./modals/VicWaterUsageModal";
import WaterUsagesCalculation from "./components/WaterUsageCalculation";

import { Modal } from "office-ui-fabric-react/lib/Modal";
import {
    generateVicWaterUsagePDF, changeState, saveSettlementMatter,
    getSettlementMatter, deleteSettlementMatter,
    clearSettlementState
} from "../settlement/redux/actions";
import * as toastr from "toastr";

import { ActionstepMatterInfo, SettlementMatterViewModel, ActionstepMatter } from "utils/vicWaterUsageApiTypes";

import { AppState } from "app.types";
import { PrimaryButton } from "@fluentui/react/lib/Button";
import * as CONSTANTS from "containers/calculators/settlement/redux/constants";
import "./VicWaterUsage.css";
import SettlementInvalidMatter from "../settlement/settlementInvalidMatter";
import { MessageBar, MessageBarType, Icon, Link } from "office-ui-fabric-react/lib";
import SettlementMatterDetails from "./components/SettlementMatter";
import { generateUIData } from "./Helper";
import { errorMessages } from "./constants";
import { apportionmentOption } from "../settlement/common";

type IProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;
type IState = {
    loadData: boolean | null;
    refresh: boolean;
    matterDetails: ActionstepMatter;
    matterInfo: ActionstepMatterInfo | null;
    isInvalidMatter: boolean;
    settlementMatter: SettlementMatterViewModel,
    isWaiting: boolean;
    hasError: boolean | null;
    orgConnected: boolean | null;
    hasWaterUsage: boolean;
    dataInputError: string;
    CommencementDateError: string;
    searchReadingError: string;
    entitlementValueError: string;
    percentageError: string;
};

export class waterUsageReading extends React.Component<IProps, IState> {
    constructor(props: any) {
        super(props);
        this.state = {
            matterDetails: new ActionstepMatter(),
            settlementMatter: new SettlementMatterViewModel(),
            loadData: null,
            refresh: false,
            isInvalidMatter: false,
            matterInfo: null,
            isWaiting: false,
            hasError: null,
            orgConnected: null,
            hasWaterUsage: false,
            dataInputError: "",
            CommencementDateError: "",
            searchReadingError: "",
            entitlementValueError: "",
            percentageError: ""
        };
    }

    updatedState: any = {
        searchDate: null,

        waterUsage: 0,
        sewerUsage: 0,

        ctsOption: "do-not-apportion",
        status: "less"
    };

    public componentDidMount(): void {
        this.loadActionstepMatter();
    }

    private loadActionstepMatter(): void {
        const { actionstepContext } = this.props;
        if (
            actionstepContext === undefined ||
            actionstepContext.matterContext === undefined ||
            actionstepContext.termsEverAccepted === undefined ||
            actionstepContext.latestTermsAccepted === undefined
        ) {
            this.setState({
                isInvalidMatter: true,
            });
            return;
        }

        const matterInfo = new ActionstepMatterInfo({
            orgKey: actionstepContext.orgKey,
            matterId: actionstepContext.matterContext?.id,
            termsEverAccepted: actionstepContext.termsEverAccepted,
            latestTermsAccepted: actionstepContext.latestTermsAccepted,
        });

        this.setState({
            matterInfo,
        });
        this.props.getSettlementMatter(matterInfo);
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>): void {
        const { loadData } = this.state;
        if (loadData !== null)
            this.loadData(false);
    }

    private updateValue(newValue: any, whichValue: string, needRefresh: boolean = false): void {
        if (whichValue !== "searchDate" && whichValue !== "ctsOption" && whichValue !== "entitlementValue") {
            newValue = (newValue === "" || isNaN(newValue)) ? 0 : newValue;
        }

        this.updatedState[whichValue] = newValue;

        if (needRefresh) {
            this.setState({
                refresh: !this.state.refresh,
            });
        }
    }

    public async loadData(useNewValue = false): Promise<void> {
        const { settlementMatter } = this.state;

        const matterDetails: ActionstepMatter = !useNewValue && settlementMatter.actionstepData
            ? settlementMatter.actionstepData
            : this.state.matterDetails;

        matterDetails.adjustmentDate = new Date(matterDetails.adjustmentDate);
        matterDetails.settlementDate = new Date(matterDetails.settlementDate);

        await this.setState({
            matterDetails: ActionstepMatter.fromJS(matterDetails),
            loadData: null,
        });

        this.props.clearSettlementState();
        this.props.changeState(matterDetails.state || "VIC");
    }

    static getDerivedStateFromProps(nextProps: IProps, prevState: IState): IState {
        let nextState = {} as IState;

        if (nextProps.gotResponse === true) {
            switch (nextProps.requestType) {
                case CONSTANTS.GENERATE_WATER_USAGE_PDF_REQUESTED:
                    nextState = {
                        ...nextState,
                        isWaiting: false,
                    };

                    if (nextProps.gotResponse === true && nextProps.success === false) {
                        toastr.error(
                            nextProps.error!.message || "Unexpected Error occured..."
                        );
                        nextState = {
                            ...nextState,
                            hasError: true,
                        };
                    }
                    break;

                case CONSTANTS.GET_SETTLEMENT_MATTER_REQUESTED:
                    if (nextProps.success === true) {
                        if (nextProps.settlementMatter !== undefined) {
                            let settlementMatter: SettlementMatterViewModel = nextProps.settlementMatter;

                            let isNew: boolean = settlementMatter.version === 0;

                            nextState = {
                                ...nextState,
                                orgConnected: true,
                                settlementMatter: settlementMatter
                            };

                            if (isNew) {
                                nextState = {
                                    ...nextState,
                                    hasError: false,
                                    loadData: false,
                                    hasWaterUsage: false,
                                    settlementMatter: settlementMatter
                                };
                            } else {
                                nextState = {
                                    ...nextState,
                                    loadData: true,
                                    hasWaterUsage: true
                                };
                            }
                        }
                    } else if (nextProps.actionstepContext === undefined || nextProps.actionstepContext.matterContext === undefined) {
                        nextState = {
                            ...nextState,
                            orgConnected: false,
                            hasError: false,
                        };
                    } else {
                        toastr.error(
                            nextProps.error!.message || "Unexpected Error occured..."
                        );
                        nextState = {
                            ...nextState,
                            hasError: true,
                        };
                    }
                    break;

                case CONSTANTS.SAVE_SETTLEMENT_MATTER_REQUESTED:
                    nextState = {
                        ...nextState,
                        isWaiting: false,
                    };

                    if (nextProps.gotResponse === true && nextProps.success === false) {
                        toastr.error(
                            nextProps.error!.message || "Unexpected Error occured..."
                        );
                        nextState = {
                            ...nextState,
                            hasError: true,
                        };
                    }
                    break;

                case CONSTANTS.DELETE_SETTLEMENT_MATTER_REQUESTED:
                    nextState = {
                        ...nextState,
                        isWaiting: false,
                    };

                    if (nextProps.gotResponse === true && nextProps.success === false) {
                        toastr.error(
                            nextProps.error!.message || "Unexpected Error occured..."
                        );
                        nextState = {
                            ...nextState,
                            hasError: true,
                        };
                    } else {
                        nextState = {
                            ...nextState,
                            // waterUsageData: new VicWaterUsageInfo()
                        };
                    }
                    break;

                default:
                    return nextState;
            }
            nextProps.clearSettlementState();
        }
        return nextState;
    }

    public render(): JSX.Element {
        const { matterDetails, settlementMatter, isInvalidMatter, orgConnected, hasError, hasWaterUsage, isWaiting } = this.state;
        const { vicWaterUsage, additionalInfo } = settlementMatter.settlementData ?? {};

        if (vicWaterUsage && Object.keys(vicWaterUsage).length > 0) {
            this.updatedState = vicWaterUsage;
            this.updatedState["searchDate"] = new Date(vicWaterUsage["searchDate"]);
        }
        else if (!hasWaterUsage && additionalInfo) {
            this.updateValue(additionalInfo!["waterUsage"], "waterUsage");
            this.updateValue(additionalInfo!["sewerUsage"], "sewerUsage");
        }

        return (
            <div className="settlement-calculator">
                {isInvalidMatter ?
                    (<SettlementInvalidMatter />
                    ) : orgConnected === true ? (
                        <div className="wrapper-content animated fadeIn vertical-container">
                            <div className="ibox">
                                <div className="ibox-content">
                                    <SettlementMatterDetails matterDetails={matterDetails} />
                                    {hasWaterUsage ?
                                        <WaterUsagesCalculation waterUsage={vicWaterUsage!} edit={() => this.edit()} />
                                        :
                                        <VicWaterUsageModal updateValue={(newValue: any, whichValue: string, needRefresh = false) => this.updateValue(newValue, whichValue, needRefresh)}
                                            updatedState={this.updatedState}
                                            stateValue={this.state}
                                        />
                                    }
                                </div>

                                <div className="ibox-footers">
                                    {hasWaterUsage ?
                                        <PrimaryButton
                                            className="button"
                                            data-automation-id="save_button"
                                            data-cy="generate_button"
                                            text="Generate a PDF"
                                            onClick={() => this.generatePDF()}
                                            allowDisabledFocus={true}
                                        />
                                        :
                                        <>
                                            {this.state.dataInputError !== "" &&
                                                <div className="display-error">
                                                    <MessageBar
                                                        messageBarType={MessageBarType.severeWarning}>
                                                        {this.state.dataInputError}
                                                    </MessageBar>
                                                </div>
                                            }

                                            <PrimaryButton
                                                className="button"
                                                data-automation-id="cancel_button"
                                                data-cy="save_button"
                                                text="Cancel"
                                                onClick={() => this.cancel()}
                                                allowDisabledFocus={true}
                                            />

                                            <PrimaryButton
                                                className="button"
                                                data-automation-id="save_button"
                                                data-cy="save_button"
                                                text="OK"
                                                onClick={() => this.save()}
                                                allowDisabledFocus={true}
                                            />
                                        </>
                                    }
                                </div>
                            </div >

                            <div data-cy="modal-header" />
                            <Modal
                                isOpen={isWaiting}
                                isBlocking={true}
                                className={isWaiting !== null ? "animated fadeIn settlement-calculator" : "animated fadeOut settlement-calculator"}
                            >
                                <div className="modal-body">
                                    <div className="ms-Grid-row loading-modal">
                                        <img
                                            src="/images/Konekta_loading.svg" alt="Logo" height="150" />
                                        <h2>Please wait...</h2>
                                    </div>
                                </div>
                            </Modal>
                        </div >
                    ) : hasError === true ? (
                        <div className="wrapper wrapper-content animated fadeInRight vertical-container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="panel panel-danger">
                                        <div className="panel-heading">
                                            <Icon iconName="StatsCircleExclamation" />{" "}
                                            <span className="m-l-xs">Sorry, something went wrong</span>
                                        </div>
                                        <div className="panel-body p-v-xs">
                                            <p className="text-danger">
                                                Please contact{" "}
                                                <Link
                                                    href={
                                                        "mailto:support@konekta.com.au?subject=Victoria%20Water%20Usage%20Calculator"
                                                    }
                                                >
                                                    {" "}
                                                    support@konekta.com.au
                                                </Link>
                                                .
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : hasError === false ? (
                        <div className="wrapper wrapper-content animated fadeInRight vertical-container">
                            <MessageBar messageBarType={MessageBarType.error}>
                                You're not connected to <strong>Konekta</strong>.
                            </MessageBar>
                        </div>
                    ) : (
                        <div className="loading-widget">
                            <img src="/images/Konekta_loading.svg" alt="Logo" height="150" width="150" />{" "}
                            Loading...
                        </div>
                    )
                }
            </div>
        );
    }

    private save() {

        const isFormValid = this.validateForm();
        if (isFormValid) {

            this.updatedState["adjustedWaterUsage"] = this.updatedState["waterUsage"];
            this.updatedState["adjustedSewerUsage"] = this.updatedState["sewerUsage"];

            let waterUsageData = generateUIData(this.updatedState, this.state.matterDetails, this.state.settlementMatter);

            this.props.saveSettlementMatter(waterUsageData);

            this.setState({
                settlementMatter: waterUsageData,
                hasWaterUsage: true,
                CommencementDateError: "",
                searchReadingError: "",
                entitlementValueError: "",
                percentageError: ""
            });
        }
    }

    private generatePDF() {
        let waterUsageData = generateUIData(this.updatedState, this.state.matterDetails, this.state.settlementMatter);

        if (waterUsageData.settlementData?.additionalInfo != null) {
            waterUsageData.settlementData.additionalInfo["isAdjustment"] = false;
            waterUsageData.settlementData.additionalInfo["saveToActionstep"] = false;
        }

        this.props.generateVicWaterUsagePDF(waterUsageData);

        this.setState({
            isWaiting: true,
        });
    }

    private edit() {
        this.setState({
            hasWaterUsage: false,
            dataInputError: ""
        })
    }

    private cancel() {
        let hasWaterUsages = this.state.settlementMatter.settlementData?.vicWaterUsage ? true : false;
        this.setState({
            hasWaterUsage: hasWaterUsages,
            dataInputError: ""
        })
    }

    private validateForm(): boolean {

        let contentLength = this.updatedState["entitlementValue"] ? this.updatedState["entitlementValue"].toString().split('/').length : 0

        if (this.updatedState["searchDate"] === null) {
            this.setState({
                dataInputError: errorMessages.dateInputError,
                CommencementDateError: "",
                searchReadingError: "error-red-border",
                entitlementValueError: "",
                percentageError: ""
            });
            return false;
        }
        if (this.updatedState["ctsOption"] === apportionmentOption.entitlement && (this.updatedState["entitlementValue"] ? false : true)) {
            this.setState({
                dataInputError: errorMessages.entitlementValueFormatError,
                CommencementDateError: "",
                searchReadingError: "",
                entitlementValueError: "error-red-border",
                percentageError: ""
            });
            return false;
        }
        else if (this.updatedState["ctsOption"] === apportionmentOption.entitlement && (contentLength == 1 || contentLength > 2)) {
            this.setState({
                dataInputError: errorMessages.entitlementValueFormatError,
                CommencementDateError: "",
                searchReadingError: "",
                entitlementValueError: "error-red-border",
                percentageError: ""
            });
            return false;
        }
        else if (this.updatedState["ctsOption"] === apportionmentOption.sharedPercentage && (this.updatedState["percentage"] ? false : true)) {
            this.setState({
                dataInputError: errorMessages.sharedPercentageError,
                CommencementDateError: "",
                searchReadingError: "",
                entitlementValueError: "",
                percentageError: "error-red-border"
            });
            return false;
        }
        return true;
    }
}

const mapStateToProps = (state: AppState) => {
    return {
        state: state.settlementInfo.state,
        actionstepContext: state.common.actionstepContext,
        success: state.settlementInfo.success,
        gotResponse: state.settlementInfo.gotResponse,
        settlementMatter: state.settlementInfo.settlementMatter,
        actionstepPDF: state.settlementInfo.actionstepPDF,
        requestType: state.settlementInfo.requestType,
        error: state.settlementInfo.error,
    };
};

const mapDispatchToProps = {
    generateVicWaterUsagePDF,
    getSettlementMatter,
    saveSettlementMatter,
    deleteSettlementMatter,
    changeState,
    clearSettlementState
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(waterUsageReading);