import {
    ActionstepMatterInfo,
    RequestPropertyInformationFromActionstepResponse,
    ErrorViewModel
} from 'utils/wcaApiTypes'

import { GlobalXEvents } from './events'
import { IActionUnion } from 'utils/reduxHelpers';

export const makeAction = <T extends GlobalXEvents, P>(type: T) => (payload: P) => {
    return {
        type,
        payload,
    }
};

export const makeActionWithoutPayload = <T extends GlobalXEvents>(type: T) => () => {
    return {
        type,
    }
};

export interface getPropertyInformationRequestedPayload {
    matterInfo: ActionstepMatterInfo,
    entryPoint: string,
    embed: boolean,
}

export const getCdmInformation = makeAction<GlobalXEvents.GET_PROPERTY_INFORMATION_REQUESTED, getPropertyInformationRequestedPayload>(GlobalXEvents.GET_PROPERTY_INFORMATION_REQUESTED);
export const getCdmInformationSuccess = makeAction<GlobalXEvents.GET_PROPERTY_INFORMATION_SUCCESS, RequestPropertyInformationFromActionstepResponse>(GlobalXEvents.GET_PROPERTY_INFORMATION_SUCCESS);
export const getCdmInformationFailed = makeAction<GlobalXEvents.GET_PROPERTY_INFORMATION_FAILED, ErrorViewModel>(GlobalXEvents.GET_PROPERTY_INFORMATION_FAILED);

const globalXActions = {
    getCdmInformation,
    getCdmInformationSuccess,
    getCdmInformationFailed,
};

export type IGlobalXAction = IActionUnion<typeof globalXActions>;