import { IDropdownOption } from "@fluentui/react";

export enum ModalIDs {
    matterDetails = 'Matter Details',
    addAdjustment = 'Add Adjustment',
    addUpdateHeader = 'Add / Edit Header',

    contractPrice = 'Contract Price',
    releaseFee = 'Release Fee',
    dischargeFee = 'Discharge Fee',
    waterUsage = 'Water Usage',
    councilRates = 'Council Rates',
    licenceFees = 'Licence Fees',
    waterAccessFee = 'Water Access Fee',
    sewerageAccessFee = 'Sewerage Access Fee',
    administrationFund = 'Administration Fund',
    sinkingFund = 'Sinking Fund',
    insurance = 'Insurance',
    penaltyInterest = 'Penalty Interest',
    otherAdjustmentLegacy = 'Other Adjustment',
    otherAdjustmentDateLegacy = 'Other Adjustment Date',
    otherAdjustment = 'Other Adjustment - Fixed Amount',
    otherAdjustmentDate = 'Other Adjustment - Date Period',
    strataLevies = 'Strata Levies',
    waterDrainageFee = 'Water Drainage Fee',
    parksCharge = 'Parks Charge',
    waterServiceCharge = 'Water Service Charge',
    sewerageUsage = 'Sewerage Usage',
    maintenanceFund = 'Maintenance Fund',
    landTax = 'Land Tax',
    rent = 'Rent',
    ownersCorporationFees = 'Owners Corporation Fees',
    councilRatesChargesLevies = 'Council Rates, Charges, Levies',
    waterRatesChargesLevies = 'Water Rates, Charges & Levies',
    sewerageServiceCharge = 'Sewerage Service Charge',
    ownersAdministrationFundFee = 'Owners Corporation - Administration Fund Fee',
    ownersMaintenanceFundFee = 'Owners Corporation - Maintenance Fund Fee',
    ownersSinkingFundFee = 'Owners Corporation - Sinking Fund Fee',
    ownersInsurance = 'Owners Corporation - Insurance',
    DrainageCharge = 'Drainage Charge',
    emergencyServicesLevy = 'Emergency Services Levy',
    waterAndSewerageRates = 'Water and Sewerage Rates',
    WaterRates = 'Water Rates',
    fee = 'Fee',
    additionalRequirements = 'Additional Requirements',
    payeeDetails = 'Payee Details',
    sourceOfFunds = 'Source Of Funds',
    ourRequirements = 'Our Requirements',
    SewerageRates = 'Sewerage Rates',
    recycledWater = 'Recycled Water',
    localLandServices = 'Local Land Services',
    waterAvailability = 'Water Availability',
    defaultInterest = 'Default Interest',
    SAWaterSupplyAndSewerage = 'SA Water Supply and Sewerage'
}

export enum apportionmentOption {
    doNotApportion = 'do-not-apportion',
    sharedPercentage = 'shared-percentage',
    entitlement = 'entitlement'
}

export enum periodOptions {
    Daily = 'Daily',
    Week = 'Week',
    Month = 'Month',
    Quarter = 'Quarter',
    OneyByThreeYear = '1/3Year',
    HalfYearFinancial = 'HalfYearFinancial',
    HalfYearCalendar = 'HalfYearCalendar',
    AnnualFinancial = 'AnnualFinancial',
    AnnualCalendar = 'AnnualCalendar',
    Other = 'Other',
}

export enum stateName {
    NSW = 'NSW',
    QLD = 'QLD',
    VIC = 'VIC',
    SA = 'SA',
    WA = 'WA',
}

export const sourceOfFunds: IDropdownOption[] = [
    { key: "TrustAccount", text: "Trust Account" },
    { key: "HomeLoanAtSettlement", text: "Home Loan - At Settlement" },
    { key: "OffsetAccount", text: "Offset Account" },
    { key: "SettlementFunds", text: "Settlement Funds" },
    { key: "SettlementShortfall", text: "Settlement Shortfall" },
    { key: "AdditionalFundNeeded", text: "Additional Funds Needed for Settlement" },
    { key: "BalanceOfDeposit", text: "Balance of Deposit" },
    { key: "DepositFunds", text: "Deposit Funds" },
    { key:  "SaleSettlementProceeds", text: "Sale Settlement Proceeds"},
    { key:  "FHOG", text: "FHOG"}
];

export const fundsTypes: IDropdownOption[] =
    [
        { key: "Electronic", text: "Electronic" },
        { key: "BankCheque", text: "Bank Cheque" },
        { key: "TrustCheque", text: "Trust Cheque" },
        { key: "PersonalCheque", text: "Personal Cheque" }
    ];

export enum TypeOfStatement {
    VendorStatement = "Vendor Statement",
    PurchaserStatement = "Purchaser Statement",
    AdjustmentStatement = "Adjustment Statement",
    ClientSettlementStatement = "Client Settlement Statement",
    SettlementStatement = "Settlement Statement"
}

export enum waterUsagesMethod {
    DailyAverage = 'daily-average',
    SearchReading = 'search-reading'
}

export const errorMessages = {
    dateInputError: "Please input all the dates...",
    entitlementValueFormatError: "Invalid Entitlement...",
    sharedPercentageError: "Please input percentage...",
    adjustmentBillingPeriodError: "Please select billing & adjustment period",
    headerNameRequiredError: "Please input a header name",
};