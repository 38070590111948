import * as React from 'react'
import { FunctionComponent } from 'react'
import { Text, Link, Spinner, SpinnerSize, HoverCard, HoverCardType, IPlainCardProps, mergeStyleSets } from 'office-ui-fabric-react';
import { useSelector } from 'react-redux';
import { AppState } from 'app.types';
import { IDataKonektaFormSubmissionDto } from 'utils/wcaApiTypes';
import useAxios from 'axios-hooks';

const DataKonektaMatterLink: FunctionComponent = () => {
    const actionstepContext = useSelector((state: AppState) => state.common.actionstepContext);
    const orgKey = actionstepContext?.orgKey;
    const matterId = actionstepContext?.matterContext?.id;
    const [{ data: formSubmission, loading, error }] = useAxios<IDataKonektaFormSubmissionDto | undefined>(
        `/api/data-konekta/form-submission/${orgKey}/${matterId}`
    );

    if (loading) return (
        <Text variant="xSmall"><Spinner size={SpinnerSize.xSmall} /> Loading form status</Text>
    );

    const classNames = mergeStyleSets({
        card: {
          width: 300,
          height: 200,
          display: 'flex',
        },
    });

    const onRenderCard = (): JSX.Element =>
        <div className={classNames.card}>
            <ul>
                {formSubmission?.attachments?.map((a,i) =>
                    <li key={i}>
                        {a.fileName} {a.linkedToMatter ? "Linked" : "Not Linked"}
                    </li>
                )}
                {formSubmission?.clientIPAddress &&
                    <Text variant="small" color="red">Submitted by {formSubmission.clientIPAddress}</Text>
                }
            </ul>
        </div>

    const cardProps: IPlainCardProps = {
        onRenderPlainCard: onRenderCard,
    };

    return (
        <>
            {error?.message ?
                <Text variant="small" color="red">Error loading form status</Text>
                :
                <HoverCard
                    cardDismissDelay={2000}
                    type={HoverCardType.plain}
                    plainCardProps={cardProps}
                >
                    {formSubmission?.actionstepMatterId ?
                        <Link>Linked</Link>
                        :
                        <Link>Not Linked</Link>
                    }
                </HoverCard>
            }
        </>
    )
}

export default DataKonektaMatterLink;