import { LegalAidActionstepDataViewModel, ActionstepProviderList } from '../utils/LegalAidWCAApiTypes';
import { GenerateLegalAidPDFSuccess, GetLegalAidMatterDetailsFromActionstepSuccess, GetActionstepProviderDetailsSuccess, LegalAidActiontypes } from './actionTypes';
import * as CONSTANTS from './constants'

export interface ILegalAidState {
    legalAidActionstepDataResponse: LegalAidActionstepDataViewModel | undefined
    gotResponse: boolean,
    success: boolean
    requestType: string,
    generatePdfResponse: boolean,
    providerList: ActionstepProviderList | null
}

const initialState: ILegalAidState = {
    legalAidActionstepDataResponse: new LegalAidActionstepDataViewModel(),
    gotResponse: false,
    success: false,
    requestType: '',
    generatePdfResponse: false,
    providerList: new ActionstepProviderList()
}

export
    function legalAidReducer(state: ILegalAidState = initialState, action: LegalAidActiontypes): ILegalAidState {
    switch (action.type) {
        case CONSTANTS.GET_PROVIDER_DETAILS_REQUESTED:
            return {
                ...state,
                requestType: action.type,
            };
        case CONSTANTS.GET_PROVIDER_DETAILS_SUCCESS:
            return {
                ...state,
                gotResponse: true,
                success: true,
                requestType: action.type,
                providerList: (action as GetActionstepProviderDetailsSuccess).data
            };
        case CONSTANTS.GET_PROVIDER_DETAILS_FAILED:
            return {
                ...state,
                gotResponse: true,
                requestType: action.type,
            };
        case CONSTANTS.GET_MATTER_DETAILS_FROM_ACTIONSTEP_REQUESTED:
            return {
                ...state,
                requestType: action.type,
            };
        case CONSTANTS.GET_MATTER_DETAILS_FROM_ACTIONSTEP_SUCCESS:
            return {
                ...state,
                gotResponse: true,
                success: true,
                requestType: action.type,
                legalAidActionstepDataResponse: (action as GetLegalAidMatterDetailsFromActionstepSuccess).data
            };
        case CONSTANTS.GET_MATTER_DETAILS_FROM_ACTIONSTEP_FAILED:
            return {
                ...state,
                gotResponse: true,
                requestType: action.type,
            };
        case CONSTANTS.GENERATE_LEGALAID_PDF_REQUESTED:
            return {
                ...state,
                gotResponse: false,
                requestType: action.type,
            };
        case CONSTANTS.GENERATE_LEGALAID_PDF_SUCCESS:
            return {
                ...state,
                requestType: action.type,
                gotResponse: true,
                success: true,
                generatePdfResponse: (action as GenerateLegalAidPDFSuccess).data
            };
        case CONSTANTS.GENERATE_LEGALAID_PDF_FAILED:
            return {
                ...state,
                gotResponse: true,
                requestType: action.type,
            };
        default:
            return state;
    }
}

export default legalAidReducer;