import * as CONSTANTS from './constants'
import {
    ActionstepClientInfo,
    ErrorViewModel
} from 'containers/rapidBuildingInspections/utils/rapidBuildingInspectionAPITypes';

import {
    GetClientDetailSuccess,
    RPIActionTypes,
    ActionFailed,
    SendRBIRequestSuccess
} from 'containers/rapidBuildingInspections/redux/actionTypes';

export interface RPIClientDetailState {
    userdetailData: ActionstepClientInfo | undefined,
    requestType: string,
    gotResponse: boolean,
    success: boolean,
    error: ErrorViewModel | undefined,
    rbiResponseDetails: string | undefined
}

const initialState: RPIClientDetailState = {
    userdetailData: undefined,
    requestType: "",
    gotResponse: false,
    success: false,
    error: undefined,
    rbiResponseDetails: undefined
}


function rapidBuildingInspectionsReducer(state: RPIClientDetailState = initialState, action: RPIActionTypes): RPIClientDetailState {

    switch (action.type) {
        case CONSTANTS.SEND_RBI_DETAILS_REQUESTED:
            return {
                ...state,
                requestType: action.type,
                gotResponse: false,
                success: false
            };
        case CONSTANTS.GET_CLIENT_DETAILS_REQUESTED:
            return {
                ...state,
                requestType: action.type,
                gotResponse: false,
                success: false
            };

        case CONSTANTS.GET_CLIENT_DETAILS_SUCCESS:
            return {
                ...state,
                requestType: action.type,
                gotResponse: true,
                success: true,
                userdetailData: (action as GetClientDetailSuccess).data
            };
        case CONSTANTS.SEND_RBI_DETAILS_SUCCESS:
            return {
                ...state,
                requestType: action.type,
                gotResponse: true,
                success: true,
                rbiResponseDetails: (action as SendRBIRequestSuccess).data
            };
        case CONSTANTS.SEND_RBI_DETAILS_FAILED:
            return {
                ...state,
                requestType: action.type,
                gotResponse: true,
                success: false,
                error: (action as ActionFailed).error
            };

        case CONSTANTS.GET_CLIENT_DETAILS_FAILED:
            return {
                ...state,
                requestType: action.type,
                gotResponse: true,
                success: false,
                error: (action as ActionFailed).error
            };

        case CONSTANTS.CLEAR_RBI_INFO_STATE:
            return {
                ...state,
                gotResponse: false,
                success: false,
                userdetailData: undefined,
                error: undefined,
                requestType: "",
                rbiResponseDetails: undefined
            };

        default:
            return state;
    }
}


export default rapidBuildingInspectionsReducer;