import React from 'react';
import * as CONSTANTS from 'containers/ecaf/redux/constants';
import { connect } from 'react-redux';

import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { PrimaryButton } from 'office-ui-fabric-react';
import { MessageBar, MessageBarType } from 'office-ui-fabric-react';
import { Spinner } from 'office-ui-fabric-react/lib/Spinner';

import { AppState } from 'app.types';

import { checkECAFCredentials, getActionstepOrgs } from '../redux/actions';

import "./ecafAuth.css"
import { ECAFCredentials } from 'utils/wcaApiTypes';
import { Dropdown, IDropdownOption } from '@fluentui/react/lib/Dropdown';
import { Redirect } from 'react-router-dom';


interface IAppProps {

}

type AppProps = IAppProps & ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;


type AppStates = {
    apiKey: string | undefined;
    actionstepOrgKey: string | undefined,
    hasError: boolean;
    errorMessage: string | undefined;
    isLoading: boolean;
    redirect: string | undefined;
    fromManageAddons:boolean
}

export class ECAFAuth extends React.Component<AppProps, AppStates> {
    constructor(props: AppProps) {
        super(props);

        this.state = {
            apiKey: "",
            actionstepOrgKey: "",
            hasError: false,
            errorMessage: undefined,
            isLoading: false,
            redirect: "",
            fromManageAddons:false
        };
    }

    componentDidMount(): void {
        this.loadStatus();
        this.props.getActionstepOrgs();
    }

    loadStatus = () => {
        if (window.location.pathname.includes("/ecaf/onboard")) {
            this.setState({ fromManageAddons: true });
        } else {
            this.setState({ fromManageAddons: false });
        }
    }

    onChangeAPIKey = (newApiKey: string | undefined) => {
        this.setState({
            apiKey: newApiKey,
            hasError: false
        });
    }
    changeActionstepOrg = (newOrg: string | undefined)=>{
        this.setState({
            actionstepOrgKey: newOrg,
            hasError: false
        });
    }

    getOrgDropDown = () => {
        const { actionstepOrgs } = this.props;
        let orgOptions: IDropdownOption[] = [];
        if (typeof actionstepOrgs !== 'undefined') {
            actionstepOrgs.forEach(element => {
                orgOptions.push({
                    key: element.actionstepOrgKey!,
                    text: element.actionstepOrgKey!
                });
            })
        }
        return orgOptions;
    }

    redirectToHome = () => {
        const { fromManageAddons } = this.state;
        if (fromManageAddons) {
            this.setState({ redirect: "/manage-add-ons" });
        } else {
            this.setState({ redirect: "/matter" });
        }
    }

    connectECAF = () => {

        const { actionstepOrgKey,apiKey } = this.state;

        const params = new ECAFCredentials({
            actionstepOrgKey,
            apiKey
        });

        this.props.checkECAFCredentials(params);

        this.setState({
            isLoading: true,
            hasError: false
        })
    }


    static getDerivedStateFromProps(nextProps: AppProps, prevState: AppStates): AppStates {
        let nextState = {} as AppStates;

        if (nextProps.gotResponse) {
            if (nextProps.requestType === CONSTANTS.CHECK_ECAF_CREDENTIAL_REQUESTED) {
                nextState.isLoading = true;
                nextState.hasError = false;
            } else if (nextProps.requestType === CONSTANTS.CHECK_ECAF_CREDENTIAL_SUCCESS) {
                nextState.isLoading = false;

                if (nextProps.isValidCredentials) {
                    nextState.hasError = false;
                } else {
                    nextState.hasError = true;
                    nextState.errorMessage = "Invalid API Key";
                }
            } else if (nextProps.requestType === CONSTANTS.CHECK_ECAF_CREDENTIAL_FAILED) {
                nextState.isLoading = false;

                nextState.hasError = true;
                nextState.errorMessage = nextProps.error?.message;
            }
        }

        return nextState;
    }

    render(): JSX.Element {
        const { apiKey, hasError, errorMessage, isLoading, fromManageAddons } = this.state;
        const { actionstepContext } = this.props;

        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }

        if (this.props.isValidCredentials) {
            return (
                <>
                    <div className="ms-Grid ecaf-auth-wrapper">
                        <div className="ms-Grid-row" >
                            <div className="ms-Grid-col ms-sm12">
                                <h3>Livesign connected.</h3>
                            </div>
                        </div>
                        <div className="ms-Grid-row" >
                            <div className="ms-Grid-col ms-sm12">
                                <PrimaryButton onClick={() => this.redirectToHome()} >Continue</PrimaryButton>
                            </div>
                        </div>
                    </div>
                </>
            )
        }

        return (
            <>
                {!fromManageAddons &&
                <div className="ecaf-auth-section">
                    <h3>
                        You're not connected to livesign. <br /><br />
                        <a className="btn btn-primary" target="_blank" rel="noopener noreferrer" href={`/api/ecaf/onboard?actionstepOrg=${actionstepContext?.orgKey}&matterId=${actionstepContext?.matterContext?.id}`}>
                            Get your account from livesign.
                        </a>
                    </h3>
                </div>}

                {fromManageAddons &&
                <div className="ecaf-auth-section">
                    <h3>
                        You're not connected to livesign. <br />
                    Please enter your livesign credentials to get connected.
                </h3>

                    <div className="ecaf-auth-row">
                        <TextField
                            label="API Key:"
                            value={apiKey}
                            onChange={(ev, newValue) => this.onChangeAPIKey(newValue)}
                        />
                    </div>
                    <div className="ecaf-auth-row">
                        <Dropdown
                            options={this.getOrgDropDown()}
                            data-cy="ecaf_actionstep_orgs"
                            placeholder="Please select..."
                            onChange={(event, item) => this.changeActionstepOrg(item!.key.toString())}
                        />
                    </div>

                    {isLoading &&
                        <div className="ecaf-auth-row">
                            <Spinner label="Please wait..." ariaLive="assertive" labelPosition="right" />
                        </div>
                    }

                    {hasError &&
                        <div className="ecaf-auth-row">
                            <MessageBar messageBarType={MessageBarType.error}>
                                {errorMessage}
                            </MessageBar>
                        </div>
                    }

                    <div className="ecaf-auth-row">
                        <PrimaryButton text="Connect ECAF" disabled={isLoading}
                            onClick={() => this.connectECAF()}
                        />
                    </div>
                </div>}
            </>
        );
    }
}

const mapStateToProps = (state: AppState) => {
    return {
        actionstepContext: state.common.actionstepContext,
        requestType: state.eCaf.requestType,
        gotResponse: state.eCaf.gotResponse,
        success: state.eCaf.success,
        isValidCredentials: state.eCaf.isValidCredentials,
        error: state.eCaf.error,
        actionstepOrgs: state.eCaf.actionstepOrgs
    }
}

const mapDispatchToProps = {
    checkECAFCredentials,
    getActionstepOrgs
}


export default connect(mapStateToProps, mapDispatchToProps)(ECAFAuth);