import * as React from 'react'
import 'containers/rapidBuildingInspections/rapidBuildingInspection.css'
import { PrimaryButton } from "office-ui-fabric-react/lib/Button";
import { Redirect } from 'react-router-dom';
import { AppState } from 'app.types';

type IState = {
    redirect: string,
}

type IProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

export default class Confirmation extends React.Component<IProps, IState> {
    constructor(props: any) {
        super(props);
        this.redirectToHome = this.redirectToHome.bind(this);
        this.state = { redirect: "" };
    }
    private redirectToHome() {
        this.setState({ redirect: "/matter" });
    }
    public render(): JSX.Element {

        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }
        return (
            <div className="ms-Grid-row state-row first-title-question-wrapper">
                <div className="ms-Grid-col ms-sm12 divconfiormation">
                    <div className="confirmationdivset">
                        <p className="txtmessage">Request Submitted</p>
                    </div>
                </div>

                <div className="ms-Grid-col ms-sm12 divconfiormationspan">
                    <p className="confirmationmessage">
                        <span className="fontstyle">A Rapid Building Inspections</span><span className="msgfontstyme"> Team Member will be in the contact with client.</span>
                    </p>
                </div>
                <div className="buttonplacecenter">
                    <div className="ms-Grid-col doc-return-button buttonsize" dir="rtl">
                        <PrimaryButton
                            text="Return to Konekta"
                            onClick={this.redirectToHome} />
                    </div>
                </div>

            </div>
        )
    }
}

const mapStateToProps = (state: AppState) => {
    return {
    }
}
const mapDispatchToProps = {
}