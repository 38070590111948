import * as React from 'react';
import { connect } from 'react-redux';

import { DefaultButton } from 'office-ui-fabric-react/lib/Button'
import { AppState, ReduxStatus } from 'app.types';
import LoadingWidget from 'components/common/loadingWidget';
import { MessageBar, MessageBarType } from 'office-ui-fabric-react/lib/MessageBar';

import { InviteParticipant } from 'containers/pexa/steps/components/inviteParticipant';

import { CreateWorkspaceInvitationRequestType, SaveUserAuditTrailDataCommand, UserAuditTrail, WorkspaceSummaryResponseType } from 'utils/wcaApiTypes';

import 'containers/pexa/inviteParticipants.css'

import { createInvitation, getInvitationUri, getPexaWorkspaceSummary, savePexaAudit } from 'containers/pexa/redux/actions';
import { saveUserAuditTrailData } from 'redux/common/actions';
import { PexaWorkspaceIdAndRole, SavePexaAuditParams, SubscriberIdAndName } from '../../../../utils/newWcaApiTypes';


interface IMapStateToProps {
    openFromIframe: boolean;
}

interface IAppProps {
    closeInviteModal: () => void;
}

type AppProps = IAppProps & IMapStateToProps & ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

type AppStates = {
    participants: JSX.Element[],
    participantRefs: React.RefObject<InviteParticipant>[],
    dataStatus: ReduxStatus,
    refresh: boolean,
    pexaWorkspaceIdAndRole: PexaWorkspaceIdAndRole | undefined,
    invitationUri: string | undefined,
    displayForm: boolean,
    gotInvitationUri: boolean,
    initialRef: React.RefObject<InviteParticipant>,
    sentInvitation: boolean,
    sentInvitationToSubscribers: SubscriberIdAndName[]
}

export class ParticipantsInvitationForm extends React.Component<AppProps, AppStates> {

    constructor(props: AppProps) {
        super(props);
        const participants: JSX.Element[] = []
        const firstRef = React.createRef<InviteParticipant>();

        this.state = {
            participantRefs: [firstRef],
            participants: participants,
            dataStatus: ReduxStatus.NotRequested,
            refresh: true,
            pexaWorkspaceIdAndRole: undefined,
            invitationUri: undefined,
            displayForm: true,
            gotInvitationUri: false,
            initialRef: firstRef,
            sentInvitation: false,
            sentInvitationToSubscribers: []
        }
    }

    public componentDidMount(): void {
        this.getPexaInformation();
    }

    private async getPexaInformation(): Promise<void> {
        const { pexaWorkspaceCreationData, pexaWorkspaceResponse } = this.props;

        let workspaceIdAndRole = new PexaWorkspaceIdAndRole;
        let invitationUri: string | undefined;

        if (pexaWorkspaceResponse) { // updated when pexa workspace created, openFromIframe = false
            workspaceIdAndRole.workspaceId = pexaWorkspaceResponse.workspaceId!;
            workspaceIdAndRole.workspaceRole = pexaWorkspaceResponse.workspaceUri?.split("=")[1];

            invitationUri = pexaWorkspaceResponse!.invitationUri!;
            let participants: JSX.Element[];
            participants = [(<InviteParticipant participantIndex={0}
                invitationUri={invitationUri}
                removeParticipant={this.removeParticipant}
                onChange={this.onChangeInput}
                key="0" ref={this.state.initialRef}
                OnSubsciberUpdate={this.OnSubsciberUpdate}
                workspaceSummary={this.getWorkspaceSummary()} />)];

            this.setState({
                invitationUri: invitationUri,
                participants: participants,
                pexaWorkspaceIdAndRole: workspaceIdAndRole,
                gotInvitationUri: true
            });

        } else { // pexaWorkspaceCreationData updated when click in the link in Pexa Iframe, or workspace created 

            workspaceIdAndRole.workspaceId = pexaWorkspaceCreationData.existingPexaWorkspace?.pexaWorkspaceId!;
            workspaceIdAndRole.workspaceRole = pexaWorkspaceCreationData.existingPexaWorkspace?.pexaWorkspaceUri?.split("=")[1];

            this.props.getInvitationUri(workspaceIdAndRole);

            this.setState({
                pexaWorkspaceIdAndRole: workspaceIdAndRole
            });
        }


    }
    private getWorkspaceSummary(): WorkspaceSummaryResponseType {

        const { pexaWorkspaceCreationData, pexaWorkspaceResponse, workspaceSummaryList } = this.props;
        let workspaceId = '';
        if (pexaWorkspaceResponse) {
            workspaceId = pexaWorkspaceResponse.workspaceId!;
        }
        else {
            workspaceId = pexaWorkspaceCreationData.existingPexaWorkspace?.pexaWorkspaceId!;
        }
        return workspaceSummaryList[workspaceId].data ?? new WorkspaceSummaryResponseType;

    }

    static getDerivedStateFromProps(nextProps: AppProps, prevState: AppStates): AppStates {
        let nextState = {} as AppStates;

        if (prevState.dataStatus === ReduxStatus.Requested && nextProps.invitationResponseList) {
            if (nextProps.invitationResponseList.status === ReduxStatus.Success) {
                nextState.dataStatus = ReduxStatus.Success;
                nextState.sentInvitation = true;

            } else if (nextProps.invitationResponseList.status === ReduxStatus.Failed) {
                nextState.dataStatus = ReduxStatus.Failed;
            }
        }
        return nextState;
    }

    public componentDidUpdate(prevProps: AppProps): void {

        if (this.props.invitationUriFromProps !== prevProps.invitationUriFromProps) {
            let participants: JSX.Element[] = [];

            participants = [(<InviteParticipant participantIndex={0}
                invitationUri={this.props.invitationUriFromProps}
                removeParticipant={this.removeParticipant}
                onChange={this.onChangeInput}
                key="0" ref={this.state.initialRef}
                OnSubsciberUpdate={this.OnSubsciberUpdate}
                workspaceSummary={this.getWorkspaceSummary()} />)];

            this.setState({
                participants: participants,
                invitationUri: this.props.invitationUriFromProps,
                gotInvitationUri: true
            });
        }
    }

    private goBackToForm(): void {
        this.setState({
            dataStatus: ReduxStatus.NotRequested,
            displayForm: true
        });
    }


    private saveUserAuditTrailDataIntoDb(): void {
        const { sentInvitation, sentInvitationToSubscribers } = this.state;
        const { invitationResponseList, actionstepContext } = this.props;

        if (sentInvitation) {
            let isAllInvitionSuccess = sentInvitationToSubscribers.length === invitationResponseList!.data!.length;

            for (let i = 0; i < invitationResponseList!.data!.length; i++) {

                let userAuditTrail = new UserAuditTrail();
                userAuditTrail.actionstepOrg = actionstepContext!.orgKey;
                userAuditTrail.matterId = actionstepContext!.matterContext!.id;
                userAuditTrail.integration = "PEXA";
                userAuditTrail.integrationLink = "Invite Participants";

                // invitationResponse only return inviteId, we can not get the subscriber name if there is one failed invitation in the list of invitationRequests
                // in this case, just simple record the inviteId instead of subscriber name
                if (isAllInvitionSuccess) {
                    userAuditTrail.description = "New Invitation sent to " + sentInvitationToSubscribers[i].subscriberName;
                } else {
                    userAuditTrail.description = "New Invitation sent succesfully with Invite Id: " + invitationResponseList!.data![i].inviteId;
                }

                this.props.saveUserAuditTrailData(new SaveUserAuditTrailDataCommand({
                    userAuditTrailData: userAuditTrail
                }));
            }

            this.setState({
                sentInvitation: false
            });
        }
    }

    private savePexaAudiTIntoDb(commandName: string): void {
        const { sentInvitation } = this.state;
        const { actionstepContext } = this.props;
        if (sentInvitation || commandName == "SEARCHSUBSCRIBERQUERY") {
            let pexaAuditParams = new SavePexaAuditParams();
            pexaAuditParams.CommandName = commandName;
            pexaAuditParams.actionstepOrg = actionstepContext!.orgKey;
            pexaAuditParams.matterId = actionstepContext!.matterContext!.id;
            pexaAuditParams.pexaWorkspaceId = this.state.pexaWorkspaceIdAndRole?.workspaceId;
            pexaAuditParams.pexaWorkspaceStatus = "";
            this.props.savePexaAudit(pexaAuditParams);
        }
    }

    render() {

        const { participants, participantRefs, dataStatus, displayForm, gotInvitationUri, } = this.state;
        const { openFromIframe } = this.props;
        let errorCount = 0;

        return (
            <>
                {displayForm === true && gotInvitationUri === true ?
                    <>
                        <div className="modal-header">
                            <span className="modal-title"><big><b>INVITE PARTICIPANTS</b></big></span>
                        </div>
                        <div className="modal-body">
                            {participants.map((participant, index) => {
                                if (!participantRefs[index].current || !participantRefs[index].current!.validate()) {
                                    errorCount++;
                                }
                                return participant;
                            })}

                            <DefaultButton
                                className="button"
                                data-automation-id="add_another_participant"
                                data-cy="add_another_participant"
                                text="Add Another Participant"
                                onClick={this.addParticipant}
                                allowDisabledFocus={true}
                            />
                        </div>

                        <div className="modal-footer">
                            <DefaultButton
                                className="button"
                                data-automation-id="invite_participants"
                                data-cy="invite_participants"
                                text="Send Invitation"
                                onClick={this.sentInvitation}
                                allowDisabledFocus={true}
                                disabled={errorCount > 0 ? true : false}
                            />

                            &nbsp;

                            {openFromIframe === false ?
                                <DefaultButton
                                    className="button"
                                    data-automation-id="close_modal"
                                    data-cy="close_modal"
                                    text="Cancel"
                                    onClick={this.props.closeInviteModal}
                                    allowDisabledFocus={true}
                                />
                                :
                                <>
                                </>
                            }
                        </div>
                    </>

                    :
                    <>
                        <div className="modal-header">
                            <span className="modal-title"><big><b>INVITE PARTICIPANTS</b></big></span>
                        </div>

                        {dataStatus === ReduxStatus.Requested &&
                            <LoadingWidget />
                        }
                        {dataStatus === ReduxStatus.Success &&
                            <>
                                {this.savePexaAudiTIntoDb("CREATEPEXAWORKSPACEINVITATIONCOMMAND")}
                                {this.saveUserAuditTrailDataIntoDb()}

                                <br />
                                <MessageBar messageBarType={MessageBarType.success}>
                                    Invited Participants successfully!
                                </MessageBar>
                                <br />

                                {openFromIframe === false ?
                                    <div className="modal-footer">
                                        <DefaultButton
                                            className="button"
                                            data-automation-id="close_modal"
                                            data-cy="close_modal"
                                            text="Close"
                                            onClick={this.props.closeInviteModal}
                                            allowDisabledFocus={true}
                                        />
                                    </div>
                                    :
                                    <></>
                                }
                            </>
                        }
                        {dataStatus === ReduxStatus.Failed &&
                            <>
                                <br />
                                {this.savePexaAudiTIntoDb("CREATEPEXAWORKSPACEINVITATIONCOMMAND")}
                                <MessageBar messageBarType={MessageBarType.warning}>
                                    <i>The selected role doesn't match with invited participant. Please select the correct role. </i>
                                </MessageBar>

                                <br />

                                <div className="modal-footer">
                                    <DefaultButton
                                        className="button"
                                        text="Go back"
                                        onClick={() => this.goBackToForm()}
                                        allowDisabledFocus={true}
                                    />

                                    &nbsp;

                                    {openFromIframe === false ?
                                        <DefaultButton
                                            className="button"
                                            data-automation-id="close_modal"
                                            data-cy="close_modal"
                                            text="Cancel"
                                            onClick={this.props.closeInviteModal}
                                            allowDisabledFocus={true}
                                        />
                                        :
                                        <>
                                        </>
                                    }
                                </div>
                            </>
                        }
                    </>
                }
            </>
        );
    }


    removeParticipant = (index: number) => {
        let newParticipants = [...this.state.participants];
        let newParticipantRefs = [...this.state.participantRefs];

        newParticipants.splice(index, 1);
        newParticipantRefs.splice(index, 1);

        this.setState({
            participants: newParticipants,
            participantRefs: newParticipantRefs
        });
    }

    addParticipant = () => {
        let newParticipants = [...this.state.participants];
        let newParticipantRefs = [...this.state.participantRefs];

        const index = newParticipants.length;
        const newRef = React.createRef<InviteParticipant>();

        newParticipants.push(<InviteParticipant removeParticipant={this.removeParticipant}
            invitationUri={this.state.invitationUri!}
            participantIndex={index}
            onChange={this.onChangeInput}
            key={index} ref={newRef}
            OnSubsciberUpdate={this.OnSubsciberUpdate}
            workspaceSummary={this.getWorkspaceSummary()} />);
        newParticipantRefs.push(newRef);

        this.setState({
            participants: newParticipants,
            participantRefs: newParticipantRefs
        });
    }

    sentInvitation = () => {
        const { participantRefs } = this.state;
        const workspaceId = this.state.pexaWorkspaceIdAndRole?.workspaceId;

        let invitationRequests: CreateWorkspaceInvitationRequestType[] = [];
        let sentInvitationToSubscribers: SubscriberIdAndName[] = [];
        for (var i = 0; i < participantRefs.length; i++) {
            const participant = participantRefs[i];

            if (participant.current) {
                let invitationRequest = participant.current.getSubscriberInfo();
                if (invitationRequest) {
                    invitationRequest.workspaceId = workspaceId;
                    invitationRequests.push(invitationRequest);
                }

                let subscribersName = participant.current.getSubscriberName();
                if (subscribersName) {
                    sentInvitationToSubscribers.push(subscribersName);
                }
            }
        }

        if (invitationRequests.length > 0) {
            this.props.createInvitation(invitationRequests);
            this.setState({
                dataStatus: ReduxStatus.Requested,
                sentInvitationToSubscribers: sentInvitationToSubscribers
            });
        }

        this.setState({
            displayForm: false
        })
    }

    onChangeInput = () => {
        this.setState({
            refresh: !this.state.refresh
        })
    }
    OnSubsciberUpdate = () => {
        this.savePexaAudiTIntoDb("SEARCHSUBSCRIBERQUERY");

    }
}

const mapStateToProps = (state: AppState) => {
    return {
        invitationUriFromProps: state.pexa.invitationUri,
        invitationResponseList: state.pexa.invitationResponseList,
        pexaWorkspaceResponse: state.pexa.createPexaWorkspaceResponse,
        pexaWorkspaceCreationData: state.pexa.pexaWorkspaceCreationData,
        gotResponse: state.pexa.gotResponse,
        requestType: state.pexa.requestType,
        actionstepContext: state.common.actionstepContext,
        workspaceSummaryList: state.pexa.workspaceSummaryList
    }
}

const mapDispatchToProps = {
    createInvitation,
    getInvitationUri,
    saveUserAuditTrailData,
    savePexaAudit,
    getPexaWorkspaceSummary
}

export default connect(mapStateToProps, mapDispatchToProps)(ParticipantsInvitationForm);