import { DatePicker, DayOfWeek, Dropdown, PrimaryButton, TextField } from "@fluentui/react";
import React from "react";
import { DateTitles, IState } from "./dateCalculatorInterface";
import { calculate, formatDateCalculatorString } from "./calculation";
import "./dateCalculator.css";

interface IProps {
	state: IState
}

export default class DateCalculator extends React.Component<IProps, IState> {
	constructor(props: any) {
		super(props);

		this.state = {
			startingDate: new Date(),
			coolingOffPeriod: undefined,
			buildingAndPest: undefined,
			buildingAndPestDue: undefined,
			buildingAndPestType: 'day',
			financeDate: undefined,
			financeDateDue: undefined,
			financeDateType: 'day',
			otherCondition: undefined,
			otherConditionDue: undefined,
			otherConditionType: 'day',
			settlementDate: undefined,
			settlementDateDue: undefined,
			settlementDateType: 'day'
		}

		this.onCaclulate = this.onCaclulate.bind(this);
	}

	private onCaclulate() {
		this.setState(calculate(this.state, false));
	}

	public render(): JSX.Element {
		return <div className="ms-Grid" dir="ltr">
			<div className="ms-Grid-row date-calc-detail-row">
				<div className='ms-Grid-col ms-sm3'>Starting Date</div>
				<div className='ms-Grid-col ms-sm9'>
					<DatePicker
						firstDayOfWeek={DayOfWeek.Monday}
						formatDate={date => formatDateCalculatorString(date ?? new Date())}
						placeholder="Select a date..."
						value={this.state.startingDate}
						onSelectDate={(newValue) => this.setState({ startingDate: newValue ?? undefined })}
					/>
				</div>
			</div>

			<div className="ms-Grid-row date-calc-detail-row">
				<div className='ms-Grid-col ms-sm3'>Cooling off Period</div>
				<div className='ms-Grid-col ms-sm9'>
					{this.state.coolingOffPeriod}
				</div>
			</div>

			<div className="ms-Grid-row date-calc-detail-row">
				<div className='ms-Grid-col ms-sm3'>Finance Date</div>
				<div className='ms-Grid-col ms-sm3'>
					<TextField
						type="number"
						data-cy="finance_date"
						onChange={(_, newItem) => this.setState({ financeDate: parseInt(newItem ?? '') })}
					/>
				</div>
				<div className='ms-Grid-col ms-sm3'>
					<Dropdown
						options={DateTitles}
						placeholder="Please select..."
						defaultSelectedKey={this.state.financeDateType}
						onChange={(_, newItem) => this.setState({ financeDateType: newItem?.key.toString() })}
					/>
				</div>
				<div className='ms-Grid-col ms-sm3'>
					{this.state.financeDateDue}
				</div>
			</div>

			<div className="ms-Grid-row date-calc-detail-row">
				<div className='ms-Grid-col ms-sm3'>Building and Pest</div>
				<div className='ms-Grid-col ms-sm3'>
					<TextField
						type="number"
						data-cy="building_and_pest"
						onChange={(_, newItem) => this.setState({ buildingAndPest: parseInt(newItem ?? '') })}
					/>
				</div>
				<div className='ms-Grid-col ms-sm3'>
					<Dropdown
						options={DateTitles}
						placeholder="Please select..."
						defaultSelectedKey={this.state.buildingAndPestType}
						onChange={(_, newItem) => this.setState({ buildingAndPestType: newItem?.key.toString() })}
					/>
				</div>
				<div className='ms-Grid-col ms-sm3'>
					{this.state.buildingAndPestDue}
				</div>
			</div>

			<div className="ms-Grid-row date-calc-detail-row">
				<div className='ms-Grid-col ms-sm3'>Other Conditions</div>
				<div className='ms-Grid-col ms-sm3'>
					<TextField
						type="number"
						data-cy="other_condition"
						onChange={(_, newItem) => this.setState({ otherCondition: parseInt(newItem ?? '') })}
					/>
				</div>
				<div className='ms-Grid-col ms-sm3'>
					<Dropdown
						options={DateTitles}
						placeholder="Please select..."
						defaultSelectedKey={this.state.otherConditionType}
						onChange={(_, newItem) => this.setState({ otherConditionType: newItem?.key.toString() })}
					/>
				</div>
				<div className='ms-Grid-col ms-sm3'>
					{this.state.otherConditionDue}
				</div>
			</div>

			<div className="ms-Grid-row date-calc-detail-row">
				<div className='ms-Grid-col ms-sm3'>Settlement Date</div>
				<div className='ms-Grid-col ms-sm3'>
					<TextField
						type="number"
						data-cy="settlement_date"
						onChange={(_, newItem) => this.setState({ settlementDate: parseInt(newItem ?? '') })}
					/>
				</div>
				<div className='ms-Grid-col ms-sm3'>
					<Dropdown
						options={DateTitles}
						placeholder="Please select..."
						defaultSelectedKey={this.state.settlementDateType}
						onChange={(_, newItem) => this.setState({ settlementDateType: newItem?.key.toString() })}
					/>
				</div>
				<div className='ms-Grid-col ms-sm3'>
					{this.state.settlementDateDue}
				</div>
			</div>

			<div className="ms-Grid-row date-calc-center">
				<PrimaryButton
					className="button"
					data-automation-id="save_button"
					data-cy="save_button"
					text="Calculate"
					onClick={this.onCaclulate}
					allowDisabledFocus={true}
				/>
			</div>

			<div className="ms-Grid-row date-calc-center">
				*** PLEASE NOTE ***
			</div>

			<div className="ms-Grid-row date-calc-center">
				The dates provided by the calculator do not factor weekends or public holidays such as Christmas. Please ensure you cross reference the dates carefully and make the relevant adjustments to the date as per the terms and conditions contained in the relevant contract.
			</div>
		</div>
	}
}