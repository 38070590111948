import * as React from 'react';
import { DatePicker } from '@fluentui/react';

interface IMapStateToProps { }
interface IMapDispatchToProps { }
interface IProps {
    updatedState: any;
    updateValue: (newValue: any, whichValue: string, needRefresh?: boolean) => void;
    datacy: string;
    datePickerName: any;
    disabled: boolean;
}

type AppProps = IMapStateToProps & IProps & IMapDispatchToProps;
type AppStates = {}

export default class DatePickers extends React.Component<AppProps, AppStates> {

    state = {
        refresh: false,
        changeValue: undefined
    }

    public onParseDateFromString(newValue: string): Date {
        const previousValue = new Date();
        const newValueParts = (newValue || '').trim().split('/');
        const day =
            newValueParts.length > 0 ? Math.max(1, Math.min(31, parseInt(newValueParts[0], 10))) : previousValue.getDate();
        const month =
            newValueParts.length > 1
                ? Math.max(1, Math.min(12, parseInt(newValueParts[1], 10))) - 1
                : previousValue.getMonth();
        let year = newValueParts.length > 2 ? parseInt(newValueParts[2], 10) : previousValue.getFullYear();
        if (year < 100) {
            year += previousValue.getFullYear() - (previousValue.getFullYear() % 100);
        }
        console.log(new Date(year, month, day));
        return new Date(year, month, day);
    }

    public render(): JSX.Element {
        const updatedState = this.props.updatedState;
        const onFormatDate = (date?: Date): string => {
            return !date ? '' : date.getDate() + '/' + (date.getMonth() + 1) + '/' + (date.getFullYear());
        };

        return (
            <DatePicker
                disabled={this.props.disabled}
                data-cy={this.props.datacy}
                showMonthPickerAsOverlay={true}
                placeholder="DD/MM/YYYY"
                allowTextInput
                formatDate={onFormatDate}
                parseDateFromString={this.onParseDateFromString}
                ariaLabel="Select a date"
                value={updatedState[this.props.datePickerName]}
                onSelectDate={(newDate) => this.props.updateValue(newDate, this.props.datePickerName)}
            />
        )
    }
}