import { call, put, takeLatest } from 'redux-saga/effects'

import * as CONSTANTS from './constants'
import * as ACTIONS from './actions'

import {
    getRequest, postRequest
} from 'utils/request'

import {
    ECAFGetMatterDetailsFromActionstepQueryResponse,
    ConnectedActionstepOrgsResponse,
    CreateOrderResponse,
    ECAFEnvelopeResponse,
    ECAFOrderResult
} from '../../../utils/wcaApiTypes';

import {
    GetMatterDetailsFromActionstep,
    GetECAFCredentialsRequested,
    GetActionstepOrgsRequested,
    CreateOrder,
    GetEnvelopeStatus,
    UpdateVOIStatus,
    GetEnvelopeSummary,

    GetECAFOrderlist,
    GetECAFOrderStatusPage,
    DeleteEnvelope,
    EditEnvelopeCustomer,
    GetCountryPhoneCodes,
    ResendCustomerNotification,
    DeleteCustomer,
    GetReportQuery
} from './actionTypes';
import { CountryPhoneCodeViewModel, ECAFReportResponse } from 'utils/newWcaApiTypes';



export function* getMatterDetailsFromActionstep(action: GetMatterDetailsFromActionstep) {
    try {
        const response = yield call(getRequest, `/api/ecaf/get-matter-details-from-actionstep?actionsteporg=${action.matterInfo.orgKey!}&matterid=${action.matterInfo.matterId}&partyToMap=${action.matterInfo.matterName}`);

        let data: ECAFGetMatterDetailsFromActionstepQueryResponse = ECAFGetMatterDetailsFromActionstepQueryResponse.fromJS(response);

        yield put(ACTIONS.getMatterDetailsFromActionstepSuccess(data));

    } catch (error) {
        yield put(ACTIONS.getMatterDetailsFromActionstepFailed(error?.response?.data));
    }
}

export function* checkECAFCredentials(action: GetECAFCredentialsRequested) {
    try {
        const response = yield call(postRequest, `/api/ecaf/ecaf-check-and-update-credentials`, action.data);

        let data: boolean = response;

        yield put(ACTIONS.checkECAFCredentialsSuccess(data));

    } catch (error) {
        yield put(ACTIONS.checkECAFCredentialsFailed(error?.response?.data));
    }
}

export function* getActionstepOrgs(action: GetActionstepOrgsRequested) {
    try {
        const response = yield call(getRequest, `/api/integrations/actionsteporgs`);

        let data: ConnectedActionstepOrgsResponse[] = response;

        yield put(ACTIONS.getActionstepOrgsSuccess(data));

    } catch (error) {
        yield put(ACTIONS.getActionstepOrgsFailed(error?.response?.data));
    }
}

export function* createOrder(action: CreateOrder) {
    const { params } = action;
    try {
        const response = yield call(postRequest, `/api/ecaf/create-order`, params);

        let data: CreateOrderResponse = CreateOrderResponse.fromJS(response);

        yield put(ACTIONS.createOrderSuccess(data));

    } catch (error) {
        yield put(ACTIONS.createOrderFailed(error?.response?.data));
    }
}

export function* getEnvelopeStatus(action: GetEnvelopeStatus) {

    try {
        const response = yield call(getRequest, `/api/ecaf/get-ecaf-envelope-status?envelopeId=${action.id}&code=${action.code}`);

        let data: ECAFEnvelopeResponse = ECAFEnvelopeResponse.fromJS(response);

        yield put(ACTIONS.getEnvelopeStatusSuccess(data));

    } catch (error) {
        yield put(ACTIONS.getEnvelopeStatusFailed(error?.response?.data));
    }
}


export function* getECAFOrderStatusPage(action: GetECAFOrderStatusPage) {

    try {
        const response = yield call(getRequest, `/api/ecaf/status-page-url?transactionId=${action.transactionId}&type=${action.transactionStatusType}`);

        let data: string = response;

        yield put(ACTIONS.getECAFOrderStatusPageSuccess(data));

    } catch (error) {
        yield put(ACTIONS.getECAFOrderStatusPageFailed(error?.response?.data));
    }
}


export function* updateVOIStatus(action: UpdateVOIStatus) {
    try {
        const response = yield call(postRequest, `/api/ecaf/update-ecaf-voi-status`, action.data);

        let data: ECAFEnvelopeResponse = ECAFEnvelopeResponse.fromJS(response);

        yield put(ACTIONS.getEnvelopeStatusSuccess(data));

    } catch (error) {
        yield put(ACTIONS.getEnvelopeStatusSuccess(error?.response?.data));
    }
}

export function* updateCounterSignStatus(action: UpdateVOIStatus) {
    try {
        const response = yield call(postRequest, `/api/ecaf/update-ecaf-countersign-status`, action.data);

        let data: ECAFEnvelopeResponse = ECAFEnvelopeResponse.fromJS(response);

        yield put(ACTIONS.getEnvelopeStatusSuccess(data));

    } catch (error) {
        yield put(ACTIONS.getEnvelopeStatusSuccess(error?.response?.data));
    }
}

export function* getValidECAFCredential(action: GetMatterDetailsFromActionstep) {
    try {
        const response = yield call(getRequest, `/api/ecaf/check-credential-for-org?actionsteporg=${action.matterInfo.orgKey!}`);

        let data: boolean = response;

        yield put(ACTIONS.getValidECAFCredentialSuccess(data));

    } catch (error) {
        yield put(ACTIONS.getValidECAFCredentialFailed(error?.response?.data));
    }
}

export function* getEnvelopeSummary(action: GetEnvelopeSummary) {

    try {
        const response = yield call(getRequest, `/api/ecaf/get-ecaf-envelope-summary?envelopeId=${action.id}`);

        let data: ECAFEnvelopeResponse = ECAFEnvelopeResponse.fromJS(response);

        yield put(ACTIONS.getEnvelopeSummarySuccess(data));

    } catch (error) {
        yield put(ACTIONS.getEnvelopeSummaryFailed(error?.response?.data));
    }
}

export function* getECAFOrderlist(action: GetECAFOrderlist) {

    try {
        const response = yield call(getRequest, `/api/ecaf/get-ecaf-orderlist?orgKey=${action.orgKey}&matterId=${action.matterId}`);

        let data: ECAFOrderResult[] = response;

        yield put(ACTIONS.getECAFOrderlistSuccess(data));

    } catch (error) {
        yield put(ACTIONS.getECAFOrderlistFailed(error?.response?.data));
    }
}


export function* deleteEnvelope(action: DeleteEnvelope) {

    try {
        const response = yield call(postRequest, `/api/ecaf/delete-envelope?envelopeId=${action.envelopeId!}&orgKey=${action.orgKey!}`);

        let data: boolean = response;

        yield put(ACTIONS.deleteEnevlopeSuccess(data));

    } catch (error) {
        yield put(ACTIONS.deleteEnevlopeFailed(error?.response?.data));
    }
}

export function* deleteCustomer(action: DeleteCustomer) {

    try {
        const response = yield call(postRequest, `/api/ecaf/delete-customer?envelopeId=${action.envelopeId!}&customerId=${action.customerId!}&orgKey=${action.orgKey!}`);

        let data: boolean = response;

        yield put(ACTIONS.deleteCustomerSuccess(data));

    } catch (error) {
        yield put(ACTIONS.deleteCustomerFailed(error?.response?.data));
    }
}

export function* postEditEnvelopeCustomer(action: EditEnvelopeCustomer) {

    try {
        const response = yield call(postRequest, `/api/ecaf/edit-envelope-customer`, action.data);

        let data: boolean = response;

        yield put(ACTIONS.editEnevlopeCustomerSuccess(data));

    } catch (error) {
        yield put(ACTIONS.editEnevlopeCustomerFailed(error?.response?.data));
    }
}

export function* getCountyPhoneCodeList(action: GetCountryPhoneCodes) {

    try {
        const response = yield call(getRequest, `/api/ecaf/country-phone-code-list?orgKey=${action.orgKey}`);

        let data: CountryPhoneCodeViewModel[] = response;

        yield put(ACTIONS.getCountryPhoneCodesSuccess(data));

    } catch (error) {
        yield put(ACTIONS.getCountryPhoneCodesFailed(error?.response?.data));
    }
}

export function* postResendCustomerNotification(action: ResendCustomerNotification) {

    try {
        const response = yield call(postRequest, `/api/ecaf/resend-notification`, action.data);

        let data: boolean = response;       

        yield put(ACTIONS.resendCustomerNotificationSuccess(data));

    } catch (error) {
        yield put(ACTIONS.resendCustomerNotificationFailed(error?.response?.data));
    }
}

export function* getEcafReportData(action: GetReportQuery) {
    try {
        const response = yield call(postRequest, `/api/ecaf/get-report-data`, action.data);

        let data: ECAFReportResponse = response;

        yield put(ACTIONS.getReportDataSuccess(data));
    } catch (error) {
        yield put(ACTIONS.getReportDataFailed(error?.response?.data));
    }
}

export default function* eCafSaga() {
    yield takeLatest(CONSTANTS.GET_MATTER_DETAILS_FROM_ACTIONSTEP_REQUESTED, getMatterDetailsFromActionstep);
    yield takeLatest(CONSTANTS.CHECK_ECAF_CREDENTIAL_REQUESTED, checkECAFCredentials);
    yield takeLatest(CONSTANTS.GET_ACTIONSTEP_ORGS_REQUESTED, getActionstepOrgs);
    yield takeLatest(CONSTANTS.CREATE_ORDER_REQUESTED, createOrder);
    yield takeLatest(CONSTANTS.GET_ECAF_ENVELOPE_STATUS_REQUESTED, getEnvelopeStatus);
    yield takeLatest(CONSTANTS.UPDATE_VOI_STATUS_REQUESTED, updateVOIStatus);
    yield takeLatest(CONSTANTS.UPDATE_COUNTER_SIGN_STATUS_REQUESTED, updateCounterSignStatus);
    yield takeLatest(CONSTANTS.GET_VALID_ECAF_CREDENTIAL_REQUESTED, getValidECAFCredential);
    yield takeLatest(CONSTANTS.GET_ECAF_ENVELOPE_SUMMARY_REQUESTED, getEnvelopeSummary);
    yield takeLatest(CONSTANTS.GET_ECAF_ORDERLIST_REQUESTED, getECAFOrderlist);
    yield takeLatest(CONSTANTS.GET_ECAF_ORDER_STATUS_PAGE_REQUESTED, getECAFOrderStatusPage);
    yield takeLatest(CONSTANTS.DELETE_ENVELOPE_REQUESTED, deleteEnvelope);
    yield takeLatest(CONSTANTS.DELETE_CUSTOMER_REQUESTED, deleteCustomer);
    yield takeLatest(CONSTANTS.EDIT_ENVELOPE_CUSTOMER_REQUESTED, postEditEnvelopeCustomer);
    yield takeLatest(CONSTANTS.GET_COUNTRY_PHONE_CODES_REQUESTED, getCountyPhoneCodeList);
    yield takeLatest(CONSTANTS.RESEND_CUSTOMER_NOTIFICATION_REQUESTED, postResendCustomerNotification);
    yield takeLatest(CONSTANTS.GET_REPORT_DATA_REQUESTED, getEcafReportData);
} 