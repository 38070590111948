import * as React from 'react';
import { connect } from 'react-redux';

import { DefaultButton } from 'office-ui-fabric-react';
import { MessageBar, MessageBarType } from 'office-ui-fabric-react';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import { Link } from 'office-ui-fabric-react/lib/Link'
import { AppState } from '../../../../app.types';
import { SubwayNavNodeState } from '../../../../components/SubwayNav';

import * as CONSTANTS from '../../../../containers/pexa/redux/constants';

import {
    updateSettlementDateTimeActionstep,
    clearStatesForSettlementDateTime
} from '../../../../containers/pexa/redux/actions';


type AppStates = {
    updateForActionstep: boolean,
    updateForPexa: boolean,
    dataLoaded: boolean
}


interface IAppProps {
    onChangeStep: (newState: SubwayNavNodeState, newStep: number) => void;
}

type AppProps = IAppProps & ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

export class DataCheckByActionstepSDT extends React.Component<AppProps, AppStates> {
    constructor(props: AppProps) {
        super(props);
        this.state = {
            dataLoaded: false,
            updateForActionstep: this.props.updateSettlementDateTimeData?.updateForActionstep || false,
            updateForPexa: this.props.updateSettlementDateTimeData?.updateForPexa || false
        }
    }


    public componentDidMount(): void {
        if (this.state.updateForActionstep) {
            this.props.updateSettlementDateTimeActionstep(this.props.updateSettlementDateTimeData!.updateSettlementDateTimeActionstepCommand!);
        } else {
            this.props.onChangeStep(SubwayNavNodeState.Completed, 3);
        }
    }

    static getDerivedStateFromProps(nextProps: AppProps, prevState: AppStates): AppStates {
        let nextState = {} as AppStates;

        if (nextProps.gotResponseActionstep) {
            switch (nextProps.requestType) {
                case CONSTANTS.UPDATE_SETTLEMENT_DATE_TIME_ACTIONSTEP_REQUESTED: {
                    nextState.dataLoaded = true;
                    if (!nextProps.actionstepError ||
                        nextProps.actionstepError && nextProps.actionstepError.errorList && nextProps.actionstepError.errorList.length === 0) {
                        nextProps.clearStatesForSettlementDateTime();
                        if (!prevState.updateForPexa) {
                            nextProps.onChangeStep(SubwayNavNodeState.Completed, 4);
                        } else {
                            nextProps.onChangeStep(SubwayNavNodeState.Completed, 3);
                        }
                    }
                    break;
                }
                default:
                    return nextState;
            }
        }
        return nextState;
    }

    private goBackToDataInput(): void {
        this.props.clearStatesForSettlementDateTime();
        this.props.onChangeStep(SubwayNavNodeState.Current, 2);
    }

    render() {
        const { actionstepError } = this.props;
        const { dataLoaded } = this.state;

        return (
            <div>
                {dataLoaded ?
                    <div>
                        {(actionstepError && actionstepError?.errorList && actionstepError?.errorList?.length > 0) ?
                            <div>
                                <h3>
                                    Actionstep returned the following error(s):
                                </h3>
                                <br />

                                {actionstepError?.errorList?.map((item, index) => (
                                    <MessageBar messageBarType={MessageBarType.error} isMultiline={false} key={index}>
                                        {item}
                                    </MessageBar>
                                ))}

                                <br />
                                <DefaultButton
                                    className="button"
                                    text="Go Back"
                                    onClick={() => this.goBackToDataInput()}
                                    allowDisabledFocus={true}
                                />
                            </div>
                            :
                            <div>
                                <p className="text-danger">
                                    An unexpected error has occured. Please <Link onClick={() => window.location.reload(false)} >refresh the page</Link> and try again. If you continue to experience problems, please
                                     <Link href={"https://support.konekta.com.au/support/tickets/new"} target="_blank" rel="noopener noreferrer"> submit a ticket</Link>.
                               </p>
                            </div>
                        }
                    </div>
                    : <Spinner size={SpinnerSize.large} />
                }
            </div>
        );
    }
}

const mapStateToProps = (state: AppState) => {
    return {
        requestType: state.pexa.requestType,
        actionstepError: state.pexa.actionstepError,
        gotResponseActionstep: state.pexa.gotResponseActionstep,
        updateSettlementDateTimeData: state.pexa.updateSettlementDateTimeData
    }
}

const mapDispatchToProps = {
    clearStatesForSettlementDateTime,
    updateSettlementDateTimeActionstep,
}

export default connect(mapStateToProps, mapDispatchToProps)(DataCheckByActionstepSDT);
