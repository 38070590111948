export const GET_PROVIDER_DETAILS_REQUESTED = "LegalaidReducer/GET_PROVIDER_DETAILS_REQUESTED";
export const GET_PROVIDER_DETAILS_SUCCESS = "LegalaidReducer/GET_PROVIDER_DETAILS_SUCCESS";
export const GET_PROVIDER_DETAILS_FAILED = "LegalaidReducer/GET_PROVIDER_DETAILS_FAILED";

export const GET_MATTER_DETAILS_FROM_ACTIONSTEP_REQUESTED = "LegalaidReducer/GET_MATTER_DETAILS_FROM_ACTIONSTEP_REQUESTED";
export const GET_MATTER_DETAILS_FROM_ACTIONSTEP_SUCCESS = "LegalaidReducer/GET_MATTER_DETAILS_FROM_ACTIONSTEP_SUCCESS";
export const GET_MATTER_DETAILS_FROM_ACTIONSTEP_FAILED = "LegalaidReducer/GET_MATTER_DETAILS_FROM_ACTIONSTEP_FAILED";

export const GENERATE_LEGALAID_PDF_REQUESTED = "LegalaidReducer/GENERATE_LEGALAID_PDF_REQUESTED";
export const GENERATE_LEGALAID_PDF_SUCCESS = "LegalaidReducer/GENERATE_LEGALAID_PDF_SUCCESS";
export const GENERATE_LEGALAID_PDF_FAILED = "LegalaidReducer/GENERATE_LEGALAID_PDF_FAILED";
