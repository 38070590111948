module.exports =
    `<link type="text/css" rel="stylesheet" href="https://cdn.actionstep.com/assets/DatetimeControl.css" media="screen">
<style>
.AdvancedForm {
    margin:0;
    padding:0;
    font-family:inherit;
    line-height:1.4;
    font-size:1em;
    color:#222;
}
.AdvancedForm .FormField {
    margin:0 0 2px 0;
    width:100%;
    font-size:inherit;
    padding:6px;
    overflow:hidden;
    white-space:nowrap;
    vertical-align:top;
}
.AdvancedForm label {
    width:16em;
    float:left;
    font-weight:bold;
    margin:0 0 3px 0;
    padding-right:10px;
    font-size:inherit;
    white-space:normal;
}
.AdvancedForm label.required:after {
    content:"*";
    color:red;
    display:inline-block;
    vertical-align:top;
    margin-left:3px;
    font-size:0.9em;
}
.AdvancedForm p {
    margin:2em 0;
    font-size:inherit;
}
.AdvancedForm p.hint {
    margin:1px 0 0 17.8em;
    font-style:italic;
    color:#555;
    font-size:0.9em;
}
.AdvancedForm input,
.AdvancedForm textarea {
    border:1px #ccc solid;
    padding:0.2em;
    margin:0;
    display:inline;
    font:inherit;
}
.AdvancedForm input[type=text],
.AdvancedForm textarea
{
    width:18em;
}
.AdvancedForm input.DateControl_DateInputBox {
    width:6em;
}
.AdvancedForm input.TimeControl_TimeInputBox {
    width:6em;
}
.AdvancedForm .DateControl_MemoInputBox {
    width:10em;
    border-color:#ccc;
    border-style:dotted;
    border-width:0 0 1px 0;
    color:#555;
    font-style:italic;
}
.AdvancedForm .FormField input:focus,
.AdvancedForm .FormField textarea:focus {
    border-color:#aaa;
}
.AdvancedForm .FormField select {
    padding:0;
    margin:0;
    display:inline;
    min-width:10em;
    max-width:18em;
}
.AdvancedForm .Submit {
    background:none;
}
.AdvancedForm button.Submit {
    font-weight:bold;
    cursor:pointer;
}

</style>
<form id="ActionStepWebform1" name="ActionStepWebform1" enctype="application/x-www-form-urlencoded" class="AdvancedForm Webform2" method="post" action="https://go.actionstep.com/frontend/application/webform/post">

<input type="hidden" name="organization_key" value="ktaproddemo" class="OrganizationKey" id="organization_key">

<input type="hidden" name="uid" value="4586314ca0e4698ab58d779d5c751ce828f56785" class="Uid" id="uid">

<div class="FormField P93CompanyName"><label for="p93__company_name" class="required js-label">Agent Details</label>

<input type="text" name="p93__company_name" id="p93__company_name" value="" class="P93CompanyName" required="required"></div>
<div class="FormField P119CompanyName"><label for="p119__company_name" class="required js-label">Property Address</label>

<input type="text" name="p119__company_name" id="p119__company_name" value="" class="P119CompanyName" required="required"></div>
<div class="FormField P105LastName"><label for="p105__last_name" class="optional js-label">Buyer's Surname</label>

<input type="text" name="p105__last_name" id="p105__last_name" value="" class="P105LastName"></div>
<div class="FormField P107LastName"><label for="p107__last_name" class="optional js-label">Seller's Surname</label>

<input type="text" name="p107__last_name" id="p107__last_name" value="" class="P107LastName"></div>
<div class="FormField Dc45Purprice"><label for="dc45__purprice" class="required js-label">Purchase Price</label>

<input type="text" name="dc45__purprice" id="dc45__purprice" value="" class="Dc45Purprice" required="required" onchange=";if (this.value != '') { this.value=ActionStep_Utilities_Math.formatCurrency(this.value); } " allowempty=""></div>
<div class="FormField Dc45Depamount"><label for="dc45__depamount" class="optional js-label">Full Deposit</label>

<input type="text" name="dc45__depamount" id="dc45__depamount" value="" class="Dc45Depamount" onchange=";if (this.value != '') { this.value=ActionStep_Utilities_Math.formatCurrency(this.value); } " allowempty=""></div>

<div class="FormField Submit">
<input type="submit" name="Submit" id="Submit" value="Submit" class="Submit"></div></form>`;