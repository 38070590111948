import * as React from 'react'
import { FunctionComponent } from 'react'
import { Link, MessageBar, MessageBarType } from 'office-ui-fabric-react'

interface IProps {
    message?: string,
}

const ErrorMessageWidget: FunctionComponent<IProps> = (props) => {
    if (props.message === undefined ||
        props.message === null ||
        props.message.length < 1) return (<></>);

    return (
        <MessageBar messageBarType={MessageBarType.error}>
            {props.message} Please try again, or if you continue to experience problems <Link href="https://support.konekta.com.au/support/tickets/new" target="_blank" rel="noopener noreferrer">open a support ticket</Link> to let us know.
        </MessageBar>
    )
}

export default ErrorMessageWidget;