import * as CONSTANTS from './constants'
import { SecurexchangeWorkspaceInfo, ErrorViewModel } from "../../../utils/wcaApiTypes";
import {
    SecurexchangeActionTypes,
    GetSecurexchangeWorkspaceInformationFromKonektaSuccess,
    GetSecurexchangeWorkspaceInformationFromKonektaFailed
} from "./actionTypes";


export interface SecurexchangeState {
    gotResponse: boolean,
    success: boolean,
    securexchangeWorkspaceInfoFromKonekta: SecurexchangeWorkspaceInfo | undefined,
    error: ErrorViewModel | undefined,
    requestType: string,

}

const initialState: SecurexchangeState = {
    gotResponse: false,
    success: false,
    securexchangeWorkspaceInfoFromKonekta: undefined,
    error: undefined,
    requestType: "",
}



function securexchangeReducer(state: SecurexchangeState = initialState, action: SecurexchangeActionTypes): SecurexchangeState {

    switch (action.type) {

        case CONSTANTS.GET_SECUREXCHANGE_WORKSPACE_INFORMATION_FROM_KONEKTA_REQUESTED:
            return {
                ...state,
                requestType: action.type
            };

        case CONSTANTS.GET_SECUREXCHANGE_WORKSPACE_INFORMATION_FROM_KONEKTA_SUCCESS:
            return {
                ...state,
                securexchangeWorkspaceInfoFromKonekta: (action as GetSecurexchangeWorkspaceInformationFromKonektaSuccess).data
            };


        case CONSTANTS.GET_SECUREXCHANGE_WORKSPACE_INFORMATION_FROM_KONEKTA_FAILED:
            return {
                ...state,
                gotResponse: true,
                success: false,
                error: (action as GetSecurexchangeWorkspaceInformationFromKonektaFailed).error
            };


        default:
            return state;
    }
}

export default securexchangeReducer;