import {
    Button, Checkbox, Divider, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField,
    Typography
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMatterDetailsFromActionstep, generateLegalAidPDF, getActionstepProviderDetails } from './redux/actions';
import { v4 as uuidv4 } from 'uuid';
import * as CONSTANTS from 'containers/legalaid/redux/constants';
import { AppState } from 'app.types';
import './legalaidReport.css';
import { AccountActivity, ActivityType, ClaimDetails, FormType, GeneratePDFLegalAidCommand, LegalAidActionstepDataViewModel, LegalAidDisbursement, ProviderActivity, ActionstepProviderList } from './utils/LegalAidWCAApiTypes';
import { Modal } from '@fluentui/react';
import legalaidStyles from './legalaidStyle';
import { Party } from 'utils/wcaApiTypes';

interface AppProps {

    // Define your props here
}
const LegalAidReport: React.FunctionComponent<AppProps> = () => {
    const classes = legalaidStyles();

    const legalAidState = useSelector((state: AppState) => state.legalAid);
    const matterId = useSelector((state: AppState) => state.common.actionstepContext?.matterContext!.id);
    const orgKey = useSelector((state: AppState) => state.common.actionstepContext?.orgKey);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [renderForm, setRenderForm] = useState(false);
    const [dateRangeFrom, setDateRangeFrom] = useState<Date | null>(null);
    const [dateRangeTo, setDateRangeTo] = useState<Date | null>(null);
    const [formType, setFormType] = useState<FormType>(FormType.Form15);
    const [legalAidMatterData, setLegalAidMatterData] = useState<LegalAidActionstepDataViewModel>();
    const [providerList, setProviderList] = useState<ActionstepProviderList | null>();
    const [selectedLeadProvider, setSelectedLeadProvider] = useState('');
    const [selectedProvider2, setSelectedProvider2] = useState('');
    const [selectedOtherProvider, setSelectedOtherProvider] = useState('');

    const onSearch = (event: React.FormEvent) => {
        event.preventDefault();
        if (dateRangeFrom == null || dateRangeTo == null) {
            return;
        }
        try {
            // Dispatch the action to initiate the data fetching process
            setLoading(true);
            dispatch(getMatterDetailsFromActionstep(matterId ?? 0, orgKey ?? "", dateRangeFrom?.toISOString(), dateRangeTo?.toISOString(),
                selectedLeadProvider, selectedProvider2, selectedOtherProvider, formType));
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    }


    useEffect(() => {
        const fetchData = async () => {
            try {
                // Dispatch the action to initiate the data fetching process
                setLoading(true);
                setRenderForm(false);
                dispatch(getActionstepProviderDetails(matterId ?? 0, orgKey ?? ""));
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        }
        // Call the fetchData function
        fetchData();
    }, [dispatch]);


    useEffect(() => {
        // Mimic the logic of getDerivedStateFromProps
        if (legalAidState.gotResponse) {
            switch (legalAidState.requestType) {
                case CONSTANTS.GET_PROVIDER_DETAILS_REQUESTED:
                    setLoading(true);
                    break;
                case CONSTANTS.GET_PROVIDER_DETAILS_SUCCESS:
                    setProviderList(legalAidState.providerList ?? new ActionstepProviderList());
                    setLoading(false);
                    break;
                case CONSTANTS.GET_PROVIDER_DETAILS_FAILED:
                    setLoading(false);
                    break;
                case CONSTANTS.GET_MATTER_DETAILS_FROM_ACTIONSTEP_REQUESTED:
                    setRenderForm(true);
                    setLoading(true);
                    break;
                case CONSTANTS.GET_MATTER_DETAILS_FROM_ACTIONSTEP_SUCCESS:
                    if (legalAidState.legalAidActionstepDataResponse != null) {
                        legalAidState.legalAidActionstepDataResponse.formType = formType;
                    }
                    setLegalAidMatterData(legalAidState.legalAidActionstepDataResponse ?? new LegalAidActionstepDataViewModel());
                    setLoading(false);
                    break;
                case CONSTANTS.GET_MATTER_DETAILS_FROM_ACTIONSTEP_FAILED:
                    setLoading(false);
                    break;
                // Add cases based on your requirements
                case CONSTANTS.GENERATE_LEGALAID_PDF_REQUESTED:
                    setLoading(true);
                    break;
                case CONSTANTS.GENERATE_LEGALAID_PDF_SUCCESS:
                    setLoading(false);
                    break;
                default:
                    setLoading(false);
                    break;
            }
        }
    }, [legalAidState.gotResponse, legalAidState.requestType]);



    const handleSelectedProviderChange = (providerType: string, providerValue: string) => {
        if (providerType == "LeadProvider") {
            setSelectedLeadProvider(providerValue)
        } else if (providerType == "Provider2") {
            setSelectedProvider2(providerValue)
        } else if (providerType == "OtherProvider") {
            setSelectedOtherProvider(providerValue)
        }
    };

    async function generatePDF() {
        try {
            // Dispatch the action to initiate the data fetching process
            setLoading(true);

            var pdfCommand = new GeneratePDFLegalAidCommand();
            pdfCommand.actionstepOrgKey = orgKey ?? "";
            pdfCommand.legalAidActionstepDataViewModel = legalAidMatterData ?? new LegalAidActionstepDataViewModel();
            pdfCommand.fileName = formType == FormType.Form15 ? "Form-15-Waitangi-Tribunal-Amendment.pdf" : "Form-16-Waitangi-Tribunal-Invoice.pdf";
            await dispatch(generateLegalAidPDF(pdfCommand));

        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };

    const renderCustomerDetails = () => {
        return (
            <Grid item xs={12} >
                <Grid container spacing={3} alignItems="stretch">
                    <Grid item xs={3}>
                        <TextField id="legalaidno" label="Legal Aid File No." value={legalAidMatterData?.legalAidFileNo || ''} InputProps={{
                            readOnly: true,
                        }}
                            variant="standard" />
                    </Grid>
                    {formType == FormType.Form15 &&
                        <Grid item xs={3}>
                            <TextField
                                id="currentDate"
                                label="Date"
                                type="date"
                                defaultValue={dateRangeFrom}
                                onChange={(e) => setLegalAidMatterDataForSave("currentdate", new Date(e.target.value))}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                    }
                    {formType == FormType.Form16 &&
                        <>
                            <Grid item xs={3}>
                                <TextField label="Invoice Reference No" value={legalAidMatterData?.invoiceRefNo || ''} />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField id="gstnumber" label="GST Number" value={legalAidMatterData?.gstNumber || ''} InputProps={{
                                    readOnly: true,
                                }}
                                    variant="standard" />
                            </Grid>

                            <Grid item xs={3}>
                                <TextField
                                    id="invDate"
                                    label="Invoice Date"
                                    type="date"
                                    defaultValue={dateRangeFrom}
                                    onChange={(e) => setLegalAidMatterDataForSave("invoicedate", new Date(e.target.value))}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField id="legalaidno" label="DX Box Number" value={legalAidMatterData?.dxBoxNumber || ''} onChange={(e) => setLegalAidMatterDataForSave("dxno", e.target.value)}
                                    variant="standard" />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField id="legalaidno" label="City" value={legalAidMatterData?.city || ''} onChange={(e) => setLegalAidMatterDataForSave("city", e.target.value)}

                                    variant="standard" />
                            </Grid>
                        </>}
                    <Grid item xs={3}>
                        <TextField id="legalaidno" label="Customer" value={legalAidMatterData?.customer || ''} />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField id="legalaidno" label="WAI Number" value={legalAidMatterData?.waiNumber || ''} InputProps={{
                            readOnly: true,
                        }}
                            variant="standard" />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField id="legalaidno" label="Inquiry" value={legalAidMatterData?.inquiry || ''} InputProps={{
                            readOnly: true,
                        }}
                            variant="standard" />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField id="legalaidno" label="Lead Provider" value={legalAidMatterData?.leadProvider || ''} InputProps={{
                            readOnly: true,
                        }}
                            variant="standard" />
                    </Grid>
                    {formType == FormType.Form16 &&
                        <Grid item xs={3}>
                            <TextField id="legalaidno" label="Law Firm" value={legalAidMatterData?.lawFirm || ''} onChange={(e) => setLegalAidMatterDataForSave("lawfirm", e.target.value)} InputProps={{
                                readOnly: true,
                            }}
                                variant="standard" />
                        </Grid>
                    }
                </Grid>
            </Grid>
        )
    }


    function formatDate(inputDate: Date | null): string {
        if (inputDate == null) {
            return "";
        }

        const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };
        const formattedDate = inputDate.toLocaleDateString('en-AU', options);

        return formattedDate;
    }

    function TotalCostPerActivity(activityType: ActivityType): string {

        let total = legalAidMatterData?.accountActivities?.filter(item => item.activityType == activityType).reduce((sum, item) => sum + Number(item.total), 0.00);
        return total?.toFixed(2) ?? "0.00";
    }

    const setLegalAidMatterDataForSave = (key: string, value: any) => {
        const claims: ClaimDetails = new ClaimDetails(legalAidMatterData?.claimDetails);
        const provideDetails = legalAidMatterData?.providerDetails;

        const legalAidMatterDataViewModel: LegalAidActionstepDataViewModel = new LegalAidActionstepDataViewModel(legalAidMatterData);

        switch (key) {
            case "formtype":
                legalAidMatterDataViewModel.formType = value;
                setLegalAidMatterData(new LegalAidActionstepDataViewModel(legalAidMatterDataViewModel));
                break;
            case "currentdate":
                legalAidMatterDataViewModel.currentDate = formatDate(value);
                setLegalAidMatterData(new LegalAidActionstepDataViewModel(legalAidMatterDataViewModel));
                break;
            case "form15Additionalcomments":
                legalAidMatterDataViewModel.form15Additionalcomments = value;
                setLegalAidMatterData(new LegalAidActionstepDataViewModel(legalAidMatterDataViewModel));
                break;
            case "comments":
                legalAidMatterDataViewModel.comments = value;
                setLegalAidMatterData(new LegalAidActionstepDataViewModel(legalAidMatterDataViewModel));
                break;
            case "interiminvoice":
                claims.interimInvoice = value;
                legalAidMatterDataViewModel.claimDetails = claims;
                setLegalAidMatterData(new LegalAidActionstepDataViewModel(legalAidMatterDataViewModel));
                break;

            case "finalinvoice":
                claims.finalInvoice = value;
                //const legalAidMatterDataViewModel: LegalAidActionstepDataViewModel = new LegalAidActionstepDataViewModel(legalAidMatterData);
                legalAidMatterDataViewModel.claimDetails = claims;
                setLegalAidMatterData(new LegalAidActionstepDataViewModel(legalAidMatterDataViewModel));
                break;
            case "inquirystage1":
                claims.stage1CaseBook = value;
                //const legalAidMatterDataViewModel: LegalAidActionstepDataViewModel = new LegalAidActionstepDataViewModel(legalAidMatterData);
                legalAidMatterDataViewModel.claimDetails = claims;
                setLegalAidMatterData(new LegalAidActionstepDataViewModel(legalAidMatterDataViewModel));
                break;
            case "inquirystage2":
                claims.stage2Interlocutory = value;
                //const legalAidMatterDataViewModel: LegalAidActionstepDataViewModel = new LegalAidActionstepDataViewModel(legalAidMatterData);
                legalAidMatterDataViewModel.claimDetails = claims;
                setLegalAidMatterData(new LegalAidActionstepDataViewModel(legalAidMatterDataViewModel));
                break;
            case "inquirystage3":
                claims.stage3Hearing = value;
                //const legalAidMatterDataViewModel: LegalAidActionstepDataViewModel = new LegalAidActionstepDataViewModel(legalAidMatterData);
                legalAidMatterDataViewModel.claimDetails = claims;
                setLegalAidMatterData(new LegalAidActionstepDataViewModel(legalAidMatterDataViewModel));
                break;
            case "legalaidserviceapprove":
                legalAidMatterDataViewModel.legalAidServiceApprove = value;
                setLegalAidMatterData(new LegalAidActionstepDataViewModel(legalAidMatterDataViewModel));
                break;
            case "legalaidservicefurtherinformation":
                legalAidMatterDataViewModel.legalAidServiceFurtherInfo = value;
                setLegalAidMatterData(new LegalAidActionstepDataViewModel(legalAidMatterDataViewModel));
                break;
            case "legalaidservicerefuse":
                legalAidMatterDataViewModel.legalAidServiceRefuse = value;
                setLegalAidMatterData(new LegalAidActionstepDataViewModel(legalAidMatterDataViewModel));
                break;
            case "leadproviderlevel":
                if (provideDetails != null) {
                    provideDetails.LeadProvider.providerExperience = value;
                }
                setLegalAidMatterData(new LegalAidActionstepDataViewModel(legalAidMatterDataViewModel));
                break;
            case "leadproviderlevelsup":
                if (provideDetails != null) {
                    provideDetails.LeadProvider.providerSUP = value;
                }
                setLegalAidMatterData(new LegalAidActionstepDataViewModel(legalAidMatterDataViewModel));
                break;
            case "provider2level":
                if (provideDetails != null) {
                    provideDetails.Provider2.providerExperience = value;
                }
                setLegalAidMatterData(new LegalAidActionstepDataViewModel(legalAidMatterDataViewModel));
                break;
            case "provider2sup":
                if (provideDetails != null) {
                    provideDetails.Provider2.providerSUP = value;
                }
                setLegalAidMatterData(new LegalAidActionstepDataViewModel(legalAidMatterDataViewModel));
                break;
            case "otherlevel":
                if (provideDetails != null) {
                    provideDetails.Other.providerExperience = value;
                }
                setLegalAidMatterData(new LegalAidActionstepDataViewModel(legalAidMatterDataViewModel));
                break;
            case "othersup":
                if (provideDetails != null) {
                    provideDetails.Other.providerSUP = value;
                }
                setLegalAidMatterData(new LegalAidActionstepDataViewModel(legalAidMatterDataViewModel));
                break;
            case "invoicedate":
                legalAidMatterDataViewModel.invoiceDate = formatDate(value);
                setLegalAidMatterData(new LegalAidActionstepDataViewModel(legalAidMatterDataViewModel));
                break;
            case "dxno":
                legalAidMatterDataViewModel.dxBoxNumber = value;
                setLegalAidMatterData(new LegalAidActionstepDataViewModel(legalAidMatterDataViewModel));
                break;
            case "city":
                legalAidMatterDataViewModel.city = value;
                setLegalAidMatterData(new LegalAidActionstepDataViewModel(legalAidMatterDataViewModel));
                break;
            case "providernumberother":
                if (provideDetails != null) {
                    provideDetails.Other.providerNumber = value;
                }
                setLegalAidMatterData(new LegalAidActionstepDataViewModel(legalAidMatterDataViewModel));
                break;
            case "leadproviderrate":
                if (provideDetails != null) {
                    provideDetails.LeadProvider.providerRate = value;
                    setLegalAidMatterData(new LegalAidActionstepDataViewModel(legalAidMatterDataViewModel));
                }
                break;
            case "provider2rate":
                if (provideDetails != null) {
                    provideDetails.Provider2.providerRate = value;
                }
                setLegalAidMatterData(new LegalAidActionstepDataViewModel(legalAidMatterDataViewModel));
                break;
            case "otherrate":
                if (provideDetails != null) {
                    provideDetails.Other.providerRate = value;
                }
                setLegalAidMatterData(new LegalAidActionstepDataViewModel(legalAidMatterDataViewModel));
                break;
            case "lawfirm":
                legalAidMatterDataViewModel.lawFirm = value;
                setLegalAidMatterData(new LegalAidActionstepDataViewModel(legalAidMatterDataViewModel));
                break;
            default:
                break;
        }
    }

    const renderClaimDetailsPart1 = () => {
        return (<Grid item xs={12} className='detailsTableSubHeading'>
            <Grid container spacing={3}>
                {formType == FormType.Form16 &&
                    <>
                        <Grid item xs={3}>
                            <FormControlLabel
                                control={<Checkbox className='checkboxColor' color="default" onChange={(e) => setLegalAidMatterDataForSave("interiminvoice", e.target.checked)} checked={legalAidMatterData?.claimDetails?.interimInvoice ?? false} />}
                                label="Interim invoice"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <FormControlLabel
                                control={<Checkbox color="default" onChange={(e) => setLegalAidMatterDataForSave("finalinvoice", e.target.checked)} checked={legalAidMatterData?.claimDetails?.finalInvoice ?? false} />}
                                label="Final Invoice"
                            />
                        </Grid>
                    </>
                }
                <Grid item xs={12}>
                    <Typography variant="h6" >
                        Covers period</Typography>
                </Grid>
                <Grid item xs={3}>
                    <TextField variant='standard' value={legalAidMatterData?.claimDetails?.coverPeriodFrom ?? ""} disabled />
                </Grid>
                <Grid item xs={3}>
                    <TextField variant='standard' value={legalAidMatterData?.claimDetails?.coverPeriodTo ?? ""} disabled />
                </Grid>
                <Table >
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                <Typography variant="h6" >Stage of Inquiry for this claim</Typography>

                                <FormControlLabel
                                    control={<Checkbox className='checkboxColor' color="default"
                                        onChange={(e) => setLegalAidMatterDataForSave("inquirystage1", e.target.checked)} checked={legalAidMatterData?.claimDetails?.stage1CaseBook ?? false} />}
                                    label="Stage 1 – Case Book"
                                />
                                <FormControlLabel
                                    control={<Checkbox color="default"
                                        onChange={(e) => setLegalAidMatterDataForSave("inquirystage2", e.target.checked)} checked={legalAidMatterData?.claimDetails?.stage2Interlocutory ?? false} />}
                                    label="Stage 2 – Interlocutory"
                                />
                                <FormControlLabel
                                    control={<Checkbox color="default"
                                        onChange={(e) => setLegalAidMatterDataForSave("inquirystage3", e.target.checked)} checked={legalAidMatterData?.claimDetails?.stage3Hearing ?? false} />}
                                    label="Stage 3 – Hearing"
                                />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Grid>
        </Grid>)
    }

    const renderLegalAidService = () => {
        return (<Grid item xs={12} className='padding-grid'>
            <Grid container>
                {formType == FormType.Form15 &&
                    <Grid item xs={12} className='padding10px'>
                        <TextField variant='outlined' multiline rows={5} fullWidth value={legalAidMatterData?.form15Additionalcomments} label="Please provide a brief overview of what you intend to undertake in this estimate period."
                            onChange={e => setLegalAidMatterDataForSave("form15Additionalcomments", e.target.value)} />
                    </Grid>}
                <Grid item xs={4}>
                    <FormControlLabel
                        control={<Checkbox color="default"
                            onChange={(e) => setLegalAidMatterDataForSave("legalaidserviceapprove", e.target.checked)} checked={legalAidMatterData?.legalAidServiceApprove ?? false} />}
                        label="Approve" labelPlacement='start'
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControlLabel
                        control={<Checkbox color="default"
                            onChange={(e) => setLegalAidMatterDataForSave("legalaidservicefurtherinformation", e.target.checked)} checked={legalAidMatterData?.legalAidServiceFurtherInfo ?? false} />}
                        label="Further Information" labelPlacement='start'
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControlLabel
                        control={<Checkbox color="default"
                            onChange={(e) => setLegalAidMatterDataForSave("legalaidservicerefuse", e.target.checked)} checked={legalAidMatterData?.legalAidServiceRefuse ?? false} />}
                        label="Refuse" labelPlacement='start'
                    />
                </Grid>
                <Grid item xs={12} className='padding-grid' >
                    <TextField variant='outlined' multiline rows={5} fullWidth value={legalAidMatterData?.comments ?? ""}
                        onChange={e => setLegalAidMatterDataForSave("comments", e.target.value)} />
                </Grid>
            </Grid>
        </Grid>)
    }
    const renderActivities = () => {
        return (
            legalAidMatterData?.accountActivities && legalAidMatterData?.accountActivities.length > 0 &&
            <Grid xs={12} item className='padding10px'>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                Activites
                            </TableCell>
                            <TableCell>
                                Lead Provider (Hours)
                            </TableCell>
                            <TableCell>
                                Provider 2 (Hours)
                            </TableCell>
                            <TableCell>
                                Other (Hours)
                            </TableCell>
                            <TableCell>
                                Total (Costs)
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell colSpan={5}>
                                Preparation (e.g. work immediately prior to attending meetings or conferences)
                            </TableCell>
                        </TableRow>
                        {legalAidMatterData?.accountActivities?.filter(item => item.activityType == ActivityType.Preparation).map((element: AccountActivity) =>
                            <TableRow>
                                <TableCell align='left'>
                                    <Typography>{element.activityDescription}</Typography>
                                </TableCell>
                                <TableCell align='center'>
                                    <Typography>{element.leadProviderHours}</Typography>
                                </TableCell>
                                <TableCell align='center'>
                                    <Typography>{element.provider2Hours}</Typography>
                                </TableCell>
                                <TableCell align='center'>
                                    <Typography>{element.otherHours}</Typography>
                                </TableCell>
                                <TableCell align='center'>
                                    <Typography>{element.total}</Typography>
                                </TableCell>
                            </TableRow>)}
                        <TableRow>
                            <TableCell colSpan={5} align='right'>
                                Total cost for Preparation $ {TotalCostPerActivity(ActivityType.Preparation)}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={5}>
                                Attendances (e.g. judicial conferences, hui, meeting with individuals, hearings)

                            </TableCell>
                        </TableRow>
                        {legalAidMatterData?.accountActivities?.filter(item => item.activityType == ActivityType.Attendances).map((element: AccountActivity) =>
                            <TableRow>
                                <TableCell align='left'>
                                    <Typography>{element.activityDescription}</Typography>
                                </TableCell>
                                <TableCell align='center'>
                                    <Typography>{element.leadProviderHours}</Typography>
                                </TableCell>
                                <TableCell align='center'>
                                    <Typography>{element.provider2Hours}</Typography>
                                </TableCell>
                                <TableCell align='center'>
                                    <Typography>{element.otherHours}</Typography>
                                </TableCell>
                                <TableCell align='center'>
                                    <Typography>{element.total}</Typography>
                                </TableCell>
                            </TableRow>)}
                        <TableRow>
                            <TableCell colSpan={5} align='right'>
                                Total cost for Attendances $ {TotalCostPerActivity(ActivityType.Attendances)}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={5}>
                                Reading Research and Reports
                            </TableCell>
                        </TableRow>
                        {legalAidMatterData?.accountActivities?.filter(item => item.activityType == ActivityType.ReadingResearchReports).map((element: AccountActivity) =>
                            <TableRow key={uuidv4()}>
                                <TableCell align='left'>
                                    <Typography>{element.activityDescription}</Typography>
                                </TableCell>
                                <TableCell align='center'>
                                    <Typography>{element.leadProviderHours}</Typography>
                                </TableCell>
                                <TableCell align='center'>
                                    <Typography>{element.provider2Hours}</Typography>
                                </TableCell>
                                <TableCell align='center'>
                                    <Typography>{element.otherHours}</Typography>
                                </TableCell>
                                <TableCell align='center'>
                                    <Typography>{element.total}</Typography>
                                </TableCell>
                            </TableRow>)}
                        <TableRow>
                            <TableCell colSpan={5} align='right'>
                                Total cost for Reading Research and Reports $ {TotalCostPerActivity(ActivityType.ReadingResearchReports)}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={5}>
                                Document Drafting (e.g. Statement of Claim, Memorandum of Counsel, Submissions)
                            </TableCell>
                        </TableRow>
                        {legalAidMatterData?.accountActivities?.filter(item => item.activityType == ActivityType.DocumentDrafting).map((element: AccountActivity) =>
                            <TableRow key={uuidv4()}>
                                <TableCell align='left'>
                                    <Typography>{element.activityDescription}</Typography>
                                </TableCell>
                                <TableCell align='center'>
                                    <Typography>{element.leadProviderHours}</Typography>
                                </TableCell>
                                <TableCell align='center'>
                                    <Typography>{element.provider2Hours}</Typography>
                                </TableCell>
                                <TableCell align='center'>
                                    <Typography>{element.otherHours}</Typography>
                                </TableCell>
                                <TableCell align='center'>
                                    <Typography>{element.total}</Typography>
                                </TableCell>
                            </TableRow>)}
                        <TableRow>
                            <TableCell colSpan={5} align='right'>
                                Total cost for  Document Drafting $ {TotalCostPerActivity(ActivityType.DocumentDrafting)}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={5}>
                                Other Activities (give full details)
                            </TableCell>
                        </TableRow>
                        {legalAidMatterData?.accountActivities?.filter(item => item.activityType == ActivityType.OtherActivities).map((element: AccountActivity) =>
                            <TableRow>
                                <TableCell align='left'>
                                    <Typography>{element.activityDescription}</Typography>
                                </TableCell>
                                <TableCell align='center'>
                                    <Typography>{element.leadProviderHours}</Typography>
                                </TableCell>
                                <TableCell align='center'>
                                    <Typography>{element.provider2Hours}</Typography>
                                </TableCell>
                                <TableCell align='center'>
                                    <Typography>{element.otherHours}</Typography>
                                </TableCell>
                                <TableCell align='center'>
                                    <Typography>{element.total}</Typography>
                                </TableCell>
                            </TableRow>)}
                        <TableRow>
                            <TableCell colSpan={5} align='right'>
                                Total cost for  Other Activities $ {TotalCostPerActivity(ActivityType.OtherActivities)}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Grid>
        )
    }

    const renderSummaryTable = () => {
        return (<Grid item xs={12} >
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell align='right'>
                            Provider Number
                        </TableCell>
                        <TableCell>
                            Lead Provider
                            <TextField variant='standard' value={legalAidMatterData?.providerDetails?.LeadProvider.providerNumber ?? ""} InputProps={{
                                readOnly: true,
                            }}
                            />
                        </TableCell>
                        <TableCell>
                            Provider 2
                            <TextField variant='standard' value={legalAidMatterData?.providerDetails?.Provider2.providerNumber ?? ""} InputProps={{
                                readOnly: true,
                            }} />
                        </TableCell>
                        <TableCell >
                            Other
                            <TextField variant='standard' value={legalAidMatterData?.providerDetails?.Other.providerNumber ?? ""}
                                onChange={(e => setLegalAidMatterDataForSave("providernumberother", e.target.value))} />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align='right'>
                            Level of experience
                        </TableCell>
                        <TableCell>
                            <RadioGroup row name="rating" onChange={(e) => setLegalAidMatterDataForSave("leadproviderlevel", e.target.value)} value={legalAidMatterData?.providerDetails?.LeadProvider.providerExperience ?? ""}>
                                <FormControlLabel value="1" control={<Radio color="default" />} label="1" />
                                <FormControlLabel value="2" control={<Radio color="default" />} label="2" />
                                <FormControlLabel value="3" control={<Radio color="default" />} label="3" />
                            </RadioGroup>

                        </TableCell>
                        <TableCell >
                            <FormControl>
                                <RadioGroup row name="rating" onChange={(e) => setLegalAidMatterDataForSave("provider2level", e.target.value)} value={legalAidMatterData?.providerDetails?.Provider2.providerExperience ?? ""}>
                                    <FormControlLabel value="1" control={<Radio color="default" />} label="1" />
                                    <FormControlLabel value="2" control={<Radio color="default" />} label="2" />
                                    <FormControlLabel value="3" control={<Radio color="default" />} label="3" />
                                </RadioGroup>
                                <FormControlLabel control={<Checkbox color="default"
                                    onChange={(e) => setLegalAidMatterDataForSave("provider2sup", e.target.checked)} checked={legalAidMatterData?.providerDetails?.Provider2.providerSUP ?? false} />}
                                    label="SUP" />

                            </FormControl>
                        </TableCell>
                        <TableCell >
                            <FormControl>
                                <RadioGroup row name="rating" onChange={(e) => setLegalAidMatterDataForSave("otherlevel", e.target.value)} value={legalAidMatterData?.providerDetails?.Other.providerExperience ?? ""}>
                                    <FormControlLabel value="1" control={<Radio color="default" />} label="1" />
                                    <FormControlLabel value="2" control={<Radio color="default" />} label="2" />
                                    <FormControlLabel value="3" control={<Radio color="default" />} label="3" />
                                </RadioGroup>
                                <FormControlLabel control={<Checkbox color="default"
                                    onChange={(e) => setLegalAidMatterDataForSave("othersup", e.target.checked)} checked={legalAidMatterData?.providerDetails?.Other.providerSUP ?? false} />}
                                    label="SUP" />

                            </FormControl>
                        </TableCell>
                    </TableRow>
                    <TableRow >
                        <TableCell align='right'>
                            <Typography>Provider rate (excl. GST)</Typography>
                        </TableCell>
                        <TableCell>
                            <TextField variant='standard' label='$' value={legalAidMatterData?.providerDetails?.LeadProvider.providerRate ?? ''}
                                onChange={e => setLegalAidMatterDataForSave("leadproviderrate", e.target.value)} size='small' />
                        </TableCell>
                        <TableCell>
                            <TextField variant='standard' label='$' value={legalAidMatterData?.providerDetails?.Provider2.providerRate ?? ''}
                                onChange={e => setLegalAidMatterDataForSave("provider2rate", e.target.value)} />
                        </TableCell>
                        <TableCell >
                            <TextField variant='standard' label='$' value={legalAidMatterData?.providerDetails?.Other.providerRate ?? ''}
                                onChange={e => setLegalAidMatterDataForSave("otherrate", e.target.value)} />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={4} align='left'>
                            <Typography>Summary</Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    <TableRow >
                        <TableCell align='left'>
                            <Typography>Activities</Typography>
                        </TableCell>
                        <TableCell align='center'>
                            <Typography>Hours</Typography>
                        </TableCell>
                        <TableCell align='center'>
                            <Typography>Hours</Typography>
                        </TableCell>
                        <TableCell align='center'>
                            <Typography>Hours</Typography>
                        </TableCell>
                        <TableCell align='center'>
                            <Typography>Total Costs</Typography>
                        </TableCell>
                    </TableRow>
                    {legalAidMatterData?.providerActivities && legalAidMatterData?.providerActivities.length > 0 && legalAidMatterData?.providerActivities?.map((element: ProviderActivity, index: number) =>
                        <TableRow key={uuidv4()}>
                            <TableCell align='left'>
                                <Typography>{element.activityDescription}</Typography>
                            </TableCell>
                            <TableCell align='center'>
                                <Typography>{element.totalLeadProviderHours}</Typography>
                            </TableCell>
                            <TableCell align='center'>
                                <Typography>{element.totalProvider2Hours}</Typography>
                            </TableCell>
                            <TableCell align='center'>
                                <Typography>{element.totalOtherHours}</Typography>
                            </TableCell>
                            <TableCell align='center'>
                                <Typography>{element.activityTotal}</Typography>
                            </TableCell>
                        </TableRow>)}

                    <TableRow>
                        <TableCell align='right' colSpan={5} >
                            <FormControlLabel
                                control={<TextField variant='outlined' value={legalAidMatterData?.providerTotal?.totalFee ?? 0} InputProps={{
                                    readOnly: true,
                                }} />}
                                labelPlacement='start'
                                label="Total fee for this period (excl GST) $   "
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align='right' colSpan={5}>
                            <FormControlLabel
                                control={<TextField variant='outlined' value={legalAidMatterData?.providerTotal?.totalDisbursements ?? 0} InputProps={{
                                    readOnly: true,
                                }} />}
                                label="Total disbursements (excl GST) $   "
                                labelPlacement='start'
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>

                        <TableCell align='right' colSpan={10}>
                            <FormControlLabel
                                control={<TextField variant='outlined' value={legalAidMatterData?.providerTotal?.totalGST ?? 0} InputProps={{
                                    readOnly: true,
                                }} />}
                                label="Total GST $   "
                                labelPlacement='start'
                            />
                        </TableCell>
                    </TableRow>
                    {formType == FormType.Form16 &&
                        <TableRow>
                            <TableCell align='right' colSpan={10}>
                                <FormControlLabel
                                    control={<TextField variant='outlined' value={legalAidMatterData?.providerTotal?.totalMileage ?? 0}
                                        InputProps={{
                                            readOnly: true,
                                        }} />}
                                    label="Total mileage (no GST) $"
                                    labelPlacement='start'
                                />
                            </TableCell>
                        </TableRow>
                    }
                    <TableRow>
                        <TableCell align='right' colSpan={10}>
                            <FormControlLabel
                                control={<TextField variant='outlined' value={legalAidMatterData?.providerTotal?.totalAmount ?? 0} InputProps={{
                                    readOnly: true,
                                }} />}
                                label="Total amount (incl. GST) $   "
                                labelPlacement='start'
                            />
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>

        </Grid>)
    }
    const renderDisbursements = () => {
        return (
            <Grid item xs={12} >
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                Disbursements (attach receipts/invoices, where applicable)
                            </TableCell>
                            <TableCell>
                                Units
                            </TableCell>
                            <TableCell>
                                Total (excl.GST)
                            </TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>

                        {legalAidMatterData?.legalAidDisbursements && legalAidMatterData?.legalAidDisbursements.length > 0 && legalAidMatterData?.legalAidDisbursements?.map((element: LegalAidDisbursement) =>
                            <TableRow>
                                <TableCell align='left'>
                                    {element.description ?? ""}
                                </TableCell>
                                <TableCell align='center'>
                                    {element.units ?? 0}
                                </TableCell>
                                <TableCell align='center'>
                                    {element.total ?? 0}
                                </TableCell>
                            </TableRow>)}
                    </TableBody>
                </Table>
            </Grid>
        )
    }

    const renderGeneratePDFButton = () => {
        return (
            <Grid item xs={12}>
                <Button variant="contained" className={`${classes.root} ${classes.generatePDFButton}`}
                    onClick={() => generatePDF()}
                >Generate PDF</Button>
            </Grid>)
    }

    const resetForm = (formValue: FormType) => {
        setFormType(formValue);
        setRenderForm(false);
        setLegalAidMatterDataForSave("formtype", formValue)
    }
    const renderFormType = () => {
        return (
            <Grid item xs={12} >
                <RadioGroup name="formtype" onChange={(e) => resetForm(e.target.value as FormType)} value={formType ?? ""}>
                    <FormControlLabel value={FormType.Form15} control={<Radio color="default" />} label={<Typography variant="h6" >09/22 form 15 Amendment to Grant Waitangi Tribunal Proceedings</Typography>} />
                    <FormControlLabel value={FormType.Form16} control={<Radio color="default" />} label={<Typography variant="h6" >09/22 form 16 Tax Invoice Waitangi Tribunal Proceedings</Typography>} />
                </RadioGroup>
            </Grid >)
    }

    const renderDateRange = () => {
        return (<Grid container spacing={4} className='dateRange-grid'>
            <Grid item xs={4}>
                <TextField
                    id="fromDate"
                    label="Select Date From"
                    type="date"
                    required
                    defaultValue={dateRangeFrom}
                    error={dateRangeFrom == null ? true : false}
                    onChange={(e) => setDateRangeFrom(new Date(e.target.value))}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    style={{ width: '100%' }}

                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    id="toDate"
                    label="Select Date To"
                    type="date"
                    defaultValue={dateRangeTo}
                    required
                    error={dateRangeTo == null ? true : false}
                    onChange={(e) => setDateRangeTo(new Date(e.target.value))}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    style={{ width: '100%' }}
                />
            </Grid>
        </Grid>)
    }

    const renderProviderFilter = () => {

        return (<Grid container spacing={4} className='dateRange-grid'>
            <Grid item xs={3}>
                <FormControl fullWidth>
                    <InputLabel id="selct-lead-provider">Lead Provider</InputLabel>
                    <Select
                        labelId="selct-lead-provider-label"
                        id="selct-lead-provider-select"
                        value={selectedLeadProvider}
                        label="Fruit"
                        onChange={(event) => handleSelectedProviderChange("LeadProvider", event.target.value as string)}
                    >
                        {
                            providerList?.leadProvider?.map((item: Party) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.firstName} {item.lastName}
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={3}>
                <FormControl fullWidth>
                    <InputLabel id="selct-provider2">Provider2</InputLabel>
                    <Select
                        labelId="selct-provider2-label"
                        id="selct-provider2-select"
                        value={selectedProvider2}
                        label="Fruit"
                        onChange={(event) => handleSelectedProviderChange("Provider2", event.target.value as string)}
                    >
                        {
                            providerList?.provider2?.map((item: Party) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.firstName} {item.lastName}
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={3}>
                <FormControl fullWidth>
                    <InputLabel id="selct-otherProvider">Other Provider</InputLabel>
                    <Select
                        labelId="selct-otherProvider-label"
                        id="selct-otherProvider-select"
                        value={selectedOtherProvider}
                        label="Fruit"
                        onChange={(event) => handleSelectedProviderChange("OtherProvider", event.target.value as string)}
                    >
                        {
                            providerList?.provider2?.map((item: Party) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.firstName} {item.lastName}
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>
            </Grid>
        </Grid>)
    }
    return (
        <div className='legalaid-body'>
            {renderFormType()}
            {formType != null &&
                <>
                    {/*  {renderHeading()}*/}
                    {renderDateRange()}
                    {renderProviderFilter()}
                    <Button onClick={onSearch} variant="contained" className={`${classes.root} ${classes.generatePDFButton}`} >Search</Button>

                    {renderForm &&
                        <><div>
                            <Divider />
                            <Grid container spacing={3} className='legalaid-search-container' >
                                {renderCustomerDetails()}
                                {renderClaimDetailsPart1()}
                                {renderSummaryTable()}
                                {renderLegalAidService()}
                                {renderActivities()}
                                {renderDisbursements()}
                                {renderGeneratePDFButton()}
                            </Grid>
                        </div><div data-cy="modal-header" /><Modal
                            isOpen={loading}
                            isBlocking={true}
                            className={loading !== null ? "animated fadeIn  settlement-calculator" : "animated fadeOut settlement-calculator"}
                        >
                                <div className="modal-body">
                                    <div className="ms-Grid-row loading-modal">
                                        <img
                                            src="/images/Konekta_loading.svg" alt="Logo" height="150" />
                                        <h2>Please wait...</h2>
                                    </div>
                                </div>
                            </Modal></>
                    }

                </>
            }

        </div >

    );
};

export default LegalAidReport;





