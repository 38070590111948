import { call, put, takeLatest } from 'redux-saga/effects'

import * as ACTIONS from './actions'
import { getRequest } from 'utils/request'
import { RequestPropertyInformationFromActionstepResponse } from 'utils/wcaApiTypes'
import { GlobalXEvents } from './events';

export function* getCdmInformationSaga(action: ReturnType<typeof ACTIONS.getCdmInformation>) {
    try {
        const response = yield call(getRequest, `/api/globalx/conveyancing-data-from-actionstep?actionsteporg=${action.payload.matterInfo.orgKey!}&matterid=${action.payload.matterInfo.matterId}&entryPoint=${action.payload.entryPoint}&embed=${action.payload.embed}`);

        let data: RequestPropertyInformationFromActionstepResponse = response;

        yield put(ACTIONS.getCdmInformationSuccess(data));

    } catch (error) {
        yield put(ACTIONS.getCdmInformationFailed(error?.response?.data));
    }
}

export default function* globalXSaga() {
    yield takeLatest(GlobalXEvents.GET_PROPERTY_INFORMATION_REQUESTED, getCdmInformationSaga);
}