import { EntityBase, IEntityBase, MatterDetails } from "utils/wcaApiTypes";

export interface ISpecialMeterReadingViewModel {
    actionstepOrg?: string | undefined;
    matterId: number;
    version: number;
    specialMeterReadingData?: SpecialMeterReadingInfo | undefined;
    localDateTime: string;
}

export class SpecialMeterReadingViewModel implements ISpecialMeterReadingViewModel {
    actionstepOrg?: string | undefined;
    matterId!: number;
    version!: number;
    specialMeterReadingData?: SpecialMeterReadingInfo | undefined;
    localDateTime!: string;

    constructor(data?: ISpecialMeterReadingViewModel) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.actionstepOrg = _data["actionstepOrg"];
            this.matterId = _data["matterId"];
            this.version = _data["version"];
            this.specialMeterReadingData = _data["specialMeterReadingData"] ? SpecialMeterReadingInfo.fromJS(_data["specialMeterReadingData"]) : <any>undefined;
        }
    }

    static fromJS(data: any): SpecialMeterReadingViewModel {
        data = typeof data === 'object' ? data : {};
        let result = new SpecialMeterReadingViewModel();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["actionstepOrg"] = this.actionstepOrg;
        data["matterId"] = this.matterId;
        data["version"] = this.version;
        data["localDateTime"] = this.localDateTime;
        data["specialMeterReadingData"] = this.specialMeterReadingData ? this.specialMeterReadingData.toJSON() : <any>undefined;
        return data;
    }
}

export interface ISpecialMeterReadingInfo {
    matterDetails?: MatterDetails | undefined;
    waterUsage?: { [key: string]: any; } | undefined;
    additionalInfo?: { [key: string]: any; } | undefined;
}

export class SpecialMeterReadingInfo implements ISpecialMeterReadingInfo {
    matterDetails?: MatterDetails | undefined;
    waterUsage?: { [key: string]: any; } | undefined;
    additionalInfo?: { [key: string]: any; } | undefined;

    constructor(data?: ISpecialMeterReadingInfo) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.matterDetails = _data["matterDetails"] ? MatterDetails.fromJS(_data["matterDetails"]) : <any>undefined;
            if (_data["waterUsage"]) {
                this.waterUsage = {} as any;
                for (let key in _data["waterUsage"]) {
                    if (_data["waterUsage"].hasOwnProperty(key))
                        this.waterUsage![key] = _data["waterUsage"][key];
                }
            }
            if (_data["additionalInfo"]) {
                this.additionalInfo = {} as any;
                for (let key in _data["additionalInfo"]) {
                    if (_data["additionalInfo"].hasOwnProperty(key))
                        this.additionalInfo![key] = _data["additionalInfo"][key];
                }
            }
        }
    }

    static fromJS(data: any): SpecialMeterReadingInfo {
        data = typeof data === 'object' ? data : {};
        let result = new SpecialMeterReadingInfo();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["matterDetails"] = this.matterDetails ? this.matterDetails.toJSON() : <any>undefined;
        if (this.waterUsage) {
            data["waterUsage"] = {};
            for (let key in this.waterUsage) {
                if (this.waterUsage.hasOwnProperty(key))
                    data["waterUsage"][key] = this.waterUsage[key];
            }
        }
        if (this.additionalInfo) {
            data["additionalInfo"] = {};
            for (let key in this.additionalInfo) {
                if (this.additionalInfo.hasOwnProperty(key))
                    data["additionalInfo"][key] = this.additionalInfo[key];
            }
        }
        return data;
    }
}

export interface ISpecialMeterReadingMatter extends IEntityBase {
    id: number;
    actionstepOrgKey?: string | undefined;
    actionstepMatterId: number;
    version: number;
    specialMeterReadingData?: SpecialMeterReadingInfo | undefined;
}

export class SpecialMeterReadingMatter extends EntityBase implements ISpecialMeterReadingMatter {
    id!: number;
    actionstepOrgKey?: string | undefined;
    actionstepMatterId!: number;
    version!: number;
    specialMeterReadingData?: SpecialMeterReadingInfo | undefined;

    constructor(data?: ISpecialMeterReadingMatter) {
        super(data);
    }

    init(_data?: any) {
        super.init(_data);
        if (_data) {
            this.id = _data["id"];
            this.actionstepOrgKey = _data["actionstepOrgKey"];
            this.actionstepMatterId = _data["actionstepMatterId"];
            this.version = _data["version"];
            this.specialMeterReadingData = _data["specialMeterReadingData"] ? SpecialMeterReadingInfo.fromJS(_data["specialMeterReadingData"]) : <any>undefined;
        }
    }

    static fromJS(data: any): SpecialMeterReadingMatter {
        data = typeof data === 'object' ? data : {};
        let result = new SpecialMeterReadingMatter();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["actionstepOrgKey"] = this.actionstepOrgKey;
        data["actionstepMatterId"] = this.actionstepMatterId;
        data["version"] = this.version;
        data["specialMeterReadingData"] = this.specialMeterReadingData ? this.specialMeterReadingData.toJSON() : <any>undefined;
        super.toJSON(data);
        return data;
    }
}