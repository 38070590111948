export function renderStatus (status)  {
    switch (status) {
        case 'Draft':
            return 'DRAFT';
        case 'SentToClient':
            return 'SENT TO CLIENT';
        case 'InProgress':
            return 'IN PROGRESS';
        case 'Finalised':
            return 'FINALISED';
        case 'RequiresReview':
            return 'REQUIRES REVIEW';
        case 'ReadyToCountersign':
            return 'READY FOR COUNTERSIGNING ';
        default:
            return '';
    }
}

export function renderCustomerStatus(status) {
    switch (status) {
        case 'NotStarted':
            return 'NOT STARTED';
        case 'Rejected':
            return 'REJECTED';
        case 'Approved':
            return 'APPROVED';
        case 'NotReviewed':
            return 'NOT REVIEWED';
        default:
            return '';
    }
}