import React from 'react';
import { Stack } from 'office-ui-fabric-react';
import { Icon, PrimaryButton } from '@fluentui/react';

interface IProps {
    onClickHandler: () => void;
}

export const RemoveBuyerComponent = ({ onClickHandler }: IProps) => {
    return (
        <Stack className="remove-buyer-container" disableShrink>
            <PrimaryButton onClick={onClickHandler} >
                <Icon iconName="ChromeClose" aria-hidden="true" />
            </PrimaryButton>
        </Stack>
    )
}