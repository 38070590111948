import * as React from 'react';

import {
    SubwayNavNodeState,
    ISubwayNavNodeProps,
    setSubwayState    
} from 'components/SubwayNav'


import EmailArchiverDataCheck from 'containers/email-archiver/steps/emailArchiverDataCheck';
import ProcessEmailArchiver from 'containers/email-archiver/steps/processEmailArchiver';

import "./emailArchiver.css"

interface IAppProps {
}

type AppProps = IAppProps;


export default class EmailArchiver extends React.Component<AppProps, any> {
    defaultSteps: ISubwayNavNodeProps[] = [];

    constructor(props: AppProps) {
        super(props);

        this.defaultSteps = [
            {
                id: '0',
                label: 'Data Check',
                state: SubwayNavNodeState.Current,
                onClickStep: this._handleClickStep,
                disabled: true
            },        
            {
                id: '1',
                label: 'Process Email Archive ',
                state: SubwayNavNodeState.NotStarted,
                onClickStep: this._handleClickStep,
                disabled: true
            }           
        ];

        this.state = {
            steps: this.defaultSteps,         
            currentStepId: 0        
        };
    }

    public componentDidMount(): void {
    }

    private _handleClickStep = (step: ISubwayNavNodeProps): void => {
        this.setState({ ...setSubwayState(step, this.state.steps, this.state.currentStepId) });
    }

    private _onChangeStep = (newState: SubwayNavNodeState, newStep: number = -1): void => {
        let newSteps = [...this.state.steps];
        let currentStepId = this.state.currentStepId;

        if (newStep >= 0) {
            currentStepId = newStep;
        }
        for (let i = 0; i < currentStepId; i++) {
            newSteps[i].state = SubwayNavNodeState.Completed;
        }
        for (let i = currentStepId + 1; i < newSteps.length; i++) {
            newSteps[i].state = SubwayNavNodeState.NotStarted;
        }

        newSteps[currentStepId].state = newState;
        if (newState === SubwayNavNodeState.Completed && currentStepId < newSteps.length - 1) {
            currentStepId++;
            newSteps[currentStepId].state = SubwayNavNodeState.Current;
        }

        this.setState({
            steps: newSteps,
            currentStepId: currentStepId
        })

        window.scrollTo(0, 0);
    }   

    render() {
        const { currentStepId} = this.state;

        return (
            <div>             
                <div className="vertical-container wrapper-content animated fadeIn" dir="ltr">                  
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-lg12 ">              
                            <h3 className="tittle">
                                <big><b>EMAIL ARCHIVER</b></big>
                            </h3>
                            <br />                                       

                            {currentStepId === 0 && <EmailArchiverDataCheck onChangeStep={this._onChangeStep} />}  

                            {currentStepId === 1 && <ProcessEmailArchiver onChangeStep={this._onChangeStep}/>}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
