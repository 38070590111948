import React from "react";
import { connect } from 'react-redux';
import 'containers/pexa/pexaRefresher.css'
import { AppState } from 'app.types';
import { Redirect } from 'react-router-dom';
import { Modal } from "office-ui-fabric-react/lib/Modal";
import { DefaultButton, PrimaryButton, IconButton } from 'office-ui-fabric-react/lib/Button';
import RefreshDealingNumber from 'containers/pexa/steps/components/refreshDealingNumber';
import { getActionstepPropertyDetails, updateDealingNumberActionstep, clearPexaWorkspaceDealingNumberData, getFSSLineItems, setFSSLineItems, clearPexaStateAndError, clearFSSLineItemsState } from 'containers/pexa/redux/actions';
import { OrgKeyAndMatter } from 'utils/wcaApiTypes';
import { DealingNumberViewModel, UpdateDealingNumberActionstepCommand } from 'utils/newWcaApiTypes';
import * as CONSTANTS from 'containers/pexa/redux/constants';
import LoadingWidget from 'components/common/loadingWidget';
import { MessageBar, MessageBarType } from 'office-ui-fabric-react'
import FSSLineItems from "./steps/components/FSSLineItems";
import { SubwayNavNodeState } from "components/SubwayNav";
import { UpdateFSSLineItems } from "./pexaApiTypes";
import Moment from 'moment';

interface IAppProps {
    onChangeStep: (newState: SubwayNavNodeState, newStep?: number) => void;
}

type AppProps = IAppProps & ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;
type AppStates = {
    redirect: string,
    matterId: number | undefined
    orgKey: string | undefined,
    errors: boolean | undefined
    showModal: boolean | undefined
    updatedState: any,
    isLoading: boolean,
    pexaworkspaceDealingNumber: DealingNumberViewModel[] | undefined,
    actionstepPropertyDetails: DealingNumberViewModel[] | undefined,
    UpdateDealingNumberActionstepCommand: UpdateDealingNumberActionstepCommand,
    showDealingNumber: boolean,
    showFss: boolean,
    workspaceId: string | undefined
}

export class PexaRefresherData extends React.Component<AppProps, AppStates> {
    constructor(props: any) {
        super(props);
        this.redirectToHome = this.redirectToHome.bind(this);
        this.state = {
            redirect: "",
            matterId: 0,
            orgKey: "",
            errors: false,
            showModal: false,
            updatedState: {},
            isLoading: false,
            pexaworkspaceDealingNumber: [],
            actionstepPropertyDetails: [],
            UpdateDealingNumberActionstepCommand: new UpdateDealingNumberActionstepCommand(),
            showDealingNumber: false,
            showFss: false,
            workspaceId: undefined
        };
    }

    private redirectToHome = () => {
        this.setState({ redirect: "/matter" });
    }
    closeModal = () => {
        this.setState({
            ...this.state,
            showModal: false
        });
    }
    public async componentDidMount(): Promise<void> {
        const { actionstepContext } = this.props;
        const { pexaWorkspaceCreationData } = this.props;
        this.setState({
            ...this.state,
            orgKey: actionstepContext?.orgKey,
            matterId: actionstepContext?.matterContext?.id ?? 0,
            workspaceId: pexaWorkspaceCreationData.existingPexaWorkspace?.pexaWorkspaceId ?? ""
        })
    }
    // Defining the componentWillUnmount method
    componentWillUnmount() {
        this.props.clearPexaStateAndError();
    }


    private getDealingNumber(workspaceId: string) {
        const { workspaceSummaryList } = this.props;
        let workspaceSummary = workspaceSummaryList[workspaceId].data;
        let dealingNumberDetails: DealingNumberViewModel[] = []
        if (workspaceSummary != null) {
            workspaceSummary.documentSummary?.map(summary => {
                summary.documents?.map(document => {
                    var landTitleReference = document.landTitleReference?.[0] ?? "";
                    dealingNumberDetails.push(new DealingNumberViewModel({
                        dealingNumber: document.lodgement?.dealingNumber,
                        landTitleReference: landTitleReference,
                        dealingNumberDate: Moment(document.lodgement?.date).format('DD/MM/YYYY')
                    }))
                })
            })
            this.setState((prevState) => {
                return {
                    ...prevState,
                    pexaworkspaceDealingNumber: dealingNumberDetails
                }
            })
        }
    }


    private renderPexaWorkspaceDealingNumberModal = () => {
        const { isLoading, showDealingNumber, showFss } = this.state;
        const { dealingNumberUpdateMessage, fssUpdateLineItems, error, success } = this.props;
        let element =
            <>
                <div className="container">
                    {showDealingNumber && dealingNumberUpdateMessage != undefined &&
                        <MessageBar messageBarType={MessageBarType.info}> {dealingNumberUpdateMessage}</MessageBar>
                    }

                    {showFss && fssUpdateLineItems != undefined &&
                        fssUpdateLineItems != "" && fssUpdateLineItems == "success" && <MessageBar messageBarType={MessageBarType.success}>Line items updated successfully.</MessageBar>
                    }

                    {showFss && fssUpdateLineItems != undefined &&
                        fssUpdateLineItems != "" && fssUpdateLineItems != "success" && <MessageBar messageBarType={MessageBarType.info}>Line items updated successfully but {fssUpdateLineItems}</MessageBar>
                    }
                    {!success && error != undefined ? error && error.errorList && error.errorList.length > 0 ? <MessageBar messageBarType={MessageBarType.error}>
                        {error.errorList[0]}
                    </MessageBar> : <MessageBar>Unable to refresh</MessageBar> : ""}
                </div>
                <div className="container" >
                    <div>
                        <div className="headertext">
                            <p>Data Refresh</p>
                        </div>
                        <div className="totalarea">

                            <div className="btndeal">
                                <div className="ms-Grid-col doc-return-button buttonplaces" dir="rtl">
                                    <PrimaryButton
                                        className="btnsize"
                                        text="Dealing No"
                                        onClick={this.DealingNumberModel} />
                                </div>
                                <div className="ms-Grid-col doc-return-button buttonplaces" dir="rtl">
                                    <PrimaryButton
                                        className="btnsize"
                                        text="Financial Line Items"
                                        onClick={this.FSSModal} />
                                </div>
                                <div className="ms-Grid-col doc-return-button buttonplaces-back" dir="rtl">
                                    <PrimaryButton
                                        className="btkbtnsize"
                                        text="Return to konekta"
                                        onClick={this.redirectToHome} />
                                </div>
                                <Modal
                                    isOpen={this.state.showModal}
                                    isBlocking={false}
                                    onDismiss={() => this.closeModal()}
                                    className={
                                        this.state.showModal
                                            ? "animated fadeIn settlement-calculator"
                                            : "animated fadeOut settlement-calculator"
                                    }
                                >
                                    {isLoading ?
                                        <LoadingWidget message="Loading details..." />
                                        : <>
                                            <div className="modal-header">
                                                <span >{showDealingNumber ? "Dealing Number" : "FSS Line Item Updates"}</span>
                                                <IconButton
                                                    className="modal-close-button"
                                                    onClick={this.closeModal}
                                                    iconProps={{
                                                        iconName: 'ChromeClose',
                                                        style: {
                                                            fontSize: 12,
                                                            fontWeight: 500
                                                        }
                                                    }}
                                                />
                                            </div>
                                            {showDealingNumber ?
                                                <RefreshDealingNumber
                                                    updatedState={this.state}
                                                /> :
                                                <FSSLineItems />}

                                            <div className="modal-footer">
                                                <DefaultButton
                                                    className="button gray-button"
                                                    text="Cancel"
                                                    onClick={this.closeModal}
                                                    allowDisabledFocus={true}
                                                />
                                                <PrimaryButton
                                                    text={showDealingNumber ? "Update Actionstep" : "Update Pexa"}
                                                    onClick={this.update}
                                                    allowDisabledFocus={true}
                                                    disabled={showDealingNumber ? this.state.pexaworkspaceDealingNumber?.length == 0 ? true : false : this.isFSSLineItemsPexaButtonEnabled() ? true : false}
                                                />
                                            </div>
                                        </>}

                                </Modal>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        return element;
    }

    private isFSSLineItemsPexaButtonEnabled(): boolean {
        const sourceTotal = this.props.fssLineItems?.reduce((sum, item) => {
            const amount = parseFloat(item?.sourceAmount ?? "") || 0;
            var returnValue = parseFloat((sum + amount).toFixed(2));
            return returnValue;
        }, 0);

        var destinationTotal = this.props.fssLineItems?.reduce((sum, item) => {
            const amount = parseFloat(item?.amount ?? "") || 0;
            var returnValue = parseFloat((sum + amount).toFixed(2));
            return returnValue;
        }, 0);

        return sourceTotal != destinationTotal
    }

    public render(): JSX.Element {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }
        return (
            <>
                {this.renderPexaWorkspaceDealingNumberModal()}
            </>)
    }

    static getDerivedStateFromProps(nextProps: AppProps): AppStates {
        let nextState = {} as AppStates;
        if (nextProps.gotResponse) {
            switch (nextProps.requestType) {
                case CONSTANTS.GET_ACTIONSTEP_PROPERTY_DETAILS_SUCCESS: {
                    if (nextProps.success === true) {
                        if (nextProps.actionstepPropertyDetails != undefined) {
                            nextState = {
                                ...nextState,
                                actionstepPropertyDetails: nextProps.actionstepPropertyDetails,
                            };
                        }
                    }
                    break;
                }
                case CONSTANTS.GET_ACTIONSTEP_PROPERTY_DETAILS_FAILED: {
                    nextState.isLoading = false;
                    break;
                }
                case CONSTANTS.UPDATE_DEALING_NUMBER_ACTIONSTEP_REQUESTED: {
                    nextState.isLoading = true;
                    break;
                }
                case CONSTANTS.UPDATE_DEALING_NUMBER_ACTIONSTEP_SUCCESS: {
                    nextState.isLoading = false;
                    nextState.showModal = false;
                    nextState = {
                        ...nextState,
                        errors: false
                    };
                    nextProps.clearPexaWorkspaceDealingNumberData();
                    break;
                }
                case CONSTANTS.UPDATE_DEALING_NUMBER_ACTIONSTEP_FAILED: {
                    nextState.isLoading = false;
                    nextState.showModal = false;
                    nextState = {
                        ...nextState,
                        errors: true,
                    };
                    nextProps.clearPexaWorkspaceDealingNumberData();
                    break;
                }

                case CONSTANTS.GET_FSS_LINEITEMS_REQUESTED: {
                    nextState.isLoading = true;
                    nextProps.clearFSSLineItemsState();
                    break;
                }
                case CONSTANTS.GET_FSS_LINEITEMS_SUCCESS: {
                    nextState.isLoading = false;

                    nextState = {
                        ...nextState,
                        errors: false
                    }
                    break;
                }
                case CONSTANTS.GET_FSS_LINEITEMS_FAILED: {
                    nextState.isLoading = false;
                    nextState.showModal = false;
                    nextState = {
                        ...nextState,
                        errors: false
                    }
                    break;
                }
                case CONSTANTS.SET_FSS_LINEITEMS_REQUESTED: {
                    nextState.isLoading = true;
                    break;
                }
                case CONSTANTS.SET_FSS_LINEITEMS_SUCCESS: {
                    nextState.isLoading = false;
                    nextState.showModal = false;
                    nextState = {
                        ...nextState,
                        errors: false
                    }
                    break;
                }
                case CONSTANTS.SET_FSS_LINEITEMS_FAILED: {
                    nextState.isLoading = false;
                    nextState.showModal = false;
                    break;
                }
                default:
                    return nextState;
            }
        }
        return nextState;
    }

    private DealingNumberModel = () => {
        const { workspaceId, orgKey, matterId } = this.state;
        this.props.clearPexaStateAndError();
        const matterInfo = new OrgKeyAndMatter({
            orgKey: orgKey,
            matterId: matterId?.toString(),
        });
        this.getDealingNumber(workspaceId ?? "");
        this.props.getActionstepPropertyDetails(matterInfo);
        this.setState((prevState) => {
            return {
                ...prevState,
                showModal: true,
                showDealingNumber: true,
                showFss: false
            }
        })
        return true;
    }

    private FSSModal = () => {
        const { orgKey, matterId } = this.state;
        this.props.clearPexaStateAndError()
        this.setState((prevState) => {
            return {
                ...prevState,
                showModal: true,
                showDealingNumber: false,
                showFss: true
            }
        })
        this.props.getFSSLineItems(matterId ?? 0, orgKey ?? "");
    }

    update = () => {
        const { showDealingNumber, workspaceId, matterId, orgKey } = this.state;
        const { pexaWorkspaceCreationData, fssLineItems } = this.props;
        if (showDealingNumber) {
            var pexaDealingNo: any;
            pexaDealingNo = this.state.pexaworkspaceDealingNumber;
            let newUpdateDealingNumberActionstepCommand = new UpdateDealingNumberActionstepCommand();
            newUpdateDealingNumberActionstepCommand.landTitleReferenceDealingNumber = [];
            pexaDealingNo.map((element: DealingNumberViewModel) => {
                newUpdateDealingNumberActionstepCommand.landTitleReferenceDealingNumber?.push(element.landTitleReference + "|" + element.dealingNumber + "|" + element.dealingNumberDate)
            })
            newUpdateDealingNumberActionstepCommand.actionstepOrg = this.state.orgKey;
            newUpdateDealingNumberActionstepCommand.matterId = this.state.matterId ?? 0;

            this.props.updateDealingNumberActionstep(newUpdateDealingNumberActionstepCommand);
        } else {
            let pexaSubscriberRole = pexaWorkspaceCreationData.existingPexaWorkspace?.pexaWorkspaceUri?.split("=")[1];
            let updateFSSLIneItems = new UpdateFSSLineItems();
            updateFSSLIneItems.role = pexaSubscriberRole;
            updateFSSLIneItems.workspaceId = workspaceId;
            updateFSSLIneItems.fssLineItems = fssLineItems;
            updateFSSLIneItems.matterId = matterId;
            updateFSSLIneItems.orgKey = orgKey;
            this.props.setFSSLineItems(updateFSSLIneItems);
        }

        return true;
    }
}

const mapStateToProps = (state: AppState) => {
    return {

        gotResponse: state.pexa.gotResponse,
        actionstepContext: state.common.actionstepContext,
        requestType: state.pexa.requestType,
        success: state.pexa.success,
        actionstepPropertyDetails: state.pexa.actionstepPropertyDetails,
        dealingNumberUpdateMessage: state.pexa.dealingNumberUpdateMessage,
        actionstepError: state.pexa.actionstepError,
        workspaceSummaryList: state.pexa.workspaceSummaryList,
        pexaWorkspaceCreationData: state.pexa.pexaWorkspaceCreationData,
        fssLineItems: state.pexa.fssLineItems,
        fssUpdateLineItems: state.pexa.fssLineItemsUpdate,
        error: state.pexa.error
    }
}

const mapDispatchToProps = {
    getFSSLineItems,
    getActionstepPropertyDetails,
    updateDealingNumberActionstep,
    clearPexaWorkspaceDealingNumberData,
    setFSSLineItems,
    clearPexaStateAndError,
    clearFSSLineItemsState
}

export default connect(mapStateToProps, mapDispatchToProps)(PexaRefresherData);
