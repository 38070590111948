import { ActionstepMatterInfo } from "utils/wcaApiTypes";
import { IdentityType } from "../components/constants";

export class StewartTitleActionstepResponse implements IStewartTitleActionstepResponse {
    actionstepData?: StewartTitleActionstepMatter | undefined;

    constructor(data?: IStewartTitleActionstepResponse) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.actionstepData = _data["actionstepData"] ? StewartTitleActionstepMatter.fromJS(_data["actionstepData"]) : <any>undefined;
        }
    }

    static fromJS(data: any): StewartTitleActionstepResponse {
        data = typeof data === 'object' ? data : {};
        let result = new StewartTitleActionstepResponse();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["actionstepData"] = this.actionstepData ? this.actionstepData.toJSON() : <any>undefined;
        return data;
    }
}

export class OrderStewartTitlePolicyCommand implements IOrderStewartTitlePolicyCommand {
    actionstepMatter?: StewartTitleActionstepMatter | undefined;
    matterId!: number;
    actionstepOrgKey?: string | undefined;

    constructor(data?: IOrderStewartTitlePolicyCommand) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.actionstepMatter = _data["actionstepMatter"] ? StewartTitleActionstepMatter.fromJS(_data["actionstepMatter"]) : <any>undefined;
            this.matterId = _data["matterId"];
            this.actionstepOrgKey = _data["actionstepOrgKey"];
        }
    }

    static fromJS(data: any): OrderStewartTitlePolicyCommand {
        data = typeof data === 'object' ? data : {};
        let result = new OrderStewartTitlePolicyCommand();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["actionstepMatter"] = this.actionstepMatter ? this.actionstepMatter.toJSON() : <any>undefined;
        data["matterId"] = this.matterId;
        data["actionstepOrgKey"] = this.actionstepOrgKey;
        return data;
    }
}

export class OrderStewartTitlePolicyCommandResponse implements IOrderStewartTitlePolicyCommandResponse {
    message?: string | undefined;
    errors?: ResponseError[] | undefined;
    fileNo!: number;
    policyNumber?: string | undefined;
    premium?: number | undefined;
    stampDuty?: number | undefined;
    gst?: number | undefined;
    totalPremium?: number | undefined;
    status?: string | undefined;


    constructor(data?: IOrderStewartTitlePolicyCommandResponse) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>this)[property];
                }
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.message = _data["message"];
            if (Array.isArray(_data["errors"])) {
                this.errors = [] as any;
                for (let item of _data["errors"])
                    this.errors!.push(ResponseError.fromJS(item));
            }
            this.fileNo = _data["fileNo"];
            this.policyNumber = _data["policyNumber"];
            this.premium = _data["premium"];
            this.stampDuty = _data["stampDuty"];
            this.gst = _data["gst"];
            this.totalPremium = _data["totalPremium"];
            this.status = _data["status"];
        }
    }

    static fromJS(data: any): OrderStewartTitlePolicyCommandResponse {
        data = typeof data === 'object' ? data : {};
        let result = new OrderStewartTitlePolicyCommandResponse();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["message"] = this.message;
        if (Array.isArray(this.errors)) {
            data["errors"] = [];
            for (let item of this.errors)
                data["errors"].push(item.toJSON());
        }
        data["fileNo"] = this.fileNo;
        data["policyNumber"] = this.policyNumber;
        data["premium"] = this.premium;
        data["stampDuty"] = this.stampDuty;
        data["gst"] = this.gst;
        data["totalPremium"] = this.totalPremium;
        data["status"] = this.status;
        return data;
    }
}

export class StewartTitleOrderDetailsQueryResponse implements IStewartTitleOrderDetailsQueryResponse {
    message?: string | undefined;
    errors?: ResponseError[] | undefined;
    fileNo!: string | undefined;
    policyNumber?: string | undefined;
    premium?: number | undefined;
    stampDuty?: number | undefined;
    gst?: number | undefined;
    totalPremium?: number | undefined;
    status?: string | undefined;
    documentVersion?: string | undefined;

    constructor(data?: IStewartTitleOrderDetailsQueryResponse) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>this)[property];
                }
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.message = _data["message"];
            if (Array.isArray(_data["errors"])) {
                this.errors = [] as any;
                for (let item of _data["errors"])
                    this.errors!.push(ResponseError.fromJS(item));
            }
            this.fileNo = _data["fileNo"];
            this.policyNumber = _data["policyNumber"];
            this.premium = _data["premium"];
            this.stampDuty = _data["stampDuty"];
            this.gst = _data["gst"];
            this.totalPremium = _data["totalPremium"];
            this.status = _data["status"];
            this.documentVersion = _data["documentVersion"];
        }
    }

    static fromJS(data: any): StewartTitleOrderDetailsQueryResponse {
        data = typeof data === 'object' ? data : {};
        let result = new StewartTitleOrderDetailsQueryResponse();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["message"] = this.message;
        if (Array.isArray(this.errors)) {
            data["errors"] = [];
            for (let item of this.errors)
                data["errors"].push(item.toJSON());
        }
        data["fileNo"] = this.fileNo;
        data["policyNumber"] = this.policyNumber;
        data["premium"] = this.premium;
        data["stampDuty"] = this.stampDuty;
        data["gst"] = this.gst;
        data["totalPremium"] = this.totalPremium;
        data["status"] = this.status;
        data["documentVersion"] = this.documentVersion;
        return data;
    }
}

export interface IAttachment {
    fileName: string | undefined;
    contentType: string | undefined;
    fileContentBase64: string | undefined;
}

export class Attachment {
    fileName: string | undefined;
    contentType: string | undefined;
    fileContentBase64: string | undefined;

    constructor(data?: IAttachment) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.fileName = _data["fileName"];
            this.contentType = _data["contentType"];
            this.fileContentBase64 = _data["fileContentBase64"];
        }
    }

    static fromJS(data: any): Attachment {
        data = typeof data === 'object' ? data : {};
        let result = new Attachment();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["fileName"] = this.fileName;
        data["contentType"] = this.contentType;
        data["fileContentBase64"] = this.fileContentBase64;

        return data;
    }
}

export class StewartTitleActionstepMatter implements IStewartTitleActionstepMatter {
    referrerAndTransaction?: ReferrerAndTransaction | undefined;
    stewartTitleProperty?: StewartTitleProperty | undefined;
    stewartTitleParty?: StewartTitleParty[] | undefined;
    stewartTitleReferences?: StewartTitleReferences | undefined;
    stewartTitleRequestPolicyOptions?: StewartTitleRequestPolicyOptions | undefined;
    attachment?: Attachment | undefined;


    constructor(data?: IStewartTitleActionstepMatter) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.referrerAndTransaction = _data["referrerAndTransaction"] ? ReferrerAndTransaction.fromJS(_data["referrerAndTransaction"]) : <any>undefined;
            this.stewartTitleProperty = _data["stewartTitleProperty"] ? StewartTitleProperty.fromJS(_data["stewartTitleProperty"]) : <any>undefined;
            this.stewartTitleReferences = _data["stewartTitleReferences"] ? StewartTitleReferences.fromJS(_data["stewartTitleReferences"]) : <any>undefined;
            this.stewartTitleRequestPolicyOptions = _data["stewartTitleRequestPolicyOptions"] ? StewartTitleRequestPolicyOptions.fromJS(_data["stewartTitleRequestPolicyOptions"]) : <any>undefined;
            this.attachment = _data["attachment"] ? Attachment.fromJS(_data["attachment"]) : <any>undefined;

            if (Array.isArray(_data["stewartTitleParty"])) {
                this.stewartTitleParty = [] as any;
                for (let item of _data["stewartTitleParty"])
                    this.stewartTitleParty!.push(StewartTitleParty.fromJS(item));
            }
        }
    }

    static fromJS(data: any): StewartTitleActionstepMatter {
        data = typeof data === 'object' ? data : {};
        let result = new StewartTitleActionstepMatter();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};

        data["referrerAndTransaction"] = this.referrerAndTransaction ? this.referrerAndTransaction.toJSON() : <any>undefined;
        data["stewartTitleProperty"] = this.stewartTitleProperty ? this.stewartTitleProperty.toJSON() : <any>undefined;
        data["stewartTitleReferences"] = this.stewartTitleReferences ? this.stewartTitleReferences.toJSON() : <any>undefined;
        data["stewartTitleRequestPolicyOptions"] = this.stewartTitleRequestPolicyOptions ? this.stewartTitleRequestPolicyOptions.toJSON() : <any>undefined;
        data["attachment"] = this.attachment ? this.attachment.toJSON() : <any>undefined;

        if (Array.isArray(this.stewartTitleParty)) {
            data["stewartTitleParty"] = [];
            for (let item of this.stewartTitleParty)
                data["stewartTitleParty"].push(item.toJSON());
        }
        return data;
    }
}

export class ReferrerAndTransaction implements IReferrerAndTransaction {
    conveyancingFirm?: string | undefined;
    conveyancer?: string | undefined;
    policyReference?: string | undefined;
    clientAddress?: string | undefined;
    contactName?: string | undefined;
    contactEmail?: string | undefined;
    clientIdentifier?: string | undefined;

    constructor(data?: IStewartTitleReferences) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.conveyancingFirm = _data["conveyancingFirm"];
            this.conveyancer = _data["conveyancer"];
            this.policyReference = _data["policyReference"];
            this.clientAddress = _data["clientAddress"];
            this.contactName = _data["contactName"];
            this.contactEmail = _data["contactEmail"];
            this.clientIdentifier = _data["clientIdentifier"];
        }
    }

    static fromJS(data: any): ReferrerAndTransaction {
        data = typeof data === 'object' ? data : {};
        let result = new ReferrerAndTransaction();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["conveyancingFirm"] = this.conveyancingFirm;
        data["conveyancer"] = this.conveyancer;
        data["policyReference"] = this.policyReference;
        data["clientAddress"] = this.clientAddress;
        data["contactName"] = this.contactName;
        data["contactEmail"] = this.contactEmail;
        data["clientIdentifier"] = this.clientIdentifier;
        return data;
    }
}

export class StewartTitleReferences implements IStewartTitleReferences {
    titleVolume?: string | undefined;
    titleFolio?: string | undefined;

    constructor(data?: IStewartTitleReferences) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.titleVolume = _data["titleVolume"];
            this.titleFolio = _data["titleFolio"];
        }
    }

    static fromJS(data: any): StewartTitleReferences {
        data = typeof data === 'object' ? data : {};
        let result = new StewartTitleReferences();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["titleVolume"] = this.titleVolume;
        data["titleFolio"] = this.titleFolio;
        return data;
    }
}

export class StewartTitleParty implements IStewartTitleParty {
    firstName: string | undefined;
    middleName: string | undefined;
    lastName: string | undefined;
    emailAddress: string | undefined;
    abn: string | undefined;
    acn: string | undefined;
    companyName: string | undefined;
    city: string | undefined;
    phoneNumber: string | undefined;
    insuredTypeID: number | undefined;
    identityType!: IdentityType;

    constructor(data?: IStewartTitleParty) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.firstName = _data["firstName"];
            this.middleName = _data["middleName"];
            this.lastName = _data["lastName"];
            this.companyName = _data["companyName"];
            this.abn = _data["abn"];
            this.acn = _data["acn"];
            this.emailAddress = _data["emailAddress"];
            this.city = _data["city"];
            this.phoneNumber = _data["phoneNumber"];
            this.insuredTypeID = _data["insuredTypeID"];
            this.identityType = _data["identityType"];
        }
    }

    static fromJS(data: any): StewartTitleParty {
        data = typeof data === 'object' ? data : {};
        let result = new StewartTitleParty();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["firstName"] = this.firstName;
        data["middleName"] = this.middleName;
        data["lastName"] = this.lastName;
        data["companyName"] = this.companyName;
        data["abn"] = this.abn;
        data["acn"] = this.acn;
        data["emailAddress"] = this.emailAddress;
        data["city"] = this.city;
        data["phoneNumber"] = this.phoneNumber;
        data["insuredTypeID"] = this.insuredTypeID;
        data["identityType"] = this.identityType;
        return data;
    }
}

export class StewartTitleProperty implements IStewartTitleProperty {
    purchasePrice?: number | undefined;
    settlementDate!: Date;
    titleReference?: string | undefined;
    planNumber?: string | undefined;
    propertyAddress?: string | undefined;
    city?: string | undefined;
    unitNo?: string | undefined;
    postalCode?: string | undefined;
    stateProvince?: string | undefined;
    stateID?: number | undefined;
    interestBeingTransferred?: string | undefined;
    natureOfProperty?: string | undefined;
    propertyTypeId?: number | undefined;
    otherTypeName?: string | undefined;
    zoningTypeId?: number | undefined;
    otherZoningType?: string | undefined;
    tenureTypeId?: number | undefined;

    constructor(data?: IStewartTitleProperty) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.purchasePrice = _data["purchasePrice"];
            this.settlementDate = _data["settlementDate"] ? new Date(_data["settlementDate"].toString()) : <any>undefined;
            this.titleReference = _data["titleReferences"];
            this.planNumber = _data["planNumber"];
            this.propertyAddress = _data["propertyAddress"];
            this.city = _data["city"];
            this.unitNo = _data["unitNo"];
            this.postalCode = _data["postalCode"];
            this.stateProvince = _data["stateProvince"];
            this.stateID = _data["stateID"];
            this.interestBeingTransferred = _data["interestBeingTransferred"];
            this.natureOfProperty = _data["natureOfProperty"];
            this.propertyTypeId = _data["propertyTypeId"];
            this.zoningTypeId = _data["zoningTypeId"];
            this.tenureTypeId = _data["tenureTypeId"];
            this.otherTypeName = _data["otherTypeName"];
            this.otherZoningType = _data["otherZoningType"];
        }
    }

    static fromJS(data: any): StewartTitleProperty {
        data = typeof data === 'object' ? data : {};
        let result = new StewartTitleProperty();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["purchasePrice"] = this.purchasePrice;
        data["settlementDate"] = this.settlementDate ? this.settlementDate.toISOString() : <any>undefined;
        data["titleReferences"] = this.titleReference;
        data["planNumber"] = this.planNumber;
        data["propertyAddress"] = this.propertyAddress;
        data["city"] = this.city;
        data["unitNo"] = this.unitNo;
        data["city"] = this.city;
        data["postalCode"] = this.postalCode;
        data["stateProvince"] = this.stateProvince;
        data["stateID"] = this.stateID;
        data["interestBeingTransferred"] = this.interestBeingTransferred;
        data["natureOfProperty"] = this.natureOfProperty;
        data["propertyTypeId"] = this.propertyTypeId;
        data["zoningTypeId"] = this.zoningTypeId;
        data["tenureTypeId"] = this.tenureTypeId;
        data["otherTypeName"] = this.otherTypeName;
        data["otherZoningType"] = this.otherZoningType;

        return data;
    }
}

export class StewartTitleRequestPolicyOptions implements IRequestPolicyOptions {
    isFormOrRuralLandUsedAsCommercialEnterprise!: boolean;
    isStrataOrCommunityTitle!: boolean;
    strataCommunityTitle!: string;
    isWarningToDimensionUnderSectionOfTitileSearch!: boolean;
    isThrereAnyKnownRisk!: boolean;
    disclosureDetails!: string;
    isTermsAndConditionAccepted!: boolean;

    isTitleSearchChecked!: boolean;
    isLandTaxChecked!: boolean;
    isCouncilAndWaterChecked!: boolean;
    isStrataBodyCorporateChecked!: boolean;

    constructor(data?: IRequestPolicyOptions) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.isFormOrRuralLandUsedAsCommercialEnterprise = _data["isFormOrRuralLandUsedAsCommercialEnterprise"];
            this.isStrataOrCommunityTitle = _data["isStrataOrCommunityTitle"];
            this.strataCommunityTitle = _data["strataCommunityTitle"];
            this.isWarningToDimensionUnderSectionOfTitileSearch = _data["isWarningToDimensionUnderSectionOfTitileSearch"];
            this.isThrereAnyKnownRisk = _data["isThrereAnyKnownRisk"];
            this.disclosureDetails = _data["disclosureDetails"];
            this.isTermsAndConditionAccepted = _data["isTermsAndConditionAccepted"];

            this.isTitleSearchChecked = _data["isTitleSearchChecked"];
            this.isLandTaxChecked = _data["isLandTaxChecked"];
            this.isCouncilAndWaterChecked = _data["isCouncilAndWaterChecked"];
            this.isStrataBodyCorporateChecked = _data["isStrataBodyCorporateChecked"];
        }
    }

    static fromJS(data: any): StewartTitleRequestPolicyOptions {
        data = typeof data === 'object' ? data : {};
        let result = new StewartTitleRequestPolicyOptions();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["isFormOrRuralLandUsedAsCommercialEnterprise"] = this.isFormOrRuralLandUsedAsCommercialEnterprise;
        data["isStrataOrCommunityTitle"] = this.isStrataOrCommunityTitle;
        data["strataCommunityTitle"] = this.strataCommunityTitle;
        data["isWarningToDimensionUnderSectionOfTitileSearch"] = this.isWarningToDimensionUnderSectionOfTitileSearch;
        data["isThrereAnyKnownRisk"] = this.isThrereAnyKnownRisk;
        data["disclosureDetails"] = this.disclosureDetails;
        data["isTermsAndConditionAccepted"] = this.isTermsAndConditionAccepted;

        data["isTitleSearchChecked"] = this.isTitleSearchChecked;
        data["isLandTaxChecked"] = this.isLandTaxChecked;
        data["isCouncilAndWaterChecked"] = this.isCouncilAndWaterChecked;
        data["isStrataBodyCorporateChecked"] = this.isStrataBodyCorporateChecked;
        return data;
    }
}

export class ResponseError implements IResponseError {
    id?: number | undefined;
    description?: string | undefined;
    messages?: string[] | undefined;
    referenceLogID?: number | undefined;

    constructor(data?: IResponseError) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>this)[property];
                }
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data["id"];
            this.description = _data["description"];
            this.messages = _data["messages"];
            this.referenceLogID = _data["referenceLogID"];
        }
    }

    static fromJS(data: any): ResponseError {
        data = typeof data === 'object' ? data : {};
        let result = new ResponseError();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["description"] = this.description;
        data["messages"] = this.messages;
        data["referenceLogID"] = this.referenceLogID;
        return data;
    }
}


enum RequestPolicySteps {
    NotStarted = -1,
    Questionnaire = 0,
    Confirmation = 1,
    Requested = 2,
    Success = 3,
    Failed = 4,
    StewartTitleNotConnected = 5
}

export interface IState {
    actionstepMatter: StewartTitleActionstepMatter | undefined,
    orderStewartTitlePolicyCommandResponse: OrderStewartTitlePolicyCommandResponse | undefined,
    currentStep: RequestPolicySteps,
    matterInfo: ActionstepMatterInfo | null,
    error: string | undefined
}

export interface IStewartTitleActionstepResponse {
    actionstepData?: StewartTitleActionstepMatter | undefined;
    requestPolicyOptions?: StewartTitleRequestPolicyOptions | undefined;
}

export interface IOrderStewartTitlePolicyCommand {
    actionstepMatter?: StewartTitleActionstepMatter | undefined;
    matterId: number;
    actionstepOrgKey?: string | undefined;
}

export interface IResponseError {
    id?: number | undefined,
    description?: string | undefined,
    messages?: string[] | undefined,
    referenceLogID?: number | undefined
}

export interface IOrderStewartTitlePolicyCommandResponse {
    message?: string | undefined;
    errors?: ResponseError[] | undefined;
    fileNo: number;
    policyNumber?: string | undefined;
    premium?: number;
    stampDuty?: number;
    gst?: number | undefined;
    totalPremium?: number;
    status?: string | undefined;
}

export interface IStewartTitleActionstepMatter {
    referrerAndTransaction?: ReferrerAndTransaction | undefined;
    stewartTitleProperty?: StewartTitleProperty | undefined;
    stewartTitleParty?: StewartTitleParty[] | undefined;
    stewartTitleReferences?: StewartTitleReferences | undefined;
    stewartTitleRequestPolicyOptions?: StewartTitleRequestPolicyOptions;
    // attachment?: any | undefined;
}

export interface IStewartTitleReferences {
    titleVolume?: string | undefined;
    titleFolio?: string | undefined;
}

export interface IStewartTitleParty {
    firstName: string | undefined;
    middleName: string | undefined;
    lastName: string | undefined;
    emailAddress: string | undefined;
    abn: string | undefined;
    acn: string | undefined;
    companyName: string | undefined;
    city: string | undefined;
    phoneNumber: string | undefined;
    insuredTypeID: number | undefined;
    identityType: IdentityType;
}

export interface IRequestPolicyOptions {
    isFormOrRuralLandUsedAsCommercialEnterprise: boolean;
    isStrataOrCommunityTitle: boolean;
    strataCommunityTitle: string;
    isWarningToDimensionUnderSectionOfTitileSearch: boolean;
    isThrereAnyKnownRisk: boolean;
    disclosureDetails: string;
    isTermsAndConditionAccepted: boolean;

    isTitleSearchChecked: boolean;
    isLandTaxChecked: boolean;
    isCouncilAndWaterChecked: boolean;
    isStrataBodyCorporateChecked: boolean;
}

export interface IReferrerAndTransaction {
    conveyancingFirm?: string | undefined;
    conveyancer?: string | undefined;
    policyReference?: string | undefined;
    clientAddress?: string | undefined;
    contactName?: string | undefined;
    contactEmail?: string | undefined;
    clientIdentifier?: string | undefined;
}

export interface IStewartTitleProperty {
    purchasePrice?: number | undefined;
    settlementDate: Date;
    titleReference?: string | undefined;
    propertyAddress?: string | undefined;
    city?: string | undefined;
    unitNo?: string | undefined;
    postalCode?: string | undefined;
    stateProvince?: string | undefined;
    stateID?: number | undefined;
    interestBeingTransferred?: string | undefined;
    natureOfProperty?: string | undefined;
    propertyTypeId?: number | undefined;
    zoningTypeId?: number | undefined;
    tenureTypeID?: number | undefined;
}

export interface IStewartTitleOrderDetailsQueryResponse {
    message?: string | undefined;
    errors?: ResponseError[] | undefined;
    fileNo: string | undefined;
    policyNumber?: string | undefined;
    premium?: number;
    stampDuty?: number;
    gst?: number | undefined;
    totalPremium?: number;
    status?: string | undefined;
    documentVersion?: string | undefined
}