import * as React from 'react';
import { connect } from 'react-redux';
import { Dropdown, IDropdownOption } from 'office-ui-fabric-react/lib/Dropdown';
import { Link, MessageBar, MessageBarType } from 'office-ui-fabric-react';
import { AppState } from 'app.types';
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import MUIDataTable, { MUIDataTableColumnDef, debounceSearchRender } from 'mui-datatables';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import { TooltipHost } from 'office-ui-fabric-react/lib/Tooltip';
import {
    Button, FormLabel, TextField, Switch, Select,
    FormControlLabel, FormGroup, MenuItem, InputLabel
} from '@material-ui/core';
import { SubwayNavNodeState } from 'components/SubwayNav';
import { PrimaryButton } from "office-ui-fabric-react/lib/Button";
import { Checkbox } from 'office-ui-fabric-react/lib/Checkbox';
import { _onFormatDate } from 'utils/dataFormatter'
import 'containers/email-archiver/emailArchiver.css'
import Tools from 'utils/tools';

import * as CONSTANTS from 'containers/email-archiver/redux/constants';

import {
    getConnectedOrgs,
    getListMatters,
    clearEmailArchiverState,
    storeUserInputState,
    validateDataInput,
    triggerEmailArchiverProcess
} from 'containers/email-archiver/redux/actions';

import {
    ActionsFiltterOptions,
    ActionStatus,
    ActionstepAction,
    ConnectedActionstepOrgsResponse,
    DateRange,
    GetListFilteredActionsQuery,
    Participant,
    ProcessEmailArchiverQuery,
    ValidateEmailArchiverInputCommand
} from '../../../utils/wcaApiTypes';


interface IAppProps {
    onChangeStep: (newState: SubwayNavNodeState) => void;
}

type AppProps = IAppProps & ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

type AppStates = {
    availableDestOrgs: IDropdownOption[] | null;
    availableMatters: IDropdownOption[] | null;
    selectedSrcOrg: string | undefined;
    selectedDestOrg: string | undefined;
    selectedMatters: number[];
    errors: string[] | undefined;
    reconnectTimes: number;
    recordCount: number;
    pageNumber: number;
    receivedMattersData: boolean | undefined;
    filters: string[][];
    filterClicked: boolean;
    actionsFiltterOptions: ActionsFiltterOptions | undefined;
    willDeleteEmailsAndAttachments: boolean,
    notDuplicateInfo: boolean,
    reverseArchiving: boolean,
    isValidated: boolean
}

const PROCESSING_TIME_WARNING: string = "The processing time will be longer with this option selected. Are you sure you wish to choose it"

export class EmailArchiverDataCheck extends React.Component<AppProps, AppStates> {
    constructor(props: Readonly<AppProps>) {
        super(props);
        const { previousState } = this.props;

        let emptyArray: IDropdownOption[] = [];

        this.state = {
            availableDestOrgs: emptyArray,
            availableMatters: emptyArray,
            selectedSrcOrg: previousState?.sourceOrg ?? undefined,
            selectedDestOrg: previousState?.destinationOrg ?? undefined,
            errors: undefined,
            selectedMatters: [],
            recordCount: 0,
            pageNumber: 0,
            filters: [[], [], [], ["Closed"], []],
            filterClicked: false,
            reconnectTimes: 0,
            receivedMattersData: previousState ? true: undefined,
            actionsFiltterOptions: new ActionsFiltterOptions(),
            willDeleteEmailsAndAttachments: false,
            notDuplicateInfo: false,
            reverseArchiving: false,
            isValidated: previousState ? true : false
        }
    }

    componentDidMount(): void {
        const { actionsFiltterOptions, selectedSrcOrg } = this.state;
        this.state.actionsFiltterOptions!.selectedActionStatus = ActionStatus.Closed;

        if (this.props.previousState) {
            var getListFilteredActionsQuery = new GetListFilteredActionsQuery();
            getListFilteredActionsQuery.actionstepOrg = this.state.selectedSrcOrg;
            getListFilteredActionsQuery!.actionsFiltterOptions = actionsFiltterOptions;
            getListFilteredActionsQuery.isForDisplayPurpose = true;

            var destOrgsList = this.props.orgsList!.filter(function (val, i) {
                return val.actionstepOrgTitle !== selectedSrcOrg;
            })
            var availableDestOrgs = destOrgsList.map((org: ConnectedActionstepOrgsResponse) => {
                return { key: org.actionstepOrgKey!, text: org.actionstepOrgTitle! };
            });
            
            this.props.getListMatters(getListFilteredActionsQuery);

            this.setState({
                receivedMattersData: false,
                availableDestOrgs :[...availableDestOrgs]
            });
        } else {
            this.props.getConnectedOrgs();
        }       
    }

    static getDerivedStateFromProps(nextProps: AppProps, prevState: AppStates): AppStates {
        let nextState = {} as AppStates;

        if (nextProps.gotResponse) {
            switch (nextProps.requestType) {
                case CONSTANTS.GET_CONNECTED_ORGS_REQUESTED: {
                    if (nextProps.success) {
                        nextProps.clearEmailArchiverState();
                        if (prevState.selectedSrcOrg && nextProps.orgsList) {
                            var destOrgsList = nextProps.orgsList.filter(function (val, i) {
                                return val.actionstepOrgTitle !== prevState.selectedSrcOrg;
                            })
                            var availableDestOrgs = destOrgsList.map((org: ConnectedActionstepOrgsResponse) => {
                                return { key: org.actionstepOrgKey!, text: org.actionstepOrgTitle! };
                            });
                            nextState.availableDestOrgs = [...availableDestOrgs];
                        }
                    }
                    break;
                }
                case CONSTANTS.GET_LIST_MATTERS_REQUESTED: {
                    if (nextProps.success) {
                        nextState.receivedMattersData = true;
                        nextProps.clearEmailArchiverState();
                        nextState.recordCount = (nextProps.listMatters?.meta?.paging?.actions?.recordCount) ? (nextProps.listMatters?.meta?.paging?.actions?.recordCount) : 0;
                    }
                    break;
                }
                case CONSTANTS.VALIDATE_DATA_INPUT_REQUESTED: {
                    if (nextProps.success) {
                        if (nextProps.validateInputResponse?.isSuccess) {
                            nextState.isValidated = true;
                            var query = new GetListFilteredActionsQuery();
                            query.actionstepOrg = prevState.selectedSrcOrg;
                            query.isForDisplayPurpose = true;
                            query.actionsFiltterOptions = prevState.actionsFiltterOptions;
                            nextState.errors = undefined;
                            nextProps.getListMatters(query);
                        } else {
                            nextState.isValidated = false;
                            nextState.errors = [...nextProps.validateInputResponse!.errors!];
                            nextProps.clearEmailArchiverState();
                        }
                    }
                    break;
                }
                default:
                    return nextState;
            }
        }
        return nextState;
    }

    public connectToActionstep(): void {
        Tools.PopupConnectToActionstep(() => this.reloadOrgList());
    }

    public reloadOrgList(): void {
        var times = this.state.reconnectTimes + 1;
        this.setState({
            reconnectTimes: times
        })
        this.props.getConnectedOrgs();
    }
   
    private selectSrcOrg(org: string | undefined): void {
        const { orgsList } = this.props;

        if (org && orgsList) {
            var destOrgsList = orgsList.filter(function (val, i) {
                return val.actionstepOrgTitle !== org;
            })
            var availableDestOrgs = destOrgsList.map((org: ConnectedActionstepOrgsResponse) => {
                return { key: org.actionstepOrgKey!, text: org.actionstepOrgTitle! };
            });
            if (availableDestOrgs.length === 0) {
                this.setState({
                    selectedDestOrg: undefined, // reset selected Dest Org               
                })
            } else if (availableDestOrgs.length === 1) {
                this.setState({
                    selectedDestOrg: availableDestOrgs[0].text,
                    errors: undefined
                })
            } else {
                this.setState({
                    selectedDestOrg: undefined,
                    errors: undefined
                })
            }
            this.setState({
                selectedSrcOrg: org,
                selectedMatters: [],
                availableDestOrgs: availableDestOrgs,
                isValidated: false
            })
        }
    }

    private selectDestOrg(org: string | undefined): void {
        if (org) {
            this.setState({
                selectedDestOrg: org,
                errors: undefined,
                isValidated: false,
            });
        }
    }

    private selectMatters(rowsSelected: { index: number, dataIndex: number }[] | undefined): void {

        var selectedMatters = [...this.state.selectedMatters];

        if (this.state.actionsFiltterOptions?.selectedAllFilteredActions !== true) {
            if (rowsSelected && rowsSelected.length > 0 && this.props.listMatters) {
                var selectedRows = rowsSelected.map(row => row.dataIndex);
                var selectMatters = selectedRows.map(index => this.props.listMatters!.actions![index].id);

                if (selectedMatters.includes(selectMatters[0])) { // the matter id already exits, remove it
                    selectedMatters = selectedMatters.filter((id, index) => id !== selectMatters[0]);
                    this.setState({ 
                        selectedMatters: [...selectedMatters]
                    });
                } else {
                    if (selectedMatters!.length) {
                        this.setState(prevState => ({
                            selectedMatters: [...prevState.selectedMatters!, ...selectMatters]
                        }));
                    } else {
                        this.setState({
                            selectedMatters: [...selectMatters]
                        });
                    }
                }
            }
        }
    }

    private getAssigneeName(assigneeId: number): string {

        const { listMatters } = this.props;
        var assigneeName = "";
        if (listMatters && listMatters.linked && listMatters.linked.participants) {
            listMatters.linked.participants.map((assignee: Participant) => {
                if (assignee.id === assigneeId)
                    assigneeName = assignee.displayName!;
            })
        }
        return assigneeName;
    }

    private triggerEmailArchiverProcess(): void {
        const { selectedMatters, actionsFiltterOptions, recordCount} = this.state;
        var selectedMatterNo = 0;

        if (actionsFiltterOptions?.selectedAllFilteredActions === true) {
            selectedMatterNo = recordCount;
        } else {
            selectedMatterNo = selectedMatters.length;
        }

        if (selectedMatterNo > 0) {
            var message = "You have chosen to archive " + selectedMatterNo + " matters \n"
                + "in Source Org: " + this.state.selectedSrcOrg + " to Destination Org: " + this.state.selectedDestOrg + ".\n";

            message += "Are you sure you want to process? ";
               
            if (window.confirm(message)) {
                var processEmailArchiverQuery = new ProcessEmailArchiverQuery();
                processEmailArchiverQuery.actionsFiltterOptions = this.state.actionsFiltterOptions;
                if (this.state.selectedMatters!.length) {
                    processEmailArchiverQuery.actionsFiltterOptions!.actionsIds = [...this.state.selectedMatters!];
                }
                processEmailArchiverQuery.sourceOrg = this.state.selectedSrcOrg;
                processEmailArchiverQuery.destinationOrg = this.state.selectedDestOrg;
                processEmailArchiverQuery.willDeleteEmailsAndAttachments = this.state.willDeleteEmailsAndAttachments;
                processEmailArchiverQuery.notDuplicateInfo = this.state.notDuplicateInfo;
                processEmailArchiverQuery.isReverseProcess = this.state.reverseArchiving;
                
                this.props.storeUserInputState(processEmailArchiverQuery);
                this.props.triggerEmailArchiverProcess(processEmailArchiverQuery);
                this.props.onChangeStep(SubwayNavNodeState.Completed);
            }
        } else {
            var errors = ["Please select at least one matter"];
            this.setState({
                errors: errors
            });
        }
    }

    private changePage(pageNumber: number) {
        const { actionsFiltterOptions } = this.state;
        actionsFiltterOptions!.pageNumber = pageNumber;

        var getListFilteredActionsQuery = new GetListFilteredActionsQuery();
        getListFilteredActionsQuery.actionstepOrg = this.state.selectedSrcOrg;
        getListFilteredActionsQuery!.actionsFiltterOptions = actionsFiltterOptions;
        getListFilteredActionsQuery.isForDisplayPurpose = true;

        this.props.getListMatters(getListFilteredActionsQuery);

        this.setState({
            receivedMattersData: false,
            pageNumber: pageNumber - 1
        });
    }

    private searchChange(searchText: string | null) {
        const { actionsFiltterOptions } = this.state;

        actionsFiltterOptions!.pageNumber = 1; // reset
        actionsFiltterOptions!.searchText = searchText!;
        var getListFilteredActionsQuery = new GetListFilteredActionsQuery();
        getListFilteredActionsQuery.actionstepOrg = this.state.selectedSrcOrg;
        getListFilteredActionsQuery!.actionsFiltterOptions = actionsFiltterOptions;
        getListFilteredActionsQuery.isForDisplayPurpose = true;

        this.props.getListMatters(getListFilteredActionsQuery);

        this.setState({
            pageNumber: 0,
            receivedMattersData: false
        });
    }

    private handleFilterSubmit() {
        const { filters, actionsFiltterOptions } = this.state;
        console.log(filters);
        if (actionsFiltterOptions!.createdDate === undefined) {
            actionsFiltterOptions!.createdDate = new DateRange();
        }

        if (filters![2] && filters![2][0])
            actionsFiltterOptions!.createdDate!.fromDate = filters![2][0];

        if (filters![2] && filters![2][1])
            actionsFiltterOptions!.createdDate!.toDate = filters![2][1];

        if (filters![3] && filters![3][0])
            actionsFiltterOptions!.selectedActionStatus = ActionStatus[(filters![3][0] as string) as keyof typeof ActionStatus];

        actionsFiltterOptions!.pageNumber = 1; // reset    

        var getListFilteredActionsQuery = new GetListFilteredActionsQuery();
        getListFilteredActionsQuery.actionstepOrg = this.state.selectedSrcOrg;
        getListFilteredActionsQuery!.actionsFiltterOptions = actionsFiltterOptions;
        getListFilteredActionsQuery.isForDisplayPurpose = true;
        this.props.getListMatters(getListFilteredActionsQuery);

        this.setState({
            pageNumber: 0,
            filterClicked: true,
            receivedMattersData: false
        });
    }

    private handleFilterChange(filterList: string[][]) {
        this.setState({
            filterClicked: false,
            filters: filterList
        });
    }

    private clearFilterSubmit(filterPos: number) {
        console.log("clear: " + filterPos);
    }

    private selectAllFilteredMatters(selectAllFilteredMatters: boolean): void {
        const { actionsFiltterOptions } = this.state;
        actionsFiltterOptions!.selectedAllFilteredActions = selectAllFilteredMatters;
        this.setState({
            actionsFiltterOptions: actionsFiltterOptions
        })
    }

    private updateDeleteEmailsAndAttachmentOption(willDelete: boolean): void {
        this.setState({
            willDeleteEmailsAndAttachments: willDelete
        })
    }

    /**
     * The handler for checking the "Reverse archiving process" checkbox selection
     * @param value The boolean value which determines what should be saved in the state
     */
    private reverseEmailInArchiveCheckHandler(value: boolean): void {

        if (value) {
            if (window.confirm(PROCESSING_TIME_WARNING)) {
                this.setState({
                    notDuplicateInfo: value
                })

                this.setState({
                    reverseArchiving: value
                })
            }
        } else {
            this.setState({
                notDuplicateInfo: value
            })

            this.setState({
                reverseArchiving: value
            })
        }
    }

    private avoidDuplicateInfoOption(value: boolean): void {
        if (value === true) {
            if (window.confirm(PROCESSING_TIME_WARNING)) {
                this.setState({
                    notDuplicateInfo: true
                })
            }
        } else {
            if (this.state.reverseArchiving) {
                this.setState({
                    reverseArchiving: false
                })
            }

            this.setState({
                notDuplicateInfo: false
            })
        }        
    }

    private displaySelectedRows(): number[] {
        const { actionsFiltterOptions, selectedMatters } = this.state;
        const { listMatters } = this.props;
        var result: number[];
        result = [];
        if (actionsFiltterOptions?.selectedAllFilteredActions !== true) {
            if (listMatters && listMatters.actions && selectedMatters) {
                listMatters!.actions!.map((a, i) => {
                    if (selectedMatters.includes(a.id)) {
                        result.push(i);
                    }
                })
            }
        } else {
            if (listMatters && listMatters.actions) {
                listMatters!.actions!.map((a, i) => {
                    result.push(i);
                })
            }
        }
        return result;
    }

    private validateInput() {
        const { selectedSrcOrg, selectedDestOrg } = this.state;
        var command = new ValidateEmailArchiverInputCommand();
        command.sourceOrg = selectedSrcOrg;
        command.destinationOrg = selectedDestOrg;
        this.props.validateDataInput(command);
    }

    render() {
        const { availableDestOrgs, selectedSrcOrg, selectedDestOrg, isValidated, errors, reconnectTimes,
            recordCount, pageNumber, receivedMattersData, willDeleteEmailsAndAttachments, actionsFiltterOptions, notDuplicateInfo, reverseArchiving} = this.state;
        const { orgsList, listMatters } = this.props;
        const columns = [
            {
                name: "Id",
                options: {
                    filter: false
                }
            },
            {
                name: "Matter name",
                options: {
                    filter: false
                }
            },
            {
                name: "Created On",
                options: {
                    filter: true,
                    filterList: this.state.filters![2],
                    filterType: "custom",
                    customFilterListOptions: {
                        render: (v: any[]) => {
                            if (v[0] && v[1]) {
                                return `From ${v[0]} To ${v[1]}`;
                            } else if (v[0]) {
                                return `From ${v[0]}`;
                            } else if (v[1]) {
                                return `To ${v[1]}`;
                            }
                            return false;
                        },
                        update: (
                            filterList: string[][],
                            filterPos: number,
                            index: number
                        ) => {
                            if (filterPos === 0) {
                                filterList[index].splice(filterPos, 1);
                            } else if (filterPos === 1) {
                                filterList[index].splice(filterPos, 1);
                            } else if (filterPos === -1) {
                                filterList[index] = [];
                            }
                            this.clearFilterSubmit(filterPos)
                            return filterList;
                        }
                    },
                    filterOptions: {
                        dates: [],
                        logic(date: number, filters: number[]) {
                            if (filters[0] && filters[1]) {
                                return date < filters[0] || date > filters[1];
                            } else if (filters[0]) {
                                return date < filters[0];
                            } else if (filters[1]) {
                                return date > filters[1];
                            }
                            return false;
                        },
                        display: (
                            filterList: { [x: string]: any },
                            onChange: (arg0: any, arg1: any, arg2: any) => void,
                            index: React.ReactText,
                            column: any
                        ) => (
                                <div>
                                    <FormLabel>Created On</FormLabel>
                                    <FormGroup row>
                                        <TextField
                                            type="date"
                                            label="from"
                                            InputLabelProps={{ shrink: true }}
                                            value={filterList[index][0] || ""}
                                            onChange={(event: { target: { value: any } }) => {
                                                filterList[index][0] = event.target.value;
                                                this.handleFilterChange(filterList as string[][]);
                                            }}
                                            style={{ width: "60%", marginRight: "5%" }}
                                        />

                                        <TextField
                                            type="date"
                                            label="to"
                                            InputLabelProps={{ shrink: true }}
                                            value={filterList[index][1] || ""}
                                            onChange={(event: { target: { value: any } }) => {
                                                filterList[index][1] = event.target.value;
                                                this.handleFilterChange(filterList as string[][]);
                                            }}
                                            style={{ width: "60%" }}
                                        />
                                    </FormGroup>
                                </div>
                            )
                    },
                    print: false
                }
            },
            {
                name: "Status",
                options: {
                    filter: true,
                    filterType: "custom",
                    filterList: this.state.filters![3],
                    customFilterListOptions: {
                        render: (v: any[]) => {
                            if (v[0]) {
                                return `Status: ${v[0]}`;
                            }
                            return false;
                        },
                        update: (
                            filterList: string[][],
                            filterPos: number,
                            index: number
                        ) => {
                            if (filterPos === 0) {
                                filterList[index].splice(filterPos, 1);
                            } else if (filterPos === 1) {
                                filterList[index].splice(filterPos, 1);
                            } else if (filterPos === -1) {
                                filterList[index] = [];
                            }
                            this.clearFilterSubmit(filterPos)
                            return filterList;
                        }
                    },
                    filterOptions: {
                        states: "",
                        display: (
                            filterList: { [x: string]: any },
                            onChange: (arg0: any, arg1: any, arg2: any) => void,
                            index: React.ReactText,
                            column: any
                        ) => (<>

                            <InputLabel >Status</InputLabel>
                            <Select style={{ width: "80%" }}
                                labelId="select-matter-status-label" id="select-matter-status"
                                defaultValue={actionsFiltterOptions?.selectedActionStatus.toString()}
                                onChange={(event: { target: { value: any } }) => {
                                    filterList[index][0] = event.target.value;
                                    this.handleFilterChange(filterList as string[][]);
                                }}
                            >
                                <MenuItem value={"Closed"}>Closed</MenuItem>
                                <MenuItem value={"Active"}>Active</MenuItem>
                                <MenuItem value={"Inactive"}>Inactive</MenuItem>
                            </Select>
                        </>
                            )
                    }
                }

            },
            {
                name: "Assignee",
                options: {
                    filter: false
                }
            }
        ] as MUIDataTableColumnDef[];

        const getMuiTheme = createMuiTheme({
            overrides: {
                MuiTypography: {
                    body1: {
                        fontSize: "12px"
                    },
                    body2: {
                        fontSize: "12px"
                    },
                    h6: {
                        fontSize: "12px"
                    }
                },
                MuiTableRow: {
                    root: {
                        fontWeight: "lighter",
                        fontSize: "12px"
                    }
                },
                MuiTableCell: {
                    root: {
                        fontWeight: "lighter",
                        fontSize: "12px",
                        padding: "2px"
                    }
                },
                MuiMenuItem: {
                    root: {
                        fontSize: "12px"
                    }
                },
                MuiFormLabel: {
                    root: {
                        fontSize: "12px"
                    }
                },
                MuiFormControlLabel: {
                    root: {
                        marginLeft: "150px"
                    }
                },
                MuiInputBase: {
                    root: {
                        fontSize: "12px"
                    }
                },
                MuiGrid: {
                    "grid-xs-6": {
                        maxWidth: "100%"
                    }
                },
                MuiFormControl: {
                    root: {
                        width: "100% !important"
                    }
                },
                MuiButton: {
                    root: {
                        fontSize: "10px"
                    }
                },
                // @ts-ignore 
                MUIDataTableFilterList: {
                    chip: {
                        display: (this.state.filterClicked || (this.state.filters![3][0] === "Closed" && !this.state.filters[2].length)) ? true : 'none'
                    }
                },
                MUIDataTableToolbar: {
                    actions: {
                        display: "flex",
                        flexDirection: "row",
                        flex: "initial"
                    }
                }
            }
        });

        const options = {
            sort: false,
            serverSide: true,
            responsive: "scrollMaxHeight" as any,
            rowsPerPage: 50 as number,
            rowsPerPageOptions: [50],
            count: recordCount,
            page: pageNumber,
            viewColumns: false as any,
            print: false,
            download: false,
            disableToolbarSelect: true as any,
            selectableRows: "multiple" as any,
            selectableRowsHeader: false,
            isRowSelectable: (dataIndex: any) => {
                return actionsFiltterOptions?.selectedAllFilteredActions === true ? false : true;
            },
            rowsSelected: this.displaySelectedRows(),   
            filter: true,
            filterType: "multiselect" as any,
            confirmFilters: true,
            filterList: this.state.filters,
            /* search: actionsFiltterOptions!.searchText ? false : true,*/
            /*     searchOpen: true,*/
            searchText: actionsFiltterOptions!.searchText,
            searchPlaceholder: "search by matter id, matter name, assignee",
            customSearchRender: debounceSearchRender(500), // delay 500ms
            onRowSelectionChange: (allRowsSelected: { index: number, dataIndex: number }[],) => {
                this.selectMatters(allRowsSelected);
            },
            onSearchChange: (searchText: string | null) => {
                this.searchChange(searchText);
            },
            onChangePage: (currentPage: number) => {
                this.changePage(currentPage + 1);
            },
            customFilterDialogFooter: (filterList: string[][]) => {
                return (
                    <div style={{ marginTop: '40px' }}>
                        <Button variant="contained" onClick={() => this.handleFilterSubmit()}>Apply Filters</Button>
                    </div>
                );
            },           
            textLabels: {
                filter: {
                    reset: ""
                }
            },
            onFilterChipClose: (index: number, removedFilter: string, filterList: string[][]) => {
                if (actionsFiltterOptions!.createdDate && index === 2) {
                    actionsFiltterOptions!.createdDate!.fromDate = undefined;
                    actionsFiltterOptions!.createdDate!.toDate = undefined;
                }
                if (index === 3) {
                    actionsFiltterOptions!.selectedActionStatus = ActionStatus.Closed;
                    filterList[3][0] = "Closed";
                }

                actionsFiltterOptions!.pageNumber = 1; // reset

                var getListFilteredActionsQuery = new GetListFilteredActionsQuery();
                getListFilteredActionsQuery.actionstepOrg = this.state.selectedSrcOrg;
                getListFilteredActionsQuery!.actionsFiltterOptions = actionsFiltterOptions;
                getListFilteredActionsQuery.isForDisplayPurpose = true;

                this.props.getListMatters(getListFilteredActionsQuery);

                this.setState({
                    filters: filterList
                });
            },
            customToolbar: () => {
                return (
                    <>
                        {selectedSrcOrg && receivedMattersData !== true ?
                            <Spinner size={SpinnerSize.large} style={{ order: -2 }} /> : <></>
                        }                 
                     
                        <FormControlLabel style={{ order: -1 }}
                            control={
                                <Switch checked={actionsFiltterOptions ? actionsFiltterOptions.selectedAllFilteredActions : false} 
                                    onChange={(e, v) => this.selectAllFilteredMatters(v!)} name="allFilteredMatters" color="primary" />
                            }
                            label="select ALL filtered matters"                          
                        />
                    </>
                );
            }
        };

        return (
            <div className="ms-Grid-row ms-sm12">
                <table className="email-archiver-table">
                    <tbody>
                        <tr className="ms-Grid-row">
                            <td className="ms-Grid-col ms-sm3">Source Organization</td>
                            <td className="ms-Grid-col ms-sm5">
                                <Dropdown
                                    options={orgsList ? orgsList.map((org: ConnectedActionstepOrgsResponse) => {
                                        return { key: org.actionstepOrgKey!, text: org.actionstepOrgTitle! };
                                    }) : []}
                                    defaultSelectedKey={selectedSrcOrg ? selectedSrcOrg : ''}
                                    disabled={false}
                                    placeholder="Please select..."
                                    onChange={(event, item) => this.selectSrcOrg(item!.text)}
                                />
                            </td>
                            <td className="ms-Grid-col ms-sm4">
                                <TooltipHost
                                    content=" Please relogin with the Org to connect it with Konekta "
                                >
                                    <Link onClick={() => this.connectToActionstep()}> Not found an Organization? </Link>
                                </TooltipHost>

                            </td>
                        </tr>

                        <tr className="ms-Grid-row">
                            <td className="ms-Grid-col ms-sm3">Destination Organization</td>
                            <td className="ms-Grid-col ms-sm5">
                                <Dropdown
                                    options={availableDestOrgs ? availableDestOrgs : []}
                                    disabled={selectedSrcOrg ? false : true}
                                    defaultSelectedKey={selectedDestOrg ? selectedDestOrg : ''}
                                    placeholder="Please select..."
                                    onChange={(event, item) => this.selectDestOrg(item!.text)}
                                />
                            </td>
                        </tr>

                        {selectedSrcOrg && orgsList?.length === 1 &&
                            <>
                                <p className="text-danger">
                                    You only have one Org. Please relogin to <Link onClick={() => this.connectToActionstep()}> connect another Org</Link>.
                                 </p>
                                {reconnectTimes > 0 ?
                                    <p className="text-danger">
                                        Please make sure you choose an Org which is different with the current Org.
                                     </p>
                                    : <></>}
                            </>
                        }

                        {!isValidated ?
                            <tr className="ms-Grid-row">
                                <td className="ms-Grid-col ms-sm4"></td>
                                <td className="ms-Grid-col ms-sm2">
                                    <PrimaryButton
                                        className="button ms-Grid-col ms-sm8"
                                        text="Validate"
                                        disabled={(selectedSrcOrg && selectedDestOrg) ? false : true}
                                        onClick={() => this.validateInput()}
                                    />
                                </td>
                            </tr> : <></>
                        }

                        {(errors && errors.length > 0) ?
                            errors.map((item, index) => (
                                <MessageBar messageBarType={MessageBarType.error} isMultiline={false} key={index}>
                                    {item}
                                </MessageBar>
                            )) : <></>}
                        <tr className="blank_row"></tr>
                    </tbody>
                </table>

                {isValidated ?
                    <>
                        <MuiThemeProvider theme={getMuiTheme}>
                            <MUIDataTable
                                title={"Matter List"}
                                columns={columns}
                                options={options}
                                data={listMatters ? listMatters!.actions!.map((matter: ActionstepAction) => {
                                    return [
                                        matter.id,
                                        matter.name!,
                                        matter.createdTimestamp,
                                        matter.status,
                                        this.getAssigneeName(matter.links!.assignedTo)
                                    ]
                                })
                                    : []}
                            />
                        </MuiThemeProvider>

                        <tr className="blank_row"></tr>
                        <table className="email-archiver-table">
                            <tbody>
                                <tr className="ms-Grid-row">
                                    <td className="ms-Grid-col ms-sm10 ">
                                        <Checkbox label="Delete the emails and attachments from Source Organization"
                                            checked={willDeleteEmailsAndAttachments}
                                            onChange={(e, v) => this.updateDeleteEmailsAndAttachmentOption(v!)} />
                                    </td>
                                </tr>

                                <tr className="ms-Grid-row">
                                    <td className="ms-Grid-col ms-sm10 ">
                                        <TooltipHost content="If the matter exists in the destination organisation, the tool will just add new emails to it.">
                                            <Checkbox
                                                label="Only create new matters and emails if they do not exist in the destination organization"
                                                checked={notDuplicateInfo}
                                                onChange={(e, v) => this.avoidDuplicateInfoOption(v!)}
                                            />
                                        </TooltipHost>                                       
                                    </td>
                                </tr>

                                <tr className="ms-Grid-row">
                                    <td className="ms-Grid-col ms-sm10 ">
                                        <TooltipHost content="If the selected matters were once part of the destination organisation, you can select this option to have the emails in the selected matters return to their original matters in the destination organisation.">
                                            <Checkbox
                                                label="Reverse archiving process"
                                                checked={reverseArchiving}
                                                onChange={(e, v) => this.reverseEmailInArchiveCheckHandler(v!)}
                                            />
                                        </TooltipHost>
                                    </td>
                                </tr>
                              
                                <tr className="ms-Grid-row">
                                    <td className="ms-Grid-col ms-sm10 "></td>
                                    <td className="ms-Grid-col ms-sm2 ">
                                        <PrimaryButton
                                            className="button ms-Grid-col ms-sm8"
                                            text="Process"
                                            disabled={isValidated ? false : true}
                                            onClick={() => this.triggerEmailArchiverProcess()}  
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </>
                    : <></>}
            </div>
        );
    }
}

const mapStateToProps = (state: AppState) => {
    return {
        orgsList: state.emailArchiver.connectedOrgsResponseList,
        actionstepContext: state.common.actionstepContext,
        requestType: state.emailArchiver.requestType,
        error: state.emailArchiver.error,
        listMatters: state.emailArchiver.listMatters,
        gotResponse: state.emailArchiver.gotResponse,
        currentOrg: state.common.actionstepContext?.orgKey,
        success: state.emailArchiver.success,
        previousState: state.emailArchiver.processEmailArchiverQuery,
        validateInputResponse: state.emailArchiver.validateEmailArchiverInputResponse
    }
}

const mapDispatchToProps = {
    getConnectedOrgs,
    getListMatters,
    clearEmailArchiverState,
    storeUserInputState,
    validateDataInput,
    triggerEmailArchiverProcess
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailArchiverDataCheck);