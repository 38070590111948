import * as React from 'react'
import { connect } from 'react-redux';

import { AppState } from 'app.types';
//import { IContactUsCommand } from 'utils/wcaApiTypes';
import Tools from 'utils/tools';

import { DefaultStackTokens } from '../../konekta-theme';
import { Text, Stack, TextField, IStackTokens, IStackStyles, PrimaryButton, SpinnerSize, Spinner } from 'office-ui-fabric-react';
/*import { rawPost } from 'utils/request';*/
import ErrorMessageWidget from 'components/common/errorMessageWidget';
import { Toggle } from '@fluentui/react/lib/Toggle';
import './index.css';
import { IContactUsVM, ISignupService } from 'utils/commonInterface';
/*import { CUSTOMER_ONBOARD_SERVICES, CUSTOMER_ONBOARD_INTEGRATION } from '../../utils/constants';*/
import { IContactUsCommand } from '../../utils/wcaApiTypes';
import { rawPost, getRequest } from '../../utils/request';


type IProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

enum FormState {
    Editable = 0,
    Submitting,
    SubmittedSuccess,
    SubmitError,
}

type IState = {
    formState: FormState,
    formDataIsValid: boolean,
    validationErrors?: string[] | undefined,
    contactUsCommand: Partial<IContactUsVM>;
    /* konektaServices: IProductService[]*/
}

export class ContactUsPageV2 extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);



        this.state = {
            formState: FormState.Editable,
            formDataIsValid: false,
            /*konektaServices: CUSTOMER_ONBOARD_SERVICES,*/
            contactUsCommand: {
                productServices: [],
                integrations: [],
                pricelist: [],
                payNowChoosed: false
                //interestedInGlobalXIntegration: false,
                //interestedInPEXAIntegration: false,
                //interestedInInfoTrackIntegration: false,
                //interestedInFirstTitleIntegration: false,
                //interestedInQuickFeeIntegration: false,
                //interestedInEmailToSMSIntegration: false,
                //interestedInTheSearchPeopleIntegration: false,
                //interestedInLiveSignIntegration: false,
                //interestedInPerfectPortalIntegration: false,
                //interestedInPexaKeyIntegration: false,
                //interestedInSecureExchangeIntegration: false,
                //interestedInACTConveyancingAddOn: false,
                //interestedInQLDConveyancingAddOn: false,
                //interestedInNSWConveyancingAddOn: false,
                //interestedInVICConveyancingAddOn: false,
                //interestedInSafeCustodyAddOn: false,
                //interestedInQLDUCPRFormsAddOn: false,
                //interestedInRBIIntegration: false
            },
        }


    }
    public componentDidMount(): void {


        const query = new URLSearchParams(window.location.search);
        if (query.get("payment")) {
            var paymentStatus = query.get("payment");
            // var paymentRefNo = query.get("paymentref");
            this.setState({ formState: paymentStatus == "true" ? FormState.SubmittedSuccess : FormState.SubmitError });
        }



        getRequest('/api/SignupService').then((response: ISignupService[]) => {

            let integrations = response.filter(x => x.serviceType == "Integration").map(y => {
                y.isSelected = false;
                return y;
            }).sort((x: ISignupService, b: ISignupService) => { return x.seqNo - b.seqNo });

            let productServices: Partial<ISignupService[]> = response.filter(x => x.serviceType == "Service").map(x => {

                x.products = response.filter(y => y.serviceType == "Product" && y.parentId == x.id).map(z => { z.isSelected = false; return z; }).sort((x: ISignupService, b: ISignupService) => { return x.seqNo - b.seqNo });
                x.isSelected = false;
                return x;
            }).sort((x: ISignupService, b: ISignupService) => { return x.seqNo - b.seqNo });
            console.log(integrations, productServices);
            this.setState({
                contactUsCommand: {
                    ...this.state.contactUsCommand,
                    integrations: [...integrations],
                    productServices: [...productServices]
                }
            })
        });


    }


    private validate = (): string[] => {
        var errors: string[] = [];
        if (Tools.isNullOrEmptyOrUndefined(this.state.contactUsCommand.orgKey)) {
            errors.push('ActionStep Organisation must be supplied');
        }
        if (Tools.isNullOrEmptyOrUndefined(this.state.contactUsCommand.firstname)) {
            errors.push('First name must be supplied');
        }

        if (Tools.isNullOrEmptyOrUndefined(this.state.contactUsCommand.lastname)) {
            errors.push('Last name must be supplied');
        }

        if (Tools.isNullOrEmptyOrUndefined(this.state.contactUsCommand.email)) {
            errors.push('Email must be supplied');
        }

        if (Tools.isNullOrEmptyOrUndefined(this.state.contactUsCommand.company)) {
            errors.push('Company must be supplied');
        }

        if (Tools.isNullOrEmptyOrUndefined(this.state.contactUsCommand.phone)) {
            errors.push('Phone must be supplied');
        }
        if ((this.state.contactUsCommand.productServices || []).filter((x: Partial<ISignupService> | undefined) => x && x.isSelected).length == 0 && (this.state.contactUsCommand.integrations || []).filter((x: Partial<ISignupService> | undefined) => x && x.isSelected).length == 0)
            errors.push('Please select at least one product or integration.');

        return errors;
    }

    private submit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
        let contactUsCommandForm: Partial<IContactUsCommand> = {};
        Object.keys(this.state.contactUsCommand).map((x: string) => {
            if (x == "integrations") {

                (this.state.contactUsCommand.integrations || []).map((intKey: any, i: number) => {
                    (contactUsCommandForm as any)[intKey.propKey] = intKey.isSelected;
                });
            } else if (x == "productServices") {
                (this.state.contactUsCommand.productServices || []).map((svcItem: ISignupService | undefined) => {
                    if (svcItem) {
                        (contactUsCommandForm as any)[svcItem.propKey] = svcItem.isSelected;

                        (svcItem.products || []).map((prodItem: any) => {
                            (contactUsCommandForm as any)[prodItem.propKey] = prodItem.isSelected;
                        })
                    }
                });

            } else {

                (contactUsCommandForm as any)[x] = (this.state.contactUsCommand as any)[x];
            }
        });
        e.preventDefault();
        const validationErrors = this.validate();
        if (validationErrors.length > 0) {
            this.setState({
                formState: FormState.Editable,
                validationErrors,
            });

            return;
        }

        this.setState({ formState: FormState.Submitting });
        //commented
        try {
            let resp: any = await rawPost('/api/customer/contact-us', contactUsCommandForm, {});
            if (resp.headers.location) {
                window.location = resp.headers.location;
            } else {
                this.setState({ formState: FormState.SubmittedSuccess });
            }
        } catch (e) {
            if (e.errorList && Array.isArray(e.errorList)) {
                this.setState({
                    validationErrors: e.errorList,
                    formState: FormState.Editable,
                });
            } else {
                console.log('Unknown submitting form. Details:', e);
                this.setState({
                    validationErrors: undefined,
                    formState: FormState.SubmitError
                });
            }
        }
    }

    getProductServiceCard(item: Partial<ISignupService> | undefined, index: number) {
        return (<>
            {item && <>
                <div className="product-image">
                    <img src={item.iconURI}></img>
                </div>
                <div>
                    <Text>
                        <h4 className="text-blue">{item.title}</h4>
                    </Text>
                    <div className="switch-container">
                        <Toggle label="" checked={item.isSelected} onText="" offText=""
                            onChange={(e, v) => {
                                this.onProductSwitchToggle(item, index, undefined, 0, v || false);

                            }} />
                    </div>
                </div>
            </>}
        </>)

    }
    getProductServiceChildCard(item: Partial<ISignupService>, itemIndex: number, product: Partial<ISignupService>|undefined, productIndex: number) {

        return (<>
            {product && <>
                <div className="product-image">
                    <img src={product.iconURI}></img>
                </div>
                <div>
                    <Text>
                        <h4 className="text-blue">{product.title}</h4>
                    </Text>
                    <div className="switch-container">
                        <Toggle label="" checked={product.isSelected} onText="" offText=""
                            onChange={(e, v) => {
                                this.onProductSwitchToggle(item, itemIndex, product, productIndex, v || false);

                            }} />


                    </div>

                </div>
            </>}
        </>)

    }
    getIntegrationCard(item: Partial<ISignupService> | undefined, index: number) {
        return (<>
            {item && <>
                <Text className="d-flex justify-end integration-card-heading">
                    <h4 className="text-blue">{item.title}

                    </h4>
                    <Text>
                        <span className="infoIcon tooltip">i
                            <span className="tooltiptext">{item.overlayText}</span>
                        </span>
                    </Text>
                </Text>



                <div className="d-flex integration-img-wrapper">
                    <div className="proImag">
                        <img src={item.iconURI}></img>

                    </div>
                    <div className="switch-container">
                        <Toggle label="" checked={item.isSelected} onText="" offText=""
                            onChange={(e, v) => {
                                this.onIntegrationToggle(item, index, v || false);

                            }} />
                    </div>

                </div>
            </>
            }
        </>)

    }

    camelCase(str: string) {
        return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word: string, index: number) {
            return index == 0 ? word.toUpperCase() : word.toLowerCase();
        }).replace(/\s+/g, '');
    }

    onProductSwitchToggle(item: Partial<ISignupService> | undefined, itemIndex: number, product: Partial<ISignupService> | undefined, productIndex: number, state: boolean) {
        if (!item)
            return;
        if (!product)
            item.isSelected = state;

        if (product) {
            product.isSelected = state;
            let products: any = item.products;
            products[productIndex] = product;
            item.products = products;

        }
        let services: any = this.state.contactUsCommand.productServices || [];
        services[itemIndex] = item;

        let pricelist: any = [];
        let frequencies: any = [];
        let excludedProducts: any = [];
        let hasDifferentFrequencies: boolean = false;
        services.filter((x: Partial<ISignupService> | undefined) => x && x.isSelected).map((x: any) => {

            (x.products || []).filter((y: any) => y.isSelected && y.price > 0).map((ix: any) => {

                let recType = ix.isRecurring ? ix.recurringType : "One Time";
                hasDifferentFrequencies = frequencies.filter((freq: string) => freq != recType).length > 0;

                frequencies.push(recType);
                if (hasDifferentFrequencies) {
                    excludedProducts.push(ix.propKey)

                }
                pricelist.push({
                    message: `$${ix.price}${ix.inclusiveGST == false ? " + GST - " : ""}${ix.isRecurring ? (" (Per " + this.camelCase(ix.recurringType) + ")") : ''} ${ix.title}${hasDifferentFrequencies ? "*" : ""}`,
                    isRecurring: ix.isRecurring,
                    recurringType: ix.recurringType
                })
            });
        });

        if (hasDifferentFrequencies) {
            pricelist.push({
                message: `Note:For Payment of selected services with * mark, Konekta will be in touch soon.`,

            })
        }



        this.setState({
            contactUsCommand: {
                ...this.state.contactUsCommand,
                productServices: [...services],
                pricelist: [...pricelist],
                excludedProducts: [...excludedProducts]
            }
        });

        this.validateOnToggle();

    }
    onIntegrationToggle(integrationItem: Partial<ISignupService> | undefined, integrationIndex: number, state: boolean) {

        if (!integrationItem)
            return;
        let integrations: any = this.state.contactUsCommand.integrations || [];


        integrationItem.isSelected = state;

        integrations[integrationIndex] = integrationItem;

        this.setState({
            contactUsCommand: {
                ...this.state.contactUsCommand,
                integrations: [...integrations]
            }
        });


        this.validateOnToggle();

    }

    validateOnToggle() {
        const validationErrors = this.validate();

        this.setState({
            formState: FormState.Editable,
            validationErrors,
        });

        return;

    }

    render(): JSX.Element {
        const checkBoxStackTokens: IStackTokens = {
            childrenGap: 3,
        }

        const containerTokens: IStackTokens = {
            childrenGap: 'l',
        }

        const outerContainerStyles: IStackStyles = {
            root: {
                alignItems: 'center',
                marginBottom: 100,
            }
        }

        const innerContainerStyles: IStackStyles = {
            root: {
                width: 1200,
            }
        }

        const sectionStyles: IStackStyles = {
        }

        if (this.state.formState === FormState.SubmittedSuccess) {
            return (
                <Stack styles={outerContainerStyles} tokens={containerTokens}>
                    <Stack styles={innerContainerStyles} tokens={DefaultStackTokens}>
                        <Stack styles={sectionStyles} tokens={checkBoxStackTokens}>
                            <Text><h1>Contact Us</h1></Text>
                            <Text><h2>Form submitted successfully</h2></Text>
                            <Text>
                                Thank you! Someone from Konekta will be in touch soon.
                            </Text>
                        </Stack>
                    </Stack>
                </Stack>
            )
        } else {
            const disableSubmit = this.state.formState === FormState.Submitting
                || (this.state.validationErrors && this.state.validationErrors.length > 0);

            const services = this.state.contactUsCommand.productServices;

            return (
                <form className='contact-us-page' onSubmit={this.submit}>
                    <Stack styles={outerContainerStyles} tokens={containerTokens}>
                        <Stack styles={innerContainerStyles} tokens={DefaultStackTokens}>
                            <Stack styles={sectionStyles} tokens={checkBoxStackTokens}>
                                <Text >
                                    <h1 className="Header">Connect with Konekta Products and Integrations</h1>
                                    <div className="Header">Please complete the fields below to sign-up Konekta Products and Integrations</div>
                                    <br />
                                    <div className="Header">Once you have signed up, you will recieve further instructions to enable us to seamlessly integrate with your Actionstep System</div>
                                    <h2 className="text-blue">Contact Details</h2>
                                </Text>



                            </Stack>
                            <Stack className="contact-us-fields-container-v1" styles={sectionStyles} tokens={checkBoxStackTokens}>

                                <TextField placeholder="ActionStep Organisation"
                                    onChange={(e, v) => this.setState({ contactUsCommand: { ...this.state.contactUsCommand, orgKey: v } })}
                                    value={this.state.contactUsCommand.orgKey}
                                    maxLength={20} required underlined />
                                <Text><h3 className="Header" >Can't see the Actionstep organisation you are looking for? Click here to connect another organisation.</h3>
                                </Text>
                                <TextField placeholder="Company"
                                    onChange={(e, v) => this.setState({ contactUsCommand: { ...this.state.contactUsCommand, company: v } })}
                                    value={this.state.contactUsCommand.company}
                                    maxLength={100} required underlined />

                                <TextField placeholder="Contact - First Name"
                                    onChange={(e, v) => this.setState({ contactUsCommand: { ...this.state.contactUsCommand, firstname: v } })}
                                    value={this.state.contactUsCommand.firstname}
                                    maxLength={100} required underlined />


                                <TextField placeholder="Contact - Last Name"
                                    onChange={(e, v) => this.setState({ contactUsCommand: { ...this.state.contactUsCommand, lastname: v } })}
                                    value={this.state.contactUsCommand.lastname}
                                    maxLength={100} required underlined />
                                <div>
                                    <div className="element1" >
                                        <TextField placeholder="Contact - Email"
                                            onChange={(e, v) => this.setState({ contactUsCommand: { ...this.state.contactUsCommand, email: v } })}
                                            value={this.state.contactUsCommand.email}
                                            maxLength={100} required underlined />
                                    </div>
                                    <div className="element2" >
                                        <TextField placeholder="Contact - Phone"
                                            onChange={(e, v) => this.setState({ contactUsCommand: { ...this.state.contactUsCommand, phone: v } })}
                                            value={this.state.contactUsCommand.phone}
                                            maxLength={20} required underlined />
                                    </div>
                                </div>

                                <TextField placeholder="Contact - Address Line 1"
                                    onChange={(e, v) => this.setState({ contactUsCommand: { ...this.state.contactUsCommand, line1: v } })}
                                    value={this.state.contactUsCommand.line1}
                                    maxLength={20} underlined />

                                <TextField placeholder="Contact - Address Line 2"
                                    onChange={(e, v) => this.setState({ contactUsCommand: { ...this.state.contactUsCommand, line2: v } })}
                                    value={this.state.contactUsCommand.line2}
                                    maxLength={20} underlined />


                                <div className="d-flex " >
                                    <div className="column " >
                                        <TextField placeholder="Contact - City"
                                            onChange={(e, v) => this.setState({ contactUsCommand: { ...this.state.contactUsCommand, suburb: v } })}
                                            value={this.state.contactUsCommand.suburb}
                                            maxLength={20} underlined />
                                    </div>

                                    <div className="column" >
                                        <TextField placeholder="Contact - State"
                                            onChange={(e, v) => this.setState({ contactUsCommand: { ...this.state.contactUsCommand, state: v } })}
                                            value={this.state.contactUsCommand.state}
                                            maxLength={20} underlined />
                                    </div>

                                    <div className="column">
                                        <TextField placeholder="Contact - Post Code"
                                            onChange={(e, v) => this.setState({ contactUsCommand: { ...this.state.contactUsCommand, postCode: v } })}
                                            value={this.state.contactUsCommand.postCode}
                                            maxLength={20} underlined />
                                    </div>
                                </div>

                                <hr className="splitter" />

                                <Text>
                                    <h2 className="text-blue">Konekta Products and Services</h2>
                                    <p>Which area of Konekta products are you intrested in?</p>
                                </Text>
                            </Stack>
                            <div className="products-services-wrapper">

                                {(services || []).map((item: Partial<ISignupService> | undefined, index: number) => {
                                    return (
                                        <div className="product-card" key={Math.random()}>
                                            {this.getProductServiceCard(item, index)}
                                        </div>)

                                })
                                }
                            </div>

                            <div>
                                {(services || []).map((item: Partial<ISignupService> | undefined, index: number) => {

                                    return (<div key={Math.random()}>
                                        {item && item.isSelected && <>
                                            <Text><h3 className="text-blue text-bold">{item.title}</h3></Text>
                                            {(item.tagLines||"").split("\\n").map((tag: string) => <p key={Math.random()}><Text>{tag.replace("\\n", "").replace("\r\n", "")}</Text></p>)}
                                            <div className="products-services-wrapper multi-container">
                                                {(item.products || []).map((prod: Partial<ISignupService>|undefined, indexProduct: number) => {
                                                    return (
                                                        <div className="product-card" key={Math.random()}>
                                                            {this.getProductServiceChildCard(item, index, prod, indexProduct)}
                                                        </div>)


                                                })}


                                            </div>
                                        </>
                                        }
                                    </div>)
                                })
                                }
                            </div>




                            <Text><h2 className="text-blue" >Konekta Integrations</h2></Text>
                            <div>
                                <div className="products-services-wrapper integration-container">
                                    {(this.state.contactUsCommand.integrations || []).map((item: Partial<ISignupService> | undefined, index: number) => {

                                        return (
                                            <div className="integration-card" key={Math.random()}>
                                                {this.getIntegrationCard(item, index)}
                                            </div>

                                        )
                                    })
                                    }
                                </div>
                            </div>
                            <hr  className="splitter" />

                            {this.state.contactUsCommand.pricelist.length > 0 &&
                                <>
                                    <Text><h2 className="text-blue" >Payment Details</h2></Text>
                                    <ul>
                                        <li><p><Text>Based on your selections above the following subscriptions will be charged to your account:</Text></p></li>
                                        {this.state.contactUsCommand.pricelist.map((x: any) => {
                                            return (<li><Text>{x.message}</Text></li>)
                                        })
                                        }
                                    </ul>
                                    <Stack className="contact-us-fields-container-v1" styles={sectionStyles} tokens={checkBoxStackTokens}>
                                        <TextField placeholder="Cardholder Name"
                                            onChange={(e, v) => this.setState({ contactUsCommand: { ...this.state.contactUsCommand, cardholderName: v } })}
                                            value={this.state.contactUsCommand.cardholderName}
                                            maxLength={100} required underlined />

                                        <TextField placeholder="Card Number"
                                            onChange={(e, v) => this.setState({ contactUsCommand: { ...this.state.contactUsCommand, cardNumber: v } })}
                                            value={this.state.contactUsCommand.cardNumber}
                                            maxLength={100} required underlined />


                                        <TextField placeholder="Promo Code"
                                            onChange={(e, v) => this.setState({ contactUsCommand: { ...this.state.contactUsCommand, promoCode: v } })}
                                            value={this.state.contactUsCommand.promoCode}
                                            maxLength={100} underlined />
                                    </Stack>

                                </>

                            }


                            {/*<Stack styles={sectionStyles} tokens={checkBoxStackTokens}>*/}
                            {/*    <Label><strong>Integrations</strong> you're interested in?</Label>*/}
                            {/*    <Checkbox label="PEXA"*/}
                            {/*        onChange={(e, v) => this.setState({ contactUsCommand: { ...this.state.contactUsCommand, interestedInPEXAIntegration: v ?? false } })}*/}
                            {/*        checked={this.state.contactUsCommand.interestedInPEXAIntegration} />*/}
                            {/*    <Checkbox label="InfoTrack"*/}
                            {/*        onChange={(e, v) => this.setState({ contactUsCommand: { ...this.state.contactUsCommand, interestedInInfoTrackIntegration: v ?? false } })}*/}
                            {/*        checked={this.state.contactUsCommand.interestedInInfoTrackIntegration} />*/}
                            {/*    <Checkbox label="Dye & Durham"*/}
                            {/*        onChange={(e, v) => this.setState({ contactUsCommand: { ...this.state.contactUsCommand, interestedInGlobalXIntegration: v ?? false } })}*/}
                            {/*        checked={this.state.contactUsCommand.interestedInGlobalXIntegration} />*/}
                            {/*    <Checkbox label="First Title"*/}
                            {/*        onChange={(e, v) => this.setState({ contactUsCommand: { ...this.state.contactUsCommand, interestedInFirstTitleIntegration: v ?? false } })}*/}
                            {/*        checked={this.state.contactUsCommand.interestedInFirstTitleIntegration} />*/}
                            {/*    <Checkbox label="livesign"*/}
                            {/*        onChange={(e, v) => this.setState({ contactUsCommand: { ...this.state.contactUsCommand, interestedInLiveSignIntegration: v ?? false } })}*/}
                            {/*        checked={this.state.contactUsCommand.interestedInLiveSignIntegration} />*/}
                            {/*    <Checkbox label="Perfect Portal"*/}
                            {/*        onChange={(e, v) => this.setState({ contactUsCommand: { ...this.state.contactUsCommand, interestedInPerfectPortalIntegration: v ?? false } })}*/}
                            {/*        checked={this.state.contactUsCommand.interestedInPerfectPortalIntegration} />*/}
                            {/*    <Checkbox label="Secure Exchange"*/}
                            {/*        onChange={(e, v) => this.setState({ contactUsCommand: { ...this.state.contactUsCommand, interestedInSecureExchangeIntegration: v ?? false } })}*/}
                            {/*        checked={this.state.contactUsCommand.interestedInSecureExchangeIntegration} />*/}
                            {/*    <Checkbox label="Pexa Key"*/}
                            {/*        onChange={(e, v) => this.setState({ contactUsCommand: { ...this.state.contactUsCommand, interestedInPexaKeyIntegration: v ?? false } })}*/}
                            {/*        checked={this.state.contactUsCommand.interestedInPexaKeyIntegration} />*/}
                            {/*    <Checkbox label="Rapid Building Inspections"*/}
                            {/*        onChange={(e, v) => this.setState({ contactUsCommand: { ...this.state.contactUsCommand, interestedInRBIIntegration: v ?? false } })}*/}
                            {/*        checked={this.state.contactUsCommand.interestedInRBIIntegration} />*/}
                            {/*</Stack>*/}

                            {/*<Stack styles={sectionStyles} tokens={checkBoxStackTokens}>*/}
                            {/*    <TextField label="Message (Optional)"*/}
                            {/*        onBlur={(e) => this.setState({ contactUsCommand: { ...this.state.contactUsCommand, message: e.currentTarget.value } })}*/}
                            {/*        maxLength={2000}*/}
                            {/*        multiline autoAdjustHeight />*/}
                            {/*</Stack>*/}

                            <Stack styles={sectionStyles} tokens={checkBoxStackTokens}>
                                {this.state.formState === FormState.SubmitError &&
                                    <Stack.Item>
                                        <ErrorMessageWidget message="Oops! We're sorry, something went wrong." />
                                    </Stack.Item>
                                }
                                {this.state.validationErrors && this.state.validationErrors.length > 0 &&
                                    <ul>
                                        {this.state.validationErrors.map(e =>
                                            <li key={e} className="errorMessage"><Text>{e}</Text></li>
                                        )}
                                    </ul>
                                }
                                <Stack.Item>
                                    <PrimaryButton
                                        text="Submit"
                                        type="submit"
                                        onClick={async (event: any) => {

                                            this.setState({
                                                contactUsCommand: {
                                                    ...this.state.contactUsCommand,
                                                    payNowChoosed: false
                                                }
                                            });

                                        }}
                                        allowDisabledFocus={true}
                                        default
                                        disabled={disableSubmit} />


                                    {this.state.contactUsCommand.pricelist.length > 0 && <PrimaryButton
                                        text="Submit & Pay Now"
                                        className="mx-auto"
                                        type="submit"
                                        allowDisabledFocus={true}
                                        default
                                        onClick={async (event: any) => {

                                            this.setState({
                                                contactUsCommand: {
                                                    ...this.state.contactUsCommand,
                                                    payNowChoosed: true
                                                }
                                            });

                                        }}
                                        disabled={disableSubmit} />
                                    }


                                    {this.state.formState === FormState.Submitting &&
                                        <Spinner size={SpinnerSize.small} />
                                    }


                                </Stack.Item>
                            </Stack>
                            <p className="payment-section"><a href="https://www.stripe.com"><img
                                src="/images/powered_by_stripe.png" alt="Powered by stripe logo" /></a>
                                <Text>Payments</Text></p>

                        </Stack>
                    </Stack>

                </form >
            )
        }
    }
}

const mapStateToProps = (state: AppState) => {
    return {
        accountInfo: state.common.accountInfo,
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactUsPageV2);