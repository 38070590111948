export enum CommonEvents {
    GET_ACCOUNT_INFO_REQUESTED = "CommonReducer/GET_ACCOUNT_INFO_REQUESTED",
    GET_ACCOUNT_INFO_SUCCESS = "CommonReducer/GET_ACCOUNT_INFO_SUCCESS",
    GET_ACCOUNT_INFO_FAILED = "CommonReducer/GET_ACCOUNT_INFO_FAILED",

    SET_CURRENT_ORG = "CommonReducer/SET_CURRENT_ORG",
    CLEAR_CURRENT_ORG = "CommonReducer/CLEAR_CURRENT_ORG",

    SET_UI_DEFINITIONS = "CommonReducer/SET_UI_DEFINITIONS",

    GET_MATTER_INFO_REQUESTED = "CommonReducer/GET_MATTER_INFO_REQUESTED",
    GET_MATTER_INFO_SUCCESS = "CommonReducer/GET_MATTER_INFO_SUCCESS",
    GET_MATTER_INFO_FAILED = "CommonReducer/GET_MATTER_INFO_FAILED",
    GET_MATTER_INFO_FAILED_NOT_FOUND = "CommonReducer/GET_MATTER_INFO_FAILED_NOT_FOUND", // Not found on server
    MATTER_INFO_NOT_SUPPLIED = "CommonReducer/MATTER_INFO_NOT_SUPPLIED",                 // Not supplied in context (e.g. query string or JWT)

    LOG_AUTH_FAILURE = "CommonReducer/LOG_AUTH_FAILURE",

    SET_ACTIONSTEP_CONTEXT = "CommonReducer/SET_ACTIONSTEP_CONTEXT",

    ACTIONSTEP_UNAUTHORIZED_RESPONSE = "CommonReducer/ACTIONSTEP_UNAUTHORIZED_RESPONSE",
    MARK_ORG_VERIFIED = "CommonReducer/MARK_ORG_VERIFIED",
    RESET_ORG_AND_MATTER_REQUEST_STATUS = "CommonReducer/RESET_ORG_AND_MATTER_REQUEST_STATUS",

    PEXA_NOT_CONNECTED = "CommonReducer/PEXA_NOT_CONNECTED",
    PEXA_CONNECTED = "CommonReducer/PEXA_CONNECTED",

    FIRST_TITLE_NOT_CONNECTED = "CommonReducer/FIRST_TITLE_NOT_CONNECTED",
    FIRST_TITLE_CONNECTED = "CommonReducer/FIRST_TITLE_CONNECTED",

    CLEAR_COMMON_STATE = "CommonReducer/CLEAR_COMMON_STATE",

    SET_FULL_PAGE = "CommonReducer/SET_FULL_PAGE",

    GET_USER_AUDIT_TRAIL_DATA_REQUESTED = "CommonReducer/GET_USER_AUDIT_TRAIL_DATA_REQUESTED",
    GET_USER_AUDIT_TRAIL_DATA_SUCCESS = "CommonReducer/GET_USER_AUDIT_TRAIL_DATA_SUCCESS",
    GET_USER_AUDIT_TRAIL_DATA_FAILED = "CommonReducer/GET_USER_AUDIT_TRAIL_DATA_FAILED",

    SAVE_USER_AUDIT_TRAIL_DATA_REQUESTED = "CommonReducer/SAVE_USER_AUDIT_TRAIL_DATA_REQUESTED",
    SAVE_USER_AUDIT_TRAIL_DATA_SUCCESS = "CommonReducer/SAVE_USER_AUDIT_TRAIL_DATA_SUCCESS",
    SAVE_USER_AUDIT_TRAIL_DATA_FAILED = "CommonReducer/SAVE_USER_AUDIT_TRAIL_DATA_FAILED"

}