import * as React from 'react'

import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { Stack, Label, ComboBox } from 'office-ui-fabric-react';

import { FTParty } from 'utils/wcaApiTypes';
import { StreetTypes, Countries } from './constants'
import { Jurisdictions } from './constants';
import Tools from 'utils/tools';
import {
    LabelColumnStackItemStyles,
    DefaultStackTokens,
    DoubleWidthFormFieldStackItemStyles
} from 'konekta-theme';
import { Icon, TooltipHost } from '@fluentui/react';
import { v4 as uuidv4 } from 'uuid';

import './style.css';
import { getFTAddress } from 'utils/firstTitleTools';

interface IProps {
    party: FTParty;
    prefix: string;
    setMatterInformation: (keyPath: string, newValue: any) => void;
    disableCountrySelection: boolean;
}

export default class FTAddressInput extends React.Component<IProps> {
    

    render(): JSX.Element {
        const { party, setMatterInformation, prefix } = this.props;

        const ftAddress = getFTAddress(party);
        const hasAddress = ftAddress.address.split(' ').join('') != 'undefined' && ftAddress.address.split(' ').join('').length > 0;
        const hasCountry = party.country != null;

        return (
            <Stack horizontal tokens={DefaultStackTokens}>
                <Stack.Item disableShrink styles={LabelColumnStackItemStyles}>
                    <div className="address-heading-container">
                        <Label htmlFor="legalDescription">Address</Label>

                        <TooltipHost
                            id={uuidv4()}
                            content={
                                <div className="ft-address-tooltip-container">
                                    <span>First Title Address Info</span>
                                    <span><b>Address</b>: {hasAddress ? ftAddress.address : ''}</span>
                                    <span><b>City</b>: {ftAddress.city}</span>
                                    <span><b>Country</b>: {hasCountry ? ftAddress.country : ''}</span>
                                    <span><b>Postcode</b>: {ftAddress.postcode}</span>
                                    <span><b>State</b>: {ftAddress.state}</span>
                                </div>
                            }
                        >
                            <div className="address-ft-convert-popover">
                                <Icon iconName='StatusCircleQuestionMark' aria-hidden="true" />
                            </div>
                        </TooltipHost>
                    </div>
                </Stack.Item>

                <Stack tokens={DefaultStackTokens} styles={DoubleWidthFormFieldStackItemStyles} horizontal wrap>
                    <TextField
                        label="Building Name"
                        id={prefix + "_building_name"}
                        value={party.buildingName || ""}
                        onChange={(ev, newValue) => setMatterInformation("buildingName", newValue)}
                    />

                    <TextField
                        label="Floor No"
                        id={prefix + "_floor_no"}
                        value={party.floorNo || ""}
                        onChange={(ev, newValue) => setMatterInformation("floorNo", newValue)}
                    />

                    <TextField
                        label="Unit No"
                        id={prefix + "_unit_no"}
                        value={party.unitNo || ""}
                        onChange={(ev, newValue) => setMatterInformation("unitNo", newValue)}
                    />

                    <TextField
                        label="Street No"
                        id={prefix + "_street_no"}
                        value={party.streetNo || ""}
                        onChange={(ev, newValue) => setMatterInformation("streetNo", newValue)}
                    />

                    <TextField
                        label="Street Name"
                        id={prefix + "_street_name"}
                        value={party.streetName || ""}
                        onChange={(ev, newValue) => setMatterInformation("streetName", newValue)}
                    />

                    <ComboBox
                        label="Street Type"
                        id={prefix + "_street_type"}
                        options={StreetTypes}
                        placeholder="Please select..."
                        selectedKey={party.streetType}
                        errorMessage={Tools.valueIsInList(party.streetType, StreetTypes) ? "" : "Required"}
                        onChange={(event, item) => setMatterInformation("streetType", item!.key.toString())}
                    />

                    <TextField
                        label="City"
                        id={prefix + "_city"}
                        value={party.city || ""}
                        errorMessage={Tools.isNullOrEmptyOrUndefined(party.city) ? "Required" : ""}
                        onChange={(ev, newValue) => setMatterInformation("city", newValue)}
                    />

                    {party.country == "AU" &&
                        <ComboBox
                            label="State"
                            id={prefix + "_state"}
                            options={Jurisdictions}
                            placeholder="Please select..."
                            defaultValue={party.stateProvince?.toUpperCase()}
                            selectedKey={party.stateProvince?.toUpperCase()}
                            errorMessage={Tools.valueIsInList(party.stateProvince?.toUpperCase(), Jurisdictions) ? "" : "Required"}
                            onChange={(ev, item) => setMatterInformation("stateProvince", item!.key.toString())}
                        />
                    }

                    <TextField
                        label="PostCode"
                        id={prefix + "_postcode"}
                        value={party.postCode || ""}
                        errorMessage={Tools.isNullOrEmptyOrUndefined(party.postCode) ? "Required" : ""}
                        onChange={(ev, newValue) => setMatterInformation("postCode", newValue)}
                    />

                    <ComboBox
                        label="Country"
                        id={prefix + "_country"}
                        options={Countries}
                        disabled={this.props.disableCountrySelection}
                        placeholder="Please select..."
                        selectedKey={party.country ?? ''}
                        errorMessage={Tools.isNullOrEmptyOrUndefined(party.country) ? "Required" : ""}
                        onChange={(ev, item) => {
                            if (item!.key.toString() !== "AU") {
                                setMatterInformation("stateProvince", "");
                            }
                            setMatterInformation("country", item!.key.toString())
                        }}
                    />
                </Stack>
            </Stack>
        );
    }
}