import { AppState } from "app.types";
import React from "react";
import { connect } from "react-redux";
import { StewartTitleOrderDetailsQueryResponse } from "./utils/stewartTitleWCAApiTypes";
import * as CONSTANTS from './redux/constants'
import { Stack } from "@fluentui/react";
import Tools from "utils/tools";
import LoadingWidget from "components/common/loadingWidget";
import { getStewartTitleOrderDetails } from "./redux/actions";
import Alert from "@material-ui/lab/Alert";

type IAppProps = {}
interface AppStates {
    orderDetails: StewartTitleOrderDetailsQueryResponse | undefined,
    isLoading: boolean,
    hasError: boolean,
    errorMessage: string | undefined
}

type AppProps = IAppProps & ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

class StewartTitleOrderDetail extends React.Component<AppProps, AppStates> {
    constructor(props: Readonly<AppProps>) {
        super(props);

        this.state = {
            orderDetails: undefined,
            isLoading: true,
            hasError: false,
            errorMessage: undefined
        }
    }

    componentDidMount(): void {
        this.getOrderDetails();
    }

    private getOrderDetails() {
        const { actionstepContext } = this.props

        if (actionstepContext === undefined || actionstepContext.matterContext === undefined) {
            this.setState({
                errorMessage: "No matter selected"
            });
            return
        }

        this.props.getOrderDetails(actionstepContext.orgKey, actionstepContext.matterContext.id);
    }

    static getDerivedStateFromProps(nextProps: AppProps, prevState: AppStates): AppStates {
        let nextState = {} as AppStates;

        if (nextProps.gotResponse) {
            switch (nextProps.requestType) {
                case CONSTANTS.GET_STEWART_TITLE_ORDER_DETAILS_REQUESTED: {
                    nextState.isLoading = true;
                    break;
                }
                case CONSTANTS.GET_STEWART_TITLE_ORDER_DETAILS_FAILED: {
                    nextState.isLoading = false;
                    break;
                }
                case CONSTANTS.GET_STEWART_TITLE_ORDER_DETAILS_SUCCESS:
                    nextState.isLoading = false;
                    nextState = {
                        ...nextState,
                        orderDetails: nextProps.orderDetails
                    }
                    break;
                default:
                    return nextState;
            }
        }
        return nextState;
    }

    render(): JSX.Element {
        const { orderDetails } = this.props
        const { isLoading } = this.state

        if (isLoading) {
            return (
                <div className="stewarttitle-page-container">
                    <LoadingWidget />
                </div>
            )
        }

        return (
            <Stack.Item>
                <h1>Stewart Title Order Details</h1>
                <h3>File No: {orderDetails?.fileNo}</h3>

                {!isLoading && orderDetails?.fileNo == "0" ?
                    <div className="stewarttitle-page-container">
                        <Alert variant="filled" severity="error">{orderDetails?.errors && orderDetails?.errors?.length > 0 ? orderDetails?.errors?.[0].messages?.[0] : "Unable to get the order details. Please try again later."}</Alert>
                    </div> :
                    <>
                        {orderDetails?.policyNumber &&
                            <>
                                <h3>Status: {orderDetails?.status}</h3>
                                <p>Policy Order Id <strong>{orderDetails?.policyNumber}</strong>.</p><table>
                                    <tr>
                                        <td>Premium</td>
                                        <td><strong>{orderDetails?.premium && Tools.formatAsCurrency(orderDetails.premium)}</strong></td>
                                    </tr>
                                    <tr>
                                        <td>Stamp Duty</td>
                                        <td><strong>{orderDetails?.stampDuty && Tools.formatAsCurrency(orderDetails?.stampDuty)}</strong></td>
                                    </tr>
                                    <tr>
                                        <td>GST</td>
                                        <td><strong>{orderDetails?.gst && Tools.formatAsCurrency(orderDetails?.gst)}</strong></td>
                                    </tr>
                                    <tr>
                                        <td>Total Premium</td>
                                        <td><strong>{orderDetails?.totalPremium && Tools.formatAsCurrency(orderDetails?.totalPremium)}</strong></td>
                                    </tr>
                                    <tr>
                                        <td>DocumentVersion</td>
                                        <td><strong>{orderDetails?.documentVersion && orderDetails?.documentVersion}</strong></td>
                                    </tr>
                                </table></>}
                    </>
                }
            </Stack.Item>
        )
    }
}

const mapStateToProps = (state: AppState) => {
    return {
        actionstepContext: state.common.actionstepContext,
        orderDetails: state.stewartTitle.StewartTitleOrderDetailsQueryResponse,
        gotResponse: state.stewartTitle.gotResponse,
        success: state.stewartTitle.success,
        requestType: state.stewartTitle.requestType
    }
}
const mapDispatchToProps = {
    getOrderDetails: getStewartTitleOrderDetails
}

export default connect(mapStateToProps, mapDispatchToProps)(StewartTitleOrderDetail);