import { createTheme, IStackItemStyles, IStackTokens } from 'office-ui-fabric-react'

export default createTheme({
    defaultFontStyle: {
      fontFamily: 'Exo, "Open Sans", "Bitstream Vera Sans", Helvetica, arial, sans-serif',
    },
    palette: {
      themePrimary: '#004989',
      themeLighterAlt: '#f0f6fa',
      themeLighter: '#c6dbec',
      themeLight: '#9abddc',
      themeTertiary: '#4a85b9',
      themeSecondary: '#125998',
      themeDarkAlt: '#00427c',
      themeDark: '#003869',
      themeDarker: '#00294d',
      neutralLighterAlt: '#faf9f8',
      neutralLighter: '#f3f2f1',
      neutralLight: '#edebe9',
      neutralQuaternaryAlt: '#e1dfdd',
      neutralQuaternary: '#d0d0d0',
      neutralTertiaryAlt: '#c8c6c4',
      neutralTertiary: '#a19f9d',
      neutralSecondary: '#605e5c',
      neutralPrimaryAlt: '#3b3a39',
      neutralPrimary: '#323130',
      neutralDark: '#201f1e',
      black: '#000000',
      white: '#ffffff',
    }});

export const DefaultStackTokens: IStackTokens = {
  childrenGap: 'm',
};

export const FormFieldStackItemStyles: IStackItemStyles = {
  root: {
    width: 200,
  },
};

export const DoubleWidthFormFieldStackItemStyles: IStackItemStyles = {
  root: {
    width: 400,
  },
};

export const LabelColumnStackItemStyles: IStackItemStyles = {
  root: {
    width: 200,
    minWidth: 200,
    maxWidth: 200,
  },
};

export const FieldColumnStackItemStyles: IStackItemStyles = {
  root: {
    maxWidth: 600,
  },
};
