import * as React from 'react'

import {
    Stack,
    Text,
    DefaultButton,
    PrimaryButton,
    Label,
    TextField,
    Dropdown,
    IDropdownOption,
    Toggle,
    Dialog,
    DialogFooter,
    IStackTokens,
    Link,
    Modal,
    TooltipHost,
    MessageBar,
    MessageBarType,
} from 'office-ui-fabric-react';
import { useForm, Controller, useFieldArray, SubmitHandler } from "react-hook-form";

import Tools from 'utils/tools';

import { Link as ReactLink, RouteProps, useHistory } from 'react-router-dom';

import axios from 'axios';
import { useSelector } from 'react-redux';
import { AppState } from '../../../app.types';
import {
    DataKonektaSettings,
    User,
    IDataKonektaSettings,
    IDataKonektaFormSettings,
    SaveDataKonektaSettings,
} from '../../../utils/wcaApiTypes';
import { getRequest, postRequest } from 'utils/request';
import { getFormInfo, enhanceForm, IField } from 'utils/webFormUtils';

import { DefaultStackTokens } from '../../../konekta-theme';
import { FunctionComponent, useState, useEffect, ChangeEvent } from 'react';
import ErrorMessageWidget from 'components/common/errorMessageWidget';
import LoadingWidget from 'components/common/loadingWidget';
import ConnectedActionstepUserSelector from 'components/common/connectedActionstepUserSelector';
import HelpTip from 'components/common/helpTip';
import { isNumber } from 'util';
import { Card, ICardTokens, ICardSectionStyles } from '@uifabric/react-cards';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faExclamationTriangle, faCode } from '@fortawesome/free-solid-svg-icons';

interface IProps extends RouteProps {
}

enum AddEditSteps {
    Step1PasteHtml,
    Step2SelectField,
    Step3CopyEnhancedHtml,
}

const backRoute = '/manage-add-ons';

export const DataKonekta: FunctionComponent<IProps> = (props) => {
    const history = useHistory();
    const [pageErrorMessage, setPageErrorMessage] = useState<string>();
    const [addEditErrorMessage, setAddEditErrorMessage] = useState<string>();

    const [loading, setLoading] = useState(true);
    const [featureDisabled, setFeatureDisabled] = useState(false);
    const { register, handleSubmit, control, watch, reset, setValue } = useForm<IDataKonektaSettings>();
    const { fields, append, remove } = useFieldArray<IDataKonektaFormSettings>({control, name: 'dataKonektaFormSettings'});
    const settings = watch();
    const [saving, setSaving] = useState(false);
    const [availableFields, setAvailableFields] = useState<IField[][]>();

    const [addEditStep, setAddEditStep] = useState<AddEditSteps>(AddEditSteps.Step1PasteHtml);

    const [formHtml, setFormHtml] = useState<string>('');

    const [addEditDialogIsOpen, setAddEditDialogIsOpen] = useState(false);
    const [formIdIndex, setFormIdIndex] = useState<number>();

    const [hideRemoveDialog, setHideRemoveDialog] = useState(true);
    const [formIndexToRemove, setFormIndexToRemove] = useState<number>();

    const orgKey = useSelector((state: AppState) => state.common.actionstepContext?.orgKey);
    const formUserMissing = settings?.dataKonektaFormSettings?.reduce((a,c) => c.formSubmissionsEnabled || a, false) && !settings.formSubmissionUser;

    const closeAddEditDialog = () => {
        setAddEditStep(AddEditSteps.Step1PasteHtml);
        setFormIdIndex(undefined);
        setPageErrorMessage('');
        setAddEditErrorMessage('');
        setAddEditDialogIsOpen(false);
    }

    useEffect(() => {
        const source = axios.CancelToken.source();
        (async () => {
            try {
                const settingsFromServer = await getRequest<DataKonektaSettings>(`/api/data-konekta/settings/${orgKey}`);
                if (settingsFromServer && !settingsFromServer.managementEnabled) setFeatureDisabled(true);

                // Reset and then calling append() is a hack to make sure the user can remove all
                // items in the array. Currently, if all fieldArray items are removed, the array
                // is set to the default value (which in this case may have data from the server).
                // See https://github.com/react-hook-form/react-hook-form/issues/2382
                reset({...settingsFromServer, dataKonektaFormSettings: []});
                settingsFromServer.dataKonektaFormSettings?.forEach(f => append(f));

                 setLoading(false);
            } catch (error) {
                if (!axios.isCancel(error)) {
                    if (error?.response?.status === 404) {
                        setFeatureDisabled(true);
                    } else {
                        console.error('Error loading Data Konekta settings', error);
                        setPageErrorMessage(error?.message);
                    }

                    setLoading(false);
                }
            }
        })();

        return () => source.cancel()
    }, [orgKey, reset, append, setLoading, setFeatureDisabled]);

    const onFormHtmlChange = (event: ChangeEvent<HTMLTextAreaElement>): void => {
        event.preventDefault();
        setAddEditErrorMessage('');
        setFormHtml(event.target.value);
        let formInfo = getFormInfo(event.target.value);
        if (typeof(formInfo) === 'string') {
            setAddEditErrorMessage(formInfo);
        } else {
            if (formInfo.orgKey !== orgKey) {
                setAddEditErrorMessage(`The organisation key in form '${formInfo.orgKey}' does not match the organisation currently selected '${orgKey}'.`);
            }

            let newFields = availableFields ?? [];
            let uid = formInfo.uid;
            let itemIndex = fields.reduce((existingItemIndex, currentItem, currentIndex) =>
                currentItem.actionstepUid === uid ? currentIndex : existingItemIndex, -1);
            if (itemIndex >= 0) {
                fields[itemIndex].formIdField = '';
                setValue(`dataKonektaFormSettings[${itemIndex}].formIdField`, '');
                fields[itemIndex].formAttachmentListField = '';
                setValue(`dataKonektaFormSettings[${itemIndex}].formAttachmentListField`, '');
                fields[itemIndex].formSubmissionsEnabled = true;
                setValue(`dataKonektaFormSettings[${itemIndex}].formSubmissionsEnabled`, true);
            } else {
                itemIndex = fields.length;
                append({
                    actionstepUid: uid,
                    formSubmissionsEnabled: true,
                    maxAttachmentSizeMiB: 20,
                    maxAttachments: 10,
                    formIdField: '',
                    formAttachmentListField: '',
                    enhancedWebformHtml: '',
                });
            }

            newFields[itemIndex] = formInfo.fields;
            setAvailableFields(newFields);

            setFormIdIndex(itemIndex);
            setAddEditStep(AddEditSteps.Step2SelectField);
        }
    }

    const onSubmit: SubmitHandler<IDataKonektaSettings> = async data => {
        if (formUserMissing) {
            setPageErrorMessage('Form submission user must be set if submissions are enabled.');
            return;
        }

        setSaving(true);
        try {
            const saveSettings = SaveDataKonektaSettings.fromJS({
                actionstepOrgKey: data?.actionstepOrg?.key,
                formSubmissionUserId: data?.formSubmissionUser?.id,
                dataKonektaFormSettings: data.dataKonektaFormSettings,
            });
            await postRequest('/api/data-konekta/settings', saveSettings);
            setSaving(false);
            history.push(backRoute);
        } catch (error) {
            setPageErrorMessage(error?.message);
            setSaving(false);
        }
    }

    const getFormIdOptions = (index: number): IDropdownOption[] => {
        if (availableFields && availableFields[index]) {
            let dropdownOptions = availableFields[index].map<IDropdownOption>(f => {return {key: f.id, text: f.label}});
            dropdownOptions.unshift({key: '', text: 'Select the ID field'});
            return dropdownOptions;
        } else {
            let formIdField = fields[index]?.formIdField ?? 'Please paste in Web Form HTML...';
            return [{key: formIdField, text: formIdField}];
        }
    }

    const sectionStackTokens: IStackTokens = { childrenGap: 10, padding: 15 };
    const configuredFormsStackTokens: IStackTokens = { childrenGap: 15, padding: 0 };
    const cardTokens: ICardTokens = { childrenMargin: 0, width: 320, maxWidth: 320};
    const footerCardSectionStyles: ICardSectionStyles = {
        root: {
            padding: '10px 12px 6px 0',
            borderTop: '1px solid #F3F2F1',
        },
    };

    return (
        <Stack tokens={DefaultStackTokens}>
            <ErrorMessageWidget message={pageErrorMessage} />

            {loading ?
                <LoadingWidget />
                :
                <Stack>
                    <Stack.Item>
                        <h1>Data Konekta</h1>
                        <Text>The Data Konekta allows you to configure enhanced Webforms that include support for Attachments.</Text>
                    </Stack.Item>

                    {featureDisabled ?
                        <div>
                            Data Konekta is not yet enabled for your organisation. Please <ReactLink to="/contactus">Contact us</ReactLink> if you would like to know more.
                        </div>
                        :
                        settings &&
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Stack tokens={{childrenGap:10}}>
                                <Stack.Item>
                                    <h2>Organisation Settings</h2>
                                </Stack.Item>

                                <Stack.Item>
                                    <Label required={formUserMissing}>
                                        User to use when uploading attachments to Actionstep <HelpTip helpContent="Select which user will be used to submit data to Actionstep. This user must have a working connection to Actionstep. If this user becomes disconnected, form submissions will fail until the user is re-connected again." />
                                    </Label>
                                    <Controller
                                        name="formSubmissionUser"
                                        control={control}
                                        defaultValue={settings.formSubmissionUser}
                                        render={(props) => <ConnectedActionstepUserSelector
                                            itemLimit={1}
                                            value={[props.value]}
                                            onChange={e => props.onChange(e && e[0] ? User.fromJS(e[0]) : undefined)}
                                        />}
                                    />
                                </Stack.Item>

                                <Stack.Item>
                                    <Text>To add a new form or update an existing form, paste the Actionstep Webform HTML below.</Text>
                                    <br />
                                    <PrimaryButton onClick={() => {setAddEditStep(AddEditSteps.Step1PasteHtml);setAddEditDialogIsOpen(true)}}>Add or Edit a Webform</PrimaryButton>
                                </Stack.Item>

                                <Stack.Item>
                                    {fields.length < 1 ?
                                        <p>No Webforms are currently configured.</p>
                                        :
                                        <h2>Configured Webforms</h2>
                                    }
                                </Stack.Item>

                                <Stack horizontal tokens={configuredFormsStackTokens} wrap>
                                    {fields?.map((formSettings, index) =>
                                        <Card key={formSettings.actionstepUid} tokens={cardTokens}>
                                            <Card.Section>
                                                <Stack tokens={sectionStackTokens} >
                                                    <Stack.Item>
                                                        {/* <Text variant="large">
                                                            Form Name
                                                        </Text><br /> */}
                                                        <Text variant="large"><strong>{formSettings.name}</strong></Text>
                                                        <input type="hidden" ref={register()} name={`dataKonektaFormSettings[${index}].name`} value={formSettings.name ?? ''}/>
                                                        <input type="hidden" ref={register()} name={`dataKonektaFormSettings[${index}].enhancedWebformHtml`} value={formSettings.enhancedWebformHtml ?? ''}/>
                                                    </Stack.Item>

                                                    <Stack.Item>
                                                        <Text variant="small">
                                                            Actionstep Form ID <HelpTip helpContent="This is the unique id (uid) of the form. It can be found in the HTML source code for the form." />
                                                        </Text><br />
                                                        <Text variant="small"><strong>{formSettings.actionstepUid}</strong></Text>
                                                        <input type="hidden" ref={register()} name={`dataKonektaFormSettings[${index}].actionstepUid`} value={formSettings.actionstepUid ?? ''}/>
                                                    </Stack.Item>

                                                    <Stack.Item>
                                                        <Text variant="small">
                                                            Submission Field <HelpTip helpContent="This is used to link attachments to the matter once created." />
                                                        </Text><br />
                                                        <Text variant="small"><strong>{formSettings.formIdFieldLabel ?? 'Not Configured'}</strong></Text>
                                                        <input type="hidden" ref={register()} name={`dataKonektaFormSettings[${index}].formIdField`} value={formSettings.formIdField ?? ''}/>
                                                        <input type="hidden" ref={register()} name={`dataKonektaFormSettings[${index}].formIdFieldLabel`} value={formSettings.formIdFieldLabel ?? ''}/>
                                                    </Stack.Item>

                                                    <Stack.Item>
                                                        <Text variant="small">
                                                            Attachment List Field <HelpTip helpContent="This field shows the list of attachments to the user when processing the form." />
                                                        </Text><br />
                                                        <Text variant="small"><strong>{formSettings.formAttachmentListField ?? 'Not Configured'}</strong></Text>
                                                        <input type="hidden" ref={register()} name={`dataKonektaFormSettings[${index}].formAttachmentListField`} value={formSettings.formAttachmentListField ?? ''}/>
                                                        <input type="hidden" ref={register()} name={`dataKonektaFormSettings[${index}].formAttachmentListFieldLabel`} value={formSettings.formAttachmentListFieldLabel ?? ''}/>
                                                    </Stack.Item>

                                                    <Stack.Item>
                                                        <Text variant="small">
                                                            Max Attachment Size <HelpTip helpContent="The largest size (in megabytes) of each individual attachment that will be accepted for this organisation." />
                                                        </Text><br />
                                                        <Text variant="small"><strong>{formSettings.maxAttachmentSizeMiB} MiB</strong></Text>
                                                        <input type="hidden" ref={register()} name={`dataKonektaFormSettings[${index}].maxAttachmentSizeMiB`} value={formSettings.maxAttachmentSizeMiB ?? ''}/>
                                                    </Stack.Item>

                                                    <Stack.Item>
                                                        <Text variant="small">
                                                            Max Attachments <HelpTip helpContent="The largest number of attachments that will be accepted by forms for this organisation." />
                                                        </Text><br />
                                                        <Text variant="small"><strong>{formSettings.maxAttachments} MiB</strong></Text>
                                                        <input type="hidden" ref={register()} name={`dataKonektaFormSettings[${index}].maxAttachments`} value={formSettings.maxAttachments ?? ''}/>
                                                    </Stack.Item>

                                                    <Stack.Item>
                                                        <Controller
                                                            name={`dataKonektaFormSettings[${index}].formSubmissionsEnabled`}
                                                            defaultValue={formSettings.formSubmissionsEnabled}
                                                            control={control}
                                                            render={p =>
                                                                <Toggle
                                                                label="Enable Form Submissions?"
                                                                    onChange={(e,checked) => p.onChange(checked)}
                                                                    onBlur={p.onBlur}
                                                                    checked={p.value} />}
                                                        />
                                                        {formSettings.lastDisabledMessage &&
                                                            <>
                                                                <FontAwesomeIcon icon={faExclamationTriangle} color="orange" /> <Text variant="small"><strong>{formSettings.lastDisabledMessage}</strong></Text>
                                                            </>}
                                                    </Stack.Item>

                                                    <Stack.Item>
                                                        <Text variant="small">Last Updated</Text><br />
                                                        <Text variant="small"><strong>{new Date(formSettings.lastUpdated!).toLocaleString(Tools.DefaultLanguage) ?? 'Unknown'}</strong></Text>
                                                    </Stack.Item>
                                                </Stack>
                                            </Card.Section>
                                            <Card.Section horizontal verticalAlign="end" styles={footerCardSectionStyles}>
                                                <Stack.Item grow={1}>
                                                    <span />
                                                </Stack.Item>
                                                <TooltipHost content="View / copy enhanced webform HTML">
                                                    <Link onClick={() => {setFormIdIndex(index);setAddEditStep(AddEditSteps.Step3CopyEnhancedHtml);setAddEditDialogIsOpen(true);}}><FontAwesomeIcon icon={faCode} color="grey" /></Link>
                                                </TooltipHost>
                                                <TooltipHost content="Remove">
                                                    <Link onClick={() => {setFormIndexToRemove(index);setHideRemoveDialog(false);}}><FontAwesomeIcon icon={faTimes} color="grey" /></Link>
                                                </TooltipHost>
                                            </Card.Section>
                                        </Card>
                                    )}
                                </Stack>

                                <Stack.Item>
                                    <PrimaryButton type="submit" disabled={saving}>{saving ? 'Saving' : 'Save'}</PrimaryButton>
                                </Stack.Item>
                            </Stack>
                        </form>
                    }
                </Stack>
            }

            <ReactLink to={backRoute}>Go back</ReactLink>

            <Dialog
                hidden={hideRemoveDialog}
                onDismiss={() => setHideRemoveDialog(true)}
                dialogContentProps={{title: 'Confirm Form Removal', subText: 'Are you sure you want to remove this Webform from Data Konekta? Note: This will not delete the Webform from Actionstep.'}}
            >
                <DialogFooter>
                    <PrimaryButton onClick={() => {if (isNumber(formIndexToRemove)) remove(formIndexToRemove);setHideRemoveDialog(true);}} text="Remove" />
                    <DefaultButton onClick={() => setHideRemoveDialog(true)} text="Cancel" />
                </DialogFooter>
            </Dialog>

            <Modal
                isOpen={addEditDialogIsOpen}
                isBlocking={true}
                isDarkOverlay={true}

            >
                <Stack tokens={DefaultStackTokens} style={{padding: 15, width:650,height:700}}>
                    <Stack.Item>
                        <h1>Add or Edit Webform</h1>
                    </Stack.Item>

                    <Stack.Item>
                        <ErrorMessageWidget message={addEditErrorMessage} />
                    </Stack.Item>

                    {addEditStep === AddEditSteps.Step1PasteHtml &&
                        <>
                            <Stack.Item>
                                <Text>Step 1/3</Text>
                                <Label>Paste your Actionstep Webform HTML to add or update a Data Konekta enhanced Webform</Label>
                                <Text variant="small">If the form is already configured, it will be updated.</Text>
                                <textarea
                                    style={{width:'100%',height:'100px',resize:'vertical'}}
                                    onChange={e => {onFormHtmlChange(e);(e.target as HTMLTextAreaElement).select();}}
                                    onFocus={e => (e.target as HTMLTextAreaElement).select()}
                                />
                            </Stack.Item>

                            <Stack.Item>
                                <DefaultButton onClick={() => {closeAddEditDialog();}} text="Cancel" />
                            </Stack.Item>
                        </>
                    }

                    {addEditStep === AddEditSteps.Step2SelectField &&
                        <>
                            <Stack.Item>
                                <Text>Step 2/3</Text>
                            </Stack.Item>

                            <Stack.Item>
                                <Label htmlFor="formName">
                                    Form Name <HelpTip helpContent="Enter the name of this form so you can recognise it later." />
                                </Label>
                                <TextField
                                    id="formIdName"
                                    onChange={(e, v) => {if (isNumber(formIdIndex)) fields[formIdIndex].name = v}}
                                    onGetErrorMessage={v => Tools.isNullOrEmptyOrUndefined(v) ? 'Required' : ''}
                                />
                            </Stack.Item>

                            <Stack.Item>
                                <Label htmlFor="formIdField">
                                    Form Submission ID Field <HelpTip helpContent="Select the field where the Data Konekta form submission ID will be stored. This is used to link attachments to the matter once created." />
                                </Label>
                                <MessageBar messageBarType={MessageBarType.info}><strong>Important!</strong> Must be of type 'single line of text'</MessageBar>
                                <Dropdown
                                    id="formIdField"
                                    options={getFormIdOptions(isNumber(formIdIndex) ? formIdIndex : -1)}
                                    defaultSelectedKey={isNumber(formIdIndex) ? fields[formIdIndex].formIdField : ''}
                                    onChange={(e, o) => {
                                        if (isNumber(formIdIndex)) {
                                            fields[formIdIndex ?? -1].formIdField = o?.key.toString();
                                            fields[formIdIndex ?? -1].formIdFieldLabel = o?.text;
                                        }
                                    }}
                                />
                            </Stack.Item>

                            <Stack.Item>
                                <Label htmlFor="formAttachmentListField">
                                    Form Submission Attachment List Field <HelpTip helpContent="Select the field where a list of attachments should be added. This is for informational purposes only to make it easy to see if there are any attachments when processing a webform." />
                                </Label>
                                <Dropdown
                                    id="formAttachmentListField"
                                    options={getFormIdOptions(isNumber(formIdIndex) ? formIdIndex : -1)}
                                    defaultSelectedKey={isNumber(formIdIndex) ? fields[formIdIndex].formAttachmentListField : ''}
                                    onChange={(e, o) => {
                                        if (isNumber(formIdIndex)) {
                                            fields[formIdIndex ?? -1].formAttachmentListField = o?.key.toString();
                                            fields[formIdIndex ?? -1].formAttachmentListFieldLabel = o?.text;
                                        }
                                    }}
                                />
                            </Stack.Item>

                            <Stack.Item>
                                <Label htmlFor="maxAttachmentSizeMiB">
                                    Max Attachment Size <HelpTip helpContent="The largest size (in megabytes) of each individual attachment that will be accepted for this organisation." />
                                </Label>
                                <Controller
                                    width="200"
                                    name={`dataKonektaFormSettings[${formIdIndex}].maxAttachmentSizeMiB`}
                                    defaultValue={formIdIndex ? fields[formIdIndex].maxAttachmentSizeMiB: 20}
                                    control={control}
                                    min="1"
                                    max="20"
                                    type="number"
                                    as={TextField}
                                />
                            </Stack.Item>

                            <Stack.Item>
                                <Label htmlFor="maxAttachments">
                                    Max Attachments <HelpTip helpContent="The largest number of attachments that will be accepted by forms for this organisation." />
                                </Label>
                                <Controller
                                    width="200"
                                    name={`dataKonektaFormSettings[${formIdIndex}].maxAttachments`}
                                    defaultValue={formIdIndex ? fields[formIdIndex].maxAttachments : 10}
                                    control={control}
                                    min="1"
                                    max="10"
                                    type="number"
                                    as={TextField}
                                />
                            </Stack.Item>

                            <Stack.Item>
                                <Controller
                                    name={`dataKonektaFormSettings[${formIdIndex}].formSubmissionsEnabled`}
                                    defaultValue={formIdIndex ? fields[formIdIndex].formSubmissionsEnabled : true}
                                    control={control}
                                    render={p =>
                                        <Toggle
                                        label="Enable Form Submissions?"
                                        onChange={(e,checked) => p.onChange(checked)}
                                        onBlur={p.onBlur}
                                        checked={p.value} />}
                                />
                            </Stack.Item>

                            <Stack.Item>
                                <PrimaryButton onClick={e => {
                                    setAddEditErrorMessage('');

                                    if (isNumber(formIdIndex)) {
                                        if (Tools.isNullOrEmptyOrUndefined(fields[formIdIndex].name)) {
                                            setAddEditErrorMessage('Form name is required.');
                                        } else {
                                            try {
                                                let thisFormEnhancedForm = enhanceForm(formHtml, window.location.origin, fields[formIdIndex].formIdField ?? '', fields[formIdIndex].formAttachmentListField ?? '');
                                                fields[formIdIndex].enhancedWebformHtml = thisFormEnhancedForm;
                                                setValue(`dataKonektaFormSettings[${formIdIndex}].enhancedWebformHtml`, thisFormEnhancedForm);
                                                setAddEditStep(AddEditSteps.Step3CopyEnhancedHtml);
                                            } catch (e) {
                                                setAddEditErrorMessage(e.message);
                                            }
                                        }
                                    }
                                    e.preventDefault();
                                }} text="Ok" />
                                <DefaultButton onClick={() => {remove(formIdIndex);closeAddEditDialog();}} text="Remove Form" />
                            </Stack.Item>
                        </>
                    }

                    {addEditStep === AddEditSteps.Step3CopyEnhancedHtml &&
                        <>
                            <Stack.Item>
                                <Text>Step 3/3</Text>
                                <Label>Your enhanced Webform HTML is below. Copy this and use this on your website.</Label>
                                <textarea
                                    style={{width:'100%',height:'100px',resize:'vertical'}}
                                    value={isNumber(formIdIndex) && fields[formIdIndex].enhancedWebformHtml ? fields[formIdIndex].enhancedWebformHtml : 'Oops! Something went wrong.'}
                                    onFocus={e => (e.target as HTMLTextAreaElement).select()}
                                    onClick={e => (e.target as HTMLTextAreaElement).select()}
                                    readOnly
                                />
                            </Stack.Item>

                            <Stack.Item>
                                <PrimaryButton onClick={() => {closeAddEditDialog();}} text="Close" />
                                &nbsp;
                                <DefaultButton onClick={() => {remove(formIdIndex);closeAddEditDialog();}} text="Remove Form" />
                            </Stack.Item>
                        </>
                    }
                </Stack>
            </Modal>
        </Stack>
    )
}