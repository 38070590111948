import { IState, daysOfWeek, monthNames } from "./dateCalculatorInterface";

export const formatDateCalculatorString = (date: Date): string => {
	let day = daysOfWeek[date.getDay()];
	let dd = date.getDate();
	let mm = monthNames[date.getMonth()];
	let y = date.getFullYear();
	return day + ', ' + dd + ' ' + mm + ' ' + y;
}

const calculateItem = (stateData: IState, name: string): string => {
	let date = new Date(stateData.startingDate?.getTime() ?? 0);
	let type = (stateData[`${name}Type` as keyof IState] ?? 'day') as string;
	let days = (stateData[name as keyof IState] ?? 0) as number;

	if (type === 'day') {
		date.setDate(date.getDate() + days);
	} else if (type === 'week') {
		date.setDate(date.getDate() + days * 7);
	} else if (type === 'month') {
		date.setMonth(date.getMonth() + (days ?? 0));
	} else if (type === 'year') {
		date.setFullYear(date.getFullYear() + (days ?? 0));
	}

	return formatDateCalculatorString(date);
}

export const calculate = (stateData: IState, isNsw: boolean): IState => {
	if (stateData.financeDate && stateData.financeDate > 0) {
		stateData.financeDateDue = calculateItem(stateData, 'financeDate');
	} else {
		stateData.financeDateDue = undefined;
	}

	if (stateData.buildingAndPest && stateData.buildingAndPest > 0) {
		stateData.buildingAndPestDue = calculateItem(stateData, 'buildingAndPest');
	} else {
		stateData.buildingAndPestDue = undefined;
	}

	if (stateData.otherCondition && stateData.otherCondition > 0) {
		stateData.otherConditionDue = calculateItem(stateData, 'otherCondition');
	} else {
		stateData.otherConditionDue = undefined;
	}

	if (stateData.settlementDate && stateData.settlementDate > 0) {
		stateData.settlementDateDue = calculateItem(stateData, 'settlementDate');
	} else {
		stateData.settlementDateDue = undefined;
	}

	// cooling off
	if (stateData.startingDate) {
		let coolingDate: Date = new Date();
		let noOfDaysToAdd: number = isNsw ? 5 : 4;
		let count: number = 0;
		let startingDate = new Date(stateData.startingDate?.getTime() ?? 0);

		if (startingDate.getDay() === 0 || startingDate.getDay() === 6) {
			noOfDaysToAdd = 5;
		}
		while (count < noOfDaysToAdd) {
			coolingDate = new Date(startingDate.setDate(startingDate.getDate() + 1));
			if (coolingDate.getDay() != 0 && coolingDate.getDay() != 6) {
				count++;
			}
		}

		stateData.coolingOffPeriod = formatDateCalculatorString(coolingDate);
	} else {
		stateData.coolingOffPeriod = undefined;
	}

	return stateData;
}