export const GET_MATTER_DETAILS_FROM_ACTIONSTEP_REQUESTED = "ECafReducer/GET_MATTER_DETAILS_FROM_ACTIONSTEP_REQUESTED";
export const GET_MATTER_DETAILS_FROM_ACTIONSTEP_SUCCESS = "ECafReducer/GET_MATTER_DETAILS_FROM_ACTIONSTEP_SUCCESS";
export const GET_MATTER_DETAILS_FROM_ACTIONSTEP_FAILED = "ECafReducer/GET_MATTER_DETAILS_FROM_ACTIONSTEP_FAILED";

export const CREATE_ORDER_REQUESTED = "ECafReducer/CREATE_ORDER_REQUESTED";
export const CREATE_ORDER_SUCCESS = "ECafReducer/CREATE_ORDER_SUCCESS";
export const CREATE_ORDER_FAILED = "ECafReducer/CREATE_ORDER_FAILED";

export const CHECK_ECAF_CREDENTIAL_REQUESTED = "ECafReducer/CHECK_ECAF_CREDENTIAL_REQUESTED";
export const CHECK_ECAF_CREDENTIAL_SUCCESS = "ECafReducer/CHECK_ECAF_CREDENTIAL_SUCCESS";
export const CHECK_ECAF_CREDENTIAL_FAILED = "ECafReducer/CHECK_ECAF_CREDENTIAL_FAILED";

export const GET_VALID_ECAF_CREDENTIAL_REQUESTED = "ECafReducer/GET_VALID_ECAF_CREDENTIAL_REQUESTED";
export const GET_VALID_ECAF_CREDENTIAL_SUCCESS = "ECafReducer/GET_VALID_ECAF_CREDENTIAL_SUCCESS";
export const GET_VALID_ECAF_CREDENTIAL_FAILED = "ECafReducer/GET_VALID_ECAF_CREDENTIAL_FAILED";

export const GET_ACTIONSTEP_ORGS_REQUESTED = "ECafReducer/GET_ACTIONSTEP_ORGS_REQUESTED";
export const GET_ACTIONSTEP_ORGS_SUCCESS = "ECafReducer/GET_ACTIONSTEP_ORGS_SUCCESS";
export const GET_ACTIONSTEP_ORGS_FAILED = "ECafReducer/GET_ACTIONSTEP_ORGS_FAILED";

export const GET_ECAF_ENVELOPE_STATUS_REQUESTED = "ECafReducer/GET_ECAF_ENVELOPE_STATUS_REQUESTED";
export const GET_ECAF_ENVELOPE_STATUS_SUCCESS = "ECafReducer/GET_ECAF_ENVELOPE_STATUS_SUCCESS";
export const GET_ECAF_ENVELOPE_STATUS_FAILED = "ECafReducer/GET_ECAF_ENVELOPE_STATUS_FAILED";

export const GET_ECAF_ORDER_STATUS_PAGE_REQUESTED = "ECafReducer/GET_ECAF_ORDER_STATUS_PAGE_REQUESTED";
export const GET_ECAF_ORDER_STATUS_PAGE_SUCCESS = "ECafReducer/GET_ECAF_ORDER_STATUS_PAGE_SUCCESS";
export const GET_ECAF_ORDER_STATUS_PAGE_FAILED = "ECafReducer/GET_ECAF_ORDER_STATUS_PAGE_FAILED";

export const UPDATE_VOI_STATUS_REQUESTED = "ECafReducer/UPDATE_VOI_STATUS_REQUESTED";
export const UPDATE_VOI_STATUS_SUCCESS = "ECafReducer/UPDATE_VOI_STATUS_SUCCESS";
export const UPDATE_VOI_STATUS_FAILED = "ECafReducer/UPDATE_VOI_STATUS_FAILED";

export const UPDATE_COUNTER_SIGN_STATUS_REQUESTED = "ECafReducer/UPDATE_COUNTER_SIGN_STATUS_REQUESTED";
export const UPDATE_COUNTER_SIGN_STATUS_SUCCESS = "ECafReducer/UPDATE_COUNTER_SIGN_STATUS_SUCCESS";
export const UPDATE_COUNTER_SIGN_STATUS_FAILED = "ECafReducer/UPDATE_COUNTER_SIGN_STATUS_FAILED";

export const GET_ECAF_ENVELOPE_SUMMARY_REQUESTED = "ECafReducer/GET_ECAF_ENVELOPE_SUMMARY_REQUESTED";
export const GET_ECAF_ENVELOPE_SUMMARY_SUCCESS = "ECafReducer/GET_ECAF_ENVELOPE_SUMMARY_SUCCESS";
export const GET_ECAF_ENVELOPE_SUMMARY_FAILED = "ECafReducer/GET_ECAF_ENVELOPE_SUMMARY_FAILED";

export const GET_ECAF_ORDERLIST_REQUESTED = "ECafReducer/GET_ECAF_ENVELOPE_ORDERLIST_REQUESTED";
export const GET_ECAF_ORDERLIST_SUCCESS = "ECafReducer/GET_ECAF_ENVELOPE_ORDERLIST_SUCCESS";
export const GET_ECAF_ORDERLIST_FAILED = "ECafReducer/GET_ECAF_ENVELOPE_ORDERLIST_FAILED";

export const DELETE_ENVELOPE_REQUESTED = "ECafReducer/DELETE_ENVELOPE_REQUESTED";
export const DELETE_ENVELOPE_SUCCESS = "ECafReducer/DELETE_ENVELOPE_SUCCESS";
export const DELETE_ENVELOPE_FAILED = "ECafReducer/DELETE_ENVELOPE_FAILED";

export const DELETE_CUSTOMER_REQUESTED = "ECafReducer/DELETE_CUSTOMER_REQUESTED";
export const DELETE_CUSTOMER_SUCCESS = "ECafReducer/DELETE_CUSTOMER_SUCCESS";
export const DELETE_CUSTOMER_FAILED = "ECafReducer/DELETE_CUSTOMER_FAILED";

export const EDIT_ENVELOPE_CUSTOMER_REQUESTED = "ECafReducer/EDIT_ENVELOPE_CUSTOMER_REQUESTED";
export const EDIT_ENVELOPE_CUSTOMER_SUCCESS = "ECafReducer/EDIT_ENVELOPE_CUSTOMER_SUCCESS";
export const EDIT_ENVELOPE_CUSTOMER_FAILED = "ECafReducer/EDIT_ENVELOPE_CUSTOMER_FAILED";

export const GET_COUNTRY_PHONE_CODES_REQUESTED = "ECafReducer/GET_COUNTRY_PHONE_CODES_REQUESTED";
export const GET_COUNTRY_PHONE_CODES_SUCCESS = "ECafReducer/GET_COUNTRY_PHONE_CODES_SUCCESS";
export const GET_COUNTRY_PHONE_CODES_FAILED = "ECafReducer/GET_COUNTRY_PHONE_CODES_FAILED";

export const RESEND_CUSTOMER_NOTIFICATION_REQUESTED = "ECafReducer/RESEND_CUSTOMER_NOTIFICATION_REQUESTED";
export const RESEND_CUSTOMER_NOTIFICATION_SUCCESS = "ECafReducer/RESEND_CUSTOMER_NOTIFICATION_SUCCESS";
export const RESEND_CUSTOMER_NOTIFICATION_FAILED = "ECafReducer/RESEND_CUSTOMER_NOTIFICATION_FAILED";
export const RESEND_CUSTOMER_NOTIFICATION_CLEAR_STATE = "ECafReducer/RESEND_CUSTOMER_NOTIFICATION_CLEAR_STATE";

export const GET_REPORT_DATA_REQUESTED = "ECafReducer/GET_REPORT_DATA_REQUESTED";
export const GET_REPORT_DATA_SUCCESS = "ECafReducer/GET_REPORT_DATA_SUCCESS";
export const GET_REPORT_DATA_FAILED = "ECafReducer/GET_REPORT_DATA_FAILED";
export const GET_REPORT_DATA_CLEAR_STATE = "ECafReducer/GET_REPORT_DATA_CLEAR_STATE";