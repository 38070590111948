import { call, put, takeLatest } from 'redux-saga/effects'

import * as CONSTANTS from './constants'
import * as ACTIONS from './actions'

import {
    getRequest, rawPost
} from 'utils/request'

import { LegalAidActionstepDataViewModel, ActionstepProviderList } from '../utils/LegalAidWCAApiTypes';
import { GenerateLegalAidPDF, GetLegalAidMatterDetailsFromActionstep, GetActionstepProviderDetails } from './actionTypes'
import axios from 'utils/axios'

// by default, axios would call the json stringify function that would convert any date into utc date
// however, this will cause issues with the pdf generation where datetime is [x date]-00:00, which when converted into utc would be the previous day
// therefore the date transformer is needed to force preserve that locale date for correct pdf date generation
// the saga.ts file in special meter reading also copy this function
function dateTransformer(data: any): any {
    if (data instanceof Date) {
        // do your specific formatting here
        return data.toLocaleString("en-US");
    }
    if (Array.isArray(data)) {
        return data.map(val => dateTransformer(val))
    }
    if (typeof data === "object" && data !== null) {
        return Object.fromEntries(Object.entries(data).map(([key, val]) =>
            [key, dateTransformer(val)]))
    }
    return data
}
async function postAndSaveFile(url: string, params: any) {
    let response = await rawPost(url, params, { responseType: 'blob', transformRequest: [dateTransformer].concat(axios.defaults.transformRequest ?? []) });
    //const fileName = "Form-16-Waitangi-Tribunal-Invoice.pdf";

    // Let the user save the file.
    saveAs(response.data, params.fileName);
}
export function* getActionstepProviderDetails(action: GetActionstepProviderDetails) {
    try {
        const response = yield call(getRequest, `/api/legalaid/get-actionstep-providers?matterId=${action.matterId!}&orgKey=${action.orgKey}`);

        let data: ActionstepProviderList = ActionstepProviderList.fromJS(response);

        yield put(ACTIONS.getActionstepProviderDetailsSuccess(data));

    } catch (error) {
        yield put(ACTIONS.getActionstepProviderDetailsFailed(error?.response?.data));
    }
}

export function* getMatterDetailsFromActionstep(action: GetLegalAidMatterDetailsFromActionstep) {
    try {
        const response = yield call(getRequest, `/api/legalaid/get-legalaid-matter?matterId=${action.matterId!}&orgKey=${action.orgKey}&dateFrom=${action.dateFrom}&dateTo=${action.dateTo}&leadProvider=${action.leadProvider}&provider2=${action.provider2}&otherProvider=${action.otherProvider}&formType=${action.formType}`);

        let data: LegalAidActionstepDataViewModel = LegalAidActionstepDataViewModel.fromJS(response);

        yield put(ACTIONS.getMatterDetailsFromActionstepSuccess(data));

    } catch (error) {
        yield put(ACTIONS.getMatterDetailsFromActionstepFailed(error?.response?.data));
    }
}
export function* generateLegalAidPdf(action: GenerateLegalAidPDF) {

    try {
        yield call(postAndSaveFile, `/api/legalaid/generate-legalaid-pdf`, action.data);

        yield put(ACTIONS.generateLegalAidPDFSuccess(true));
    } catch (error) {
        yield put(ACTIONS.generateLegalAidPDFFailed(error?.response?.data));
    }
}

export default function* legalAidSaga() {
    yield takeLatest(CONSTANTS.GET_PROVIDER_DETAILS_REQUESTED, getActionstepProviderDetails);
    yield takeLatest(CONSTANTS.GET_MATTER_DETAILS_FROM_ACTIONSTEP_REQUESTED, getMatterDetailsFromActionstep);
    yield takeLatest(CONSTANTS.GENERATE_LEGALAID_PDF_REQUESTED, generateLegalAidPdf);

} 