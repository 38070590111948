import * as React from 'react';
import { connect } from 'react-redux';

import QuestionnaireComponent from 'containers/firsttitle/components/questionnaireComponent';
import ConfirmationComponent from 'containers/firsttitle/components/confirmationComponent';
import PolicySuccess from 'containers/firsttitle/components/policySuccess/policySuccess';

import {
    getFirstTitlePolicyRequestFromActionstep,
    sendDataToFirstTitle,
    firstTitleCredentialsLogout
} from 'containers/firsttitle/redux/actions'

import { AppState, ReduxStatus } from 'app.types';

import {
    ActionstepMatterInfo,
    OrderFirstTitlePolicyCommand,
    OrderFirstTitlePolicyCommandResponse,
    RequestPolicyOptions,
    FTActionstepMatter,
    RiskInformation,
    FTParty,
    IdentityType
} from 'utils/wcaApiTypes';

import Tools from 'utils/tools';
import LoadingWidget from 'components/common/loadingWidget';
import FirstTitleAuth from 'containers/firsttitle/auth/firstTitleAuth';

import './requestPolicy.css';
import { MessageBar, MessageBarType, Stack } from 'office-ui-fabric-react';
import { DefaultStackTokens } from 'konekta-theme';
import { v4 as uuidv4 } from 'uuid';

export interface FTPartyExtended extends FTParty {
    newlyCreatedId?: string;
}

interface FTActionstepMatterExtended extends FTActionstepMatter {
    buyers?: FTPartyExtended[] | undefined;
}

type IProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

enum RequestPolicySteps {
    NotStarted = -1,
    Questionnaire = 0,
    Confirmation = 1,
    Requested = 2,
    Success = 3,
    Failed = 4,
    FirstTitleNotConnected = 5
}

interface IState {
    policyReference: string;
    requestPolicyOptions: RequestPolicyOptions | undefined,
    actionstepMatter: FTActionstepMatterExtended | undefined,
    sendFirstTitlePolicyRequestResponse: OrderFirstTitlePolicyCommandResponse | undefined,
    currentStep: RequestPolicySteps,
    firstTitleConnected: boolean,
    matterInfo: ActionstepMatterInfo | null,
    error: string | undefined,
    loggedOut: boolean
}

class RequestPolicy extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            policyReference: '',
            actionstepMatter: undefined,
            currentStep: RequestPolicySteps.NotStarted,
            firstTitleConnected: false,
            requestPolicyOptions: undefined,
            sendFirstTitlePolicyRequestResponse: undefined,
            matterInfo: null,
            error: undefined,
            loggedOut: false
        };
    }

    public componentDidMount(): void {
        this.requestMatterInformation();
    }

    public reviewForm = () => {
        this.setState({
            currentStep: RequestPolicySteps.Confirmation
        })
    };

    /** Logs the user out of First Title */
    public logoutHandler = () => {
        this.props.firstTitleCredentialsLogout();

        this.setState({
            loggedOut: true
        });
    }

    /** Place order function */
    public placeOrder = () => {
        const { policyReference, requestPolicyOptions, actionstepMatter, matterInfo } = this.state;

        const clonedActionstepMatter = FTActionstepMatter.fromJS(actionstepMatter);

        const typedBuyers: Array<FTParty> = [];

        if(clonedActionstepMatter != null) {
            for (const buyer of (actionstepMatter?.buyers ?? [])) {
                const { newlyCreatedId, ...buyerDeconstructed} = buyer;

                const buyerDeconstructedTyped = FTParty.fromJS(buyerDeconstructed);

                typedBuyers.push(buyerDeconstructedTyped);
            }
        }

        clonedActionstepMatter.buyers = typedBuyers;
       
        const params: OrderFirstTitlePolicyCommand = new OrderFirstTitlePolicyCommand({
            policyReference,
            actionstepMatter: clonedActionstepMatter,
            requestPolicyOptions,
            matterId: matterInfo!.matterId,
            actionstepOrg: matterInfo!.orgKey,
        });

        this.props.sendDataToFirstTitle(params);

        this.setState({
            currentStep: RequestPolicySteps.Requested
        });
    }

    /**
     * Requests the Matter information from Actionstep API
     */
    public requestMatterInformation = () => {
        const { actionstepContext } = this.props;
        let matterInfo: ActionstepMatterInfo | null = null;

        this.setState({
            firstTitleConnected: true,
            currentStep: RequestPolicySteps.NotStarted
        });

        if (actionstepContext === undefined
            || actionstepContext.matterContext === undefined) {
            return;
        } else {
            matterInfo = new ActionstepMatterInfo({
                orgKey: actionstepContext.orgKey,
                matterId: actionstepContext.matterContext.id,
                termsEverAccepted: actionstepContext?.termsEverAccepted ?? false,
                latestTermsAccepted: actionstepContext?.latestTermsAccepted ?? false,
            });
        }

        this.setState({
            matterInfo
        });

        // Setting PolicyReference to matterId by default
        this.setState({
            policyReference: matterInfo.matterId + ""
        })

        /** Sending the request for information about matter information **/
        this.props.getFirstTitlePolicyRequestFromActionstep(matterInfo);
    };

    static getDerivedStateFromProps(nextProps: IProps, prevState: IState): IState {
        if (prevState.loggedOut) window.location.reload();

        let nextState = {} as IState;

        var undefinedSettlementDate: any = undefined;

        if (prevState.firstTitleConnected) {
            if (prevState.currentStep === RequestPolicySteps.NotStarted && nextProps.firstTitlePolicyRequestFromActionstepResponse) {
                if (nextProps.firstTitlePolicyRequestFromActionstepResponse.status === ReduxStatus.Success) {
                    nextState.currentStep = RequestPolicySteps.Questionnaire;

                    nextState.actionstepMatter = FTActionstepMatter.fromJS(nextProps.firstTitlePolicyRequestFromActionstepResponse.data!.actionstepData);

                    if (nextProps.firstTitlePolicyRequestFromActionstepResponse.data!.actionstepData?.settlementDate) {
                        if (nextProps.firstTitlePolicyRequestFromActionstepResponse.data!.actionstepData?.settlementDate.getFullYear() < 100) {
                            nextState.actionstepMatter.settlementDate = undefinedSettlementDate;
                        } else {
                            let settlementDate = nextProps.firstTitlePolicyRequestFromActionstepResponse.data!.actionstepData?.settlementDate;

                            let currentLocalDate = new Date();

                            let date = new Date(settlementDate.getFullYear(), settlementDate.getMonth(), settlementDate.getDate(), currentLocalDate.getHours(), currentLocalDate.getMinutes(), currentLocalDate.getSeconds()); // fix the issue for the backend auto change GMT +9:30

                            let dateUTC = new Date(Date.UTC(settlementDate.getFullYear(), settlementDate.getMonth(), settlementDate.getDate(), 0, 0, 0)); // fix the issue for the backend auto change GMT +9:30 
                            nextState.actionstepMatter.settlementDate = date;
                            nextState.actionstepMatter.settlementDateNoUTC = dateUTC;
                        }
                    }

                    // Perform checking for additional fields which may not have been binded properly
                    // Floor no. checking for SourceProperty
                    if (nextState.actionstepMatter.sourceProperty && nextState.actionstepMatter.sourceProperty?.unitNo == null) {
                        if (nextState.actionstepMatter.sourceProperty.addressLine1 && nextState.actionstepMatter.sourceProperty.addressLine1.includes("/"))
                            nextState.actionstepMatter.sourceProperty.unitNo = nextState.actionstepMatter.sourceProperty.addressLine1.split("/")[0];
                    }

                    // Convert Source Property State to Upper Case if provided
                    if (nextState.actionstepMatter.sourceProperty != null && nextState.actionstepMatter.sourceProperty.stateProvince) {
                        nextState.actionstepMatter.sourceProperty.stateProvince = nextState.actionstepMatter.sourceProperty.stateProvince.toUpperCase();
                    }

                    if (nextState && nextState.actionstepMatter && nextState.actionstepMatter.buyers && nextState.actionstepMatter.buyers.length > 0) {
                        nextState.actionstepMatter.buyers.forEach(buyer => {
                            // Floor no. checking for Party (Buyer)
                            if (buyer != null && buyer.unitNo == null) {
                                if (buyer.addressLine1 && buyer.addressLine1.includes("/"))
                                    buyer.unitNo = buyer.addressLine1.split("/")[0];
                            }

                            // Converting State to uppercase (just incase it's not in uppercase - so validator can catch it)
                            if (buyer != null && buyer.stateProvince != null)
                                buyer.stateProvince = buyer.stateProvince.toUpperCase();
                        })
                    }

                    nextState.requestPolicyOptions = RequestPolicyOptions.fromJS(nextProps.firstTitlePolicyRequestFromActionstepResponse.data!.requestPolicyOptions);

                    nextState.requestPolicyOptions.riskInformation = nextState.requestPolicyOptions.riskInformation || new RiskInformation();

                } else if (nextProps.firstTitlePolicyRequestFromActionstepResponse.status === ReduxStatus.Failed) {
                    nextState.currentStep = RequestPolicySteps.Failed;
                    nextState.error = nextProps.firstTitlePolicyRequestFromActionstepResponse.error!.message;

                    if (!nextProps.firstTitleConnected) {
                        nextState.currentStep = RequestPolicySteps.FirstTitleNotConnected;

                        nextState.firstTitleConnected = false;
                    }
                }
            }

            if (prevState.currentStep === RequestPolicySteps.Requested && nextProps.sendFirstTitlePolicyRequestResponse) {
                if (nextProps.sendFirstTitlePolicyRequestResponse.status === ReduxStatus.Success) {
                    nextState.currentStep = RequestPolicySteps.Success;
                    nextState.sendFirstTitlePolicyRequestResponse = nextProps.sendFirstTitlePolicyRequestResponse.data!;
                } else if (nextProps.sendFirstTitlePolicyRequestResponse.status === ReduxStatus.Failed) {
                    nextState.currentStep = RequestPolicySteps.Failed;
                    nextState.error = nextProps.sendFirstTitlePolicyRequestResponse.error!.message;

                    if (!nextProps.firstTitleConnected) {
                        nextState.currentStep = RequestPolicySteps.FirstTitleNotConnected;

                        nextState.firstTitleConnected = false;
                    }
                }
            }
        }

        return nextState;
    }

    backToForm = () => {
        this.setState({
            currentStep: RequestPolicySteps.Questionnaire
        })
    };

    setKnownRiskPolicyProperty = (propName: string, propVal: any | undefined) => {
        let newRequestPolicyOptions: RequestPolicyOptions = RequestPolicyOptions.fromJS({ ...this.state.requestPolicyOptions });

        (newRequestPolicyOptions.riskInformation! as any)[propName] = propVal;

        this.setState({
            requestPolicyOptions: newRequestPolicyOptions
        });
    };

    getNewRequestPolicyOptions = (passedRequestPolicyOptions: Map<string, any | undefined>) => {
        let newRequestPolicyOptions: RequestPolicyOptions = RequestPolicyOptions.fromJS({ ...this.state.requestPolicyOptions });

        for (let [field, value] of Array.from(passedRequestPolicyOptions.entries())) {
            (newRequestPolicyOptions as any)[field] = value;
        }

        return newRequestPolicyOptions;
    }


    getNewMatterInformation = (keyPath: string, newValue: any) => {
        let newActionstepMatter = this.state.actionstepMatter;

        if (typeof newValue === "string" && newValue === "") newValue = null;

        Tools.assign(newActionstepMatter, keyPath, newValue);

        return newActionstepMatter;
    };

    setRequestPolicyOptions = (propName: string, propVal: boolean) => {
        let newRequestPolicyOptions = new RequestPolicyOptions(this.state.requestPolicyOptions);

        (newRequestPolicyOptions as any)[propName] = propVal;

        this.setState({
            requestPolicyOptions: newRequestPolicyOptions
        });
    };

    setMatterInformation = (keyPath: string, newValue: any) => {
        let newActionstepMatter = this.state.actionstepMatter;

        if (typeof newValue === "string" && newValue === "") newValue = null;

        Tools.assign(newActionstepMatter, keyPath, newValue);

        this.setState({
            actionstepMatter: newActionstepMatter
        });
    };

    setPolicyReference = (value: string) => {
        if (typeof value === "string") {
            this.setState({
                policyReference: value
            });
        }
    }

    setIsCommercial = (value: boolean) => {
        let passedRequestPolicyOptions: Map<string, any> = new Map();
        passedRequestPolicyOptions.set("isCommercialPolicy", value);
        passedRequestPolicyOptions.set("requestKnownRiskPolicies", false);
        const newRequestPolicyOptions = this.getNewRequestPolicyOptions(passedRequestPolicyOptions);
        const newActionstepMatter = this.getNewMatterInformation("isStrataOrCommunityTitle", false);

        this.setState({
            requestPolicyOptions: newRequestPolicyOptions,
            actionstepMatter: newActionstepMatter
        })
    }

    createNewBuyer = (buyerType: IdentityType) => {
        const clonnedActionstepMatter = { ...this.state.actionstepMatter } as FTActionstepMatterExtended;
        const id = uuidv4();

        const newBuyer2 = {
            name: null,
            identityType: buyerType,
            title: null,
            firstName: null,
            middleName: null,
            lastName: null,
            suffix: null,
            preferredName: null,
            companyName: "",
            abn: null,
            acn: null,
            abrn: null,
            emailAddress: null,
            addressLine1: "",
            addressLine2: null,
            city: null,
            country: null,
            postCode: null,
            stateProvince: null,
            streetNo: null,
            streetType: null,
            streetName: null,
            buildingName: null,
            floorNo: null,
            unitNo: null,
            newlyCreatedId: null
        } as unknown as FTPartyExtended;

        newBuyer2.newlyCreatedId = id;

        clonnedActionstepMatter?.buyers?.push(newBuyer2);

        this.setState({
            actionstepMatter: clonnedActionstepMatter
        })
    }

    removeBuyer = (id: string) => {
        const clonnedActionstepMatter = { ...this.state.actionstepMatter } as FTActionstepMatterExtended;
        const clonnedBuyers = clonnedActionstepMatter.buyers;

        const index = clonnedBuyers?.findIndex(el => el.newlyCreatedId != null && el.newlyCreatedId == id);

        if(index != null) {
            clonnedBuyers?.splice(index, 1);
        }

        clonnedActionstepMatter.buyers = clonnedBuyers;

        this.setState({
            actionstepMatter: clonnedActionstepMatter
        })
    }

    render(): JSX.Element {
        const { currentStep, actionstepMatter, requestPolicyOptions, sendFirstTitlePolicyRequestResponse, error } = this.state;

        if (currentStep === RequestPolicySteps.NotStarted) {
            return (
                <div className="ft-container">
                    <LoadingWidget />
                </div>
            );
        }

        if (currentStep === RequestPolicySteps.Requested) {
            return (
                <div className="ft-container">
                    <LoadingWidget message="Sending your order to First Title and waiting for their response..." />
                </div>
            );
        }

        return (
            <div className="wrapper vertical-container wrapper-content animated fadeIn ft-container" dir="ltr">
                <Stack tokens={DefaultStackTokens}>
                    {currentStep === RequestPolicySteps.FirstTitleNotConnected &&
                        <FirstTitleAuth onConnection={this.requestMatterInformation} />
                    }

                    {currentStep === RequestPolicySteps.Failed &&
                        <MessageBar messageBarType={MessageBarType.error}>
                                {error ? error : "Unexpected error occured!"}
                        </MessageBar>
                    }

                    {(currentStep === RequestPolicySteps.Questionnaire || currentStep === RequestPolicySteps.Failed) &&
                        <QuestionnaireComponent
                            policyReference={this.state.policyReference}
                            setPolicyReference={this.setPolicyReference}
                            requestPolicyOptions={requestPolicyOptions!}
                            actionstepMatter={actionstepMatter!}
                            reviewForm={this.reviewForm}
                            logoutHandler={this.logoutHandler}
                            placeOrder={this.placeOrder}
                            setKnownRiskPolicyProperty={this.setKnownRiskPolicyProperty}
                            setRequestPolicyOptions={this.setRequestPolicyOptions}
                            setMatterInformation={this.setMatterInformation}
                            setIsCommercial={this.setIsCommercial}
                            isCommercial={this.state.requestPolicyOptions?.isCommercialPolicy ?? false}
                            createNewBuyer={this.createNewBuyer}
                            removeBuyer={this.removeBuyer}
                        />
                    }

                    {currentStep === RequestPolicySteps.Success &&
                        <PolicySuccess
                            sendFirstTitlePolicyRequestResponse={sendFirstTitlePolicyRequestResponse!}
                        />
                    }

                    {currentStep === RequestPolicySteps.Confirmation &&
                        <ConfirmationComponent
                            requestPolicyOptions={requestPolicyOptions!}
                            actionstepMatter={actionstepMatter!}
                            backOnClick={this.backToForm}
                            placeOrder={this.placeOrder}
                        />
                    }

                </Stack>
            </div>
        )
    }
}

const mapStateToProps = (state: AppState) => {
    return {
        firstTitlePolicyRequestFromActionstepResponse: state.firstTitle.firstTitlePolicyRequestFromActionstepResponse,
        isValidCredentials: state.firstTitle.isValidCredentials,
        sendFirstTitlePolicyRequestResponse: state.firstTitle.sendFirstTitlePolicyRequestResponse,
        actionstepContext: state.common.actionstepContext,
        firstTitleConnected: state.common.firstTitleConnected
    }
}

const mapDispatchToProps = {
    getFirstTitlePolicyRequestFromActionstep,
    sendDataToFirstTitle,
    firstTitleCredentialsLogout,
}

export default connect(mapStateToProps, mapDispatchToProps)(RequestPolicy);