import * as React from 'react'

import {
    NormalPeoplePicker,
    IPersonaProps,
    IBasePickerSuggestionsProps,
    Spinner,
    SpinnerSize,
} from 'office-ui-fabric-react';

import { useSelector } from 'react-redux';
import { FunctionComponent, useState, useEffect } from 'react';
import ErrorMessageWidget from './errorMessageWidget';
import { IUser } from '../../utils/wcaApiTypes';
import { AppState } from '../../app.types';
import useAxios from 'axios-hooks';

interface IProps {
    onChange?: (items?: IUser[]) => void;
    itemLimit?: number;
    value?: IUser[] | IUser;
}

const userToPersona = (user: IUser): IPersonaProps => {
    if (!user) return {};
    const firstInitial = user.firstName && user.firstName.length > 0 ? user.firstName[0].toUpperCase() : '';
    const secondInitial = user.lastName && user.lastName.length > 0 ? user.lastName[0].toUpperCase() : '';
    return {
        id: user.id,
        imageInitials: firstInitial + secondInitial,
        text: `${user.firstName} ${user.lastName} <${user.email}>`,
        unselectable: "on",
    }
}

const ConnectedActionstepUserSelector: FunctionComponent<IProps> = (props) => {
    const [selectedPersonas, setSelectedPersonas] = useState<IPersonaProps[]>([]);
    const orgKey = useSelector((state: AppState) => state.common.actionstepContext?.orgKey);
    const [{ data: allConnectedUsers, loading, error: connectedUsersError }] = useAxios<IUser[] | undefined>(
        `/api/integrations/actionstep/${orgKey}/connected-users`
    );

    const peopleList: IPersonaProps[] = allConnectedUsers ? allConnectedUsers.map<IPersonaProps>(u => userToPersona(u)) : [];
    const {
        value: rawSelectedUsers,
        onChange,
        itemLimit = 1,
    } = props;

    const selectedUsers = Array.isArray(rawSelectedUsers) ? rawSelectedUsers : [rawSelectedUsers];

    // Sync props selected users to state
    useEffect(() => {
        if (selectedUsers && allConnectedUsers) {
            const usersAsPersonas: IPersonaProps[] = allConnectedUsers
                .filter(c => selectedUsers!.some(e => e?.id === c.id))
                .map(u => userToPersona(u));
            setSelectedPersonas(usersAsPersonas);
        } else {
            setSelectedPersonas([]);
        }
    }, [selectedUsers, setSelectedPersonas, allConnectedUsers]);

    const onChangeInternal = (selectedItems?: IPersonaProps[]): void => {
        setSelectedPersonas(selectedItems ?? []);

        if (onChange && allConnectedUsers) {
            if (selectedItems) {
                onChange(allConnectedUsers.filter(u => selectedItems.some(p => p.id === u.id)));
            } else {
                onChange();
            }
        }
    };

    const onFilterChanged = (filter: string, selectedItems?: IPersonaProps[]): IPersonaProps[] | PromiseLike<IPersonaProps[]> => {
        if (filter) {
            return filterPersonasByText(filter);
        } else {
            return [];
        }
    };

    const onEmptyInputFocus = (selectedItems?: IPersonaProps[]): IPersonaProps[] | PromiseLike<IPersonaProps[]> => {
        return peopleList;
    };

    const filterPersonasByText = (filterText: string): IPersonaProps[] => {
        return peopleList.filter(item => doesTextStartWith(item.text as string, filterText));
    };

    function doesTextStartWith(text: string, filterText: string): boolean {
        return text.toLowerCase().indexOf(filterText.toLowerCase()) === 0;
    }

    const suggestionProps: IBasePickerSuggestionsProps = {
        suggestionsHeaderText: 'Suggested Connections',
        mostRecentlyUsedHeaderText: 'Suggested Contacts',
        noResultsFoundText: 'No results found',
        loadingText: 'Loading',
        showRemoveButtons: false,
        suggestionsAvailableAlertText: 'People Picker Suggestions available',
        suggestionsContainerAriaLabel: 'Suggested contacts',
    };

    if (connectedUsersError?.message) return (<ErrorMessageWidget message={connectedUsersError?.message} />);

    return (
        <>
            <NormalPeoplePicker
                onResolveSuggestions={onFilterChanged}
                onEmptyInputFocus={onEmptyInputFocus}
                itemLimit={itemLimit}
                onChange={onChangeInternal}
                selectedItems={selectedPersonas}
                pickerSuggestionsProps={suggestionProps}
            />
            {loading && <Spinner size={SpinnerSize.small} />}
        </>
    )
}

export default ConnectedActionstepUserSelector;