import * as CONSTANTS from './constants'
import {
    PEXAWorkspaceCreationRequestWithActionstepResponse,
    CreatePexaWorkspaceCommand,
    ErrorViewModel,
    LandTitleReferenceVerificationResponseType,
    WorkspaceSummaryResponseType,
    CreateWorkspaceInvitationResponseType,
    RetrieveSettlementAvailabilityResponseType,
    CreatePexaWorkspaceResponse,
    PexaWorkspaceInfo
} from 'utils/wcaApiTypes';

import {
    PexaActionTypes,
    SetPexaWorkspaceCreation,
    GetDataFromActionstepSuccess,
    SendDataToPexaSuccess,
    ActionFailed,
    WorkspaceCreationRequestWithMatterInfo,
    SavePexaFormData,
    ValidateLandTitleSuccess,
    WorkspaceSummaryList,
    GetPexaWorkspaceSummary,
    GetPexaWorkspaceSummaryFailed,
    GetPexaWorkspaceSummarySuccess,
    CreateInvitationSuccess,
    AvailableSettlementTimes,
    GetAvailableSettlementTimes,
    GetAvailableSettlementTimesSuccess,
    GetAvailableSettlementTimesFailed,
    GetPexaWorkspaceInformationFromKonektaSuccess,
    GetPexaWorkspaceInformationFromKonektaFailed,
    MaintainSettlementDateTimeFailed,
    UpdateSettlementDateTimeActionstepFailed,
    GetInvitationUriSuccess,
    GetInvitationUriFailed,
    SetSettlementDateTimeData,
    CreatePexaKeySuccess,
    CreatePexaKeyFailed,
    GetPexaKeySuccess,
    GetPexaKeyFailed,
    GetPexaKeyIviteInformationFromKonektaFailed,
    GetPexaKeyIviteInformationFromKonektaSuccess,
    CopyLodgedDocumentsToActionStepSuccess,
    CopyLodgedDocumentsToActionStepFailed,

    GetActionstepPropertyDetailsSuccess,
    GetActionstepPropertyDetailsFailed,
    UpdateDealingNumberActionstepFailed,
    UpdateDealingNumberActionstepSuccess,
    GetFSSLineItemsSuccess,
    GetFSSLineItemsFailed,
    SetFSSLineItemsFailed,
    SetFSSLineItemsSuccess


} from 'containers/pexa/redux/actionTypes';
import { ReduxData, ReduxStatus } from 'app.types';
import { UpdateSettlementDateTimeData, CreatePexaKeyInviteResponse, CopyDocumentsToActionStepResponse, DealingNumberViewModel } from '../../../utils/newWcaApiTypes';
import { ActionstepFinancialLineItems } from '../pexaApiTypes';

export interface PexaState {
    gotResponse: boolean,
    success: boolean,
    pexaWorkspaceCreationData: PEXAWorkspaceCreationRequestWithActionstepResponse,
    createPexaWorkspaceResponse: CreatePexaWorkspaceResponse | undefined,
    availableSettlementTimes: AvailableSettlementTimes,
    validateLandTitleResponse: LandTitleReferenceVerificationResponseType | undefined,
    error: ErrorViewModel | undefined,
    requestType: string,
    pexaFormData: WorkspaceCreationRequestWithMatterInfo | undefined,
    workspaceSummaryList: WorkspaceSummaryList,
    invitationResponseList: ReduxData<CreateWorkspaceInvitationResponseType[]> | undefined,
    pexaWorkspaceInfoFromKonekta: PexaWorkspaceInfo | undefined,

    /* States for Maintain Update Settlement Date Time */
    dealingNumberUpdateMessage: string | undefined,
    actionstepSuccess: boolean,
    gotResponseActionstep: boolean,
    actionstepError: ErrorViewModel | undefined,
    updateSettlementDateTimeData: UpdateSettlementDateTimeData | undefined,

    /* States for invite participants integration link */
    invitationUri: string,

    /* create pexa key response status  */
    createPexaKeyInviteResponse: CreatePexaKeyInviteResponse | undefined,

    /* get pexa key status*/
    pexaKeyInviteStatus: string | undefined,

    /* check pexa key status*/
    checkKeyInviteStatus: string | undefined,
    /* get the status of the lodged documents copied */
    lodgedDocuments: CopyDocumentsToActionStepResponse | undefined,

    /* get actionstep propertydetails*/
    actionstepPropertyDetails: DealingNumberViewModel[] | undefined

    /*FSS menu items*/
    fssLineItems: ActionstepFinancialLineItems[] | undefined
    fssLineItemsUpdate: string | undefined;

}

const initialState: PexaState = {
    gotResponse: false,
    success: false,
    pexaWorkspaceCreationData: new PEXAWorkspaceCreationRequestWithActionstepResponse(),
    createPexaWorkspaceResponse: undefined,
    availableSettlementTimes: {} as AvailableSettlementTimes,
    validateLandTitleResponse: undefined,
    error: undefined,
    requestType: "",
    pexaFormData: undefined,
    workspaceSummaryList: {} as WorkspaceSummaryList,
    invitationResponseList: undefined,
    pexaWorkspaceInfoFromKonekta: undefined,

    /* States for Maintain Update Settlement Date Time */
    actionstepSuccess: false,
    dealingNumberUpdateMessage: undefined,
    actionstepError: undefined,
    updateSettlementDateTimeData: undefined,
    gotResponseActionstep: false,
    invitationUri: "",
    createPexaKeyInviteResponse: undefined,
    pexaKeyInviteStatus: "",
    checkKeyInviteStatus: "",
    lodgedDocuments: undefined,

    actionstepPropertyDetails: undefined,
    fssLineItems: undefined,
    fssLineItemsUpdate: undefined
}

function pexaReducer(state: PexaState = initialState, action: PexaActionTypes): PexaState {

    switch (action.type) {
        case CONSTANTS.GET_DATA_FROM_ACTIONSTEP_REQUESTED:
        case CONSTANTS.VALIDATE_LAND_TITLE_REQUESTED:
            return {
                ...state,
                requestType: action.type
            };

        case CONSTANTS.SEND_DATA_TO_PEXA_REQUESTED:
            return {
                ...state,
                createPexaWorkspaceResponse: undefined,
                requestType: action.type
            };

        case CONSTANTS.GET_PEXA_WORKSPACE_SUMMARY_REQUESTED:
            return {
                ...state,
                requestType: action.type,
                workspaceSummaryList: {
                    ...state.workspaceSummaryList,
                    [(action as GetPexaWorkspaceSummary).params.workspaceId!]: new ReduxData<WorkspaceSummaryResponseType>(ReduxStatus.Requested)
                }
            };

        case CONSTANTS.GET_AVAILABLE_SETTLEMENT_TIMES_REQUESETD:
            const settlementTimesParams = (action as GetAvailableSettlementTimes).params;
            return {
                ...state,
                availableSettlementTimes: {
                    ...state.availableSettlementTimes,
                    [settlementTimesParams.jurisdiction!]: {
                        ...state.availableSettlementTimes[settlementTimesParams.jurisdiction!],
                        [settlementTimesParams.settlementDate!]: new ReduxData<RetrieveSettlementAvailabilityResponseType>(ReduxStatus.Requested)
                    }
                }
            };

        case CONSTANTS.CREATE_INVITATION_REQUESTED:
            return {
                ...state,
                invitationResponseList: new ReduxData<CreateWorkspaceInvitationResponseType[]>(ReduxStatus.Requested)
            };

        case CONSTANTS.SET_PEXA_WORKSPACE_CREATION_REQUEST:
            return {
                ...state,
                pexaWorkspaceCreationData: new PEXAWorkspaceCreationRequestWithActionstepResponse({
                    ...state.pexaWorkspaceCreationData,
                    createPexaWorkspaceCommand: new CreatePexaWorkspaceCommand({
                        ...state.pexaWorkspaceCreationData.createPexaWorkspaceCommand!,
                        pexaWorkspaceCreationRequest: (action as SetPexaWorkspaceCreation).data
                    })
                })
            };

        case CONSTANTS.GET_DATA_FROM_ACTIONSTEP_SUCCESS:
            return {
                ...state,
                gotResponse: true,
                success: true,
                pexaWorkspaceCreationData: (action as GetDataFromActionstepSuccess).data
            };

        case CONSTANTS.VALIDATE_LAND_TITLE_SUCCESS:
            return {
                ...state,
                gotResponse: true,
                success: true,
                validateLandTitleResponse: (action as ValidateLandTitleSuccess).data
            }

        case CONSTANTS.SEND_DATA_TO_PEXA_SUCCESS:
            return {
                ...state,
                gotResponse: true,
                success: true,
                createPexaWorkspaceResponse: (action as SendDataToPexaSuccess).data
            };

        case CONSTANTS.GET_PEXA_WORKSPACE_SUMMARY_SUCCESS:
            const getPexaWorkspaceSummarySuccessAction = action as GetPexaWorkspaceSummarySuccess;
            return {
                ...state,
                gotResponse: true,
                success: true,
                workspaceSummaryList: {
                    ...state.workspaceSummaryList,
                    [getPexaWorkspaceSummarySuccessAction.workspaceId]: new ReduxData<WorkspaceSummaryResponseType>().markAsSuccess(getPexaWorkspaceSummarySuccessAction.data)
                }
            };

        case CONSTANTS.GET_AVAILABLE_SETTLEMENT_TIMES_SUCCESS:
            const availableTimesResponse = (action as GetAvailableSettlementTimesSuccess).data;
            return {
                ...state,
                availableSettlementTimes: {
                    ...state.availableSettlementTimes,
                    [(action as GetAvailableSettlementTimesSuccess).jurisdiction]: {
                        ...state.availableSettlementTimes[(action as GetAvailableSettlementTimesSuccess).jurisdiction],
                        [(action as GetAvailableSettlementTimesSuccess).settlementDate]: new ReduxData<RetrieveSettlementAvailabilityResponseType>().markAsSuccess(availableTimesResponse)
                    }
                }
            };

        case CONSTANTS.CREATE_INVITATION_SUCCESS:
            return {
                ...state,
                invitationResponseList: new ReduxData<CreateWorkspaceInvitationResponseType[]>().markAsSuccess((action as CreateInvitationSuccess).data)
            };

        case CONSTANTS.GET_DATA_FROM_ACTIONSTEP_FAILED:
        case CONSTANTS.VALIDATE_LAND_TITLE_FAILED:
        case CONSTANTS.SEND_DATA_TO_PEXA_FAILED:
            return {
                ...state,
                gotResponse: true,
                success: false,
                error: (action as ActionFailed).error
            };

        case CONSTANTS.GET_PEXA_WORKSPACE_SUMMARY_FAILED:
            const getPexaWorkspaceSummaryFailedAction = action as GetPexaWorkspaceSummaryFailed;
            return {
                ...state,
                gotResponse: true,
                success: false,
                error: getPexaWorkspaceSummaryFailedAction.error,
                workspaceSummaryList: {
                    ...state.workspaceSummaryList,
                    [getPexaWorkspaceSummaryFailedAction.workspaceId]: new ReduxData<WorkspaceSummaryResponseType>().markAsFailed(getPexaWorkspaceSummaryFailedAction.error)
                }
            };

        case CONSTANTS.GET_AVAILABLE_SETTLEMENT_TIMES_FAILED:
            return {
                ...state,
                availableSettlementTimes: {
                    ...state.availableSettlementTimes,
                    [(action as GetAvailableSettlementTimesFailed).jurisdiction]: {
                        ...state.availableSettlementTimes[(action as GetAvailableSettlementTimesFailed).jurisdiction],
                        [(action as GetAvailableSettlementTimesFailed).settlementDate]: new ReduxData<RetrieveSettlementAvailabilityResponseType>().markAsFailed((action as GetAvailableSettlementTimesFailed).error)
                    }
                }
            };

        case CONSTANTS.CREATE_INVITATION_FAILED:
            return {
                ...state,
                invitationResponseList: new ReduxData<CreateWorkspaceInvitationResponseType[]>().markAsFailed((action as ActionFailed).error)
            };

        case CONSTANTS.SAVE_PEXA_FORM_DATA:
            return {
                ...state,
                pexaFormData: (action as SavePexaFormData).pexaFormData
            };

        case CONSTANTS.CLEAR_PEXA_FORM_DATA:
            return {
                ...state,
                pexaFormData: undefined
            };

        case CONSTANTS.CLEAR_PEXA_STATE:
            return {
                ...state,
                gotResponse: false,
                success: false,
                requestType: ""
            };

        case CONSTANTS.CLEAR_PEXA_STATE_AND_ERROR:
            return {
                ...state,
                gotResponse: false,
                error: undefined,
                success: false,
                requestType: ""
            };

        case CONSTANTS.GET_PEXA_WORKSPACE_INFORMATION_FROM_KONEKTA_REQUESTED:
            return {
                ...state,
                requestType: action.type
            };

        case CONSTANTS.GET_PEXA_WORKSPACE_INFORMATION_FROM_KONEKTA_SUCCESS:
            return {
                ...state,
                pexaWorkspaceInfoFromKonekta: (action as GetPexaWorkspaceInformationFromKonektaSuccess).data
            };


        case CONSTANTS.GET_PEXA_WORKSPACE_INFORMATION_FROM_KONEKTA_FAILED:
            return {
                ...state,
                gotResponse: true,
                success: false,
                error: (action as GetPexaWorkspaceInformationFromKonektaFailed).error
            };


        case CONSTANTS.MAINTAIN_SETTLEMENT_DATE_TIME_REQUESTED:
            return {
                ...state,
                requestType: action.type
            };

        case CONSTANTS.MAINTAIN_SETTLEMENT_DATE_TIME_SUCCESS:
            return {
                ...state,
                gotResponse: true,
                success: true
            };


        case CONSTANTS.MAINTAIN_SETTLEMENT_DATE_TIME_FAILED:
            return {
                ...state,
                gotResponse: true,
                success: false,
                error: (action as MaintainSettlementDateTimeFailed).error
            };

        case CONSTANTS.UPDATE_SETTLEMENT_DATE_TIME_ACTIONSTEP_REQUESTED:
            return {
                ...state,
                requestType: action.type
            };

        case CONSTANTS.UPDATE_SETTLEMENT_DATE_TIME_ACTIONSTEP_SUCCESS:
            return {
                ...state,
                gotResponseActionstep: true,
                actionstepSuccess: true
            };

        case CONSTANTS.UPDATE_SETTLEMENT_DATE_TIME_ACTIONSTEP_FAILED:
            return {
                ...state,
                gotResponseActionstep: true,
                actionstepSuccess: false,
                actionstepError: (action as UpdateSettlementDateTimeActionstepFailed).error
            };

        case CONSTANTS.GET_INVITATION_URI_REQUESTED:
            return {
                ...state,
                invitationUri: "",
                requestType: action.type
            };

        case CONSTANTS.GET_INVITATION_URI_SUCCESS:
            return {
                ...state,
                invitationUri: (action as GetInvitationUriSuccess).data,
                gotResponse: true,
                success: true
            };

        case CONSTANTS.GET_INVITATION_URI_FAILED:
            return {
                ...state,
                gotResponse: true,
                success: false,
                error: (action as GetInvitationUriFailed).error
            };

        case CONSTANTS.SET_SETTLEMENT_DATE_TIME_DATA_REQUEST:
            return {
                ...state,
                updateSettlementDateTimeData: (action as SetSettlementDateTimeData).data
            };

        case CONSTANTS.CLEAR_STATES_FOR_SETTLEMENT_DATE_TIME:
            return {
                ...state,
                gotResponse: false,
                success: false,
                error: undefined,
                actionstepSuccess: false,
                actionstepError: undefined,
                gotResponseActionstep: false,
                requestType: ""
            };
        case CONSTANTS.CREATE_PEXA_KEY_REQUESTED:
        case CONSTANTS.CREATE_PEXA_KEY_SUCCESS:
            return {
                ...state,
                requestType: action.type,
                gotResponse: true,
                createPexaKeyInviteResponse: (action as CreatePexaKeySuccess).data
            }
        case CONSTANTS.CREATE_PEXA_KEY_FAILED:
            return {
                ...state,
                requestType: action.type,
                gotResponse: true,
                success: false,
                error: (action as CreatePexaKeyFailed).error
            }
        case CONSTANTS.GET_PEXA_KEY_REQUESTED:
        case CONSTANTS.GET_PEXA_KEY_SUCCESS:
            return {
                ...state,
                requestType: action.type,
                gotResponse: true,
                pexaKeyInviteStatus: (action as GetPexaKeySuccess).data
            }
        case CONSTANTS.GET_PEXA_KEY_FAILED:
            return {
                ...state,
                gotResponse: true,
                success: false,
                error: (action as GetPexaKeyFailed).error
            };
        case CONSTANTS.GET_PEXA_KEY_INVITE_FROM_KONEKTA_REQUESTED:
        case CONSTANTS.GET_PEXA_KEY_INVITE_FROM_KONEKTA_SUCCESS:
            return {
                ...state,
                requestType: action.type,
                gotResponse: true,
                checkKeyInviteStatus: (action as GetPexaKeyIviteInformationFromKonektaSuccess).data
            }
        case CONSTANTS.GET_PEXA_WORKSPACE_INFORMATION_FROM_KONEKTA_FAILED:
            return {
                ...state,
                gotResponse: true,
                success: false,
                error: (action as GetPexaKeyIviteInformationFromKonektaFailed).error
            };
        case CONSTANTS.COPY_LODGED_DOCUMENTS_TO_ACTIONSTEP_REQUESTED:
        case CONSTANTS.COPY_LODGED_DOCUMENTS_TO_ACTIONSTEP_SUCCESS:
            return {
                ...state,
                requestType: action.type,
                gotResponse: true,
                lodgedDocuments: (action as CopyLodgedDocumentsToActionStepSuccess).data
            }
        case CONSTANTS.COPY_LODGED_DOCUMENTS_TO_ACTIONSTEP_FAILED:
            return {
                ...state,
                requestType: action.type,
                gotResponse: true,
                success: false,
                error: (action as CopyLodgedDocumentsToActionStepFailed).error
            };

        case CONSTANTS.GET_ACTIONSTEP_PROPERTY_DETAILS_REQUESTED:
            return {
                ...state,
                requestType: action.type,
                gotResponse: true,
                success: false
            }
        case CONSTANTS.GET_ACTIONSTEP_PROPERTY_DETAILS_SUCCESS:
            return {
                ...state,
                requestType: action.type,
                gotResponse: true,
                success: true,
                actionstepPropertyDetails: (action as GetActionstepPropertyDetailsSuccess).data
            }
        case CONSTANTS.GET_ACTIONSTEP_PROPERTY_DETAILS_FAILED:
            return {
                ...state,
                requestType: action.type,
                gotResponse: true,
                success: false,
                error: (action as GetActionstepPropertyDetailsFailed).error
            };
        case CONSTANTS.UPDATE_DEALING_NUMBER_ACTIONSTEP_REQUESTED:
            return {
                ...state,
                requestType: action.type,
                gotResponse: true,
                success: false
            };

        case CONSTANTS.UPDATE_DEALING_NUMBER_ACTIONSTEP_SUCCESS:
            return {
                ...state,
                requestType: action.type,
                gotResponseActionstep: true,
                dealingNumberUpdateMessage: (action as UpdateDealingNumberActionstepSuccess).data
            };

        case CONSTANTS.UPDATE_DEALING_NUMBER_ACTIONSTEP_FAILED:
            return {
                ...state,
                requestType: action.type,
                gotResponseActionstep: true,
                actionstepError: (action as UpdateDealingNumberActionstepFailed).error
            };

        case CONSTANTS.SAVE_PEXA_AUDIT_REQUESTED:
        default:
            return state;
        case CONSTANTS.GET_FSS_LINEITEMS_REQUESTED:
        case CONSTANTS.GET_FSS_LINEITEMS_SUCCESS:
            return {
                ...state,
                requestType: action.type,
                gotResponse: true,
                fssLineItems: (action as GetFSSLineItemsSuccess).data
            }
        case CONSTANTS.GET_FSS_LINEITEMS_FAILED:
            return {
                ...state,
                gotResponse: true,
                success: false,
                error: (action as GetFSSLineItemsFailed).error
            };
        case CONSTANTS.SET_FSS_LINEITEMS_REQUESTED:
        case CONSTANTS.SET_FSS_LINEITEMS_SUCCESS:
            return {
                ...state,
                requestType: action.type,
                gotResponse: true,
                fssLineItemsUpdate: (action as SetFSSLineItemsSuccess).data
            }
        case CONSTANTS.SET_FSS_LINEITEMS_FAILED:
            return {
                ...state,
                gotResponse: true,
                requestType: action.type,
                success: false,
                error: (action as SetFSSLineItemsFailed).error
            };
        case CONSTANTS.CLEAR_FSS_LINEITEMS_STATE:
            return {
                ...state,
                fssLineItemsUpdate: "",
                gotResponse: true,
                requestType: action.type
            };

    }
}


export default pexaReducer;