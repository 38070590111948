import * as React from 'react'

import {
    Stack,
    MessageBar,
    MessageBarType,
    Spinner,
    PrimaryButton,
    ProgressIndicator,
} from 'office-ui-fabric-react';

import { Link as ReactLink } from 'react-router-dom';

import { connect } from 'react-redux';
import { AppState } from '../../../app.types';
import { IGlobalXApiTokenStatus } from '../../../utils/wcaApiTypes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tools from '../../../utils/tools';
import { postRequest } from 'utils/request';

import { DefaultStackTokens } from 'konekta-theme';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

type IProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

type IState = {
    tokenCheckInProgress: boolean;
    tokenWorking?: boolean;
    errorMessage?: string;

    connectInProgress: boolean,
}

export class ManageGlobalX extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            tokenCheckInProgress: false,
            tokenWorking: undefined,
            connectInProgress: false,
        }
    }

    public async componentDidMount() {
        await this.checkToken();
    }

    private checkToken = async () => {
        try {
            this.setState({tokenCheckInProgress:true});
            const globalXApiTokenStatus: IGlobalXApiTokenStatus = await postRequest('/api/integrations/globalx/check-token-status', {}, {});
            this.setState({
                tokenCheckInProgress: false,
                tokenWorking: globalXApiTokenStatus.found && globalXApiTokenStatus.verifiedToBeWorking,
            });
        } catch (e) {
            this.setState({
                tokenCheckInProgress: false,
                tokenWorking: undefined,
                errorMessage: "Oops! Couldn't retrieve connection status",
            });
        }
    }

    public componentDidUpdate(nextProps: IProps): void {
    }

    private connectToken = async () => {
        this.setState({connectInProgress:true});
        Tools.PopupConnectToGlobalX(() => {
            this.setState({connectInProgress:false});
            this.checkToken();
        });
    }

    render(): JSX.Element {

        return (
            <Stack tokens={{ padding: 'm' }}>
                <Stack tokens={DefaultStackTokens}>
                    {this.state.errorMessage &&
                        <MessageBar messageBarType={MessageBarType.error}>this.state.errorMessage</MessageBar>
                    }

                    <div>
                        GlobalX Link status:&nbsp;
                        {this.state.tokenCheckInProgress
                            ? <Spinner />
                            : this.state.tokenWorking
                                ? <><FontAwesomeIcon icon={faCheck} color="green" /> Connected and verified. <p>Congratulations, your link to GlobalX is working. Please <ReactLink to="/contactus">contact us</ReactLink> so we can finish setting up your account.</p></>
                                : <>
                                    <FontAwesomeIcon icon={faTimes} color="red" /> Not conected
                                    <p>
                                        {this.state.connectInProgress
                                            ? <ProgressIndicator label="Linking your GlobalX Master Account" description="Please log in to GlobalX in the popup window to finish linking your account." />
                                            : <PrimaryButton onClick={this.connectToken} label="Connect">Link GlobalX Master Account</PrimaryButton>
                                        }
                                    </p>
                                </>
                        }
                    </div>

                    <ReactLink to="/manage-add-ons">Go back</ReactLink>
                </Stack>
            </Stack>
        )
    }
}

const mapStateToProps = (state: AppState) => {
    return {
        actionstepContext: state.common.actionstepContext,
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageGlobalX);