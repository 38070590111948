import { apportionmentOption } from "../settlement/common";
import { getNumberOfDays } from "../settlement/Helper";
import { SpecialMeterReadingInfo } from "./utils/specialMeterReadingWCAApiTypes";
import { MatterDetails } from "utils/wcaApiTypes";

export const generateUIData = (updatedState: any, matterDetails: MatterDetails, specialMeterReadingData: SpecialMeterReadingInfo) => {
    let waterUsage = {};

    let waterUsagesResult = calculate(updatedState, specialMeterReadingData);
    waterUsage = waterUsagesResult;

    return new SpecialMeterReadingInfo({
        matterDetails: matterDetails,
        waterUsage: waterUsage
    });
}

const calculate = (item: any, specialMeterReadingData: SpecialMeterReadingInfo) => {

    let baseRateResult30June = 0;
    let tier2Result30June = 0;
    let tier3Result30June = 0;
    let balanceResult30June = 0;

    let dateOfCommencement = new Date(item["paidDate"]);
    let searchReadingDate = new Date(item["searchDate"]);

    let adjustedBaseRateKLCount = item["adjustedBaseRateKLCount"];
    let baseRateCostPerKL = item["baseRateCostPerKL"];
    let baseRateIncrease = item["baseRateIncrease"];

    let adjustedTier2KLCount = item["adjustedTier2KLCount"];
    let tier2CostPerKL = item["tier2CostPerKL"];
    let tier2FeeIncrease = item["tier2FeeIncrease"];

    let adjustedTier3KLCount = item["adjustedTier3KLCount"];
    let tier3CostPerKL = item["tier3CostPerKL"];
    let tier3FeeIncrease = item["tier3FeeIncrease"];

    let balanceWaterCharges = item["balanceWaterCharges"];
    let balanceWaterChargesFeeIncrease = item["balanceWaterChargesFeeIncrease"];
    let searchReadingAmount = item["searchReadingAmount"];

    let commencementAndSearchDateDiff = Math.abs(searchReadingDate.valueOf() - dateOfCommencement.valueOf())
    // this one might be problematic, there's no + 1 for commencementAndSearchDateDiff when it's non zero
    commencementAndSearchDateDiff = getNumberOfDays(commencementAndSearchDateDiff);
    commencementAndSearchDateDiff = commencementAndSearchDateDiff === 0 ? 1 : commencementAndSearchDateDiff;
    let dailyUsageCharge = commencementAndSearchDateDiff ? (searchReadingAmount / commencementAndSearchDateDiff) : 0;
    dailyUsageCharge = +dailyUsageCharge;

    let adjustmentDate = specialMeterReadingData.matterDetails && specialMeterReadingData.matterDetails["adjustmentDate"];

    adjustmentDate = new Date(adjustmentDate!)
    let diffMs = Math.abs(adjustmentDate.valueOf() - dateOfCommencement.valueOf());
    // this one might be problematic, there's no + 1 for adjustmentAndCommencementDateDiff when it's non zero
    let adjustmentAndCommencementDateDiff = getNumberOfDays(diffMs);
    adjustmentAndCommencementDateDiff = adjustmentAndCommencementDateDiff === 0 ? 1 : adjustmentAndCommencementDateDiff;
    let totalUsages = dailyUsageCharge * adjustmentAndCommencementDateDiff;
    totalUsages = +totalUsages;

    let balanceCalc = 0;
    let tempTotalUsages = totalUsages;

    if (tempTotalUsages >= parseFloat(adjustedBaseRateKLCount)) {
        tempTotalUsages -= parseFloat(adjustedBaseRateKLCount);
    } else {
        adjustedBaseRateKLCount = tempTotalUsages;
        adjustedTier2KLCount = 0;
        adjustedTier3KLCount = 0;

        item["adjustedBaseRateKLCount"] = tempTotalUsages;
        item["adjustedTier2KLCount"] = 0;
        item["adjustedTier3KLCount"] = 0;
    }

    if (tempTotalUsages >= parseFloat(adjustedTier2KLCount)) {
        tempTotalUsages -= parseFloat(adjustedTier2KLCount);
        item["adjustedTier3KLCount"] = Math.min(tempTotalUsages, parseFloat(adjustedTier3KLCount));
    } else {
        adjustedTier2KLCount = tempTotalUsages;
        adjustedTier3KLCount = 0;

        item["adjustedTier2KLCount"] = tempTotalUsages;
        item["adjustedTier3KLCount"] = 0;
    }

    balanceCalc = totalUsages - adjustedBaseRateKLCount - adjustedTier2KLCount - adjustedTier3KLCount;
    balanceCalc = balanceCalc < 0 ? 0 : balanceCalc;

    let waterUsageCalcTotal = 0;

    let baseRateResult = 0,
        tier2Result = 0,
        tier3Result = 0,
        balanceResult = 0

    let numberOfDaysToJune = 0,
        numberOfDaysFromJune = 0,
        numberOfDaysToJuly = 0

    if (parseFloat(baseRateIncrease) > 0 || parseFloat(tier2FeeIncrease) > 0 || parseFloat(tier3FeeIncrease) > 0 || parseFloat(balanceWaterChargesFeeIncrease) > 0) {
        let juneMonth = dateOfCommencement.getUTCFullYear() + '-06-30'

        // all of these dates here might be problematic cause there's no + 1
        let parsedDateJune30 = new Date(Date.parse(juneMonth.toString()))
        let absoluteDateJune30 = Math.abs(parsedDateJune30.valueOf() - dateOfCommencement.valueOf());
        numberOfDaysToJune = getNumberOfDays(absoluteDateJune30);

        let absoluteValue = Math.abs(parsedDateJune30.valueOf() - adjustmentDate.valueOf());
        numberOfDaysToJuly = getNumberOfDays(absoluteValue);

        let absoluteDateFromJune = Math.abs(adjustmentDate.valueOf() - dateOfCommencement.valueOf());
        numberOfDaysFromJune = getNumberOfDays(absoluteDateFromJune);

        baseRateResult = adjustedBaseRateKLCount * baseRateCostPerKL * numberOfDaysToJune / numberOfDaysFromJune
        tier2Result = adjustedTier2KLCount * tier2CostPerKL * numberOfDaysToJune / numberOfDaysFromJune
        tier3Result = adjustedTier3KLCount * tier3CostPerKL * numberOfDaysToJune / numberOfDaysFromJune
        balanceResult = balanceCalc * balanceWaterCharges * numberOfDaysToJune / numberOfDaysFromJune

        baseRateResult30June = baseRateResult;
        tier2Result30June = tier2Result;
        tier3Result30June = tier3Result;
        balanceResult30June = balanceResult;

        let water_usage_calc_tot_june30 = baseRateResult + tier2Result + tier3Result + balanceResult

        // july 1
        baseRateResult = adjustedBaseRateKLCount * baseRateIncrease * numberOfDaysToJuly / numberOfDaysFromJune
        tier2Result = adjustedTier2KLCount * tier2FeeIncrease * numberOfDaysToJuly / numberOfDaysFromJune
        tier3Result = adjustedTier3KLCount * tier3FeeIncrease * numberOfDaysToJuly / numberOfDaysFromJune
        balanceResult = balanceCalc * balanceWaterChargesFeeIncrease * numberOfDaysToJuly / numberOfDaysFromJune

        waterUsageCalcTotal = water_usage_calc_tot_june30 + baseRateResult + tier2Result + tier3Result + balanceResult

    } else if (parseFloat(baseRateIncrease) === 0 && parseFloat(tier2FeeIncrease) === 0 && parseFloat(tier3FeeIncrease) === 0 && parseFloat(balanceWaterChargesFeeIncrease) === 0) {
        baseRateResult30June = adjustedBaseRateKLCount * baseRateCostPerKL;
        tier2Result30June = adjustedTier2KLCount * tier2CostPerKL;
        tier3Result30June = adjustedTier3KLCount * tier3CostPerKL;
        balanceResult30June = balanceCalc * balanceWaterCharges;

        waterUsageCalcTotal = baseRateResult30June + tier2Result30June + tier3Result30June + balanceResult30June
    }

    let finalWaterUsageResult = 0;
    if (item["ctsOption"] === apportionmentOption.sharedPercentage) {
        let percent = item["percentage"] / 100;
        finalWaterUsageResult = waterUsageCalcTotal * percent;
    } else if (item["ctsOption"] === apportionmentOption.doNotApportion) {
        finalWaterUsageResult = waterUsageCalcTotal;
    } else if (item["ctsOption"] === apportionmentOption.entitlement && (item["entitlementValue"] ? true : false) && item["entitlementValue"].toString().split("/").length > 1) {
        let entitlement_Final = item["entitlementValue"].split("/")[0] / item["entitlementValue"].split("/")[1];
        entitlement_Final = parseFloat(entitlement_Final.toString())
        finalWaterUsageResult = waterUsageCalcTotal * entitlement_Final;
    }

    let someOfResultIncludingJuly = 0;
    let someOfResultExcludingJuly = 0;
    let showJulyCalculation = false;

    if (parseFloat(baseRateIncrease) === 0 && parseFloat(tier2FeeIncrease) === 0 && parseFloat(tier3FeeIncrease) === 0) {
        someOfResultExcludingJuly = (baseRateResult30June + tier2Result30June + tier3Result30June + balanceResult30June);
    }
    else {
        someOfResultIncludingJuly = (baseRateResult + baseRateResult30June + tier2Result + tier2Result30June + tier3Result + tier3Result30June + balanceResult + balanceResult30June);
        showJulyCalculation = true;
    }

    return {
        ...item,
        numberOfDays: commencementAndSearchDateDiff,
        partDays: adjustmentAndCommencementDateDiff,
        dailyAndDays: parseFloat(totalUsages.toFixed(3)),
        numberOfDaysToJune,
        numberOfDaysToJuly,
        numberOfDaysFromJune,
        baseRateResult,
        tier2Result: parseFloat(tier2Result.toFixed(3)),
        tier3Result: parseFloat(tier3Result.toFixed(3)),
        balanceResult: parseFloat(balanceResult.toFixed(3)),
        balanceCalc: parseFloat(balanceCalc.toFixed(3)),
        finalWaterUsageResult: parseFloat(finalWaterUsageResult.toFixed(3)),
        dailyUsage: parseFloat(dailyUsageCharge.toFixed(3)),
        diffAmountReading: searchReadingAmount,
        baseRateResult30June: parseFloat(baseRateResult30June.toFixed(3)),
        tier2Result30June: parseFloat(tier2Result30June.toFixed(3)),
        tier3Result30June: parseFloat(tier3Result30June.toFixed(3)),
        balanceResult30June: parseFloat(balanceResult30June.toFixed(3)),
        someOfResultIncludingJuly: parseFloat(someOfResultIncludingJuly.toFixed(3)),
        someOfResultExcludingJuly: parseFloat(someOfResultExcludingJuly.toFixed(3)),
        showJulyCalculation
    };
}