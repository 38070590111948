import * as CONSTANTS from './constants'
import {
    ConnectedActionstepOrgsResponse,
    ErrorViewModel,
    ListFilteredActionsResponse,   
    ProcessEmailArchiverResponse,
    ValidateEmailArchiverInputResponse,
    ProcessEmailArchiverQuery,
    EmailArchiverMainState,
    GeneralResponse 
} from 'utils/wcaApiTypes';

import {
    EmailArchiverActionTypes, 
    GetConnectedOrgsSuccess,
    GetListMattersSuccess, 
    ValidateDataInputSuccess,
    TriggerEmailArchiverProcessSuccess,
    ActionFailed,
    StoreUserInputState,
    GetTransactionStatusSuccess,
    DownloadReportsSuccess,
    CancelTransactionSuccess
} from 'containers/email-archiver/redux/actionTypes';


export interface EmailArchiverState {
    gotResponse: boolean,
    success: boolean,
    error: ErrorViewModel | undefined,
    requestType: string,
    connectedOrgsResponseList: ConnectedActionstepOrgsResponse[] | undefined,
    listMatters: ListFilteredActionsResponse | undefined,   
    validateEmailArchiverInputResponse: ValidateEmailArchiverInputResponse | undefined,
    processEmailArchiverResponse: ProcessEmailArchiverResponse | undefined,
    processEmailArchiverQuery: ProcessEmailArchiverQuery | undefined,
    transactionStatus: EmailArchiverMainState | undefined,
    isDownloadReportSuccess: boolean,
    cancelTransactionResponse: GeneralResponse | undefined,
    isAuthorisedUser: boolean,
} 

const initialState: EmailArchiverState = {
    gotResponse: false,
    success: false,   
    error: undefined,
    requestType: "",
    connectedOrgsResponseList: undefined,
    listMatters: undefined,  
    validateEmailArchiverInputResponse: undefined,
    processEmailArchiverResponse: undefined,
    processEmailArchiverQuery: undefined, 
    transactionStatus: undefined,
    isDownloadReportSuccess: false,
    cancelTransactionResponse: undefined,
    isAuthorisedUser: false
}


function emailArchiverReducer(state: EmailArchiverState = initialState, action: EmailArchiverActionTypes): EmailArchiverState {

    switch (action.type) {
        case CONSTANTS.GET_CONNECTED_ORGS_REQUESTED:
        case CONSTANTS.GET_LIST_MATTERS_REQUESTED:
        case CONSTANTS.VALIDATE_DATA_INPUT_REQUESTED:      
        case CONSTANTS.TRIGGER_EMAIL_ARCHIVER_PROCESS_REQUESTED:    
        case CONSTANTS.GET_TRANSACTION_STATUS_REQUESTED:
        case CONSTANTS.DOWNLOAD_REPORTS_REQUESTED:
        case CONSTANTS.CANCEL_TRANSACTION_REQUESTED:
            return {
                ...state,
                requestType: action.type,
                gotResponse: false,
                success: false
            };       

        case CONSTANTS.STORE_USER_INPUT_STATE: {
            return {
                ...state,
                processEmailArchiverQuery: (action as StoreUserInputState).data,
            };
        };              

        case CONSTANTS.GET_CONNECTED_ORGS_SUCCESS:
            return {
                ...state,
                connectedOrgsResponseList: (action as GetConnectedOrgsSuccess).data,
                gotResponse: true,
                success: true

            };
        case CONSTANTS.GET_LIST_MATTERS_SUCCESS:
            return {
                ...state,
                listMatters: (action as GetListMattersSuccess).data,
                gotResponse: true,
                success: true
            };

        case CONSTANTS.VALIDATE_DATA_INPUT_SUCCESS:
            return {
                ...state,
                validateEmailArchiverInputResponse: (action as ValidateDataInputSuccess).data,
                gotResponse: true,
                success: true
            };

        case CONSTANTS.TRIGGER_EMAIL_ARCHIVER_PROCESS_SUCCESS:
            return {
                ...state,
                processEmailArchiverResponse: (action as TriggerEmailArchiverProcessSuccess).data,
                gotResponse: true,
                success: true
            };     

        case CONSTANTS.GET_TRANSACTION_STATUS_SUCCESS:
            return {
                ...state,
                transactionStatus: (action as GetTransactionStatusSuccess).data.mainState,
                isAuthorisedUser: (action as GetTransactionStatusSuccess).data.isAuthorisedUser,
                gotResponse: true,
                success: true
            };  

        case CONSTANTS.DOWNLOAD_REPORTS_SUCCESS:
            return {
                ...state,
                isDownloadReportSuccess: (action as DownloadReportsSuccess).data,
                gotResponse: true,
                success: true
            };

        case CONSTANTS.CANCEL_TRANSACTION_SUCCESS:
            return {
                ...state,
                cancelTransactionResponse: (action as CancelTransactionSuccess).data,
                gotResponse: true,
                success: true
            };

        case CONSTANTS.GET_CONNECTED_ORGS_FAILED:      
        case CONSTANTS.GET_LIST_MATTERS_FAILED:    
        case CONSTANTS.VALIDATE_DATA_INPUT_FAILED:   
        case CONSTANTS.TRIGGER_EMAIL_ARCHIVER_PROCESS_FAILED:               
        case CONSTANTS.DELETE_EMAILS_AND_ATTACHMENTS_IN_SRC_ORG_FAILED:  
        case CONSTANTS.GET_TRANSACTION_STATUS_FAILED:
        case CONSTANTS.DOWNLOAD_REPORTS_FAILED:
        case CONSTANTS.CANCEL_TRANSACTION_FAILED:
            return {
                ...state,
                gotResponse: true,
                success: false,
                error: (action as ActionFailed).error
            };

        case CONSTANTS.CLEAR_EMAIL_ARCHIVER_STATE:
            return {
                ...state,
                gotResponse: false,
                success: false,
                requestType: ""                
            };

        case CONSTANTS.CLEAR_EMAIL_ARCHIVER_RESPONSE:
            return {
                ...state,
                gotResponse: false,
                success: false,
                requestType: "",  
                listMatters:undefined,
                validateEmailArchiverInputResponse:undefined                         
            };

        default:
            return state;
    }
}

export default emailArchiverReducer;