export enum FirstTitleEvents {
    GET_FIRST_TITLE_POLICY_REQUEST_FROM_ACTIONSTEP_REQUESTED = "FirstTitleReducer/GET_FIRST_TITLE_POLICY_REQUEST_FROM_ACTIONSTEP_REQUESTED",
    GET_FIRST_TITLE_POLICY_REQUEST_FROM_ACTIONSTEP_SUCCESS = "FirstTitleReducer/GET_FIRST_TITLE_POLICY_REQUEST_FROM_ACTIONSTEP_SUCCESS",
    GET_FIRST_TITLE_POLICY_REQUEST_FROM_ACTIONSTEP_FAILED = "FirstTitleReducer/GET_FIRST_TITLE_POLICY_REQUEST_FROM_ACTIONSTEP_FAILED",

    SEND_DATA_TO_FIRST_TITLE_REQUESTED = "FirstTitleReducer/SEND_DATA_TO_FIRST_TITLE_REQUESTED",
    SEND_DATA_TO_FIRST_TITLE_SUCCESS = "FirstTitleReducer/SEND_DATA_TO_FIRST_TITLE_SUCCESS",
    SEND_DATA_TO_FIRST_TITLE_FAILED = "FirstTitleReducer/SEND_DATA_TO_FIRST_TITLE_FAILED",

    CHECK_FIRST_TITLE_CREDENTIALS_REQUESTED = "FirstTitleReducer/CHECK_FIRST_TITLE_CREDENTIALS_REQUESTED",
    CHECK_FIRST_TITLE_CREDENTIALS_SUCCESS = "FirstTitleReducer/CHECK_FIRST_TITLE_CREDENTIALS_SUCCESS",
    CHECK_FIRST_TITLE_CREDENTIALS_FAILED = "FirstTitleReducer/CHECK_FIRST_TITLE_CREDENTIALS_FAILED",

    FIRST_TITLE_CREDENTIALS_LOGOUT = "FirstTitleReducer/FIRST_TITLE_CREDENTIALS_LOGOUT",
    FIRST_TITLE_CREDENTIALS_LOGOUT_SUCCESS = "FirstTitleReducer/FIRST_TITLE_CREDENTIALS_LOGOUT_SUCCESS",
    FIRST_TITLE_CREDENTIALS_LOGOUT_FAILED = "FirstTitleReducer/FIRST_TITLE_CREDENTIALS_LOGOUT_FAILED"
}