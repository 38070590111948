import * as React from 'react';
import { connect } from 'react-redux';

import { DefaultButton } from 'office-ui-fabric-react';
import { MessageBar, MessageBarType } from 'office-ui-fabric-react';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import { Link } from 'office-ui-fabric-react/lib/Link';
import { AppState } from '../../../../app.types';
import { SubwayNavNodeState } from '../../../../components/SubwayNav';

import * as CONSTANTS from '../../../../containers/pexa/redux/constants';

import {
    maintainSettlementDateTime,
    clearStatesForSettlementDateTime
} from '../../../../containers/pexa/redux/actions';


type AppStates = {
    updateForPexa: boolean,
    dataLoaded: boolean
}


interface IAppProps {
    onChangeStep: (newState: SubwayNavNodeState, newStep: number) => void;
}

type AppProps = IAppProps & ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

export class DataCheckByPexaSDT extends React.Component<AppProps, AppStates> {
    constructor(props: AppProps) {
        super(props);
        this.state = {
            dataLoaded: false,
            updateForPexa: this.props.updateSettlementDateTimeData?.updateForPexa || false
        }
    }


    public componentDidMount(): void {
        if (this.state.updateForPexa) {
            this.props.maintainSettlementDateTime(this.props.updateSettlementDateTimeData!.updateSettlementDateAndTimeRequestCommand!);
        }
    }

    static getDerivedStateFromProps(nextProps: AppProps, prevState: AppStates): AppStates {
        let nextState = {} as AppStates;

        if (nextProps.gotResponsePexa) {
            switch (nextProps.requestType) {
                case CONSTANTS.MAINTAIN_SETTLEMENT_DATE_TIME_REQUESTED: {
                    nextState.dataLoaded = true;
                    if (!nextProps.pexaError ||
                        nextProps.pexaError && nextProps.pexaError.errorList && nextProps.pexaError.errorList.length === 0) {
                        nextProps.clearStatesForSettlementDateTime();
                        nextProps.onChangeStep(SubwayNavNodeState.Completed, 4);
                    }
                    break;
                }
                default:
                    return nextState;
            }
        }
        return nextState;
    }

    private goBackToDataInput(): void {
        this.props.clearStatesForSettlementDateTime();
        this.props.onChangeStep(SubwayNavNodeState.Current, 1);
    }

    render() {
        const { pexaError } = this.props;
        const { dataLoaded } = this.state;

        return (
            <div>
                {dataLoaded ?
                    <div>
                        {(pexaError && pexaError.errorList && pexaError.errorList.length > 0) ?
                            <div>
                                <h3>
                                    Pexa returned the following error(s):
                                </h3>
                                <br />

                                {pexaError.errorList.map((item, index) => (
                                    <MessageBar messageBarType={MessageBarType.error} isMultiline={false} key={index}>
                                        {item}
                                    </MessageBar>
                                ))}
                                <br />

                                <DefaultButton
                                    className="button"
                                    text="Go Back"
                                    onClick={() => this.goBackToDataInput()}
                                    allowDisabledFocus={true}
                                />
                            </div>
                            :
                            <div>
                                <p className="text-danger">
                                    An unexpected error has occured. Please <Link onClick={() => window.location.reload(false)} >refresh the page</Link> and try again. If you continue to experience problems, please
                                     <Link href={"https://support.konekta.com.au/support/tickets/new"} target="_blank" rel="noopener noreferrer"> submit a ticket</Link>.
                               </p>
                            </div>
                        }
                    </div>
                    : <Spinner size={SpinnerSize.large} />
                }
            </div>
        );
    }
}

const mapStateToProps = (state: AppState) => {
    return {
        pexaError: state.pexa.error,
        gotResponsePexa: state.pexa.gotResponse,
        requestType: state.pexa.requestType,
        updateSettlementDateTimeData: state.pexa.updateSettlementDateTimeData
    }
}

const mapDispatchToProps = {
    clearStatesForSettlementDateTime,
    maintainSettlementDateTime
}

export default connect(mapStateToProps, mapDispatchToProps)(DataCheckByPexaSDT);
