import * as CONSTANTS from './constants'

import {
    GetClientDetails,
    GetClientDetailSuccess,
    ActionFailed,
    SendRBIData,
    SendRBIRequestSuccess,
    ClearRBIInfoState
} from "containers/rapidBuildingInspections/redux/actionTypes";

import {
    ActionstepClientInfo,
    ActionstepMatterInfo,
    ErrorViewModel,
    RBIDataModel
} from "containers/rapidBuildingInspections/utils/rapidBuildingInspectionAPITypes"

export function getClientDetails(params: ActionstepMatterInfo): GetClientDetails {
    return {
        type: CONSTANTS.GET_CLIENT_DETAILS_REQUESTED,
        params
    }
}

export function getClientDetailSuccess(data: ActionstepClientInfo): GetClientDetailSuccess {
    return {
        type: CONSTANTS.GET_CLIENT_DETAILS_SUCCESS,
        data
    }
}

export function getClientDetailFailed(error: ErrorViewModel): ActionFailed {
    return {
        type: CONSTANTS.GET_CLIENT_DETAILS_FAILED,
        error
    }
}

export function sendRBIData(params: RBIDataModel): SendRBIData {
    return {
        type: CONSTANTS.SEND_RBI_DETAILS_REQUESTED,
        params
    }
}
export function sendRBIRequestSuccess(data: string): SendRBIRequestSuccess {
    return {
        type: CONSTANTS.SEND_RBI_DETAILS_SUCCESS,
        data
    }
}

export function sendRBIDataFailed(error: ErrorViewModel): ActionFailed {
    return {
        type: CONSTANTS.SEND_RBI_DETAILS_FAILED,
        error
    }
}

export function clearRBIDetails(): ClearRBIInfoState {
    return {
        type: CONSTANTS.CLEAR_RBI_INFO_STATE
    }
}