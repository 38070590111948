export const GET_STEWART_TITLE_MATTER_FROM_ACTIONSTEP_REQUESTED = "StewartTitleReducer/GET_STEWART_TITLE_MATTER_FROM_ACTIONSTEP_REQUESTED";
export const GET_STEWART_TITLE_MATTER_FROM_ACTIONSTEP_SUCCESS = "StewartTitleReducer/GET_STEWART_TITLE_MATTER_FROM_ACTIONSTEP_SUCCESS";
export const GET_STEWART_TITLE_MATTER_FROM_ACTIONSTEP_FAILED = "StewartTitleReducer/GET_STEWART_TITLE_MATTER_FROM_ACTIONSTEP_FAILED";

export const ORDER_STEWART_TITLE_POLICY_REQUESTED = "StewartTitleReducer/ORDER_STEWART_TITLE_POLICY_REQUESTED";
export const ORDER_STEWART_TITLE_POLICY_SUCCESS = "StewartTitleReducer/ORDER_STEWART_TITLE_POLICY_SUCCESS";
export const ORDER_STEWART_TITLE_POLICY_FAILED = "StewartTitleReducer/ORDER_STEWART_TITLE_POLICY_FAILED";

export const GET_STEWART_TITLE_ORDER_DETAILS_REQUESTED = "StewartTitleReducer/GET_STEWART_TITLE_ORDER_DETAILS_REQUESTED";
export const GET_STEWART_TITLE_ORDER_DETAILS_SUCCESS = "StewartTitleReducer/GET_STEWART_TITLE_ORDER_DETAILS_SUCCESS";
export const GET_STEWART_TITLE_ORDER_DETAILS_FAILED = "StewartTitleReducer/GET_STEWART_TITLE_ORDER_DETAILS_FAILED";