import {  createMuiTheme } from "@material-ui/core";

//@ts-ignore
export const formLabelsTheme = createMuiTheme({
    overrides: {
        MuiFormLabel: {
            asterisk: {
                color: "#db3131",
                "&$error": {
                    color: "#db3131"
                }
            }, root: {
                fontSize: 14,
                color: "black"
            },
        },
        MuiFormControlLabel: {
            label: {
                fontSize: 14
            },
        },
        MuiFab: {
            root: {
                fontSize: 14,
            },
        },
        MuiSvgIcon: {
            root: {
                fontSize: 20,
            },
        },
        MuiInputBase: {
            root: {
                fontSize: 14,
            },
        },
        MuiFormHelperText: {
            root: {
                fontSize: 10,
            }
        },
    }
});