import * as React from 'react';
import { TextField, DatePicker, DayOfWeek, Dropdown, ChoiceGroup, Separator } from 'office-ui-fabric-react/lib';
import { _onFormatDateDDMMYYYY } from 'utils/dataFormatter';
import { apportionmentOption } from 'containers/calculators/settlement/common';
import { getCTSOptions } from 'containers/calculators/settlement/Helper';
import '../SpecialMeterReadingCalculator.css'

interface IMapStateToProps { }
interface IMapDispatchToProps { }

interface IProps {
    updatedState: any;
    updateValue: (newValue: any, whichValue: string, needRefresh?: boolean) => void;
    stateValue: any
}

type AppProps = IMapStateToProps & IProps & IMapDispatchToProps;
type AppStates = {}

export default class SpecialMeterReading extends React.Component<AppProps, AppStates> {

    public render(): JSX.Element {
        const updatedState = this.props.updatedState;
        const ctsOptions = getCTSOptions().sort((a, b) => (a.key > b.key) ? 1 : -1);

        return (
            <div className="section">
                <div className="section-body">
                    <div className="WaterUsage modal-body">
                        <div className="ms-Grid" dir="ltr">
                            <div className="ms-Grid-row modal-row">
                                <div className="ms-Grid-col ms-sm6">
                                    <div className="ms-Grid-col ms-sm3">Method :</div>
                                    <div className="ms-Grid-col ms-sm9">
                                        <ChoiceGroup
                                            className="defaultChoiceGroup"
                                            defaultSelectedKey={updatedState['method']}
                                            options={[
                                                {
                                                    key: 'search-reading',
                                                    text: 'Search Reading',
                                                }
                                            ]}
                                            required={true}
                                            onChange={(ev, item) => this.props.updateValue(item!.key, 'method')}
                                        />
                                    </div>
                                </div>
                            </div>

                            <Separator>
                                <b>Readings</b>
                            </Separator>
                            <div className='ms-Grid-row modal-row'>
                                <div className='ms-Grid-col ms-sm4'>Date of Commencement :</div>
                                <div className='ms-Grid-col ms-sm4'>
                                    <DatePicker
                                        className={this.props.stateValue["CommencementDateError"] !== "" ? 'error-red-border' : ''}
                                        data-cy="paid_to_date_input"
                                        firstDayOfWeek={DayOfWeek.Monday}
                                        formatDate={_onFormatDateDDMMYYYY}
                                        showMonthPickerAsOverlay={true}
                                        placeholder="Select a date..."
                                        ariaLabel="Select a date"
                                        value={updatedState['paidDate']}
                                        onSelectDate={(newDate) => this.props.updateValue(newDate, 'paidDate')}
                                    />
                                </div>
                            </div>

                            <div className='ms-Grid-row modal-row'>
                                <div className='ms-Grid-col ms-sm4'>Search Reading :</div>
                                <div className='ms-Grid-col ms-sm4'>
                                    <DatePicker
                                        className={this.props.stateValue["searchReadingError"] !== "" ? 'error-red-border' : ''}
                                        data-cy="search_date_input"
                                        firstDayOfWeek={DayOfWeek.Monday}
                                        formatDate={_onFormatDateDDMMYYYY}
                                        showMonthPickerAsOverlay={true}
                                        placeholder="Select a date..."
                                        disabled={updatedState['method'] === 'daily-average'}
                                        ariaLabel="Select a date"
                                        value={updatedState['searchDate']}
                                        onSelectDate={(newDate) => this.props.updateValue(newDate, 'searchDate')}
                                    />
                                </div>
                                <div className='ms-Grid-col ms-sm4'>
                                    <TextField
                                        data-cy="search_reading_amount_input"
                                        placeholder='0 kl'
                                        disabled={updatedState['method'] === 'daily-average'}
                                        defaultValue={updatedState['searchReadingAmount']}
                                        onChange={(ev, newText) => this.props.updateValue(newText, 'searchReadingAmount')}
                                        type="number"
                                    />
                                </div>
                            </div>

                            <Separator>
                                <b>Charges</b>
                            </Separator>
                            <div className='ms-Grid-row modal-row'>
                                <div className='ms-Grid-col ms-sm3'></div>
                                <div className='ms-Grid-col ms-sm3'>Cost (Per KL)</div>
                                <div className='ms-Grid-col ms-sm3'>KL Count</div>
                                <div className='ms-Grid-col ms-sm3'>Increase (If app from 1st july)</div>
                            </div>

                            <div className='ms-Grid-row modal-row'>
                                <div className='ms-Grid-col ms-sm3'>Base Rate:</div>
                                <div className='ms-Grid-col ms-sm3'>
                                    <TextField
                                        data-cy='base_Rate_Cost_Per_KL_Input'
                                        defaultValue={updatedState['baseRateCostPerKL']}
                                        onChange={(ev, nextText) => this.props.updateValue(nextText, 'baseRateCostPerKL')}
                                        type="number"
                                    />
                                </div>
                                <div className='ms-Grid-col ms-sm3'>
                                    <TextField
                                        data-cy='base_Rate_KL_Count_Input'
                                        placeholder='0 KL'
                                        defaultValue={updatedState['baseRateKLCount']}
                                        onChange={(ev, nextText) => this.props.updateValue(nextText, 'baseRateKLCount')}
                                        type="number"
                                    />
                                </div>
                                <div className='ms-Grid-col ms-sm3'>
                                    <TextField
                                        data-cy='base_Rate_Increase_Input'
                                        defaultValue={updatedState['baseRateIncrease']}
                                        onChange={(ev, nextText) => this.props.updateValue(nextText, 'baseRateIncrease')}
                                        type="number"
                                    />
                                </div>
                            </div>

                            <div className='ms-Grid-row modal-row'>
                                <div className='ms-Grid-col ms-sm3'>Tier 2 (If app)</div>
                                <div className='ms-Grid-col ms-sm3'>
                                    <TextField
                                        data-cy='tier2_Cost_Per_KL_Input'
                                        defaultValue={updatedState['tier2CostPerKL']}
                                        onChange={(ev, nextText) => this.props.updateValue(nextText, 'tier2CostPerKL')}
                                        type="number"
                                    />
                                </div>
                                <div className='ms-Grid-col ms-sm3'>
                                    <TextField
                                        data-cy='tier2_KL_Count_Input'
                                        placeholder='0 KL'
                                        defaultValue={updatedState['tier2KLCount']}
                                        onChange={(ev, nextText) => this.props.updateValue(nextText, 'tier2KLCount')}
                                        type="number"
                                    />
                                </div>
                                <div className='ms-Grid-col ms-sm3'>
                                    <TextField
                                        data-cy='tier2_Fee_Increase_Input'
                                        defaultValue={updatedState['tier2FeeIncrease']}
                                        onChange={(ev, nextText) => this.props.updateValue(nextText, 'tier2FeeIncrease')}
                                        type="number"
                                    />
                                </div>
                            </div>

                            <div className='ms-Grid-row modal-row'>
                                <div className='ms-Grid-col ms-sm3'>Tier 3 (If app)</div>
                                <div className='ms-Grid-col ms-sm3'>
                                    <TextField
                                        data-cy='tier3_Cost_Per_KL_Input'
                                        defaultValue={updatedState['tier3CostPerKL']}
                                        onChange={(ev, nextText) => this.props.updateValue(nextText, 'tier3CostPerKL')}
                                        type="number"
                                    />
                                </div>
                                <div className='ms-Grid-col ms-sm3'>
                                    <TextField
                                        data-cy='tier3_KL_Count_Input'
                                        placeholder='0 KL'
                                        defaultValue={updatedState['tier3KLCount']}
                                        onChange={(ev, nextText) => this.props.updateValue(nextText, 'tier3KLCount')}
                                        type="number"
                                    />
                                </div>
                                <div className='ms-Grid-col ms-sm3'>
                                    <TextField
                                        data-cy='tier3_Fee_Increase_Input'
                                        defaultValue={updatedState['tier3FeeIncrease']}
                                        onChange={(ev, nextText) => this.props.updateValue(nextText, 'tier3FeeIncrease')}
                                        type="number"
                                    />
                                </div>
                            </div>

                            <div className='ms-Grid-row modal-row'>
                                <div className='ms-Grid-col ms-sm3'>Balance Water Charges: </div>
                                <div className='ms-Grid-col ms-sm3'>
                                    <TextField
                                        data-cy='balance_Water_Charges_Input'
                                        defaultValue={updatedState['balanceWaterCharges']}
                                        onChange={(ev, nextText) => this.props.updateValue(nextText, 'balanceWaterCharges')}
                                        type="number"
                                    />
                                </div>
                                <div className='ms-Grid-col ms-sm3'></div>
                                <div className='ms-Grid-col ms-sm3'>
                                    <TextField
                                        data-cy="balance_Water_Charges_Fee_Increase_Input"
                                        defaultValue={updatedState['balanceWaterChargesFeeIncrease']}
                                        onChange={(ev, newText) => this.props.updateValue(newText, 'balanceWaterChargesFeeIncrease')}
                                        type="number"
                                    />
                                </div>
                            </div>

                            <Separator>
                                <b>Apportionment</b>
                            </Separator>

                            <div className="ms-Grid-row modal-row">
                                <div className="ms-Grid-col ms-sm3">CTS Option:</div>
                                <div className="ms-Grid-col ms-sm3">
                                    <Dropdown
                                        data-cy="cts_option_select"
                                        id="cts_option_select"
                                        options={ctsOptions}
                                        defaultSelectedKey={updatedState['ctsOption']}
                                        onChange={(ev, item) => this.props.updateValue(item!.key, 'ctsOption', true)}
                                    />
                                </div>

                                {updatedState['ctsOption'] === apportionmentOption.sharedPercentage &&

                                    <div className="ms-Grid-col ms-sm3">
                                        <TextField
                                            className={this.props.stateValue["percentageError"] !== "" ? 'error-red-border' : ''}
                                            data-cy="percentage_input"
                                            defaultValue={updatedState['percentage']}
                                            onChange={(ev, newText) => this.props.updateValue(newText, 'percentage')}
                                            type="number"
                                        />
                                    </div>
                                }

                                {updatedState['ctsOption'] === apportionmentOption.entitlement &&
                                    <div className="ms-Grid-col ms-sm3">
                                        <TextField
                                            className={this.props.stateValue["entitlementValueError"] !== "" ? 'error-red-border' : ''}
                                            defaultValue={updatedState['entitlementValue']}
                                            placeholder='Lot Allocation/Aggregate'
                                            onChange={(ev, newText) => this.props.updateValue(newText, 'entitlementValue')}
                                        />
                                    </div>
                                }
                            </div>

                            <div className='ms-Grid-row modal-row'>
                                <div className='ms-Grid-col ms-sm3'>Funds withheld at settlement</div>
                                <div className='ms-Grid-col ms-sm3'>
                                    <TextField
                                        data-cy='special_Water_Meter_Reading_Charges'
                                        defaultValue={updatedState['specialWaterMeterReadingCharges']}
                                        onChange={(ev, nextText) => this.props.updateValue(nextText, 'specialWaterMeterReadingCharges')}
                                        type="number"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}