import { ReduxData, ReduxStatus } from 'app.types';
import { RequestPropertyInformationFromActionstepResponse } from 'utils/wcaApiTypes';
import { IGlobalXAction } from './actions';
import { GlobalXEvents } from './events';

export interface GlobalXState {
    cdmInformation: ReduxData<RequestPropertyInformationFromActionstepResponse> | undefined
}

const initialState: GlobalXState = {
    cdmInformation: undefined
}

function globalXReducer(state: GlobalXState = initialState, action: IGlobalXAction): GlobalXState {
    switch (action.type) {
        case GlobalXEvents.GET_PROPERTY_INFORMATION_REQUESTED:
            return {
                ...state,
                cdmInformation: new ReduxData<RequestPropertyInformationFromActionstepResponse>(ReduxStatus.Requested)
            };

        case GlobalXEvents.GET_PROPERTY_INFORMATION_SUCCESS:
            return {
                ...state,
                cdmInformation: new ReduxData<RequestPropertyInformationFromActionstepResponse>().markAsSuccess(action.payload)
            };

        case GlobalXEvents.GET_PROPERTY_INFORMATION_FAILED:
            return {
                ...state,
                cdmInformation: new ReduxData<RequestPropertyInformationFromActionstepResponse>().markAsFailed(action.payload)
            };

        default:
            return state;
    }
}

export default globalXReducer;