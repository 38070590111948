import { call, put, takeLatest } from 'redux-saga/effects'

import * as ACTIONS from './actions'
import {
    FirstTitlePolicyRequestFromActionstepResponse,
    OrderFirstTitlePolicyCommandResponse
} from 'utils/wcaApiTypes'

import { getRequest, postRequest } from 'utils/request'
import { FirstTitleEvents } from './events'

export function* disableFirstTitleCredentialsSaga() {
    try {
        const response = yield call(postRequest, `/api/insurance/first-title-disable-credentials`);

        let isValid: boolean = response;

        yield put(ACTIONS.firstTitleCredentialsLogoutSuccess(isValid));

    } catch (error) {
        yield put(ACTIONS.firstTitleCredentialsLogoutFailed(error?.response?.data));
    }
}

export function* checkFirstTitleCredentialsSaga(action: ReturnType<typeof ACTIONS.checkFirstTitleCredentials>) {
    try {
        const response = yield call(postRequest, `/api/insurance/first-title-check-and-update-credentials`, action.payload);

        let isValid: boolean = response;

        yield put(ACTIONS.checkFirstTitleCredentialsSuccess(isValid));

    } catch (error) {
        yield put(ACTIONS.checkFirstTitleCredentialsFailed(error?.response?.data));
    }
}

export function* getFirstTitlePolicyRequestFromActionstepSaga(action: ReturnType<typeof ACTIONS.getFirstTitlePolicyRequestFromActionstep>) {
    try {
        const response = yield call(getRequest, `/api/insurance/first-title-request-from-matter?actionsteporg=${action.payload.orgKey}&matterid=${action.payload.matterId}`);

        let data: FirstTitlePolicyRequestFromActionstepResponse = FirstTitlePolicyRequestFromActionstepResponse.fromJS(response);

        yield put(ACTIONS.getFirstTitlePolicyRequestFromActionstepSuccess(data));

    } catch (error) {
        yield put(ACTIONS.getFirstTitlePolicyRequestFromActionstepFailed(error?.response?.data));
    }
}

export function* sendDataToFirstTitleSaga(action: ReturnType<typeof ACTIONS.sendDataToFirstTitle>) {
    try {
        const response = yield call(postRequest, `/api/insurance/first-title-request-from-matter`, action.payload);

        if (response["errorList"]) {
            yield put(ACTIONS.sendDataToFirstTitleFailed(response));
        } else {
            let data: OrderFirstTitlePolicyCommandResponse = OrderFirstTitlePolicyCommandResponse.fromJS(response);

            yield put(ACTIONS.sendDataToFirstTitleSuccess(data));
        }
    } catch (error) {
        yield put(ACTIONS.sendDataToFirstTitleFailed(error?.response?.data));
    }
}

export default function* pexaSaga() {
    yield takeLatest(FirstTitleEvents.FIRST_TITLE_CREDENTIALS_LOGOUT, disableFirstTitleCredentialsSaga)
    yield takeLatest(FirstTitleEvents.CHECK_FIRST_TITLE_CREDENTIALS_REQUESTED, checkFirstTitleCredentialsSaga);
    yield takeLatest(FirstTitleEvents.GET_FIRST_TITLE_POLICY_REQUEST_FROM_ACTIONSTEP_REQUESTED, getFirstTitlePolicyRequestFromActionstepSaga);
    yield takeLatest(FirstTitleEvents.SEND_DATA_TO_FIRST_TITLE_REQUESTED, sendDataToFirstTitleSaga);
}