import { call, put, takeLatest } from 'redux-saga/effects'

import * as CONSTANTS from './constants'
import * as ACTIONS from './actions'
import {
    GetUserAuditTrailDataResponse
} from 'utils/wcaApiTypes'

import {
    getRequest,
    postRequest
} from 'utils/request'

import {
    GetUserAuditTrailData,
    SaveUserAuditTrailData
} from './actionTypes'


export function* getUserAuditTrailData(action: GetUserAuditTrailData) {
    const { actionstepOrg, matterId} = action;
    try {
        const response = yield call(getRequest, `/api/conveyancing/get-user-audit-trail-data?actionstepOrg=${actionstepOrg}&matterId=${matterId}`);

        let data: GetUserAuditTrailDataResponse = response;

        yield put(ACTIONS.getUserAuditTrailDataSuccess(data));

    } catch (error) {
        yield put(ACTIONS.getUserAuditTrailDataFailed(error?.response?.data));
    }
}

export function* saveUserAuditTrailData(action: SaveUserAuditTrailData) {
    const { params } = action;
    try {
        const response = yield call(postRequest, `/api/conveyancing/save-user-audit-trail-data`, params);

        let data: boolean = response;

        yield put(ACTIONS.saveUserAuditTrailDataSuccess(data));

    } catch (error) {
        yield put(ACTIONS.saveUserAuditTrailDataFailed(error?.response?.data));
    }
}


export default function* userAuditTrailSaga() {
    yield takeLatest(CONSTANTS.GET_USER_AUDIT_TRAIL_DATA_REQUESTED, getUserAuditTrailData);
    yield takeLatest(CONSTANTS.SAVE_USER_AUDIT_TRAIL_DATA_REQUESTED, saveUserAuditTrailData);
} 