import * as React from 'react';
import { IconButton } from '@fluentui/react';
import { ModalIDs } from 'containers/calculators/settlement/common';
import SettlementInfo from './settlementInfo';
import { reorderDragDropList, toFormatLocalString, QldVendorSellerUtils } from './Helper';
import './settlementCalculator.css';
// @ts-ignore
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

interface IMapStateToProps {
    stateOfCountry: string;
}
interface IMapDispatchToProps { }

interface IProps {
    toggleSection: (payee: string) => void;
    showPayee: any;
    showModal: (modalID: ModalIDs, additionalInfo: any) => void;
    unallocatedFund: any;
    isVendor: boolean;
    additionalInfo?: { [key: string]: any; } | undefined;
    conveyType: string | undefined;
    payeeCheckboxHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
    payeeTotal: any;
    payees?: { [key: string]: any; }[] | undefined;
    onReorder: (list: { [key: string]: any; }[]) => void;
}

type AppProps = IMapStateToProps & IProps & IMapDispatchToProps;
type AppStates = {}

export default class PayeeInfo extends React.Component<AppProps, AppStates> {
    constructor(props: AppProps) {
        super(props);

        this.onDragEnd = this.onDragEnd.bind(this);
    }

    private onDragEnd(result: any) {
        if (!result.destination) {
            return;
        }

        let r = reorderDragDropList(this.props.payees ?? [], result.source.index, result.destination.index) ?? [];
        this.props.onReorder(r);
    }

    render(): JSX.Element {
        const { showPayee, unallocatedFund, isVendor, additionalInfo, conveyType, payeeTotal, payees } = this.props;

        return (
            <div className="section">
                <div className="right-align-section-header">
                    Show/Hide Payees
                    <IconButton
                        className="button show-hide-button"
                        data-automation-id="save_button"
                        onClick={() => this.props.toggleSection("Payees")}
                        allowDisabledFocus={true}
                        iconProps={{
                            iconName: showPayee
                                ? "CaretDownSolid8"
                                : "CaretUpSolid8",
                            style: { fontSize: 10 },
                        }}
                    />
                </div>
                {showPayee && (
                    <>
                        <div className="section-body animated fadeIn">
                            <div className="ms-Grid-row additional-header">
                                <div className="ms-Grid-col ms-sm8 ms-smPush2">
                                    <b className="header-text">{QldVendorSellerUtils(this.props.stateOfCountry).toUpperCase()} FUNDS DISTRIBUTION</b>
                                </div>
                            </div>
                        </div>

                        <div className="ms-Grid-row state-row">
                            <div className="ms-Grid-col ms-sm12 right-align">
                                {this.props.payees!.length > 0 ? <span>Select all cheques <input type="checkbox" id="allCheckedPayee" checked={this.props.payees!.filter((payee) => payee?.isChecked !== true).length < 1} onChange={(event) => this.props.payeeCheckboxHandler(event)} /></span> : ""}
                            </div>
                        </div>
                        {this.props.payees!.length > 0 ?
                            <>
                                <div className="ms-Grid-col Paye-Amount">
                                    <b>Amount</b>
                                </div>
                                <div className="ms-Grid-col Paye-Cheque-Details">
                                    <b>Payee/Cheque Details</b>
                                </div>
                            </>
                            : <></>
                        }
                        <div className="section-body">
                            <DragDropContext onDragEnd={this.onDragEnd}>
                                <Droppable droppableId="payeeInfo">
                                    {(provided: any, snapshot: any) => (
                                        <div
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                        >
                                            {payees &&
                                                payees.map((payee, index) => {
                                                    return (
                                                        <Draggable key={index} draggableId={"1-" + index} index={index}>
                                                            {(provided: any, snapshot: any) => (
                                                                <div
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                >
                                                                    <SettlementInfo
                                                                        isVendor={isVendor}
                                                                        key={"payee_" + index}
                                                                        item={payee}
                                                                        index={index}
                                                                        additionalInfo={additionalInfo}
                                                                        showModal={(modalID, additionalInfo) =>
                                                                            this.props.showModal(modalID, additionalInfo)
                                                                        }
                                                                        payeeCheckboxHandler={(event) =>
                                                                            this.props.payeeCheckboxHandler(event)
                                                                        }
                                                                        additionalRequirementCheckboxHandler={() => { }}
                                                                        draggable={true}
                                                                        conveyType={conveyType}
                                                                    />
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    );
                                                })}
                                            {provided.placeholder}
                                        </div>)}
                                </Droppable>
                            </DragDropContext>


                            <div className="ms-Grid-col ms-sm12 separator">
                                --------------
                            </div>

                            <div className="ms-Grid-row state-row">
                                <div className="ms-Grid-col ms-sm2">
                                    <IconButton
                                        data-cy="add_payee"
                                        className="button rounded-blue-icon-button"
                                        data-automation-id="save_button"
                                        onClick={() =>
                                            this.props.showModal(ModalIDs.payeeDetails, { index: -1 })
                                        }
                                        allowDisabledFocus={true}
                                        iconProps={{
                                            iconName: "Add",
                                            style: {
                                                fontSize: 15,
                                                fontWeight: 900,
                                            },
                                        }}
                                    />
                                </div>
                                <div className="ms-Grid-col ms-sm10">
                                    <div className="ms-Grid" dir="ltr">
                                        <div className="ms-Grid-row detail-row">
                                            <div className="ms-Grid-col ms-sm5 add-label">
                                                Click + to Add Cheque Payee's
                                            </div>
                                            <div className="ms-Grid-col ms-sm3">
                                                <b>TOTAL</b>
                                            </div>

                                            <div className="ms-Grid-col ms-sm2 price-info ms-smPush2">
                                                <b data-cy="contract_debit_value">
                                                    ${toFormatLocalString(payeeTotal, "en-AU", 2, 2)}
                                                </b>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="ms-Grid-row state-row">
                                <div className='ms-Grid-col ms-sm12 right-align'>
                                    <label className={unallocatedFund < 0 ? "red" : "green"}>(unallocated: ${toFormatLocalString(unallocatedFund, "en-AU", 2, 2)})
                                    </label>
                                </div>
                            </div>
                        </div>
                    </>)}
            </div>
        )

    }
}