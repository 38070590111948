import React from 'react';
import { Icon, PrimaryButton } from '@fluentui/react';
import { IdentityType } from 'utils/wcaApiTypes';

import './style.css';

interface IProps {
    isCompany: boolean;
    onClickHandler: (type: IdentityType) => void;
}

export const AddBuyerComponent = ({ isCompany, onClickHandler }: IProps) => {
    const onClickEventHandler = () => {
        if(isCompany) {
            onClickHandler(IdentityType.Company);
        } else {
            onClickHandler(IdentityType.Individual);
        }
    }
    return (
        <div className="add-buyer-container">
            <PrimaryButton text={`Add ${isCompany ? 'Company ' : ''}Buyer`} onClick={onClickEventHandler} >
                <Icon iconName="AddFriend" aria-hidden="true" />
            </PrimaryButton>
        </div>
    )
}