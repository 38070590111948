import * as React from 'react';
import { AppState } from 'app.types';
import { connect } from 'react-redux';
import * as CONSTANTS from 'containers/ecaf/redux/constants';
import 'containers/ecaf/createOrder.css'
import { MessageBar, MessageBarType } from 'office-ui-fabric-react'
import AddIcon from "@material-ui/icons/Add";
import {
    Fab,
    Grid,
    TextField,
    MuiThemeProvider,
    Button,
    FormControlLabel,
    Divider,
    FormControl,
    FormLabel,
    Radio,
    RadioGroup,
    Select,
    MenuItem
} from "@material-ui/core";

import {
    getMatterDetailsFromActionstep,
    getValidECAFCredential,
    createOrder
} from 'containers/ecaf/redux/actions';

import { ActionstepMatterInfo, CreateOrderQuery, ECAFTransaction, ECAFClient } from '../../utils/wcaApiTypes';
import { Redirect } from 'react-router-dom';
import { formLabelsTheme } from 'containers/ecaf/muiThemeStyles'

import ECafClientForm from 'containers/ecaf/components/eCafClientForm';
import ECafTransactionForm from 'containers/ecaf/components/eCafTransactionForm'

import PreferenceComponent from 'containers/ecaf/components/preference';



enum CreateOrderSteps {
    NotStarted = -1,
    ECAFNotConnected = 0,
    ECAFOrderPlaced = 1
}

interface IAppProps {
}

type AppStates = {
    currentStep: CreateOrderSteps,
    ECAFConnected: boolean,
    isLoading: boolean,
    pageError: string | undefined,
    matterInfo: ActionstepMatterInfo | undefined,
    clients: ECAFClient[],
    transactions: ECAFTransaction[],
    isStandingAuthority: boolean,
    notificationType: string,
    partyToMap: string,
    paymentMethod: string,
    deliveryAddressName: string,
    deliveryEmailAddress: string,
    createOrderValidationFailed: boolean,
    isCreateOrder: boolean,
}

type AppProps = IAppProps & ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

class CreateECAFOrder extends React.Component<AppProps, AppStates> {

    constructor(props: Readonly<AppProps>) {
        super(props);

        this.state = {
            currentStep: CreateOrderSteps.NotStarted,
            ECAFConnected: false,
            isLoading: false,
            pageError: undefined,
            matterInfo: undefined,
            isStandingAuthority: false,
            clients: [],
            transactions: [],
            notificationType: "",
            partyToMap: "",
            paymentMethod: "",
            deliveryAddressName: "",
            deliveryEmailAddress: "",
            createOrderValidationFailed: false,
            isCreateOrder: false
        }
    }

    public componentDidMount(): void {
        this.loadActionstepMatter();
    }

    private loadActionstepMatter(): void {

        const { actionstepContext } = this.props;
        let matterInfo: ActionstepMatterInfo | undefined = undefined;

        this.setState({
            ECAFConnected: true,
            currentStep: CreateOrderSteps.NotStarted,
            isCreateOrder: window.location.href.includes('create-order')
        });

        if (actionstepContext === undefined
            || actionstepContext.matterContext === undefined) {
            this.setState({
                pageError: " Actionstep Matter not found "
            });

            return;
        } else {
            matterInfo = new ActionstepMatterInfo({
                orgKey: actionstepContext.orgKey,
                matterId: actionstepContext.matterContext.id,
                termsEverAccepted: actionstepContext?.termsEverAccepted ?? false,
                latestTermsAccepted: actionstepContext?.latestTermsAccepted ?? false,
                matterName: ""
            });
        }

        this.setState({
            matterInfo: matterInfo,
            isLoading: true
        });
        this.props.getValidECAFCredential(matterInfo);
    }


    static getDerivedStateFromProps(nextProps: AppProps, prevState: AppStates): AppStates {
        let nextState = {} as AppStates;

        if (nextProps.gotResponse) {
            switch (nextProps.requestType) {
                case CONSTANTS.GET_MATTER_DETAILS_FROM_ACTIONSTEP_REQUESTED: {
                    nextState.isLoading = false;

                    if (!prevState.paymentMethod) {
                        nextState.paymentMethod = nextProps.matterDetails?.defaultPaymentMethod ?? "AccountHolderPays";
                    }
                    if (nextProps.success && nextProps.matterDetails?.clients?.length && prevState.clients.length === 0) {
                        nextState.clients = [...nextProps.matterDetails.clients];
                        let transaction = new ECAFTransaction();
                        transaction.propertyAddress = nextProps.matterDetails.propertyAddress;
                        transaction.titleReference = nextProps.matterDetails.titleReference;
                        transaction.isStandingAuthority = false;
                        nextState.transactions = [transaction];
                    } else if (prevState.clients.length === 0) {
                        nextState.clients = [new ECAFClient()];
                        let transaction = new ECAFTransaction();
                        transaction.isStandingAuthority = false;
                        nextState.transactions = [transaction];
                    }
                    break;
                }
                case CONSTANTS.CREATE_ORDER_REQUESTED: {
                    nextState.isLoading = false;
                    break;
                }
                case CONSTANTS.CREATE_ORDER_SUCCESS: {
                    nextState.isLoading = false;
                    if (nextProps.orderResponse?.success) {
                        nextState.currentStep = CreateOrderSteps.ECAFOrderPlaced;
                    } else {
                        nextState.pageError = nextProps.orderResponse?.error;
                    }
                    break;
                }
                case CONSTANTS.GET_VALID_ECAF_CREDENTIAL_FAILED: {
                    nextState.isLoading = false;
                    nextState.currentStep = CreateOrderSteps.ECAFNotConnected;
                    nextState.ECAFConnected = false;
                    break;
                }
                case CONSTANTS.GET_VALID_ECAF_CREDENTIAL_SUCCESS: {
                    nextState.isLoading = false;
                    if (!nextProps.eCAFConnected) {
                        nextState.currentStep = CreateOrderSteps.ECAFNotConnected;
                        nextState.ECAFConnected = false;
                    }
                    break;
                }

                default:
                    return nextState;
            }
        }
        return nextState;
    }

    public addClient = () => {
        var client = new ECAFClient();
        client.isIndividual = true;

        this.setState({
            clients: [...this.state.clients, client]
        });
    }

    public updateClient = (updatedClient: ECAFClient, index: number) => {
        var clients = [...this.state.clients];
        clients[index] = updatedClient;

        this.setState({
            clients: clients
        });
    }

    public requestMatterInformation = (party: string) => {
        let matterInfo = this.state.matterInfo!;
        matterInfo.matterName = party;
        this.setState({
            matterInfo: matterInfo,
            isLoading: true
        });
        this.props.getMatterDetailsFromActionstep(matterInfo);
    }

    public getECAFConnectionURL = () => {
        const { matterInfo } = this.state;
        let url = `/ecaf/connect?actionsteporg=${matterInfo?.orgKey}&matterid=${matterInfo?.matterId}`;
        return url;
    }

    public showSummaryPage = () => {
        const { matterInfo } = this.state;
        const { orderResponse } = this.props;
        let url = `/ecaf/summary?actionsteporg=${matterInfo?.orgKey}&matterid=${matterInfo?.matterId}&id=${orderResponse?.envelopeId}`;
        return url;
    }

    public removeParty = (index: number) => {
        var clients = [...this.state.clients];
        clients.splice(index, 1);
        this.setState({
            clients: [...clients],
        })
    }

    public addTransaction(): void {
        var transactions = this.state.transactions;
        var transaction = new ECAFTransaction();
        transaction.isStandingAuthority = false;

        this.setState({
            transactions: [...transactions, transaction]
        })
    }

    public removeTransaction = (index: number) => {
        var transactions = [...this.state.transactions];
        transactions.splice(index, 1);
        this.setState({
            transactions: [...transactions]
        })
    }

    public updateTransaction = (updatedTransaction: ECAFTransaction, index: number) => {
        var transactions = [...this.state.transactions];
        transactions[index] = updatedTransaction;

        this.setState({
            transactions: transactions
        });
    }

    private changeAuthorityType(value: string): void {
        var matterDetails = this.props.matterDetails;
        var transaction = new ECAFTransaction();

        if (value === "true") {
            transaction.isStandingAuthority = true;
            this.setState({
                isStandingAuthority: true,
                transactions: [transaction]
            })
        } else {
            transaction.propertyAddress = matterDetails?.propertyAddress;
            transaction.titleReference = matterDetails?.titleReference;
            transaction.isStandingAuthority = false;
            this.setState({
                isStandingAuthority: false,
                transactions: [transaction]
            })
        }
    }

    private closeCreateOrderError = () => {
        this.setState({
            createOrderValidationFailed: false,
        });
    }

    private changeInfo(newValue: any, key: string): void {
        if (key === 'deliveryAddressName') {
            this.setState({ deliveryAddressName: newValue });
        }

        if (key === 'deliveryEmail') {
            this.setState({ deliveryEmailAddress: newValue });
        }
    }

    createOrder = (event: React.FormEvent) => {
        event.preventDefault()
        const { actionstepContext, matterDetails } = this.props;
        const { notificationType, paymentMethod, isCreateOrder } = this.state;

        this.setState({
            createOrderValidationFailed: false,
        });

        let hasInvalidClient: boolean = false;
        for (let client of this.state.clients) {
            if (client.hasInvalidField) {
                hasInvalidClient = true;
                break;
            }
        }

        let hasInvalidTransaction: boolean = false;
        for (let transaction of this.state.transactions) {
            if (transaction.hasInvalidField) {
                hasInvalidTransaction = true;
                break;
            }
        }

        if (hasInvalidClient || hasInvalidTransaction) {
            this.setState({
                createOrderValidationFailed: true,
            })
            return;
        }

        var createOrderQuery = new CreateOrderQuery();
        createOrderQuery.actionstepOrg = actionstepContext!.orgKey;
        createOrderQuery.matterId = actionstepContext!.matterContext!.id!;
        createOrderQuery.clients = [...this.state.clients];
        createOrderQuery.transactions = [...this.state.transactions];
        createOrderQuery.notificationType = notificationType;
        createOrderQuery.ecafRepresentative = matterDetails?.representative;
        createOrderQuery.paymentMethod = paymentMethod;
        createOrderQuery.voi = !isCreateOrder;
        createOrderQuery.deliveryAddressName = this.state.deliveryAddressName;
        createOrderQuery.deliveryEmailAddress = this.state.deliveryEmailAddress;

        this.props.createOrder(createOrderQuery);
        this.setState({
            isLoading: true,
        })
    }

    private updatePreferenceCallBack = (type: string, party: string) => {
        this.setState({
            notificationType: type,
            partyToMap: party
        });
        this.requestMatterInformation(party);
    }

    private renderPreferenceModal = () => {
        const { isLoading, notificationType, partyToMap } = this.state;

        if (isLoading) return null;
        if (notificationType && partyToMap) return null;
        let element = <PreferenceComponent
            ConfirmHandler={this.updatePreferenceCallBack} />;
        return element;
    }

    private renderSpinner = () => {
        let element =
            <div className="loading-section">
                <h1 className="header">
                    Passing your matter details to livesign...
                </h1>
                <div className="content-wrapper">
                    <div className="table-row">
                        <div className="table-cell"></div>
                        <div className="table-cell">
                            <img src="/images/arc-arrow.svg" className="img-arrow" />
                        </div>
                        <div className="table-cell"></div>
                    </div>
                </div>
                <div className="table-row">
                    <div className="table-cell cell-left">
                        <img src="/images/ActionStep logo_400x77.png" className="img-actionstep" />
                    </div>
                    <div className="table-cell cell-center">
                        <img src="/images/konekta_loading.svg" height="60" />
                    </div>
                    <div className="table-cell cell-right">
                        <img src="/images/livesign_logo.png" className="img-livesign" />
                    </div>
                </div>
            </div>;
        return element;
    }
    private renderPaymentMethodOptions = () => {
        const { paymentMethod } = this.state;
        let options = [];
        if (true || paymentMethod === "AccountHolderPays") {
            options.push(<MenuItem disabled={false} value={"AccountHolderPays"} key="account-holder-pays">Account Holder Pays</MenuItem>);
        }
        if (true || paymentMethod === "CustomerPays") {
            options.push(<MenuItem disabled={false} value={"CustomerPays"} key="customer-pay">Customer Pays</MenuItem>);
        }

        return options;
    }
    render() {
        const { isLoading, pageError, clients, transactions, isStandingAuthority, currentStep, isCreateOrder } = this.state;
        const { actionstepContext, matterDetails } = this.props;
        if (currentStep === CreateOrderSteps.ECAFNotConnected) {
            return <Redirect to={this.getECAFConnectionURL()} />
        }

        if (currentStep === CreateOrderSteps.ECAFOrderPlaced) {
            return <Redirect to={this.showSummaryPage()} />
        }

        return (
            <div className="create-order-form">
                {pageError &&
                    <MessageBar messageBarType={MessageBarType.error}>
                        {pageError}
                    </MessageBar>
                }
                {this.renderPreferenceModal()}

                {isLoading ?
                    this.renderSpinner() :
                    <form
                        onSubmit={(e) => this.createOrder(e)}
                        autoComplete="off"
                        className="flex-form"
                    >
                        <MuiThemeProvider theme={formLabelsTheme}>
                            <div className="section">
                                <div className="section-name"> YOUR DETAILS </div>

                                <Grid container spacing={3}>
                                    <Grid item xs={6}>
                                        <TextField
                                            id="orgKey"
                                            label="Organization Key"
                                            variant="outlined"
                                            className="text-field"
                                            size="small"
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            defaultValue={actionstepContext?.orgKey ?? ""}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            id="orderReference"
                                            label="Reference"
                                            variant="outlined"
                                            className="text-field"
                                            size="small"
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            defaultValue={actionstepContext?.matterContext?.id.toString() ?? ""}

                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            id="deliveryAddressName"
                                            label="Results Delivered To First Name"
                                            variant="outlined"
                                            className="text-field"
                                            size="small"
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.changeInfo(event.target.value, "deliveryAddressName")}
                                            defaultValue={matterDetails?.deliveryAddressName}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            id="deliveryEmail"
                                            label="Results Delivered To Email"
                                            variant="outlined"
                                            className="text-field"
                                            size="small"
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.changeInfo(event.target.value, "deliveryEmail")}
                                            defaultValue={matterDetails?.deliveryEmailAddress}

                                        />
                                    </Grid>
                                </Grid>
                            </div>

                            <Divider variant="middle" />
                            <div className="section">
                                <div className="section-name"> PAYMENT METHOD </div>

                                <Grid container spacing={3}>
                                    <Grid item xs={6}>
                                        <Select
                                            id="paymentMethod"
                                            label="Payment method"
                                            variant="outlined"
                                            className="text-field"
                                            value={this.state.paymentMethod}
                                            onChange={(event) => {
                                                if (typeof event.target.value === 'string') {
                                                    this.setState({ paymentMethod: event.target.value });
                                                }
                                            }}>
                                            {this.renderPaymentMethodOptions()}
                                        </Select>
                                    </Grid>
                                </Grid>
                            </div>
                            <Divider variant="middle" />

                            {clients.map((client, index) => (
                                < ECafClientForm key={"client" + index} clientIndex={index}
                                    client={client}
                                    updateClient={this.updateClient}
                                    removeParty={this.removeParty}
                                />
                            ))}

                            {clients.length < 5 ?
                                <>
                                    <Divider variant="inset" />
                                    <Fab variant="extended" className="add-btn" size="small" onClick={this.addClient}>
                                        <AddIcon />
                                        Add Client
                                    </Fab>
                                </>
                                : <Divider variant="middle" />
                            }
                            {isCreateOrder &&
                                <><div className="section">
                                    <FormControl required>
                                        <FormLabel className="section-name">
                                            <b>AUTHORITY TYPE</b>
                                        </FormLabel>
                                        <RadioGroup
                                            aria-label="authority-type"
                                            name="Authority Type"
                                            value={String(isStandingAuthority)}
                                            onChange={(event, newValue) => this.changeAuthorityType(newValue)}
                                        >
                                            <FormControlLabel
                                                value="false"
                                                control={<Radio color="primary" />}
                                                label="Specific Authority" />
                                            <FormControlLabel
                                                value="true"
                                                control={<Radio color="primary" />}
                                                label="Standing Authority" />
                                        </RadioGroup>
                                    </FormControl>
                                </div><Divider variant="middle" />
                                    {transactions.map((transaction, index) => (
                                        < ECafTransactionForm key={"transaction" + index} transactionIndex={index}
                                            transaction={transaction}
                                            isStandingAuthority={this.state.isStandingAuthority}
                                            removeTransaction={this.removeTransaction}
                                            updateTransaction={this.updateTransaction}
                                        />
                                    ))
                                    }</>}

                            {isCreateOrder ?
                                transactions.length < 5 && !isStandingAuthority ?
                                    <>
                                        <Divider variant="inset" />
                                        <Fab variant="extended" className="add-btn" size="small" onClick={() => this.addTransaction()}>
                                            <AddIcon />
                                            Add Transaction
                                        </Fab>
                                    </>
                                    :
                                    <Divider variant="middle" />
                                : <div></div>}


                            <Button variant="contained" size="small"
                                className="create-order-btn" type="submit"
                                color="primary" >
                                {isCreateOrder ? 'Create Order' : 'Request VOI'}
                            </Button>

                            {this.state.createOrderValidationFailed &&
                                <Grid container justify='flex-end'>
                                    <Grid item xs={6} className="validation-error">
                                        <MessageBar messageBarType={MessageBarType.error}
                                            onDismiss={this.closeCreateOrderError}
                                            dismissButtonAriaLabel="Close"
                                        >
                                            Please review the form.
                                        </MessageBar>
                                    </Grid>
                                </Grid>
                            }
                        </MuiThemeProvider>

                    </form>

                }

            </div>

        );
    }
}

const mapStateToProps = (state: AppState) => {
    return {
        gotResponse: state.eCaf.gotResponse,
        success: state.eCaf.success,
        requestType: state.eCaf.requestType,
        actionstepContext: state.common.actionstepContext,
        matterDetails: state.eCaf.matterDetails,
        eCAFConnected: state.eCaf.eCAFConnected,
        orderResponse: state.eCaf.orderResponse

    }
}

const mapDispatchToProps = {
    getMatterDetailsFromActionstep,
    getValidECAFCredential,
    createOrder

}

export default connect(mapStateToProps, mapDispatchToProps)(CreateECAFOrder);