import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router/immutable';
import createSagaMiddleware from 'redux-saga';
import { History } from 'history';

import createReducer from 'redux/reducers';
import rootSaga from 'redux/sagas'
import LogRocket from 'logrocket';
import { AppState } from 'app.types';

const sagaMiddleware = createSagaMiddleware();

export default function configureStore(initialState = {}, history: History<any>) {

    // Create the store with two middlewares
    // 1. sagaMiddleware: Makes redux-sagas work
    // 2. routerMiddleware: Syncs the location/URL path to the state

    const middlewares = [
        sagaMiddleware,
        routerMiddleware(history),
        LogRocket.reduxMiddleware()
    ];

    const enhancers = [applyMiddleware(...middlewares)];

    const rootReducer = createReducer(history);

    const store = createStore(
        rootReducer,
        <AppState>initialState,
        compose(...enhancers),
    );

    sagaMiddleware.run(rootSaga);

    return store;
}