export const GENERATE_PDF_REQUESTED = 'SettlementInfoReducer/GENERATE_PDF_REQUESTED'
export const GENERATE_WATER_USAGE_PDF_REQUESTED = 'SettlementInfoReducer/GENERATE_WATER_USAGE_PDF_REQUESTED'
export const GENERATE_PDF_SUCCESS = 'SettlementInfoReducer/GENERATE_PDF_SUCCESS'
export const GENERATE_PDF_FAILED = 'SettlementInfoReducer/GENERATE_PDF_FAILED'

export const GENERATE_STATEMENT_OF_ACCOUNT_REQUESTED = 'SettlementInfoReducer/GENERATE_STATEMENT_OF_ACCOUNT_REQUESTED'
export const GENERATE_STATEMENT_OF_ACCOUNT_SUCCESS = 'SettlementInfoReducer/GENERATE_STATEMENT_OF_ACCOUNT_SUCCESS'
export const GENERATE_STATEMENT_OF_ACCOUNT_FAILED = 'SettlementInfoReducer/GENERATE_STATEMENT_OF_ACCOUNT_FAILED'

export const SAVE_PDF_REQUESTED = 'SettlementInfoReducer/SAVE_PDF_REQUESTED'
export const SAVE_PDF_SUCCESS = 'SettlementInfoReducer/SAVE_PDF_SUCCESS'
export const SAVE_PDF_FAILED = 'SettlementInfoReducer/SAVE_PDF_FAILED'

export const SAVE_SETTLEMENT_MATTER_REQUESTED = 'SettlementInfoReducer/SAVE_SETTLEMENT_MATTER_REQUESTED'
export const SAVE_SETTLEMENT_MATTER_SUCCESS = 'SettlementInfoReducer/SAVE_SETTLEMENT_MATTER_SUCCESS'
export const SAVE_SETTLEMENT_MATTER_FAILED = 'SettlementInfoReducer/SAVE_SETTLEMENT_MATTER_FAILED'

export const DELETE_SETTLEMENT_MATTER_REQUESTED = 'SettlementInfoReducer/DELETE_SETTLEMENT_MATTER_REQUESTED'
export const DELETE_SETTLEMENT_MATTER_SUCCESS = 'SettlementInfoReducer/DELETE_SETTLEMENT_MATTER_SUCCESS'
export const DELETE_SETTLEMENT_MATTER_FAILED = 'SettlementInfoReducer/DELETE_SETTLEMENT_MATTER_FAILED'

export const GET_SETTLEMENT_MATTER_REQUESTED = 'SettlementInfoReducer/GET_SETTLEMENT_MATTER_REQUESTED'
export const GET_SETTLEMENT_MATTER_SUCCESS = 'SettlementInfoReducer/GET_SETTLEMENT_MATTER_SUCCESS'
export const GET_SETTLEMENT_MATTER_FAILED = 'SettlementInfoReducer/GET_SETTLEMENT_MATTER_FAILED'

export const CHANGE_STATE = 'SettlementInfoReducer/CHANGE_STATE'

export const CLEAR_SETTLEMENT_INFO_STATE = 'SettlementInfoReducer/CLEAR_SETTLEMENT_INFO_STATE';