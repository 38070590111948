import * as React from 'react';
import 'containers/pexa/pexaRefresher.css';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { MessageBar, MessageBarType } from '@fluentui/react';
import { DealingNumberViewModel } from 'utils/newWcaApiTypes';
interface IMapStateToProps { }
interface IMapDispatchToProps { }
interface IProps {
    updatedState: any;
}

type AppProps = IMapStateToProps & IProps & IMapDispatchToProps;
//type AppStates = {}
const RefreshDealingNumber: React.FunctionComponent<AppProps> = (props) => {

    const pexaDealingNo = props.updatedState.pexaworkspaceDealingNumber;
    const actionstepDealingNo = props.updatedState.actionstepPropertyDetails;

    const concatenated = actionstepDealingNo.map((element: DealingNumberViewModel) => {
        const obj2 = pexaDealingNo.find((obj2: DealingNumberViewModel) => obj2.landTitleReference === element.landTitleReference);
        if (obj2 != undefined) {
            return {
                'pexaDealingNumber': obj2.dealingNumber,
                'pexaLandTitleReference': obj2.landTitleReference,
                'pexaDealingNumberRegistrationDate': obj2.dealingNumberDate,
                'asDealingNumber': element.dealingNumber,
                'asLandTitleReference': element.landTitleReference
            }
        } else {
            return {
                'pexaDealingNumber': 'not available',
                'pexaLandTitleReference': 'not available',
                'pexaDealingNumberRegistrationDate': 'not available',
                'asDealingNumber': element.dealingNumber,
                'asLandTitleReference': element.landTitleReference
            }
        }

    });

    return (
        <div id='dealingNumber' className="modal-body" >
            <TableContainer component={Paper}>
                <Table ria-label="simple table">
                    <TableHead>
                        <TableRow>

                            <TableCell className='fssLineItems-th' align="right">Pexa Land Title</TableCell>
                            <TableCell className='fssLineItems-th' align="right">Pexa Date</TableCell>
                            <TableCell className='fssLineItems-th' align="right">Pexa Dealing Number</TableCell>
                            <TableCell className='fssLineItems-th' align="right">Actionstep Land Title</TableCell>
                            <TableCell className='fssLineItems-th' align="right">Actionstep Dealing Number</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {concatenated.length > 0 ? concatenated.map((element: any, index: number) =>
                            <TableRow key={index}>

                                <TableCell align="center">{element.pexaLandTitleReference ?? "not available"}</TableCell>
                                <TableCell align="center">{element.pexaDealingNumberRegistrationDate ?? "not available"}</TableCell>
                                <TableCell align="center">{element.pexaDealingNumber ?? "not available"}</TableCell>
                                <TableCell align="center">{element.asLandTitleReference ?? "not available"}</TableCell>
                                <TableCell align="center">{element.asDealingNumber ?? "not available"}</TableCell>
                            </TableRow>
                        ) : <MessageBar messageBarType={MessageBarType.info}>No land title reference found in actionstep.</MessageBar>}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>

    )
}
export default RefreshDealingNumber;