import * as React from 'react';
import { TextField, Dropdown } from 'office-ui-fabric-react/lib';
import { sourceOfFunds } from '../common';

interface IMapStateToProps { }
interface IMapDispatchToProps { }

interface IProps {
    updatedState: any;
    updateValue: (newValue: any, whichValue: string, needRefresh?: boolean) => void;
    balanceFunds: number;
}

type AppProps = IMapStateToProps & IProps & IMapDispatchToProps;
type AppStates = {}

export default class FundsDetails extends React.Component<AppProps, AppStates> {

    public render(): JSX.Element {
        const { updatedState } = this.props;

        return (
            <div className="modal-body">
                <div className="ms-Grid" dir="ltr">

                    <div className="ms-Grid-row modal-row">
                        <div className="ms-Grid-col ms-sm2">Funds Source : </div>
                        <div className="ms-Grid-col ms-sm6">
                            <Dropdown
                                placeholder='Select Funds Source'
                                data-cy='funds_source_input'
                                options={sourceOfFunds}
                                defaultSelectedKey={updatedState['sourceOfFunds']}
                                onChange={(ev, newItem) => this.props.updateValue(newItem!.key, 'sourceOfFunds')}
                            />
                        </div>

                        <div className="ms-Grid-col ms-sm2">Amount : </div>
                        <div className="ms-Grid-col ms-sm4">
                            <TextField
                                type="number"
                                data-cy="amount_input"
                                defaultValue={updatedState['amount']}
                                onChange={(ev, newText) => this.props.updateValue(newText, 'amount')}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}