import * as CONSTANTS from './constants'

import {
    ErrorViewModel,
    SaveUserAuditTrailDataCommand,
    GetUserAuditTrailDataResponse   
} from 'utils/wcaApiTypes'

import {
    ActionFailed,
    ClearUserAuditTrailState,
    GetUserAuditTrailData,
    GetUserAuditTrailDataSuccess,
    SaveUserAuditTrailData,
    SaveUserAuditTrailDataSuccess
} from './actionTypes'

export function getUserAuditTrailData(actionstepOrg: string, matterId: number): GetUserAuditTrailData {
    return {
        type: CONSTANTS.GET_USER_AUDIT_TRAIL_DATA_REQUESTED,
        actionstepOrg,
        matterId
    }
}

export function getUserAuditTrailDataSuccess(data: GetUserAuditTrailDataResponse): GetUserAuditTrailDataSuccess {
    return {
        type: CONSTANTS.GET_USER_AUDIT_TRAIL_DATA_SUCCESS,
        data
    }
}

export function getUserAuditTrailDataFailed(error: ErrorViewModel): ActionFailed {
    return {
        type: CONSTANTS.GET_USER_AUDIT_TRAIL_DATA_FAILED,
        error
    }
}

export function saveUserAuditTrailData(params: SaveUserAuditTrailDataCommand): SaveUserAuditTrailData {
    return {
        type: CONSTANTS.SAVE_USER_AUDIT_TRAIL_DATA_REQUESTED,
        params
    }
}

export function saveUserAuditTrailDataSuccess(data: boolean): SaveUserAuditTrailDataSuccess {
    return {
        type: CONSTANTS.SAVE_USER_AUDIT_TRAIL_DATA_SUCCESS,
        data
    }
}

export function saveUserAuditTrailDataFailed(error: ErrorViewModel): ActionFailed {
    return {
        type: CONSTANTS.SAVE_USER_AUDIT_TRAIL_DATA_FAILED,
        error
    }
}

export function clearUserAuditTrailState(): ClearUserAuditTrailState {
    return {
        type: CONSTANTS.CLEAR_USER_AUDIT_TRAIL_STATE
    }
}