import * as React from 'react';
import { AppState } from 'app.types';
import { connect } from 'react-redux';
import { MessageBar, MessageBarType } from 'office-ui-fabric-react'
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import { PrimaryButton } from "office-ui-fabric-react/lib/Button";
import * as CONSTANTS from 'containers/ecaf/redux/constants';
import 'containers/ecaf/statusPage.css'
import { renderStatus } from './util/util';

import {
    Divider
} from "@material-ui/core";

import {
    getEnvelopeSummary
} from 'containers/ecaf/redux/actions';

import {
    EnvelopeDetailsCustomerViewModel
} from '../../utils/wcaApiTypes';
import { Redirect } from 'react-router-dom';
import Tools from 'utils/tools';

interface IAppProps {
}

type AppStates = {
    envelopeId: string | undefined,
    isLoading: boolean,
    pageError: string | undefined,
    redirect: string,
    customerId: string
}

type AppProps = IAppProps & ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

class ECAFSummaryPage extends React.Component<AppProps, AppStates> {

    constructor(props: Readonly<AppProps>) {
        super(props);

        this.state = {
            envelopeId: undefined,
            isLoading: true,
            pageError: undefined,
            redirect: "",
            customerId: ""
        }
    }

    public componentDidMount(): void {
        this.loadSummary();
    }

    private loadSummary = () => {
        let id = new URLSearchParams(window.location.search).get("id");
        let customerId = new URLSearchParams(window.location.search).get("customerId");

        if (id && this.isValidGuid(id)) {
            this.setState({
                envelopeId: id,
                isLoading: true,
                customerId: customerId!
            });
            this.props.getEnvelopeSummary(id);

        } else {
            this.setState({
                isLoading: false,
                pageError: "The envelope id in URL is invalid",
                customerId: ""
            });
        }
    }

    private isValidGuid = (id: string | undefined | null) => {
        if (!id) return false;

        var pattern = new RegExp(/^([0-9A-Fa-f]{8}[-][0-9A-Fa-f]{4}[-][0-9A-Fa-f]{4}[-][0-9A-Fa-f]{4}[-][0-9A-Fa-f]{12})$/);
        if (id.match(pattern)) return true;

        return false;
    }

    private redirectToHome = () => {
        this.setState({ redirect: "/matter" });
    }

    private renderCustomers = () => {
        const { envelopeDetails } = this.props;
        const { customerId } = this.state;

        if (!Tools.isNullOrEmptyOrUndefined(customerId)) {

            let customer: EnvelopeDetailsCustomerViewModel[] = [];
            let customerInfo = envelopeDetails?.customers?.find(customer => customer.id === customerId);
            customer.push(customerInfo!)
            envelopeDetails!.customers = customer
        }

        let customers = envelopeDetails?.customers?.map((cust: EnvelopeDetailsCustomerViewModel, i: number) => {
            return (
                <div className="customer-wrapper" key={cust.id} >
                    <div className="ms-Grid-row" >
                        <div className="ms-Grid-col ms-sm12 ms-lg4">
                            <p><b> Name :</b></p>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-lg4">
                            <p>{cust.firstName} {cust.lastName}</p>
                        </div>
                    </div>
                    <div className="ms-Grid-row" >
                        <div className="ms-Grid-col ms-sm12 ms-lg4">
                            <p><b> Email :</b></p>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-lg4">
                            <p>{cust.emailAddress}</p>
                        </div>
                    </div>
                    <div className="ms-Grid-row" >
                        <div className="ms-Grid-col ms-sm12 ms-lg4">
                            <p><b> Mobile :</b></p>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-lg4">
                            <p>{cust.fullyQualifiedMobileNumber}</p>
                        </div>
                    </div>
                </div>)
        });
        return customers;
    }

    private renderDisbursementNotice = (paymentMethod: string) => {
        let element = null;
        if (paymentMethod === "AccountHolderPays") {
            element = <small className="small-text">**NOTE A disbursement will automatically be recorded on the matter</small>;
        }
        return element;
    }


    static getDerivedStateFromProps(nextProps: AppProps, prevState: AppStates): AppStates {
        let nextState = {} as AppStates;

        if (nextProps.gotResponse) {
            switch (nextProps.requestType) {
                case CONSTANTS.GET_ECAF_ENVELOPE_SUMMARY_REQUESTED: {
                    nextState.isLoading = false;
                    break;
                }
                case CONSTANTS.GET_ECAF_ENVELOPE_SUMMARY_SUCCESS:
                    nextState.isLoading = false;
                    break;
                default:
                    return nextState;
            }
        }
        return nextState;
    }


    render() {
        const { isLoading, pageError, customerId } = this.state;
        const { envelopeDetails } = this.props;

        if (envelopeDetails && !Tools.isNullOrEmptyOrUndefined(customerId)) {
            const customer = envelopeDetails.customers?.find(customer => customer.id === customerId)
            envelopeDetails.totalPrice = customer!.price;
        }

        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }

        return (
            <div>
                <div className="vertical-container wrapper-content animated fadeIn" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-lg12 ">
                            <h3 className="tittle">
                                <big><b>livesign Summary</b></big>
                            </h3>
                            <br />
                            {isLoading ?
                                <Spinner size={SpinnerSize.large} label="loading eCAF envelope..." />
                                :
                                <>
                                    {pageError &&
                                        <MessageBar messageBarType={MessageBarType.error}>
                                            Error: {pageError}
                                        </MessageBar>
                                    }

                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-sm12 ms-lg4">
                                            <p><b> Envelope Name :</b></p>
                                        </div>
                                        <div className="ms-Grid-col ms-sm12 ms-lg4">
                                            <p>{envelopeDetails?.name}</p>
                                        </div>
                                    </div>
                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-sm12 ms-lg4">
                                            <p ><b> Envelope Status :</b></p>
                                        </div>
                                        <div className="ms-Grid-col ms-sm12 ms-lg4">
                                            <div className={('status-' + envelopeDetails?.status) + " status-shape"}> {renderStatus(envelopeDetails?.status)}</div>
                                        </div>
                                    </div>

                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-sm12 ms-lg4">
                                            <p><b> Pay Method :</b></p>
                                        </div>
                                        <div className="ms-Grid-col ms-sm12 ms-lg8">
                                            <p>{envelopeDetails?.payMethod}</p>
                                        </div>
                                    </div>

                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-sm12 ms-lg4">
                                            <p><b> Total Price :</b></p>
                                        </div>
                                        <div className="ms-Grid-col ms-sm12 ms-lg4">
                                            <p>${envelopeDetails?.totalPrice?.toFixed(2)}</p>
                                            {this.renderDisbursementNotice(envelopeDetails?.payMethod!)}
                                        </div>
                                    </div>

                                    <Divider variant="middle" />
                                    <br />

                                    {this.renderCustomers()}

                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-sm12 ms-lg4 ms-lgPush8">
                                            <PrimaryButton
                                                text="Return to konekta"
                                                onClick={() => this.redirectToHome()}
                                            />
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: AppState) => {
    return {
        error: state.eCaf.error,
        gotResponse: state.eCaf.gotResponse,
        requestType: state.eCaf.requestType,
        envelopeDetails: state.eCaf.envelopeDetails,
    }
}

const mapDispatchToProps = {
    getEnvelopeSummary
}

export default connect(mapStateToProps, mapDispatchToProps)(ECAFSummaryPage);