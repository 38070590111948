import { makeStyles } from '@material-ui/core/styles';

const legalaidStyle = makeStyles((theme) => ({
    root: {
        // Default Material-UI styles
        backgroundColor: theme.palette.primary.main,
        padding: theme.spacing(2),
        // ... other default styles
    },
    // Your custom styles
    generatePDFButton: {
        backgroundColor: '#004989',
        color: '#ffffff',
        '&:hover': {
            backgroundColor: '#003979',
            color: '#fff'

        }
        // ... other custom styles
    },
    table: {
        minWidth: 650,
        "& .MuiTableCell-root": {
            borderLeft: "1px solid rgba(224, 224, 224, 1)",
            borderRight: "1px solid rgba(224, 224, 224, 1)",
            borderTop: "1px solid rgba(224, 224, 224, 1)"
        }
    }
}));

export default legalaidStyle;