import React from "react";
import "./stewartTitleInsurance.css";
import { ErrorViewModel } from "utils/wcaApiTypes";
import { connect } from "react-redux";
import { AppState } from "app.types";
import { orderStewartTitlePolicy, getMatterDetailsFromActionstep } from "./redux/actions"; import {
    StewartTitleActionstepMatter, OrderStewartTitlePolicyCommandResponse, OrderStewartTitlePolicyCommand
} from "./utils/stewartTitleWCAApiTypes";
import LoadingWidget from "components/common/loadingWidget";
import PolicySuccess from "./components/policySuccess/policySuccess";
import { RequestPolicySteps } from "./components/constants";
import QuestionnaireComponent from "./components/questionnaireComponent";
import { Icon, Link } from "@fluentui/react";
import * as CONSTANTS from './redux/constants';

interface IAppProps { }

type AppStates = {
    actionstepMatter: StewartTitleActionstepMatter | undefined,
    orderStewartTitlePolicyCommandResponse: OrderStewartTitlePolicyCommandResponse | undefined,
    currentStep: RequestPolicySteps,
    error: ErrorViewModel | undefined,
    isLoading: boolean
}

type AppProps = IAppProps & ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

class StewartTitleInsurance extends React.Component<AppProps, AppStates> {

    constructor(props: AppProps) {
        super(props);

        this.state = {
            actionstepMatter: undefined,
            orderStewartTitlePolicyCommandResponse: undefined,
            currentStep: RequestPolicySteps.NotStarted,
            error: undefined,
            isLoading: false
        };
    }

    componentDidMount(): void {
        this.getMatterInformation();
    }

    private getMatterInformation() {
        const { actionstepContext } = this.props;
        if (actionstepContext === undefined || actionstepContext.matterContext === undefined) {
            return;
        }

        this.props.getMatterDetailsFromActionstep(actionstepContext.orgKey, actionstepContext.matterContext.id);
    }

    static getDerivedStateFromProps(nextProps: AppProps, prevState: AppStates): AppStates {
        let nextState = {} as AppStates;
        if (nextProps.gotResponse) {
            switch (nextProps.requestType) {
                case CONSTANTS.ORDER_STEWART_TITLE_POLICY_REQUESTED:
                case CONSTANTS.GET_STEWART_TITLE_MATTER_FROM_ACTIONSTEP_REQUESTED: {
                    nextState.currentStep = RequestPolicySteps.NotStarted;
                    nextState.isLoading = true;
                    break;
                }
                case CONSTANTS.GET_STEWART_TITLE_MATTER_FROM_ACTIONSTEP_SUCCESS: {
                    nextState.isLoading = false;
                    let currentLocalDate = new Date();
                    nextState.actionstepMatter = nextProps.matterDetails;
                    let settlementDate = nextState.actionstepMatter?.stewartTitleProperty?.settlementDate;
                    let date = new Date(settlementDate!.getFullYear(), settlementDate!.getMonth(), settlementDate!.getDate(), currentLocalDate.getHours(), currentLocalDate.getMinutes(), currentLocalDate.getSeconds()); // fix the issue for the backend auto change GMT +9:30
                    nextState.actionstepMatter!.stewartTitleProperty!.settlementDate = date;
                    nextState.currentStep = RequestPolicySteps.Questionnaire;
                    break;
                }
                case CONSTANTS.ORDER_STEWART_TITLE_POLICY_SUCCESS: {
                    nextState.isLoading = false;
                    nextState.orderStewartTitlePolicyCommandResponse = nextProps.orderStewartTitlePolicyCommandResponse;
                    if (nextState.orderStewartTitlePolicyCommandResponse?.errors && nextState.orderStewartTitlePolicyCommandResponse?.errors?.length > 0) {
                        nextState.currentStep = RequestPolicySteps.Questionnaire;
                    }
                    else {
                        nextState.currentStep = RequestPolicySteps.Success;
                    }
                    break;
                }
                case CONSTANTS.ORDER_STEWART_TITLE_POLICY_FAILED: {
                    nextState.currentStep = RequestPolicySteps.Questionnaire;
                    break;
                }
                case CONSTANTS.GET_STEWART_TITLE_MATTER_FROM_ACTIONSTEP_FAILED: {
                    nextState.isLoading = false;
                    nextState.error = nextProps.error
                    nextState.currentStep = RequestPolicySteps.Failed;
                    break;
                }
            }
        }
        return nextState;
    }

    public placeOrder = (actionstepMatterData: StewartTitleActionstepMatter) => {
        // const { actionstepMatter } = this.state;
        const { actionstepContext } = this.props;
        const params: OrderStewartTitlePolicyCommand = new OrderStewartTitlePolicyCommand({
            actionstepMatter: actionstepMatterData,
            matterId: actionstepContext?.matterContext?.id != undefined ? actionstepContext?.matterContext?.id : 0,
            actionstepOrgKey: actionstepContext?.orgKey
        });

        this.props.orderStewartTitlePolicy(params);

        this.setState({
            currentStep: RequestPolicySteps.Requested,
            actionstepMatter: actionstepMatterData
        });
    }

    render(): JSX.Element {
        const { currentStep } = this.state;

        if (currentStep === RequestPolicySteps.NotStarted) {
            return (
                <div className="stewarttitle-page-container">
                    <LoadingWidget />
                </div>
            );
        }

        if (currentStep === RequestPolicySteps.Requested) {
            return (
                <div className="stewarttitle-page-container">
                    <LoadingWidget message="Sending your order to Stewart Title and waiting for their response..." />
                </div>
            );
        }

        if (currentStep === RequestPolicySteps.Questionnaire) {
            return (
                <QuestionnaireComponent
                    //  updateInformation={this.updateInformation}
                    //  updateDropdown={this.updateDropdown}
                    //  handleFileUpload={this.handleFileUpload}
                    placeOrder={this.placeOrder}
                    actionstepMatter={this.state.actionstepMatter}
                    policyRequestError={this.state.orderStewartTitlePolicyCommandResponse?.errors}
                />
            )
        }

        if (currentStep === RequestPolicySteps.Success) {
            return (
                <PolicySuccess orderStewartTitlePolicyCommandResponse={this.props.orderStewartTitlePolicyCommandResponse!} />
            )
        }

        return (
            <div className="wrapper wrapper-content animated fadeInRight vertical-container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="panel panel-danger">
                            <div className="panel-heading">
                                <Icon iconName="StatsCircleExclamation" />{" "}
                                <span className="m-l-xs">Sorry, something went wrong</span>
                            </div>
                            <div className="panel-body p-v-xs">
                                <p className="text-danger">
                                    Please contact{" "}
                                    <Link
                                        href={
                                            "mailto:support@konekta.com.au?subject=StewartTitle%20Insurance"
                                        }
                                    >
                                        {" "}
                                        support@konekta.com.au
                                    </Link>
                                    .
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state: AppState) => {
    return {
        gotResponse: state.stewartTitle.gotResponse,
        requestType: state.stewartTitle.requestType,
        matterDetails: state.stewartTitle.matterDetails?.actionstepData,
        orderStewartTitlePolicyCommandResponse: state.stewartTitle.orderStewartTitlePolicyCommandResponse,
        actionstepContext: state.common.actionstepContext,
        error: state.stewartTitle.error
    }
}

const mapDispatchToProps = {
    getMatterDetailsFromActionstep,
    orderStewartTitlePolicy
}

export default connect(mapStateToProps, mapDispatchToProps)(StewartTitleInsurance)