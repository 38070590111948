import * as React from 'react';
import { connect } from 'react-redux';
import { AppState } from 'app.types'; 
import { MessageBar, MessageBarType } from 'office-ui-fabric-react';
import { SubwayNavNodeState } from 'components/SubwayNav';
import { PrimaryButton } from "office-ui-fabric-react/lib/Button";

import {
    clearEmailArchiverResponse
} from 'containers/email-archiver/redux/actions';

interface IAppProps {
    onChangeStep: (newState: SubwayNavNodeState, newStep: number) => void;
}

type AppProps = IAppProps & ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

type AppStates = {

}

export class ProcessEmailArchiver extends React.Component<AppProps, AppStates> {

    constructor(props: Readonly<AppProps>) {
        super(props);       
    }
     
    private goBackToDataInput(): void {
        this.props.clearEmailArchiverResponse();
        this.props.onChangeStep(SubwayNavNodeState.Current, 0);
    }

    render() {            
        return (
            <div className="ms-Grid-row ms-sm12">    
                <MessageBar messageBarType={MessageBarType.success} isMultiline={true}>
                    Thank you. The email archiver process has started.
                    You will receive a notification email when the process started and completed.                 
                </MessageBar>                  
                <br />

                <PrimaryButton
                    className="button"
                    text="Archive other matters"
                    onClick={() => this.goBackToDataInput()}
                    allowDisabledFocus={true}
                />
            
            </div>
        );
    }
}

const mapStateToProps = (state: AppState) => {
    return {
       
    }
}

const mapDispatchToProps = {
    clearEmailArchiverResponse
}

export default connect(mapStateToProps, mapDispatchToProps)(ProcessEmailArchiver);