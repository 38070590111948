import * as React from 'react';
import { connect } from 'react-redux';
import { MessageBar, MessageBarType } from 'office-ui-fabric-react'
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import { PrimaryButton } from "office-ui-fabric-react/lib/Button";
import { LinearProgress, LinearProgressProps, Typography, Box } from '@material-ui/core';
import * as CONSTANTS from 'containers/email-archiver/redux/constants';
import { AppState } from 'app.types';
import "./emailArchiver.css"

import {
    getTransactionStatus,
    downloadReports,
    cancelTransaction
} from './redux/actions';


function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
    return (
        <Box display="flex" alignItems="bottom">
            <Box width="50%" m={1}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box minWidth={35}>
                <Typography variant="body2" className="percentageValue" color="textSecondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}


interface IAppProps {

}

type AppProps = IAppProps & ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

type AppStates = {
    transactionId: number | undefined,
    pageError: string | undefined,
    loadingStatus: boolean,
    downloadingReports: boolean,
    completedPercentage: number | undefined,
}

export class EmailArchiverTransactionStatus extends React.Component<AppProps, AppStates> {

    constructor(props: AppProps) {
        super(props);

        this.state = {
            transactionId: undefined,
            pageError: undefined,
            loadingStatus: false,
            downloadingReports: false,
            completedPercentage: undefined
        };
    }

    public componentDidMount(): void {

        let param = new URLSearchParams(window.location.search).get("transactionId");
        let transactionId = Number(param);

        if (!isNaN(transactionId)) {
            this.loadTransactionStatus(transactionId);
            this.setState({
                transactionId: transactionId
            });

        } else {
            this.setState({
                pageError: "The transaction Id in URL is invalid"
            });
        }
    }

    public loadTransactionStatus(transactionId: number): void {
        this.setState({
            loadingStatus: true
        });
        this.props.getTransactionStatus(transactionId);
    }

    public b64toBlob = (b64Data: any, contentType: any, sliceSize: any) => {
        contentType = contentType || '';
        sliceSize = sliceSize || 512;

        var byteCharacters = atob(b64Data);
        var byteArrays = [];

        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);

            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            var byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        var blob = new Blob(byteArrays, { type: contentType });
        return blob;
    };

    public downloadReports(transactionId: number) {
        this.setState({
            downloadingReports: true
        });

        this.props.downloadReports(transactionId);
    }
    public cancelProcessingTransaction(transactionId: number) {
        this.props.cancelTransaction(transactionId);
    }

    static getDerivedStateFromProps(nextProps: AppProps, prevState: AppStates): AppStates {
        let nextState = {} as AppStates;

        if (nextProps.gotResponse) {
            switch (nextProps.requestType) {
                case CONSTANTS.GET_TRANSACTION_STATUS_REQUESTED: {
                    nextState.loadingStatus = false;
                    if (nextProps.success) {
                        if (nextProps.transactionStatus?.processedMattersCount && nextProps.transactionStatus?.selectedMattersCount) {
                            nextState.completedPercentage = Math.round((nextProps.transactionStatus?.processedMattersCount * 100 / nextProps.transactionStatus?.selectedMattersCount) * 100) / 100;
                        }
                    }
                    else {
                        nextState.completedPercentage = 0;
                    }
                    break;
                }
                case CONSTANTS.DOWNLOAD_REPORTS_REQUESTED: {
                    nextState.downloadingReports = false;
                    break;
                }
                default:
                    return nextState;
            }
        }
        return nextState;
    }

    render() {
        const { transactionId, pageError, loadingStatus, completedPercentage, downloadingReports } = this.state;
        const { transactionStatus, error, isAuthorisedUser } = this.props;
        return (
            <div>
                <div className="vertical-container wrapper-content animated fadeIn" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-lg12 ">
                            <h3 className="tittle">
                                <big><b>EMAIL ARCHIVER TRANSACTION STATUS</b></big>
                            </h3>
                            <br />
                            {loadingStatus ?
                                <Spinner size={SpinnerSize.large} label="loading transaction status..." />
                                :
                                <>
                                    {pageError &&
                                        <MessageBar messageBarType={MessageBarType.error}>
                                            Error: {pageError}
                                        </MessageBar>
                                    }

                                    <p>Updated Time: {new Date().toLocaleTimeString()}</p>
                                    <br />
                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-lg3">
                                            <p><b> Completed percentage </b></p>
                                        </div>
                                        <div className="ms-Grid-col ms-lg9">
                                            <LinearProgressWithLabel className="progressBar" value={completedPercentage ?? 0} />
                                        </div>
                                    </div>

                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-lg3">
                                            <p><b> Transaction Status </b></p>
                                        </div>
                                        <div className="ms-Grid-col ms-lg9">
                                            <p> Transaction Id : {transactionId} </p>
                                            <p> From Organization Key: {transactionStatus?.sourceOrgKey} </p>
                                            <p> To Organization Key: {transactionStatus?.destinationOrgKey} </p>
                                            <p><b> Transaction status: {transactionStatus?.processingStatus} </b></p>
                                            {(transactionStatus?.processingMatterId !== 0) &&
                                                <p><b> Processing matterId: {transactionStatus?.processingMatterId} </b></p>
                                            }
                                            <p> Send emails and create tickets status: {transactionStatus?.sendNotificationStatus} </p>
                                        </div>
                                    </div>
                                    <br />

                                    {transactionStatus?.processingStatus === "ProcessedWithErrors" &&
                                        <>
                                            <div className="ms-Grid-row">
                                                <div className="ms-Grid-col ms-lg3">
                                                    <p><b> Errors </b></p>
                                                </div>
                                                <div className="ms-Grid-col ms-lg9">
                                                    <p> Last error: {transactionStatus?.lastError} </p>
                                                </div>
                                            </div>
                                            <br />
                                        </>
                                    }

                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-lg3">
                                            <p><b> Filter Options </b></p>
                                        </div>
                                        {transactionStatus?.filterOptions &&
                                            <>
                                                <div className="ms-Grid-col ms-lg9">
                                                    {transactionStatus?.filterOptions &&
                                                        <p> Seleted ALL filtered matters: {transactionStatus?.filterOptions?.selectedAllFilteredActions === true ? "True" : "False"} </p>}
                                                    {transactionStatus?.filterOptions?.selectedActionStatus &&
                                                        <p> Matter Status: {transactionStatus?.filterOptions?.selectedActionStatus} </p>
                                                    }
                                                    {transactionStatus?.filterOptions?.searchText &&
                                                        <p> Last search text: {transactionStatus?.filterOptions?.searchText} </p>
                                                    }
                                                    {transactionStatus?.filterOptions?.fromDate &&
                                                        <p> From date: {transactionStatus?.filterOptions?.fromDate} </p>
                                                    }

                                                    {transactionStatus?.filterOptions?.toDate &&
                                                        <p> To date: {transactionStatus?.filterOptions?.toDate} </p>
                                                    }

                                                    {transactionStatus?.filterOptions?.assignedTo &&
                                                        <p> Assigned to: {transactionStatus?.filterOptions?.assignedTo} </p>
                                                    }

                                                    {!transactionStatus?.filterOptions?.selectedAllFilteredActions && transactionStatus?.filterOptions?.actionsIds &&
                                                        <p> Matters: {transactionStatus?.filterOptions?.actionsIds} </p>
                                                    }

                                                <p> Will delete emails in SrcOrg: {transactionStatus?.willDeleteEmails === true ? "True" : "False"} </p>
                                                <p> No duplicate information in DestOrg: {transactionStatus?.notDuplicateInfo === true ? "True" : "False"} </p>
                                                </div>
                                            </>
                                        }
                                    </div>
                                    <br />

                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-lg3">
                                            <p><b> Matter Status </b></p>
                                        </div>
                                        <div className="ms-Grid-col ms-lg9">
                                            <p><b>  Number of seleted matters : {transactionStatus?.selectedMattersCount} </b>  </p>
                                            <p><b> Number of processed matters : {transactionStatus?.processedMattersCount} </b> </p>
                                            <p> Number of processed matters which have emails: {transactionStatus?.selectedMattersHaveEmailsCount} </p>
                                            <p>  Number of processed matters which is existing in DestOrg:        {transactionStatus?.existingMattersCount} </p>
                                            <p> Number of cloned matters: {transactionStatus?.clonedMattersCount} </p>
                                        </div>
                                    </div>
                                    <br />

                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-lg3">
                                            <p><b> Email Status </b></p>
                                        </div>
                                        <div className="ms-Grid-col ms-lg9">
                                            <p> Number of emails in the processed matters : {transactionStatus?.selectedEmailsCount} </p>
                                            <p>  Number of emails which is existing in DestOrg:        {transactionStatus?.existingEmailsCount}</p>
                                            <p> Number of cloned emails  : {transactionStatus?.clonedEmailsCount} </p>
                                            <p> Number of deleted emails in Source Organization: {transactionStatus?.deletedEmailsCount} </p>
                                        </div>
                                    </div>
                                    <br />

                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-lg3">
                                            <p><b> Attachment Status </b></p>
                                        </div>
                                        <div className="ms-Grid-col ms-lg9">
                                            <p> Number of email attachments in the processed emails : {transactionStatus?.selectedAttachmentsCount} </p>
                                            <p> Number of cloned email attachments : {transactionStatus?.clonedAttachmentsCount} </p>
                                            <p> Number of deleted email attachments in Source Organization : {transactionStatus?.deletedAttachmentsCount} </p>
                                        </div>
                                    </div>
                                    <br />
                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-sm3 "></div>
                                        <div className="ms-Grid-col ms-sm3 ">
                                            <PrimaryButton                                               
                                                text="Reload Status"
                                                disabled={loadingStatus}
                                                onClick={() => this.loadTransactionStatus(transactionId!)}
                                            />
                                        </div>

                                        {isAuthorisedUser &&
                                            <>
                                                <div className="ms-Grid-col ms-sm3 ">
                                                    <PrimaryButton                                                       
                                                        text={downloadingReports ? "Downloading ..." : "Download Reports"}
                                                        disabled={loadingStatus || downloadingReports || transactionStatus?.processingStatus === "Processing"}
                                                        onClick={() => this.downloadReports(transactionId!)}
                                                    />
                                            </div>

                                            {/**

                                                <div className="ms-Grid-col ms-sm3 ">
                                                    <PrimaryButton                                                    
                                                        text={"Cancel Transaction"}
                                                        disabled={loadingStatus || downloadingReports || transactionStatus?.processingStatus !== "Processing"}
                                                        onClick={() => this.cancelProcessingTransaction(transactionId!)}
                                                    />
                                            </div>
                                              **/}
                                            </>
                                          
                                        }
                                    </div>

                                    {error &&
                                        <MessageBar messageBarType={MessageBarType.error}>
                                            Error: {error}
                                        </MessageBar>}
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: AppState) => {
    return {
        error: state.emailArchiver.error,
        gotResponse: state.emailArchiver.gotResponse,
        success: state.emailArchiver.success,
        transactionStatus: state.emailArchiver.transactionStatus,
        requestType: state.emailArchiver.requestType,
        isAuthorisedUser: state.emailArchiver.isAuthorisedUser
    }
}

const mapDispatchToProps = {
    getTransactionStatus,
    downloadReports,
    cancelTransaction
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailArchiverTransactionStatus);