export const GENERATE_PDF_REQUESTED = 'specialMeterReadingInfoReducer/GENERATE_PDF_REQUESTED'
export const GENERATE_PDF_SUCCESS = 'specialMeterReadingInfoReducer/GENERATE_PDF_SUCCESS'
export const GENERATE_PDF_FAILED = 'specialMeterReadingInfoReducer/GENERATE_PDF_FAILED'

export const SAVE_PDF_REQUESTED = 'specialMeterReadingInfoReducer/SAVE_PDF_REQUESTED'
export const SAVE_PDF_SUCCESS = 'specialMeterReadingInfoReducer/SAVE_PDF_SUCCESS'
export const SAVE_PDF_FAILED = 'specialMeterReadingInfoReducer/SAVE_PDF_FAILED'

export const SAVE_SPECIAL_METER_READING_REQUESTED = 'specialMeterReadingInfoReducer/SAVE_SPECIAL_METER_READING_REQUESTED'
export const SAVE_SPECIAL_METER_READING_SUCCESS = 'specialMeterReadingInfoReducer/SAVE_SPECIAL_METER_READING_SUCCESS'
export const SAVE_SPECIAL_METER_READING_FAILED = 'specialMeterReadingInfoReducer/SAVE_SPECIAL_METER_READING_FAILED'

export const DELETE_SPECIAL_METER_READING_REQUESTED = 'specialMeterReadingInfoReducer/DELETE_SPECIAL_METER_READING_REQUESTED'
export const DELETE_SPECIAL_METER_READING_SUCCESS = 'specialMeterReadingInfoReducer/DELETE_SPECIAL_METER_READING_SUCCESS'
export const DELETE_SPECIAL_METER_READING_FAILED = 'specialMeterReadingInfoReducer/DELETE_SPECIAL_METER_READING_FAILED'

export const GET_SPECIAL_METER_READING_REQUESTED = 'specialMeterReadingInfoReducer/GET_SPECIAL_METER_READING_REQUESTED'
export const GET_SPECIAL_METER_READING_SUCCESS = 'specialMeterReadingInfoReducer/GET_SPECIAL_METER_READING_SUCCESS'
export const GET_SPECIAL_METER_READING_FAILED = 'specialMeterReadingInfoReducer/GET_SPECIAL_METER_READING_FAILED'

export const CHANGE_STATE = 'specialMeterReadingInfoReducer/CHANGE_STATE'

export const CLEAR_SPECIAL_METER_READING_INFO_STATE = 'specialMeterReadingInfoReducer/CLEAR_SPECIAL_METER_READING_INFO_STATE';