import * as React from 'react';
import { IconButton } from '@fluentui/react';
import { ModalIDs } from 'containers/calculators/settlement/common';
import SettlementInfo from './settlementInfo';
import './settlementCalculator.css';

interface IMapStateToProps { }
interface IMapDispatchToProps { }

interface IProps {
    toggleSection: (payee: string) => void;
    iconName: any;
    showModal: (modalID: ModalIDs, additionalInfo: any) => void;
    isVendor: boolean;
    additionalInfo?: { [key: string]: any; } | undefined;
    conveyType: string | undefined;
    payeeCheckboxHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
    ourRequirements?: { [key: string]: any; }[] | undefined;
}

type AppProps = IMapStateToProps & IProps & IMapDispatchToProps;
type AppStates = {}

export default class OurRequirementInfo extends React.Component<AppProps, AppStates> {
    public render(): JSX.Element {
        const { iconName: showOurRequirements, isVendor, additionalInfo, conveyType, ourRequirements } = this.props;

        return (
            <div className="section">
                <div className="right-align-section-header">
                    Show/Hide Our Requirements
                    <IconButton
                        className="button show-hide-button"
                        data-automation-id="save_button"
                        onClick={() => this.props.toggleSection("OurRequirements")}
                        allowDisabledFocus={true}
                        iconProps={{
                            iconName: showOurRequirements
                                ? "CaretDownSolid8"
                                : "CaretUpSolid8",
                            style: { fontSize: 10 },
                        }}
                    />
                </div>
                {showOurRequirements && (
                    <div className="section-body">
                        <div className="ms-Grid-row additional-header">
                            <div className="ms-Grid-col ms-sm8 ms-smPush2">
                                <b className="header-text">OUR REQUIREMENTS AT SETTLEMENT</b>
                            </div>
                        </div>

                        {ourRequirements &&
                            ourRequirements.map((ourRequirement, index) => {
                                return (
                                    <SettlementInfo
                                        isVendor={isVendor}
                                        key={"requirement_" + index}
                                        item={ourRequirement}
                                        index={index}
                                        additionalInfo={additionalInfo}
                                        showModal={(modalID, additionalInfo) =>
                                            this.props.showModal(modalID, additionalInfo)
                                        }
                                        payeeCheckboxHandler={(event) =>
                                            this.props.payeeCheckboxHandler(event)
                                        }
                                        additionalRequirementCheckboxHandler={() => {}}
                                        draggable={false}
                                        conveyType={conveyType}
                                    />
                                );
                            })}

                        <div className="ms-Grid-row state-row">
                            <div className="ms-Grid-col ms-sm2">
                                <IconButton
                                    data-cy="add_our_requirement"
                                    className="button rounded-blue-icon-button"
                                    data-automation-id="save_button"
                                    onClick={() =>
                                        this.props.showModal(ModalIDs.ourRequirements, {
                                            index: -1,
                                        })
                                    }
                                    allowDisabledFocus={true}
                                    iconProps={{
                                        iconName: "Add",
                                        style: {
                                            fontSize: 15,
                                            fontWeight: 900,
                                        },
                                    }}
                                />
                            </div>
                            <div className="ms-Grid-col ms-sm10">
                                <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row detail-row">
                                        <div className="ms-Grid-col ms-sm12 add-label">
                                            Click + to Add additional requirements
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>)}
            </div>
        )
    }
}