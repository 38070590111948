import * as React from 'react';
import { TextField, Dropdown, Separator } from 'office-ui-fabric-react/lib';
import { _onFormatDateDDMMYYYY } from 'utils/dataFormatter';
import { apportionmentOption } from 'containers/calculators/settlement/common';
import { getCTSOptions } from 'containers/calculators/settlement/Helper';
import CustomDatePickers from 'components/common/customDatePickers';
import '../VicWaterUsage.css'

interface IMapStateToProps { }
interface IMapDispatchToProps { }

interface IProps {
	updatedState: any;
	updateValue: (newValue: any, whichValue: string, needRefresh?: boolean) => void;
	stateValue: any
}

type AppProps = IMapStateToProps & IProps & IMapDispatchToProps;
type AppStates = {}

export default class VicWaterUsageModal extends React.Component<AppProps, AppStates> {

	public render(): JSX.Element {
		const updatedState = this.props.updatedState;
		const ctsOptions = getCTSOptions().sort((a, b) => (a.key > b.key) ? 1 : -1);

		return (
			<div className="modal-body">
				<Separator>
					<b>Readings</b>
				</Separator>
				<div className='ms-Grid-row modal-row'>
					<>
						<div className='ms-Grid-col ms-sm4'>Search Reading :</div>
						<div className='ms-Grid-col ms-sm4'>
							<CustomDatePickers
								disabled={updatedState['method'] === 'daily-average'}
								updateValue={this.props.updateValue}
								updatedState={this.props.updatedState}
								datacy="search_date_input"
								datePickerName="searchDate"
							/>
						</div>
					</>
				</div>

				<Separator>
					<b>Charges</b>
				</Separator>

				<div className='ms-Grid-row modal-row'>
					<div className='ms-Grid-col ms-sm4'></div>
					<div className='ms-Grid-col ms-sm4'>Estimated Average Daily Charges</div>
					<div className='ms-Grid-col ms-sm4'>Increase (If app from 1st july)</div>
				</div>

				<div className='ms-Grid-row modal-row'>
					<div className='ms-Grid-col ms-sm4'>Water Usage</div>
					<div className='ms-Grid-col ms-sm4'>
						<TextField
							type="number"
							data-cy='water_Usage_Input'
							placeholder='0'
							defaultValue={updatedState['waterUsage']}
							onChange={(ev, nextText) => this.props.updateValue(nextText, 'waterUsage')}
						/>
					</div>
					<div className='ms-Grid-col ms-sm4'>
						<TextField
							type="number"
							data-cy='water_Usage_Increase_Input'
							placeholder='0'
							defaultValue={updatedState['waterUsageIncrease']}
							onChange={(ev, nextText) => this.props.updateValue(nextText, 'waterUsageIncrease')}
						/>
					</div>
				</div>

				<div className='ms-Grid-row modal-row'>
					<div className='ms-Grid-col ms-sm4'>Sewer Usage</div>
					<div className='ms-Grid-col ms-sm4'>
						<TextField
							type="number"
							data-cy='sewer_Usage_Input'
							placeholder='0'
							defaultValue={updatedState['sewerUsage']}
							onChange={(ev, nextText) => this.props.updateValue(nextText, 'sewerUsage')}
						/>
					</div>
					<div className='ms-Grid-col ms-sm4'>
						<TextField
							type="number"
							data-cy='sewer_Usage_Increase_Input'
							placeholder='0'
							defaultValue={updatedState['sewerUsageIncrease']}
							onChange={(ev, nextText) => this.props.updateValue(nextText, 'sewerUsageIncrease')}
						/>
					</div>
				</div>

				<Separator>
					<b>Apportionment</b>
				</Separator>

				<div className="ms-Grid-row modal-row">
					<div className="ms-Grid-col ms-sm4">CTS Option:</div>
					<div className="ms-Grid-col ms-sm4">
						<Dropdown
							data-cy="cts_option_select"
							id="cts_option_select"
							options={ctsOptions}
							defaultSelectedKey={updatedState['ctsOption']}
							onChange={(ev, item) => this.props.updateValue(item!.key, 'ctsOption', true)}
						/>
					</div>

					{updatedState['ctsOption'] === apportionmentOption.sharedPercentage &&

						<div className="ms-Grid-col ms-sm4">
							<TextField
								type="number"
								data-cy="percentage_input"
								defaultValue={updatedState['percentage']}
								onChange={(ev, newText) => this.props.updateValue(newText, 'percentage')}
							/>
						</div>
					}

					{updatedState['ctsOption'] === apportionmentOption.entitlement &&
						<div className="ms-Grid-col ms-sm4">
							<TextField
								defaultValue={updatedState['entitlementValue']}
								placeholder='Lot Allocation/Aggregate'
								onChange={(ev, newText) => this.props.updateValue(newText, 'entitlementValue')}
							/>
						</div>
					}
				</div>

				<div className='ms-Grid-row modal-row'>
					<div className='ms-Grid-col ms-sm4'>Funds Withhead at Settlement</div>
					<div className='ms-Grid-col ms-sm4'>
						<TextField
							data-cy='fund_withheld_at_settlement'
							defaultValue={updatedState['fundWithheldAtSettlement']}
							onChange={(ev, nextText) => this.props.updateValue(nextText, 'fundWithheldAtSettlement')}
						/>
					</div>
				</div>
			</div>
		)
	}
}