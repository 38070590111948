import { call, put, takeLatest } from 'redux-saga/effects'

import * as CONSTANTS from './constants'
import * as ACTIONS from './actions'
import {
    ConnectedActionstepOrgsResponse,
    GetTransactionStatusResponse,
    ListFilteredActionsResponse,
    ProcessEmailArchiverResponse,
    ValidateEmailArchiverInputResponse,
    GeneralResponse
} from 'utils/wcaApiTypes'

import {
    getRequest,
    postRequest
} from 'utils/request'

import {
    GetListMatters,
    GetTransactionStatus,
    TriggerEmailArchiverProcess,
    ValidateDataInput,
    DownloadReports,
    CancelTransaction
} from './actionTypes'


export function* getConnectedOrgs() {
    try {
        const response = yield call(getRequest, `/api/actionstep/get-list-connected-orgs`);

        let data: ConnectedActionstepOrgsResponse[] = response;

        yield put(ACTIONS.getConnectedOrgsSuccess(data));

    } catch (error) {
        yield put(ACTIONS.getConnectedOrgsFailed(error?.response?.data));
    }
}

export function* getListMatters(action: GetListMatters) {
    const { params } = action;
    try {
        const response = yield call(postRequest, `/api/actionstep/get-list-filtered-actions`, params);

        let data: ListFilteredActionsResponse = response;

        yield put(ACTIONS.getListMattersSuccess(data));

    } catch (error) {
        yield put(ACTIONS.getListMattersFailed(error?.response?.data));
    }
}

export function* validateDataInput(action: ValidateDataInput) {
    const { params } = action;
    try {
        const response = yield call(postRequest, `/api/actionstep/validate-email-archiver-input`, params);

        let data: ValidateEmailArchiverInputResponse = response;

        yield put(ACTIONS.validateDataInputSuccess(data));

    } catch (error) {
        yield put(ACTIONS.validateDataInputFailed(error?.response?.data));
    }
}

export function* triggerEmailArchiverProcess(action: TriggerEmailArchiverProcess) {
    const { params } = action;
    try {
        const response = yield call(postRequest, `/api/actionstep/process-email-archiver`, params);

        let data: ProcessEmailArchiverResponse = response;

        yield put(ACTIONS.triggerEmailArchiveProcessSuccess(data));

    } catch (error) {
        yield put(ACTIONS.triggerEmailArchiveProcessFailed(error?.response?.data));
    }
}

export function* getTransactionStatus(action: GetTransactionStatus) {
    const { params } = action;
    try {
        const response = yield call(getRequest, `/api/actionstep/get-email-archiver-transaction-status?transactionId=${params}`);

        let data: GetTransactionStatusResponse = response;

        yield put(ACTIONS.getTransactionStatusSuccess(data));

    } catch (error) {
        yield put(ACTIONS.getTransactionStatusFailed(error?.response?.data));
    }
}
export function* cancelTransaction(action: CancelTransaction) {
    const { params } = action;
    try {
        const response = yield call(postRequest, `/api/actionstep/cancel-email-archiver-process`, params);

        let data: GeneralResponse = response;

        yield put(ACTIONS.cancelTransactionSuccess(data));

    } catch (error) {
        yield put(ACTIONS.cancelTransactionFailed(error?.response?.data));
    }
}

export function* downloadReports(action: DownloadReports) {
    const { params } = action;
    try {
        const response = yield call(getRequest, `/api/actionstep/download-email-archiver-transaction-reports?transactionId=${params}`);
        // download file
        var blob = b64toBlob(response, 'application/zip', 512);
        saveAs(blob, "Email Archiver Transaction " + params + " Reports.zip");

        yield put(ACTIONS.downloadReportsSuccess(true));

    } catch (error) {
        yield put(ACTIONS.downloadReportsFailed(error?.response?.data));
    }
}

const b64toBlob = (b64Data: any, contentType: any, sliceSize: any) => {
    //https://stackoverflow.com/questions/37948809/client-download-of-a-server-generated-zip-file
    //https://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
};

export default function* emailArchiverSaga() {
    yield takeLatest(CONSTANTS.GET_CONNECTED_ORGS_REQUESTED, getConnectedOrgs);
    yield takeLatest(CONSTANTS.GET_LIST_MATTERS_REQUESTED, getListMatters);
    yield takeLatest(CONSTANTS.VALIDATE_DATA_INPUT_REQUESTED, validateDataInput);
    yield takeLatest(CONSTANTS.TRIGGER_EMAIL_ARCHIVER_PROCESS_REQUESTED, triggerEmailArchiverProcess);
    yield takeLatest(CONSTANTS.GET_TRANSACTION_STATUS_REQUESTED, getTransactionStatus);
    yield takeLatest(CONSTANTS.DOWNLOAD_REPORTS_REQUESTED, downloadReports);
    yield takeLatest(CONSTANTS.CANCEL_TRANSACTION_REQUESTED, cancelTransaction);
} 