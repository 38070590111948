import * as React from 'react';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import CustomDatePickers from 'components/common/customDatePickers';
import { _onFormatDateDDMMYYYY } from 'utils/dataFormatter'
import { Dropdown } from '@fluentui/react';
import { getAdjustmentPeriods, billingPeriodOptions } from '../Helper';

interface IMapStateToProps { }
interface IMapDispatchToProps { }

interface IProps {
    updatedState: any;
    updateValue: (newValue: any, whichValue: string, needRefresh?: boolean) => void;
    matterDetails: any;
}

type AppProps = IMapStateToProps & IProps & IMapDispatchToProps;

type AppStates = {}

export default class PenaltyInterest extends React.Component<AppProps, AppStates> {

    public render(): JSX.Element {
        const updatedState = this.props.updatedState;
        const matterDetails = this.props.matterDetails;


        const onPeriodChange = (selectedPeriod: any, whichValue: string) => {
            const datePeriod: any = getAdjustmentPeriods(selectedPeriod, matterDetails.settlementDate);
            this.props.updateValue(selectedPeriod, whichValue);

            if (selectedPeriod === 'Daily')
                this.props.updateValue(null, 'from');
            else
                this.props.updateValue(datePeriod.fromDate, 'from');

            this.props.updateValue(datePeriod.toDate, 'to');

            this.setState({
                refresh: true
            });
        }
        return (
            <div className="modal-body">
                <div className="ms-Grid" dir="ltr">

                    <div className="ms-Grid-row modal-row">
                        <div className="ms-Grid-col ms-sm2">Rate(%) : </div>
                        <div className="ms-Grid-col ms-sm4">
                            <TextField
                                type="number"
                                data-cy="rate_input"
                                defaultValue={updatedState['rate']}
                                onChange={(ev, newText) => this.props.updateValue(newText, 'rate')}
                            />
                        </div>
                        <div className="ms-Grid-col ms-sm6"></div>
                    </div>
                    <div className="ms-Grid-row  modal-row">
                        <div className="ms-Grid-col ms-sm2">Billing Period : </div>
                        <div className="ms-Grid-col ms-sm4">
                            <Dropdown
                                placeholder="Please Select"
                                data-cy="billingPeriod_select"
                                label=''
                                options={billingPeriodOptions()}
                                defaultSelectedKey={updatedState['billingPeriod']}
                                onChange={(ev, newItem) => this.props.updateValue(newItem?.key, 'billingPeriod')}
                            />
                        </div>

                        <div className="ms-Grid-col ms-sm1"></div>
                        <div className="ms-Grid-col ms-sm1">From: </div>

                        <div className="ms-Grid-col ms-sm4">
                            <CustomDatePickers
                                disabled={false}
                                updateValue={this.props.updateValue}
                                updatedState={this.props.updatedState}
                                datacy="from_Date_select"
                                datePickerName="from"
                            />
                        </div>
                    </div>
                    <div className="ms-Grid-row  modal-row">
                        <div className="ms-Grid-col ms-sm2">Adjustment Period : </div>
                        <div className="ms-Grid-col ms-sm4">
                            <Dropdown
                                placeHolder='Please Select'
                                data-cy="adjustmentPeriod_select"
                                label=''
                                options={billingPeriodOptions()}
                                defaultSelectedKey={updatedState['adjustmentPeriod']}
                                onChange={(ev, newItem) => onPeriodChange(newItem?.key, 'adjustmentPeriod')}
                            />
                        </div>

                        <div className="ms-Grid-col ms-sm1"></div>
                        <div className="ms-Grid-col ms-sm1">To: </div>

                        <div className="ms-Grid-col ms-sm4">
                            <CustomDatePickers
                                disabled={false}
                                updateValue={this.props.updateValue}
                                updatedState={this.props.updatedState}
                                datacy="from_Date_select"
                                datePickerName="to"
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
