import * as CONSTANTS from './constants'
import { SpecialMeterReadingViewModel } from '../utils/specialMeterReadingWCAApiTypes';
import { ErrorViewModel, ActionstepDocument } from 'utils/wcaApiTypes';
import {
    SpecialMeterReadingActionTypes,
    SavePDFSuccess, GetSpecialMeterReadingMatterSuccess, ChangeState, ActionFailed
} from './actionTypes';

export interface SpecialMeterReadingInfoState {
    gotResponse: boolean,
    actionstepPDF: ActionstepDocument | undefined,
    state: string,
    success: boolean,
    specialMeterReadingMatter: SpecialMeterReadingViewModel | undefined,
    error: ErrorViewModel | undefined,
    requestType: string
}

const initialState: SpecialMeterReadingInfoState = {
    gotResponse: false,
    actionstepPDF: new ActionstepDocument(),
    state: 'SA',
    success: false,
    specialMeterReadingMatter: undefined,
    error: undefined,
    requestType: ""
}

function specialMeterReadingInfoReducer(state: SpecialMeterReadingInfoState = initialState, action: SpecialMeterReadingActionTypes): SpecialMeterReadingInfoState {

    switch (action.type) {
        case CONSTANTS.GENERATE_PDF_REQUESTED:
        case CONSTANTS.SAVE_PDF_REQUESTED:
        case CONSTANTS.GET_SPECIAL_METER_READING_REQUESTED:
        case CONSTANTS.SAVE_SPECIAL_METER_READING_REQUESTED:
        case CONSTANTS.DELETE_SPECIAL_METER_READING_REQUESTED:
            return {
                ...state,
                requestType: action.type
            };

        case CONSTANTS.GENERATE_PDF_SUCCESS:
            return {
                ...state,
                gotResponse: true,
                success: true
            };

        case CONSTANTS.SAVE_PDF_SUCCESS:
            return {
                ...state,
                actionstepPDF: (action as SavePDFSuccess).data,
                gotResponse: true,
                success: true
            };

        case CONSTANTS.GET_SPECIAL_METER_READING_SUCCESS:
            return {
                ...state,
                gotResponse: true,
                success: true,
                specialMeterReadingMatter: (action as GetSpecialMeterReadingMatterSuccess).data
            };

        case CONSTANTS.SAVE_SPECIAL_METER_READING_SUCCESS:
            return {
                ...state,
                gotResponse: true,
                success: true
            };

        case CONSTANTS.DELETE_SPECIAL_METER_READING_SUCCESS:
            return {
                ...state,
                gotResponse: true,
                success: true
            };

        case CONSTANTS.CLEAR_SPECIAL_METER_READING_INFO_STATE:
            return {
                ...state,
                gotResponse: false,
                success: false,
                specialMeterReadingMatter: undefined,
                actionstepPDF: undefined,
                error: undefined,
                requestType: ""
            };

        case CONSTANTS.CHANGE_STATE:
            return {
                ...state,
                state: (action as ChangeState).data
            };

        case CONSTANTS.GENERATE_PDF_FAILED:
        case CONSTANTS.SAVE_PDF_FAILED:
        case CONSTANTS.GET_SPECIAL_METER_READING_FAILED:
        case CONSTANTS.SAVE_SPECIAL_METER_READING_FAILED:
        case CONSTANTS.DELETE_SPECIAL_METER_READING_FAILED:
            return {
                ...state,
                gotResponse: true,
                success: false,
                error: (action as ActionFailed).error
            };

        default:
            return state;
    }
}

export default specialMeterReadingInfoReducer;