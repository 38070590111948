import { AppState } from "app.types";
import { SettlementCalculator } from "./settlementCalculator";
import {
    generatePDF,
    changeState,
    clearSettlementState,
    saveSettlementMatter,
    getSettlementMatter,
    savePDF,
    deleteSettlementMatter
} from "containers/calculators/settlement/redux/actions";
import { connect } from "react-redux";

export class SettlementCalculatorBasic extends SettlementCalculator {}

const mapStateToProps = (state: AppState) => {
    return {
        state: state.settlementInfo.state,
        actionstepContext: state.common.actionstepContext,
        success: state.settlementInfo.success,
        gotResponse: state.settlementInfo.gotResponse,
        settlementMatter: state.settlementInfo.settlementMatter,
        actionstepPDF: state.settlementInfo.actionstepPDF,
        requestType: state.settlementInfo.requestType,
        error: state.settlementInfo.error,
        stateOfCountry: state.settlementInfo.state,
        useAdvancedCalculator: false,
    };
};

const mapDispatchToProps = {
    generatePDF,
    savePDF,
    getSettlementMatter,
    saveSettlementMatter,
    deleteSettlementMatter,
    changeState,
    clearSettlementState
};

export default connect(mapStateToProps, mapDispatchToProps)
    (SettlementCalculatorBasic);