import { call, put, takeLatest } from 'redux-saga/effects'
import * as ACTIONS from './actions'
import {
    OrderStewartTitlePolicyCommandResponse,
    StewartTitleActionstepResponse,
    StewartTitleOrderDetailsQueryResponse
} from '../utils/stewartTitleWCAApiTypes'
import { getRequest, postRequest } from 'utils/request'
import * as CONSTANTS from './constants'
import { GetMatterDetailsFromActionstep, GetStewartTitleOrderDetails, OrderStewartTitlePolicy } from './actionTypes'

export function* getMatterDetailsFromActionstep(action: GetMatterDetailsFromActionstep) {
    try {
        const response = yield call(getRequest, `/api/insurance/stewart-title-request-from-matter?actionsteporg=${action.orgKey}&matterid=${action.matterId}`);

        let data: StewartTitleActionstepResponse = StewartTitleActionstepResponse.fromJS(response);

        yield put(ACTIONS.getMatterDetailsFromActionstepSuccess(data));

    } catch (error) {
        yield put(ACTIONS.getMatterDetailsFromActionstepFailed(error?.response?.data));
    }
}

export function* orderStewartTitlePolicy(action: OrderStewartTitlePolicy) {
    try {
        const response = yield call(postRequest, `/api/insurance/stewart-title-policy-order-request`, action.data);

        if (response["errorList"]) {
            yield put(ACTIONS.orderStewartTitlePolicyFailed(response));
        } else {
            let data: OrderStewartTitlePolicyCommandResponse = OrderStewartTitlePolicyCommandResponse.fromJS(response);

            yield put(ACTIONS.orderStewartTitlePolicySuccess(data));
        }
    } catch (error) {
        yield put(ACTIONS.orderStewartTitlePolicyFailed(error?.response?.data));
    }
}

export function* getStewartTitleOrderDetails(action: GetStewartTitleOrderDetails) {
    try {
        const response = yield call(postRequest, `/api/insurance/stewart-title-order-details-request?actionsteporg=${action.orgKey}&matterid=${action.matterId}`);

        let data: StewartTitleOrderDetailsQueryResponse = StewartTitleOrderDetailsQueryResponse.fromJS(response);

        yield put(ACTIONS.getStewartTitleOrderDetailsSuccess(data));

    } catch (error) {
        yield put(ACTIONS.getStewartTitleOrderDetailsFailed(error?.response?.data));
    }
}

export default function* stewartTitleSaga() {
    yield takeLatest(CONSTANTS.GET_STEWART_TITLE_MATTER_FROM_ACTIONSTEP_REQUESTED, getMatterDetailsFromActionstep);
    yield takeLatest(CONSTANTS.ORDER_STEWART_TITLE_POLICY_REQUESTED, orderStewartTitlePolicy);
    yield takeLatest(CONSTANTS.GET_STEWART_TITLE_ORDER_DETAILS_REQUESTED, getStewartTitleOrderDetails);
}