import * as React from 'react';
import { TextField, Dropdown } from 'office-ui-fabric-react/lib';
import { sourceOfFunds, fundsTypes } from '../common';

interface IMapStateToProps { }
interface IMapDispatchToProps { }

interface IProps {
    updatedState: any;
    updateValue: (newValue: any, whichValue: string, needRefresh?: boolean) => void;
    balanceFunds: number;
}

type AppProps = IMapStateToProps & IProps & IMapDispatchToProps;

type AppStates = {}

export default class PayeeDetails extends React.Component<AppProps, AppStates> {

    state = {
        refresh: false,
        updatedAmount: undefined
    }

    public componentDidMount(): void {
        this.setState({
            updatedAmount: this.props.updatedState["amount"]
        })
    }

    private onBalanceFundChange = (checked?: boolean): void => {

        if (checked === true) {
            this.setState({
                updatedAmount: this.props.balanceFunds.toFixed(2)
            });
            this.props.updateValue(this.props.balanceFunds.toFixed(2), 'amount');
        } else {
            this.setState({
                updatedAmount: ''
            });
            this.props.updateValue('0', 'amount');
        }
        this.setState({
            refresh: true
        });
    }

    private async onValueChange(newValue: any, KeyName: any) {
        this.setState({
            updatedAmount: newValue
        });
        this.props.updateValue(newValue, KeyName);
        this.setState({
            refresh: true
        });
    }

    public render(): JSX.Element {
        const { updatedState } = this.props;
        const { updatedAmount } = this.state

        return (
            <div className="modal-body">
                <div className="ms-Grid" dir="ltr">

                    <div className="ms-Grid-row modal-row">
                        <div className="ms-Grid-col ms-sm2">Description : </div>
                        <div className="ms-Grid-col ms-sm4">
                            <TextField
                                data-cy="amount_description_input"
                                defaultValue={updatedState['description']}
                                onChange={(ev, newText) => this.props.updateValue(newText, 'description')}
                            />
                        </div>

                        <div className="ms-Grid-col ms-sm2">Amount : </div>
                        <div className="ms-Grid-col ms-sm4">
                            <TextField
                                data-cy="amount_input"
                                value={updatedAmount}
                                onChange={(ev, newText) => this.onValueChange(newText, 'amount')}
                            />
                        </div>
                    </div>
                </div>

                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row modal-row">
                        <div className="ms-Grid-col ms-sm2">Funds Type : </div>
                        <div className="ms-Grid-col ms-sm4">
                            <Dropdown
                                placeholder='Select Funds Type'
                                data-cy='funds_type_input'
                                options={fundsTypes}
                                defaultSelectedKey={updatedState['fundType']}
                                onChange={(ev, newItem) => this.props.updateValue(newItem!.key, 'fundType')}
                            />
                        </div>
                        <div className='ms-Grid-col ms-sm2'>
                            <label>
                                Balance Fund
                            </label>
                        </div>
                        <div className='ms-Grid-col ms-sm4'>
                            <input type="checkbox" onChange={(event) => this.onBalanceFundChange(event.currentTarget.checked)} />
                            <label className={this.props.balanceFunds < 0 ? "red" : "green"}>(unallocated: ${" "}
                                {this.props && this.props.balanceFunds.toLocaleString("en-AU", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                })}
                                )</label>
                        </div>
                    </div>
                </div>

                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row modal-row">
                        <div className="ms-Grid-col ms-sm2">Funds Source : </div>
                        <div className="ms-Grid-col ms-sm6">
                            <Dropdown
                                placeholder='Select Funds Source'
                                data-cy='funds_source_input'
                                options={sourceOfFunds}
                                defaultSelectedKey={updatedState['fundsSource']}
                                onChange={(ev, newItem) => this.props.updateValue(newItem!.key, 'fundsSource')}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}