import {
    AccountModel,
    UISettings,
    ActionstepMatterInfo,
    ConnectedActionstepOrgsResponse,
    VerifyActionstepConnectionResponse,
    GetUserAuditTrailDataResponse,
    SaveUserAuditTrailDataCommand,
} from 'utils/wcaApiTypes'

import { CommonEvents } from './events'
import { IActionUnion } from 'utils/reduxHelpers';
import { ActionstepContext } from './reducer';

export const makeAction = <T extends CommonEvents, P>(type: T) => (payload: P) => {
    return {
        type,
        payload,
    }
};

export const makeActionWithoutPayload = <T extends CommonEvents>(type: T) => () => {
    return {
        type,
    }
};

export const actionstepUnauthorizedResponse = makeActionWithoutPayload<CommonEvents.ACTIONSTEP_UNAUTHORIZED_RESPONSE>(CommonEvents.ACTIONSTEP_UNAUTHORIZED_RESPONSE);
export const markOrgVerified = makeAction<CommonEvents.MARK_ORG_VERIFIED, VerifyActionstepConnectionResponse>(CommonEvents.MARK_ORG_VERIFIED);
export const resetOrgAndMatterRequestStatus = makeActionWithoutPayload<CommonEvents.RESET_ORG_AND_MATTER_REQUEST_STATUS>(CommonEvents.RESET_ORG_AND_MATTER_REQUEST_STATUS);
export const clearCommonState = makeActionWithoutPayload<CommonEvents.CLEAR_COMMON_STATE>(CommonEvents.CLEAR_COMMON_STATE);
export const firstTitleConnected = makeActionWithoutPayload<CommonEvents.FIRST_TITLE_CONNECTED>(CommonEvents.FIRST_TITLE_CONNECTED);
export const firstTitleNotConnected = makeAction<CommonEvents.FIRST_TITLE_NOT_CONNECTED, boolean>(CommonEvents.FIRST_TITLE_NOT_CONNECTED);

export const getAccountInfo = makeActionWithoutPayload<CommonEvents.GET_ACCOUNT_INFO_REQUESTED>(CommonEvents.GET_ACCOUNT_INFO_REQUESTED);
export const getAccountInfoFailed = makeAction<CommonEvents.GET_ACCOUNT_INFO_FAILED, string[]>(CommonEvents.GET_ACCOUNT_INFO_FAILED);
export const getAccountInfoSuccess = makeAction<CommonEvents.GET_ACCOUNT_INFO_SUCCESS, AccountModel>(CommonEvents.GET_ACCOUNT_INFO_SUCCESS);

export const getMatterInfo = makeAction<CommonEvents.GET_MATTER_INFO_REQUESTED, ActionstepContext>(CommonEvents.GET_MATTER_INFO_REQUESTED);
export const getMatterInfoFailed = makeAction<CommonEvents.GET_MATTER_INFO_FAILED, string[]>(CommonEvents.GET_MATTER_INFO_FAILED);
export const getMatterInfoSuccess = makeAction<CommonEvents.GET_MATTER_INFO_SUCCESS, ActionstepMatterInfo>(CommonEvents.GET_MATTER_INFO_SUCCESS);
export const getMatterInfoFailedNotFound = makeActionWithoutPayload<CommonEvents.GET_MATTER_INFO_FAILED_NOT_FOUND>(CommonEvents.GET_MATTER_INFO_FAILED_NOT_FOUND);
export const matterInfoNotSupplied = makeActionWithoutPayload<CommonEvents.MATTER_INFO_NOT_SUPPLIED>(CommonEvents.MATTER_INFO_NOT_SUPPLIED);

export const pexaConnected = makeActionWithoutPayload<CommonEvents.PEXA_CONNECTED>(CommonEvents.PEXA_CONNECTED);
export const pexaNotConnected = makeActionWithoutPayload<CommonEvents.PEXA_NOT_CONNECTED>(CommonEvents.PEXA_NOT_CONNECTED);
export const setFullPage = makeAction<CommonEvents.SET_FULL_PAGE, boolean>(CommonEvents.SET_FULL_PAGE);
export const setActionstepContext = makeAction<CommonEvents.SET_ACTIONSTEP_CONTEXT, ActionstepContext | null>(CommonEvents.SET_ACTIONSTEP_CONTEXT);
export const setUIDefinitions = makeAction<CommonEvents.SET_UI_DEFINITIONS, UISettings>(CommonEvents.SET_UI_DEFINITIONS);
export const setCurrentOrg = makeAction<CommonEvents.SET_CURRENT_ORG, ConnectedActionstepOrgsResponse>(CommonEvents.SET_CURRENT_ORG);
export const clearCurrentOrg = makeActionWithoutPayload<CommonEvents.CLEAR_CURRENT_ORG>(CommonEvents.CLEAR_CURRENT_ORG);

export const getUserAuditTrailData = makeAction<CommonEvents.GET_USER_AUDIT_TRAIL_DATA_REQUESTED, ActionstepContext>(CommonEvents.GET_USER_AUDIT_TRAIL_DATA_REQUESTED);
export const getUserAuditTrailDataFailed = makeAction<CommonEvents.GET_USER_AUDIT_TRAIL_DATA_FAILED, string[]>(CommonEvents.GET_USER_AUDIT_TRAIL_DATA_FAILED);
export const getUserAuditTrailDataSuccess = makeAction<CommonEvents.GET_USER_AUDIT_TRAIL_DATA_SUCCESS, GetUserAuditTrailDataResponse>(CommonEvents.GET_USER_AUDIT_TRAIL_DATA_SUCCESS);

export const saveUserAuditTrailData = makeAction<CommonEvents.SAVE_USER_AUDIT_TRAIL_DATA_REQUESTED, SaveUserAuditTrailDataCommand>(CommonEvents.SAVE_USER_AUDIT_TRAIL_DATA_REQUESTED);
export const saveUserAuditTrailDataFailed = makeAction<CommonEvents.SAVE_USER_AUDIT_TRAIL_DATA_FAILED, string[]>(CommonEvents.SAVE_USER_AUDIT_TRAIL_DATA_FAILED);
export const saveUserAuditTrailDataSuccess = makeAction<CommonEvents.SAVE_USER_AUDIT_TRAIL_DATA_SUCCESS, boolean>(CommonEvents.SAVE_USER_AUDIT_TRAIL_DATA_SUCCESS);



const commonActions = {
    actionstepUnauthorizedResponse,
    markOrgVerified,
    resetOrgAndMatterRequestStatus,
    clearCommonState,
    firstTitleConnected,
    firstTitleNotConnected,

    getAccountInfo,
    getAccountInfoFailed,
    getAccountInfoSuccess,

    getMatterInfo,
    getMatterInfoFailed,
    getMatterInfoSuccess,
    getMatterInfoFailedNotFound,
    matterInfoNotSupplied,

    pexaConnected,
    pexaNotConnected,
    setFullPage,
    setActionstepContext,
    setUIDefinitions,
    setCurrentOrg,
    clearCurrentOrg,

    getUserAuditTrailData,
    getUserAuditTrailDataFailed,
    getUserAuditTrailDataSuccess,
    saveUserAuditTrailData,
    saveUserAuditTrailDataFailed,
    saveUserAuditTrailDataSuccess
};

export type ICommonAction = IActionUnion<typeof commonActions>;
export default ICommonAction;