import * as React from 'react'

import { Card, ICardTokens, ICardSectionStyles, ICardSectionTokens } from '@uifabric/react-cards';
import { FontWeights } from '@uifabric/styling';
import {
    Stack,
    IStackTokens,
    Text,
    ITextStyles,
    Link,
    TooltipHost,
    TooltipDelay,
    Spinner,
    MessageBar,
    MessageBarType,
} from 'office-ui-fabric-react';
import { useId } from '@uifabric/react-hooks';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDatabase, faTimes, faPlus, faSyncAlt, faExclamation, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FunctionComponent, useState, useEffect, useCallback } from 'react';
import { ConnectedActionstepOrgsResponse, RefreshActionstepTokenResponseViewModel } from '../../utils/wcaApiTypes';
import Tools from '../../utils/tools';
import { postRequest, getRequest } from 'utils/request';

interface IProps {
    onSelect?: (org: ConnectedActionstepOrgsResponse) => void,
    dontAutoSelectSingleOrg?: boolean | undefined;
}

const OrgSelector: FunctionComponent<IProps> = (props) => {
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const [orgs, setOrgs] = useState<ConnectedActionstepOrgsResponse[]>();
    const [firstRender, setFirstRender] = useState(true);

    const selectOrg = useCallback((org: ConnectedActionstepOrgsResponse): void => {
        if (props.onSelect) {
            props.onSelect(org);
        }
    }, [props]);

    const refreshData = useCallback(async (): Promise<void> => {
        try {
            const connectedOrgs = await getRequest<ConnectedActionstepOrgsResponse[]>('/api/integrations/actionsteporgs', {});
            setOrgs(connectedOrgs);

            // If the user only has one org,
            if (connectedOrgs.length === 1 && !!!props.dontAutoSelectSingleOrg) {
                selectOrg(connectedOrgs[0]);
            }
        } catch (e) {
            console.log('Error loading connected orgs. Details:', e);
            setErrorMessage("We're sorry, something went wrong.");
        }

        setLoading(false);
    }, [setOrgs, selectOrg, setLoading, props.dontAutoSelectSingleOrg]);

    const connect = (): void => {
        setLoading(true);
        Tools.PopupConnectToActionstep(() => {
            refreshData();
        });
    };

    const refreshOrg = async (credentialId: number): Promise<void> => {
        setLoading(true);
        try {
            await postRequest<RefreshActionstepTokenResponseViewModel>('/api/integrations/actionstep/refresh-credentials',
                {
                    ActionstepCredentialIdToRefresh: credentialId,
                    ForceRefreshIfNotExpired: true
                });
        } catch (error) {
            console.log('Error refreshing Actionstep credentials using token, attempting to re-authenticate interactively using popup. Error received was:', error);
            connect();
        }

        refreshData();
    }

    const disconnect = async (orgKey: string): Promise<void> => {
        setLoading(true);
        try {
            await postRequest('/api/integrations/actionstep/disconnect', { actionstepOrgKey: orgKey });
        } catch (e) {
            console.log('Error attempting to disconnect from org. Error details:', e);
            setErrorMessage("We're sorry, something went wrong.");
        }

        refreshData();
    };

    useEffect(() => {
        // We need firstRender because we're re-using refreshData
        if (firstRender) {
            refreshData();
            setFirstRender(false);
        }
    }, [refreshData, firstRender]);

    const descriptionTextStyles: ITextStyles = {
        root: {
            color: '#333333',
            fontWeight: FontWeights.semibold,
        },
    };

    const footerCardSectionStyles: ICardSectionStyles = {
        root: {
            borderTop: '1px solid #F3F2F1',
        },
    };

    const sectionStackTokens: IStackTokens = { childrenGap: 30, padding: 15 };
    const orgInfoStackTokens: IStackTokens = { childrenGap: 6 };
    const connectionInfoStackTokens: IStackTokens = { childrenGap: 6 };
    const cardTokens: ICardTokens = { childrenMargin: 12, width: 500, height: 175 };
    const footerCardSectionTokens: ICardSectionTokens = { padding: '12px 0px 0px' };

    const tooltipId = useId('tooltip');

    if (loading) {
        return (<Spinner />);
    }

    return (
        <Stack tokens={sectionStackTokens}>
            {errorMessage.length > 0 &&
                <MessageBar messageBarType={MessageBarType.error}>{errorMessage}</MessageBar>
            }

            <Stack.Item>
                <Text variant={"xLargePlus"}>
                    Click an Actionstep Organisation to continue
                </Text>
            </Stack.Item>

            <Stack.Item>
                <Text>
                    If you don't see the Organisation you're looking for, you can connect to another Organisation.
                </Text>
            </Stack.Item>

            <Stack horizontal tokens={sectionStackTokens} wrap>
                {orgs && orgs.length > 0 && orgs.map((o) => (
                    <Card
                        key={o.actionstepOrgKey}
                        aria-label={`Organisation ${o.actionstepOrgTitle}`}
                        onClick={() => selectOrg(o)}
                        tokens={cardTokens}
                    >
                        <Card.Item>
                            <Text styles={descriptionTextStyles}>
                                <FontAwesomeIcon icon={faDatabase} />&nbsp;
                                {o.actionstepOrgTitle}
                            </Text>
                        </Card.Item>
                        <Card.Section verticalFill>
                            <Stack tokens={orgInfoStackTokens}>
                                <Stack horizontal horizontalAlign="space-between">
                                    <Text variant="small">Org Key</Text>
                                    <Text variant="small">
                                        <strong>{o.actionstepOrgKey}</strong>
                                    </Text>
                                </Stack>
                                <Stack horizontal horizontalAlign="space-between">
                                    <Text variant="small">Status</Text>
                                    <TooltipHost
                                        delay={TooltipDelay.zero}
                                        id={tooltipId}
                                        style={{ width: 250 }}
                                        tooltipProps={{
                                            onRenderContent: () => (
                                                <Stack tokens={connectionInfoStackTokens}>
                                                    <Stack aria-describedby={tooltipId} horizontal horizontalAlign="space-between">
                                                        <Text variant="small">Last refreshed</Text>
                                                        <Text variant="small"><strong>{(new Date(o.lastRefreshedUtc)).toLocaleString('en-AU')}</strong></Text>
                                                    </Stack>
                                                    {o.expiredAtUtc &&
                                                        <Stack horizontal horizontalAlign="space-between">
                                                            <Text variant="small">Expired on</Text>
                                                            <Text variant="small"><strong>{(new Date(o.expiredAtUtc)).toLocaleString('en-AU')}</strong></Text>
                                                        </Stack>
                                                    }
                                                    {o.revokedAtUtc &&
                                                        <Stack horizontal horizontalAlign="space-between">
                                                            <Text variant="small">Revoked on</Text>
                                                            <Text variant="small"><strong>{(new Date(o.revokedAtUtc)).toLocaleString('en-AU')}</strong></Text>
                                                        </Stack>
                                                    }
                                                </Stack>
                                            )
                                        }}>
                                        {o.isValid
                                            ? <Text variant="small"><FontAwesomeIcon icon={faCheck} color="green" /> <strong>Connected</strong></Text>
                                            : <Text variant="small"><FontAwesomeIcon icon={faExclamation} color="red" /> <strong>Disconnected</strong></Text>
                                        }
                                    </TooltipHost>
                                </Stack>
                                {!o.isValid &&
                                    <Text><Link onClick={(e) => { connect(); e.stopPropagation(); }}>Fix this connection</Link></Text>
                                }
                            </Stack>
                        </Card.Section>
                        <Card.Section horizontal verticalAlign="end" styles={footerCardSectionStyles} tokens={footerCardSectionTokens}>
                            <Stack.Item grow={1}>
                                <span />
                            </Stack.Item>

                            <Link onClick={(e) => { refreshOrg(o.actionstepCredentialId); e.stopPropagation(); }}><FontAwesomeIcon icon={faSyncAlt} /></Link>
                            <Link onClick={(e) => { disconnect(o.actionstepOrgKey!); e.stopPropagation(); }}><FontAwesomeIcon icon={faTimes} color="grey" /></Link>
                        </Card.Section>
                    </Card>
                ))}

                <Card
                    aria-label='Connect to another organisation'
                    onClick={connect}
                    tokens={cardTokens}
                >
                    <Card.Section>
                        {orgs && orgs.length > 0
                            ? <Text variant="large">Connect to another Actionstep Organisation...</Text>
                            : <Text variant="large">Connect to an Actionstep Organisation...</Text>
                        }
                        <FontAwesomeIcon icon={faPlus} color="grey" />
                    </Card.Section>
                </Card>
            </Stack>
        </Stack>
    );

}

export default OrgSelector;