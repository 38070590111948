export class UpdateFSSLineItems implements IUpdateFSSLineItems {
    workspaceId: string | undefined;
    role: string | undefined;
    fssLineItems: ActionstepFinancialLineItems[] | undefined;
    matterId: number | undefined;
    orgKey: string | undefined;
}

export interface IUpdateFSSLineItems {
    workspaceId: string | undefined;
    role: string | undefined;
    fssLineItems: ActionstepFinancialLineItems[] | undefined;
    matterId: number | undefined;
    orgKey: string | undefined;
}

export class ActionstepFinancialLineItems implements IActionstepFinancialLineItems {
    sendToPexa?: string | undefined;
    distribType?: string | undefined;
    distribDescription?: string | undefined;
    amount?: string | undefined;
    sourceAmount?: string | undefined;
    category?: string | undefined;
    defaultAc?: string | undefined;
    paymentMethod?: string | undefined;
    preVerifiedAccount?: string | undefined;
    accountname?: string | undefined;
    bsbNo?: string | undefined;
    account?: string | undefined;
    bpayBillerCode?: string | undefined;
    bpayReferenceNumber?: string | undefined;
}
export interface IActionstepFinancialLineItems {
    sendToPexa?: string | undefined;
    distribType?: string | undefined;
    distribDescription?: string | undefined;
    amount?: string | undefined;
    sourceAmount?: string | undefined;
    category?: string | undefined;
    defaultAc?: string | undefined;
    paymentMethod?: string | undefined;
    preVerifiedAccount?: string | undefined;
    accountname?: string | undefined;
    bsbNo?: string | undefined;
    account?: string | undefined;
    bpayBillerCode?: string | undefined;
    bpayReferenceNumber?: string | undefined;
}