import * as React from 'react';
import { IconButton } from '@fluentui/react';
import { ModalIDs } from 'containers/calculators/settlement/common';
import SettlementInfo from './settlementInfo';
import { reorderDragDropList, toFormatLocalString } from './Helper';
import "./settlementCalculator.css";
// @ts-ignore
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

interface IMapStateToProps { }
interface IMapDispatchToProps { }

interface IProps {
    toggleSection: (payee: string) => void;
    iconName: any;
    showModal: (modalID: ModalIDs, additionalInfo: any) => void;
    unallocatedFund: any;
    isVendor: boolean;
    additionalInfo?: { [key: string]: any; } | undefined;
    conveyType: string | undefined;
    additionalRequirementCheckboxHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
    balanceDueToTheVendorAtSettlement: any;
    additionalRequirements?: { [key: string]: any; }[] | undefined;
    additionalDebit: any;
    additionalCredit: any;
    onReorder: (list: { [key: string]: any; }[]) => void;
}

type AppProps = IMapStateToProps & IProps & IMapDispatchToProps;
type AppStates = {}

export default class AdditionalRequirementInfo extends React.Component<AppProps, AppStates> {
    constructor(props: IProps) {
        super(props);

        this.onDragEnd = this.onDragEnd.bind(this);
    }

    private onDragEnd(result: any) {
        if (!result.destination) {
            return;
        }

        let r = reorderDragDropList(this.props.additionalRequirements ?? [], result.source.index, result.destination.index) ?? [];
        this.props.onReorder(r);
    }

    public render(): JSX.Element {
        const { iconName: showAdditionalRequirements, additionalDebit, additionalCredit, isVendor, additionalInfo,
            conveyType, balanceDueToTheVendorAtSettlement, additionalRequirements } = this.props;

        return (
            <div className="section">
                <div className="right-align-section-header">
                    Show/Hide Additional Requirements
                    <IconButton
                        className="button show-hide-button"
                        data-automation-id="save_button"
                        onClick={() => this.props.toggleSection('AdditionalRequirements')}
                        allowDisabledFocus={true}
                        iconProps={{
                            iconName: showAdditionalRequirements
                                ? "CaretDownSolid8"
                                : "CaretUpSolid8",
                            style: { fontSize: 10 },
                        }}
                    />
                </div>
                {showAdditionalRequirements && (
                    <div className="section-body animated fadeIn">
                        <div className="ms-Grid-row additional-header">
                            <div className="ms-Grid-col ms-sm8 ms-smPush2">
                                <b className="header-text">PURCHASER'S ADDITIONAL REQUIREMENTS AT SETTLEMENT</b>
                            </div>
                        </div>

                        <div className="ms-Grid-row state-row">
                            <div className="ms-Grid-col ms-sm12 right-align">
                                {this.props.additionalRequirements!.length > 0 ? <span>Select all cheques <input type="checkbox" id="allCheckedAdditionalRequirement" checked={this.props.additionalRequirements!.filter((additionalRequirement) => additionalRequirement?.isChecked !== true).length < 1} onChange={(event) => this.props.additionalRequirementCheckboxHandler(event)} /></span> : ""}
                            </div>
                        </div>

                        <div className="ms-Grid-row" dir="ltr">
                            <div className="ms-Grid-col ms-sm10">
                                <b>Balance at Settlement</b>
                            </div>
                            <div className="ms-Grid-col ms-sm2 right-align">
                                <b>
                                    ${toFormatLocalString(balanceDueToTheVendorAtSettlement, "en-AU", 2, 2)}
                                </b>
                            </div>
                        </div>

                        <DragDropContext onDragEnd={this.onDragEnd}>
                            <Droppable droppableId="additionalRequirement">
                                {(provided: any, snapshot: any) => (
                                    <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                    >
                                        {additionalRequirements &&
                                            additionalRequirements.map(
                                                (additionalRequirement, index) => {
                                                    return (
                                                        <Draggable key={index} draggableId={"1-" + index} index={index}>
                                                            {(provided: any, snapshot: any) => (
                                                                <div
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                >
                                                                    <SettlementInfo
                                                                        isVendor={isVendor}
                                                                        key={"additional_requirement_" + index}
                                                                        item={additionalRequirement}
                                                                        index={index}
                                                                        additionalInfo={additionalInfo}
                                                                        showModal={(modalID, additionalInfo) =>
                                                                            this.props.showModal(modalID, additionalInfo)
                                                                        }
                                                                        payeeCheckboxHandler={(event) => {}}
                                                                        additionalRequirementCheckboxHandler={(event) =>
                                                                            this.props.additionalRequirementCheckboxHandler(event)
                                                                        }
                                                                        draggable={true}
                                                                        conveyType={conveyType}
                                                                    />

                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    );
                                                }
                                            )}

                                        {provided.placeholder}
                                    </div>)}
                            </Droppable>
                        </DragDropContext>

                        <div className="ms-Grid-col ms-sm12 separator">
                            --------------
                        </div>

                        <div className="ms-Grid-row state-row">
                            <div className="ms-Grid-col ms-sm2">
                                <IconButton
                                    data-cy="add_additional_requirement"
                                    className="button rounded-blue-icon-button"
                                    data-automation-id="save_button"
                                    onClick={() =>
                                        this.props.showModal(
                                            ModalIDs.additionalRequirements,
                                            { index: -1 }
                                        )
                                    }
                                    allowDisabledFocus={true}
                                    iconProps={{
                                        iconName: "Add",
                                        style: {
                                            fontSize: 15,
                                            fontWeight: 900,
                                        },
                                    }}
                                />
                            </div>

                            <div className="ms-Grid-col ms-sm4 add-label">
                                Click + to Add Additional Costs
                            </div>
                            <div className="ms-Grid-col ms-sm4 align-right" />
                            <div className="ms-Grid-col ms-sm2 price-info ms-smPush2" />
                        </div>

                        <div className="ms-Grid-row state-row">
                            <div className="ms-Grid-col ms-sm2" />
                            <div className="ms-Grid-col ms-sm3 add-label" />

                            <div className="ms-Grid-col ms-sm5 align-right">
                                <b>TOTAL ADDITIONAL REQUIREMENTS</b>
                            </div>
                            <div className="ms-Grid-col ms-sm2 price-info ms-smPush2">
                                {
                                    <b data-cy="contract_debit_value">
                                        ${toFormatLocalString(additionalDebit - additionalCredit, "en-AU", 2, 2)}
                                    </b>
                                }
                                <br />--------------
                            </div>
                        </div>

                        <div className="ms-Grid-row state-row">
                            <div className="ms-Grid-col ms-sm2"></div>
                            <div className="ms-Grid-col ms-sm3"></div>
                            <div className="ms-Grid-col ms-sm5 align-right" id='totalFundRequiredForSettlement'>
                                <b>TOTAL FUNDS REQUIRED FOR SETTLEMENT</b>
                            </div>
                            <div className="ms-Grid-col ms-sm2 price-info ms-smPush2">
                                {
                                    <b data-cy="contract_debit_value">
                                        ${toFormatLocalString(additionalDebit + balanceDueToTheVendorAtSettlement - additionalCredit, "en-AU", 2, 2)}
                                    </b>
                                }
                            </div>
                        </div>

                    </div>
                )}
            </div>
        )
    }
}