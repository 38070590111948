import * as React from 'react';
import Moment from 'moment';
import { connect } from 'react-redux';
import { AppState } from '../../../../app.types';
import 'containers/pexa/showWorkspaceSummary'
import PexaKeyInvitationStatus from 'containers/pexa/steps/components/PexaKeyInvite/PexaKeyInviteStatus';
import { Redirect } from 'react-router-dom';
import { PrimaryButton } from "office-ui-fabric-react/lib/Button";
import { savePexaAudit } from 'containers/pexa/redux/actions';
import { SavePexaAuditParams } from 'utils/newWcaApiTypes';

interface IAppProps {

}
type AppStates = {
    workspaceId: string;
    redirect: string;
}

type AppProps = IAppProps & ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

export class DisplayWorkspaceSummary extends React.Component<AppProps, AppStates> {

    constructor(props: AppProps) {
        super(props);

        this.state = {
            workspaceId: "",
            redirect: ""
        }
    }

    public componentDidMount(): void {
        this.getWorkspaceId();
        //this.savePexaAudiTIntoDb();
    }

    private getWorkspaceId(): void {

        const { pexaWorkspaceInfoFromKonekta, pexaWorkspaceResponse, pexaWorkspaceCreationData } = this.props;
        let pexaWorkspaceId: string;
        if (pexaWorkspaceInfoFromKonekta) {
            pexaWorkspaceId = pexaWorkspaceInfoFromKonekta!.pexaWorkspaceId!;
        } else if (pexaWorkspaceCreationData) {
            pexaWorkspaceId = pexaWorkspaceCreationData.existingPexaWorkspace?.pexaWorkspaceId!;
        }
        else {
            pexaWorkspaceId = pexaWorkspaceResponse?.workspaceId!;
        }

        this.setState({
            workspaceId: pexaWorkspaceId,
        });

    }

    private redirectToHome = () => {
        this.setState({ redirect: "/matter" });
    }

    private savePexaAudiTIntoDb(): void {
        const { actionstepContext, workspaceSummaryList } = this.props;
        let pexaId = this.props.pexaWorkspaceCreationData.existingPexaWorkspace?.pexaWorkspaceId ?? "";
        let pexaAuditParams = new SavePexaAuditParams();
        pexaAuditParams.CommandName = "GETPEXAWORKSPACESUMMARYQUERY";
        pexaAuditParams.actionstepOrg = actionstepContext!.orgKey;
        pexaAuditParams.matterId = actionstepContext!.matterContext!.id;
        pexaAuditParams.pexaWorkspaceId = pexaId;
        pexaAuditParams.pexaWorkspaceStatus = workspaceSummaryList[pexaId].data?.status;
        this.props.savePexaAudit(pexaAuditParams);
    }
    render() {
        const { workspaceSummaryList } = this.props;
        const { workspaceId } = this.state;
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }
        return (
            <>
                <div className="modal-header">
                    <span className="modal-title"><big><b>WORKSPACE SUMMARY</b></big></span>
                </div>

                {workspaceSummaryList[workspaceId] !== undefined &&
                    workspaceSummaryList[workspaceId].data !== undefined &&
                    <>{this.savePexaAudiTIntoDb()}
                        <div className="modal-body">
                            <table className="workspace-summary-modal-table">
                                <tbody>
                                    {/* Information */}
                                    <table className="workspace-summary-modal-table">
                                        <tbody>
                                            <big><b> Information</b></big>
                                            <tr className="workspace-summary-tr md-Grid-row">
                                                <td className="md-Grid-col ms-md4">
                                                    PEXA Workspace Id:
                                                </td>
                                                <td className="md-Grid-col ms-md8">
                                                    {workspaceSummaryList[workspaceId].data?.workspaceId ?? ""}
                                                </td>
                                            </tr>
                                            <tr className="workspace-summary-tr md-Grid-row">
                                                <td className="md-Grid-col ms-md4">
                                                    Jurisdiction:
                                                </td>
                                                <td className="md-Grid-col ms-md8">
                                                    {workspaceSummaryList[workspaceId].data?.jurisdiction ?? ""}
                                                </td>
                                            </tr>
                                            <tr className="workspace-summary-tr has-bottom-border md-Grid-row">
                                                <td className="md-Grid-col ms-md4">
                                                    Role:
                                                </td>
                                                <td className="md-Grid-col ms-md8">
                                                    {workspaceSummaryList[workspaceId].data?.role ?? ""}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    {/* Land Title Details */}
                                    <table className="workspace-summary-modal-table">
                                        <tbody>
                                            <big><b>Land Title Details</b></big>
                                            <tr className="workspace-summary-tr md-Grid-row">
                                                <td className="md-Grid-col ms-md4">
                                                    Land Title Reference:
                                                </td>
                                                <td className="md-Grid-col ms-md8">
                                                    {workspaceSummaryList[workspaceId].data?.landTitleDetails![0]?.landTitleReference ?? ""}
                                                </td>
                                            </tr>
                                            <tr className="workspace-summary-tr md-Grid-row">
                                                <td className="md-Grid-col ms-md4">
                                                    Parent Title:
                                                </td>
                                                <td className="md-Grid-col ms-md8">
                                                    {workspaceSummaryList[workspaceId].data?.landTitleDetails![0]?.parentTitle ?? ""}
                                                </td>
                                            </tr>
                                            <tr className="workspace-summary-tr has-bottom-border md-Grid-row">
                                                <td className="md-Grid-col ms-md4">
                                                    Property Details:
                                                </td>
                                                <td className="md-Grid-col ms-md8">
                                                    {workspaceSummaryList[workspaceId].data?.landTitleDetails![0]?.propertyDetails ?? ""}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    {/* Settlement Schedule */}
                                    <table className="workspace-summary-modal-table">
                                        <tbody>
                                            <big><b>Settlement Schedule</b></big>
                                            <tr className="workspace-summary-tr md-Grid-row">
                                                <td className="md-Grid-col ms-md4">
                                                    Settlement Date:
                                                </td>
                                                <td className="md-Grid-col ms-md8">
                                                    {Moment(workspaceSummaryList[workspaceId].data?.settlementSchedule?.settlementDate)?.format('DD/MM/YYYY') ?? ""}
                                                </td>
                                            </tr>
                                            <tr className="workspace-summary-tr md-Grid-row">
                                                <td className="md-Grid-col ms-md4">
                                                    Settlement Schedule Id:
                                                </td>
                                                <td className="md-Grid-col ms-md8">
                                                    {workspaceSummaryList[workspaceId].data?.settlementSchedule?.settlementScheduleId ?? ""}
                                                </td>
                                            </tr>
                                            <tr className="workspace-summary-tr has-bottom-border md-Grid-row">
                                                <td className="md-Grid-col ms-md4">
                                                    Status:
                                                </td>
                                                <td className="md-Grid-col ms-md8">
                                                    {workspaceSummaryList[workspaceId].data?.settlementSchedule?.status ?? ""}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    {/* Participant Details */}
                                    {workspaceSummaryList[workspaceId].data?.participantDetails &&
                                        workspaceSummaryList[workspaceId].data?.participantDetails?.length &&
                                        workspaceSummaryList[workspaceId].data?.participantDetails![0]?.workspace?.length &&
                                        <table className="workspace-summary-modal-table has-bottom-border md-Grid-row">
                                            <tbody>
                                                <big><b>Participant Details</b></big>
                                                {workspaceSummaryList[workspaceId].data!.participantDetails!.map((participantDetail, index) => {
                                                    return (
                                                        <table className="workspace-summary-modal-table " key={index}>
                                                            <tbody>
                                                                <tr className="workspace-summary-tr md-Grid-row">
                                                                    <td className="md-Grid-col ms-md4">
                                                                        Subscriber Reference:
                                                                    </td>
                                                                    <td className="md-Grid-col ms-md8">
                                                                        {participantDetail.workspace![0]?.subscriberReference ?? "N/A"}
                                                                    </td>
                                                                </tr>
                                                                <tr className="workspace-summary-tr md-Grid-row">
                                                                    <td className="md-Grid-col ms-md4">
                                                                        Subscriber Name:
                                                                    </td>
                                                                    <td className="md-Grid-col ms-md8">
                                                                        {participantDetail.subscriberName ?? "N/A"}
                                                                    </td>
                                                                </tr>
                                                                <tr className="workspace-summary-tr">
                                                                    <td className="md-Grid-col ms-md4">
                                                                        Email:
                                                                    </td>
                                                                    <td className="md-Grid-col ms-md8">
                                                                        {participantDetail.contactDetails?.email ?? "N/A"}
                                                                    </td>
                                                                </tr>
                                                                <tr className="workspace-summary-tr">
                                                                    <td className={index !== (workspaceSummaryList[workspaceId].data!.participantDetails!.length! - 1) ?
                                                                        "md-Grid-col has-bottom-border ms-md4" : "md-Grid-col ms-md4"}>
                                                                        Status:
                                                                    </td>
                                                                    <td className="md-Grid-col ms-md8">
                                                                        {participantDetail.workspace![0]?.status ?? "N/A"}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    );
                                                })}
                                            </tbody>
                                        </table>}

                                    {/* Party Details */}
                                    {workspaceSummaryList[workspaceId].data?.partyDetails &&
                                        workspaceSummaryList[workspaceId].data?.partyDetails?.length &&
                                        <table className="workspace-summary-modal-table">
                                            <tbody>
                                                <big><b>Party Details</b></big>
                                                {workspaceSummaryList[workspaceId].data!.partyDetails!.map((partyDetail, index) => {
                                                    return (
                                                        <table className="workspace-summary-modal-table" key={index}>
                                                            <tbody>
                                                                {/*  <PexaKeyInvitationStatus workspaceId={workspaceId} workspaceRole={partyDetail.partyRole ?? ""} partyId={partyDetail.partyId ?? ""} />*/}

                                                                <tr className="workspace-summary-tr md-Grid-row">
                                                                    <td className="md-Grid-col ms-md4">
                                                                        Party Role:
                                                                    </td>
                                                                    <td className="md-Grid-col ms-md8">
                                                                        {partyDetail.partyRole ?? "N/A"}
                                                                    </td>
                                                                </tr>
                                                                <tr className="workspace-summary-tr md-Grid-row">
                                                                    <td className="md-Grid-col ms-md4">
                                                                        Party Type:
                                                                    </td>
                                                                    <td className="md-Grid-col ms-md8">
                                                                        {partyDetail.partyType ?? "N/A"}
                                                                    </td>
                                                                </tr>

                                                                {partyDetail.partyType == "Organisation" && partyDetail.business
                                                                    && partyDetail.business?.legalEntityName &&
                                                                    <tr className="workspace-summary-tr md-Grid-row">
                                                                        <td className="md-Grid-col ms-md4">
                                                                            Legal Entity Name:
                                                                        </td>
                                                                        <td className="md-Grid-col ms-md8">
                                                                            {partyDetail.business?.legalEntityName ?? "N/A"}
                                                                        </td>
                                                                    </tr>}

                                                                {partyDetail.partyType == "Individual" && partyDetail.fullName
                                                                    && partyDetail.fullName.givenName?.length
                                                                    && partyDetail.fullName.familyName &&
                                                                    <tr className="workspace-summary-tr md-Grid-row">
                                                                        <td className="md-Grid-col ms-md4">
                                                                            Full Name:
                                                                        </td>
                                                                        <td className="md-Grid-col ms-md8">
                                                                            {partyDetail.fullName!.givenName.length > 1 ?
                                                                                (partyDetail.fullName!.givenName![0].value! + " " + partyDetail.fullName!.givenName![1].value! + " " + partyDetail.fullName.familyName)
                                                                                : (partyDetail.fullName!.givenName![0].value + " " + partyDetail.fullName.familyName)}
                                                                        </td>
                                                                    </tr>}
                                                                <PexaKeyInvitationStatus workspaceId={workspaceId} workspaceRole={partyDetail.partyRole ?? ""} partyId={partyDetail.partyId ?? ""} />
                                                            </tbody>
                                                        </table>
                                                    );
                                                })}
                                            </tbody>
                                        </table>}
                                </tbody>
                            </table>
                        </div><div className="ms-Grid-col doc-return-button" dir="rtl">
                            <PrimaryButton
                                text="Return to konekta"
                                onClick={() => this.redirectToHome()} />
                        </div></>
                }
            </>
        );
    }
}

const mapStateToProps = (state: AppState) => {
    return {
        pexaWorkspaceInfoFromKonekta: state.pexa.pexaWorkspaceInfoFromKonekta,
        pexaWorkspaceResponse: state.pexa.createPexaWorkspaceResponse,
        pexaWorkspaceCreationData: state.pexa.pexaWorkspaceCreationData,
        workspaceSummaryList: state.pexa.workspaceSummaryList,
        actionstepContext: state.common.actionstepContext
    }
}
const mapDispatchToProps = {
    savePexaAudit
}

export default connect(mapStateToProps, mapDispatchToProps)(DisplayWorkspaceSummary);