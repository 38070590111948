import axios from 'utils/axios'
import rawAxios, { AxiosRequestConfig, AxiosPromise } from 'axios'

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export function getRequest<T = any>(url: string, config?: AxiosRequestConfig): Promise<T> {
    return axios.get(url, config).then(response => response.data);
}

export function rawGetRequest<T = any>(url: string, config?: AxiosRequestConfig): Promise<T> {
    return rawAxios.get(url, config).then(response => response.data);
}

export function postRequest<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> {
    return axios.post<T>(url, data, config).then(response => response.data);
}

export function rawPost<T = any>(url: string, data?: any, config?: AxiosRequestConfig): AxiosPromise<T> {
    return axios.post<T>(url, data, config);
}

export function deleteRequest<T = any>(url: string, config?: AxiosRequestConfig): Promise<T> {
    return axios.delete(url, config).then(response => response.data);
}