import * as React from 'react';
import * as IntegrationLink from "./integrationLinksConstants";

import {
    SubwayNav,
    SubwayNavNodeState,
    ISubwayNavNodeProps,
    setSubwayState
} from 'components/SubwayNav'

import RetrieveFromActionstep from 'containers/pexa/steps/retrieveFromActionstep';
import ConveyancingDataCheck from 'containers/pexa/steps/conveyancingDataCheck';
import SendToPexa from 'containers/pexa/steps/sendToPexa';
import DataCheckByPexa from 'containers/pexa/steps/dataCheckByPexa';
import WorkspaceCreated from 'containers/pexa/steps/workspaceCreated';

import "./createWorkspace.css"
import { getRequest } from '../../utils/request';
import Tools from '../../utils/tools';

import { MessageBar, MessageBarType } from 'office-ui-fabric-react';

interface IAppProps {
}

type AppProps = IAppProps;

export default class CreatePexaWorkspace extends React.Component<AppProps, any> {
    steps: ISubwayNavNodeProps[] = [];

    constructor(props: AppProps) {
        super(props);

        this.steps = [
            {
                id: '0',
                label: 'Retrieve from Actionstep',
                state: SubwayNavNodeState.Current,
                onClickStep: this._handleClickStep,
                disabled: true
            },
            {
                id: '1',
                label: 'Conveyancing Data Check',
                state: SubwayNavNodeState.NotStarted,
                onClickStep: this._handleClickStep,
                disabled: true
            },
            {
                id: '2',
                label: 'Send to PEXA',
                state: SubwayNavNodeState.NotStarted,
                onClickStep: this._handleClickStep,
                disabled: true
            },
            {
                id: '3',
                label: 'Data Check by PEXA',
                state: SubwayNavNodeState.NotStarted,
                onClickStep: this._handleClickStep,
                disabled: true
            },
            {
                id: '4',
                label: 'Workspace Created',
                state: SubwayNavNodeState.NotStarted,
                onClickStep: this._handleClickStep,
                disabled: true
            }
        ];

        this.state = {
            steps: this.steps,
            currentStepId: 0,
            integrationLink: IntegrationLink.Create_Workspace,
            pexaEnabled: false, // Checks if PEXA is enabled
        };
    }

    private async checkPEXAIsConnected(): Promise<void> {
        var pexaStatusFromBackend = (await getRequest(`/api/conveyancing/check-pexa-is-connected`)) as any;

        if (!pexaStatusFromBackend) {
            Tools.PopupConnectToPexa(async () => {
                pexaStatusFromBackend = (await getRequest(`/api/conveyancing/check-pexa-is-connected`)) as any;

                if (!pexaStatusFromBackend) {

                } else {
                    this.setState({
                        pexaEnabled: true
                    })
                }
            })
        } else {
            this.setState({
                pexaEnabled: true
            })
        }
    } 

    public async componentDidMount(): Promise<void> {
        await this.checkPEXAIsConnected();
    }

    private _handleClickStep = (step: ISubwayNavNodeProps): void => {
        this.setState({ ...setSubwayState(step, this.state.steps, this.state.currentStepId) });
    }

    private _onChangeStep = (newState: SubwayNavNodeState, newStep: number = -1): void => {
        let newSteps = [...this.state.steps];
        let currentStepId = this.state.currentStepId;

        if (newStep >= 0) {
            currentStepId = newStep;
        }
        for (let i = 0; i < currentStepId; i++) {
            newSteps[i].state = SubwayNavNodeState.Completed;
        }
        for (let i = currentStepId + 1; i < newSteps.length; i++) {
            newSteps[i].state = SubwayNavNodeState.NotStarted;
        }

        newSteps[currentStepId].state = newState;
        if (newState === SubwayNavNodeState.Completed && currentStepId < newSteps.length - 1) {
            currentStepId++;
            newSteps[currentStepId].state = SubwayNavNodeState.Current;
        }

        this.setState({
            steps: newSteps,
            currentStepId: currentStepId
        })

        window.scrollTo(0, 0);
    }

    render() {
        const { currentStepId, steps, pexaEnabled } = this.state;

        return (
            <div>
                <div className="wrapper vertical-container wrapper-content animated fadeIn" dir="ltr">

                    <div className="ms-Grid-row create-pexa-workspace">
                        {pexaEnabled ? 
                            <>
                                <div className="create-pexa-step ms-Grid-col ms-lg3">
                                    <SubwayNav steps={steps} />
                                </div>

                                <div className="create-pexa-content ms-Grid-col ms-lg9">
                                    {currentStepId === 0 && <RetrieveFromActionstep integrationLink={this.state.integrationLink} onChangeStep={this._onChangeStep} />}

                                    {currentStepId === 1 &&
                                        <ConveyancingDataCheck onChangeStep={this._onChangeStep} />
                                    }

                                    {currentStepId === 2 && <SendToPexa onChangeStep={this._onChangeStep} />}

                                    {currentStepId === 3 && <DataCheckByPexa onChangeStep={this._onChangeStep} />}

                                    {currentStepId === 4 &&
                                        <WorkspaceCreated onChangeStep={this._onChangeStep} />
                                    }
                                </div>
                            </>

                            : <div>
                                <MessageBar messageBarType={MessageBarType.error}>
                                    PEXA is not connected <span style={{ cursor: 'pointer' }} onClick={() => { this.checkPEXAIsConnected() }}><i><u>click here</u></i> to connect.</span>
                                </MessageBar>
                            </div>}

                    </div>

                </div>
            </div>
        );
    }
}
