import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'

import * as ACTIONS from './actions'
import {
    AccountModel,
    ActionstepMatterInfo,
    GetUserAuditTrailDataResponse,
} from 'utils/wcaApiTypes'

import { getRequest, postRequest, rawGetRequest } from 'utils/request'
import { CommonEvents } from './events'

export function* getAccountInfoSaga() {
    try {
        const response = yield call(rawGetRequest, '/api/account/currentUser');
        let data: AccountModel = AccountModel.fromJS(response);

        yield put(ACTIONS.getAccountInfoSuccess(data));
    } catch (error) {
        yield put(ACTIONS.getAccountInfoFailed(error));
    }
}

export function* getMatterInfoSaga(action: ReturnType<typeof ACTIONS.getMatterInfo>) {
    try {
        if(action.payload.matterContext?.id) {
            const response = yield call(getRequest, `/api/actionstep/matter/${action.payload.orgKey}/${action.payload.matterContext?.id}`);
            let data: ActionstepMatterInfo = ActionstepMatterInfo.fromJS(response);
            yield put(ACTIONS.getMatterInfoSuccess(data));
        } else {
            yield put(ACTIONS.getMatterInfoFailed(['Missing Matter ID']));
        }
    } catch (error) {
        if (error?.response?.status === 404) {
            yield put(ACTIONS.getMatterInfoFailedNotFound());
        } else {
            yield put(ACTIONS.getMatterInfoFailed(error));
        }
    }
}

export function* getUserAuditTrailData(action: ReturnType<typeof ACTIONS.getUserAuditTrailData>) {
   
    try {
        const response = yield call(getRequest, `/api/conveyancing/get-user-audit-trail-data?actionstepOrg=${action.payload.orgKey}&matterId=${action.payload.matterContext?.id}`);

        let data: GetUserAuditTrailDataResponse = response;

        yield put(ACTIONS.getUserAuditTrailDataSuccess(data));

    } catch (error) {
        yield put(ACTIONS.getUserAuditTrailDataFailed(error));
    }
}

export function* saveUserAuditTrailData(action: ReturnType<typeof ACTIONS.saveUserAuditTrailData>) {   
    try {
        const response = yield call(postRequest, `/api/conveyancing/save-user-audit-trail-data`, action.payload);

        let data: boolean = response;

        yield put(ACTIONS.saveUserAuditTrailDataSuccess(data));

    } catch (error) {
        yield put(ACTIONS.saveUserAuditTrailDataFailed(error?.response?.data));
    }
}

export default function* commonSaga() {
    yield takeLatest(CommonEvents.GET_ACCOUNT_INFO_REQUESTED, getAccountInfoSaga);
    yield takeLatest(CommonEvents.GET_MATTER_INFO_REQUESTED, getMatterInfoSaga);
    yield takeLatest(CommonEvents.GET_USER_AUDIT_TRAIL_DATA_REQUESTED, getUserAuditTrailData);
    yield takeEvery(CommonEvents.SAVE_USER_AUDIT_TRAIL_DATA_REQUESTED, saveUserAuditTrailData);
}