import * as React from 'react'
import { FunctionComponent, useState } from 'react'
import { Text, Link, Spinner, SpinnerSize, Stack, IStackTokens, PrimaryButton } from 'office-ui-fabric-react';
import { postRequest } from '../../utils/request';
import { IAcceptTermsCommand } from '../../utils/wcaApiTypes';
import ErrorMessageWidget from '../../components/common/errorMessageWidget';
import { DefaultStackTokens } from 'konekta-theme';

interface IProps {
    orgKey: string;
    orgName: string;
    showUpdatedTermsMessage?: boolean;
    onAccepted: () => void;
}

const AcceptTermsWidget: FunctionComponent<IProps> = (props) => {
    const [errorMessage, setErrorMessage] = useState<string>();
    const [loading, setLoading] = useState(false);
    const [accepted, setAccepted] = useState(false);

    const acceptTerms = async () => {
        try {
            setLoading(true);
            setErrorMessage('');
            const requestBody: IAcceptTermsCommand = { orgKey: props.orgKey };
            await postRequest('/api/account/acceptterms', requestBody);
            setAccepted(true);
            setLoading(false);
            props.onAccepted();
        } catch (e) {
            console.log('Error encountered trying to save acceptance of terms.', e);
            setErrorMessage('Oops! Something went wrong.');
            setLoading(false);
        }
    }

    const stackTokens: IStackTokens = {
        ...DefaultStackTokens,
        padding: 15,
    }

    const termsAndConditionsLink = <Link href="https://konekta.sslsvc.com/terms-and-conditions/" target="_blank" rel="noopener">terms and conditions</Link>;

    return (
        <Stack tokens={stackTokens} horizontalAlign='center'>
            <Stack.Item>
                <ErrorMessageWidget message={errorMessage} />
            </Stack.Item>

            <Stack.Item>
                {props.showUpdatedTermsMessage ?
                    <Text variant={'large'}>Terms &amp; Conditions Updated</Text>
                    :
                    <Text variant={'large'}>Terms &amp; Conditions</Text>
                }
            </Stack.Item>

            <Stack.Item>
                {props.showUpdatedTermsMessage ?
                    <Text>
                        We've updated our {termsAndConditionsLink} since you signed up for Konekta. Please take a look and accept on behalf of your organisation <strong>{props.orgName}</strong>.
                    </Text>
                :
                    <Text>
                        To use Konekta integrations you must first accept our {termsAndConditionsLink} on behalf of your organisation <strong>{props.orgName}</strong>.
                    </Text>
                }
            </Stack.Item>

            <Stack.Item>
                {accepted
                    ? <PrimaryButton disabled>Accepted</PrimaryButton>
                    : loading
                        ? <Spinner size={SpinnerSize.small} />
                        : <PrimaryButton onClick={acceptTerms}>Accept Terms</PrimaryButton>
                }
            </Stack.Item>
        </Stack>
    )
}

export default AcceptTermsWidget;