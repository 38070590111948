import * as React from 'react'
import { AppState } from '../../../app.types';
import { connect } from 'react-redux';
import { ECAFOrderResult } from '../../../utils/wcaApiTypes';
import { CommandBarButton, Spinner, SpinnerSize } from '@fluentui/react';
import './eCafResendNotification.css';
import { resendCustomerNotification, resendCustomerNotificationClearstate } from 'containers/ecaf/redux/actions';
import * as CONSTANTS from 'containers/ecaf/redux/constants';
import { ECAFEditEnvelopeCustomerViewModel } from 'utils/newWcaApiTypes';
import Tools from 'utils/tools';
interface IAppProps {
    ecafOrder: ECAFOrderResult
    callbackResendResponse: (status: boolean) => void
    customerId: string | undefined
}

type AppProps = IAppProps & ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

type AppStates = {
    isLoading: boolean
}


class ResendNotification extends React.Component<AppProps, AppStates> {

    constructor(props: Readonly<AppProps>) {
        super(props);
        this.state = {
            isLoading: false,
        }
    }

    private resendNotification() {
        this.setState({ isLoading: true });

        const { actionstepContext, ecafOrder, customerId } = this.props;
        let command: ECAFEditEnvelopeCustomerViewModel[] = []

        if (!Tools.isNullOrEmptyOrUndefined(customerId)) {
            const customer = ecafOrder.envelopeDetails?.customers?.find(customer => customer.id === customerId);

            command.push(new ECAFEditEnvelopeCustomerViewModel({
                customerFirstName: customer!.firstName,
                customerLastName: customer!.lastName,
                customerCountryCode: customer!.countryCodeId,
                customerEmailAddress: customer!.emailAddress,
                customerId: customer!.id,
                customerMobileNumber: customer!.mobileNumber,
                envelopeId: ecafOrder.envelopeId,
                orgKey: actionstepContext?.orgKey
            }))
        }
        else {
            ecafOrder.envelopeDetails?.customers?.map(customer => {
                command.push(new ECAFEditEnvelopeCustomerViewModel({
                    customerFirstName: customer.firstName,
                    customerLastName: customer.lastName,
                    customerCountryCode: customer.countryCodeId,
                    customerEmailAddress: customer.emailAddress,
                    customerId: customer.id,
                    customerMobileNumber: customer.mobileNumber,
                    envelopeId: ecafOrder.envelopeId,
                    orgKey: actionstepContext?.orgKey
                }))
            });
        }
        this.props.resendCustomerNotification(command)
    }

    static getDerivedStateFromProps(nextProps: AppProps): AppStates {
        let nextState = {} as AppStates;
        //  if (nextProps.gotResponse) {
        switch (nextProps.requestType) {
            // case CONSTANTS.RESEND_CUSTOMER_NOTIFICATION_REQUESTED: {
            //     nextState.isLoading = true;
            //     break;
            // }
            case CONSTANTS.RESEND_CUSTOMER_NOTIFICATION_SUCCESS: {
                nextState.isLoading = false;
                nextProps.callbackResendResponse(nextProps.resendNotificationStatus ?? true);
                nextProps.resendCustomerNotificationClearstate();
                break;
            }
            case CONSTANTS.RESEND_CUSTOMER_NOTIFICATION_FAILED: {
                nextState.isLoading = false;
                nextProps.callbackResendResponse(nextProps.resendNotificationStatus ?? true);
                nextProps.resendCustomerNotificationClearstate();
                break;
            }
            default:
                return nextState;
        }
        //  }
        return nextState;
    }

    render() {
        const { isLoading } = this.state;
        return (
            <>
                {!isLoading ?
                    <CommandBarButton className='resend-notiifcation-button' iconProps={{ iconName: 'Send' }} text="Resend Notification" onClick={() => this.resendNotification()} />
                    :
                    <Spinner size={SpinnerSize.large} label="resending notification..." />}
            </>
        )
    }
}



const mapStateToProps = (state: AppState) => {
    return {
        actionstepContext: state.common.actionstepContext,
        error: state.eCaf.error,
        success: state.eCaf.success,
        gotResponse: state.eCaf.gotResponse,
        requestType: state.eCaf.requestType,
        resendNotificationStatus: state.eCaf.resendNotificationStatus
    }
}

const mapDispatchToProps = {
    resendCustomerNotification,
    resendCustomerNotificationClearstate
}

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(ResendNotification));