import * as React from 'react'
import ClearIcon from "@material-ui/icons/Clear";
import 'containers/ecaf/createOrder.css'
import {
    Fab,
    FormControl,
    FormControlLabel,
    TextField,
    FormGroup,
    TextareaAutosize,
    Checkbox,
    MuiThemeProvider,
    FormHelperText
} from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import { formLabelsTheme } from 'containers/ecaf/muiThemeStyles'
import { ECAFTransaction } from '../../../utils/wcaApiTypes';



type AppProps = {
    updateTransaction: (updatedTransaction: ECAFTransaction, index: number) => void;
    transactionIndex: number;
    transaction: ECAFTransaction;
    removeTransaction: (index: number) => void;
    isStandingAuthority: boolean;
}

type AppStates = {
    refresh: boolean;
    validationErrors: string[];
}

class ECafTransactionForm extends React.Component<AppProps, AppStates> {
    constructor(props: AppProps) {
        super(props);

        this.state = {
            refresh: false,
            validationErrors: (props.transaction.transactionTypes?.length ?? 0) === 0 ? ["transactionTypes"] : []
        }
    }

    changeInfo = (newValue: any, key: string, extraKey?: string) => {
        var transaction = this.props.transaction;
        var validationErrors = [...this.state.validationErrors];
        if (key === "transactionTypes" && extraKey) {
            var index = transaction.transactionTypes?.indexOf(extraKey);
            if (index && index > -1 || index === 0) {
                transaction.transactionTypes?.splice(index, 1);
            } else {
                if (!transaction.transactionTypes) {
                    transaction.transactionTypes = [];
                }
                transaction.transactionTypes?.push(extraKey);
            }

            //Error handling for conveyancing transations, checks if one is selected, if not adds error, removes it if one has been selected
            var errorIndex = validationErrors.indexOf(key);
            if (transaction.transactionTypes && transaction.transactionTypes?.length === 0) {
                if (errorIndex == -1) { // not exit, add
                    validationErrors.push(key);
                    this.setState({
                        validationErrors: [...validationErrors]
                    });
                }
            } else {
                if (errorIndex > -1) { // exit, remove
                    validationErrors.splice(errorIndex, 1);
                    this.setState({
                        validationErrors: [...validationErrors]
                    });
                }
            }
            //This is for checking if other is selected, that there is some content in additional information
            //This preemptively adds an error onces other is selected
            if (extraKey === "Other") {
                var otherIndexError = validationErrors.indexOf("other");
                //Reobtained index as "index" is saved pre checkbox save
                var otherIndexActive = transaction.transactionTypes?.indexOf("Other");
                //If no content, add other to validationErrors
                if ((transaction.additionalInstructions === "" || !transaction.additionalInstructions)
                    && (otherIndexActive !== undefined && otherIndexActive > -1)) {
                    if (otherIndexError === -1) {
                        validationErrors.push("other");
                        this.setState({
                            validationErrors: [...validationErrors]
                        })
                    }
                    //If there is content, check if error is still in array, and remove it if it is
                } else {
                    if (otherIndexError > -1) {
                        validationErrors.splice(otherIndexError, 1);
                        this.setState({
                            validationErrors: [...validationErrors]
                        })
                    }
                }
            }
        }
        else if (key === "landTitleRef") {
            transaction.titleReference = newValue;
        } else if (key === "propertyAddress") {
            transaction.propertyAddress = newValue;
        }
        else if (key === "additionalInstructions") {
            transaction.additionalInstructions = newValue;
            //Checking for "other" error, and resolving it on addition/removal of additionalInstructions content
            var validationErrors = [...this.state.validationErrors];
            var otherIndex = validationErrors.indexOf("other");
            var otherActive = transaction.transactionTypes?.indexOf("Other");
            //If no content and other is selected, add other to validationErrors
            if (transaction.additionalInstructions === "" && (otherActive !== undefined && otherActive > -1)) {
                if (otherIndex === -1) {
                    validationErrors.push("other");
                    this.setState({
                        validationErrors: [...validationErrors]
                    })
                }
                //If there is content, check if error is still in array, and remove it if it is
            } else {
                if (otherIndex > -1) {
                    validationErrors.splice(otherIndex, 1);
                    this.setState({
                        validationErrors: [...validationErrors]
                    })
                }
            }

        }
        else if (key === "revocationDates") {
            // newValue is using yyyy-mm-dd format, so got to parse it to proper date time for the json conversion to work and the form can be submitted
            transaction.revocationDate = new Date(Date.parse(newValue));
        }



        transaction.hasInvalidField = validationErrors.length > 0;
        this.props.updateTransaction(transaction, this.props.transactionIndex);
    }

    // convert date into yyyy-mm-dd (specifically with 2 digits for m and d) for text field
    toTextFieldDateFormat = (date: Date) => {
        const year = date.getFullYear();
        const monthWithOffset = date.getUTCMonth() + 1; // +1 as jan is 0 in js
        const month = monthWithOffset.toString().length < 2
            ? `0${monthWithOffset}`
            : monthWithOffset;
        const day = date.getUTCDate().toString().length < 2
            ? `0${date.getDate()}`
            : date.getDate();
        return `${year}-${month}-${day}`;
    }

    render(): JSX.Element {
        const { transactionIndex, isStandingAuthority, transaction } = this.props;
        const { validationErrors } = this.state;

        return (

            <MuiThemeProvider theme={formLabelsTheme}>
                <div className="section">
                    <Grid container spacing={3}>

                        {!isStandingAuthority &&
                            <Grid item xs={6}>
                                <div className="section-name">  TRANSACTION {transactionIndex + 1} </div>
                            </Grid>
                        }

                        {transactionIndex > 0 &&
                            <Grid item xs={6}>
                                <Fab variant="extended" className="remove-btn" size="small" onClick={() => this.props.removeTransaction(transactionIndex)}>
                                    <ClearIcon />
                                    Remove
                                </Fab>
                            </Grid>
                        }
                    </Grid>

                    {!isStandingAuthority &&
                        <Grid container spacing={3}>
                            <Grid item xs={8}>
                                <TextField
                                    label="PROPERTY ADDRESS"
                                    required
                                    variant="outlined"
                                    className="text-field"
                                    size="small"
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    value={transaction.propertyAddress ?? ""}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.changeInfo(event.target.value, "propertyAddress")}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    label="LAND TITLE REFERENCE"
                                    required
                                    variant="outlined"
                                    className="text-field"
                                    size="small"
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    value={transaction.titleReference ?? ""}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.changeInfo(event.target.value, "landTitleRef")}
                                />
                            </Grid>
                        </Grid>
                    }

                    <FormControl required>
                        <h4 className="transaction-types">
                            CONVEYANCING TRANSACTIONS
                        </h4>
                        <FormHelperText
                            error={true}
                            hidden={validationErrors?.includes("transactionTypes") ? false : true}
                            aria-hidden={validationErrors?.includes("transactionTypes") ? false : true}
                        >At least 1 conveyancing transaction type must be selected</FormHelperText>
                        <FormGroup className="transaction-types">
                            <Grid container spacing={4}>
                                <Grid item xs={3}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox color="primary" checked={transaction?.transactionTypes?.includes("Transfer") ?? false}
                                                onChange={(event, newValue) => this.changeInfo(newValue, "transactionTypes", "Transfer")} />
                                        }
                                        label="Transfer"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox color="primary" checked={transaction?.transactionTypes?.includes("PriorityNotice") ?? false}
                                                onChange={(event, newValue) => this.changeInfo(newValue, "transactionTypes", "PriorityNotice")} />
                                        }
                                        label="Priority Notice"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox color="primary" checked={transaction?.transactionTypes?.includes("Other") ?? false}
                                                onChange={(event, newValue) => this.changeInfo(newValue, "transactionTypes", "Other")} />
                                        }
                                        label="Other"
                                    />
                                </Grid>

                                <Grid item xs={1}>

                                </Grid>

                                <Grid item xs={4}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox color="primary" checked={transaction?.transactionTypes?.includes("Mortgage") ?? false}
                                                onChange={(event, newValue) => this.changeInfo(newValue, "transactionTypes", "Mortgage")} />
                                        }
                                        label="Mortgage"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox color="primary" checked={transaction?.transactionTypes?.includes("Discharge") ?? false}
                                                onChange={(event, newValue) => this.changeInfo(newValue, "transactionTypes", "Discharge")} />
                                        }
                                        label="Discharge Release Mortgage"
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox color="primary" checked={transaction?.transactionTypes?.includes("Caveat") ?? false}
                                                onChange={(event, newValue) => this.changeInfo(newValue, "transactionTypes", "Caveat")} />
                                        }
                                        label="Caveat"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox color="primary" checked={transaction?.transactionTypes?.includes("WithdrawalOfCaveat") ?? false}
                                                onChange={(event, newValue) => this.changeInfo(newValue, "transactionTypes", "WithdrawalOfCaveat")} />
                                        }
                                        label="Withdrawal of Caveat"
                                    />
                                </Grid>
                            </Grid>
                        </FormGroup>
                    </FormControl>

                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <TextareaAutosize className="transaction-instruction"
                                style={{ width: "100%", padding: "10px 10px" }}
                                placeholder="Additional Instructions"
                                value={transaction.additionalInstructions ?? ""}
                                rowsMin={8}
                                onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => this.changeInfo(event.target.value, "additionalInstructions")}

                            />
                            <FormHelperText
                                error={true}
                                hidden={validationErrors?.includes("other") ? false : true}
                                aria-hidden={validationErrors?.includes("other") ? false : true}
                            >If Other is selected, additional imformation must be added</FormHelperText>
                        </Grid>
                        {isStandingAuthority &&
                            <Grid item xs={6}>
                                <TextField className="transaction-date"
                                    label="Revocation Date"
                                    type="date"
                                    value={transaction?.revocationDate ? this.toTextFieldDateFormat(transaction.revocationDate) : null}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.changeInfo(event.target.value, "revocationDates")}
                                    InputLabelProps={{ shrink: true, }}
                                />
                            </Grid>
                        }
                    </Grid>
                </div>

            </MuiThemeProvider>
        )
    }
}

export default ECafTransactionForm;