import { FTParty } from "./wcaApiTypes";

export const FTRoadTypeRecord: Record<string, string> = {
    ["ACCS"]: "Access",
    ["ALLY"]: "Alley",
    ["ALWY"]: "Alleyway",
    ["AMBL"]: "Amble",
    ["ANCG"]: "Anchorage",
    ["APP"]: "Approach",
    ["ARC"]: "Arcade",
    ["ARTL"]: "Arterial",
    ["ARTY"]: "Artery",
    ["AV"]: "Avenue",
    ["BA"]: "Banan",
    ["BANK"]: "Bank",
    ["BASN"]: "Basin",
    ["BAY"]: "Bay",
    ["BCH"]: "Beach",
    ["BDGE"]: "Bridge",
    ["BDWY"]: "Broadway",
    ["BEND"]: "Bend",
    ["BLK"]: "Block",
    ["BOWL"]: "Bowl",
    ["BR"]: "Brace",
    ["BRAE"]: "Brae",
    ["BRK"]: "Break",
    ["BROW"]: "Brow",
    ["BVD"]: "Boulevard",
    ["BVDE"]: "Boulevarde",
    ["BWLK"]: "Boardwalk",
    ["BYPA"]: "Bypass",
    ["BYWY"]: "Byway",
    ["CCT"]: "Circuit",
    ["CH"]: "Chase",
    ["CIR"]: "Circle",
    ["CL"]: "Close",
    ["CLDE"]: "Colonnade",
    ["CLST"]: "Cluster",
    ["CLT"]: "Circlet",
    ["CMMN"]: "Common",
    ["CNR"]: "Corner",
    ["CNWY"]: "Centreway",
    ["CON"]: "Concourse",
    ["COVE"]: "Cove",
    ["COWY"]: "Crossway",
    ["CPS"]: "Copse",
    ["CR"]: "Crescent",
    ["CRCS"]: "Circus",
    ["CRD"]: "Crossroad",
    ["CRSE"]: "Course",
    ["CRSG"]: "Crossing",
    ["CRSS"]: "Cross",
    ["CRST"]: "Crest",
    ["CSAC"]: "Cul-de-sac",
    ["CSO"]: "Corso",
    ["CSWY"]: "Causeway",
    ["CT"]: "Court",
    ["CTR"]: "Centre",
    ["CTT"]: "Cutting",
    ["CTYD"]: "Courtyard",
    ["CUWY"]: "Cruiseway",
    ["CX"]: "Connection",
    ["DALE"]: "Dale",
    ["DELL"]: "Dell",
    ["DENE"]: "Dene",
    ["DEVN"]: "Deviation",
    ["DIP"]: "Dip",
    ["DIV"]: "Divide",
    ["DMN"]: "Domain",
    ["DOCK"]: "Dock",
    ["DR"]: "Drive",
    ["DSTR"]: "Distributor",
    ["DVWY"]: "Driveway",
    ["EDGE"]: "Edge",
    ["ELB"]: "Elbow",
    ["END"]: "End",
    ["ENT"]: "Entrance",
    ["ESP"]: "Esplanade",
    ["EST"]: "Estate",
    ["EXP"]: "Expressway",
    ["EXTN"]: "Extension",
    ["FAWY"]: "Fairway",
    ["FB"]: "Firebreak",
    ["FE"]: "Fireline",
    ["FITR"]: "Firetrail",
    ["FLAT"]: "Flat",
    ["FLTS"]: "Flats",
    ["FOLW"]: "Follow",
    ["FORD"]: "Ford",
    ["FORM"]: "Formation",
    ["FRNT"]: "Front",
    ["FRTG"]: "Frontage",
    ["FSHR"]: "Foreshore",
    ["FTRK"]: "Firetrack",
    ["FTWY"]: "Footway",
    ["FWY"]: "Freeway",
    ["GAP"]: "Gap",
    ["GDN"]: "Garden",
    ["GDNS"]: "Gardens",
    ["GLDE"]: "Glade",
    ["GLEN"]: "Glen",
    ["GLY"]: "Gully",
    ["GR"]: "Grove",
    ["GRA"]: "Grange",
    ["GRN"]: "Green",
    ["GRND"]: "Ground",
    ["GTE"]: "Gate",
    ["GTWY"]: "Gateway",
    ["HILL"]: "Hill",
    ["HOLW"]: "Hollow",
    ["HRBR"]: "Harbour",
    ["HTH"]: "Heath",
    ["HTRD"]: "Highroad",
    ["HTS"]: "Heights",
    ["HUB"]: "Hub",
    ["HWY"]: "Highway",
    ["INTG"]: "Interchange",
    ["INTN"]: "Intersection",
    ["ISLD"]: "Island",
    ["JNC"]: "Junction",
    ["KEY"]: "Key",
    ["KEYS"]: "Keys",
    ["LANE"]: "Lane",
    ["LDG"]: "Landing",
    ["LEES"]: "Lees",
    ["LINE"]: "Line",
    ["LINK"]: "Link",
    ["LKT"]: "Lookout",
    ["LNWY"]: "Laneway",
    ["LOOP"]: "Loop",
    ["LT"]: "Little",
    ["LWR"]: "Lower",
    ["MALL"]: "Mall",
    ["MEW"]: "Mew",
    ["MEWS"]: "Mews",
    ["MNDR"]: "Meander",
    ["MNR"]: "Manor",
    ["MT"]: "Mount",
    ["MTWY"]: "Motorway",
    ["NOOK"]: "Nook",
    ["OTLK"]: "Outlook",
    ["OTLT"]: "Outlet",
    ["PARK"]: "Park",
    ["PART"]: "Part",
    ["PASS"]: "Pass",
    ["PATH"]: "Path",
    ["PDE"]: "Parade",
    ["PIAZ"]: "Piazza",
    ["PKLD"]: "Parklands",
    ["PKT"]: "Pocket",
    ["PL"]: "Place",
    ["PLAT"]: "Plateau",
    ["PLZA"]: "Plaza",
    ["PNT"]: "Point",
    ["PORT"]: "Port",
    ["PROM"]: "Promenade",
    ["PRST"]: "Pursuit",
    ["PSGE"]: "Passage",
    ["PWAY"]: "Pathway",
    ["PWY"]: "Parkway",
    ["QDGL"]: "Quadrangle",
    ["QDRT"]: "Quadrant",
    ["QUAD"]: "Quad",
    ["QY"]: "Quay",
    ["QYS"]: "Quays",
    ["RAMP"]: "Ramp",
    ["RCH"]: "Reach",
    ["RD"]: "Road",
    ["RDGE"]: "Ridge",
    ["RDS"]: "Roads",
    ["RDSD"]: "Roadside",
    ["RDWY"]: "Roadway",
    ["RES"]: "Reserve",
    ["REST"]: "Rest",
    ["RGWY"]: "Ridgeway",
    ["RIDE"]: "Ride",
    ["RING"]: "Ring",
    ["RISE"]: "Rise",
    ["RMBL"]: "Ramble",
    ["RND"]: "Round",
    ["RNDE"]: "Ronde",
    ["RNGE"]: "Range",
    ["ROW"]: "Row",
    ["ROWY"]: "Right of Way",
    ["RSBL"]: "Rosebowl",
    ["RSNG"]: "Rising",
    ["RTE"]: "Route",
    ["RTRN"]: "Return",
    ["RTT"]: "Retreat",
    ["RTY"]: "Rotary",
    ["RUE"]: "Rue",
    ["RUN"]: "Run",
    ["RVR"]: "River",
    ["RVRA"]: "Riviera",
    ["RVWY"]: "Riverway",
    ["SBWY"]: "Subway",
    ["SDNG"]: "Siding",
    ["SHUN"]: "Shunt",
    ["SHWY"]: "State Highway",
    ["SLPE"]: "Slope",
    ["SND"]: "Sound",
    ["SPUR"]: "Spur",
    ["SQ"]: "Square",
    ["ST"]: "Street",
    ["STPS"]: "Steps",
    ["STRA"]: "Strand",
    ["STRP"]: "Strip",
    ["STRS"]: "Stairs",
    ["SVWY"]: "Service Way",
    ["TARN"]: "Tarn",
    ["TCE"]: "Terrace",
    ["THFR"]: "Thoroughfare",
    ["TKWY"]: "Trunkway",
    ["TLWY"]: "Tollway",
    ["TOP"]: "Top",
    ["TOR"]: "Tor",
    ["TRI"]: "Triangle",
    ["TRK"]: "Track",
    ["TRL"]: "Trail",
    ["TRLR"]: "Trailer",
    ["TRWY"]: "Throughway",
    ["TURN"]: "Turn",
    ["TWIST"]: "Twist",
    ["TWRS"]: "Towers",
    ["UPAS"]: "Underpass",
    ["UPR"]: "Upper",
    ["VALE"]: "Vale",
    ["VIAD"]: "Viaduct",
    ["VIEW"]: "View",
    ["VLLS"]: "Villas",
    ["VLLY"]: "Valley",
    ["VSTA"]: "Vista",
    ["VWS"]: "Views",
    ["WADE"]: "Wade",
    ["WALK"]: "Walk",
    ["WAY"]: "Way",
    ["WDS"]: "Woods",
    ["WHRF"]: "Wharf",
    ["WKWY"]: "Walkway",
    ["WTRS"]: "Waters",
    ["WTWY"]: "Waterway",
    ["WYND"]: "Wynd",
    ["YARD"]: "Yard"
}

enum FTState {
    ACT = "ACT",
    NSW = "NSW",
    NT = "NT",
    QLD = "QLD",
    SA = "SA",
    TAS = "TAS",
    VIC = "VIC",
    WA = "WA",
    Other = "Other"
}

export interface IFTTooltipAddress {
    address: string;
    city: string;
    postcode: string;
    country: string;
    state: string;
}

const getFTSourceAddress = (party: FTParty): string => {
    const unitNo = party.unitNo ?? "";
    const floorNo = party.floorNo ?? "";
    const buildingName = party.buildingName ?? "";
    const streetNo = party.streetNo ?? "";
    const streetName = party.streetName ?? "";
    const streetType = party.streetType ?? "";

    let returnedAddress = "";

    if (unitNo.length > 0) {
        returnedAddress = `Unit ${unitNo}`;
    }

    if (floorNo.length > 0) {
        if (returnedAddress.length > 0) {
            returnedAddress += `, Floor ${floorNo}`;
        } else {
            returnedAddress += `Floor ${floorNo}`;
        }
    }

    if (buildingName.length > 0) {
        if (returnedAddress) {
            returnedAddress += `, ${buildingName}`;
        } else {
            returnedAddress += buildingName;
        }
    }

    if (streetNo != null) {
        returnedAddress += ` ${streetNo}`;
    }

    if (streetName != null) {
        returnedAddress += ` ${streetName}`;
    }

    if (streetType != null) {
        returnedAddress += ` ${FTRoadTypeRecord[streetType]}`;
    }

    return returnedAddress;
}

export const getFTAddress = (party: FTParty): IFTTooltipAddress => {
    const ftTooltipAddress: IFTTooltipAddress = {
        address: "",
        city: "",
        postcode: "",
        country: "",
        state: "",
    }

    ftTooltipAddress.address = getFTSourceAddress(party);
    ftTooltipAddress.city = party.city ?? "";
    ftTooltipAddress.postcode = party.postCode ?? "";
    ftTooltipAddress.country = party.country ?? "Australia";

    try {
        const stateValue = (party.stateProvince ?? "") as FTState;
        ftTooltipAddress.state = stateValue;
    } catch {
        if (ftTooltipAddress.country == "AU") {
            ftTooltipAddress.state = FTState.Other;
        }
    }

    return ftTooltipAddress;
}