import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { AppState } from 'app.types';
import { connect } from 'react-redux';
import { MessageBar, MessageBarType } from 'office-ui-fabric-react'
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import { DefaultButton, PrimaryButton } from "office-ui-fabric-react/lib/Button";
import * as CONSTANTS from 'containers/ecaf/redux/constants';
import 'containers/ecaf/statusPage.css';
import { renderStatus } from './util/util';

import {
    getEnvelopeStatus,
    updateVOIStatus,
    updateCounterSignStatus,
    getECAFOrderStatusPage
} from 'containers/ecaf/redux/actions';

import {
    Divider
} from "@material-ui/core";
import {
    EnvelopeDetailsCustomerViewModel,
    ECAFUpdateVOIStatusQuery,
    ECAFUpdateCounterSignStatusQuery,
    ECAFEnvelopeStatus,
    EnvelopeDetailsViewModel
} from '../../utils/wcaApiTypes';
import { UserAuditTrail, SaveUserAuditTrailDataCommand } from 'utils/wcaApiTypes';
import { saveUserAuditTrailData } from 'redux/common/actions';
import Tools from 'utils/tools';

interface IAppProps {
}

type AppStates = {
    envelopeId: string | undefined,
    code: string | undefined,
    isLoading: boolean,
    downloadingDocuments: boolean,
    pageError: string | undefined,
    redirect: string,
    statusPageRedirect: boolean,
    orgKey: string | null,
    matterId: string | null,
    transactionId: string | null,
    customerId: string
}

type AppProps = IAppProps & ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

class ECAFStatusPage extends React.Component<AppProps, AppStates> {

    constructor(props: Readonly<AppProps>) {
        super(props);

        this.state = {
            envelopeId: undefined,
            code: undefined,
            isLoading: false,
            downloadingDocuments: false,
            pageError: undefined,
            redirect: "",
            statusPageRedirect: false,
            orgKey: "",
            matterId: "",
            transactionId: "",
            customerId: ""
        }
    }

    public componentDidMount(): void {
        this.loadStatus();
    }

    private loadStatus = () => {
        let id = new URLSearchParams(window.location.search).get("id");
        let code = new URLSearchParams(window.location.search).get("code");
        let orgId = new URLSearchParams(window.location.search).get("actionsteporg");
        let transactionId = new URLSearchParams(window.location.search).get("transactionId");
        let matterId = new URLSearchParams(window.location.search).get("matterid");
        let type = new URLSearchParams(window.location.search).get("type");
        let customerId = new URLSearchParams(window.location.search).get("customerId");

        if (!code) {
            this.setState({
                orgKey: orgId ?? "",
                matterId: matterId ?? "",
                transactionId: transactionId,
                customerId: customerId!
            });
            this.props.getECAFOrderStatusPage(transactionId ?? "", type ?? "");
            return;
        }
        if (orgId) {
            this.setState({
                statusPageRedirect: true
            })
        }
        if (id && code && this.isValidGuid(id)) {
            this.setState({
                envelopeId: id,
                code: code,
                isLoading: true,
                customerId: customerId!
            });
            this.props.getEnvelopeStatus(id, code)

        } else {
            this.setState({
                pageError: "The envelopeID in URL is invalid"
            });
        }
    }

    private isValidGuid = (id: string | undefined | null) => {
        if (!id) return false;

        var pattern = new RegExp(/^([0-9A-Fa-f]{8}[-][0-9A-Fa-f]{4}[-][0-9A-Fa-f]{4}[-][0-9A-Fa-f]{4}[-][0-9A-Fa-f]{12})$/);
        if (id.match(pattern)) return true;

        return false;
    }

    private redirectToHome = () => {
        this.setState({ redirect: "/matter" });
    }
    private completeVOI = (envelopeId: string, customerId: string, approval: boolean, envelopeDetails: EnvelopeDetailsViewModel) => {

        this.props.updateVOIStatus(new ECAFUpdateVOIStatusQuery({
            envelopeId: envelopeId,
            customerId: customerId,
            isApproved: approval,
            voiVerificationCode: this.state.code
        }));
        if (this.state.statusPageRedirect && approval) {
            //check if all the customers have been approved
            var customersExceptThis = envelopeDetails.customers?.filter(x => x.id != customerId);
            var areRemainingCustomerVoiApproved = customersExceptThis?.filter(x => x.voiReviewStatus != "Approved") ?? [];
            if (areRemainingCustomerVoiApproved.length == 0) {
                let transactionId = new URLSearchParams(window.location.search).get("transactionId");
                let orgId = new URLSearchParams(window.location.search).get("actionsteporg");
                let matterId = new URLSearchParams(window.location.search).get("matterid");
                this.setState({
                    transactionId: transactionId,
                    orgKey: orgId,
                    matterId: matterId
                })
                this.props.getECAFOrderStatusPage(transactionId ?? "", ECAFEnvelopeStatus.ReadyToCountersign);
            }
        }

        let description = approval ? "Approved eCaf. Id: " + envelopeId : "Rejected eCaf. Id: " + envelopeId;
        this.saveUserAuditTrailDataIntoDb("eCAF", "eCAF/Web VOI Status", description)
    }

    private CounterSignEnvelope = (envelopeId: string) => {
        this.props.updateCounterSignStatus(new ECAFUpdateCounterSignStatusQuery({
            envelopeId: envelopeId,
            counterSignVerificationCode: this.state.code
        }));
        this.saveUserAuditTrailDataIntoDb("eCAF", "eCAF/Web VOI Status", "Signed eCaf Successfully. Id: " + envelopeId);
    }

    private saveUserAuditTrailDataIntoDb(integration: string, integrationLink: string, description: string): void {
        let orgId = new URLSearchParams(window.location.search).get("actionsteporg");
        let matterId = new URLSearchParams(window.location.search).get("matterid");
        let userAuditTrail = new UserAuditTrail();
        userAuditTrail.actionstepOrg = orgId ?? "";
        userAuditTrail.matterId = Number(matterId);
        userAuditTrail.description = description;
        userAuditTrail.integration = integration;
        userAuditTrail.integrationLink = integrationLink;

        this.props.saveUserAuditTrailData(new SaveUserAuditTrailDataCommand({
            userAuditTrailData: userAuditTrail
        }));
    }

    private renderRejectOrApproval = (envelopId: string, cust: EnvelopeDetailsCustomerViewModel, envelopeDetails: EnvelopeDetailsViewModel) => {
        let element = null;
        if (cust.voiReviewStatus === "NotReviewed") {
            element = <div> <div className="ms-Grid-row" >
                <br />
                <Divider variant="middle" />
                <br />
                <div className="ms-Grid-col ms-sm12 ms-lg4">
                    <p><b>Finalise Document</b></p>
                </div>
                <div className="ms-Grid-col ms-sm12 ms-lg4">
                    <p><b>You must approve or reject VOI first.</b></p>
                </div>
            </div>
                <div className="ms-Grid-row" >
                    <div className="ms-Grid-col ms-sm12 ms-lg4">
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-lg4">
                        <div className="ms-Grid-row" >
                            <div className="ms-Grid-col ms-sm12 ms-lg4">
                                <DefaultButton
                                    className="btn btn-success"
                                    text="Approve"
                                    onClick={() => this.completeVOI(envelopId, cust.id!, true, envelopeDetails)}
                                />
                            </div>
                            <div className="ms-Grid-col ms-sm12 ms-lg4">
                                <PrimaryButton className="btn btn-danger"
                                    text="Reject"
                                    onClick={() => this.completeVOI(envelopId, cust.id!, false, envelopeDetails)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
        return element;
    }
    private renderVOIIdentityTime = (customerData: EnvelopeDetailsCustomerViewModel) => {
        let element = null;
        if (customerData.voiReviewStatus != "NotReviewed") {
            element = <div className="ms-Grid-row" >
                <br />
                <div className="ms-Grid-col ms-sm12 ms-lg4">

                </div>
                <div className="ms-Grid-col ms-sm12 ms-lg4">
                    <span > <p><b> Verification of identity {customerData.voiReviewStatus} on {customerData.voiReviewedOn?.toLocaleDateString()}  </b></p></span>
                </div>
            </div>
        }
        return element;
    }
    private renderCustomers = () => {
        const { envelopeDetails, status } = this.props;
        const { customerId } = this.state;

        if (!Tools.isNullOrEmptyOrUndefined(customerId)) {
            let customer: EnvelopeDetailsCustomerViewModel[] = [];
            let customerInfo = envelopeDetails?.customers?.find(customer => customer.id === customerId)
            customer.push(customerInfo!)
            envelopeDetails!.customers = customer
        }

        let customers = envelopeDetails?.customers?.map((cust: EnvelopeDetailsCustomerViewModel, i: number) => {
            return (
                <div className="customer-wrapper" key={cust.id} >
                    <div className="ms-Grid-row" >
                        <div className="ms-Grid-col ms-sm12 ms-lg4">
                            <p><b> Name :</b></p>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-lg4">
                            <p>{cust.firstName} {cust.lastName}</p>
                        </div>
                    </div>
                    <div className="ms-Grid-row" >
                        <div className="ms-Grid-col ms-sm12 ms-lg4">
                            <p><b> Email :</b></p>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-lg4">
                            <p>{cust.emailAddress}</p>
                        </div>
                    </div>
                    <div className="ms-Grid-row" >
                        <div className="ms-Grid-col ms-sm12 ms-lg4">
                            <p><b> Mobile :</b></p>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-lg4">
                            <p>{cust.fullyQualifiedMobileNumber}</p>
                        </div>
                    </div>
                    <div className="ms-Grid-row" >
                        <div className="ms-Grid-col ms-sm12 ms-lg4">
                            <p><b> Verification of identity :</b></p>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-lg4">
                            <p><b>Download and review:</b></p>
                        </div>
                    </div>
                    <div className="ms-Grid-row" >
                        <div className="ms-Grid-col ms-sm12 ms-lg4">
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-lg4">
                            <a className="btn btn-primary" target="_blank" rel="noopener noreferrer"
                                href={`/api/ecaf/ecaf-voi-download?envelopeId=${envelopeDetails?.id}&customerId=${cust.id}`}>
                                {status == ECAFEnvelopeStatus.Finalised ? 'Download VOI Certificate' : 'Review VOI Certificate'}
                            </a>
                        </div>
                    </div>

                    {this.renderRejectOrApproval(envelopeDetails?.id!, cust, envelopeDetails)}
                    {this.renderVOIIdentityTime(cust)}
                </div>)
        });
        return customers;
    }

    private renderCounterSignedTime = () => {
        const { envelopeDetails } = this.props;
        let element = null;
        if (envelopeDetails?.countersignedOn) {
            element = <span><b>Document countersigned on {new Date(envelopeDetails?.countersignedOn!).toLocaleString()}</b></span>
        }
        else {
            element = <span><b>Document ready for countersigning</b></span>
        }
        return element;
    }

    private renderCounterSignStatus = () => {
        const { envelopeDetails } = this.props;
        let element = null;
        if (!envelopeDetails?.countersignedOn) {
            element = <a
                className="btn btn-success btn-countersign"
                onClick={() => this.CounterSignEnvelope(envelopeDetails?.id!)}
            >Sign</a>
        }
        return element;
    }
    private renderCounterSign = () => {
        const { envelopeDetails } = this.props;
        let envelopeStatus = envelopeDetails?.status;

        if (envelopeStatus !== ECAFEnvelopeStatus.ReadyToCountersign && envelopeStatus !== ECAFEnvelopeStatus.Finalised) return null;
        let customClass = (envelopeStatus == ECAFEnvelopeStatus.Finalised ? "ms-lg4 caf-finalised-padding" : "");

        let element =
            <div>
                <div className="ms-Grid-row" >
                    <div className="ms-Grid-col ms-sm12 ms-lg4">
                        <p><b> Finalise Document :</b></p>
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-lg4">
                        {this.renderCounterSignedTime()}
                    </div>
                </div>
                <div className="ms-Grid-row" >
                    <div className={"ms-Grid-col ms-smPush4 " + customClass}>
                        <a className={"btn btn-primary btn-download-caf-" + envelopeStatus} target="_blank" rel="noopener noreferrer"
                            href={`/api/ecaf/ecaf-envelope-download?envelopeId=${envelopeDetails?.id}`}>
                            {envelopeStatus == ECAFEnvelopeStatus.ReadyToCountersign ? 'Download CAF' : 'Download Client Authorisation Pack'}
                        </a>
                    </div>
                    {envelopeStatus !== ECAFEnvelopeStatus.Finalised && <div className="ms-Grid-col ms-smPush4">
                        {this.renderCounterSignStatus()}
                    </div>}
                </div>
            </div>
        return element;
    }


    static getDerivedStateFromProps(nextProps: AppProps, prevState: AppStates): AppStates {
        let nextState = {} as AppStates;

        if (nextProps.gotResponse) {
            switch (nextProps.requestType) {
                case CONSTANTS.GET_ECAF_ENVELOPE_STATUS_REQUESTED: {
                    nextState.isLoading = false;
                    break;
                }
                case CONSTANTS.GET_ECAF_ENVELOPE_STATUS_SUCCESS:
                    nextState.isLoading = false;
                    break;
                default:
                    return nextState;
            }
        }
        return nextState;
    }

    render() {
        const { isLoading, pageError, statusPageRedirect, customerId } = this.state;
        const { error, envelopeDetails, orderStatusPageUrl } = this.props;

        if (envelopeDetails && !Tools.isNullOrEmptyOrUndefined(customerId)) {
            const customer = envelopeDetails.customers?.find(customer => customer.id === customerId)
            envelopeDetails.totalPrice = customer!.price;
        }

        if (this.state.redirect) {
            return (<Redirect to={this.state.redirect} />)
        } else if (orderStatusPageUrl) {
            let url = orderStatusPageUrl + "&actionsteporg=" + this.state.orgKey + "&matterid=" + this.state.matterId + "&transactionId=" + this.state.transactionId;
            window.location.href = url;
        }

        return (
            <div>
                <div className="vertical-container wrapper-content animated fadeIn" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-lg12 ">
                            <h3 className="tittle">
                                <big><b>eCAF Transaction Summary</b></big>
                            </h3>
                            <br />
                            {isLoading ?
                                <Spinner size={SpinnerSize.large} label="loading eCAF Transaction..." />
                                :
                                <>
                                    {pageError &&
                                        <MessageBar messageBarType={MessageBarType.error}>
                                            Error: {pageError}
                                        </MessageBar>
                                    }

                                    <p>Updated Time: {new Date().toLocaleTimeString()}</p>
                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-sm12 ms-lg4">
                                            <p><b> Envelope Name :</b></p>
                                        </div>
                                        <div className="ms-Grid-col ms-sm12 ms-lg4">
                                            <p>{envelopeDetails?.name}</p>
                                        </div>
                                    </div>
                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-sm12 ms-lg4">
                                            <p><b>Envelope Status :</b></p>
                                        </div>
                                        <div className="ms-Grid-col ms-sm12 ms-lg4">
                                            <div className={('status-' + envelopeDetails?.status) + " status-shape"}>{renderStatus(envelopeDetails?.status)}</div>
                                        </div>
                                    </div>
                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-sm12 ms-lg4">
                                            <p><b> Pay Method :</b></p>
                                        </div>
                                        <div className="ms-Grid-col ms-sm12 ms-lg8">
                                            <p>{envelopeDetails?.payMethod}</p>
                                        </div>
                                    </div>
                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-sm12 ms-lg4">
                                            <p><b> Total Price :</b></p>
                                        </div>
                                        <div className="ms-Grid-col ms-sm12 ms-lg4">
                                            <p>${envelopeDetails?.totalPrice?.toFixed(2)}</p>
                                        </div>
                                    </div>

                                    <Divider variant="middle" />
                                    <br />


                                    {this.renderCustomers()}


                                    {this.renderCounterSign()}

                                    {error &&
                                        <MessageBar messageBarType={MessageBarType.error}>
                                            Error: {error}
                                        </MessageBar>}
                                    {statusPageRedirect &&
                                        <div className="ms-Grid-row ">
                                            <div className="ms-Grid-col ms-sm12 ms-lg4 ms-lgPush8">
                                                <PrimaryButton
                                                    text="Return to konekta"
                                                    onClick={() => this.redirectToHome()}
                                                />
                                            </div>
                                        </div>
                                    }
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: AppState) => {
    return {
        error: state.emailArchiver.error,
        gotResponse: state.eCaf.gotResponse,
        success: state.eCaf.success,
        requestType: state.eCaf.requestType,
        envelopeDetails: state.eCaf.envelopeDetails,
        message: state.eCaf.message,
        status: state.eCaf.status,
        orderStatusPageUrl: state.eCaf.statusPageUrl
    }
}

const mapDispatchToProps = {
    getEnvelopeStatus,
    getECAFOrderStatusPage,
    updateVOIStatus,
    updateCounterSignStatus,
    saveUserAuditTrailData
}

export default connect(mapStateToProps, mapDispatchToProps)(ECAFStatusPage);