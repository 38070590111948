import { IDropdownOption, IChoiceGroupOption, IComboBoxOption } from "office-ui-fabric-react";

export const Countries: IDropdownOption[] = [
    { key: "", text: "" },
    { key: "AU", text: "Australia" },
    { key: "NZ", text: "New Zealand" },
    { key: "AF", text: "Afghanistan" },
    { key: "AL", text: "Albania" },
    { key: "DZ", text: "Algeria" },
    { key: "AS", text: "American Samoa" },
    { key: "AD", text: "Andorra" },
    { key: "AO", text: "Angola" },
    { key: "AI", text: "Anguilla" },
    { key: "AQ", text: "Antarctica" },
    { key: "AG", text: "Antigua and Barbuda" },
    { key: "AR", text: "Argentina" },
    { key: "AM", text: "Armenia" },
    { key: "AW", text: "Aruba" },
    { key: "AT", text: "Austria" },
    { key: "AZ", text: "Azerbaijan" },
    { key: "BS", text: "Bahamas" },
    { key: "BH", text: "Bahrain" },
    { key: "BD", text: "Bangladesh" },
    { key: "BB", text: "Barbados" },
    { key: "BY", text: "Belarus" },
    { key: "BE", text: "Belgium" },
    { key: "BZ", text: "Belize" },
    { key: "BJ", text: "Benin" },
    { key: "BM", text: "Bermuda" },
    { key: "BT", text: "Bhutan" },
    { key: "BO", text: "Bolivia" },
    { key: "BA", text: "Bosnia and Herzegovina" },
    { key: "BW", text: "Botswana" },
    { key: "BV", text: "Bouvet Island" },
    { key: "BR", text: "Brazil" },
    { key: "IO", text: "British Indian Ocean Territory" },
    { key: "BN", text: "Brunei Darussalam" },
    { key: "BG", text: "Bulgaria" },
    { key: "BF", text: "Burkina Faso" },
    { key: "BI", text: "Burundi" },
    { key: "KH", text: "Cambodia" },
    { key: "CM", text: "Cameroon" },
    { key: "CA", text: "Canada" },
    { key: "CV", text: "Cape Verde" },
    { key: "KY", text: "Cayman Islands" },
    { key: "CF", text: "Central African Republic" },
    { key: "TD", text: "Chad" },
    { key: "CL", text: "Chile" },
    { key: "CN", text: "China" },
    { key: "CX", text: "Christmas Island" },
    { key: "CC", text: "Cocos (Keeling) Islands" },
    { key: "CO", text: "Colombia" },
    { key: "KM", text: "Comoros" },
    { key: "CG", text: "Congo" },
    { key: "CD", text: "Congo, The Democratic Republic of The" },
    { key: "CK", text: "Cook Islands" },
    { key: "CR", text: "Costa Rica" },
    { key: "CI", text: "Côte d'Ivoire" },
    { key: "HR", text: "Croatia" },
    { key: "CU", text: "Cuba" },
    { key: "CY", text: "Cyprus" },
    { key: "CZ", text: "Czech Republic" },
    { key: "DK", text: "Denmark" },
    { key: "DJ", text: "Djibouti" },
    { key: "DM", text: "Dominica" },
    { key: "DO", text: "Dominican Republic" },
    { key: "TP", text: "East Timor" },
    { key: "EC", text: "Ecuador" },
    { key: "EG", text: "Egypt" },
    { key: "SV", text: "El Salvador" },
    { key: "GQ", text: "Equatorial Guinea" },
    { key: "ER", text: "Eritrea" },
    { key: "EE", text: "Estonia" },
    { key: "ET", text: "Ethiopia" },
    { key: "FK", text: "Falkland Islands (Malvinas)" },
    { key: "FO", text: "Faroe Islands" },
    { key: "FJ", text: "Fiji" },
    { key: "FI", text: "Finland" },
    { key: "FR", text: "France" },
    { key: "GF", text: "French Guiana" },
    { key: "PF", text: "French Polynesia" },
    { key: "TF", text: "French Southern Territories" },
    { key: "GA", text: "Gabon" },
    { key: "GM", text: "Gambia" },
    { key: "GE", text: "Georgia" },
    { key: "DE", text: "Germany" },
    { key: "GH", text: "Ghana" },
    { key: "GI", text: "Gibraltar" },
    { key: "GR", text: "Greece" },
    { key: "GL", text: "Greenland" },
    { key: "GD", text: "Grenada" },
    { key: "GP", text: "Guadeloupe" },
    { key: "GU", text: "Guam" },
    { key: "GT", text: "Guatemala" },
    { key: "GN", text: "Guinea" },
    { key: "GW", text: "Guinea-Bissau" },
    { key: "GY", text: "Guyana" },
    { key: "HT", text: "Haiti" },
    { key: "HM", text: "Heard Island and McDonald Islands" },
    { key: "HN", text: "Honduras" },
    { key: "HK", text: "Hong Kong" },
    { key: "HU", text: "Hungary" },
    { key: "IS", text: "Iceland" },
    { key: "IN", text: "India" },
    { key: "ID", text: "Indonesia" },
    { key: "IR", text: "Iran, Islamic Republic of" },
    { key: "IQ", text: "Iraq" },
    { key: "IE", text: "Ireland" },
    { key: "IL", text: "Israel" },
    { key: "IT", text: "Italy" },
    { key: "JM", text: "Jamaica" },
    { key: "JP", text: "Japan" },
    { key: "JO", text: "Jordan" },
    { key: "KZ", text: "Kazakstan" },
    { key: "KE", text: "Kenya" },
    { key: "KI", text: "Kiribati" },
    { key: "KP", text: "Korea, Democratic People's Republic of" },
    { key: "KR", text: "Korea, Republic of" },
    { key: "KW", text: "Kuwait" },
    { key: "KG", text: "Kyrgyzstan" },
    { key: "LA", text: "Lao People's Democratic Republic" },
    { key: "LV", text: "Latvia" },
    { key: "LB", text: "Lebanon" },
    { key: "LS", text: "Lesotho" },
    { key: "LR", text: "Liberia" },
    { key: "LY", text: "Libyan Arab Jamahiriya" },
    { key: "LI", text: "Liechtenstein" },
    { key: "LT", text: "Lithuania" },
    { key: "LU", text: "Luxembourg" },
    { key: "MO", text: "Macau" },
    { key: "MK", text: "Macedonia, The Former Yugoslav Republic of" },
    { key: "MG", text: "Madagascar" },
    { key: "MW", text: "Malawi" },
    { key: "MY", text: "Malaysia" },
    { key: "MV", text: "Maldives" },
    { key: "ML", text: "Mali" },
    { key: "MT", text: "Malta" },
    { key: "MH", text: "Marshall Islands" },
    { key: "MQ", text: "Martinique" },
    { key: "MR", text: "Mauritania" },
    { key: "MU", text: "Mauritius" },
    { key: "YT", text: "Mayotte" },
    { key: "MX", text: "Mexico" },
    { key: "FM", text: "Micronesia, Federated States of" },
    { key: "MD", text: "Moldova, Republic of" },
    { key: "MC", text: "Monaco" },
    { key: "MN", text: "Mongolia" },
    { key: "MS", text: "Montserrat" },
    { key: "MA", text: "Morocco" },
    { key: "MZ", text: "Mozambique" },
    { key: "MM", text: "Myanmar" },
    { key: "NA", text: "Namibia" },
    { key: "NR", text: "Nauru" },
    { key: "NP", text: "Nepal" },
    { key: "NL", text: "Netherlands" },
    { key: "AN", text: "Netherlands Antilles" },
    { key: "NC", text: "New Caledonia" },
    { key: "NI", text: "Nicaragua" },
    { key: "NE", text: "Niger" },
    { key: "NG", text: "Nigeria" },
    { key: "NU", text: "Niue" },
    { key: "NF", text: "Norfolk Island" },
    { key: "MP", text: "Northern Mariana Islands" },
    { key: "NO", text: "Norway" },
    { key: "OM", text: "Oman" },
    { key: "PK", text: "Pakistan" },
    { key: "PW", text: "Palau" },
    { key: "PS", text: "Palestinian Territory, Occupied" },
    { key: "PA", text: "Panama" },
    { key: "PG", text: "Papua New Guinea" },
    { key: "PY", text: "Paraguay" },
    { key: "PE", text: "Peru" },
    { key: "PH", text: "Philippines" },
    { key: "PN", text: "Pitcairn" },
    { key: "PL", text: "Poland" },
    { key: "PT", text: "Portugal" },
    { key: "PR", text: "Puerto Rico" },
    { key: "QA", text: "Qatar" },
    { key: "RE", text: "RÚunion" },
    { key: "RO", text: "Romania" },
    { key: "RU", text: "Russian Federation" },
    { key: "RW", text: "Rwanda" },
    { key: "SH", text: "Saint Helena" },
    { key: "KN", text: "Saint Kitts and Nevis" },
    { key: "LC", text: "Saint Lucia" },
    { key: "PM", text: "Saint Pierre and Miquelon" },
    { key: "VC", text: "Saint Vincent and The Grenadines" },
    { key: "WS", text: "Samoa" },
    { key: "SM", text: "San Marino" },
    { key: "ST", text: "Sao Tome and Principe" },
    { key: "SA", text: "Saudi Arabia" },
    { key: "SN", text: "Senegal" },
    { key: "SC", text: "Seychelles" },
    { key: "SL", text: "Sierra Leone" },
    { key: "SG", text: "Singapore" },
    { key: "SK", text: "Slovakia" },
    { key: "SI", text: "Slovenia" },
    { key: "SB", text: "Solomon Islands" },
    { key: "SO", text: "Somalia" },
    { key: "ZA", text: "South Africa" },
    { key: "GS", text: "South Georgia and The South Sandwich Islands" },
    { key: "ES", text: "Spain" },
    { key: "LK", text: "Sri Lanka" },
    { key: "SD", text: "Sudan" },
    { key: "SR", text: "Suriname" },
    { key: "SJ", text: "Svalbard and Jan Mayen" },
    { key: "SZ", text: "Swaziland" },
    { key: "SE", text: "Sweden" },
    { key: "CH", text: "Switzerland" },
    { key: "SY", text: "Syrian Arab Republic" },
    { key: "TW", text: "Taiwan, Province of China" },
    { key: "TJ", text: "Tajikistan" },
    { key: "TZ", text: "Tanzania, United Republic of" },
    { key: "TH", text: "Thailand" },
    { key: "TG", text: "Togo" },
    { key: "TK", text: "Tokelau" },
    { key: "TO", text: "Tonga" },
    { key: "TT", text: "Trinidad and Tobago" },
    { key: "TN", text: "Tunisia" },
    { key: "TR", text: "Turkey" },
    { key: "TM", text: "Turkmenistan" },
    { key: "TC", text: "Turks and Caicos Islands" },
    { key: "TV", text: "Tuvalu" },
    { key: "UG", text: "Uganda" },
    { key: "UA", text: "Ukraine" },
    { key: "AE", text: "United Arab Emirates" },
    { key: "GB", text: "United Kingdom" },
    { key: "US", text: "United States" },
    { key: "UM", text: "United States Minor Outlying Islands" },
    { key: "UY", text: "Uruguay" },
    { key: "UZ", text: "Uzbekistan" },
    { key: "VU", text: "Vanuatu" },
    { key: "VA", text: "Vatican City State" },
    { key: "VE", text: "Venezuela" },
    { key: "VN", text: "Viet Nam" },
    { key: "VG", text: "Virgin Islands, British" },
    { key: "VI", text: "Virgin Islands, U.S." },
    { key: "WF", text: "Wallis and Futuna" },
    { key: "EH", text: "Western Sahara" },
    { key: "YE", text: "Yemen" },
    { key: "YU", text: "Yugoslavia" },
    { key: "ZM", text: "Zambia" },
    { key: "ZW", text: "Zimbabwe" },
    { key: "BL", text: "Saint Barthélemy" },
    { key: "MF", text: "Saint Martin" }]

export const StreetTypes: IDropdownOption[] = [
    {
        key: "ACCS",
        text: "Access"
    },
    {
        key: "ALLY",
        text: "Alley"
    },
    {
        key: "ALWY",
        text: "Alleyway"
    },
    {
        key: "AMBL",
        text: "Amble"
    },
    {
        key: "ANCG",
        text: "Anchorage"
    },
    {
        key: "APP",
        text: "Approach"
    },
    {
        key: "ARC",
        text: "Arcade"
    },
    {
        key: "ARTL",
        text: "Arterial"
    },
    {
        key: "ARTY",
        text: "Artery"
    },
    {
        key: "AV",
        text: "Avenue"
    },
    {
        key: "BA",
        text: "Banan"
    },
    {
        key: "BANK",
        text: "Bank"
    },
    {
        key: "BASN",
        text: "Basin"
    },
    {
        key: "BAY",
        text: "Bay"
    },
    {
        key: "BCH",
        text: "Beach"
    },
    {
        key: "BDGE",
        text: "Bridge"
    },
    {
        key: "BDWY",
        text: "Broadway"
    },
    {
        key: "BEND",
        text: "Bend"
    },
    {
        key: "BLK",
        text: "Block"
    },
    {
        key: "BOWL",
        text: "Bowl"
    },
    {
        key: "BR",
        text: "Brace"
    },
    {
        key: "BRAE",
        text: "Brae"
    },
    {
        key: "BRK",
        text: "Break"
    },
    {
        key: "BROW",
        text: "Brow"
    },
    {
        key: "BVD",
        text: "Boulevard"
    },
    {
        key: "BVDE",
        text: "Boulevarde"
    },
    {
        key: "BWLK",
        text: "Boardwalk"
    },
    {
        key: "BYPA",
        text: "Bypass"
    },
    {
        key: "BYWY",
        text: "Byway"
    },
    {
        key: "CCT",
        text: "Circuit"
    },
    {
        key: "CH",
        text: "Chase"
    },
    {
        key: "CIR",
        text: "Circle"
    },
    {
        key: "CL",
        text: "Close"
    },
    {
        key: "CLDE",
        text: "Colonnade"
    },
    {
        key: "CLST",
        text: "Cluster"
    },
    {
        key: "CLT",
        text: "Circlet"
    },
    {
        key: "CMMN",
        text: "Common"
    },
    {
        key: "CNR",
        text: "Corner"
    },
    {
        key: "CNWY",
        text: "Centreway"
    },
    {
        key: "CON",
        text: "Concourse"
    },
    {
        key: "COVE",
        text: "Cove"
    },
    {
        key: "COWY",
        text: "Crossway"
    },
    {
        key: "CPS",
        text: "Copse"
    },
    {
        key: "CR",
        text: "Crescent"
    },
    {
        key: "CRCS",
        text: "Circus"
    },
    {
        key: "CRD",
        text: "Crossroad"
    },
    {
        key: "CRSE",
        text: "Course"
    },
    {
        key: "CRSG",
        text: "Crossing"
    },
    {
        key: "CRSS",
        text: "Cross"
    },
    {
        key: "CRST",
        text: "Crest"
    },
    {
        key: "CSAC",
        text: "Cul-de-sac"
    },
    {
        key: "CSO",
        text: "Corso"
    },
    {
        key: "CSWY",
        text: "Causeway"
    },
    {
        key: "CT",
        text: "Court"
    },
    {
        key: "CTR",
        text: "Centre"
    },
    {
        key: "CTT",
        text: "Cutting"
    },
    {
        key: "CTYD",
        text: "Courtyard"
    },
    {
        key: "CUWY",
        text: "Cruiseway"
    },
    {
        key: "CX",
        text: "Connection"
    },
    {
        key: "DALE",
        text: "Dale"
    },
    {
        key: "DELL",
        text: "Dell"
    },
    {
        key: "DENE",
        text: "Dene"
    },
    {
        key: "DEVN",
        text: "Deviation"
    },
    {
        key: "DIP",
        text: "Dip"
    },
    {
        key: "DIV",
        text: "Divide"
    },
    {
        key: "DMN",
        text: "Domain"
    },
    {
        key: "DOCK",
        text: "Dock"
    },
    {
        key: "DR",
        text: "Drive"
    },
    {
        key: "DSTR",
        text: "Distributor"
    },
    {
        key: "DVWY",
        text: "Driveway"
    },
    {
        key: "EDGE",
        text: "Edge"
    },
    {
        key: "ELB",
        text: "Elbow"
    },
    {
        key: "END",
        text: "End"
    },
    {
        key: "ENT",
        text: "Entrance"
    },
    {
        key: "ESP",
        text: "Esplanade"
    },
    {
        key: "EST",
        text: "Estate"
    },
    {
        key: "EXP",
        text: "Expressway"
    },
    {
        key: "EXTN",
        text: "Extension"
    },
    {
        key: "FAWY",
        text: "Fairway"
    },
    {
        key: "FB",
        text: "Firebreak"
    },
    {
        key: "FE",
        text: "Fireline"
    },
    {
        key: "FITR",
        text: "Firetrail"
    },
    {
        key: "FLAT",
        text: "Flat"
    },
    {
        key: "FLTS",
        text: "Flats"
    },
    {
        key: "FOLW",
        text: "Follow"
    },
    {
        key: "FORD",
        text: "Ford"
    },
    {
        key: "FORM",
        text: "Formation"
    },
    {
        key: "FRNT",
        text: "Front"
    },
    {
        key: "FRTG",
        text: "Frontage"
    },
    {
        key: "FSHR",
        text: "Foreshore"
    },
    {
        key: "FTRK",
        text: "Firetrack"
    },
    {
        key: "FTWY",
        text: "Footway"
    },
    {
        key: "FWY",
        text: "Freeway"
    },
    {
        key: "GAP",
        text: "Gap"
    },
    {
        key: "GDN",
        text: "Garden"
    },
    {
        key: "GDNS",
        text: "Gardens"
    },
    {
        key: "GLDE",
        text: "Glade"
    },
    {
        key: "GLEN",
        text: "Glen"
    },
    {
        key: "GLY",
        text: "Gully"
    },
    {
        key: "GR",
        text: "Grove"
    },
    {
        key: "GRA",
        text: "Grange"
    },
    {
        key: "GRN",
        text: "Green"
    },
    {
        key: "GRND",
        text: "Ground"
    },
    {
        key: "GTE",
        text: "Gate"
    },
    {
        key: "GTWY",
        text: "Gateway"
    },
    {
        key: "HILL",
        text: "Hill"
    },
    {
        key: "HOLW",
        text: "Hollow"
    },
    {
        key: "HRBR",
        text: "Harbour"
    },
    {
        key: "HTH",
        text: "Heath"
    },
    {
        key: "HTRD",
        text: "Highroad"
    },
    {
        key: "HTS",
        text: "Heights"
    },
    {
        key: "HUB",
        text: "Hub"
    },
    {
        key: "HWY",
        text: "Highway"
    },
    {
        key: "INTG",
        text: "Interchange"
    },
    {
        key: "INTN",
        text: "Intersection"
    },
    {
        key: "ISLD",
        text: "Island"
    },
    {
        key: "JNC",
        text: "Junction"
    },
    {
        key: "KEY",
        text: "Key"
    },
    {
        key: "KEYS",
        text: "Keys"
    },
    {
        key: "LANE",
        text: "Lane"
    },
    {
        key: "LDG",
        text: "Landing"
    },
    {
        key: "LEES",
        text: "Lees"
    },
    {
        key: "LINE",
        text: "Line"
    },
    {
        key: "LINK",
        text: "Link"
    },
    {
        key: "LKT",
        text: "Lookout"
    },
    {
        key: "LNWY",
        text: "Laneway"
    },
    {
        key: "LOOP",
        text: "Loop"
    },
    {
        key: "LT",
        text: "Little"
    },
    {
        key: "LWR",
        text: "Lower"
    },
    {
        key: "MALL",
        text: "Mall"
    },
    {
        key: "MEW",
        text: "Mew"
    },
    {
        key: "MEWS",
        text: "Mews"
    },
    {
        key: "MNDR",
        text: "Meander"
    },
    {
        key: "MNR",
        text: "Manor"
    },
    {
        key: "MT",
        text: "Mount"
    },
    {
        key: "MTWY",
        text: "Motorway"
    },
    {
        key: "NOOK",
        text: "Nook"
    },
    {
        key: "OTLK",
        text: "Outlook"
    },
    {
        key: "OTLT",
        text: "Outlet"
    },
    {
        key: "PARK",
        text: "Park"
    },
    {
        key: "PART",
        text: "Part"
    },
    {
        key: "PASS",
        text: "Pass"
    },
    {
        key: "PATH",
        text: "Path"
    },
    {
        key: "PDE",
        text: "Parade"
    },
    {
        key: "PIAZ",
        text: "Piazza"
    },
    {
        key: "PKLD",
        text: "Parklands"
    },
    {
        key: "PKT",
        text: "Pocket"
    },
    {
        key: "PL",
        text: "Place"
    },
    {
        key: "PLAT",
        text: "Plateau"
    },
    {
        key: "PLZA",
        text: "Plaza"
    },
    {
        key: "PNT",
        text: "Point"
    },
    {
        key: "PORT",
        text: "Port"
    },
    {
        key: "PROM",
        text: "Promenade"
    },
    {
        key: "PRST",
        text: "Pursuit"
    },
    {
        key: "PSGE",
        text: "Passage"
    },
    {
        key: "PWAY",
        text: "Pathway"
    },
    {
        key: "PWY",
        text: "Parkway"
    },
    {
        key: "QDGL",
        text: "Quadrangle"
    },
    {
        key: "QDRT",
        text: "Quadrant"
    },
    {
        key: "QUAD",
        text: "Quad"
    },
    {
        key: "QY",
        text: "Quay"
    },
    {
        key: "QYS",
        text: "Quays"
    },
    {
        key: "RAMP",
        text: "Ramp"
    },
    {
        key: "RCH",
        text: "Reach"
    },
    {
        key: "RD",
        text: "Road"
    },
    {
        key: "RDGE",
        text: "Ridge"
    },
    {
        key: "RDS",
        text: "Roads"
    },
    {
        key: "RDSD",
        text: "Roadside"
    },
    {
        key: "RDWY",
        text: "Roadway"
    },
    {
        key: "RES",
        text: "Reserve"
    },
    {
        key: "REST",
        text: "Rest"
    },
    {
        key: "RGWY",
        text: "Ridgeway"
    },
    {
        key: "RIDE",
        text: "Ride"
    },
    {
        key: "RING",
        text: "Ring"
    },
    {
        key: "RISE",
        text: "Rise"
    },
    {
        key: "RMBL",
        text: "Ramble"
    },
    {
        key: "RND",
        text: "Round"
    },
    {
        key: "RNDE",
        text: "Ronde"
    },
    {
        key: "RNGE",
        text: "Range"
    },
    {
        key: "ROW",
        text: "Row"
    },
    {
        key: "ROWY",
        text: "Right of Way"
    },
    {
        key: "RSBL",
        text: "Rosebowl"
    },
    {
        key: "RSNG",
        text: "Rising"
    },
    {
        key: "RTE",
        text: "Route"
    },
    {
        key: "RTRN",
        text: "Return"
    },
    {
        key: "RTT",
        text: "Retreat"
    },
    {
        key: "RTY",
        text: "Rotary"
    },
    {
        key: "RUE",
        text: "Rue"
    },
    {
        key: "RUN",
        text: "Run"
    },
    {
        key: "RVR",
        text: "River"
    },
    {
        key: "RVRA",
        text: "Riviera"
    },
    {
        key: "RVWY",
        text: "Riverway"
    },
    {
        key: "SBWY",
        text: "Subway"
    },
    {
        key: "SDNG",
        text: "Siding"
    },
    {
        key: "SHUN",
        text: "Shunt"
    },
    {
        key: "SHWY",
        text: "State Highway"
    },
    {
        key: "SLPE",
        text: "Slope"
    },
    {
        key: "SND",
        text: "Sound"
    },
    {
        key: "SPUR",
        text: "Spur"
    },
    {
        key: "SQ",
        text: "Square"
    },
    {
        key: "ST",
        text: "Street"
    },
    {
        key: "STPS",
        text: "Steps"
    },
    {
        key: "STRA",
        text: "Strand"
    },
    {
        key: "STRP",
        text: "Strip"
    },
    {
        key: "STRS",
        text: "Stairs"
    },
    {
        key: "SVWY",
        text: "Service Way"
    },
    {
        key: "TARN",
        text: "Tarn"
    },
    {
        key: "TCE",
        text: "Terrace"
    },
    {
        key: "THFR",
        text: "Thoroughfare"
    },
    {
        key: "TKWY",
        text: "Trunkway"
    },
    {
        key: "TLWY",
        text: "Tollway"
    },
    {
        key: "TOP",
        text: "Top"
    },
    {
        key: "TOR",
        text: "Tor"
    },
    {
        key: "TRI",
        text: "Triangle"
    },
    {
        key: "TRK",
        text: "Track"
    },
    {
        key: "TRL",
        text: "Trail"
    },
    {
        key: "TRLR",
        text: "Trailer"
    },
    {
        key: "TRWY",
        text: "Throughway"
    },
    {
        key: "TURN",
        text: "Turn"
    },
    {
        key: "TWIST",
        text: "Twist"
    },
    {
        key: "TWRS",
        text: "Towers"
    },
    {
        key: "UPAS",
        text: "Underpass"
    },
    {
        key: "UPR",
        text: "Upper"
    },
    {
        key: "VALE",
        text: "Vale"
    },
    {
        key: "VIAD",
        text: "Viaduct"
    },
    {
        key: "VIEW",
        text: "View"
    },
    {
        key: "VLLS",
        text: "Villas"
    },
    {
        key: "VLLY",
        text: "Valley"
    },
    {
        key: "VSTA",
        text: "Vista"
    },
    {
        key: "VWS",
        text: "Views"
    },
    {
        key: "WADE",
        text: "Wade"
    },
    {
        key: "WALK",
        text: "Walk"
    },
    {
        key: "WAY",
        text: "Way"
    },
    {
        key: "WDS",
        text: "Woods"
    },
    {
        key: "WHRF",
        text: "Wharf"
    },
    {
        key: "WKWY",
        text: "Walkway"
    },
    {
        key: "WTRS",
        text: "Waters"
    },
    {
        key: "WTWY",
        text: "Waterway"
    },
    {
        key: "WYND",
        text: "Wynd"
    },
    {
        key: "YARD",
        text: "Yard"
    },
];

export const Jurisdictions: IComboBoxOption[] = [
    {
        key: "ACT",
        text: "ACT"
    },
    {
        key: "NSW",
        text: "NSW"
    },
    {
        key: "NT",
        text: "NT"
    },
    {
        key: "QLD",
        text: "QLD"
    },
    {
        key: "SA",
        text: "SA"
    },
    {
        key: "TAS",
        text: "TAS"
    },
    {
        key: "VIC",
        text: "VIC"
    },
    {
        key: "WA",
        text: "WA"
    }
]

export const NameTitles: IDropdownOption[] = [
    {
        key: "Mr",
        text: "Mr"
    },
    {
        key: "Mrs",
        text: "Mrs"
    },
    {
        key: "Miss",
        text: "Miss"
    },
    {
        key: "Ms",
        text: "Ms"
    },
    {
        key: "Dr",
        text: "Dr"
    },
    {
        key: "Prof",
        text: "Prof"
    }
];

export const TitleInfoTypes: IChoiceGroupOption[] = [
    {
        key: "Reference",
        text: "Reference"
    },
    {
        key: "VolumeFolio",
        text: "Volume/Folio"
    }
];