import React from "react";
import { Separator } from "@fluentui/react";
import { toFormatLocalString, formatToLocaleDateString } from 'containers/calculators/settlement/Helper';
import "../SpecialMeterReadingCalculator.css";
import { IconButton } from "@fluentui/react";
import { apportionmentOption } from "containers/calculators/settlement/common";

type props = {
    waterUsage: { [key: string]: any; };
    edit: () => void;
}

const WaterUsagesCalculation: React.FC<props> = ({ waterUsage, edit }) => {

    return (
        <div className="section">
            <div className="section-body">
                <div className="ms-Grid-row state-row">
                    <div className="ms-Grid-col ms-sm12">
                        <div className="ms-Grid" dir="ltr">
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm1">
                                    <IconButton
                                        className="topaz-blue-icon-button"
                                        data-automation-id="edit_button"
                                        onClick={() => edit()}
                                        allowDisabledFocus={true}
                                        iconProps={{ iconName: "ChevronRightSmall" }}
                                        data-cy={"adjustment_info_"}
                                    />
                                </div>
                                <div className="ms-Grid-col ms-sm6">
                                    <b>Water Usage Calculation</b>
                                    <div>Date of Commencement : {waterUsage && formatToLocaleDateString(waterUsage["paidDate"])}</div>
                                    <div>Date of search reading : {waterUsage && formatToLocaleDateString(waterUsage["searchDate"])}</div>
                                </div>
                                <div className="ms-Grid-col ms-sm3"></div>
                                <div className="ms-Grid-col ms-sm2">
                                    <br></br><br></br>
                                    Reading: {waterUsage && waterUsage["searchReadingAmount"]}kL
                                </div>
                            </div>
                            <Separator></Separator>
                            <div className="ms-Grid-row">

                                <div className="ms-Grid-col ms-sm8 calculationcenter">
                                    <b>Average Daily Usage</b><br />
                                    Days between readings: {waterUsage["numberOfDays"]}<br />
                                    {waterUsage["searchReadingAmount"]}kL-kL = {waterUsage["diffAmountReading"]}kL / {waterUsage["numberOfDays"]} = {waterUsage["dailyUsage"]}kL<br />
                                    Days from date paid to settlement = {waterUsage["partDays"]}<br />
                                    {waterUsage["dailyUsage"]}kL x {waterUsage["partDays"]}days = {waterUsage["dailyAndDays"]}kL<br /><br />
                                    Days from date paid to 30 June = {waterUsage["numberOfDaysToJune"]}<br />

                                    {waterUsage["showJulyCalculation"] === true &&
                                        <>
                                            Days from 1 July to settlement = {waterUsage["numberOfDaysFromJune"]}<br /><br />
                                        </>
                                    }
                                    (All kL results are rounded to whole litres ie 3 decimal places)
                                </div>

                                <div className="ms-Grid-col ms-sm4 calculationcenter">
                                    <b>Charge Per kL</b><br />
                                    ${waterUsage["baseRateCostPerKL"]} for first {waterUsage["baseRateKLCount"]}kL<br />
                                    ${waterUsage["tier2CostPerKL"]} for the next {waterUsage["tier2KLCount"]}kL<br />
                                    ${waterUsage["tier3CostPerKL"]} for the next {waterUsage["tier3KLCount"]}kL<br />
                                    ${waterUsage["balanceWaterCharges"]} for the balance<br />

                                    {waterUsage["showJulyCalculation"] === true &&
                                        <>
                                            <b>Charge per kL from 1 July</b><br />
                                            ${waterUsage["baseRateIncrease"]} for first {waterUsage["baseRateKLCount"]} kL<br />
                                            ${waterUsage["tier2FeeIncrease"]} for next {waterUsage["tier2KLCount"]} kL<br />
                                            ${waterUsage["tier3FeeIncrease"]} for next {waterUsage["tier3KLCount"]} kL<br />
                                            ${waterUsage["balanceWaterChargesFeeIncrease"]} for the balance<br />
                                        </>
                                    }
                                </div>
                            </div>

                            <Separator></Separator>
                            <div className="ms-Grid-row">
                                <b>Water Adjustment</b>
                            </div>
                            {waterUsage["showJulyCalculation"] === true ?
                                <>
                                    <div className="ms-Grid-row state-row-no-padding">
                                        <div className="ms-Grid-col ms-sm6">
                                            <span>Base Rate</span>
                                        </div>
                                        <div className="ms-Grid-col ms-sm4">
                                            {waterUsage['adjustedBaseRateKLCount']}kL x {waterUsage['numberOfDaysToJune']}/{waterUsage['numberOfDaysFromJune']}days x ${waterUsage['baseRateCostPerKL']}
                                        </div>
                                        <div className="ms-Grid-col ms-sm2  content-right-align">
                                            {toFormatLocalString(waterUsage["baseRateResult30June"], "en-AU", 2, 2)}
                                        </div>
                                    </div>

                                    <div className="ms-Grid-row state-row-no-padding">
                                        <div className="ms-Grid-col ms-sm6">
                                            <span>Tier 2</span>
                                        </div>
                                        <div className="ms-Grid-col ms-sm4">
                                            {waterUsage['adjustedTier2KLCount']}kL x {waterUsage['numberOfDaysToJune']}/{waterUsage['numberOfDaysFromJune']} days x ${waterUsage['tier2CostPerKL']}
                                        </div>
                                        <div className="ms-Grid-col ms-sm2  content-right-align">
                                            {toFormatLocalString(waterUsage["tier2Result30June"], "en-AU", 2, 2)}
                                        </div>
                                    </div>

                                    <div className="ms-Grid-row state-row-no-padding">
                                        <div className="ms-Grid-col ms-sm6">
                                            <span>Tier 3</span>
                                        </div>
                                        <div className="ms-Grid-col ms-sm4">
                                            {waterUsage['adjustedTier3KLCount']}kL x {waterUsage['numberOfDaysToJune']}/{waterUsage['numberOfDaysFromJune']} days x ${waterUsage['tier3CostPerKL']}
                                        </div>
                                        <div className="ms-Grid-col ms-sm2  content-right-align">
                                            {toFormatLocalString(waterUsage["tier3Result30June"], "en-AU", 2, 2)}
                                        </div>
                                    </div>

                                    <div className="ms-Grid-row state-row-no-padding">
                                        <div className="ms-Grid-col ms-sm6">
                                            <span>Balance</span>
                                        </div>
                                        <div className="ms-Grid-col ms-sm4">
                                            {waterUsage["balanceCalc"]} x {waterUsage['numberOfDaysToJune']}/{waterUsage['numberOfDaysFromJune']}days x ${waterUsage['balanceWaterCharges']}
                                        </div>
                                        <div className="ms-Grid-col ms-sm2  content-right-align">
                                            {toFormatLocalString(waterUsage["balanceResult30June"], "en-AU", 2, 2)}
                                        </div>
                                    </div>

                                    <div className="ms-Grid-row state-row-no-padding">
                                        <div className="ms-Grid-col ms-sm4">
                                            <b>from 1 July</b>
                                        </div>
                                    </div>

                                    <div className="ms-Grid-row state-row-no-padding">
                                        <div className="ms-Grid-col ms-sm6">
                                            <span>Base Rate</span>
                                        </div>
                                        <div className="ms-Grid-col ms-sm4">
                                            {waterUsage['adjustedBaseRateKLCount']}kL x {waterUsage['numberOfDaysToJuly']}/{waterUsage['numberOfDaysFromJune']}days x ${waterUsage['baseRateIncrease']}
                                        </div>
                                        <div className="ms-Grid-col ms-sm2  content-right-align">
                                            {toFormatLocalString(waterUsage["baseRateResult"], "en-AU", 2, 2)}
                                        </div>
                                    </div>

                                    <div className="ms-Grid-row state-row-no-padding">
                                        <div className="ms-Grid-col ms-sm6">
                                            <span>Tier 2</span>
                                        </div>
                                        <div className="ms-Grid-col ms-sm4">
                                            {waterUsage['adjustedTier2KLCount']}kL x {waterUsage['numberOfDaysToJuly']}/{waterUsage['numberOfDaysFromJune']}days x ${waterUsage['tier2FeeIncrease']}
                                        </div>
                                        <div className="ms-Grid-col ms-sm2  content-right-align">
                                            {toFormatLocalString(waterUsage["tier2Result"], "en-AU", 2, 2)}
                                        </div>
                                    </div>

                                    <div className="ms-Grid-row state-row-no-padding">
                                        <div className="ms-Grid-col ms-sm6">
                                            <span>Tier 3</span>
                                        </div>
                                        <div className="ms-Grid-col ms-sm4">
                                            {waterUsage['adjustedTier3KLCount']}kL x {waterUsage['numberOfDaysToJuly']}/{waterUsage['numberOfDaysFromJune']}days x ${waterUsage['tier3FeeIncrease']}
                                        </div>
                                        <div className="ms-Grid-col ms-sm2  content-right-align">
                                            {toFormatLocalString(waterUsage["tier3Result"], "en-AU", 2, 2)}
                                        </div>
                                    </div>

                                    <div className="ms-Grid-row state-row-no-padding">
                                        <div className="ms-Grid-col ms-sm6">
                                            <span>Balance</span>
                                        </div>
                                        <div className="ms-Grid-col ms-sm4">
                                            {waterUsage['balanceCalc']} x {waterUsage['numberOfDaysToJuly']}/{waterUsage['numberOfDaysFromJune']}days x ${waterUsage["balanceWaterChargesFeeIncrease"]}
                                        </div>
                                        <div className="ms-Grid-col ms-sm2  content-right-align">
                                            {toFormatLocalString(waterUsage["balanceResult"], "en-AU", 2, 2)}
                                        </div>
                                    </div>
                                </>
                                :
                                <>
                                    <div className="ms-Grid-row state-row-no-padding">
                                        <div className="ms-Grid-col ms-sm6">
                                            <span>Base Rate</span>
                                        </div>
                                        <div className="ms-Grid-col ms-sm4">
                                            {waterUsage['adjustedBaseRateKLCount']}kL x ${waterUsage['baseRateCostPerKL']}
                                        </div>
                                        <div className="ms-Grid-col ms-sm2  content-right-align">
                                            {toFormatLocalString(waterUsage["baseRateResult30June"], "en-AU", 2, 2)}
                                        </div>
                                    </div>

                                    <div className="ms-Grid-row state-row-no-padding">
                                        <div className="ms-Grid-col ms-sm6">
                                            <span>Tier 2</span>
                                        </div>
                                        <div className="ms-Grid-col ms-sm4">
                                            {waterUsage['adjustedTier2KLCount']}kL x ${waterUsage['tier2CostPerKL']}
                                        </div>
                                        <div className="ms-Grid-col ms-sm2  content-right-align">
                                            {toFormatLocalString(waterUsage["tier2Result30June"], "en-AU", 2, 2)}
                                        </div>
                                    </div>

                                    <div className="ms-Grid-row state-row-no-padding">
                                        <div className="ms-Grid-col ms-sm6">
                                            <span>Tier 3</span>
                                        </div>
                                        <div className="ms-Grid-col ms-sm4">
                                            {waterUsage['adjustedTier3KLCount']}kL x ${waterUsage['tier3CostPerKL']}
                                        </div>
                                        <div className="ms-Grid-col ms-sm2  content-right-align">
                                            {toFormatLocalString(waterUsage["tier3Result30June"], "en-AU", 2, 2)}
                                        </div>
                                    </div>

                                    <div className="ms-Grid-row state-row-no-padding">
                                        <div className="ms-Grid-col ms-sm6">
                                            <span>Balance</span>
                                        </div>
                                        <div className="ms-Grid-col ms-sm4">
                                            {waterUsage["balanceCalc"]} x ${waterUsage['balanceWaterCharges']}
                                        </div>
                                        <div className="ms-Grid-col ms-sm2  content-right-align">
                                            {toFormatLocalString(waterUsage["balanceResult30June"], "en-AU", 2, 2)}
                                        </div>
                                    </div>
                                </>
                            }

                            <div className="ms-Grid-col ms-sm12 separator">
                                --------------<br />
                                <b>{waterUsage['ctsOption'] === apportionmentOption.entitlement
                                    ? `$${waterUsage["showJulyCalculation"] === true ?
                                        toFormatLocalString(waterUsage['someOfResultIncludingJuly'], "en-AU", 2, 2) :
                                        toFormatLocalString(waterUsage['someOfResultExcludingJuly'], "en-AU", 2, 2)} x ${waterUsage['entitlementValue']}  = $${toFormatLocalString(waterUsage['finalWaterUsageResult'], "en-AU", 2, 2)}`
                                    : waterUsage['ctsOption'] === apportionmentOption.sharedPercentage
                                        ? `$${waterUsage["showJulyCalculation"] === true ?
                                            toFormatLocalString(waterUsage['someOfResultIncludingJuly'], "en-AU", 2, 2) :
                                            toFormatLocalString(waterUsage['someOfResultExcludingJuly'], "en-AU", 2, 2)} x ${waterUsage['percentage']}% = $${toFormatLocalString(waterUsage['finalWaterUsageResult'], "en-AU", 2, 2)}`
                                        : `$${toFormatLocalString(waterUsage['finalWaterUsageResult'], "en-AU", 2, 2)}`
                                }</b><br />
                                --------------
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default WaterUsagesCalculation;