import * as React from 'react';
import { FirstTitlePrice, IOrderFirstTitlePolicyCommandResponse } from 'utils/wcaApiTypes';
import { Stack, MessageBar, MessageBarType } from 'office-ui-fabric-react';
import { DefaultStackTokens } from 'konekta-theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes, faMinus } from '@fortawesome/free-solid-svg-icons';
import PolicyPricing from './policyPricing';
import UnderwritingDisclaimer from './underwritingDisclaimer';

interface IProps {
    sendFirstTitlePolicyRequestResponse: IOrderFirstTitlePolicyCommandResponse
}

interface IState { }

const PENDING_STATUS = "Pending";

class PolicySuccess extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {}
    }

    public renderPricingSection(status: string, firstTitlePrice?: FirstTitlePrice): JSX.Element {
        if (status === PENDING_STATUS) {
            return <UnderwritingDisclaimer />
        } else if (firstTitlePrice) {
            return (
                <PolicyPricing
                    premium={firstTitlePrice.premium}
                    gstOnPremium={firstTitlePrice.gstOnPremium}
                    stampDuty={firstTitlePrice.stampDuty}
                />
            )
        } else {
            return <p>No disbursement information returned</p>
        }
    }

    render() {
        const { sendFirstTitlePolicyRequestResponse } = this.props;

        const firstTitleStatus = sendFirstTitlePolicyRequestResponse.firstTitleStatus ?? '';

        const disbursementErrors = sendFirstTitlePolicyRequestResponse.disbursementCreationErrors && sendFirstTitlePolicyRequestResponse.disbursementCreationErrors.length > 0;
        const documentErrors = sendFirstTitlePolicyRequestResponse.documentUploadErrors && sendFirstTitlePolicyRequestResponse.documentUploadErrors.length > 0;
        const disbursementsCreatedCount = sendFirstTitlePolicyRequestResponse.disbursementsCreated;
        const documentsUploadedCount = sendFirstTitlePolicyRequestResponse.uploadedDocuments ? sendFirstTitlePolicyRequestResponse.uploadedDocuments.length : 0;

        return (
            <Stack tokens={DefaultStackTokens}>
                <h1>Policy order submitted to First Title</h1>
                <h2>Status: {firstTitleStatus}</h2>

                {sendFirstTitlePolicyRequestResponse.policyNumber &&
                    <p>The policy order ID is <strong>{sendFirstTitlePolicyRequestResponse.policyNumber}</strong>.</p>
                }

                {this.renderPricingSection(firstTitleStatus, sendFirstTitlePolicyRequestResponse.price)}

                <ul className="fa-ul extra-space">
                    <li>
                        <div>
                            {disbursementErrors
                                ? <FontAwesomeIcon icon={faTimes} listItem color="red" />
                                : disbursementsCreatedCount > 0
                                    ? <FontAwesomeIcon icon={faCheck} listItem color="green" />
                                    : <FontAwesomeIcon icon={faMinus} listItem color="grey" />
                            }

                            {sendFirstTitlePolicyRequestResponse.disbursementsCreated > 0
                                ? <span>{sendFirstTitlePolicyRequestResponse.disbursementsCreated} disbursement(s) were created</span>
                                : <span>No disbursement(s) were created
                                    {firstTitleStatus === PENDING_STATUS && <MessageBar messageBarType={MessageBarType.info}>
                                        Disbursements will be uploaded to the matter once confirmation has been received back from First Title of the amount.
                                    </MessageBar>}
                                </span>
                            }
                        </div>

                        {disbursementErrors &&
                            <ul>
                                {sendFirstTitlePolicyRequestResponse.disbursementCreationErrors?.map(error =>
                                    <li className="errorMessage">{error}</li>
                                )}
                            </ul>
                        }
                    </li>
                    <li>
                        <div>
                            {documentErrors
                                ? <FontAwesomeIcon icon={faMinus} listItem color="red" />
                                : documentsUploadedCount > 0
                                    ? <FontAwesomeIcon icon={faCheck} listItem color="green" />
                                    : <FontAwesomeIcon icon={faMinus} listItem color="grey" />
                            }

                            {documentsUploadedCount > 0
                                ? <span>The following document{documentsUploadedCount > 1 ? "s were" : " was"} uploaded:</span>
                                : <span>
                                    No documents were uploaded to Actionstep at this time.
                                    <MessageBar messageBarType={MessageBarType.info}>
                                        You will need to manually upload First Title documents to the matter as related documents were not available from First Title at this time.
                                        This might be because the policy requires manual review before it is approved.
                                    </MessageBar>
                                </span>
                            }

                            <ul>
                                {sendFirstTitlePolicyRequestResponse.uploadedDocuments?.map(doc =>
                                    <li><a href={doc.actionstepFileUrl} target="_blank" rel="noopener noreferrer">{doc.fileName}</a></li>
                                )}
                            </ul>
                        </div>

                        {documentErrors &&
                            <ul>
                                {sendFirstTitlePolicyRequestResponse.documentUploadErrors?.map(error =>
                                    <li className="errorMessage">{error}</li>
                                )}
                            </ul>
                        }
                    </li>
                </ul>
            </Stack>
        )
    }
}

export default PolicySuccess;