import {
    UpdateSettlementDateTimeActionstepCommand,
    UpdateSettlementDateAndTimeRequestCommand,
    ActionstepAction,
    FeatureFlag,
    EnvelopeDetailsCustomerViewModel,
    WCAUser
} from "./wcaApiTypes";


export enum SettlementActionType {
    Accept = "Accept",
    Reset_To_Pending = "Reset to pending",
    Propose_New_Date_And_Time = "Propose new date and time"
}
export enum ParticipantSettlementAcceptanceStatusType {
    Accepted = "Accepted",
    Pending = "Pending"
}

export enum SettlementActionTypeReason {
    Default = "Default settlement time is not my preferred Settlement time",
    Reschedule = "Reschedule"
}

export class UpdateSettlementDateTimeData implements IUpdateSettlementDateTimeData {
    currentSettlementDateTimeActionstep: Date | undefined;
    currentSettlementDateTimePexa: Date | undefined;
    newSettlementDateTimeActionstep: Date | undefined;
    newSettlementDateTimePexa: Date | undefined;
    updateSettlementDateAndTimeRequestCommand: UpdateSettlementDateAndTimeRequestCommand | undefined; /*update command for Pexa*/
    updateSettlementDateTimeActionstepCommand: UpdateSettlementDateTimeActionstepCommand | undefined; /*update command for Actionstep*/
    updateForPexa: boolean = false;
    updateForActionstep: boolean = false;

    constructor(data?: IUpdateSettlementDateTimeData) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }
}

export interface IUpdateSettlementDateTimeData {
    currentSettlementDateTimeActionstep: Date | undefined;
    currentSettlementDateTimePexa: Date | undefined;
    newSettlementDateTimeActionstep: Date | undefined;
    newSettlementDateTimePexa: Date | undefined;
    updateSettlementDateAndTimeRequestCommand: UpdateSettlementDateAndTimeRequestCommand | undefined; /*update command for Pexa*/
    updateSettlementDateTimeActionstepCommand: UpdateSettlementDateTimeActionstepCommand | undefined; /*update command for Actionstep*/
    updateForPexa: boolean;
    updateForActionstep: boolean;
}

export class ActionstepMatterInfoState implements IActionstepMatterInfoWithState {
    orgKey?: string | undefined;
    orgName?: string | undefined;
    termsEverAccepted!: boolean;
    latestTermsAccepted!: boolean;
    matterName?: string | undefined;
    matterId!: number;
    state?: string | undefined;
    featureFlags?: FeatureFlag[] | undefined;

    constructor(data?: IActionstepMatterInfoWithState) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.orgKey = _data["orgKey"];
            this.orgName = _data["orgName"];
            this.termsEverAccepted = _data["termsEverAccepted"];
            this.latestTermsAccepted = _data["latestTermsAccepted"];
            this.matterName = _data["matterName"];
            this.matterId = _data["matterId"];
            this.state = _data["state"];
            if (Array.isArray(_data["featureFlags"])) {
                this.featureFlags = [] as any;
                for (let item of _data["featureFlags"])
                    this.featureFlags!.push(item);
            }
        }
    }

    static fromJS(data: any): ActionstepMatterInfoState {
        data = typeof data === 'object' ? data : {};
        let result = new ActionstepMatterInfoState();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["orgKey"] = this.orgKey;
        data["orgName"] = this.orgName;
        data["termsEverAccepted"] = this.termsEverAccepted;
        data["latestTermsAccepted"] = this.latestTermsAccepted;
        data["matterName"] = this.matterName;
        data["matterId"] = this.matterId;
        data["state"] = this.state;
        if (Array.isArray(this.featureFlags)) {
            data["featureFlags"] = [];
            for (let item of this.featureFlags)
                data["featureFlags"].push(item);
        }
        return data;
    }
}

export interface IActionstepMatterInfoWithState {
    orgKey?: string | undefined;
    orgName?: string | undefined;
    termsEverAccepted: boolean;
    latestTermsAccepted: boolean;
    matterName?: string | undefined;
    matterId: number;
    state?: string | undefined;
    featureFlags?: FeatureFlag[] | undefined;
}

export interface IOrgKeysAndActionParams {
    action: ActionstepAction | undefined;
    srcOrgKey: string | undefined;
    destOrgKey: string | undefined;
}

export class OrgKeysAndActionParams implements IOrgKeysAndActionParams {
    action: ActionstepAction | undefined;
    srcOrgKey: string | undefined;
    destOrgKey: string | undefined;

    constructor(data?: IOrgKeysAndActionParams) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.action = _data["action"] ? ActionstepAction.fromJS(_data["action"]) : <any>undefined;
            this.srcOrgKey = _data["srcOrgKey"];
            this.destOrgKey = _data["destOrgKey"];
        }
    }

    static fromJS(data: any): OrgKeysAndActionParams {
        data = typeof data === 'object' ? data : {};
        let result = new OrgKeysAndActionParams();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["action"] = this.action ? this.action.toJSON() : <any>undefined;
        data["srcOrgKey"] = this.srcOrgKey;
        data["destOrgKey"] = this.destOrgKey;
        return data;
    }
}

export class PexaWorkspaceIdAndRole implements IPexaWorkspaceIdAndRole {
    workspaceRole: string | undefined;
    workspaceId: string | undefined;

    constructor(data?: IUpdateSettlementDateTimeData) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }
}

export interface IPexaWorkspaceIdAndRole {
    workspaceRole: string | undefined;
    workspaceId: string | undefined;
}

export class SubscriberIdAndName implements ISubscriberIdAndName {
    subscriberId: string | undefined;
    subscriberName: string | undefined;

    constructor(data?: ISubscriberIdAndName) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }
}

export interface ISubscriberIdAndName {
    subscriberId: string | undefined;
    subscriberName: string | undefined;
}


export class SearchAndFilterMatters implements ISearchAndFilterMatters {
    searchText: string | undefined;
    filters: string[][] | undefined;
    pageNumber: number | undefined
    recordCount: number | undefined

    constructor(data?: IUpdateSettlementDateTimeData) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }
}
export interface ISearchAndFilterMatters {
    searchText: string | undefined;
    filters: string[][] | undefined;
    pageNumber: number | undefined;
    recordCount: number | undefined
}

export class CreatePexaKeyInviteCommandParams implements ICreatePexaKeyInviteCommandParams {
    workspaceId: string | undefined;
    workspaceRole: string | undefined;
    actionstepOrg?: string | undefined;
    matterId!: number;
    partyId: string | undefined;
    partyType: string | undefined;
    partyName: string | undefined;
    pexaKeyConsent: string | undefined;
    email: string | undefined;
    mobileNumber: string | undefined;
    contactGivenName: string | undefined;
    contactFamilyName: string | undefined;

    constructor(data?: ICreatePexaKeyInviteCommandParams) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }
}

export interface ICreatePexaKeyInviteCommandParams {
    workspaceId: string | undefined;
    workspaceRole: string | undefined;
    actionstepOrg?: string | undefined;
    matterId: number;
    partyId: string | undefined;
    partyType: string | undefined;
    partyName: string | undefined;
    pexaKeyConsent: string | undefined;
    email: string | undefined;
    mobileNumber: string | undefined;
    contactGivenName: string | undefined;
    contactFamilyName: string | undefined;
}

export class CreatePexaKeyInviteResponse implements ICreatePexaKeyInviteResponse {
    status: string | undefined;
    message: string | undefined;


    constructor(data?: ICreatePexaKeyInviteResponse) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.status = _data["status"];
            this.message = _data["message"];
        }
    }

    static fromJS(data: any): CreatePexaKeyInviteResponse {
        data = typeof data === 'object' ? data : {};
        let result = new CreatePexaKeyInviteResponse();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["status"] = this.status;
        data["message"] = this.message;

        return data;
    }
}
export interface ICreatePexaKeyInviteResponse {
    status: string | undefined;
    message: string | undefined;
}


export class PexaKeyWorkspacePartyIdAndRole implements IPexaKeyWorkspacePartyIdAndRole {
    workspaceRole: string | undefined;
    workspaceId: string | undefined;
    partyId: string | undefined;
}

export interface IPexaKeyWorkspacePartyIdAndRole {
    workspaceRole: string | undefined;
    workspaceId: string | undefined;
    partyId: string | undefined;
}

export class CopyDocumentsToActionStepResponse implements ICopyDocumentsToActionStepResponse {
    status: string | undefined;
    message: string | undefined;

    constructor(data?: ICopyDocumentsToActionStepResponse) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.status = _data["status"];
            this.message = _data["message"];
        }
    }

    static fromJS(data: any): CopyDocumentsToActionStepResponse {
        data = typeof data === 'object' ? data : {};
        let result = new CopyDocumentsToActionStepResponse();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["status"] = this.status;
        data["message"] = this.message;

        return data;
    }
}
export interface ICopyDocumentsToActionStepResponse {
    status: string | undefined;
    message: string | undefined;
}

export class SavePexaAuditParams implements ISavePexaAudit {
    actionstepOrg: string | undefined;
    matterId: number | undefined;
    pexaWorkspaceId: string | undefined;
    pexaWorkspaceStatus: string | undefined;
    CommandName: string | undefined;


    constructor(data?: ISavePexaAudit) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }
}
export interface ISavePexaAudit {
    actionstepOrg: string | undefined,
    matterId: number | undefined,
    pexaWorkspaceId: string | undefined,
    pexaWorkspaceStatus: string | undefined,
    CommandName: string | undefined
}

export class ECAFEditEnvelopeCustomerViewModel implements IECAFEditEnvelopeCustomerViewModel {

    envelopeId?: string | undefined;
    orgKey?: string | undefined;
    customerId?: string | undefined;
    customerFirstName?: string | undefined;
    customerLastName?: string | undefined;
    customerEmailAddress?: string | undefined;
    customerCountryCode?: string | undefined;
    customerMobileNumber?: string | undefined;

    constructor(data?: IECAFEditEnvelopeCustomerViewModel) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {

            this.envelopeId = _data["envelopeId"];
            this.orgKey = _data["orgKey"];
            this.customerId = _data["customerId"];
            this.customerFirstName = _data["customerFirstName"];
            this.customerLastName = _data["customerLastName"];
            this.customerEmailAddress = _data["customerEmailAddress"];
            this.customerCountryCode = _data["customerCountryCode"];
            this.customerMobileNumber = _data["customerMobileNumber"];
        }
    }

    static fromJS(data: any): ECAFEditEnvelopeCustomerViewModel {
        data = typeof data === 'object' ? data : {};
        let result = new ECAFEditEnvelopeCustomerViewModel();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};

        data["envelopeId"] = this.envelopeId;
        data["orgKey"] = this.orgKey;
        data["customerId"] = this.customerId;
        data["customerFirstName"] = this.customerFirstName;
        data["customerLastName"] = this.customerLastName;
        data["customerEmailAddress"] = this.customerEmailAddress;
        data["customerCountryCode"] = this.customerCountryCode;
        data["customerMobileNumber"] = this.customerMobileNumber;
        return data;
    }
}

export interface IECAFEditEnvelopeCustomerViewModel {
    envelopeId?: string | undefined;
    orgKey?: string | undefined;
    customerId?: string | undefined;
    customerFirstName?: string | undefined;
    customerLastName?: string | undefined;
    customerEmailAddress?: string | undefined;
    customerCountryCode?: string | undefined;
    customerMobileNumber?: string | undefined;
}

export class ECAFCustomerEditModel implements IECAFCustomerEditModel {
    newData: ECAFEditEnvelopeCustomerViewModel | undefined;
    oldData: EnvelopeDetailsCustomerViewModel | undefined

    constructor(data?: IECAFCustomerEditModel) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }
}

export interface IECAFCustomerEditModel {
    newData: ECAFEditEnvelopeCustomerViewModel | undefined;
    oldData: EnvelopeDetailsCustomerViewModel | undefined;
}

export class CountryPhoneCodeViewModel implements ICountryPhoneCodeViewModel {
    id: number | undefined;
    name: string | undefined;
    countryCode: string | undefined;
    phoneCode: string | undefined;

    constructor(data?: ICopyDocumentsToActionStepResponse) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }


    init(_data?: any) {
        if (_data) {
            this.id = _data["id"];
            this.name = _data["name"];
            this.countryCode = _data["countryCode"];
            this.phoneCode = _data["phoneCode"];
        }
    }

    static fromJS(data: any): CopyDocumentsToActionStepResponse {
        data = typeof data === 'object' ? data : {};
        let result = new CopyDocumentsToActionStepResponse();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["name"] = this.name;
        data["countryCode"] = this.countryCode;
        data["phoneCode"] = this.phoneCode;


        return data;
    }
}
export interface ICountryPhoneCodeViewModel {
    id: number | undefined;
    name: string | undefined;
    countryCode: string | undefined;
    phoneCode: string | undefined;
}

export class DealingNumberViewModel implements IDealingNumberViewModel {
    dealingNumber: string | undefined;
    landTitleReference: string | undefined;
    dealingNumberDate: string | undefined;

    constructor(data?: IDealingNumberViewModel) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }



    init(_data?: any) {
        if (_data) {
            this.dealingNumber = _data["dealingNumber"];
            this.landTitleReference = _data["landTitleReference"];
            this.dealingNumberDate = _data["dealingNumberDate"];
        }
    }

    static fromJS(data: any): DealingNumberViewModel {
        data = typeof data === 'object' ? data : {};
        let result = new DealingNumberViewModel();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["dealingNumber"] = this.dealingNumber;
        data["landTitleReference"] = this.landTitleReference;
        data["dealingNumberDate"] = this.dealingNumberDate;
        return data;
    }
}
export interface IDealingNumberViewModel {
    dealingNumber: string | undefined;
    landTitleReference: string | undefined;
    dealingNumberDate: string | undefined;
}

export class UpdateDealingNumberActionstepCommand implements IUpdateDealingNumberActionstepCommand {
    actionstepOrg?: string | undefined;
    matterId!: number;
    authenticatedUser?: WCAUser | undefined;
    landTitleReferenceDealingNumber: string[] | undefined;

    constructor(data?: IUpdateDealingNumberActionstepCommand) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }


    init(_data?: any) {
        if (_data) {
            this.actionstepOrg = _data["actionstepOrg"];
            this.matterId = _data["matterId"];
            this.authenticatedUser = _data["authenticatedUser"] ? WCAUser.fromJS(_data["authenticatedUser"]) : <any>undefined;

            if (Array.isArray(_data["landTitleReferenceDealingNumber"])) {
                this.landTitleReferenceDealingNumber = [] as any;
                for (let item of _data["landTitleReferenceDealingNumber"])
                    this.landTitleReferenceDealingNumber!.push(item);
            }

        }
    }

    static fromJS(data: any): UpdateDealingNumberActionstepCommand {
        data = typeof data === 'object' ? data : {};
        let result = new UpdateDealingNumberActionstepCommand();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["actionstepOrg"] = this.actionstepOrg;
        data["matterId"] = this.matterId;
        data["authenticatedUser"] = this.authenticatedUser ? this.authenticatedUser.toJSON() : <any>undefined;
        if (Array.isArray(this.landTitleReferenceDealingNumber)) {
            data["landTitleReferenceDealingNumber"] = [];
            for (let item of this.landTitleReferenceDealingNumber)
                data["landTitleReferenceDealingNumber"].push(item);
        }

        return data;
    }
}

export interface IUpdateDealingNumberActionstepCommand {
    actionstepOrg?: string | undefined;
    matterId: number;
    authenticatedUser?: WCAUser | undefined;
    landTitleReferenceDealingNumber?: string[];
}

export class ECAFReportResponseEntry implements ECAFReportResponseEntry {
    matterId?: number | undefined;
    envelopeId?: string | undefined;
    orderDescription?: string | undefined;
    disbursementAmount?: number | undefined;
    customers: EnvelopeDetailsCustomerViewModel[] | undefined;

    constructor(data?: IECAFReportResponseEntry) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.matterId = _data["matterId"];
            this.envelopeId = _data["envelopeId"];
            this.orderDescription = _data["orderDescription"];
            this.disbursementAmount = _data["disbursementAmmount"];

            if (Array.isArray(_data["customers"])) {
                this.customers = [] as any;
                for (let item of _data["customers"])
                    this.customers!.push(EnvelopeDetailsCustomerViewModel.fromJS(item));
            }
        }
    }

    static fromJS(data: any): ECAFReportResponseEntry {
        data = typeof data === 'object' ? data : {};
        let result = new ECAFReportResponseEntry();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["matterId"] = this.matterId;
        data["envelopeId"] = this.envelopeId;
        data["orderDescription"] = this.orderDescription;
        data["disbursementAmmount"] = this.disbursementAmount;
        if (Array.isArray(this.customers)) {
            data["customers"] = [];
            for (let item of this.customers)
                data["customers"].push(item.toJSON());
        }

        return data;
    }
}

export interface IECAFReportResponseEntry {
    matterId?: number | undefined;
    envelopeId?: string | undefined;
    orderDescription?: string | undefined;
    disbursementAmmount?: number | undefined;
    customers: EnvelopeDetailsCustomerViewModel[] | undefined;
}

export class ECAFReportResponse implements IECAFReportResponse {
    reportEntries!: ECAFReportResponseEntry[];

    constructor(data?: IECAFReportResponse) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            if (Array.isArray(_data["reportEntries"])) {
                this.reportEntries = [] as any;
                for (let item of _data["reportEntries"])
                    this.reportEntries!.push(ECAFReportResponseEntry.fromJS(item));
            }
        }
    }

    static fromJS(data: any): ECAFReportResponse {
        data = typeof data === 'object' ? data : {};
        let result = new ECAFReportResponse();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        if (Array.isArray(this.reportEntries)) {
            data["reportEntries"] = [];
            for (let item of this.reportEntries)
                data["reportEntries"].push(item.toJSON());
        }
        return data;
    }
}

export interface IECAFReportResponse {
    reportEntries: ECAFReportResponseEntry[];
}

export class ECAFReportQuery implements IECAFReportQuery {
    orgKey!: string;
    dateStartUtc!: Date;
    dateEndUtc!: Date;

    constructor(data?: IECAFReportQuery) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.orgKey = _data["orgKey"];
            this.dateStartUtc = _data["dateStartUtc"] ? new Date(_data["dateStartUtc"].toString()) : <any>undefined;
            this.dateEndUtc = _data["dateEndUtc"] ? new Date(_data["dateEndUtc"].toString()) : <any>undefined;
        }
    }

    static fromJS(data: any): ECAFReportQuery {
        data = typeof data === 'object' ? data : {};
        let result = new ECAFReportQuery();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["orgKey"] = this.orgKey;
        data["dateStartUtc"] = this.dateStartUtc ? this.dateStartUtc.toISOString() : <any>undefined;
        data["dateEndUtc"] = this.dateEndUtc ? this.dateEndUtc.toISOString() : <any>undefined;
        return data;
    }
}

export interface IECAFReportQuery {
    orgKey: string;
    dateStartUtc: Date;
    dateEndUtc: Date;
}





