import { IDropdownOption } from "@fluentui/react";
import { PropertyState, propertyTypeForWAAndVICStateOptions, propertyTypeForOtherStateOptions } from "./components/constants";

export const getPropertyType = (propertyState: string): IDropdownOption[] => {
    if (propertyState === PropertyState.WA || propertyState === PropertyState.VIC) {
        return propertyTypeForWAAndVICStateOptions;
    }
    else {
        return propertyTypeForOtherStateOptions;
    }
}