import axios from 'axios'

import * as ACTIONS from 'redux/common/actions'
import { store } from 'redux/store'

const AxiosInstance = axios.create({
    baseURL: "",
})

AxiosInstance.interceptors.response.use((response) => {

    // TODO: Should these be here?
    store.dispatch(ACTIONS.pexaConnected());
    store.dispatch(ACTIONS.firstTitleConnected());

    return response;
}, (error) => {
    if (error?.response?.status === 401) {
        const authenticateHeaderValue = error.response.headers["www-authenticate"];
        if (authenticateHeaderValue && authenticateHeaderValue.startsWith("ActionstepConnection OrgKey=")) {
            store.dispatch(ACTIONS.actionstepUnauthorizedResponse());
        } else if (authenticateHeaderValue && authenticateHeaderValue.startsWith("PexaConnection")) {
            store.dispatch(ACTIONS.pexaNotConnected());
        } else if (authenticateHeaderValue && authenticateHeaderValue.startsWith("FirstTitleConnection")) {
            const warningHeaderValue = error.response.headers["warning"];
            if (warningHeaderValue && warningHeaderValue === "MissingCredentials") {
                store.dispatch(ACTIONS.firstTitleNotConnected(true));
            } else {
                store.dispatch(ACTIONS.firstTitleNotConnected(false));
            }
        }
    }

    return Promise.reject(error);
})

export default AxiosInstance