import * as CONSTANTS from './constants'
import {
    ActionstepMatterInfo,
    SecurexchangeWorkspaceInfo,
    ErrorViewModel
} from "../../../utils/wcaApiTypes"
import {
    GetSecurexchangeWorkspaceInformationFromKonekta,
    GetSecurexchangeWorkspaceInformationFromKonektaFailed,
    GetSecurexchangeWorkspaceInformationFromKonektaSuccess
} from './actionTypes'



export function getSecurexchangeWorkspaceInformationFromKonekta(matterInfo: ActionstepMatterInfo): GetSecurexchangeWorkspaceInformationFromKonekta {
    return {
        type: CONSTANTS.GET_SECUREXCHANGE_WORKSPACE_INFORMATION_FROM_KONEKTA_REQUESTED,
        matterInfo
    }
}

export function getSecurexchangeWorkspaceInformationFromKonektaSuccess(data: SecurexchangeWorkspaceInfo): GetSecurexchangeWorkspaceInformationFromKonektaSuccess {
    return {
        type: CONSTANTS.GET_SECUREXCHANGE_WORKSPACE_INFORMATION_FROM_KONEKTA_SUCCESS,
        data
    }
}

export function getSecurexchangeWorkspaceInformationFromKonektaFailed(error: ErrorViewModel): GetSecurexchangeWorkspaceInformationFromKonektaFailed {
    return {
        type: CONSTANTS.GET_SECUREXCHANGE_WORKSPACE_INFORMATION_FROM_KONEKTA_FAILED,
        error
    }
}