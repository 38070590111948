import { ErrorViewModel } from 'utils/wcaApiTypes'
import { GeneratePDFLegalAidCommand, LegalAidActionstepDataViewModel, ActionstepProviderList } from '../utils/LegalAidWCAApiTypes'
import * as CONSTANTS from './constants'
import {
    ActionFailed, GenerateLegalAidPDF, GenerateLegalAidPDFSuccess, GetActionstepProviderDetails,
    GetLegalAidMatterDetailsFromActionstep, GetLegalAidMatterDetailsFromActionstepSuccess, GetActionstepProviderDetailsSuccess
} from './actionTypes'


export function getActionstepProviderDetails(matterId: number, orgKey: string): GetActionstepProviderDetails {
    return {
        type: CONSTANTS.GET_PROVIDER_DETAILS_REQUESTED,
        matterId,
        orgKey
    }
}
export function getActionstepProviderDetailsSuccess(data: ActionstepProviderList): GetActionstepProviderDetailsSuccess {
    return {
        type: CONSTANTS.GET_PROVIDER_DETAILS_SUCCESS,
        data
    }
}
export function getActionstepProviderDetailsFailed(error: ErrorViewModel): ActionFailed {
    return {
        type: CONSTANTS.GET_PROVIDER_DETAILS_FAILED,
        error
    }
}

export function getMatterDetailsFromActionstep(matterId: number, orgKey: string, dateFrom: string, dateTo: string, leadProvider: string, provider2: string, otherProvider: string, formType: string): GetLegalAidMatterDetailsFromActionstep {
    return {
        type: CONSTANTS.GET_MATTER_DETAILS_FROM_ACTIONSTEP_REQUESTED,
        matterId,
        orgKey,
        dateFrom,
        dateTo,
        leadProvider,
        provider2,
        otherProvider,
        formType
    }
}

export function getMatterDetailsFromActionstepSuccess(data: LegalAidActionstepDataViewModel): GetLegalAidMatterDetailsFromActionstepSuccess {
    return {
        type: CONSTANTS.GET_MATTER_DETAILS_FROM_ACTIONSTEP_SUCCESS,
        data
    }
}

export function getMatterDetailsFromActionstepFailed(error: ErrorViewModel): ActionFailed {
    return {
        type: CONSTANTS.GET_MATTER_DETAILS_FROM_ACTIONSTEP_FAILED,
        error
    }
}

export function generateLegalAidPDF(data: GeneratePDFLegalAidCommand): GenerateLegalAidPDF {
    return {
        type: CONSTANTS.GENERATE_LEGALAID_PDF_REQUESTED,
        data

    }
}

export function generateLegalAidPDFSuccess(data: boolean): GenerateLegalAidPDFSuccess {
    return {
        type: CONSTANTS.GENERATE_LEGALAID_PDF_SUCCESS,
        data
    }
}

export function generateLegalAidPDFFailed(error: ErrorViewModel): ActionFailed {
    return {
        type: CONSTANTS.GENERATE_LEGALAID_PDF_FAILED,
        error
    }
}

