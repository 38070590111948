import * as React from 'react'

// Office Facric imports
import { Dialog, DialogFooter, PrimaryButton, DefaultButton } from 'office-ui-fabric-react';

// FluentUI imports
import { ITextFieldStyles, TextField } from '@fluentui/react/lib/TextField';
import { MessageBar, MessageBarType } from 'office-ui-fabric-react';
import { Dropdown, IDropdownStyles, IDropdownOption } from '@fluentui/react/lib/Dropdown';
import { Label } from '@fluentui/react/lib/Label';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';

// Stylesheet import
import './style.css';
import { getRequest, postRequest } from '../../../../utils/request';
import Tools from '../../../../utils/tools';

interface IAppProps {
    hideDialog: boolean;
    cancelOnClickHandler: () => void;
    actionstepOrg: string;
    matterId: string;
}

// Type declaration
type AppProps = IAppProps;

type AppStates = {
    workspaceID: string;
    pexaRole: string;
    status: string;
    errorText: string;
}

// Dropdown options
const options: IDropdownOption[] = [
    { key: 'Incoming Proprietor', text: 'Incoming Proprietor' },
    { key: 'Proprietor on Title', text: 'Proprietor on Title' },
    { key: 'Mortgagee on Title', text: 'Mortgagee on Title' },
    { key: 'Applicant', text: 'Applicant' },
    { key: 'Caveator on Title', text: 'Caveator on Title' },
    { key: 'Consentor', text: 'Consentor' },
    { key: 'CT Controller', text: 'CT Controller' },
    { key: 'Incoming Caveator', text: 'Incoming Caveator' },
    { key: 'Incoming Mortgagee', text: 'Incoming Mortgagee' },
];

// Styles
const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: { width: 232 }
};

const textfieldStyles: Partial<ITextFieldStyles> = {
    field: { width: 230 }
};

const spinnerStyles: object = {
    marginRight: "5px"
};

// Constants
const UPDATING_TEXT = "Matter is updating, please wait";
const COMPLETE_TEST = "Matter is updated, browser is refreshing";

export class UpdateMatterWithWorkspaceComponent extends React.Component<AppProps, AppStates> {
    constructor(props: AppProps) {
        super(props);

        this.state = {
            workspaceID: "",
            pexaRole: "Incoming Proprietor",
            status: "",
            errorText: ""
        }
    }

    public renderStatus() {
        switch (this.state.status) {
            case "updating":
                return (
                    <div className="status-container">
                        <Spinner size={SpinnerSize.xSmall} style={spinnerStyles} />
                        <span>{UPDATING_TEXT}</span>
                    </div>
                );
            case "complete":
                return (
                    <div className="status-container">
                        <span>{COMPLETE_TEST}</span>
                    </div>
                );
            case "error":
                return (
                    this.state.errorText.includes("MapWorkspaceError") ?
                        <MessageBar messageBarType={MessageBarType.success}>
                            {this.state.errorText.replace("MapWorkspaceError-", "")}
                        </MessageBar>
                        :
                        <div className="status-container">
                            <span style={{ color: 'red' }}>{this.state.errorText}</span>
                        </div>
                );
            
            default: return null;
        }
    }

    public refreshIFrame() {
        var href = document.location.href;
        window.location.reload();
        console.log("HREF = ", href);



        //if (href.charAt(href.length - 1) == "#") href = href.substr(0, href.length - 1);

        //if (href != null) document.location.href = href;
    }

    public async updateMatterWithPEXAWorkspaceID() {
        this.setState({
            status: "updating"
        });

        var pexaStatusFromBackend = (await getRequest(`/api/conveyancing/check-pexa-is-connected`)) as any;

        var url = `/api/pexa/update-matter-with-pexaworkspace/${this.props.actionstepOrg}/${this.props.matterId}/${this.state.workspaceID}/${this.state.pexaRole}`;

        if (!pexaStatusFromBackend) {
            Tools.PopupConnectToPexa(async () => {
                await postRequest(url).then(res => {
                    this.refreshIFrame();
                    this.setState({
                        status: "complete"
                    })
                }).catch(error => {
                    this.setState({
                        status: "error",
                        errorText: error?.response?.data?.message
                    });
                });
            })
        } else {
            await postRequest(url).then(res => {
                this.refreshIFrame();
                this.setState({
                    status: "complete"
                })
            }).catch(error => {
                this.setState({
                    status: "error",
                    errorText: error?.response?.data?.message
                });
            });
        }
    }

    render(): JSX.Element {
        return (
            <Dialog
                hidden={this.props.hideDialog}
                onDismiss={() => { }}
                minWidth={400}
                maxWidth={500}
                dialogContentProps={{
                    title: 'Update Matter with PEXA Workspace',
                    subText: ""
                }}
            >
                <div className="status-text-container">
                    { this.renderStatus() }
                </div>

                <div className="update-workspace-element-container">
                    <Label htmlFor={"workspace-id-textfield"}>PEXA Workspace ID</Label>
                    <TextField
                        id={"workspace-id-textfield"}
                        styles={textfieldStyles}
                        onChange={(el, val) => {
                            if (val) {
                                this.setState({
                                    workspaceID: val
                                })
                            }
                        }}
                    />
                </div>

                <div className="update-workspace-element-container">
                    <Label htmlFor={"workspace-role-dropdown"}>PEXA Role</Label>
                    <Dropdown
                        id={"workspace-role-dropdown"}
                        label=""
                        defaultSelectedKey="Incoming Proprietor"
                        onChange={(elem, option) => {
                            if (option) {
                                this.setState({
                                    pexaRole: option.key + ""
                                })
                            }
                        }}
                        options={options}
                        disabled={false}
                        styles={dropdownStyles}
                    />
                </div>

                <DialogFooter>
                    <PrimaryButton
                        disabled ={!this.state.workspaceID ||  !this.state.pexaRole }
                        onClick={() => {
                        this.updateMatterWithPEXAWorkspaceID();
                    }} text="Update" />
                    <DefaultButton onClick={() => {
                        this.props.cancelOnClickHandler();
                    }} text="Close" />
                </DialogFooter>
            </Dialog>
        )
    }
}