import * as React from 'react';
import { IconButton } from '@fluentui/react';
import { ModalIDs } from 'containers/calculators/settlement/common';
import SettlementInfo from './settlementInfo';
import { toFormatLocalString } from './Helper';
import './settlementCalculator.css';

interface IMapStateToProps { }
interface IMapDispatchToProps { }

interface IProps {
    toggleSection: (payee: string) => void;
    showSourceOfFund: any;
    showModal: (modalID: ModalIDs, additionalInfo: any) => void;
    unallocatedFund: any;
    isVendor: boolean;
    additionalInfo?: { [key: string]: any; } | undefined;
    conveyType: string | undefined;
    payeeCheckboxHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
    sourceOfFundTotal: any;
    sourceOfFunds?: { [key: string]: any; }[] | undefined;
}

type AppProps = IMapStateToProps & IProps & IMapDispatchToProps;
type AppStates = {}

export default class SourceOfFundsInfo extends React.Component<AppProps, AppStates> {
    render(): JSX.Element {
        const { showSourceOfFund, unallocatedFund: unallocated, isVendor, additionalInfo, conveyType, sourceOfFundTotal, sourceOfFunds } = this.props;

        return (
            <div className="section">
                <div className="right-align-section-header">
                    Show/Hide Source Of Funds
                    <IconButton
                        className="button show-hide-button"
                        data-automation-id="save_button"
                        onClick={() => this.props.toggleSection("SourceOfFunds")}
                        allowDisabledFocus={true}
                        iconProps={{
                            iconName: showSourceOfFund
                                ? "CaretDownSolid8"
                                : "CaretUpSolid8",
                            style: { fontSize: 10 },
                        }} />
                </div>
                {showSourceOfFund && (
                    <>
                        <div className="ms-Grid-row state-row">
                            <div className="ms-Grid-col ms-sm4">
                                <b className="header-text">SOURCE OF FUNDS</b>
                            </div>
                        </div>

                        <div className="section-body">

                            {sourceOfFunds &&
                                sourceOfFunds.map((fund, index) => {
                                    return (
                                        <SettlementInfo
                                            isVendor={isVendor}
                                            key={"sourceOfFund_" + index}
                                            item={fund}
                                            index={index}
                                            additionalInfo={additionalInfo}
                                            showModal={(modalID, additionalInfo) =>
                                                this.props.showModal(modalID, additionalInfo)
                                            }
                                            payeeCheckboxHandler={(event) =>
                                                this.props.payeeCheckboxHandler(event)
                                            }
                                            additionalRequirementCheckboxHandler={() => { }}
                                            draggable={false}
                                            conveyType={conveyType}
                                        />
                                    );
                                })}

                            <div className="ms-Grid-col ms-sm12 separator">
                                --------------
                            </div>

                            <div className="ms-Grid-row state-row">
                                <div className="ms-Grid-col ms-sm2">
                                    <IconButton
                                        data-cy="add_sourceOfFunds"
                                        className="button rounded-blue-icon-button"
                                        data-automation-id="save_button"
                                        onClick={() =>
                                            this.props.showModal(ModalIDs.sourceOfFunds, { index: -1 })
                                        }
                                        allowDisabledFocus={true}
                                        iconProps={{
                                            iconName: "Add",
                                            style: {
                                                fontSize: 15,
                                                fontWeight: 900,
                                            },
                                        }}
                                    />
                                </div>
                                <div className="ms-Grid-col ms-sm10">
                                    <div className="ms-Grid" dir="ltr">
                                        <div className="ms-Grid-row detail-row">
                                            <div className="ms-Grid-col ms-sm5 add-label">
                                                Click + to Add Source of Funds
                                            </div>
                                            <div className="ms-Grid-col ms-sm3">
                                                <b>TOTAL</b>
                                            </div>

                                            <div className="ms-Grid-col ms-sm2 price-info ms-smPush2">
                                                <b data-cy="contract_debit_value">
                                                    ${toFormatLocalString(sourceOfFundTotal, "en-AU", 2, 2)}
                                                </b>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="ms-Grid-row state-row">
                                <div className='ms-Grid-col ms-sm12 right-align'>
                                    <label className={unallocated < 0 ? "red" : "green"}>(unallocated: ${toFormatLocalString(unallocated, "en-AU", 2, 2)})
                                    </label>
                                </div>
                            </div>
                        </div>
                    </>)}
            </div>
        )
    }
}