import * as React from 'react';
import { connect } from 'react-redux';
import { Dropdown, IDropdownOption } from 'office-ui-fabric-react/lib/Dropdown';
import { AppState, ReduxStatus } from '../../../../app.types';
import { DefaultButton, Stack } from 'office-ui-fabric-react';
import { DatePicker } from 'office-ui-fabric-react/lib/DatePicker';
import { _onFormatDate, formatDateTime12HourString, formatISODateString } from '../../../../utils/dataFormatter'
import Moment from 'moment';
import 'containers/pexa/maintainUpdateSettlementDateTime.css'

import {
    SettlementDetailsType,
    RetrieveSettlementAvailabilityParams,
    UpdateSettlementDateTimeActionstepCommand,
    DataCollectionRecordValue,
    UpdateSettlementDateAndTimeRequestCommand,
    MaintainSettlementDateAndTimeRequestType
} from '../../../../utils/wcaApiTypes';


import {
    SettlementActionType,
    ParticipantSettlementAcceptanceStatusType,
    SettlementActionTypeReason,
    UpdateSettlementDateTimeData
} from '../../../../utils/newWcaApiTypes'

import {
    getPexaWorkspaceSummary,
    getAvailableSettlementTimes,
    setSettlementDateTimeData
} from '../../../../containers/pexa/redux/actions';

import { SubwayNavNodeState } from '../../../../components/SubwayNav';

interface IAppProps {
    onChangeStep: (newState: SubwayNavNodeState) => void;
}

type AppProps = IAppProps & ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

type AppStates = {
    currentSettlementDateTimeActionstep: Date | undefined;
    currentSettlementDateTimePexa: Date | undefined;
    newSettlementDate: Date | undefined;
    newSettlementDateTime: Date | undefined;
    workspaceId: string | undefined;
    jurisdiction: string;
    availableSettlementTimes: IDropdownOption[] | null;
    errorMessage: string | null;
    updateSettlementDateAndTimeRequestCommand: UpdateSettlementDateAndTimeRequestCommand; /*update command for Pexa*/
    updateSettlementDateTimeActionstepCommand: UpdateSettlementDateTimeActionstepCommand; /*update command for Actionstep*/

}

export class UpdateSettlementDateTime extends React.Component<AppProps, AppStates> {

    constructor(props: Readonly<AppProps>) {
        super(props);

        let availableSettlementTimes: IDropdownOption[] = [];

        this.state = {
            currentSettlementDateTimeActionstep: this.props.pexaWorkspaceCreationData?.actionstepData?.settlementDate,
            currentSettlementDateTimePexa: undefined,
            newSettlementDate: undefined,
            newSettlementDateTime: undefined,
            jurisdiction: "",
            availableSettlementTimes: availableSettlementTimes,
            workspaceId: this.props.pexaWorkspaceCreationData.existingPexaWorkspace?.pexaWorkspaceId,
            errorMessage: null,
            updateSettlementDateAndTimeRequestCommand: new UpdateSettlementDateAndTimeRequestCommand(), /*update command for Pexa*/
            updateSettlementDateTimeActionstepCommand: new UpdateSettlementDateTimeActionstepCommand() /*update command for Actionstep*/
        }
    }

    componentDidMount(): void {
        this.updateRequest();
    }

    private updateRequest(): void {
        const { pexaWorkspaceCreationData, actionstepContext } = this.props;
        const { workspaceId } = this.state;

        let newUpdateSettlementDateTimeActionstepCommand = this.state.updateSettlementDateTimeActionstepCommand;
        let newUpdateSettlementDateAndTimeRequestCommand = this.state.updateSettlementDateAndTimeRequestCommand;


        let pexaSubscriberRole = pexaWorkspaceCreationData.existingPexaWorkspace?.pexaWorkspaceUri?.split("=")[1];

        newUpdateSettlementDateAndTimeRequestCommand.maintainSettlementDateAndTimeRequestType = new MaintainSettlementDateAndTimeRequestType();
        newUpdateSettlementDateAndTimeRequestCommand.maintainSettlementDateAndTimeRequestType.workspaceId = workspaceId;
        newUpdateSettlementDateAndTimeRequestCommand.maintainSettlementDateAndTimeRequestType.role = pexaSubscriberRole!;
        newUpdateSettlementDateAndTimeRequestCommand.maintainSettlementDateAndTimeRequestType.settlementActionType = SettlementActionType.Propose_New_Date_And_Time;

        newUpdateSettlementDateTimeActionstepCommand.actionstepOrg = actionstepContext?.orgKey;
        newUpdateSettlementDateTimeActionstepCommand.matterId = actionstepContext!.matterContext!.id;

        this.setState({
            updateSettlementDateAndTimeRequestCommand: newUpdateSettlementDateAndTimeRequestCommand,
            updateSettlementDateTimeActionstepCommand: newUpdateSettlementDateTimeActionstepCommand
        });

    }

    static getDerivedStateFromProps(nextProps: AppProps, prevState: AppStates): AppStates {
        let nextState = {} as AppStates;

        if (prevState.jurisdiction !== "" && prevState.newSettlementDate !== null) {
            const jurisdiction = prevState.jurisdiction;
            const settlementDate = formatISODateString(prevState.newSettlementDate!);

            if (nextProps.availableSettlementTimes && nextProps.availableSettlementTimes[jurisdiction] && nextProps.availableSettlementTimes[jurisdiction][settlementDate]) {
                if (nextProps.availableSettlementTimes[jurisdiction][settlementDate].status === ReduxStatus.Success) {
                    const availabilityResponse = nextProps.availableSettlementTimes[jurisdiction][settlementDate].data!;
                    if (availabilityResponse.settlementAvailability === "Yes") {
                        const settlementTimes: IDropdownOption[] = availabilityResponse.availableSettlementTime!.map((settlementTime, index) => {
                            return {
                                key: formatDateTime12HourString(settlementTime),
                                text: formatDateTime12HourString(settlementTime)
                            };
                        })

                        nextState.availableSettlementTimes = settlementTimes;
                    }
                } else if (nextProps.availableSettlementTimes[jurisdiction][settlementDate].status === ReduxStatus.Failed) {
                    nextState.availableSettlementTimes = null;
                    nextState.errorMessage = nextProps.availableSettlementTimes[jurisdiction][settlementDate].error!.errorList![0];
                }
            }
        }

        if (prevState.currentSettlementDateTimePexa === undefined &&
            nextProps.workspaceSummaryList[prevState.workspaceId!]?.data?.settlementSchedule?.settlementDate) {
            nextState.currentSettlementDateTimePexa = nextProps.workspaceSummaryList[prevState.workspaceId!]?.data?.settlementSchedule?.settlementDate;
        }

        return nextState;
    }

    private checkAvailableSettlementTime(newDate: Date | null | undefined): void {
        let jurisdiction = this.props.workspaceSummaryList[this.state.workspaceId!]?.data?.jurisdiction;

        if (jurisdiction && newDate && !isNaN(newDate.getTime())) {
            const params = new RetrieveSettlementAvailabilityParams({
                jurisdiction: jurisdiction,
                settlementDate: formatISODateString(newDate!)
            });
            if (this.props.getAvailableSettlementTimes) {
                this.props.getAvailableSettlementTimes(params);
            }

            this.setState({
                jurisdiction: jurisdiction,
                newSettlementDate: newDate,
                availableSettlementTimes: null,
                errorMessage: null
            })
        }
    }

    private updateSettlementDateAndTime(newDateTime: Date | null | undefined): void {
        let newUpdateSettlementDateAndTimeRequestCommand = this.state.updateSettlementDateAndTimeRequestCommand;
        let newUpdateSettlementDateTimeActionstepCommand = this.state.updateSettlementDateTimeActionstepCommand;

        if (newDateTime) {
            newUpdateSettlementDateAndTimeRequestCommand.maintainSettlementDateAndTimeRequestType!.settlementDateAndTimeValue = newDateTime;
            newUpdateSettlementDateAndTimeRequestCommand.maintainSettlementDateAndTimeRequestType!.settlementDateAndTime = newDateTime;
            newUpdateSettlementDateAndTimeRequestCommand.maintainSettlementDateAndTimeRequestType!.settlementDateAndTimeValueSpecified = true;
            newUpdateSettlementDateAndTimeRequestCommand.maintainSettlementDateAndTimeRequestType!.settlementDetails = new SettlementDetailsType({
                settlementDateAndTime: newDateTime,
                participantSettlementAcceptanceStatus: ParticipantSettlementAcceptanceStatusType.Accepted,
                reason: SettlementActionTypeReason.Reschedule
            });

            let dataCollectionRecordValues: DataCollectionRecordValue[] = [];
            dataCollectionRecordValues.push(
                new DataCollectionRecordValue({
                    id: undefined,
                    stringValue: Moment(newDateTime).format('DD-MM-YYYY HH:mm').toString(),
                    links: undefined
                }));
            newUpdateSettlementDateTimeActionstepCommand.dataCollectionRecordValues = dataCollectionRecordValues;
            this.setState({
                updateSettlementDateAndTimeRequestCommand: newUpdateSettlementDateAndTimeRequestCommand,
                updateSettlementDateTimeActionstepCommand: newUpdateSettlementDateTimeActionstepCommand,
                newSettlementDateTime: newDateTime
            });
        }
    }

    private sendNewSettlementDateTime(buttonName: string): void {

        var updateSettlementDateTimeData = new UpdateSettlementDateTimeData()
        updateSettlementDateTimeData.currentSettlementDateTimeActionstep = this.state.currentSettlementDateTimeActionstep;
        updateSettlementDateTimeData.currentSettlementDateTimePexa = this.state.currentSettlementDateTimePexa;
        updateSettlementDateTimeData.updateSettlementDateAndTimeRequestCommand = this.state.updateSettlementDateAndTimeRequestCommand;
        updateSettlementDateTimeData.updateSettlementDateTimeActionstepCommand = this.state.updateSettlementDateTimeActionstepCommand;

        if (buttonName === "updateActionstep") {
            updateSettlementDateTimeData.updateForActionstep = true;
            updateSettlementDateTimeData.updateForPexa = false;
            updateSettlementDateTimeData.newSettlementDateTimeActionstep = this.state.newSettlementDateTime;
        }
        else if (buttonName === "updatePexa") {
            updateSettlementDateTimeData.updateForActionstep = false;
            updateSettlementDateTimeData.updateForPexa = true;
            updateSettlementDateTimeData.newSettlementDateTimePexa = this.state.newSettlementDateTime;
        }
        else if (buttonName === "updateBoth") {
            updateSettlementDateTimeData.updateForActionstep = true;
            updateSettlementDateTimeData.updateForPexa = true;
            updateSettlementDateTimeData.newSettlementDateTimeActionstep = this.state.newSettlementDateTime;
            updateSettlementDateTimeData.newSettlementDateTimePexa = this.state.newSettlementDateTime;
        }
        this.props.setSettlementDateTimeData(updateSettlementDateTimeData);
        this.props.onChangeStep(SubwayNavNodeState.Completed);
    }

    render() {
        const { availableSettlementTimes, newSettlementDate, newSettlementDateTime, currentSettlementDateTimeActionstep,
            currentSettlementDateTimePexa, errorMessage } = this.state;

        return (
            <div className="tms-Grid-row ms-sm12">
                < h3 className="tittle" >
                    <big><b>Maintain Settlement Date And Time</b></big>
                </h3 >
                <table className="pexa-data-list">
                    <tbody>
                        <tr className="ms-Grid-row ms-sm9">
                            <td className="ms-Grid-col ms-sm12">
                                <table className="pexa-data-list">
                                    <tbody>
                                        {/**Display the Current Settlement Date in Actionstep **/}
                                        <tr className="ms-Grid-row">
                                            <td className="pexa-data-key ms-Grid-col ms-sm6">
                                                Current Settlement Date in Actionstep
                                                        </td>
                                            <td className="pexa-data-value ms-Grid-col ms-sm6">
                                                <DatePicker
                                                    disabled={true}
                                                    value={currentSettlementDateTimeActionstep ?
                                                        currentSettlementDateTimeActionstep!.getTime() !== 0 && currentSettlementDateTimeActionstep!.getFullYear() !== 1 ?
                                                            currentSettlementDateTimeActionstep! : undefined
                                                        : undefined}
                                                    placeholder="Settlement date has not been set yet"
                                                    formatDate={_onFormatDate}
                                                />
                                            </td>
                                        </tr>

                                        {/**Display the Current Settlement Date in Pexa **/}
                                        <tr className="ms-Grid-row">
                                            <td className="pexa-data-key ms-Grid-col ms-sm6">
                                                Current Settlement Date in Pexa
                                             </td>
                                            <td className="pexa-data-value ms-Grid-col ms-sm6 pexa-current-settlement-date">
                                                <DatePicker
                                                    disabled={true}
                                                    value={currentSettlementDateTimePexa ?
                                                        currentSettlementDateTimePexa!.getTime() !== 0 && currentSettlementDateTimePexa!.getFullYear() !== 1 ?
                                                            currentSettlementDateTimePexa! : undefined
                                                        : undefined}
                                                    placeholder="Settlement date has not been set yet"
                                                    formatDate={_onFormatDate}
                                                />
                                            </td>
                                        </tr>

                                        {/**Display the new settlement Date **/}
                                        <tr className="ms-Grid-row">
                                            <td className="pexa-new-settlement ms-Grid-col ms-sm6">
                                                New Settlement Date
                                             </td>
                                            <td className="pexa-data-value ms-Grid-col ms-sm6">
                                                <DatePicker
                                                    showMonthPickerAsOverlay={true}
                                                    allowTextInput={true}
                                                    value={newSettlementDate ? newSettlementDate : undefined}
                                                    placeholder="Please select..."
                                                    formatDate={_onFormatDate}
                                                    onSelectDate={(newDate) => this.checkAvailableSettlementTime(newDate)}
                                                />
                                            </td>
                                        </tr>

                                        {/**Display the new settlement Time **/}
                                        <tr className="ms-Grid-row">
                                            <td className="pexa-new-settlement ms-Grid-col ms-sm6">
                                                New Settlement Time
                                             </td>
                                            <td className="pexa-data-value ms-Grid-col ms-sm6">
                                                <Dropdown
                                                    options={availableSettlementTimes ? availableSettlementTimes : []}
                                                    disabled={!availableSettlementTimes || !availableSettlementTimes.length}
                                                    errorMessage={!availableSettlementTimes ? errorMessage! : ""}
                                                    placeholder="Please select..."
                                                    onChange={(event, item) => this.updateSettlementDateAndTime(new Date(item!.text))}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <br />
                <br />

                <Stack horizontal horizontalAlign="space-around" >
                    <DefaultButton
                        className="button update-button"
                        disabled={newSettlementDateTime ? false : true}
                        text="Update Actionstep"
                        onClick={() => this.sendNewSettlementDateTime("updateActionstep")}
                    />
                    <DefaultButton
                        className="button update-button"
                        disabled={newSettlementDateTime ? false : true}
                        text="Update Pexa"
                        onClick={() => this.sendNewSettlementDateTime("updatePexa")}
                    />
                    <DefaultButton
                        className="button update-button"
                        disabled={newSettlementDate && newSettlementDateTime ? false : true}
                        text="Update BOTH"
                        onClick={() => this.sendNewSettlementDateTime("updateBoth")}
                    />
                </Stack>

            </div>
        );
    }
}

const mapStateToProps = (state: AppState) => {
    return {
        workspaceSummaryList: state.pexa.workspaceSummaryList,
        availableSettlementTimes: state.pexa.availableSettlementTimes,
        actionstepContext: state.common.actionstepContext,
        pexaWorkspaceCreationData: state.pexa.pexaWorkspaceCreationData
    }
}

const mapDispatchToProps = {
    getPexaWorkspaceSummary,
    getAvailableSettlementTimes,
    setSettlementDateTimeData
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateSettlementDateTime);