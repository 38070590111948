import * as React from 'react';
import * as IntegrationLink from "./integrationLinksConstants";

import {
    SubwayNavNodeState,
    ISubwayNavNodeProps,
    setSubwayState
} from 'components/SubwayNav';

import RetrieveFromActionstep from 'containers/pexa/steps/retrieveFromActionstep';
import ParticipantsInvitationForm from 'containers/pexa/steps/inviteParticipants/participantsInvitationForm';


import "./inviteParticipants.css"
import RetrieveWorkspaceFromPexa from './steps/showWorkspaceSummary/retrieveWorkspaceFromPexa';


interface IAppProps {
    closeInviteModal: () => void;
}

interface IMapStateToProps {
    openFromIframe: boolean;
}

type AppProps = IAppProps & IMapStateToProps;


export default class InviteParticipants extends React.Component<AppProps, any> {
    defaultSteps: ISubwayNavNodeProps[] = [];

    constructor(props: AppProps) {
        super(props);

        this.defaultSteps = [
            {
                id: '0',
                label: 'Retrieve from Actionstep',
                state: SubwayNavNodeState.Current,
                onClickStep: this._handleClickStep,
                disabled: true
            },
            {
                id: '1',
                label: 'Retrieve Workspace From Pexa',
                state: SubwayNavNodeState.NotStarted,
                onClickStep: this._handleClickStep,
                disabled: true
            },
            {
                id: '2',
                label: 'Participants Invitation Form',
                state: SubwayNavNodeState.NotStarted,
                onClickStep: this._handleClickStep,
                disabled: true
            }
        ];

        this.state = {
            steps: this.defaultSteps,
            currentStepId: 0,
            integrationLink: IntegrationLink.Invite_Participants,
            openFromIframe: this.props.openFromIframe === false ? false : true
        };
    }

    public componentDidMount(): void {
        if (this.props.openFromIframe === false) {
            this.setState({
                currentStepId: 1
            })
        }
    }

    private _handleClickStep = (step: ISubwayNavNodeProps): void => {
        this.setState({ ...setSubwayState(step, this.state.steps, this.state.currentStepId) });
    }

    private _onChangeStep = (newState: SubwayNavNodeState, newStep: number = -1): void => {
        let newSteps = [...this.state.steps];
        let currentStepId = this.state.currentStepId;

        if (newStep >= 0) {
            currentStepId = newStep;
        }
        for (let i = 0; i < currentStepId; i++) {
            newSteps[i].state = SubwayNavNodeState.Completed;
        }
        for (let i = currentStepId + 1; i < newSteps.length; i++) {
            newSteps[i].state = SubwayNavNodeState.NotStarted;
        }

        newSteps[currentStepId].state = newState;
        if (newState === SubwayNavNodeState.Completed && currentStepId < newSteps.length - 1) {
            currentStepId++;
            newSteps[currentStepId].state = SubwayNavNodeState.Current;
        }

        this.setState({
            steps: newSteps,
            currentStepId: currentStepId
        })

        window.scrollTo(0, 0);
    }

    render() {
        const { currentStepId, openFromIframe } = this.state;

        return (
            <div>
                <div className="invite-participants-wrapper wrapper vertical-container wrapper-content animated fadeIn" dir="ltr">

                    <div className="ms-Grid-row ">

                        <div className=" ms-Grid-col ms-lg12 ">
                            {openFromIframe ?
                                <>
                                    {currentStepId === 0 && <RetrieveFromActionstep integrationLink={this.state.integrationLink} onChangeStep={this._onChangeStep} />}
                                    {currentStepId === 1 && <RetrieveWorkspaceFromPexa onChangeStep={this._onChangeStep} />}
                                    {currentStepId === 2 && <ParticipantsInvitationForm closeInviteModal={() => void {}} openFromIframe={openFromIframe} />}
                                </> :
                                <>
                                    {currentStepId === 1 && <RetrieveWorkspaceFromPexa onChangeStep={this._onChangeStep} />}
                                    {currentStepId === 2 && <ParticipantsInvitationForm closeInviteModal={this.props.closeInviteModal} openFromIframe={openFromIframe} />}
                                </>
                            }

                        </div>
                    </div>

                </div>
            </div>
        );
    }
}
