export interface IField {
    id: string,
    label: string,
}

export interface IFormInfo {
    orgKey: string,
    uid: string,
    fields: IField[]
}

export const getFormInfo = (formHtml: string): IFormInfo | string => {
    try {
        let formDoc = document.implementation.createHTMLDocument('My title');
        let body = document.createElementNS('http://www.w3.org/1999/xhtml', 'body');
        body.innerHTML = formHtml;
        formDoc.documentElement.appendChild(body);

        let orgKey = (formDoc.getElementById('organization_key') as HTMLInputElement)?.value;
        if (!orgKey) return "Couldn't find organisation key, is this HTML from Actionstep?";

        let uid = (formDoc.getElementById('uid') as HTMLInputElement)?.value;
        if (!uid) return "Couldn't find form 'uid', is this HTML from Actionstep?";

        let form = formDoc.forms[0];
        if (!form) return "Couldn't find form element, is this HTML from Actionstep?";

        let fields = Array.from(form).reduce((result: IField[], current) => {
            let id = current.id;
            if (((current as HTMLInputElement)?.type === 'text' && id !== 'organization_key' && id !== 'uid' && id !== 'Submit') || current.tagName === 'TEXTAREA') {
                let label = `${(formDoc.querySelector(`label[for='${id}']`) as HTMLElement)?.innerText} (${id})` ?? id;
                result.push({ id, label });
            }
            return result;
        }, []);

        return { orgKey, uid, fields };
    } catch (e) {
        console.warn("Couldn't parse Actionstep Web Form HTML. The error encountered was:", e);
        return "Couldn't parse Actionstep Web Form HTML. Check the JavaScript console for more information.";
    }
}

export const enhanceForm = (formHtml: string, baseUrl: string, formIdFieldId: string, formAttachmentListFieldId: string): string => {
    let formDoc = document.implementation.createHTMLDocument('My title');
    let body = document.createElementNS('http://www.w3.org/1999/xhtml', 'body');
    body.innerHTML = formHtml;
    formDoc.documentElement.appendChild(body);

    let orgKey = (formDoc.getElementById('organization_key') as HTMLInputElement)?.value;
    if (!orgKey) throw new Error("Couldn't find organisation key, is this HTML from Actionstep?");

    let uid = (formDoc.getElementById('uid') as HTMLInputElement)?.value;
    if (!uid) throw new Error("Couldn't find form 'uid', is this HTML from Actionstep?");

    let formIdDiv = (formDoc.getElementById(formIdFieldId) as HTMLInputElement)?.parentElement;
    if (!formIdDiv) throw new Error(`Couldn't find form ID field '${formIdFieldId}'.`);
    formIdDiv.remove();

    let formAttachmentListDiv = (formDoc.getElementById(formAttachmentListFieldId) as HTMLInputElement)?.parentElement;
    if (!formAttachmentListDiv) throw new Error(`Couldn't find form ID field '${formAttachmentListFieldId}'.`);
    formAttachmentListDiv.remove();

    let form = formDoc.forms[0];
    if (!form) throw new Error("Couldn't find form element, is this HTML from Actionstep?");

    body.insertAdjacentHTML('beforeend', `\n<script type="text/javascript" src="${baseUrl}/api/data-konekta/script/${orgKey}/${uid}"></script>`)

    return body.innerHTML;
}