import { ErrorViewModel } from 'utils/wcaApiTypes';
import * as CONSTANTS from './constants';
import {
    StewartTitleActionstepResponse as StewartTitleActionstepResponse,
    OrderStewartTitlePolicyCommandResponse as OrderStewartTitlePolicyCommandResponse,
    StewartTitleOrderDetailsQueryResponse as StewartTitleOrderDetailsQueryResponse
} from '../utils/stewartTitleWCAApiTypes';
import {
    IActionFailed,
    GetMatterDetailsFromActionstepSuccess,
    OrderStewartTitlePolicySuccess,
    GetStewartTitleOrderDetailsSuccess,
    StewartTitleActionTypes
} from './actionTypes';

export interface IStewartTitleState {
    matterDetails: StewartTitleActionstepResponse | undefined,
    isValidCredentials: boolean | undefined,
    orderStewartTitlePolicyCommandResponse: OrderStewartTitlePolicyCommandResponse | undefined,
    StewartTitleOrderDetailsQueryResponse: StewartTitleOrderDetailsQueryResponse | undefined
    gotResponse: boolean,
    success: boolean,
    requestType: string,
    error: ErrorViewModel | undefined,
}

const initialState: IStewartTitleState = {
    matterDetails: undefined,
    isValidCredentials: undefined,
    orderStewartTitlePolicyCommandResponse: undefined,
    StewartTitleOrderDetailsQueryResponse: undefined,
    gotResponse: false,
    success: false,
    requestType: "",
    error: undefined
}

function stewartTitleReducer(state: IStewartTitleState = initialState, action: StewartTitleActionTypes): IStewartTitleState {

    switch (action.type) {
        case CONSTANTS.GET_STEWART_TITLE_MATTER_FROM_ACTIONSTEP_REQUESTED:
        case CONSTANTS.ORDER_STEWART_TITLE_POLICY_REQUESTED:
        case CONSTANTS.GET_STEWART_TITLE_ORDER_DETAILS_REQUESTED:
            return {
                ...state,
                requestType: action.type,
                gotResponse: true,
                success: false
            };

        case CONSTANTS.GET_STEWART_TITLE_MATTER_FROM_ACTIONSTEP_SUCCESS:
            return {
                ...state,
                requestType: action.type,
                gotResponse: true,
                success: true,
                matterDetails: (action as GetMatterDetailsFromActionstepSuccess).data
            };

        case CONSTANTS.ORDER_STEWART_TITLE_POLICY_SUCCESS:
            return {
                ...state,
                requestType: action.type,
                gotResponse: true,
                success: true,
                orderStewartTitlePolicyCommandResponse: (action as OrderStewartTitlePolicySuccess).data
            };

        case CONSTANTS.GET_STEWART_TITLE_ORDER_DETAILS_SUCCESS:
            return {
                ...state,
                requestType: action.type,
                gotResponse: true,
                success: true,
                StewartTitleOrderDetailsQueryResponse: (action as GetStewartTitleOrderDetailsSuccess).data
            };

        case CONSTANTS.GET_STEWART_TITLE_MATTER_FROM_ACTIONSTEP_FAILED:
        case CONSTANTS.ORDER_STEWART_TITLE_POLICY_FAILED:
        case CONSTANTS.GET_STEWART_TITLE_ORDER_DETAILS_FAILED:
            return {
                ...state,
                requestType: action.type,
                gotResponse: true,
                success: false,
                error: (action as IActionFailed).error
            };
        default:
            return state;
    }
}

export default stewartTitleReducer;