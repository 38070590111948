import React from 'react';
import {
    Stack,
    Separator
} from 'office-ui-fabric-react';
import { TextField } from 'office-ui-fabric-react/lib/TextField';

import { _onFormatDate } from 'utils/dataFormatter';
import {
    LabelColumnStackItemStyles,
    DefaultStackTokens,
    FieldColumnStackItemStyles
} from 'konekta-theme';
import Tools from 'utils/tools';
import { FTParty } from 'utils/wcaApiTypes';
import FTAddressInput from 'containers/firsttitle/components/fTAddressInput';
import { FTPartyExtended } from 'containers/firsttitle/requestPolicy';
import { RemoveBuyerComponent } from './RemoveBuyerComponent';

interface IProps {
    buyer: FTPartyExtended; 
    index: number;
    setMatterInformation: (keyPath: string, newValue: any) => void;
    validatePartyBusinessDetails: (party: FTParty) => string[];
    removeBuyer?: (id: string) => void;
}

export const CompanyBuyer = ({ buyer, index, setMatterInformation, validatePartyBusinessDetails, removeBuyer }: IProps) => {
    const onRemoveBtnClick = () => {
        if (buyer.newlyCreatedId != null && removeBuyer != null) {
            removeBuyer(buyer.newlyCreatedId);
        }
    }

    return (
        <Stack>
            {buyer.newlyCreatedId != null && <RemoveBuyerComponent onClickHandler={onRemoveBtnClick} />}

            <Stack horizontal tokens={DefaultStackTokens}>
                <Stack disableShrink styles={LabelColumnStackItemStyles}>
                    <h3>Company: {buyer.companyName}</h3>
                </Stack>
                <Stack styles={FieldColumnStackItemStyles}>
                    <TextField
                        label="Company Name"
                        id={`buyers_${index}_company_name`}
                        value={buyer.companyName || ""}
                        errorMessage={!Tools.isNullOrEmptyOrUndefined(buyer.companyName) ? "" : "Required"}
                        onChange={(ev, newValue) => setMatterInformation(`buyers.${index}.companyName`, newValue)}
                    />

                    <TextField
                        label="Email Address"
                        id={`buyers_${index}_email_address`}
                        value={buyer.emailAddress || ""}
                        onChange={(ev, newValue) => setMatterInformation(`buyers.${index}.emailAddress`, newValue)}
                    />

                    <TextField
                        label="ABN"
                        id={`buyers_${index}_abn`}
                        value={buyer.abn || ""}
                        errorMessage={validatePartyBusinessDetails(buyer).length <= 0 ? "" : "At least one of ABN, ACN or ABRN is required. ABN must have 11 digits."}
                        onChange={(ev, newValue) => setMatterInformation(`buyers.${index}.abn`, newValue)}
                    />

                    <TextField
                        label="ACN"
                        id={`buyers_${index}_acn`}
                        value={buyer.acn || ""}
                        errorMessage={validatePartyBusinessDetails(buyer).length <= 0 ? "" : "At least one of ABN, ACN or ABRN is required. ACN must have 9 digits."}
                        onChange={(ev, newValue) => setMatterInformation(`buyers.${index}.acn`, newValue)}
                    />

                    <TextField
                        label="ABRN"
                        id={`buyers_${index}_abrn`}
                        value={buyer.abrn || ""}
                        errorMessage={validatePartyBusinessDetails(buyer).length <= 0 ? "" : "At least one of ABN, ACN or ABRN is required."}
                        onChange={(ev, newValue) => setMatterInformation(`buyers.${index}.abrn`, newValue)}
                    />

                    <div>
                        You have to provide one of these numbers.
                    </div>

                    <Separator />
                    <FTAddressInput
                        prefix={`buyers_${index}_`}
                        party={buyer}
                        setMatterInformation={(keyPath, newValue) => setMatterInformation(`buyers.${index}.${keyPath}`, newValue)}
                        disableCountrySelection={false}
                    />
                </Stack>
            </Stack>
        </Stack>

    )
}