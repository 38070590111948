import * as CONSTANTS from './constants'
import {   
    ErrorViewModel,   
    GetUserAuditTrailDataResponse 
} from 'utils/wcaApiTypes';

import {
    UserAuditTrailActionTypes,
    ActionFailed,
    GetUserAuditTrailDataSuccess,
    SaveUserAuditTrailDataSuccess
  
} from 'containers/konekta/redux/actionTypes';


export interface UserAuditTrailState {
    gotResponse: boolean,
    success: boolean,
    error: ErrorViewModel | undefined,
    requestType: string,
    getUserAuditTrailDataResponse: GetUserAuditTrailDataResponse | undefined,
    saveUserAuditTrailDataResponse: boolean
 
}

const initialState: UserAuditTrailState = {
    gotResponse: false,
    success: false,
    error: undefined,
    requestType: "",
    getUserAuditTrailDataResponse: undefined,
    saveUserAuditTrailDataResponse: false
 
}

function userAuditTrailReducer(state: UserAuditTrailState = initialState, action: UserAuditTrailActionTypes): UserAuditTrailState {

    switch (action.type) {
        case CONSTANTS.GET_USER_AUDIT_TRAIL_DATA_REQUESTED:      
        case CONSTANTS.SAVE_USER_AUDIT_TRAIL_DATA_REQUESTED:   
            return {
                ...state,
                requestType: action.type,
                gotResponse: false,
                success: false
            };




        case CONSTANTS.GET_USER_AUDIT_TRAIL_DATA_SUCCESS:
            return {
                ...state,
                getUserAuditTrailDataResponse: (action as GetUserAuditTrailDataSuccess).data,
                gotResponse: true,
                success: true

            };
        case CONSTANTS.SAVE_USER_AUDIT_TRAIL_DATA_SUCCESS:
            return {
                ...state,
                saveUserAuditTrailDataResponse: (action as SaveUserAuditTrailDataSuccess).data,
                gotResponse: true,
                success: true
            };



        
        case CONSTANTS.GET_USER_AUDIT_TRAIL_DATA_FAILED:       
        case CONSTANTS.SAVE_USER_AUDIT_TRAIL_DATA_FAILED:
            return {
                ...state,
                gotResponse: true,
                success: false,
                error: (action as ActionFailed).error
            };

        case CONSTANTS.CLEAR_USER_AUDIT_TRAIL_STATE:
            return {
                ...state,
                gotResponse: false,
                success: false,
                requestType: ""
            };

        default:
            return state;
    }
}

export default userAuditTrailReducer;