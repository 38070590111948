import * as React from 'react';
import Moment from 'moment';
import { connect } from 'react-redux';
import { saveUserAuditTrailData } from 'redux/common/actions';
import 'containers/pexa/maintainUpdateSettlementDateTime.css'
import { AppState } from '../../../../app.types';
import { SaveUserAuditTrailDataCommand, UserAuditTrail } from '../../../../utils/wcaApiTypes';
import { SavePexaAuditParams } from 'utils/newWcaApiTypes';
import { savePexaAudit } from 'containers/pexa/redux/actions';

type AppProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

type AppStates = {
    newDateTimeAS: string,
    previousDateTimeAS: string,
    newDateTimePexa: string,
    previousDateTimePexa: string,
}

export class SettlementDateTimeUpdated extends React.Component<AppProps, AppStates> {
    constructor(props: AppProps) {
        super(props);
        this.state = {
            newDateTimeAS: "",
            previousDateTimeAS: "",
            newDateTimePexa: "",
            previousDateTimePexa: ""
        }
    }

    async componentDidMount(): Promise<void> {
        await this.updateState();
        await this.saveUserAuditTrailDataIntoDb();
        this.savePexaAudiTIntoDb();

    }

    private async updateState(): Promise<void> {
        const formatDT = 'DD/MM/YYYY HH:mm';
        const previousSettlementDateTimeAS = this.props.updateSettlementDateTimeData?.currentSettlementDateTimeActionstep;
        const newSettlementDateTimeAS = this.props.updateSettlementDateTimeData?.newSettlementDateTimeActionstep;
        const previousSettlementDateTimePexa = this.props.updateSettlementDateTimeData?.currentSettlementDateTimePexa;
        const newSettlementDateTimePexa = this.props.updateSettlementDateTimeData?.newSettlementDateTimePexa;

        const previousDateTimeAS = previousSettlementDateTimeAS && previousSettlementDateTimeAS.getFullYear() !== 1
            ? Moment(previousSettlementDateTimeAS).format('DD/MM/YYYY') : "Not set yet";

        const newDateTimeAS = newSettlementDateTimeAS && newSettlementDateTimeAS.getFullYear() !== 1 ?
            Moment(newSettlementDateTimeAS).format(formatDT) :
            newSettlementDateTimeAS ? Moment(newSettlementDateTimeAS).format(formatDT) : previousDateTimeAS;

        const previousDateTimePexa = previousSettlementDateTimePexa ? Moment(previousSettlementDateTimePexa).format(formatDT) : "Not set yet";

        const newDateTimePexa = newSettlementDateTimePexa ? Moment(newSettlementDateTimePexa).format(formatDT) :
            newSettlementDateTimePexa ? Moment(newSettlementDateTimePexa).format(formatDT) : previousDateTimePexa;
        await this.setState({
            newDateTimeAS: newDateTimeAS,
            previousDateTimeAS: previousDateTimeAS,
            newDateTimePexa: newDateTimePexa,
            previousDateTimePexa: previousDateTimePexa
        });

    }

    private async saveUserAuditTrailDataIntoDb(): Promise<void> {
        const { newDateTimeAS, previousDateTimeAS, newDateTimePexa, previousDateTimePexa } = this.state;
        let userAuditTrail = new UserAuditTrail();
        userAuditTrail.actionstepOrg = this.props.actionstepContext!.orgKey;
        userAuditTrail.matterId = this.props.actionstepContext!.matterContext!.id;
        userAuditTrail.integration = "PEXA";
        userAuditTrail.integrationLink = "Maintain and Update Settlement Date/Time";

        if (newDateTimeAS !== previousDateTimeAS) {
            if (previousDateTimeAS !== "Not set yet") {
                userAuditTrail.description =
                    "Actionstep date time changed from " + previousDateTimeAS + " to " + newDateTimeAS;
            } else {
                userAuditTrail.description =
                    "New Actionstep datetime: " + newDateTimeAS;
            }
            await this.props.saveUserAuditTrailData(new SaveUserAuditTrailDataCommand({
                userAuditTrailData: userAuditTrail
            }));

        }

        if (newDateTimePexa !== previousDateTimePexa) {
            if (previousDateTimePexa !== "Not set yet") {
                userAuditTrail.description =
                    "Pexa date time changed from " + previousDateTimePexa + " to " + newDateTimePexa;
            } else {
                userAuditTrail.description =
                    "New Pexa date time :" + newDateTimePexa;
            }
            await this.props.saveUserAuditTrailData(new SaveUserAuditTrailDataCommand({
                userAuditTrailData: userAuditTrail
            }));
        }
    }

    private savePexaAudiTIntoDb(): void {
        const { actionstepContext } = this.props;
        let pexaAuditParams = new SavePexaAuditParams();
        pexaAuditParams.CommandName = "UPDATESETTLEMENTDATEANDTIMEREQUESTCOMMAND";
        pexaAuditParams.actionstepOrg = actionstepContext!.orgKey;
        pexaAuditParams.matterId = actionstepContext!.matterContext!.id;
        pexaAuditParams.pexaWorkspaceId = this.props.pexaWorkspaceCreationData.existingPexaWorkspace?.pexaWorkspaceId;
        pexaAuditParams.pexaWorkspaceStatus = "";
        this.props.savePexaAudit(pexaAuditParams);
    }

    render() {
        const { newDateTimeAS, previousDateTimeAS, newDateTimePexa, previousDateTimePexa } = this.state;

        return (
            <div>
                <h4> The settlement date and time has been updated as below:  </h4>

                <table className="pexa-settlement-date-time-confirmation-table">
                    <tr>
                        <th rowSpan={2}> </th>
                        <th colSpan={2}>Settlement Date Time</th>
                        <th rowSpan={2}>Status</th>
                    </tr>
                    <tr>
                        <td>Previous</td>
                        <td>Current</td>
                    </tr>
                    <tr>
                        <td>Actionstep</td>
                        <td>{previousDateTimeAS}</td>
                        <td>{newDateTimeAS}</td>
                        <td>{newDateTimeAS !== previousDateTimeAS ? "Updated" : "Not Change"}</td>
                    </tr>
                    <tr>
                        <td>Pexa</td>
                        <td>{previousDateTimePexa}</td>
                        <td>{newDateTimePexa}</td>
                        <td>{newDateTimePexa !== previousDateTimePexa ? "Updated" : "Not Change"}</td>
                    </tr>
                </table>
            </div>
        );
    }
}

const mapStateToProps = (state: AppState) => {
    return {
        updateSettlementDateTimeData: state.pexa.updateSettlementDateTimeData,
        actionstepContext: state.common.actionstepContext,
        pexaWorkspaceCreationData: state.pexa.pexaWorkspaceCreationData
    }
}

const mapDispatchToProps = {
    saveUserAuditTrailData,
    savePexaAudit
}

export default connect(mapStateToProps, mapDispatchToProps)(SettlementDateTimeUpdated);

