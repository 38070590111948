import * as React from 'react';
import sanitize from 'sanitize-html';
import { MessageBar } from 'office-ui-fabric-react';
import { useState } from 'react';
import { getRequest } from 'utils/request';
import "./promotion.css";

class PromotionRecord {
  id: number | "" | undefined;
  message: string | "" | undefined;
  bannerType: string | "" | undefined;
}

const Promotions: React.FunctionComponent<any> = (props) => {
  let promotionObj: PromotionRecord[] = [];
  const [data, setPromotionDataObject] = useState([]);
  Object.assign(promotionObj, data);
  React.useEffect(() => {
    (async () => {
      const settingsFromServer = await getRequest(`/api/data-konekta/promotion-data`)
      setPromotionDataObject(settingsFromServer);
    })();
  }, [])

  const promotionBody = (promotion: PromotionRecord) => {
    return (<div className="announcement-body" key={promotion.id}>
      <span dangerouslySetInnerHTML={{
        __html: sanitize(promotion.message ?? "",
          {
            allowedTags: ['p', 'span', 'div', 'a', 'br'],
            allowedAttributes: {
              'p': ["style"],
              'span': ["style"],
              'div': ['style'],
              'a': ["href", "target"]
            },
            allowedStyles: {
              '*': {
                // Match HEX and RGB
                'color': [/^#(0x)?[0-9a-f]+$/i, /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/],
                'background-color': [/^#(0x)?[0-9a-f]+$/i, /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/],
                'text-align': [/^left$/, /^right$/, /^center$/],
                // Match any number with px, em, or %
                'font-size': [/^\d+(?:px|em|%)$/],
                'font-weight': [/^bold$/],
                'padding': [/^\d+(?:px|em|%)$/]
              }
            }
          })
      }} />
    </div>);
  }

  const promotionType = (type: string | undefined) => {
    switch (type) {
      case "Announcement":
        return "promotion-announcement";
      case "NewProduct":
        return "promotion-new-product";
      case "Warning":
        return "promotion-warning";
      default:
        return "";
    }
  }

  return (
    <>
      <div className='announcement-header-margin'>
        {promotionObj != null && promotionObj.length > 0 &&
          <div>
            <MessageBar className={`announcement-header ${promotionType(promotionObj[0].bannerType)}`}>
              <span className="announcement-heading">Announcement</span>
              {promotionBody(promotionObj[0])}
            </MessageBar>

            {promotionObj.slice(1).map(d => (
              <MessageBar className={`announcement-header ${promotionType(d.bannerType)}`}>
                {promotionBody(d)}
              </MessageBar>
            ))}
          </div>
        }
      </div>
    </>
  );
}
export default Promotions;

