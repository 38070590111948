import * as React from 'react'
import {
    Card,
    ICardTokens,
    ICardStyles,
} from '@uifabric/react-cards';

import {
    Stack,
    Text,
    Image,
    IImageStyles,
    ITextStyles,
    FontWeights,
    Link,
    MessageBar,
    MessageBarType,
} from 'office-ui-fabric-react';

import { Link as ReactLink } from 'react-router-dom';

import {
    setCurrentOrg,
    clearCurrentOrg,
} from 'redux/common/actions';
import konektaTheme, { DefaultStackTokens } from 'konekta-theme';
import { connect } from 'react-redux';
import { AppState } from 'app.types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { CSSProperties } from 'react';
import OrgSelector from 'components/common/orgSelector';

import './index.css';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { ConnectedActionstepOrgsResponse } from 'utils/wcaApiTypes';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

type IProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

enum LoadingStatus {
    Loaded,
    Loading,
    LoadFailed,
}

type IState = {
    loadingStatus: LoadingStatus,
}

export class ManageAddOns extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            loadingStatus: LoadingStatus.Loading,
        }
    }

    public componentDidUpdate(nextProps: IProps): void {
    }

    public selectDifferentOrg = (): void => {
        this.props.clearCurrentOrg();
    }

    public setOrgKey = (org: ConnectedActionstepOrgsResponse): void => {
        this.props.setCurrentOrg(org);
    }

    render(): JSX.Element {
        if (!this.props.actionstepContext?.orgKey) {
            return (
                <OrgSelector onSelect={this.setOrgKey} />
            );
        }

        const siteTextStyles: ITextStyles = {
            root: {
                color: konektaTheme.palette.themePrimary,
                fontWeight: FontWeights.semibold,
            },
        };
        const descriptionTextStyles: ITextStyles = {
            root: {
                color: '#333333',
                fontWeight: FontWeights.regular,
            },
        };
        const helpfulTextStyles: ITextStyles = {
            root: {
                alignContent: "center",
                fontWeight: FontWeights.regular,
            },
        };

        const integrationCardTokens: ICardTokens = { childrenMargin: 30, minWidth: 300, width: 300 };
        const integrationCardStyles: ICardStyles = {
            root: {
                alignItems: "center",
            }
        };

        const integrationLogoStyles: IImageStyles = {
            root: {},
            image: {
                maxHeight: 80,
                maxWidth: 100,
                width: 100,
                //filter: "grayscale(100%)",
                padding: 20,
            }
        };

        const integrationFaLogoStyles: CSSProperties = {
            maxHeight: 80,
            maxWidth: 150,
            width: 150,
            //filter: "grayscale(100%)",
            padding: 20,
        };

        const addOnCardTokens: ICardTokens = { childrenMargin: 30, minWidth: 300, width: 300 };
        const addOnCardStyles: ICardStyles = {
            root: {
                alignItems: "center",
            }
        };

        const addOnLogoStyles: IImageStyles = {
            root: {},
            image: {
                maxHeight: 50,
                maxWidth: 75,
                width: 75,
                //filter: "grayscale(100%)",
                padding: 20,
            }
        };

        interface IIntegration {
            logoSrc?: string;
            logoAlt?: string;
            faIcon?: IconProp;
            color?: string;
            linkTo?: string;
            linkIsExternal?: boolean;
            title?: string;
            description?: string;
        }

        const integrations: IIntegration[] = [
            {
                logoSrc: "/images/globalx-logo-noborder.png",
                logoAlt: "GlobalX Logo",
                linkTo: "/manage-add-ons/globalx",
            },
            {
                logoSrc: "/images/InfoTrackLogo_216x80.png",
                logoAlt: "InfoTrack Logo",
                linkTo: "/wca/integrations/infotrack-connection",
                linkIsExternal: true,
            },
            {
                logoSrc: "/images/se_logo_dark.png",
                logoAlt: "Securexchange Logo",
                linkTo: "/wca/integrations/securexchange-connection",
                linkIsExternal: true,
            },
            {
                logoSrc: "/images/livesign_logo.png",
                logoAlt: "livesign Logo",
                linkTo: "/ecaf/onboard",
                linkIsExternal: true,
            },
            {
                logoSrc: "/images/pexa-logo.svg",
                logoAlt: "PEXA Logo",
            },
            {
                logoSrc: "/images/firsttitle-logo.svg",
                logoAlt: "First Title Logo",
            },
            {
                logoSrc: "/images/the-search-people-logo.png",
                logoAlt: "The Search People Logo",
            },
            {
                faIcon: "sms",
                color: "gray",
                title: "Advanced SMS",
            },
            {
                faIcon: "sync",
                color: "gray",
                title: "Reporting Sync",
            },
            {
                faIcon: "file-alt",
                color: "gray",
                title: "Data Konekta",
                linkTo: "/manage-add-ons/data-konekta",
            },
        ]

        interface IAddOn {
            logoSrc: string;
            logoAlt: string;
            title: string;
            description?: string;
        }

        const addons: IAddOn[] = [
            {
                logoSrc: "/images/Conveyancing-ACT.svg",
                logoAlt: "ACT Conveyancing",
                title: "ACT Conveyancing",
            },
            {
                logoSrc: "/images/Conveyancing-VIC.svg",
                logoAlt: "VIC Conveyancing",
                title: "VIC Conveyancing",
            },
            {
                logoSrc: "/images/Conveyancing-QLD.svg",
                logoAlt: "QLD Conveyancing",
                title: "QLD Conveyancing",
            },
            {
                logoSrc: "/images/Conveyancing-NSW.svg",
                logoAlt: "NSW Conveyancing",
                title: "NSW Conveyancing",
            },

        ]

        const context = this.props.actionstepContext;

        return (
            <Stack tokens={{ padding: 'm' }}>
                <Stack tokens={DefaultStackTokens}>
                    <Stack.Item>
                        <h1>Integrations</h1>
                    </Stack.Item>
                    <MessageBar messageBarType={MessageBarType.info}>
                        <Text>Selected Organisation: {context.orgName}</Text>
                        &nbsp;
                        <span>
                            <FontAwesomeIcon icon={faCheck} color="green" />&nbsp;
                            <Link onClick={this.selectDifferentOrg}>Select another</Link>
                        </span>
                    </MessageBar>
                    <Stack.Item tokens={DefaultStackTokens}>
                        <Stack horizontal wrap tokens={DefaultStackTokens}>
                            {integrations.map((integration, index) => (
                                <Card key={index} horizontal tokens={integrationCardTokens} styles={integrationCardStyles}>
                                    <Card.Item fill>
                                        {integration.faIcon
                                            ? <FontAwesomeIcon size={'3x'} color={integration.color} icon={integration.faIcon} style={integrationFaLogoStyles} />
                                            : <Image src={integration.logoSrc} alt={integration.logoAlt} styles={integrationLogoStyles} />
                                        }
                                    </Card.Item>
                                    <Card.Section>
                                        <Text variant="small" styles={siteTextStyles}>{integration.title}</Text>
                                        <Text>{integration.description}</Text>
                                        <Text variant="small" styles={helpfulTextStyles}>
                                            {/* <Toggle inlineLabel onText="Enabled" offText="Disabled" disabled /> */}
                                            {/* <Toggle inlineLabel label="Enable/Disable" disabled /> */}
                                            {/* <Link disabled>Configure</Link> */}
                                            {integration.linkTo
                                                ? integration.linkIsExternal
                                                    ? <Link href={integration.linkTo}>Manage</Link>
                                                    : <ReactLink to={integration.linkTo}>Manage</ReactLink>
                                                : <ReactLink to="/contactus">Contact us</ReactLink>
                                            }
                                        </Text>
                                    </Card.Section>
                                </Card>
                            ))}
                        </Stack>
                    </Stack.Item>

                    <Stack.Item>
                        <h1>Add-ons</h1>
                    </Stack.Item>
                    <Stack.Item>
                        <Stack horizontal wrap tokens={DefaultStackTokens}>
                            {addons.map((addon, index) => (
                                <Card key={index} horizontal tokens={addOnCardTokens} styles={addOnCardStyles}>
                                    <Card.Item fill>
                                        <Image src={addon.logoSrc} alt={addon.logoAlt} styles={addOnLogoStyles} />
                                    </Card.Item>
                                    <Card.Section>
                                        <Text variant="small" styles={siteTextStyles}>{addon.title}</Text>
                                        <Text styles={descriptionTextStyles}>{addon.description}</Text>
                                        <Text variant="small" styles={helpfulTextStyles}>
                                            {/* <Toggle inlineLabel onText="Enabled" offText="Disabled" disabled /> */}
                                            {/* {index % 2 === 0
                                                ? <><FontAwesomeIcon icon={faCheck} color="green" /> <Link disabled>Already installed</Link></>
                                                : <Link>Install</Link>*/
                                            }
                                            <ReactLink to="/contactus">Contact us</ReactLink>
                                        </Text>
                                    </Card.Section>
                                </Card>
                            ))}
                        </Stack>
                    </Stack.Item>

                </Stack>
            </Stack>
        );
    }
}

const mapStateToProps = (state: AppState) => {
    return {
        actionstepContext: state.common.actionstepContext,
    }
}

const mapDispatchToProps = {
    setCurrentOrg,
    clearCurrentOrg,
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageAddOns);