import { IDropdownOption, IStackItemStyles } from '@fluentui/react';

export const stateOfCountryOptions = [
    { key: "", text: "Select" },
    { key: 1, text: "ACT" },
    { key: 2, text: "NSW" },
    { key: 3, text: "NT" },
    { key: 4, text: "QLD" },
    { key: 5, text: "SA" },
    { key: 6, text: "TAS" },
    { key: 7, text: "VIC" },
    { key: 8, text: "WA" }
]

export enum OrderStatus {
    Incomplete = -1,
    Pending = 0,
    Accepted = 1,
    Closed = 2,
    PendingCancel = 3,
    Failed = 4,
    Cancelled = 5
}

export const interestBeingTransferredOptions: IDropdownOption[] = [
    { key: "", text: "Select" },
    { key: "Fee Simple", text: "Fee Simple" },
    { key: "State Leasehold", text: "State Leasehold" },
    { key: "Water Allocation", text: "Water Allocation" },
    { key: "Lease", text: "Lease" },
    { key: "3/4 share in fee simple", text: "3/4 share in fee simple" },
    { key: "1/5 share in fee simple", text: "1/5 share in fee simple" },
    { key: "1/3 share in fee simple", text: "1/3 share in fee simple" },
    { key: "1/2 share in fee simple", text: "1/2 share in fee simple" }
]

export const tenureTypeOptions: IDropdownOption[] = [
    { key: "", text: "Select" },
    { key: "1", text: "Freehold" },
    { key: "2", text: "Leasehold" }
]

export const natureOfPropertyOptions: IDropdownOption[] = [
    { key: "", text: "Select" },
    { key: "House", text: "House" },
    { key: "Units/Apartments", text: "Units/Apartments" },
    { key: "Commercial", text: "Commercial" },
    { key: "Farm/Rural", text: "Farm/Rural" },
    { key: "Industial/Commercial", text: "Industial/Commercial" },
    { key: "Relocatable Home", text: "Relocatable Home" },
    { key: "Retail", text: "Retail" },
    { key: "Vacant Land", text: "Vacant Land" }
]

// export const zoningType: IDropdownOption[] = [
//     { key: "Residential", text: "Residential" },
//     { key: "Township", text: "Township" },
//     { key: "Mixed Use", text: "Mixed Use" },
//     { key: "Rural", text: "Rural" },
//     { key: "Farming", text: "Farming" },
//     { key: "Commercial", text: "Commercial" },
//     { key: "Industrial", text: "Industrial" },
//     { key: "Other", text: "Other" }
// ]

export const zoningTypeOptions: IDropdownOption[] = [
    { key: "", text: "Select" },
    { key: 1, text: "Residential" },
    { key: 2, text: "Township" },
    { key: 3, text: "Mixed Use" },
    { key: 4, text: "Rural" },
    { key: 5, text: "Farming" },
    { key: 6, text: "Commercial" },
    { key: 7, text: "Industrial" },
    { key: 8, text: "Other" }
]

// export const propertyTypeForWAAndVICState: IDropdownOption[] =
//     [
//         { key: "House", text: "House" },
//         { key: "Strata With Surrounding Land", text: "Strata With Surrounding Land" },
//         { key: "Strata Units/Apartment With No Surrounding Land", text: "Strata Units/Apartment With No Surrounding Land" },
//         { key: "Vacant Land", text: "Vacant Land" },
//         { key: "Warehouse", text: "Warehouse" },
//         { key: "Factory", text: "Factory" },
//         { key: "Office", text: "Office" },
//         { key: "Shop", text: "Shop" },
//         { key: "House Used For Commercial Purposes", text: "House Used For Commercial Purposes" },
//         { key: "Other", text: "Other" },
//         { key: "Multiple Dwellings (Under One Roof)", text: "Multiple Dwellings (Under One Roof)" }
//     ]

export const propertyTypeForWAAndVICStateOptions: IDropdownOption[] =
    [
        { key: "", text: "Select" },
        { key: 1, text: "House" },
        { key: 2, text: "Strata With Surrounding Land" },
        { key: 3, text: "Strata Units/Apartment With No Surrounding Land" },
        { key: 4, text: "Vacant Land" },
        { key: 5, text: "Warehouse" },
        { key: 6, text: "Factory" },
        { key: 7, text: "Office" },
        { key: 8, text: "Shop" },
        { key: 9, text: "House Used For Commercial Purposes" },
        { key: 10, text: "Other" },
        { key: 11, text: "Multiple Dwellings (Under One Roof)" }
    ]

// export const propertyTypeForOtherState: IDropdownOption[] =
//     [
//         { key: "House", text: "House" },
//         { key: "Strata Title", text: "Strata Title" },
//         { key: "Vacant Land", text: "Vacant Land" },
//         { key: "Warehouse", text: "Warehouse" },
//         { key: "Factory", text: "Factory" },
//         { key: "Office", text: "Office" },
//         { key: "Shop", text: "Shop" },
//         { key: "House Used For Commercial Purposes", text: "House Used For Commercial Purposes" },
//         { key: "Other", text: "Other" },
//         { key: "Multiple Dwellings (Under One Roof)", text: "Multiple Dwellings (Under One Roof)" }
//     ]

export const propertyTypeForOtherStateOptions: IDropdownOption[] =
    [
        { key: "", text: "Select" },
        { key: 1, text: "House" },
        { key: 3, text: "Strata Title" },
        { key: 4, text: "Vacant Land" },
        { key: 5, text: "Warehouse" },
        { key: 6, text: "Factory" },
        { key: 7, text: "Office" },
        { key: 8, text: "Shop" },
        { key: 9, text: "House Used For Commercial Purposes" },
        { key: 10, text: "Other" },
        { key: 11, text: "Multiple Dwellings (Under One Roof)" }
    ]

export enum IdentityType {
    Company = "Company",
    Individual = "Individual",
}

export enum TitleInfoType {
    Reference = "Reference",
    VolumeFolio = "VolumeFolio",
}

export const LargeWidthFormFieldStackItemStyles: IStackItemStyles = {
    root: {
        width: 530,
    },
};

export const MidLevelWidthFormFieldStackItemStyles: IStackItemStyles = {
    root: {
        width: 320,
    },
};

export enum RequestPolicySteps {
    NotStarted = -1,
    Questionnaire = 0,
    Confirmation = 1,
    Requested = 2,
    Success = 3,
    Failed = 4,
    StewartTitleNotConnected = 5
}

export enum PropertyState {
    ACT = "ACT",
    NSW = "NSW",
    TN = "TN",
    QLD = "QLD",
    SA = "SA",
    TAS = "TAS",
    VIC = "VIC",
    WA = "WA"
}

export enum PropertyType {
    Other = 10
}
export enum ZoningType {
    Other = 8
}