import React from "react";
import { MatterDetails } from "utils/wcaApiTypes";
import { formatToLocaleDateString } from "containers/calculators/settlement/Helper";
import { convertStringToDate } from "containers/calculators/settlement/Helper";

type props = {
    matterDetails: MatterDetails | undefined
}

const SettlementMatterDetails: React.FC<props> = ({ matterDetails }) => {
    
    return (
        <div className="section">
            <div className="section-body">
                <div className="ms-Grid-row state-row">
                    <div className="ms-Grid-col ms-sm10">
                        <div className="ms-Grid" dir="ltr">
                            <div className="ms-Grid-row detail-row">
                                <div className="ms-Grid-col ms-sm2">
                                    <b>Matter</b>
                                </div>
                                <div className="ms-Grid-col ms-sm10">
                                    {(matterDetails && matterDetails.matter && matterDetails.matter) || ""}
                                </div>
                            </div>

                            <div className="ms-Grid-row detail-row">
                                <div className="ms-Grid-col ms-sm2">
                                    <b>Property</b>
                                </div>
                                <div className="ms-Grid-col ms-sm10">
                                    {matterDetails && matterDetails.property}
                                </div>
                            </div>

                            <div className="ms-Grid-row detail-row">
                                <div className="ms-Grid-col ms-sm2">
                                    <b>Adjustment Date</b>
                                </div>
                                <div className="ms-Grid-col ms-sm10">
                                    {(matterDetails && matterDetails.adjustmentDate && formatToLocaleDateString(convertStringToDate(matterDetails.adjustmentDate.toString()))) || ""}
                                </div>
                            </div>

                            <div className="ms-Grid-row detail-row">
                                <div className="ms-Grid-col ms-sm2">
                                    <b>Settlement Date</b>
                                </div>
                                <div className="ms-Grid-col ms-sm10">
                                    {(matterDetails && matterDetails.settlementDate && formatToLocaleDateString(convertStringToDate(matterDetails.settlementDate.toString()))) || ""}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SettlementMatterDetails;