import * as React from 'react'
import { connect } from 'react-redux';

import { AppState, ReduxStatus } from 'app.types';
import {
    ActionstepMatterInfo,
} from 'utils/wcaApiTypes';

import { getCdmInformation } from 'containers/globalx/redux/actions';
import { setFullPage } from '../../../redux/common/actions';

import { MessageBar, MessageBarType } from 'office-ui-fabric-react/lib/MessageBar';
import ActionstepToGlobalX from 'containers/globalx/pages/components/actionstepToGlobalX'

import "containers/globalx/globalx.css"

type AppProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

type AppStates = {
    dataLoaded: boolean,
    gxUri: string,
    gxVersion: string | null,
    gxMatter: string | null,    // GlobalX Matter
    errorMessage: string | null,
}

interface ICdmIntegrationReadyMessage {
    data: {
        action: string,
        message: string
    },
    sourceOrigin: string,
    targetOrigin: string,
    sourceWindowName: string,
    targetWindowName: string
}

interface ICdmIntegrationMessage {
    data: {
        action: string,
        cdm: string | null,
        version: string | null
    }
}

export class GXOnline extends React.Component<AppProps, AppStates> {
    iframeRef: HTMLIFrameElement | null = null;

    constructor(props: AppProps) {
        super(props);

        this.state = {
            dataLoaded: false,
            gxUri: "",
            gxVersion: null,
            gxMatter: null,
            errorMessage: null
        }

        window.addEventListener("message", this.onReceiveMessage)
    }

    componentDidMount() {
        const { actionstepContext } = this.props;
        let matterInfo: ActionstepMatterInfo | null = null;

        if (actionstepContext === undefined || actionstepContext.matterContext === undefined) {
            this.setState({
                errorMessage: "No matter selected",
                gxUri: "/",
                dataLoaded: true
            });

            return;
        } else {
            matterInfo = new ActionstepMatterInfo({
                orgKey: actionstepContext.orgKey,
                matterId: actionstepContext.matterContext.id,
                termsEverAccepted: actionstepContext.termsEverAccepted ?? false,
                latestTermsAccepted: actionstepContext.latestTermsAccepted ?? false,
            });
        }

        const urlParams = new URLSearchParams(window.location.search);
        const entryPoint = urlParams.get('entryPoint') ?? '';
        const embed = urlParams.get('embed') === 'true' ? true : false;

        this.props.getCdmInformation({matterInfo, entryPoint, embed});
    }

    public componentDidUpdate(nextProps: AppProps): void {
        if (this.state.dataLoaded && !this.state.errorMessage && !this.props.fullPage)
        {
            this.props.setFullPage(true);
        }
    }

    static getDerivedStateFromProps(nextProps: AppProps, prevState: AppStates): AppStates {
        let nextState = {} as AppStates;

        if (nextProps.cdmInformation) {
            if (nextProps.cdmInformation.status === ReduxStatus.Success) {
                nextState.dataLoaded = true;
                nextState.gxUri = nextProps.cdmInformation.data!.gxUri!;
                nextState.gxMatter = nextProps.cdmInformation.data!.matter!;
                nextState.gxVersion = nextProps.cdmInformation.data!.version!;
            } else if (nextProps.cdmInformation.status === ReduxStatus.Failed) {
                nextState.dataLoaded = true;
                nextState.gxUri = "/";
                nextState.gxMatter = null;
                nextState.gxVersion = null;
                nextState.errorMessage = nextProps.cdmInformation.error!.message!;
            }
        }

        return nextState;
    }

    render(): JSX.Element {
        const { dataLoaded, gxUri, errorMessage } = this.state;

        return (
            <div className="gx-wrapper">
                {!dataLoaded ?
                    <ActionstepToGlobalX />
                    :
                    errorMessage ?
                        <MessageBar messageBarType={MessageBarType.error}>
                            {errorMessage}
                        </MessageBar>
                        :
                        <iframe src={gxUri}
                            ref={ref => this.iframeRef = ref}
                            onLoad={() => this.onLoadIframe()}
                            className="gx-iframe"
                            title="GlobalX Portal"
                        />
                }
            </div>
        )
    }

    onReceiveMessage = (event: MessageEvent) => {
        if (event.origin.includes("globalx.com.au")) {
            console.log("Message from GlobalX: ", event);

            try {
                const message: ICdmIntegrationReadyMessage = JSON.parse(event.data);

                if (message && message.data && message.data.action === "CdmIntegrationReady") {
                    if (this.iframeRef && this.iframeRef.contentWindow) {

                        const { gxMatter, gxVersion } = this.state;

                        const cdmMessage: ICdmIntegrationMessage = {
                            data: {
                                "action": "CdmIntegration",     // notify GlobalX to perform search integration
                                "cdm": gxMatter,                // search data (matter) for integration with GlobalX
                                "version": gxVersion            // search data version
                            }
                        };

                        this.iframeRef.contentWindow.postMessage(JSON.stringify(cdmMessage), '*');
                    }
                }
            } catch (e) {
                console.log('Konekta: Error populating form data', e);
            }
        } else { }
    }

    onLoadIframe = () => { }
}

const mapStateToProps = (state: AppState) => {
    return {
        actionstepContext: state.common.actionstepContext,
        cdmInformation: state.globalx.cdmInformation,
        fullPage: state.common.fullPage,
    }
}

const mapDispatchToProps = {
    getCdmInformation,
    setFullPage,
}

export default connect(mapStateToProps, mapDispatchToProps)(GXOnline);