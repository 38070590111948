
import * as CONSTANTS from './constants'
import {
    GeneratePDF,
    GeneratePDFSuccess,
    ActionFailed,
    SavePDF,
    SavePDFSuccess,
    SaveSpecialMeterReadingMatter,
    SaveSpecialMeterReadingMatterSuccess,
    DeleteSpecialMeterReadingMatter,
    DeleteSpecialMeterReadingMatterSuccess,
    GetSpecialMeterReadingMatter,
    GetSpecialMeterReadingMatterSuccess,
    ChangeState,
    ClearSpecialMeterReadingInfoState

} from './actionTypes'

import {
    ErrorViewModel,
    ActionstepDocument,
    ActionstepMatterInfo
} from 'utils/wcaApiTypes'
import { SpecialMeterReadingViewModel, SpecialMeterReadingMatter } from '../utils/specialMeterReadingWCAApiTypes'

export function generatePDF(params: SpecialMeterReadingViewModel): GeneratePDF {
    return {
        type: CONSTANTS.GENERATE_PDF_REQUESTED,
        params
    }
}

export function generatePDFSuccess(data: string): GeneratePDFSuccess {
    return {
        type: CONSTANTS.GENERATE_PDF_SUCCESS,
        data
    }
}

export function generatePDFFailed(error: ErrorViewModel): ActionFailed {
    return {
        type: CONSTANTS.GENERATE_PDF_FAILED,
        error
    }
}

export function savePDF(params: SpecialMeterReadingViewModel): SavePDF {
    return {
        type: CONSTANTS.SAVE_PDF_REQUESTED,
        params
    }
}

export function savePDFSuccess(data: ActionstepDocument): SavePDFSuccess {
    return {
        type: CONSTANTS.SAVE_PDF_SUCCESS,
        data
    }
}

export function savePDFFailed(error: ErrorViewModel): ActionFailed {
    return {
        type: CONSTANTS.SAVE_PDF_FAILED,
        error
    }
}

export function saveSpecialMeterReadingMatter(params: SpecialMeterReadingViewModel): SaveSpecialMeterReadingMatter {
    return {
        type: CONSTANTS.SAVE_SPECIAL_METER_READING_REQUESTED,
        params
    }
}

export function saveSpecialMeterReadingMatterSuccess(data: SpecialMeterReadingMatter): SaveSpecialMeterReadingMatterSuccess {
    return {
        type: CONSTANTS.SAVE_SPECIAL_METER_READING_SUCCESS,
        data
    }
}

export function saveSpecialMeterReadingMatterFailed(error: ErrorViewModel): ActionFailed {
    return {
        type: CONSTANTS.SAVE_SPECIAL_METER_READING_FAILED,
        error
    }
}

export function deleteSpecialMeterReadingMatter(params: ActionstepMatterInfo): DeleteSpecialMeterReadingMatter {
    return {
        type: CONSTANTS.DELETE_SPECIAL_METER_READING_REQUESTED,
        params
    }
}

export function deleteSpecialMeterReadingMatterSuccess(data: SpecialMeterReadingMatter): DeleteSpecialMeterReadingMatterSuccess {
    return {
        type: CONSTANTS.DELETE_SPECIAL_METER_READING_SUCCESS,
        data
    }
}

export function deleteSpecialMeterReadingMatterFailed(error: ErrorViewModel): ActionFailed {
    return {
        type: CONSTANTS.DELETE_SPECIAL_METER_READING_FAILED,
        error
    }
}

export function getSpecialMeterReadingMatter(params: ActionstepMatterInfo): GetSpecialMeterReadingMatter {
    return {
        type: CONSTANTS.GET_SPECIAL_METER_READING_REQUESTED,
        params
    }
}

export function getSpecialMeterReadingMatterSuccess(data: SpecialMeterReadingViewModel): GetSpecialMeterReadingMatterSuccess {
    return {
        type: CONSTANTS.GET_SPECIAL_METER_READING_SUCCESS,
        data
    }
}

export function getSpecialMeterReadingMatterFailed(error: ErrorViewModel): ActionFailed {
    return {
        type: CONSTANTS.GET_SPECIAL_METER_READING_FAILED,
        error
    }
}

export function changeState(data: string): ChangeState {
    return {
        type: CONSTANTS.CHANGE_STATE,
        data
    }
}

export function clearSpecialMeterReadingInfoState(): ClearSpecialMeterReadingInfoState {
    return {
        type: CONSTANTS.CLEAR_SPECIAL_METER_READING_INFO_STATE
    }
}