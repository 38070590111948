import * as React from 'react';
import * as IntegrationLink from "./integrationLinksConstants";

import {
    SubwayNavNodeState,
    ISubwayNavNodeProps,
    setSubwayState
} from 'components/SubwayNav'

import RetrieveFromActionstep from 'containers/pexa/steps/retrieveFromActionstep';
import RetrieveWorkspaceFromPexa from 'containers/pexa/steps/showWorkspaceSummary/retrieveWorkspaceFromPexa';
import UpdateSettlementDateTime from 'containers/pexa/steps/updateSettlementDateTimeSteps/updateSettlementDateTime';
import DataCheckByActionstepSDT from 'containers/pexa/steps/updateSettlementDateTimeSteps/dataCheckByActionstepSDT';
import DataCheckByPexaSDT from 'containers/pexa/steps/updateSettlementDateTimeSteps/dataCheckByPexaSDT';
import SettlementDateTimeUpdated from 'containers/pexa/steps/updateSettlementDateTimeSteps/settlementDateTimeUpdated';

import "./maintainUpdateSettlementDateTime.css"

interface IAppProps {
}

type AppProps = IAppProps;


export default class MaintainUpdateSettlementDateTime extends React.Component<AppProps, any> {   
    defaultSteps: ISubwayNavNodeProps[] = [];   

    constructor(props: AppProps) {
        super(props);
        
        this.defaultSteps = [
            {
                id: '0',
                label: 'Retrieve from Actionstep',
                state: SubwayNavNodeState.Current,
                onClickStep: this._handleClickStep,
                disabled: true
            },
            {
                id: '1',
                label: 'Retrieve Workspace From Pexa',
                state: SubwayNavNodeState.NotStarted,
                onClickStep: this._handleClickStep,
                disabled: true
            },
            {
                id: '2',
                label: 'Update Settlement Date Time',
                state: SubwayNavNodeState.NotStarted,
                onClickStep: this._handleClickStep,
                disabled: true
            },          
            {
                id: '3',
                label: 'Data Check By Actionstep For SDT ',
                state: SubwayNavNodeState.NotStarted,
                onClickStep: this._handleClickStep,
                disabled: true
            },
            {
                id: '4',
                label: 'Data Check By Pexa For SDT',
                state: SubwayNavNodeState.NotStarted,
                onClickStep: this._handleClickStep,
                disabled: true
            },
            {
                id: '5',
                label: 'Settlement Date Time Updated',
                state: SubwayNavNodeState.NotStarted,
                onClickStep: this._handleClickStep,
                disabled: true
            }       
        ];

        this.state = {
            steps: this.defaultSteps,
            currentStepId: 0,
            integrationLink: IntegrationLink.Maintain_Update_Settlement_Date_Time            
        };
    }

    public componentDidMount(): void {
    }

    private _handleClickStep = (step: ISubwayNavNodeProps): void => {
        this.setState({ ...setSubwayState(step, this.state.steps, this.state.currentStepId) });
    }

    private _onChangeStep = (newState: SubwayNavNodeState, newStep: number = -1): void => {
        let newSteps = [...this.state.steps];
        let currentStepId = this.state.currentStepId;

        if (newStep >= 0) {
            currentStepId = newStep;
        }
        for (let i = 0; i < currentStepId; i++) {
            newSteps[i].state = SubwayNavNodeState.Completed;
        }
        for (let i = currentStepId + 1; i < newSteps.length; i++) {
            newSteps[i].state = SubwayNavNodeState.NotStarted;
        }

        newSteps[currentStepId].state = newState;
        if (newState === SubwayNavNodeState.Completed && currentStepId < newSteps.length - 1) {
            currentStepId++;
            newSteps[currentStepId].state = SubwayNavNodeState.Current;
        }

        this.setState({
            steps: newSteps,
            currentStepId: currentStepId
        })

        window.scrollTo(0, 0);
    }

    render() {
        const { currentStepId } = this.state;

        return (
            <div>
                <div className="wrapper vertical-container wrapper-content animated fadeIn" dir="ltr">

                    <div className="ms-Grid-row create-pexa-workspace">           

                        <div className="create-pexa-content ms-Grid-col ms-lg9 ">
                            {currentStepId === 0 && <RetrieveFromActionstep integrationLink={this.state.integrationLink} onChangeStep={this._onChangeStep} />}

                            {currentStepId === 1 && <RetrieveWorkspaceFromPexa onChangeStep={this._onChangeStep} />}

                            {currentStepId === 2 && <UpdateSettlementDateTime onChangeStep={this._onChangeStep} />}                            
                                                                   
                            {currentStepId === 3 && <DataCheckByActionstepSDT onChangeStep={this._onChangeStep} />}

                            {currentStepId === 4 && <DataCheckByPexaSDT onChangeStep={this._onChangeStep} />}
                          
                            {currentStepId === 5 && <SettlementDateTimeUpdated/>}
                        
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}
