import { Party, WCAUser } from "utils/wcaApiTypes";

export interface IGetActionstepProviderList {
    leadProvider?: Party[] | null;
    provider2?: Party[] | null;
    otherProvider?: Party[] | null;
}

export class ActionstepProviderList implements IGetActionstepProviderList {
    leadProvider?: Party[] | null;
    provider2?: Party[] | null;
    otherProvider?: Party[] | null;


    constructor(data?: IGetActionstepProviderList) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }



    init(_data?: any) {
        if (_data) {
            if (Array.isArray(_data["leadProvider"])) {
                this.leadProvider = [] as any;
                for (let item of _data["leadProvider"])
                    this.leadProvider!.push(Party.fromJS(item));
            }
            if (Array.isArray(_data["provider2"])) {
                this.provider2 = [] as any;
                for (let item of _data["provider2"])
                    this.provider2!.push(Party.fromJS(item));
            }
            if (Array.isArray(_data["otherProvider"])) {
                this.otherProvider = [] as any;
                for (let item of _data["otherProvider"])
                    this.otherProvider!.push(Party.fromJS(item));
            }

        }
    }

    static fromJS(data: any): ActionstepProviderList {
        data = typeof data === 'object' ? data : {};
        let result = new ActionstepProviderList();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        if (Array.isArray(this.leadProvider)) {
            data["leadProvider"] = [];
            for (let item of this.leadProvider)
                data["leadProvider"].push(item.toJSON());
        }
        if (Array.isArray(this.provider2)) {
            data["provider2"] = [];
            for (let item of this.provider2)
                data["provider2"].push(item.toJSON());
        }
        if (Array.isArray(this.otherProvider)) {
            data["otherProvider"] = [];
            for (let item of this.otherProvider)
                data["otherProvider"].push(item.toJSON());
        }
        return data;
    }

}

export class LegalAidGetDataModel {
    formType: FormType | undefined;
    matterId: number | undefined;
    orgKey: string | undefined;
    dateFrom: string | undefined;
    dateTo: string | undefined;
}


export class LegalAidActionstepDataViewModel implements ILegalAidActionstepDataViewModel {
    legalAidFileNo?: string | undefined;
    invoiceRefNo?: string | undefined;
    gstNumber?: string | undefined;
    invoiceDate?: string | undefined;
    currentDate?: string | undefined;
    dxBoxNumber?: string | undefined;
    city?: string | undefined;
    customer?: string | undefined;
    waiNumber?: string | undefined;
    inquiry?: string | undefined;
    leadProvider?: string | undefined;
    lawFirm?: string | undefined;
    formType: FormType | undefined;
    comments?: string | undefined;
    form15Additionalcomments?: string | undefined;
    legalAidServiceApprove!: boolean;
    legalAidServiceFurtherInfo!: boolean;
    legalAidServiceRefuse!: boolean;
    providerDetails?: { [key in keyof typeof ProviderType]: ProviderDetail; } | undefined;
    claimDetails?: ClaimDetails | undefined;
    providerActivities?: ProviderActivity[] | undefined;
    providerTotal?: ProviderTotal | undefined;
    accountActivities?: AccountActivity[] | undefined;
    legalAidDisbursements?: LegalAidDisbursement[] | undefined;

    constructor(data?: ILegalAidActionstepDataViewModel) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.legalAidFileNo = _data["legalAidFileNo"];
            this.invoiceRefNo = _data["invoiceRefNo"];
            this.gstNumber = _data["gstNumber"];
            this.invoiceDate = _data["invoiceDate"];
            this.currentDate = _data["currentDate"];
            this.dxBoxNumber = _data["dxBoxNumber"];
            this.city = _data["city"];
            this.customer = _data["customer"];
            this.waiNumber = _data["waiNumber"];
            this.inquiry = _data["inquiry"];
            this.leadProvider = _data["leadProvider"];
            this.lawFirm = _data["lawFirm"];
            this.formType = _data["formType"];
            this.comments = _data["comments"];
            this.form15Additionalcomments = _data["form15Additionalcomments"];
            this.legalAidServiceApprove = _data["legalAidServiceApprove"];
            this.legalAidServiceFurtherInfo = _data["legalAidServiceFurtherInfo"];
            this.legalAidServiceRefuse = _data["legalAidServiceRefuse"];
            if (_data["providerDetails"]) {
                this.providerDetails = { LeadProvider: new ProviderDetail(), Provider2: new ProviderDetail(), Other: new ProviderDetail() };
                let leadProviderDetails = new ProviderDetail();
                let provider2Details = new ProviderDetail();
                let otherDetails = new ProviderDetail();
                for (let key in _data["providerDetails"]) {
                    if (_data["providerDetails"].hasOwnProperty(key)) {
                        if (key === "LeadProvider") {
                            leadProviderDetails = _data["providerDetails"][key] ? ProviderDetail.fromJS(_data["providerDetails"][key]) : new ProviderDetail();
                        } else if (key === "Provider2") {
                            provider2Details = _data["providerDetails"][key] ? ProviderDetail.fromJS(_data["providerDetails"][key]) : new ProviderDetail();
                        } else if (key === "Other") {
                            otherDetails = _data["providerDetails"][key] ? ProviderDetail.fromJS(_data["providerDetails"][key]) : new ProviderDetail();
                        }
                        this.providerDetails = {
                            LeadProvider: leadProviderDetails,
                            Provider2: provider2Details,
                            Other: otherDetails
                        };
                        //this.providerDetails![key] = _data["providerDetails"][key] ? ProviderDetail.fromJS(_data["providerDetails"][key]) : new ProviderDetail();
                    }
                }
            }
            this.claimDetails = _data["claimDetails"] ? ClaimDetails.fromJS(_data["claimDetails"]) : <any>undefined;
            if (Array.isArray(_data["providerActivities"])) {
                this.providerActivities = [] as any;
                for (let item of _data["providerActivities"])
                    this.providerActivities!.push(ProviderActivity.fromJS(item));
            }
            this.providerTotal = _data["providerTotal"] ? ProviderTotal.fromJS(_data["providerTotal"]) : <any>undefined;
            if (Array.isArray(_data["accountActivities"])) {
                this.accountActivities = [] as any;
                for (let item of _data["accountActivities"])
                    this.accountActivities!.push(AccountActivity.fromJS(item));
            }
            if (Array.isArray(_data["legalAidDisbursements"])) {
                this.legalAidDisbursements = [] as any;
                for (let item of _data["legalAidDisbursements"])
                    this.legalAidDisbursements!.push(LegalAidDisbursement.fromJS(item));
            }
        }
    }

    static fromJS(data: any): LegalAidActionstepDataViewModel {
        data = typeof data === 'object' ? data : {};
        let result = new LegalAidActionstepDataViewModel();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["legalAidFileNo"] = this.legalAidFileNo;
        data["invoiceRefNo"] = this.invoiceRefNo;
        data["gstNumber"] = this.gstNumber;
        data["invoiceDate"] = this.invoiceDate;
        data["currentDate"] = this.invoiceDate;
        data["dxBoxNumber"] = this.dxBoxNumber;
        data["city"] = this.city;
        data["customer"] = this.customer;
        data["waiNumber"] = this.waiNumber;
        data["inquiry"] = this.inquiry;
        data["leadProvider"] = this.leadProvider;
        data["lawFirm"] = this.lawFirm;
        data["formType"] = this.formType;
        data["comments"] = this.comments;
        data["form15Additionalcomments"] = this.form15Additionalcomments;
        data["legalAidServiceApprove"] = this.legalAidServiceApprove;
        data["legalAidServiceFurtherInfo"] = this.legalAidServiceFurtherInfo;
        data["legalAidServiceRefuse"] = this.legalAidServiceRefuse;
        if (this.providerDetails) {
            data["providerDetails"] = {};
            for (let key in this.providerDetails) {
                if (this.providerDetails.hasOwnProperty(key))
                    if (key === "LeadProvider") {
                        data["providerDetails"][key] = this.providerDetails.LeadProvider ? this.providerDetails.LeadProvider.toJSON() : new ProviderDetail();
                    }
                if (key === "Provider2") {
                    data["providerDetails"][key] = this.providerDetails.Provider2 ? this.providerDetails.Provider2.toJSON() : new ProviderDetail();
                }
                if (key === "Other") {
                    data["providerDetails"][key] = this.providerDetails.Other ? this.providerDetails.Other.toJSON() : new ProviderDetail();
                }
                // data["providerDetails"][key] = this.providerDetails[key] ? this.providerDetails[key].toJSON() : <any>undefined;
            }
        }
        data["claimDetails"] = this.claimDetails ? this.claimDetails.toJSON() : <any>undefined;
        if (Array.isArray(this.providerActivities)) {
            data["providerActivities"] = [];
            for (let item of this.providerActivities)
                data["providerActivities"].push(item.toJSON());
        }
        data["providerTotal"] = this.providerTotal ? this.providerTotal.toJSON() : <any>undefined;
        if (Array.isArray(this.accountActivities)) {
            data["accountActivities"] = [];
            for (let item of this.accountActivities)
                data["accountActivities"].push(item.toJSON());
        }
        if (Array.isArray(this.legalAidDisbursements)) {
            data["legalAidDisbursements"] = [];
            for (let item of this.legalAidDisbursements)
                data["legalAidDisbursements"].push(item.toJSON());
        }
        return data;
    }
}

export interface ILegalAidActionstepDataViewModel {
    legalAidFileNo?: string | undefined;
    invoiceRefNo?: string | undefined;
    gstNumber?: string | undefined;
    invoiceDate?: string | undefined;
    currentDate?: string | undefined;
    dxBoxNumber?: string | undefined;
    city?: string | undefined;
    customer?: string | undefined;
    waiNumber?: string | undefined;
    inquiry?: string | undefined;
    leadProvider?: string | undefined;
    lawFirm?: string | undefined;
    formType: FormType | undefined;
    form15Additionalcomments?: string | undefined;
    comments?: string | undefined;
    legalAidServiceApprove: boolean;
    legalAidServiceFurtherInfo: boolean;
    legalAidServiceRefuse: boolean;
    providerDetails?: { [key in keyof typeof ProviderType]: ProviderDetail; } | undefined;
    claimDetails?: ClaimDetails | undefined;
    providerActivities?: ProviderActivity[] | undefined;
    providerTotal?: ProviderTotal | undefined;
    accountActivities?: AccountActivity[] | undefined;
    legalAidDisbursements?: LegalAidDisbursement[] | undefined;
}

export enum ProviderType {
    LeadProvider = "LeadProvider",
    Provider2 = "Provider2",
    Other = "Other",
}
export enum FormType {
    Form15 = "Form15",
    Form16 = "Form16"
}

export class ProviderDetail implements IProviderDetail {
    providerNumber?: string | undefined;
    providerExperience?: ProviderExperienceType | undefined;
    providerRate!: string | undefined;
    providerSUP!: boolean;

    constructor(data?: IProviderDetail) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.providerNumber = _data["providerNumber"];
            this.providerExperience = _data["providerExperience"];
            this.providerRate = _data["providerRate"];
            this.providerSUP = _data["providerSUP"];
        }
    }

    static fromJS(data: any): ProviderDetail {
        data = typeof data === 'object' ? data : {};
        let result = new ProviderDetail();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["providerNumber"] = this.providerNumber;
        data["providerExperience"] = this.providerExperience;
        data["providerRate"] = this.providerRate;
        data["providerSUP"] = this.providerSUP;
        return data;
    }
}

export interface IProviderDetail {
    providerNumber?: string | undefined;
    providerExperience?: ProviderExperienceType | undefined;
    providerRate: string | undefined;
    providerSUP: boolean;
}

export enum ProviderExperienceType {
    Level1 = "Level1",
    Level2 = "Level2",
    Level3 = "Level3",
}

export class ClaimDetails implements IClaimDetails {
    coverPeriodFrom?: string | undefined;
    coverPeriodTo?: string | undefined;
    interimInvoice!: boolean;
    finalInvoice!: boolean;
    stage1CaseBook!: boolean;
    stage2Interlocutory!: boolean;
    stage3Hearing!: boolean;

    constructor(data?: IClaimDetails) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.coverPeriodFrom = _data["coverPeriodFrom"];
            this.coverPeriodTo = _data["coverPeriodTo"];
            this.interimInvoice = _data["interimInvoice"];
            this.finalInvoice = _data["finalInvoice"];
            this.stage1CaseBook = _data["stage1CaseBook"];
            this.stage2Interlocutory = _data["stage2Interlocutory"];
            this.stage3Hearing = _data["stage3Hearing"];
        }
    }

    static fromJS(data: any): ClaimDetails {
        data = typeof data === 'object' ? data : {};
        let result = new ClaimDetails();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["coverPeriodFrom"] = this.coverPeriodFrom;
        data["coverPeriodTo"] = this.coverPeriodTo;
        data["interimInvoice"] = this.interimInvoice;
        data["finalInvoice"] = this.finalInvoice;
        data["stage1CaseBook"] = this.stage1CaseBook;
        data["stage2Interlocutory"] = this.stage2Interlocutory;
        data["stage3Hearing"] = this.stage3Hearing;
        return data;
    }
}

export interface IClaimDetails {
    coverPeriodFrom?: string | undefined;
    coverPeriodTo?: string | undefined;
    interimInvoice: boolean;
    finalInvoice: boolean;
    stage1CaseBook: boolean;
    stage2Interlocutory: boolean;
    stage3Hearing: boolean;
}

export class ProviderActivity implements IProviderActivity {
    activityType!: ActivityType;
    activityDescription?: string | undefined;
    totalLeadProviderHours!: number;
    totalProvider2Hours!: number;
    totalOtherHours!: number;
    activityTotal!: number;

    constructor(data?: IProviderActivity) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.activityType = _data["activityType"];
            this.activityDescription = _data["activityDescription"];
            this.totalLeadProviderHours = _data["totalLeadProviderHours"];
            this.totalProvider2Hours = _data["totalProvider2Hours"];
            this.totalOtherHours = _data["totalOtherHours"];
            this.activityTotal = _data["activityTotal"].toFixed(2);
        }
    }

    static fromJS(data: any): ProviderActivity {
        data = typeof data === 'object' ? data : {};
        let result = new ProviderActivity();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["activityType"] = this.activityType;
        data["activityDescription"] = this.activityDescription;
        data["totalLeadProviderHours"] = this.totalLeadProviderHours;
        data["totalProvider2Hours"] = this.totalProvider2Hours;
        data["totalOtherHours"] = this.totalOtherHours;
        data["activityTotal"] = this.activityTotal;
        return data;
    }
}

export interface IProviderActivity {
    activityType: ActivityType;
    activityDescription?: string | undefined;
    totalLeadProviderHours: number;
    totalProvider2Hours: number;
    totalOtherHours: number;
    activityTotal: number;
}

export enum ActivityType {
    Preparation = "Preparation",
    Attendances = "Attendances",
    ReadingResearchReports = "ReadingResearchReports",
    DocumentDrafting = "DocumentDrafting",
    OtherActivities = "OtherActivities",
}

export class ProviderTotal implements IProviderTotal {
    totalFee!: number;
    totalDisbursements!: number;
    totalGST!: number;
    totalMileage!: number;
    totalAmount!: number;

    constructor(data?: IProviderTotal) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.totalFee = _data["totalFee"].toFixed(2);
            this.totalDisbursements = _data["totalDisbursements"].toFixed(2);
            this.totalGST = _data["totalGST"].toFixed(2);
            this.totalMileage = _data["totalMileage"].toFixed(2);
            this.totalAmount = _data["totalAmount"].toFixed(2);
        }
    }

    static fromJS(data: any): ProviderTotal {
        data = typeof data === 'object' ? data : {};
        let result = new ProviderTotal();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["totalFee"] = this.totalFee;
        data["totalDisbursements"] = this.totalDisbursements;
        data["totalGST"] = this.totalGST;
        data["totalMileage"] = this.totalMileage;
        data["totalAmount"] = this.totalAmount;
        return data;
    }
}

export interface IProviderTotal {
    totalFee: number;
    totalDisbursements: number;
    totalGST: number;
    totalMileage: number;
    totalAmount: number;
}

export class AccountActivity implements IAccountActivity {
    activityType?: ActivityType | undefined;
    activityDescription?: string | undefined;
    leadProviderHours!: number;
    provider2Hours!: number;
    otherHours!: number;
    total!: number;

    constructor(data?: IAccountActivity) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.activityType = _data["activityType"];
            this.activityDescription = _data["activityDescription"];
            this.leadProviderHours = _data["leadProviderHours"];
            this.provider2Hours = _data["provider2Hours"];
            this.otherHours = _data["otherHours"];
            this.total = _data["total"].toFixed(2);
        }
    }

    static fromJS(data: any): AccountActivity {
        data = typeof data === 'object' ? data : {};
        let result = new AccountActivity();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["activityType"] = this.activityType;
        data["activityDescription"] = this.activityDescription;
        data["leadProviderHours"] = this.leadProviderHours;
        data["provider2Hours"] = this.provider2Hours;
        data["otherHours"] = this.otherHours;
        data["total"] = this.total.toFixed(2);
        return data;
    }
}

export interface IAccountActivity {
    activityType?: ActivityType | undefined;
    activityDescription?: string | undefined;
    leadProviderHours: number;
    provider2Hours: number;
    otherHours: number;
    total: number;
}

export class LegalAidDisbursement implements ILegalAidDisbursement {
    description?: string | undefined;
    units!: number;
    total!: number;

    constructor(data?: ILegalAidDisbursement) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.description = _data["description"];
            this.units = _data["units"];
            this.total = _data["total"].toFixed(2);
        }
    }

    static fromJS(data: any): LegalAidDisbursement {
        data = typeof data === 'object' ? data : {};
        let result = new LegalAidDisbursement();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["description"] = this.description;
        data["units"] = this.units;
        data["total"] = this.total;
        return data;
    }
}

export interface ILegalAidDisbursement {
    description?: string | undefined;
    units: number;
    total: number;
}

export class GeneratePDFLegalAidCommand implements IGeneratePDFLegalAidCommand {
    legalAidActionstepDataViewModel?: LegalAidActionstepDataViewModel | undefined;
    actionstepOrgKey?: string | undefined;
    matterId!: number;
    fileName: string | undefined;
    authenticatedUser?: WCAUser | undefined;

    constructor(data?: IGeneratePDFLegalAidCommand) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.legalAidActionstepDataViewModel = _data["legalAidActionstepDataViewModel"] ? LegalAidActionstepDataViewModel.fromJS(_data["legalAidActionstepDataViewModel"]) : <any>undefined;
            this.actionstepOrgKey = _data["actionstepOrgKey"];
            this.matterId = _data["matterId"];
            this.fileName = _data["filename"];
            this.authenticatedUser = _data["authenticatedUser"] ? WCAUser.fromJS(_data["authenticatedUser"]) : <any>undefined;
        }
    }

    static fromJS(data: any): GeneratePDFLegalAidCommand {
        data = typeof data === 'object' ? data : {};
        let result = new GeneratePDFLegalAidCommand();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["legalAidActionstepDataViewModel"] = this.legalAidActionstepDataViewModel ? this.legalAidActionstepDataViewModel.toJSON() : <any>undefined;
        data["actionstepOrgKey"] = this.actionstepOrgKey;
        data["matterId"] = this.matterId;
        data["fileName"] = this.fileName;
        data["authenticatedUser"] = this.authenticatedUser ? this.authenticatedUser.toJSON() : <any>undefined;
        return data;
    }
}

export interface IGeneratePDFLegalAidCommand {
    legalAidActionstepDataViewModel?: LegalAidActionstepDataViewModel | undefined;
    actionstepOrgKey?: string | undefined;
    matterId: number;
    fileName: string | undefined;
    authenticatedUser?: WCAUser | undefined;
}