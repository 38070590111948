import React, { FC, useState } from "react"
import { PrimaryButton, Separator, Toggle, DatePicker, DayOfWeek } from 'office-ui-fabric-react'
import {
    stateOfCountryOptions, interestBeingTransferredOptions, natureOfPropertyOptions, tenureTypeOptions, zoningTypeOptions,
    IdentityType, ZoningType, PropertyType
} from "./constants";
import { StateName } from "components/common/constants";
import { ResponseError, StewartTitleActionstepMatter } from "../utils/stewartTitleWCAApiTypes";
import { _onFormatDateDDMMYYYY } from "utils/dataFormatter";
import Tools from "utils/tools";
import { stateName } from "containers/calculators/settlement/common";
import { getPropertyType } from "../helper";
import { Box, Checkbox, FormControlLabel, FormHelperText, Grid, Input, InputLabel, MuiThemeProvider, Select, TextField, Typography } from "@material-ui/core";
import { ITextStyles } from "@fluentui/react";
import Alert from "@material-ui/lab/Alert";
import { createMuiTheme } from "@material-ui/core";

interface Props {
    placeOrder: (stewartTitleActionstepMatter: StewartTitleActionstepMatter) => void;
    actionstepMatter: StewartTitleActionstepMatter | undefined;
    policyRequestError: ResponseError[] | undefined
}
const customMuiTheme = createMuiTheme({
    typography: {
        fontFamily: 'Poppins, sans-serif',
    }
});

const QuestionnaireComponent: FC<Props> = (props) => {
    const datepickerStyle: ITextStyles = {
        root: {
            paddingTop: '17px'
        }
    };

    // initialise title folio as unit no if not exist
    const [stewartTitleActionstepMatter, setstewartTitleActionstepMatter] = useState(props.actionstepMatter ?? new StewartTitleActionstepMatter);

    function checkFormDataValidation() {
        if (props.actionstepMatter) {
            const party1 = stewartTitleActionstepMatter.stewartTitleParty?.[0];
            const party2 = stewartTitleActionstepMatter.stewartTitleParty?.[1];

            if (Tools.isNullOrEmptyOrUndefined(stewartTitleActionstepMatter.referrerAndTransaction?.conveyancingFirm)) {
                return true;
            }
            else if (Tools.isNullOrEmptyOrUndefined(stewartTitleActionstepMatter.referrerAndTransaction?.conveyancer)) {
                return true;
            }
            else if (Tools.isNullOrEmptyOrUndefined(stewartTitleActionstepMatter.referrerAndTransaction?.policyReference)) {
                return true;
            }
            else if (party1?.identityType === IdentityType.Individual && Tools.isNullOrEmptyOrUndefined(party1?.firstName)) {
                return true;
            }
            else if (party1?.identityType === IdentityType.Individual && Tools.isNullOrEmptyOrUndefined(party1?.lastName)) {
                return true;
            }
            else if (party1?.identityType === IdentityType.Company && Tools.isNullOrEmptyOrUndefined(party1?.companyName)) {
                return true;
            }
            else if (party2?.identityType === IdentityType.Individual && Tools.isNullOrEmptyOrUndefined(party2?.firstName)) {
                return true;
            }
            else if (party2?.identityType === IdentityType.Individual && Tools.isNullOrEmptyOrUndefined(party2?.lastName)) {
                return true;
            }
            else if (party2?.identityType === IdentityType.Company && Tools.isNullOrEmptyOrUndefined(party2?.companyName)) {
                return true;
            }
            else if (Tools.isNullOrEmptyOrUndefined(stewartTitleActionstepMatter.stewartTitleProperty?.purchasePrice?.toString())) {
                return true;
            }
            else if (Tools.isNullOrEmptyOrUndefined(stewartTitleActionstepMatter.stewartTitleProperty?.settlementDate)) {
                return true;
            }
            else if (Tools.isNullOrEmptyOrUndefined(stewartTitleActionstepMatter.stewartTitleReferences?.titleVolume)) {
                return true;
            }
            else if (stewartTitleActionstepMatter.stewartTitleProperty?.stateProvince !== stateName.QLD && Tools.isNullOrEmptyOrUndefined(stewartTitleActionstepMatter.stewartTitleReferences?.titleFolio)) {
                return true;
            }
            else if (Tools.isNullOrEmptyOrUndefined(stewartTitleActionstepMatter.stewartTitleProperty?.propertyAddress)) {
                return true;
            }
            else if (Tools.isNullOrEmptyOrUndefined(stewartTitleActionstepMatter.stewartTitleProperty?.city)) {
                return true;
            }
            else if (Tools.isNullOrEmptyOrUndefined(stewartTitleActionstepMatter.stewartTitleProperty?.stateProvince)) {
                return true;
            }
            else if (Tools.isNumberNullOrEmptyOrUndefined(stewartTitleActionstepMatter.stewartTitleProperty?.tenureTypeId)) {
                return true;
            }
            else if (Tools.isNumberNullOrEmptyOrUndefined(stewartTitleActionstepMatter.stewartTitleProperty?.zoningTypeId)) {
                return true;
            }
            else if (Tools.isNumberNullOrEmptyOrUndefined(stewartTitleActionstepMatter.stewartTitleProperty?.propertyTypeId)) {
                return true;
            }
            else if (!stewartTitleActionstepMatter.stewartTitleRequestPolicyOptions?.isTitleSearchChecked) {
                return true;
            }
            else if (!stewartTitleActionstepMatter.stewartTitleRequestPolicyOptions?.isTermsAndConditionAccepted) {
                return true;
            }

            if (stewartTitleActionstepMatter.stewartTitleRequestPolicyOptions.isThrereAnyKnownRisk) {
                if (Tools.isNullOrEmptyOrUndefined(stewartTitleActionstepMatter.stewartTitleRequestPolicyOptions.disclosureDetails) || Tools.isNullOrEmptyOrUndefined(stewartTitleActionstepMatter.attachment?.fileName)) {
                    return true;
                }
            }
            return false;
        }
        return true;
    }
    let isFormDataInvalid = checkFormDataValidation();
    //let isFormDataInvalid = false;

    const updateInformation = (keyPath: string, newValue: any) => {
        let clonedActionstepMatter = stewartTitleActionstepMatter;
        Tools.createOrassign(clonedActionstepMatter, keyPath, newValue);
        setstewartTitleActionstepMatter(StewartTitleActionstepMatter.fromJS(clonedActionstepMatter));
    };
    const updateDropdown = (keyPath: string, newValue: any) => {
        let clonedActionstepMatter = stewartTitleActionstepMatter;
        Tools.createOrassign(clonedActionstepMatter, keyPath, newValue);
        setstewartTitleActionstepMatter(StewartTitleActionstepMatter.fromJS(clonedActionstepMatter));
    };

    const getBase64 = async (file: File) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        let clonedActionstepMatter = stewartTitleActionstepMatter;
        if (event.target.files && event.target.files?.length > 0) {
            const file = event.target.files[0];
            /* reader.onload = () => {
                 const base64Data = reader.result;
             };*/

            let base64String = await getBase64(file);
            Tools.assign(clonedActionstepMatter, "attachment.fileName", file.name);
            Tools.assign(clonedActionstepMatter, "attachment.contentType", file.type);
            Tools.assign(clonedActionstepMatter, "attachment.fileContentBase64", base64String);
        }
        else {
            Tools.assign(clonedActionstepMatter, "attachment.fileName", "");
            Tools.assign(clonedActionstepMatter, "attachment.contentType", "");
            Tools.assign(clonedActionstepMatter, "attachment.fileContentBase64", "");
        }
        setstewartTitleActionstepMatter(StewartTitleActionstepMatter.fromJS(clonedActionstepMatter));
    }

    const renderReferrerDetails = () => {
        return (
            <>
                <Typography className="main-heading" variant="subtitle1" gutterBottom>
                    Referrer & Transaction Details
                </Typography>
                <Grid container spacing={2}>
                    <Grid item >
                        <InputLabel>Conveyancing Firm</InputLabel>
                        <TextField className="stewarttitle-disabled st-textfield" variant="outlined"
                            margin="normal"
                            error={Tools.isNullOrEmptyOrUndefined(stewartTitleActionstepMatter.referrerAndTransaction?.conveyancingFirm)}
                            helperText={Tools.isNullOrEmptyOrUndefined(stewartTitleActionstepMatter.referrerAndTransaction?.conveyancingFirm) ? "Conveyancing firm is required" : ""}
                            value={stewartTitleActionstepMatter.referrerAndTransaction?.conveyancingFirm}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => updateInformation("referrerAndTransaction.conveyancingFirm", event.target.value)}
                        />
                    </Grid>
                    <Grid item   >
                        <InputLabel >Conveyancer</InputLabel>
                        <TextField
                            className="stewarttitle-disabled st-textfield" variant="outlined"
                            margin="normal"
                            error={Tools.isNullOrEmptyOrUndefined(stewartTitleActionstepMatter?.referrerAndTransaction?.conveyancer) ? true : false}
                            helperText={Tools.isNullOrEmptyOrUndefined(stewartTitleActionstepMatter?.referrerAndTransaction?.conveyancer) ? "Conveyancer is required" : ""}
                            value={stewartTitleActionstepMatter.referrerAndTransaction?.conveyancer}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => updateInformation("referrerAndTransaction.conveyancer", event.target.value)}
                        />
                    </Grid>
                    <Grid item >
                        <InputLabel >Policy Reference</InputLabel>
                        <TextField
                            className="stewarttitle-disabled st-textfield" variant="outlined"
                            margin="normal"
                            error={Tools.isNullOrEmptyOrUndefined(stewartTitleActionstepMatter.referrerAndTransaction?.policyReference) ? true : false}
                            helperText={Tools.isNullOrEmptyOrUndefined(stewartTitleActionstepMatter.referrerAndTransaction?.policyReference) ? "Policy reference is required" : ""}
                            value={stewartTitleActionstepMatter.referrerAndTransaction?.policyReference}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => updateInformation("referrerAndTransaction.policyReference", event.target.value)}
                        />
                    </Grid>
                </Grid>
            </>
        )
    }

    const renderParty1 = () => {
        return (
            <>
                <Typography className="st-main-heading-subtitle" gutterBottom>
                    Party 1
                </Typography>
                <Grid container spacing={2}>
                    <Grid item sm={4}>
                        <InputLabel >Client First Name</InputLabel>
                        <TextField
                            margin="normal"
                            className="stewarttitle-disabled st-textfield" variant="outlined"
                            error={stewartTitleActionstepMatter?.stewartTitleParty?.[0]?.identityType === IdentityType.Individual && Tools.isNullOrEmptyOrUndefined(stewartTitleActionstepMatter.stewartTitleParty?.[0]?.firstName) ? true : false}
                            helperText={stewartTitleActionstepMatter?.stewartTitleParty?.[0]?.identityType === IdentityType.Individual && Tools.isNullOrEmptyOrUndefined(stewartTitleActionstepMatter.stewartTitleParty?.[0]?.firstName) ? "Client first name is required" : ""}
                            value={stewartTitleActionstepMatter.stewartTitleParty?.[0]?.firstName || ''}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => updateInformation(`stewartTitleParty.[${0}].firstName`, event.target.value)} />
                    </Grid>
                    <Grid item sm={4}>
                        <InputLabel >Client Middle Name</InputLabel>
                        <TextField margin="normal"
                            className="stewarttitle-disabled st-textfield" variant="outlined"
                            value={stewartTitleActionstepMatter.stewartTitleParty?.[0]?.middleName || ''}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => updateInformation(`stewartTitleParty.[${0}].middleName`, event.target.value)} />
                    </Grid>
                    <Grid item sm={4}>
                        <InputLabel >Client Last Name</InputLabel>
                        <TextField margin="normal"
                            className="stewarttitle-disabled st-textfield" variant="outlined"
                            error={stewartTitleActionstepMatter?.stewartTitleParty?.[0]?.identityType === IdentityType.Individual && stewartTitleActionstepMatter.stewartTitleParty && Tools.isNullOrEmptyOrUndefined(stewartTitleActionstepMatter.stewartTitleParty[0]?.lastName) ? true : false}
                            helperText={stewartTitleActionstepMatter?.stewartTitleParty?.[0]?.identityType === IdentityType.Individual && stewartTitleActionstepMatter.stewartTitleParty && Tools.isNullOrEmptyOrUndefined(stewartTitleActionstepMatter.stewartTitleParty[0]?.lastName) ? "Client last name is required" : ""}
                            value={stewartTitleActionstepMatter.stewartTitleParty?.[0]?.lastName || ''}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => updateInformation(`stewartTitleParty.[${0}].lastName`, event.target.value)} />
                    </Grid>
                    <Grid item sm={4}>
                        <InputLabel >Company Name (if applicable)</InputLabel>
                        <TextField margin="normal"
                            className="stewarttitle-disabled st-textfield" variant="outlined"
                            error={stewartTitleActionstepMatter.stewartTitleParty?.[0]?.identityType === IdentityType.Company &&
                                Tools.isNullOrEmptyOrUndefined(stewartTitleActionstepMatter.stewartTitleParty[0]?.companyName) ? true : false}
                            helperText={stewartTitleActionstepMatter.stewartTitleParty?.[0]?.identityType === IdentityType.Company &&
                                Tools.isNullOrEmptyOrUndefined(stewartTitleActionstepMatter.stewartTitleParty[0]?.companyName) ? "Company name is required" : ""}
                            value={stewartTitleActionstepMatter.stewartTitleParty?.[0]?.companyName || ""}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => updateInformation(`stewartTitleParty.[${0}].companyName`, event.target.value)} />
                    </Grid>
                    <Grid item sm={4}>
                        <InputLabel >ABN/ACN (if applicable)</InputLabel>
                        <TextField margin="normal"
                            className="stewarttitle-disabled st-textfield" variant="outlined"
                            value={stewartTitleActionstepMatter.stewartTitleParty && stewartTitleActionstepMatter.stewartTitleParty?.length > 0 && stewartTitleActionstepMatter.stewartTitleParty[0] ? stewartTitleActionstepMatter.stewartTitleParty[0]?.abn : ""}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => updateInformation(`stewartTitleParty.[${0}].abn`, event.target.value)} />
                    </Grid>
                    <Grid item sm={4}>
                        <InputLabel >Client Email</InputLabel>
                        <TextField margin="normal"
                            className="stewarttitle-disabled st-textfield" variant="outlined"
                            value={stewartTitleActionstepMatter.stewartTitleParty?.[0]?.emailAddress}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => updateInformation(`stewartTitleParty.${0}.emailAddress`, event.target.value)} />
                    </Grid>
                    <Grid item sm={4}>
                        <InputLabel >Client Mobile</InputLabel>
                        <TextField margin="normal"
                            className="stewarttitle-disabled st-textfield" variant="outlined"
                            value={stewartTitleActionstepMatter.stewartTitleParty && stewartTitleActionstepMatter.stewartTitleParty?.length > 0 && stewartTitleActionstepMatter.stewartTitleParty[0] ? stewartTitleActionstepMatter.stewartTitleParty[0]?.phoneNumber : ""}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => updateInformation(`stewartTitleParty.[${0}].phoneNumber`, event.target.value)} />
                    </Grid>
                </Grid></>

        )
    }

    const renderParty2 = () => {
        return (
            <>
                <Typography className="main-heading" variant="subtitle1" gutterBottom>
                    Party 2 (if applicable)
                </Typography>
                <Grid container spacing={2}>

                    <Grid item sm={4}>
                        <InputLabel >Client First Name</InputLabel>
                        <TextField margin="normal"
                            className="stewarttitle-disabled st-textfield" variant="outlined"
                            error={stewartTitleActionstepMatter?.stewartTitleParty?.[1]?.identityType === IdentityType.Individual && Tools.isNullOrEmptyOrUndefined(stewartTitleActionstepMatter.stewartTitleParty?.[1]?.firstName) ? true : false}
                            helperText={stewartTitleActionstepMatter?.stewartTitleParty?.[1]?.identityType === IdentityType.Individual && Tools.isNullOrEmptyOrUndefined(stewartTitleActionstepMatter.stewartTitleParty?.[1]?.firstName) ? "Client first name is required" : ""}
                            value={stewartTitleActionstepMatter.stewartTitleParty?.[1]?.firstName}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => updateInformation(`stewartTitleParty.[${1}].firstName`, event.target.value)} />
                    </Grid>
                    <Grid item sm={4}>
                        <InputLabel >Client Middle Name</InputLabel>
                        <TextField margin="normal"
                            className="stewarttitle-disabled st-textfield" variant="outlined"
                            value={stewartTitleActionstepMatter.stewartTitleParty?.[1]?.middleName}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => updateInformation(`stewartTitleParty.[${1}].middleName`, event.target.value)} />
                    </Grid>
                    <Grid item sm={4}>
                        <InputLabel >Client Last Name</InputLabel>
                        <TextField margin="normal"
                            className="stewarttitle-disabled st-textfield" variant="outlined"
                            error={stewartTitleActionstepMatter?.stewartTitleParty?.[1]?.identityType === IdentityType.Individual && stewartTitleActionstepMatter.stewartTitleParty && Tools.isNullOrEmptyOrUndefined(stewartTitleActionstepMatter.stewartTitleParty[1]?.lastName) ? true : false}
                            helperText={stewartTitleActionstepMatter?.stewartTitleParty?.[1]?.identityType === IdentityType.Individual && stewartTitleActionstepMatter.stewartTitleParty && Tools.isNullOrEmptyOrUndefined(stewartTitleActionstepMatter.stewartTitleParty[1]?.lastName) ? "Client last name is required" : ""}
                            value={stewartTitleActionstepMatter.stewartTitleParty?.[1]?.lastName}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => updateInformation(`stewartTitleParty.[${1}].lastName`, event.target.value)} />
                    </Grid>

                    <Grid item sm={4}>
                        <InputLabel >Client Email</InputLabel>
                        <TextField margin="normal"
                            className="stewarttitle-disabled st-textfield" variant="outlined"
                            value={stewartTitleActionstepMatter.stewartTitleParty?.[1]?.emailAddress}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => updateInformation(`stewartTitleParty.${1}.emailAddress`, event.target.value)} />
                    </Grid>
                    <Grid item sm={4}>
                        <InputLabel >Client Mobile</InputLabel>
                        <TextField margin="normal"
                            className="stewarttitle-disabled st-textfield" variant="outlined"
                            value={stewartTitleActionstepMatter.stewartTitleParty && stewartTitleActionstepMatter.stewartTitleParty?.length > 0 && stewartTitleActionstepMatter.stewartTitleParty[1] ? stewartTitleActionstepMatter.stewartTitleParty[1]?.phoneNumber : ""}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => updateInformation(`stewartTitleParty.[${1}].phoneNumber`, event.target.value)} />
                    </Grid>
                </Grid></>
        )
    }

    const renderInsuredDetails = () => {
        return (
            <><Typography className="main-heading" variant="subtitle1" gutterBottom>
                Insured Details
            </Typography>
                <Grid item>
                    {renderParty1()}
                    {renderParty2()}
                </Grid>
            </>
        )

    }

    const renderPropertyDetails = () => {
        const stateOptions = stateOfCountryOptions.map((option) => {
            return <option value={option.key}>{option.text}</option>
        })
        const interestTransferOptions = interestBeingTransferredOptions.map((option) => {
            return <option value={option.key}>{option.text}</option>
        })
        const tenureOptions = tenureTypeOptions.map((option) => {
            return <option value={option.key}>{option.text}</option>
        })
        const naturePropertyOptions = natureOfPropertyOptions.map((option) => {
            return <option value={option.key}>{option.text}</option>
        })
        const zoningOptions = zoningTypeOptions.map((option) => {
            return <option value={option.key}>{option.text}</option>
        })
        return (
            <><Typography className="main-heading" variant="subtitle1" gutterBottom>
                Property Details
            </Typography>
                <Grid container spacing={2}>
                    <Grid item sm={4}>
                        <InputLabel>Purchase Price</InputLabel>
                        <TextField margin="normal"
                            className="st-textfield"
                            variant="outlined"
                            type="number"
                            error={Tools.isNullOrEmptyOrUndefined(stewartTitleActionstepMatter.stewartTitleProperty?.purchasePrice?.toString()) ? true : false}
                            helperText={Tools.isNullOrEmptyOrUndefined(stewartTitleActionstepMatter.stewartTitleProperty?.purchasePrice?.toString()) ? "Purchase price is required" : ""}
                            value={stewartTitleActionstepMatter.stewartTitleProperty?.purchasePrice?.toString()}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => updateInformation("stewartTitleProperty.purchasePrice", event.target.value)}
                        />
                    </Grid>
                    <Grid item sm={4}>
                        <InputLabel>Anticipated Settlement Date</InputLabel>
                        <DatePicker className="st_datepicker" styles={datepickerStyle}
                            isRequired
                            firstDayOfWeek={DayOfWeek.Monday}
                            formatDate={_onFormatDateDDMMYYYY}
                            showMonthPickerAsOverlay={true}
                            placeholder="Select a date..."
                            ariaLabel="Select a date"
                            value={stewartTitleActionstepMatter.stewartTitleProperty?.settlementDate}
                            onSelectDate={(newDate) => updateInformation("stewartTitleProperty.settlementDate", newDate)}
                        />
                    </Grid>
                    <Grid item sm={4}>
                        <InputLabel >Title Volume/Title Reference</InputLabel>
                        <TextField className="st-textfield" margin="normal"
                            variant="outlined"
                            error={(Tools.isNullOrEmptyOrUndefined(stewartTitleActionstepMatter.stewartTitleReferences?.titleVolume)) ? true : false}
                            helperText={(Tools.isNullOrEmptyOrUndefined(stewartTitleActionstepMatter.stewartTitleReferences?.titleVolume)) ? "Title volume is required" : ""}
                            value={stewartTitleActionstepMatter.stewartTitleReferences?.titleVolume}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => updateInformation(`stewartTitleReferences.titleVolume`, event.target.value)}
                        />
                    </Grid>
                    <Grid item sm={4}>
                        <InputLabel>Title Folio</InputLabel>
                        <TextField className="st-textfield" margin="normal"
                            variant="outlined"
                            error={stewartTitleActionstepMatter.stewartTitleProperty?.stateProvince !== stateName.QLD && Tools.isNullOrEmptyOrUndefined(stewartTitleActionstepMatter.stewartTitleReferences?.titleFolio) ? true : false}
                            helperText={stewartTitleActionstepMatter.stewartTitleProperty?.stateProvince !== stateName.QLD && Tools.isNullOrEmptyOrUndefined(stewartTitleActionstepMatter.stewartTitleReferences?.titleFolio) ? "Title folio is required" : ""}
                            value={stewartTitleActionstepMatter.stewartTitleReferences?.titleFolio}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => updateInformation(`stewartTitleReferences.titleFolio`, event.target.value)}
                        />
                    </Grid>
                    <Grid item sm={4}>
                        <InputLabel>Property Address</InputLabel>
                        <TextField className="st-textfield" margin="normal"
                            variant="outlined"
                            error={Tools.isNullOrEmptyOrUndefined(stewartTitleActionstepMatter.stewartTitleProperty?.propertyAddress) ? true : false}
                            helperText={Tools.isNullOrEmptyOrUndefined(stewartTitleActionstepMatter.stewartTitleProperty?.propertyAddress) ? "Property address is required" : ""}
                            value={stewartTitleActionstepMatter.stewartTitleProperty?.propertyAddress}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => updateInformation(`stewartTitleProperty.propertyAddress`, event.target.value)}
                        />
                    </Grid>
                    <Grid item sm={4}>
                        <InputLabel>Suburb</InputLabel>
                        <TextField className="st-textfield" margin="normal"
                            variant="outlined"
                            error={Tools.isNullOrEmptyOrUndefined(stewartTitleActionstepMatter.stewartTitleProperty?.city) ? true : false}
                            helperText={Tools.isNullOrEmptyOrUndefined(stewartTitleActionstepMatter.stewartTitleProperty?.city) ? "Suburb is required" : ""}
                            value={stewartTitleActionstepMatter.stewartTitleProperty?.city}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => updateInformation(`stewartTitleProperty.city`, event.target.value)}
                        />
                    </Grid>
                    <Grid item sm={4}>
                        <InputLabel className="st-select-label" htmlFor="state" >State</InputLabel>
                        <Select className="st-selectfield"
                            native
                            variant="outlined"
                            value={stewartTitleActionstepMatter.stewartTitleProperty?.stateID}
                            onChange={(event) => updateDropdown('stewartTitleProperty.stateID', event.target.value)}
                            error={Tools.isNumberNullOrEmptyOrUndefined(stewartTitleActionstepMatter.stewartTitleProperty?.stateID) ? true : false}
                            inputProps={{
                                name: 'state',
                                id: 'state',
                            }}>
                            {stateOptions}
                        </Select>
                        <FormHelperText>{Tools.isNumberNullOrEmptyOrUndefined(stewartTitleActionstepMatter.stewartTitleProperty?.stateID) ? "State is required" : ""}</FormHelperText>
                    </Grid>
                    <Grid item sm={4}>
                        <InputLabel className="st-select-label">Interest being transferred (Tenure)</InputLabel>
                        <Select className="st-selectfield"
                            native
                            variant="outlined"
                            value={stewartTitleActionstepMatter.stewartTitleProperty?.interestBeingTransferred}
                            onChange={(event) => updateDropdown('stewartTitleProperty.interestBeingTransferred', event.target.value)}
                            inputProps={{
                                name: 'interesttransferred',
                                id: 'interesttransferred',
                            }}>
                            {interestTransferOptions}
                        </Select>
                    </Grid>
                    <Grid item sm={4}>
                        <InputLabel className="st-select-label">Tenure Type</InputLabel>
                        <Select className="st-selectfield"
                            native
                            variant="outlined"
                            value={stewartTitleActionstepMatter.stewartTitleProperty?.tenureTypeId}
                            onChange={(event) => updateDropdown('stewartTitleProperty.tenureTypeId', event.target.value)}
                            error={Tools.isNumberNullOrEmptyOrUndefined(stewartTitleActionstepMatter.stewartTitleProperty?.tenureTypeId) ? true : false} inputProps={{
                                name: 'tenuretype',
                                id: 'tenuretype',
                            }}>
                            {tenureOptions}
                        </Select>
                        {Tools.isNumberNullOrEmptyOrUndefined(stewartTitleActionstepMatter.stewartTitleProperty?.tenureTypeId) && <FormHelperText error > Tenure type is required</FormHelperText>}
                    </Grid>
                    <Grid item sm={4}>
                        <InputLabel className="st-select-label">Nature of property</InputLabel>
                        <Select className="st-selectfield"
                            native
                            variant="outlined"
                            value={stewartTitleActionstepMatter.stewartTitleProperty?.natureOfProperty}
                            onChange={(event) => updateDropdown('stewartTitleProperty.natureOfProperty', event.target.value)}
                            inputProps={{
                                name: 'natureOfProperty',
                                id: 'natureOfProperty',
                            }}>
                            {naturePropertyOptions}
                        </Select>
                    </Grid>
                    <Grid item sm={4}>
                        <InputLabel className="st-select-label">Zoning Type</InputLabel>
                        <Select className="st-selectfield"
                            native
                            variant="outlined"
                            value={stewartTitleActionstepMatter.stewartTitleProperty?.zoningTypeId}
                            onChange={(event) => updateDropdown('stewartTitleProperty.zoningTypeId', event.target.value)}
                            error={Tools.isNumberNullOrEmptyOrUndefined(stewartTitleActionstepMatter.stewartTitleProperty?.zoningTypeId) ? true : false} inputProps={{
                                name: 'tenuretype',
                                id: 'tenuretype',
                            }}>
                            {zoningOptions}
                        </Select>
                        {Tools.isNumberNullOrEmptyOrUndefined(stewartTitleActionstepMatter.stewartTitleProperty?.zoningTypeId) && <FormHelperText error>  Zoning type is required {stewartTitleActionstepMatter.stewartTitleProperty?.zoningTypeId} </FormHelperText>}
                    </Grid>
                    {stewartTitleActionstepMatter.stewartTitleProperty?.zoningTypeId == ZoningType.Other &&
                        <Grid item sm={4}>
                            <InputLabel >Other Zoning Type</InputLabel>
                            <TextField className="st-textfield" margin="normal"
                                variant="outlined"
                                error={Tools.isNullOrEmptyOrUndefined(stewartTitleActionstepMatter.stewartTitleProperty?.otherZoningType) ? true : false}
                                helperText={Tools.isNullOrEmptyOrUndefined(stewartTitleActionstepMatter.stewartTitleProperty?.otherZoningType) ? "Other zoning type is required" : ""}
                                value={stewartTitleActionstepMatter.stewartTitleProperty?.otherZoningType}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => updateInformation(`stewartTitleProperty.otherZoningType`, event.target.value)}
                            />
                        </Grid>
                    }
                    <Grid item sm={4}>
                        <InputLabel className="st-select-label">Property Type </InputLabel>
                        <Select className="st-selectfield"
                            native
                            variant="outlined"
                            value={stewartTitleActionstepMatter.stewartTitleProperty?.propertyTypeId}
                            onChange={(event) => updateDropdown('stewartTitleProperty.propertyTypeId', event.target.value)}
                            error={Tools.isNumberNullOrEmptyOrUndefined(stewartTitleActionstepMatter.stewartTitleProperty?.propertyTypeId) ? true : false}>
                            {getPropertyType(stewartTitleActionstepMatter.stewartTitleProperty?.stateProvince ?? "").map((option) => (
                                <option value={option.key}>
                                    {option.text}
                                </option>
                            ))}
                        </Select>
                        {Tools.isNumberNullOrEmptyOrUndefined(stewartTitleActionstepMatter.stewartTitleProperty?.propertyTypeId) && <FormHelperText error>{Tools.isNumberNullOrEmptyOrUndefined(stewartTitleActionstepMatter.stewartTitleProperty?.propertyTypeId) ? "Property type is required" : ""}</FormHelperText>}

                    </Grid>

                    {stewartTitleActionstepMatter.stewartTitleProperty?.propertyTypeId == PropertyType.Other &&
                        <Grid item sm={4}>
                            <InputLabel>Other Type Name </InputLabel>
                            <TextField className="st-textfield" margin="normal"
                                variant="outlined"
                                error={Tools.isNullOrEmptyOrUndefined(stewartTitleActionstepMatter.stewartTitleProperty?.otherTypeName) ? true : false}
                                helperText={Tools.isNullOrEmptyOrUndefined(stewartTitleActionstepMatter.stewartTitleProperty?.otherTypeName) ? "Other type name is required" : ""}
                                value={stewartTitleActionstepMatter.stewartTitleProperty?.otherTypeName}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => updateInformation(`stewartTitleProperty.otherTypeName`, event.target.value)}
                            />
                        </Grid>
                    }

                    <Grid item sm={12}>
                        {renderRadios()}
                    </Grid>
                    <Grid item sm={12}>
                        {renderCheckboxes()}
                    </Grid>

                </Grid>
            </>
        )
    }
    const renderRadios = () => {
        return (
            <Grid container direction="column" alignItems="flex-start" justify="center" spacing={2}>
                <Grid container>
                    <Grid item sm={6}>
                        <Typography className="st_radios">If form or rural land, is it used as commercial enterprise?</Typography>
                    </Grid>
                    <Grid item sm={4}>
                        <Toggle className="st-grid-item__toggle"
                            id="isFormOrRuralLandUsedAsCommercialEnterprise"
                            onText="Yes"
                            offText="No"
                            checked={stewartTitleActionstepMatter.stewartTitleRequestPolicyOptions?.isFormOrRuralLandUsedAsCommercialEnterprise}
                            onChange={(event, item) => updateInformation("stewartTitleRequestPolicyOptions.isFormOrRuralLandUsedAsCommercialEnterprise", item ? item : false)}
                        />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item sm={6}>
                        <Typography className="st_radios">Does property have a Strata or Community Title?</Typography>
                    </Grid>
                    <Grid item sm={4}>
                        <Toggle className="st-grid-item__toggle"
                            id="isStrataOrCommunityTitle"
                            onText="Yes"
                            offText="No"
                            checked={stewartTitleActionstepMatter.stewartTitleRequestPolicyOptions?.isStrataOrCommunityTitle}
                            onChange={(event, item) => updateInformation("stewartTitleRequestPolicyOptions.isStrataOrCommunityTitle", item ? item : false)}
                        />
                    </Grid>
                </Grid>
                {stewartTitleActionstepMatter.stewartTitleProperty?.stateProvince == StateName.VIC &&
                    <Grid container>
                        <Grid item sm={5}>
                            <Typography className="st_radios">Does the Title to Land note a 'Warning as to Dimensions' Under the 'Encrumbrances, Caveats and Notices' Section of the Title Search?</Typography>
                        </Grid>
                        <Grid item sm={4}>
                            <Toggle className="st-grid-item__toggle"
                                id="isWarningToDimensionUnderSectionOfTitileSearch"
                                onText="Yes"
                                offText="No"
                                checked={stewartTitleActionstepMatter.stewartTitleRequestPolicyOptions?.isWarningToDimensionUnderSectionOfTitileSearch}
                                onChange={(event, item) => updateInformation("stewartTitleRequestPolicyOptions.isWarningToDimensionUnderSectionOfTitileSearch", item ? item : false)} />
                        </Grid>
                    </Grid>
                }
            </Grid>
        )
    }

    const renderKnownRisks = () => {
        return (
            <>
                <Typography className="main-heading" variant="subtitle1" gutterBottom>
                    Known Risks
                </Typography>
                <Grid container spacing={2}>
                    <Grid item sm={6}>
                        <Typography> Are there any matters affecting title that have been disclosed by the vendor in the contract for safe,
                            or disclosed in any searches, enquiries or reports (including but not limited to any survey reports) obtained by
                            the client (or on behalf of the client) or are otherwise known to you or your client?</Typography>
                    </Grid>
                    <Grid item sm={2}>
                        <Toggle
                            id="isThrereAnyKnownRisk"
                            onText="Yes"
                            offText="No"
                            checked={stewartTitleActionstepMatter.stewartTitleRequestPolicyOptions?.isThrereAnyKnownRisk}
                            onChange={(event, item) => updateInformation("stewartTitleRequestPolicyOptions.isThrereAnyKnownRisk", item ? item : false)}
                        />
                    </Grid>
                    {stewartTitleActionstepMatter.stewartTitleRequestPolicyOptions?.isThrereAnyKnownRisk &&
                        <Grid item sm={4}>
                            <>
                                <TextField multiline rows={5} placeholder="Provide risk details" variant="outlined"
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => updateInformation("stewartTitleRequestPolicyOptions.disclosureDetails", event.target.value)}
                                    value={stewartTitleActionstepMatter.stewartTitleRequestPolicyOptions?.disclosureDetails}
                                    error={(stewartTitleActionstepMatter.stewartTitleRequestPolicyOptions?.isThrereAnyKnownRisk && Tools.isNullOrEmptyOrUndefined(stewartTitleActionstepMatter.stewartTitleRequestPolicyOptions.disclosureDetails)) ? true : false}
                                    helperText={(stewartTitleActionstepMatter.stewartTitleRequestPolicyOptions?.isThrereAnyKnownRisk && Tools.isNullOrEmptyOrUndefined(stewartTitleActionstepMatter.stewartTitleRequestPolicyOptions.disclosureDetails)) ? "Please provide risk details" : ""} />
                                <Input type="file" onChange={handleFileUpload}
                                    error={(stewartTitleActionstepMatter.stewartTitleRequestPolicyOptions?.isThrereAnyKnownRisk && Tools.isNullOrEmptyOrUndefined(stewartTitleActionstepMatter.attachment?.fileName)) ? true : false} />
                            </>
                        </Grid>
                    }
                </Grid>      </>

        )
    }
    const renderTexts = () => {
        return (
            <Grid container direction="column" alignItems="flex-start" justify="center" spacing={2}>

                <Grid item >
                    <Typography >
                        I/we declare that I/we do not know title defects or observed matter affecting title or use of property.<br />
                        To the best of my knowledge all matter which affects title to the propery has been disclosed.<br />
                        I will advise Stewart Title Limited of any additional registraions or matter affecting titles, changes to the status of the property and/or purchaser's interest
                        (if applicable) Prior to settlement. If I become aware of any of these circumstances arising after settlement but prior to payment remittance, I will advise Stewart Title immediately.<br />
                        I will advise Stewart Title of any changes in the scheduled settlement date.<br />
                        I will provide a copy of the policy order confirmation to my client(s).
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1" gutterBottom={true} className="st-typo__heading" >Important notice to purchaser:</Typography>
                    <Typography>
                        If you do know of any title defects or adverse matter affecting title of use of the property then you must disclose them by providing details to Stewart Title.<br />
                        Title defects or adverse matter inclused illegal structures, survey/boundary defects, encroachments, non-compilance with zoning and development laws, non-compliance with
                        easements and covenants, lack of legal access, lack of a legal right of water supply or drainage, fraud, forgery & identity theft and
                        outstanding rates, charges & taxs that will not be paid or reimbursed on settlement.
                    </Typography>

                </Grid>
                <Grid container>
                    <Grid item sm={4}>
                        <Typography className="st_tandc">I accept these term and conditions</Typography>
                    </Grid>
                    <Grid item sm={2}>
                        <Toggle className="st-grid-item__toggle"
                            id="isTermsAndConditionAccepted"
                            onText="Yes"
                            offText="No"
                            checked={stewartTitleActionstepMatter.stewartTitleRequestPolicyOptions?.isTermsAndConditionAccepted}
                            onChange={(event, item) => updateInformation("stewartTitleRequestPolicyOptions.isTermsAndConditionAccepted", item ? item : false)}
                        />
                        {!stewartTitleActionstepMatter.stewartTitleRequestPolicyOptions?.isTermsAndConditionAccepted && <FormHelperText error>Please accept</FormHelperText>}
                    </Grid>
                </Grid>
            </Grid>

        )
    }
    const renderCheckboxes = () => {
        return (
            <Grid container spacing={2} >
                <Typography className="st-checkbox-label">Please confirm these searches will be conducted prior the settlement</Typography>
                <Grid item>
                    <FormControlLabel control={<Checkbox
                        color="primary"
                        aria-required={true}
                        id="isTitleSearchChecked"
                        className="st_create-cheque-button"
                        data-cy="title_search_check"
                        checked={stewartTitleActionstepMatter.stewartTitleRequestPolicyOptions?.isTitleSearchChecked}
                        onChange={(event, item) => updateInformation("stewartTitleRequestPolicyOptions.isTitleSearchChecked", item ? item : false)}
                    />} label="Title Search" />

                    {stewartTitleActionstepMatter.stewartTitleRequestPolicyOptions?.isTitleSearchChecked ? "" : <FormHelperText error>Title search is mandatory</FormHelperText>}
                </Grid>
                <Grid item>
                    <FormControlLabel control={<Checkbox
                        color="primary"
                        id="isLandTaxChecked"
                        className="st_create-cheque-button"
                        data-cy="land_tax_check"
                        checked={stewartTitleActionstepMatter.stewartTitleRequestPolicyOptions?.isLandTaxChecked}
                        onChange={(event, item) => updateInformation("stewartTitleRequestPolicyOptions.isLandTaxChecked", item ? item : false)}
                    />}
                        label="Land Tax" />
                </Grid>
                <Grid item>
                    <FormControlLabel control={<Checkbox
                        color="primary"
                        id="isCouncilAndWaterChecked"
                        className="st_create-cheque-button"
                        data-cy="council_water_check"
                        checked={stewartTitleActionstepMatter.stewartTitleRequestPolicyOptions?.isCouncilAndWaterChecked}
                        onChange={(event, item) => updateInformation("stewartTitleRequestPolicyOptions.isCouncilAndWaterChecked", item ? item : false)}
                    />}
                        label="Council & Water" />
                </Grid>
                <Grid item>
                    <FormControlLabel control={<Checkbox
                        color="primary"
                        id="isStrataBodyCorporateChecked"
                        className="create-cheque-button"
                        data-cy="stata_body_corporate_check"
                        checked={stewartTitleActionstepMatter.stewartTitleRequestPolicyOptions?.isStrataBodyCorporateChecked}
                        onChange={(event, item) => updateInformation("stewartTitleRequestPolicyOptions.isStrataBodyCorporateChecked", item ? item : false)}
                    />
                    } label="Strata/Body Corporate (if applicable)" />
                </Grid>

            </Grid>
        )
    }

    const renderError = () => {
        return (
            <Grid container spacing={2}>
                {props.policyRequestError && props.policyRequestError?.length > 0 ?
                    <>
                        <Alert severity="error" variant="filled">
                            Some information is missing. Please check the form above and fill in all required fields.
                            <ul>
                                {props.policyRequestError.map(errors => {
                                    return (
                                        errors?.messages?.map((error, indexNo) => {
                                            return (<li key={indexNo} className="customErrorMessage">{error}</li>)
                                        })
                                    )
                                })}
                            </ul>
                        </Alert>
                    </>
                    :
                    <Alert severity="info">
                        Note:
                        Your order will be placed immediately, so please review the above information before proceeding.
                        If you need to make any changes after your order is placed, please contact Stewart Title directly.
                    </Alert>
                }
            </Grid>)
    }
    return (
        <MuiThemeProvider theme={customMuiTheme}>
            <div className="stewarttitle-page-container">
                <Typography className="st-main-title">Order Policy From Stewart Title</Typography>
                <Typography className="sub-title">Submitting this form will send a request direct to Stewart Title Limited, Underwriting Department</Typography>
                <Separator />
                <Grid container direction="column" alignItems="flex-start" justify="center" spacing={4}>
                    <Grid item >
                        {renderReferrerDetails()}
                    </Grid>
                    <Grid item>
                        {renderInsuredDetails()}
                    </Grid>
                    <Grid item>
                        {renderPropertyDetails()}
                    </Grid>
                    <Grid item>
                        {renderKnownRisks()}
                    </Grid>
                    <Grid item>
                        {renderTexts()}
                    </Grid>
                    <Grid item >
                        <Box display="flex" justifyContent="flex-end">
                            <PrimaryButton
                                className="button"
                                data-automation-id="order_policy_button"
                                data-cy="order_policy_button"
                                text="Order Policy"
                                allowDisabledFocus={true}
                                disabled={isFormDataInvalid}
                                onClick={() => props.placeOrder(stewartTitleActionstepMatter)}
                            />
                        </Box>
                    </Grid>
                    <Separator />
                    <Grid item>
                        {renderError()}
                    </Grid>
                </Grid>
            </div >


        </MuiThemeProvider>
    )


}

export default QuestionnaireComponent;