import React, { FC } from "react"
import { Box, Grid } from '@material-ui/core';
import { OrderStewartTitlePolicyCommandResponse } from '../../utils/stewartTitleWCAApiTypes';
import Tools from "utils/tools";

interface Props {
    orderStewartTitlePolicyCommandResponse: OrderStewartTitlePolicyCommandResponse
}
const PolicySuccess: FC<Props> = (props) => {
    const policyStatus = props.orderStewartTitlePolicyCommandResponse.status ?? '';
    return (
        <Box>
            <h1>Policy order submitted to Stewart Title</h1>
            <h2>Status: {policyStatus}</h2>
            {props.orderStewartTitlePolicyCommandResponse.fileNo &&
                props.orderStewartTitlePolicyCommandResponse.policyNumber &&
                <><p>Policy Order Id  <strong>{props.orderStewartTitlePolicyCommandResponse.policyNumber}</strong>.</p>
                    <Grid item xs={12}>
                        <table>
                            <tr>
                                <td>Premium</td>
                                <td><strong>{props.orderStewartTitlePolicyCommandResponse.premium && Tools.formatAsCurrency(props.orderStewartTitlePolicyCommandResponse.premium)}</strong></td>
                            </tr>
                            <tr>
                                <td>GST</td>
                                <td><strong>{props.orderStewartTitlePolicyCommandResponse.gst && Tools.formatAsCurrency(props.orderStewartTitlePolicyCommandResponse.gst)}</strong></td>
                            </tr>
                            <tr>
                                <td>Stamp Duty</td>
                                <td><strong>{props.orderStewartTitlePolicyCommandResponse.stampDuty && Tools.formatAsCurrency(props.orderStewartTitlePolicyCommandResponse.stampDuty)}</strong></td>
                            </tr>
                            <tr>
                                <td>Total Premium</td>
                                <td ><strong>{props.orderStewartTitlePolicyCommandResponse.totalPremium && Tools.formatAsCurrency(props.orderStewartTitlePolicyCommandResponse.totalPremium)}</strong></td>
                            </tr>
                        </table>
                    </Grid></>
            }
        </Box>
    )
}
export default PolicySuccess;