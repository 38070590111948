import * as CONSTANTS from './constants'
import {    
    ConnectedActionstepOrgsResponse,       
    ErrorViewModel,
    GetListFilteredActionsQuery,
    ListFilteredActionsResponse,  
    ProcessEmailArchiverQuery,
    ProcessEmailArchiverResponse, 
    ValidateEmailArchiverInputCommand,
    ValidateEmailArchiverInputResponse,
    GeneralResponse,
    GetTransactionStatusResponse
} from 'utils/wcaApiTypes'

import {
    ActionFailed,
    ClearEmailArchiverState,
    GetConnectedOrgs,
    GetConnectedOrgsSuccess, 
    GetListMatters,
    GetListMattersSuccess,    
    ClearEmailArchiverResponse,
    ValidateDataInput,
    ValidateDataInputSuccess,
    TriggerEmailArchiverProcess,
    TriggerEmailArchiverProcessSuccess,
    StoreUserInputState,
    GetTransactionStatus,
    GetTransactionStatusSuccess,
    DownloadReports,
    DownloadReportsSuccess,
    CancelTransaction,
    CancelTransactionSuccess
} from './actionTypes'


export function getConnectedOrgs(): GetConnectedOrgs {
    return {
        type: CONSTANTS.GET_CONNECTED_ORGS_REQUESTED,      
    }
}

export function getConnectedOrgsSuccess(data: ConnectedActionstepOrgsResponse[]): GetConnectedOrgsSuccess {
    return {
        type: CONSTANTS.GET_CONNECTED_ORGS_SUCCESS,
        data
    }
}

export function getConnectedOrgsFailed(error: ErrorViewModel): ActionFailed {
    return {
        type: CONSTANTS.GET_CONNECTED_ORGS_FAILED,
        error
    }
}

export function getListMatters(params: GetListFilteredActionsQuery): GetListMatters {
    return {
        type: CONSTANTS.GET_LIST_MATTERS_REQUESTED,
        params
    }
}

export function getListMattersSuccess(data: ListFilteredActionsResponse): GetListMattersSuccess {
    return {
        type: CONSTANTS.GET_LIST_MATTERS_SUCCESS,
        data
    }
}

export function getListMattersFailed(error: ErrorViewModel): ActionFailed {
    return {
        type: CONSTANTS.GET_LIST_MATTERS_FAILED,
        error
    }
}

export function validateDataInput(params: ValidateEmailArchiverInputCommand): ValidateDataInput {
    return {
        type: CONSTANTS.VALIDATE_DATA_INPUT_REQUESTED,
        params
    }
}

export function validateDataInputSuccess(data: ValidateEmailArchiverInputResponse): ValidateDataInputSuccess {
    return {
        type: CONSTANTS.VALIDATE_DATA_INPUT_SUCCESS,
        data
    }
}

export function validateDataInputFailed(error: ErrorViewModel): ActionFailed  {
    return {
        type: CONSTANTS.VALIDATE_DATA_INPUT_FAILED,
        error
    }
}

export function triggerEmailArchiverProcess(params: ProcessEmailArchiverQuery): TriggerEmailArchiverProcess {
    return {
        type: CONSTANTS.TRIGGER_EMAIL_ARCHIVER_PROCESS_REQUESTED,
        params
    }
}

export function triggerEmailArchiveProcessSuccess(data: ProcessEmailArchiverResponse): TriggerEmailArchiverProcessSuccess {
    return {
        type: CONSTANTS.TRIGGER_EMAIL_ARCHIVER_PROCESS_SUCCESS,
        data
    }
}

export function triggerEmailArchiveProcessFailed(error: ErrorViewModel): ActionFailed {
    return {
        type: CONSTANTS.TRIGGER_EMAIL_ARCHIVER_PROCESS_FAILED,
        error
    }
}

export function getTransactionStatus(params: number): GetTransactionStatus{
    return {
        type: CONSTANTS.GET_TRANSACTION_STATUS_REQUESTED,
        params
    }
}

export function getTransactionStatusSuccess(data: GetTransactionStatusResponse): GetTransactionStatusSuccess {
    return {
        type: CONSTANTS.GET_TRANSACTION_STATUS_SUCCESS,
        data
    }
}

export function getTransactionStatusFailed(error: ErrorViewModel): ActionFailed {
    return {
        type: CONSTANTS.GET_TRANSACTION_STATUS_FAILED,
        error
    }
}

export function storeUserInputState(data: ProcessEmailArchiverQuery): StoreUserInputState {
    return {
        type: CONSTANTS.STORE_USER_INPUT_STATE,
        data
    }
}

export function clearEmailArchiverState(): ClearEmailArchiverState {
    return {
        type: CONSTANTS.CLEAR_EMAIL_ARCHIVER_STATE
    }
}

export function clearEmailArchiverResponse(): ClearEmailArchiverResponse {
    return {
        type: CONSTANTS.CLEAR_EMAIL_ARCHIVER_RESPONSE
    }
}

export function downloadReports(params: number): DownloadReports {
    return {
        type: CONSTANTS.DOWNLOAD_REPORTS_REQUESTED,
        params
    }
}

export function downloadReportsSuccess(data: boolean): DownloadReportsSuccess {
    return {
        type: CONSTANTS.DOWNLOAD_REPORTS_SUCCESS,
        data
    }
}

export function downloadReportsFailed(error: ErrorViewModel): ActionFailed {
    return {
        type: CONSTANTS.DOWNLOAD_REPORTS_FAILED,
        error
    }
}


export function cancelTransaction(params: number): CancelTransaction  {
    return {
        type: CONSTANTS.CANCEL_TRANSACTION_REQUESTED,
        params
    }
}

export function cancelTransactionSuccess(data: GeneralResponse): CancelTransactionSuccess {
    return {
        type: CONSTANTS.CANCEL_TRANSACTION_SUCCESS,
        data
    }
}

export function cancelTransactionFailed(error: ErrorViewModel): ActionFailed {
    return {
        type: CONSTANTS.CANCEL_TRANSACTION_FAILED,
        error
    }
}

