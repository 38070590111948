import * as React from 'react'

import { Text, Image, Stack } from 'office-ui-fabric-react';
import { MessageBar, MessageBarType } from 'office-ui-fabric-react';

export default class InvalidAccess extends React.Component {
    public render() {

        const tokens = {
            padding: 10,
            childrenGap: 20,
        };

        return (
            <Stack tokens={tokens}>
                <MessageBar messageBarType={MessageBarType.error}>
                    <Text>You have attempted to access Konekta from an invalid location</Text>
                </MessageBar>

                <Text>Please use the Konekta link from within an Actionstep matter:</Text>

                <Stack.Item>
                    <Image src="/images/konekta-as-integration.png" alt={"Actionstep - Konekta Integration"} width="100%" />
                </Stack.Item>
            </Stack>
        );
    }
}