import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

interface IProps { }

interface IState { }

class UnderwritingDisclaimer extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {}
    }

    render() {
        return (
            <ul className="fa-ul underwriting-disclaimer-container">
                <li>
                    <FontAwesomeIcon icon={faExclamationTriangle} listItem />
                    <strong>Note: </strong>
                    The ordered policy is currently in pending status.
                </li>
            </ul>
        )
    }
}

export default UnderwritingDisclaimer;