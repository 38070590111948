import React from 'react';
import {
    Stack,
    Separator
} from 'office-ui-fabric-react';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { Dropdown } from 'office-ui-fabric-react/lib/Dropdown';

import { _onFormatDate } from 'utils/dataFormatter';
import {
    LabelColumnStackItemStyles,
    DefaultStackTokens,
    FieldColumnStackItemStyles
} from 'konekta-theme';
import Tools from 'utils/tools';
import { NameTitles } from 'containers/firsttitle/components/constants';
import FTAddressInput from 'containers/firsttitle/components/fTAddressInput';
import { FTPartyExtended } from 'containers/firsttitle/requestPolicy';

import './style.css';
import { RemoveBuyerComponent } from './RemoveBuyerComponent';

interface IProps {
    buyer: FTPartyExtended;
    index: number;
    setMatterInformation: (keyPath: string, newValue: any) => void;
    removeBuyer?: (id: string) => void;
}

export const IndividualBuyer = ({ buyer, index, removeBuyer, setMatterInformation }: IProps) => {
    const onRemoveBtnClick = () => {
        if(buyer.newlyCreatedId != null && removeBuyer != null) {
            removeBuyer(buyer.newlyCreatedId);
        }
    }

    return (
        <Stack>
            {buyer.newlyCreatedId != null && <RemoveBuyerComponent onClickHandler={onRemoveBtnClick} /> }

            <Stack horizontal tokens={DefaultStackTokens}>
                <Stack disableShrink styles={LabelColumnStackItemStyles}>
                    <h3>Individual: {buyer.firstName} {buyer.middleName} {buyer.lastName}</h3>
                </Stack>
                <Stack.Item styles={FieldColumnStackItemStyles}>
                    <Dropdown
                        label="Title"
                        id={`buyers_${index}_name_title`}
                        options={NameTitles}
                        placeholder="Please select..."
                        selectedKey={buyer.title}
                        errorMessage={!Tools.isNullOrEmptyOrUndefined(buyer.title) ? "" : "Required"}
                        onChange={(event, item) => setMatterInformation(`buyers.${index}.title`, item!.key.toString())}
                    />

                    <TextField
                        label="First Name"
                        id={`buyers_${index}_first_name`}
                        value={buyer.firstName || ""}
                        errorMessage={!Tools.isNullOrEmptyOrUndefined(buyer.firstName) ? "" : "Required"}
                        onChange={(ev, newValue) => setMatterInformation(`buyers.${index}.firstName`, newValue)}
                    />

                    <TextField
                        label="Middle Name"
                        id={`buyers_${index}_middle_name`}
                        value={buyer.middleName || ""}
                        onChange={(ev, newValue) => setMatterInformation(`buyers.${index}.middleName`, newValue)}
                    />

                    <TextField
                        label="Last Name"
                        id={`buyers_${index}_last_name`}
                        value={buyer.lastName || ""}
                        errorMessage={!Tools.isNullOrEmptyOrUndefined(buyer.lastName) ? "" : "Required"}
                        onChange={(ev, newValue) => setMatterInformation(`buyers.${index}.lastName`, newValue)}
                    />

                    <TextField
                        label="Email Address"
                        id={`buyers_${index}_email_address`}
                        value={buyer.emailAddress || ""}
                        errorMessage={!Tools.isNullOrEmptyOrUndefined(buyer.emailAddress) ? "" : "Required"}
                        onChange={(ev, newValue) => setMatterInformation(`buyers.${index}.emailAddress`, newValue)}
                    />

                    <Separator />

                    <FTAddressInput
                        prefix={`buyers_${index}_`}
                        party={buyer}
                        setMatterInformation={(keyPath, newValue) => setMatterInformation(`buyers.${index}.${keyPath}`, newValue)}
                        disableCountrySelection={false}
                    />
                </Stack.Item>
            </Stack>
        </Stack>

    )
}