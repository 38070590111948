import * as React from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../../../app.types';
import { getPexaWorkspaceSummary, copyLodgedDocumentsToActionStep, getPexaWorkspaceInformationFromKonekta } from '../../redux/actions';
import * as CONSTANTS from 'containers/pexa/redux/constants'
import { FolderOpenSharp } from '@material-ui/icons';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import { PrimaryButton } from "office-ui-fabric-react/lib/Button";
import { Redirect } from 'react-router-dom';
import './RetrieveDocuments.css';

type AppStates = {
    isLoading: boolean;
    hasError: boolean;
    redirect: string;
}

type AppProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;
export class RetrieveDocuments extends React.Component<AppProps, AppStates> {
    constructor(props: AppProps) {
        super(props);
        this.state = {
            isLoading: false,
            hasError: false,
            redirect: ""
        }
    }

    public async componentDidMount(): Promise<void> {
        await this.getPexaUploadStatus();
    }
    private async getPexaUploadStatus(): Promise<void> {
        const { actionstepContext } = this.props;      
        this.props.copyLodgedDocumentsToActionStep( actionstepContext?.matterContext?.id ?? 0, actionstepContext?.orgKey ?? "")
    }
    static getDerivedStateFromProps(nextProps: AppProps, prevState: AppStates): AppStates {
        let nextState = {} as AppStates;
        if (nextProps.gotResponse) {
            switch (nextProps.requestType) {
                case CONSTANTS.COPY_LODGED_DOCUMENTS_TO_ACTIONSTEP_REQUESTED: {
                    nextState.isLoading = true;
                    break;
                }
                case CONSTANTS.COPY_LODGED_DOCUMENTS_TO_ACTIONSTEP_SUCCESS:
                    nextState.isLoading = false;
                    break;
                case CONSTANTS.COPY_LODGED_DOCUMENTS_TO_ACTIONSTEP_FAILED: {
                    nextState.isLoading = false;
                    nextState.hasError = true;
                    break;
                }
                default:
                    return nextState;
            }
        }
        return nextState;
    }

    private redirectToHome = () => {
        this.setState({ redirect: "/matter" });
    }

    render(): JSX.Element {
        const lodgedDocuments = this.props.lodgedDocuments;
        let documentList = lodgedDocuments?.message?.split(';');
        let documentRetrievalStatus = lodgedDocuments?.status;
        const { isLoading, hasError } = this.state;
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }
        return (
            <>
                {isLoading ?
                    <Spinner size={SpinnerSize.large} label="loading retrieved document list..." />
                    : <>
                        {hasError ? <span> An error occured while retrieveing the document. Please try again later </span> :
                            <><div className="modal-header">
                                <span className="modal-title"><big><b>Retrived Documents</b></big></span>
                            </div>
                                <div className="modal-body">
                                    {documentRetrievalStatus !== undefined && documentRetrievalStatus !== "FAILED" ?                                    
                                        <div className="ms-Grid" dir="ltr">
                                            <div className="ms-Grid-row doc-header">
                                                <div className="ms-Grid-col ms-lg5">Document Name</div>
                                                <div className="ms-Grid-col ms-sm4">Date of Retrieval </div>
                                            </div>
                                            {documentList?.map((document, index) => (
                                                <div className="ms-Grid-row">
                                                    <div className="ms-Grid-col ms-lg5 doc-row" key={index}><FolderOpenSharp /> <span> {document}</span></div>
                                                    <div className="ms-Grid-col ms-sm4">{new Date().toLocaleDateString()}</div>
                                                </div>

                                            ))}
                                        </div> 
                                        : <div><b> {documentList}</b></div>}
                                </div><div className="ms-Grid-col doc-return-button" dir="rtl">
                                    <PrimaryButton
                                        text="Return to konekta"
                                        onClick={() => this.redirectToHome()} />
                                </div></>
                        }
                    </>
                }
            </>
        )
    }
}

const mapStateToProps = (state: AppState) => {
    return {
       // pexaWorkspaceInfoFromKonekta: state.pexa.pexaWorkspaceInfoFromKonekta,
        actionstepContext: state.common.actionstepContext,
        gotResponse: state.pexa.gotResponse,
        requestType: state.pexa.requestType,
        lodgedDocuments: state.pexa.lodgedDocuments
    }
}

const mapDispatchToProps = {
    getPexaWorkspaceInformationFromKonekta,
    getPexaWorkspaceSummary,
    copyLodgedDocumentsToActionStep
}

export default connect(mapStateToProps, mapDispatchToProps)(RetrieveDocuments);