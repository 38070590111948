import * as React from 'react';
import { connect } from 'react-redux';

import { MessageBar, MessageBarType } from 'office-ui-fabric-react';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import { Link } from 'office-ui-fabric-react/lib/Link';
import { AppState } from '../../../../app.types';
import { SubwayNavNodeState } from '../../../../components/SubwayNav';

import * as CONSTANTS from '../../../../containers/pexa/redux/constants';

import {
    getPexaWorkspaceSummary,
    clearPexaState
} from '../../../../containers/pexa/redux/actions';
import {
    RetrieveWorkspaceSummaryParameters
} from '../../../../utils/wcaApiTypes';

type AppStates = {
    dataLoaded: boolean,
    retrieveWorkspaceSummaryParameters: RetrieveWorkspaceSummaryParameters | undefined,
}

interface IAppProps {
    onChangeStep: (newState: SubwayNavNodeState, newStep: number) => void;
}

type AppProps = IAppProps & ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

export class RetrieveWorkspaceFromPexa extends React.Component<AppProps, AppStates> {
    constructor(props: AppProps) {
        super(props);
        this.state = {
            dataLoaded: false,
            retrieveWorkspaceSummaryParameters: undefined,
        }
    }

    public componentDidMount(): void {
        this.loadPexaWorkspaceSummary();
    }

    private loadPexaWorkspaceSummary(): void {


        const { pexaWorkspaceResponse, pexaWorkspaceCreationData } = this.props;


        let retrieveWorkspaceSummaryParameters = new RetrieveWorkspaceSummaryParameters();
        let pexaSubscriberRole: string | undefined;
        let pexaWorkspaceId: string | undefined;
        if (pexaWorkspaceCreationData && pexaWorkspaceCreationData.existingPexaWorkspace) {
            pexaSubscriberRole = pexaWorkspaceCreationData.existingPexaWorkspace?.pexaWorkspaceUri?.split("=")[1];
            pexaWorkspaceId = pexaWorkspaceCreationData.existingPexaWorkspace?.pexaWorkspaceId;
        }
        else {
            pexaSubscriberRole = pexaWorkspaceResponse?.workspaceUri?.split("=")[1];
            pexaWorkspaceId = pexaWorkspaceResponse?.workspaceId;;
        }

        retrieveWorkspaceSummaryParameters = new RetrieveWorkspaceSummaryParameters({
            workspaceId: pexaWorkspaceId,
            subscriberRole: pexaSubscriberRole
        });


        this.props.getPexaWorkspaceSummary(retrieveWorkspaceSummaryParameters);
    }

    static getDerivedStateFromProps(nextProps: AppProps, prevState: AppStates): AppStates {
        let nextState = {} as AppStates;

        if (nextProps.gotResponse) {
            switch (nextProps.requestType) {
                case CONSTANTS.GET_PEXA_WORKSPACE_SUMMARY_REQUESTED: {
                    nextState.dataLoaded = true;
                    if (!nextProps.pexaError ||
                        nextProps.pexaError && nextProps.pexaError.errorList && nextProps.pexaError.errorList.length === 0) {
                        nextProps.clearPexaState();
                        nextProps.onChangeStep(SubwayNavNodeState.Completed, 1);
                    }
                    break;
                }
                default:
                    return nextState;
            }
        }
        return nextState;
    }


    render() {
        const { pexaError } = this.props;
        const { dataLoaded } = this.state;

        return (
            <div>
                {dataLoaded ?
                    <div>
                        {(pexaError && pexaError.errorList && pexaError.errorList.length > 0) ?
                            <div>
                                <h3>
                                    Pexa returned the following error(s):
                                </h3>
                                <br />

                                {pexaError.errorList.map((item, index) => (
                                    <MessageBar messageBarType={MessageBarType.error} isMultiline={false} key={index}>
                                        {item}
                                    </MessageBar>
                                ))}
                                <br />
                                <p className="text-danger">
                                    Please try again later. If you continue to experience problems, please
                                    <Link href={"https://support.konekta.com.au/support/tickets/new"} target="_blank" rel="noopener noreferrer"> submit a ticket</Link>.
                                </p>
                            </div>
                            :
                            <div>
                                <p className="text-danger">
                                    An unexpected error has occured. Please <Link onClick={() => window.location.reload(false)} >refresh the page</Link> and try again. If you continue to experience problems, please
                                    <Link href={"https://support.konekta.com.au/support/tickets/new"} target="_blank" rel="noopener noreferrer"> submit a ticket</Link>.
                                </p>
                            </div>
                        }
                    </div>

                    : <Spinner
                        size={SpinnerSize.large} />
                }
            </div>
        );
    }
}

const mapStateToProps = (state: AppState) => {
    return {
        pexaError: state.pexa.error,
        gotResponse: state.pexa.gotResponse,
        requestType: state.pexa.requestType,
        pexaWorkspaceResponse: state.pexa.createPexaWorkspaceResponse,
        pexaWorkspaceCreationData: state.pexa.pexaWorkspaceCreationData,
        workspaceSummaryList: state.pexa.workspaceSummaryList
    }
}

const mapDispatchToProps = {
    getPexaWorkspaceSummary,
    clearPexaState
}

export default connect(mapStateToProps, mapDispatchToProps)(RetrieveWorkspaceFromPexa);
