import * as React from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../../../../app.types';
import { getPexaKey } from '../../../../../containers/pexa/redux/actions';
import { PexaKeyWorkspacePartyIdAndRole } from '../../../../../utils/newWcaApiTypes';
import { FunctionComponent, useState } from 'react'
import { getRequest } from 'utils/request';

interface IAppProps {
    workspaceId: string;
    workspaceRole: string;
    partyId: string;
}


type AppProps = IAppProps & ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;
const PexaKeyInvitationStatus: FunctionComponent<AppProps> = (props) => {

    const [status, setStatus] = useState('');
    React.useEffect(() => {
        let workspaceIdAndRole = new PexaKeyWorkspacePartyIdAndRole;
        const { workspaceId, workspaceRole, partyId } = props;
        workspaceIdAndRole.workspaceId = workspaceId;
        workspaceIdAndRole.workspaceRole = workspaceRole;
        workspaceIdAndRole.partyId = partyId;
        (async () => {
        
            const settingsFromServer = await getRequest<string>(`/api/conveyancing/get-pexa-keyInvitation?workspaceId=${props.workspaceId!}&workspaceRole=${props.workspaceRole!}&partyId=${props.partyId!}`)
            setStatus(settingsFromServer);
        })();
    }, [props.partyId])
    return (
        <>           
            <tr className="workspace-summary-tr md-Grid-row ms-md4">
                <td className="md-Grid-col has-bottom-border ms-md4">
                    PexaKey Invite Status:
                </td>
                <td className="md-Grid-col ms-md4">
                    {status ?? "N/A"}
                </td>
            </tr>
        </>
    )
}

const mapStateToProps = (state: AppState) => {
    return {
        pexaKeyInviteStatus: state.pexa.pexaKeyInviteStatus,
        gotResponse: state.pexa.gotResponse,
        requestType: state.pexa.requestType
    }
}
const mapDispatchToProps = {
    getPexaKey
}

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(PexaKeyInvitationStatus));