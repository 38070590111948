import * as React from 'react';
import { Component } from "react";
var __html = require('./webform.js');
var template = { __html: __html };

class Webform extends Component {
    div: any;
    // Sync props selected users to state
    componentDidMount() {
        //const sLocale = document.createElement('script');
        //sLocale.type = 'text/javascript';
        //sLocale.async = true;
        //sLocale.src = "https://cdn.actionstep.com/assets/Locale.js";
        //this.div.appendChild(sLocale);

        //const sDatetimeControl = document.createElement('script');
        //sDatetimeControl.type = 'text/javascript';
        //sDatetimeControl.async = true;
        //sDatetimeControl.src = "https://cdn.actionstep.com/assets/DatetimeControl.js";
        //this.div.appendChild(sDatetimeControl);

        //const sphpDateUtilities = document.createElement('script');
        //sphpDateUtilities.type = 'text/javascript';
        //sphpDateUtilities.async = true;
        //sphpDateUtilities.src = "https://cdn.actionstep.com/assets/phpDateUtilities.js";
        //this.div.appendChild(sphpDateUtilities);

        //const sFunction = document.createElement('script');
        //sFunction.type = 'text/javascript';
        //sFunction.async = true;
        //sFunction.innerHTML =`if (typeof(ActionStep_Locale) !== 'undefined') {
        //    ActionStep_Locale.setDateFormats('%d/%m/%Y', '%d %b %Y', '%A %B %e, %Y');
        //    ActionStep_Locale.setFirstDayOfTheWeek('Monday');
        //}

        //if (typeof(jQuery) != 'undefined') {
        //    jQuery(window).ready(function() {
        //        jQuery('.DateControl_CalIcon').attr("src", "https://cdn.actionstep.com/assets/ActionStep_DatetimeControl_Cal.png");
        //    });
        //} else {
        //    window.setTimeout(function() {
        //        var calImages = document.getElementsByClassName("DateControl_CalIcon");
        //        for (var i=0;i<calImages.length;i++) {
        //            calImages[i].src = "https://cdn.actionstep.com/assets/ActionStep_DatetimeControl_Cal.png";
        //        }
        //    }, 500);
        //}
        //ActionStep_DatetimeControl.CONFIG.dateFormat = 'DDMMYYYY';`
        //this.div.appendChild(sFunction);

        const sWebform = document.createElement('script');
        sWebform.type = 'text/javascript';
        sWebform.async = true;
        sWebform.src = "https://app.konekta.com.au/api/data-konekta/script/ktaproddemo/4586314ca0e4698ab58d779d5c751ce828f56785";
        this.div.appendChild(sWebform);
    }

    render() {
        return (
            <div className="screen-share" ref={el => (this.div = el)}
                style={{ margin: 'auto'}} >
                <span dangerouslySetInnerHTML={template} />
            </div>
        );
    }
}
export default Webform;