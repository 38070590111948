import * as React from 'react';
import {
    Route,
    RouteProps,
} from "react-router-dom";

import { useSelector, useDispatch } from 'react-redux';

import {
    setFullPage,
    getAccountInfo,
    setActionstepContext,
    getMatterInfo,
    setCurrentOrg,
    resetOrgAndMatterRequestStatus,
    markOrgVerified,
    matterInfoNotSupplied,
} from '../redux/common/actions';
import { AppState, IJWTInfo } from '../app.types';
import OrgSelector from './common/orgSelector';
import { FunctionComponent, useEffect, useState } from 'react';
import Tools from 'utils/tools';
import { RequestStatus, ActionstepContext, MatterRequestStatus } from 'redux/common/reducer';
import LoadingWidget from './common/loadingWidget';
import { ConnectToActionstep } from './connectToActionstep/connectToActionstep';
import InvalidAccess from './common/invalidAccess';
import AuthFailed from './common/authFailed';
import AccepTermsWidget from './common/acceptTermsWidget';
import ErrorMessageWidget from './common/errorMessageWidget';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';

import axios from 'axios';
import { postRequest } from 'utils/request';
import { VerifyActionstepConnectionResponse } from 'utils/wcaApiTypes';

const findActionstepContext = (): ActionstepContext | null => {
    const urlParams = new URLSearchParams(window.location.search);
    const encodedJwt = urlParams.get('jwt') ?? "";

    if (encodedJwt) {
        const jwt = require('jsonwebtoken');
        const jwtInfo: IJWTInfo = jwt.decode(encodedJwt) as IJWTInfo;
        if (!jwtInfo) return null;
        return {
            orgKey: jwtInfo.orgkey,
            matterContext: {
                id: jwtInfo.action_id,
                actionTypeId: jwtInfo.action_type_id,
                actionTypeName: jwtInfo.action_type_name,
                timezone: jwtInfo.timezone
            },
        };
    } else {
        // Fallback to query string for org/matter context
        const queryString = require('query-string');
        const urlParams = queryString.parse(window.location.search);
        return Tools.GetActionstepContextFromQueryString(urlParams);
    }
}

export enum RequiredContext {
    Org,
    OrgAndMatter,
}

interface IProps extends RouteProps {
    requiredContext?: RequiredContext;
    fullPage?: boolean;
    allowAnonymous?: boolean | undefined;
}

export const KonektaRoute: FunctionComponent<IProps> = (props) => {
    const dispatch = useDispatch();
    const commonState = useSelector((state: AppState) => state.common);
    const orgKey = commonState.actionstepContext?.orgKey;
    const matterId = commonState.actionstepContext?.matterContext?.id;
    const verificationStatus = commonState.actionstepContext?.credentials?.verificationStatus;
    const [logRocketInitialised, setLogRocketInitialised] = useState(false);

    // Init LogRocket if enabled for user
    useEffect(() => {
        if (!logRocketInitialised && commonState.accountInfo?.enhancedLoggingEnabled) {
            LogRocket.init('uoasb3/konekta-app');
            setupLogRocketReact(LogRocket);
            setLogRocketInitialised(true);
        }

    }, [logRocketInitialised, setLogRocketInitialised, commonState.accountInfo]);

    // Ensure full page mode is set correctly in redux for this route
    useEffect(() => {
        const fullPage = props.fullPage ?? false;
        dispatch(setFullPage(fullPage));
    }, [dispatch, props.fullPage]);

    // Request account info if not already requested
    useEffect(() => {
        if (commonState.accountInfoStatus !== RequestStatus.NotRequested) return;
        dispatch(getAccountInfo());
    }, [dispatch, commonState.accountInfoStatus]);

    // Ensure ActionstepContext is set if available
    useEffect(() => {
        const actionstepContext = findActionstepContext();

        if (!commonState.actionstepContextHasLoaded){
            dispatch(setActionstepContext(actionstepContext));
        }
    }, [dispatch, commonState.actionstepContextHasLoaded]);

    // Handle OrgAndMatter context
    useEffect(() => {
        if (props.requiredContext !== RequiredContext.OrgAndMatter) return;
        if (commonState.matterInfoStatus !== MatterRequestStatus.NotRequested) return;
        if (!commonState.actionstepContextHasLoaded) return;

        if (commonState.actionstepContext?.orgKey === undefined || commonState.actionstepContext?.matterContext === undefined) {
            dispatch(matterInfoNotSupplied());
        } else {
            // Retrieve additional Matter info from the backend
            dispatch(getMatterInfo(commonState.actionstepContext));
        }
    }, [dispatch, props.requiredContext, commonState.matterInfoStatus, commonState.actionstepContext, commonState.actionstepContextHasLoaded]);

    // Handle Org context
    useEffect(() => {
        if (props.requiredContext !== RequiredContext.Org) return;
        if (verificationStatus !== RequestStatus.NotRequested) return;
        if (!commonState.actionstepContextHasLoaded) return;

        // No org key, nothing to verify
        if (!commonState.actionstepContext?.orgKey) return;

        // Make sure the user has permissions on this org on load. That way, if there
        // is a connection problem the user will be prompted to re-connect before they
        // can agree to terms. If there is a 401, the axios client config will dispatch
        // an action that will trigger re-connection.
        const source = axios.CancelToken.source()
        const verifyConnection = async () => {
            try {
                const response = await postRequest<VerifyActionstepConnectionResponse>(`/api/integrations/actionstep/verify-connection?orgKey=${commonState.actionstepContext?.orgKey}`, null, { cancelToken: source.token });
                dispatch(markOrgVerified(response));
            } catch (error) {
                if (!axios.isCancel(error)) {
                    throw error
                }
            }
        }

        verifyConnection();

        return () => { source.cancel() }
    }, [dispatch, props.requiredContext, verificationStatus, commonState.actionstepContext, commonState.actionstepContextHasLoaded]);

    // Handle authentication and make sure the user is logged in unless allowAnonymous
    if (!props.allowAnonymous) {
        if (commonState.accountInfoStatus === RequestStatus.Requested) {
            return (<LoadingWidget message="Performing security checks..." />);
        } else if (commonState.accountInfoStatus === RequestStatus.Failed) {
            return (<AuthFailed />);
        } else {
            if (!commonState.accountInfo?.isLoggedIn) {
                // Use popup for sign-in, so user doesn't have to navigate away from this page.
                // This will work both inside and out of an iframe and will provide a consistent user experience.
                return (<ConnectToActionstep signIn isFirstTime={false} callback={() => dispatch(getAccountInfo())} />);
            }
        }

        LogRocket.identify('THE_USER_ID_IN_YOUR_APP', {
            name: `${commonState.accountInfo.firstName} ${commonState.accountInfo.lastName}`,
            email: commonState.accountInfo.email ?? 'Unknown',
          });
    }

    // Check to make sure we have the required context
    if (props.requiredContext !== undefined) {
        // Wait for ActionstepContext to be determined
        if (!commonState.actionstepContextHasLoaded) {
            return (<LoadingWidget message="Determining which Actionstep Organisation and Matter to load..." />);
        }

        if (props.requiredContext === RequiredContext.OrgAndMatter) {
            if (commonState.actionstepContext?.credentials?.verificationStatus === RequestStatus.Failed) {
                return (<ConnectToActionstep isFirstTime={false} callback={() => dispatch(getMatterInfo(commonState.actionstepContext!))} />);
            } else if (commonState.matterInfoStatus === MatterRequestStatus.NotRequested) {
                return (<LoadingWidget message="Attempting to load matter details..." />);
            } else if (verificationStatus === RequestStatus.Requested) {
                return (<LoadingWidget message="Verifying connection to Actionstep..." />);
            } else if (commonState.matterInfoStatus === MatterRequestStatus.Requested) {
                return (<LoadingWidget message="Loading matter details..." />);
            } else if (commonState.matterInfoStatus === MatterRequestStatus.NotFound) {
                return (<ErrorMessageWidget message={`The matter '${commonState.actionstepContext?.matterContext?.id}' was not found.`} />);
            } else if (!orgKey || !matterId) {
                return (<InvalidAccess />);
            }
        } else if (props.requiredContext === RequiredContext.Org) {
            if (!orgKey) {
                return (<OrgSelector onSelect={(o) => dispatch(setCurrentOrg(o))} dontAutoSelectSingleOrg={commonState.disableOrgAutoSelect} />);
            }

            if (verificationStatus === RequestStatus.NotRequested) {
                return (<LoadingWidget message="Attempting to verify connection..." />);
            } else if (verificationStatus === RequestStatus.Requested) {
                return (<LoadingWidget message="Verifying connection to Actionstep..." />);
            } else if (verificationStatus === RequestStatus.Failed) {
                return (<ConnectToActionstep isFirstTime={false} callback={() => dispatch(resetOrgAndMatterRequestStatus())} />);
            }
        }

        if (!orgKey) return (
            // If there's no org key here, something is quite wrong. We shouldn't get here.
            <ErrorMessageWidget message="Oops, something went wrong. We can't figure out which Actionstep Organisation you're trying to use." />
        );

        // Show terms acceptance if required for this org
        if (!commonState.actionstepContext?.termsEverAccepted || !commonState.actionstepContext?.latestTermsAccepted) {
            const orgName = commonState.actionstepContext?.orgName ?? orgKey;
            const showUpdatedTermsMessage = commonState.actionstepContext?.termsEverAccepted && !commonState.actionstepContext?.latestTermsAccepted;
            return (<AccepTermsWidget orgKey={orgKey} orgName={orgName} showUpdatedTermsMessage={showUpdatedTermsMessage} onAccepted={() => dispatch(resetOrgAndMatterRequestStatus())} />);
        }
    }

    return (<Route {...props} />);
}