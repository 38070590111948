
import { RouterState } from "connected-react-router";

import { PexaState } from "containers/pexa/redux/reducer";
import { SecurexchangeState } from "containers/securexchange/redux/reducer";
import { SettlementInfoState } from "containers/calculators/settlement/redux/reducer";
import { CommonState } from "redux/common/reducer";
import { FirstTitleState } from "containers/firsttitle/redux/reducer";
import { GlobalXState } from "containers/globalx/redux/reducer";
import { UserAuditTrailState } from "containers/konekta/redux/reducer";
import { EmailArchiverState } from "containers/email-archiver/redux/reducer";
import { ECafState } from "containers/ecaf/redux/reducer";
import { RPIClientDetailState } from "containers/rapidBuildingInspections/redux/reducer"
import { IStewartTitleState } from "containers/stewartTitle/redux/reducer";



import { PexaActionTypes } from "containers/pexa/redux/actionTypes";
import { SecurexchangeActionTypes } from "containers/securexchange/redux/actionTypes";
import { SettlementActionTypes } from "containers/calculators/settlement/redux/actionTypes";
import { ICommonAction } from "redux/common/actions";
import { IFirstTitleAction } from "containers/firsttitle/redux/actions";
import { IGlobalXAction } from "containers/globalx/redux/actions";
import { UserAuditTrailActionTypes } from "containers/konekta/redux/actionTypes";
import { ErrorViewModel } from "utils/wcaApiTypes";
import { EmailArchiverActionTypes } from "./containers/email-archiver/redux/actionTypes";
import { ECafActionTypes } from "./containers/ecaf/redux/actionTypes";
import { RPIActionTypes } from "containers/rapidBuildingInspections/redux/actionTypes";
import { SpecialMeterReadingInfoState } from "containers/calculators/specialMeterReading/redux/reducer";
import { SpecialMeterReadingActionTypes } from "containers/calculators/specialMeterReading/redux/actionTypes";
import { StewartTitleActionTypes } from "containers/stewartTitle/redux/actionTypes";
import { ILegalAidState } from "containers/legalaid/redux/reducer";

export type AppState = {
    router: RouterState,
    common: CommonState,
    pexa: PexaState,
    securexchange: SecurexchangeState,
    globalx: GlobalXState,
    settlementInfo: SettlementInfoState,
    specialMeterReadingInfo: SpecialMeterReadingInfoState,
    firstTitle: FirstTitleState,
    userAuditTrail: UserAuditTrailState,
    emailArchiver: EmailArchiverState,
    eCaf: ECafState,
    RPI: RPIClientDetailState,
    stewartTitle: IStewartTitleState
    legalAid: ILegalAidState
};

export type AppActionTypes = PexaActionTypes |
    SecurexchangeActionTypes |
    IGlobalXAction |
    SettlementActionTypes |
    SpecialMeterReadingActionTypes |
    ICommonAction |
    IFirstTitleAction |
    UserAuditTrailActionTypes |
    EmailArchiverActionTypes |
    ECafActionTypes |
    RPIActionTypes |
    StewartTitleActionTypes

export interface IBasicAction {
    type: string
}

export interface IJWTInfo {
    action_id: number,
    action_type_id: number,
    action_type_name: string,
    aud: string,
    email: string,
    exp: number,
    iat: number,
    iss: string,
    jti: string,
    name: string,
    nbf: number,
    orgkey: string,
    sub: string,
    timezone: string
}

export class JwtMatterInfo {
    orgKey!: string;
    matterId!: number;
    actionTypeId!: number;
    actionTypeName!: string;
    timezone!: string;
}

export enum ReduxStatus {
    NotRequested,
    Requested,
    Success,
    Failed
}

export class ReduxData<T> {
    data?: T;
    status: ReduxStatus = ReduxStatus.NotRequested;
    error?: ErrorViewModel

    constructor(initialStatus: ReduxStatus = ReduxStatus.NotRequested, initialData: T | undefined = undefined) {
        this.data = initialData;
        this.status = initialStatus;
    }

    markAsSuccess = (data: T) => {
        this.data = data;
        this.status = ReduxStatus.Success;
        this.error = undefined;

        return this;
    }

    markAsFailed = (error: ErrorViewModel) => {
        this.data = undefined;
        this.error = error;
        this.status = ReduxStatus.Failed;

        return this;
    }
}