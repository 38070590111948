import * as React from 'react'
import ClearIcon from "@material-ui/icons/Clear";
import 'containers/ecaf/createOrder.css'
import {
    Fab,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    TextField,
    MuiThemeProvider
} from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import { ECAFClient } from '../../../utils/wcaApiTypes';
import { formLabelsTheme } from 'containers/ecaf/muiThemeStyles'


type AppProps = {
    updateClient: (updatedClient: ECAFClient, index: number) => void;
    removeParty: (partyIndex: number) => void;
    clientIndex: number;
    client: ECAFClient;
}

type AppStates = {
    refresh: boolean;
    validationErrors: string[];
}

class ECafClientForm extends React.Component<AppProps, AppStates> {
    constructor(props: AppProps) {
        super(props);

        this.state = {
            refresh: false,
            validationErrors: []
        }
    }

    isValidCountryCode(value: string): boolean {
        if (!value) return true;

        let internationalPhone = /^[0-9]{1,3}$/;
        var countryCode = value.trim();

        if (countryCode.match(internationalPhone)) return true;

        return false;
    }

    isValidMobileNumber(value: string): boolean {
        if (!value) return true;

        let internationalPhone = /^[0-9]{3,14}$/; //https://regex101.com/r/itwfrP/1
        let australianPhone = /^[0-9]{9}$/; // same as above but only 9 digits
        // let australianPhone = /^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/ // https://regex101.com/r/dkFASs/6 this is the full australian "phone number" (including landline) format
        let ausPhoneCode = this.props.client.countryCode === "61";

        var phoneNo = value.trim();

        if (ausPhoneCode ? phoneNo.match(australianPhone) : phoneNo.match(internationalPhone)) return true;

        return false;
    }

    invalidPhoneNumberMessage(): string {
        return this.props.client.countryCode === "61" ? "Please ensure your mobile number includes 9 digits and no space" : "Please ensure your mobile number includes 3 ~ 14 digits and no space";
    }

    isValidEmail(value: string): boolean {
        if (!value) return true;

        let simpleEmail = /.+\@.+\..+/; //https://regex101.com/r/yDvKPC/1

        if (value.match(simpleEmail)) return true;
        return false;
    }

    changeInfo = async (newValue: any, key: string) => {
        var client = this.props.client;
        var validationErrors = [...this.state.validationErrors];

        if (key === "isIndividual") {
            if (newValue === "true")
                client.isIndividual = true;
            else {
                client.isIndividual = false;
            }
        }

        if (key === "countryCode") {
            var index = validationErrors.indexOf(key);
            if (!this.isValidCountryCode(newValue)) {
                if (index == -1) { // not exit, add
                    validationErrors.push(key);
                    this.setState({
                        validationErrors: [...validationErrors]
                    });
                }
            } else {
                if (index > -1) { // exit, remove
                    validationErrors.splice(index, 1);
                    this.setState({
                        validationErrors: [...validationErrors]
                    });
                }
            }
        }

        if (key === "mobile") {
            var index = validationErrors.indexOf(key);
            if (!this.isValidMobileNumber(newValue)) {
                if (index == -1) { // not exit, add
                    validationErrors.push(key);
                    this.setState({
                        validationErrors: [...validationErrors]
                    });
                }
            } else {
                if (index > -1) { // exit, remove
                    validationErrors.splice(index, 1);
                    this.setState({
                        validationErrors: [...validationErrors]
                    });
                }
            }
        }

        if (key === "email") {
            var index = validationErrors.indexOf(key);
            if (!this.isValidEmail(newValue)) {
                if (index == -1) { // not exit, add
                    validationErrors.push(key);
                    this.setState({
                        validationErrors: [...validationErrors]
                    });
                }
            } else {
                if (index > -1) { // exit, remove
                    validationErrors.splice(index, 1);
                    this.setState({
                        validationErrors: [...validationErrors]
                    });
                }
            }
        }

        switch (key) {
            case "email":
                client.emailAddress = newValue;
                break;
            case "companyName":
                client.companyName = newValue;
                break;
            case "firstName":
                client.firstName = newValue;
                break;
            case "middleName":
                client.middleName = newValue;
                break;
            case "lastName":
                client.lastName = newValue;
                break;
            case "countryCode":
                client.countryCode = newValue;
                break;
            case "mobile":
                client.mobile = newValue;
                break;
            case "address":
                client.address = newValue;
                break;
        }
        client.hasInvalidField = validationErrors.length > 0;
        this.props.updateClient(client, this.props.clientIndex);
    }

    render(): JSX.Element {

        const { clientIndex, removeParty, client } = this.props;
        const { validationErrors } = this.state;

        return (

            <MuiThemeProvider theme={formLabelsTheme}>
                <div className="section">
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <div className="section-name"> CLIENT {clientIndex + 1} </div>
                        </Grid>

                        <Grid item xs={6}>
                            <Fab variant="extended" className="remove-btn" size="small" onClick={() => removeParty(clientIndex)}>
                                <ClearIcon />
                                Remove
                            </Fab>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <FormControl >
                                <RadioGroup
                                    row
                                    name="Client type"
                                    value={String(client.isIndividual)}
                                    onChange={(event, newValue) => this.changeInfo(newValue, "isIndividual")}
                                >
                                    <FormControlLabel
                                        value="true"
                                        control={<Radio color="primary" />}
                                        label="Individual"
                                    />
                                    <FormControlLabel
                                        value="false"
                                        control={<Radio color="primary" />}
                                        label="Organisation"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Email"
                                required
                                error={validationErrors?.includes("email") ? true : false}
                                helperText={validationErrors?.includes("email") ? "Invalid email" : undefined}
                                variant="outlined"
                                className="text-field"
                                size="small"
                                InputLabelProps={{
                                    shrink: true
                                }}
                                value={client.emailAddress ?? ""}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.changeInfo(event.target.value, "email")}
                            />
                        </Grid>
                        {!client.isIndividual &&
                            <>
                                <Grid item xs={6}>
                                    <TextField
                                        label="ORGANISATION NAME"
                                        required
                                        variant="outlined"
                                        className="text-field"
                                        size="small"
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        value={client.companyName ?? ""}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.changeInfo(event.target.value, "orgName")}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        label="ABN/ACN"
                                        required
                                        variant="outlined"
                                        className="text-field"
                                        size="small"
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        value={client.abnOrAcn ?? ""}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.changeInfo(event.target.value, "abnOrAcn")}
                                    />
                                </Grid>
                            </>
                        }
                        <Grid item xs={6}>
                            <TextField
                                label="FIRST NAME"
                                required
                                variant="outlined"
                                className="text-field"
                                size="small"
                                InputLabelProps={{
                                    shrink: true
                                }}
                                value={client.firstName ?? ""}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.changeInfo(event.target.value, "firstName")}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="MIDDLE NAME"
                                variant="outlined"
                                className="text-field"
                                size="small"
                                InputLabelProps={{
                                    shrink: true
                                }}
                                value={client.middleName ?? ""}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.changeInfo(event.target.value, "middleName")}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="LAST NAME"
                                required
                                variant="outlined"
                                className="text-field"
                                size="small"
                                InputLabelProps={{
                                    shrink: true
                                }}
                                value={client.lastName ?? ""}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.changeInfo(event.target.value, "lastName")}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container spacing={3}>
                                <Grid item xs={4}>
                                    <TextField
                                        label="Country Code"
                                        required
                                        error={validationErrors?.includes("countryCode") ? true : false}
                                        helperText={validationErrors?.includes("countryCode") ? "Invalid code" : undefined}
                                        variant="outlined"
                                        className="text-field"
                                        size="small"
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        inputProps={{
                                            maxLength: 3,
                                            minLength: 1,
                                            style: { textAlign: 'center' }
                                        }}
                                        value={client.countryCode ?? ""}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.changeInfo(event.target.value, "countryCode")}
                                    />
                                </Grid>
                                <Grid item xs={8}>
                                    <TextField
                                        label="Mobile"
                                        required
                                        error={validationErrors?.includes("mobile") ? true : false}
                                        helperText={validationErrors?.includes("mobile") ? this.invalidPhoneNumberMessage() : undefined}
                                        variant="outlined"
                                        className="text-field"
                                        size="small"
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        value={client.mobile ?? ""}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.changeInfo(event.target.value, "mobile")}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="ADDRESS"
                                required
                                error={false}
                                variant="outlined"
                                className="text-field"
                                size="small"
                                InputLabelProps={{
                                    shrink: true
                                }}
                                value={client.address ?? ""}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.changeInfo(event.target.value, "address")}
                            />
                        </Grid>
                    </Grid>

                </div>
            </MuiThemeProvider>

        )
    }
}


export default ECafClientForm;