import * as React from 'react';
import { Stack } from 'office-ui-fabric-react';
import Tools from '../../../../utils/tools';

interface IProps {
    premium: number;
    gstOnPremium: number;
    stampDuty: number;
}

interface IState { }

class PolicyPricing extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {}
    }

    render() {
        return (
            <Stack.Item>
                <table>
                    <tr>
                        <td>Premium</td>
                        <td><strong>{this.props.premium && Tools.formatAsCurrency(this.props.premium)}</strong></td>
                    </tr>
                    <tr>
                        <td>GST</td>
                        <td><strong>{this.props.gstOnPremium && Tools.formatAsCurrency(this.props.gstOnPremium)}</strong></td>
                    </tr>
                    <tr>
                        <td>Stamp Duty</td>
                        <td><strong>{this.props.stampDuty && Tools.formatAsCurrency(this.props.stampDuty)}</strong></td>
                    </tr>
                </table>
            </Stack.Item>
        )
    }
}

export default PolicyPricing;