import * as CONSTANTS from './constants'

import {
    ActionFailed,
    GetMatterDetailsFromActionstep,
    GetMatterDetailsFromActionstepSuccess,
    CreateOrder,
    CreateOrderSuccess,
    GetECAFCredentialsSuccess,
    GetECAFCredentialsRequested,
    GetActionstepOrgsSuccess,
    GetActionstepOrgsRequested,
    GetEnvelopeStatus,
    GetEnvelopeStatusSuccess,
    GetECAFOrderStatusPage,
    GetECAFOrderStatusPageSuccess,
    UpdateVOIStatus,
    UpdateCounterSignStatus,
    GetValidECAFCredential,
    GetValidECAFCredentialSuccess,
    GetEnvelopeSummary,
    GetEnvelopeSummarySuccess,
    GetECAFOrderlist,
    GetECAFOrderlistSuccess,
    DeleteEnvelope,
    DeleteEnvelopeSuccess,
    EditEnvelopeCustomer,
    EditEnvelopeCustomerSuccess,
    GetCountryPhoneCodes,
    GetCountryPhoneCodesSuccess,
    ResendCustomerNotification,
    ResendCustomerNotificationSuccess,
    ResendNotificationStateClearState,
    DeleteCustomer,
    DeleteCustomerSuccess,
    GetReportDataSuccess,
    GetReportQuery,
    GetReportDataClearState
} from "./actionTypes";
import {
    ActionstepMatterInfo,
    ECAFGetMatterDetailsFromActionstepQueryResponse,
    ErrorViewModel,
    ECAFCredentials,
    ConnectedActionstepOrgsResponse,
    CreateOrderQuery,
    CreateOrderResponse,
    ECAFEnvelopeResponse,
    ECAFUpdateVOIStatusQuery,
    ECAFUpdateCounterSignStatusQuery,
    ECAFOrderResult
} from '../../../utils/wcaApiTypes';

import {
    ECAFCustomerEditModel,
    CountryPhoneCodeViewModel,
    ECAFEditEnvelopeCustomerViewModel,
    ECAFReportQuery,
    ECAFReportResponse
} from '../../../utils/newWcaApiTypes'
export function getMatterDetailsFromActionstep(matterInfo: ActionstepMatterInfo): GetMatterDetailsFromActionstep {
    return {
        type: CONSTANTS.GET_MATTER_DETAILS_FROM_ACTIONSTEP_REQUESTED,
        matterInfo
    }
}

export function getMatterDetailsFromActionstepSuccess(data: ECAFGetMatterDetailsFromActionstepQueryResponse): GetMatterDetailsFromActionstepSuccess {
    return {
        type: CONSTANTS.GET_MATTER_DETAILS_FROM_ACTIONSTEP_SUCCESS,
        data
    }
}

export function getMatterDetailsFromActionstepFailed(error: ErrorViewModel): ActionFailed {
    return {
        type: CONSTANTS.GET_MATTER_DETAILS_FROM_ACTIONSTEP_FAILED,
        error
    }
}

export function getValidECAFCredential(matterInfo: ActionstepMatterInfo): GetValidECAFCredential {
    return {
        type: CONSTANTS.GET_VALID_ECAF_CREDENTIAL_REQUESTED,
        matterInfo
    }
}

export function getValidECAFCredentialSuccess(data: boolean): GetValidECAFCredentialSuccess {
    return {
        type: CONSTANTS.GET_VALID_ECAF_CREDENTIAL_SUCCESS,
        data
    }
}

export function getValidECAFCredentialFailed(error: ErrorViewModel): ActionFailed {
    return {
        type: CONSTANTS.GET_VALID_ECAF_CREDENTIAL_FAILED,
        error
    }
}

export function checkECAFCredentials(data: ECAFCredentials): GetECAFCredentialsRequested {
    return {
        type: CONSTANTS.CHECK_ECAF_CREDENTIAL_REQUESTED,
        data
    }
}

export function checkECAFCredentialsSuccess(data: boolean): GetECAFCredentialsSuccess {
    return {
        type: CONSTANTS.CHECK_ECAF_CREDENTIAL_SUCCESS,
        data
    }
}

export function checkECAFCredentialsFailed(error: ErrorViewModel): ActionFailed {
    return {
        type: CONSTANTS.CHECK_ECAF_CREDENTIAL_FAILED,
        error
    }
}

export function getActionstepOrgs(): GetActionstepOrgsRequested {
    return {
        type: CONSTANTS.GET_ACTIONSTEP_ORGS_REQUESTED
    }
}

export function getActionstepOrgsSuccess(data: ConnectedActionstepOrgsResponse[]): GetActionstepOrgsSuccess {
    return {
        type: CONSTANTS.GET_ACTIONSTEP_ORGS_SUCCESS,
        data
    }
}

export function createOrder(params: CreateOrderQuery): CreateOrder {
    return {
        type: CONSTANTS.CREATE_ORDER_REQUESTED,
        params
    }
}

export function createOrderSuccess(data: CreateOrderResponse): CreateOrderSuccess {
    return {
        type: CONSTANTS.CREATE_ORDER_SUCCESS,
        data
    }
}


export function getActionstepOrgsFailed(error: ErrorViewModel): ActionFailed {
    return {
        type: CONSTANTS.GET_ACTIONSTEP_ORGS_FAILED,
        error
    }
}

export function createOrderFailed(error: ErrorViewModel): ActionFailed {
    return {
        type: CONSTANTS.CREATE_ORDER_FAILED,
        error
    }
}

export function getEnvelopeStatus(id: string, code: string): GetEnvelopeStatus {
    return {
        type: CONSTANTS.GET_ECAF_ENVELOPE_STATUS_REQUESTED,
        id,
        code
    }
}

export function getEnvelopeStatusSuccess(data: ECAFEnvelopeResponse): GetEnvelopeStatusSuccess {
    return {
        type: CONSTANTS.GET_ECAF_ENVELOPE_STATUS_SUCCESS,
        data
    }
}

export function getEnvelopeStatusFailed(error: ErrorViewModel): ActionFailed {
    return {
        type: CONSTANTS.GET_ECAF_ENVELOPE_STATUS_FAILED,
        error
    }
}
export function getECAFOrderStatusPage(transactionId: string, transactionStatusType: string): GetECAFOrderStatusPage {
    return {
        type: CONSTANTS.GET_ECAF_ORDER_STATUS_PAGE_REQUESTED,
        transactionId,
        transactionStatusType
    }
}

export function getECAFOrderStatusPageSuccess(data: string): GetECAFOrderStatusPageSuccess {
    return {
        type: CONSTANTS.GET_ECAF_ORDER_STATUS_PAGE_SUCCESS,
        data
    }
}
export function getECAFOrderStatusPageFailed(error: ErrorViewModel): ActionFailed {
    return {
        type: CONSTANTS.GET_ECAF_ORDER_STATUS_PAGE_FAILED,
        error
    }
}

export function updateVOIStatus(data: ECAFUpdateVOIStatusQuery): UpdateVOIStatus {
    return {
        type: CONSTANTS.UPDATE_VOI_STATUS_REQUESTED,
        data
    }
}

export function updateCounterSignStatus(data: ECAFUpdateCounterSignStatusQuery): UpdateCounterSignStatus {
    return {
        type: CONSTANTS.UPDATE_COUNTER_SIGN_STATUS_REQUESTED,
        data
    }
}

export function getEnvelopeSummary(id: string): GetEnvelopeSummary {
    return {
        type: CONSTANTS.GET_ECAF_ENVELOPE_SUMMARY_REQUESTED,
        id
    }
}

export function getEnvelopeSummarySuccess(data: ECAFEnvelopeResponse): GetEnvelopeSummarySuccess {
    return {
        type: CONSTANTS.GET_ECAF_ENVELOPE_SUMMARY_SUCCESS,
        data
    }
}

export function getEnvelopeSummaryFailed(error: ErrorViewModel): ActionFailed {
    return {
        type: CONSTANTS.GET_ECAF_ENVELOPE_SUMMARY_FAILED,
        error
    }
}

export function getECAFOrderlist(orgKey: string, matterId: number): GetECAFOrderlist {
    return {
        type: CONSTANTS.GET_ECAF_ORDERLIST_REQUESTED,
        orgKey,
        matterId
    }
}

export function getECAFOrderlistSuccess(data: ECAFOrderResult[]): GetECAFOrderlistSuccess {
    return {
        type: CONSTANTS.GET_ECAF_ORDERLIST_SUCCESS,
        data
    }
}

export function getECAFOrderlistFailed(error: ErrorViewModel): ActionFailed {
    return {
        type: CONSTANTS.GET_ECAF_ORDERLIST_FAILED,
        error
    }
}
export function deleteEnvelope(envelopeId: string, orgKey: string): DeleteEnvelope {
    return {
        type: CONSTANTS.DELETE_ENVELOPE_REQUESTED,
        envelopeId,
        orgKey
    }
}

export function deleteEnevlopeSuccess(data: boolean): DeleteEnvelopeSuccess {
    return {
        type: CONSTANTS.DELETE_ENVELOPE_SUCCESS,
        data
    }
}

export function deleteEnevlopeFailed(error: ErrorViewModel): ActionFailed {
    return {
        type: CONSTANTS.DELETE_ENVELOPE_FAILED,
        error
    }
}

export function deleteCustomer(envelopeId: string, customerId: string, orgKey: string): DeleteCustomer {
    return {
        type: CONSTANTS.DELETE_CUSTOMER_REQUESTED,
        envelopeId,
        customerId,
        orgKey
    }
}

export function deleteCustomerSuccess(data: boolean): DeleteCustomerSuccess {
    return {
        type: CONSTANTS.DELETE_CUSTOMER_SUCCESS,
        data
    }
}

export function deleteCustomerFailed(error: ErrorViewModel): ActionFailed {
    return {
        type: CONSTANTS.DELETE_CUSTOMER_FAILED,
        error
    }
}

export function editEnvelopeCustomer(data: ECAFCustomerEditModel): EditEnvelopeCustomer {
    return {
        type: CONSTANTS.EDIT_ENVELOPE_CUSTOMER_REQUESTED,
        data
    }
}

export function editEnevlopeCustomerSuccess(data: boolean): EditEnvelopeCustomerSuccess {
    return {
        type: CONSTANTS.EDIT_ENVELOPE_CUSTOMER_SUCCESS,
        data
    }
}
export function editEnevlopeCustomerFailed(error: ErrorViewModel): ActionFailed {
    return {
        type: CONSTANTS.EDIT_ENVELOPE_CUSTOMER_FAILED,
        error
    }
}

export function getCountryPhoneCodes(orgKey: string): GetCountryPhoneCodes {
    return {
        type: CONSTANTS.GET_COUNTRY_PHONE_CODES_REQUESTED,
        orgKey
    }
}

export function getCountryPhoneCodesSuccess(data: CountryPhoneCodeViewModel[]): GetCountryPhoneCodesSuccess {
    return {
        type: CONSTANTS.GET_COUNTRY_PHONE_CODES_SUCCESS,
        data
    }
}
export function getCountryPhoneCodesFailed(error: ErrorViewModel): ActionFailed {
    return {
        type: CONSTANTS.GET_ECAF_ORDERLIST_FAILED,
        error
    }
}
export function resendCustomerNotification(data: ECAFEditEnvelopeCustomerViewModel[]): ResendCustomerNotification {
    return {
        type: CONSTANTS.RESEND_CUSTOMER_NOTIFICATION_REQUESTED,
        data
    }
}

export function resendCustomerNotificationSuccess(data: boolean): ResendCustomerNotificationSuccess {
    return {
        type: CONSTANTS.RESEND_CUSTOMER_NOTIFICATION_SUCCESS,
        data
    }
}
export function resendCustomerNotificationFailed(error: ErrorViewModel): ActionFailed {
    return {
        type: CONSTANTS.RESEND_CUSTOMER_NOTIFICATION_FAILED,
        error
    }
}

export function resendCustomerNotificationClearstate(): ResendNotificationStateClearState {
    return {
        type: CONSTANTS.RESEND_CUSTOMER_NOTIFICATION_CLEAR_STATE
    }
}

export function getReportData(data: ECAFReportQuery) : GetReportQuery {
    return {
        type: CONSTANTS.GET_REPORT_DATA_REQUESTED,
        data
    }
}

export function getReportDataSuccess(data: ECAFReportResponse) : GetReportDataSuccess {
    return {
        type: CONSTANTS.GET_REPORT_DATA_SUCCESS,
        data
    }
}

export function reportDataClearState() : GetReportDataClearState {
    return {
        type: CONSTANTS.GET_REPORT_DATA_CLEAR_STATE
    }
}

export function getReportDataFailed(error: ErrorViewModel): ActionFailed {
    return {
        type: CONSTANTS.GET_REPORT_DATA_FAILED,
        error
    }
}