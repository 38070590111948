import * as React from 'react'
import { AppState } from '../../../app.types';
import { connect } from 'react-redux';

// Office Facric imports
import { Dialog, DialogFooter,  PrimaryButton } from 'office-ui-fabric-react';

// FluentUI imports
import { Dropdown, IDropdownStyles } from '@fluentui/react/lib/Dropdown';
import { Label } from '@fluentui/react/lib/Label';
import { PartyMapperOptions, NotificationTypeOptions } from '../constants';

interface IAppProps {
    ConfirmHandler: (type: string, party:string) => void;
}


// Styles
const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: { width: 232 }
};

// Type declaration
type AppProps = IAppProps & ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

type AppStates = {
    notificationType: string;
    partyToMap: string;
    hideRemoveDialog: boolean,
}

export class PreferenceComponent extends React.Component<AppProps, AppStates> {
    constructor(props: AppProps) {
        super(props);

        this.state = {
            notificationType: "EmailAndSms",
            partyToMap: "Buyer",
            hideRemoveDialog:false
        }
    }

    private UpdateNotificationTypeHandler = (elem: any, option: any) => {
        this.setState({ notificationType: option.key });
    }

    private UpdatePartyToMapHandler = (elem: any, option: any) => {
        this.setState({ partyToMap: option.key });
    }

    private ConfirmHandler = () => {
        this.setState({
            hideRemoveDialog: true
        })
        this.props.ConfirmHandler(this.state.notificationType, this.state.partyToMap);
    }

    render() {
        return (
            <Dialog
                hidden={this.state.hideRemoveDialog}
                onDismiss={() => { }}
                minWidth={400}
                maxWidth={500}
                dialogContentProps={{
                    title: 'Party you are preparing eCAF for',
                    subText: ""
                }}
            >

                <div className="party-mapper-element-container">
                    <Label htmlFor={"party-mapper-dropdown"}>Choose party</Label>
                    <Dropdown
                        id={"party-mapper-dropdown"}
                        label=""
                        defaultSelectedKey="Buyer"
                        onChange={(elem, option) => {
                            if (option) {
                                this.UpdatePartyToMapHandler(elem,option);
                            }
                        }}
                        options={PartyMapperOptions}
                        disabled={false}
                        styles={dropdownStyles}
                    />
                </div>

                <div className="notification-type-element-container">
                    <Label htmlFor={"notification-type-dropdown"}>Choose notification Type</Label>
                    <Dropdown
                        id={"notification-type-dropdown"}
                        label=""
                        defaultSelectedKey="EmailAndSms"
                        onChange={(elem, option) => {
                            if (option) {
                                this.UpdateNotificationTypeHandler(elem,option);
                            }
                        }}
                        options={NotificationTypeOptions}
                        disabled={false}
                        styles={dropdownStyles}
                    />
                </div>

                <DialogFooter>
                    <PrimaryButton onClick={() => {
                        this.ConfirmHandler();
                    }} text="Confirm" />
                </DialogFooter>
            </Dialog>
        )
    }
}

const mapStateToProps = (state: AppState) => {
    return {

    }
}

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(PreferenceComponent);