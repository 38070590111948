import * as React from 'react'
import { connect } from 'react-redux'
import { IDropdownOption } from 'office-ui-fabric-react/lib/Dropdown';
import { AppState } from 'app.types';
import { ComboBox } from '@fluentui/react';

interface IMapStateToProps {
    state: string;
}

interface IMapDispatchToProps { }
interface IProps {
    updateValue: (newValue: any, whichValue: string) => void;
    useAdvancedCalculator: boolean;
}

type AppProps = IMapStateToProps & IProps & IMapDispatchToProps;
type AppStates = {}
export class AddAdjustment extends React.Component<AppProps, AppStates> {

    constructor(props: AppProps) {
        super(props);

        this.adjustmentTypes = this.props.useAdvancedCalculator ? [
            { key: 'Release Fee', text: 'Release Fee' },
            { key: 'Discharge Fee', text: 'Discharge Fee' },
            { key: 'Water Usage', text: 'Water Usage' },
            { key: 'Emergency Services Levy', text: 'Emergency Services Levy' },
            { key: 'Water and Sewerage Rates', text: 'Water and Sewerage Rates' },
            { key: 'Council Rates', text: 'Council Rates' },
            { key: 'Licence Fees', text: 'Licence Fees' },
            { key: 'Insurance', text: 'Insurance' },
            { key: 'Penalty Interest', text: 'Penalty Interest' },
            { key: 'Other Adjustment', text: 'Other Adjustment - Fixed Amount' },
            { key: 'Other Adjustment Date', text: 'Other Adjustment - Date Period' },
            { key: 'Recycled Water', text: 'Recycled Water' },
            { key: 'Land Tax', text: 'Land Tax' },
            { key: 'Local Land Services', text: 'Local Land Services' },
            { key: 'Water Availability', text: 'Water Availability' },
            { key: 'Default Interest', text: 'Default Interest' },
            { key: 'Owners Corporation Fees', text: 'Owners Corporation Fees' },
            { key: 'Parks Charge', text: 'Parks Charge' },
            { key: 'Drainage Charge', text: 'Drainage Charge' },
            { key: 'Rent', text: 'Rent' },
            { key: 'Water Rates', text: 'Water Rates' },
            { key: 'Sewerage Rates', text: 'Sewerage Rates' },
            { key: 'Maintenance Fund', text: 'Maintenance Fund' },
            ...(this.props.state === "SA" || this.props.state === "NSW" ? [
                { key: 'Strata Levies', text: 'Strata Levies' },
                { key: 'SA Water Supply and Sewerage', text: 'SA Water Supply and Sewerage' },
            ] : [
                { key: 'Water Access Fee', text: 'Water Access Fee' },
                { key: 'Sewerage Access Fee', text: 'Sewerage Access Fee' },
                { key: 'Administration Fund', text: 'Administration Fund' },
                { key: 'Sinking Fund', text: 'Sinking Fund' },
            ]),
        ] : [
            { key: 'Other Adjustment', text: 'Other Adjustment - Fixed Amount' },
            { key: 'Other Adjustment Date', text: 'Other Adjustment - Date Period' },
            { key: 'Water and Sewerage Rates', text: 'Water and Sewerage Rates' },
            { key: 'Water Usage', text: 'Water Usage' },
        ];

        // reset the item type
        this.props.updateValue('', 'itemType');
    }

    adjustmentTypes: IDropdownOption[];

    public render(): JSX.Element {
        const adjustmentTypes = this.adjustmentTypes.sort((a, b) => a.key > b.key ? 1 : -1);

        return (
            <div className="modal-body">
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row modal-row">
                        <div className="ms-Grid-col ms-sm4 modal-label">New Item Type :</div>
                        <div className="ms-Grid-col ms-sm8">
                            <ComboBox
                                allowFreeform
                                openOnKeyboardFocus
                                placeholder="Please Select"
                                data-cy="adjustment_type_select"
                                id="adjustment_type_select"
                                options={adjustmentTypes}
                                onChange={(ev, item) => {
                                    if (item?.key) {
                                        this.props.updateValue(item!.key, 'itemType')
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state: AppState): IMapStateToProps => {
    return {
        state: state.settlementInfo.state
    }
}

export default connect(mapStateToProps)(AddAdjustment);