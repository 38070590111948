import React from "react";
import { Separator } from "@fluentui/react";
import { toFormatLocalString, formatToLocaleDateString } from 'containers/calculators/settlement/Helper';
import "../VicWaterUsage.css";
import { IconButton } from "@fluentui/react";
import { apportionmentOption } from "containers/calculators/settlement/common";

type props = {
    waterUsage: { [key: string]: any; };
    edit: () => void;
}

const WaterUsagesCalculation: React.FC<props> = ({ waterUsage, edit }) => {
    return (
        <div className="section">
            <div className="section-body">
                <div className="ms-Grid-row state-row">
                    <div className="ms-Grid-col ms-sm12">
                        <div className="ms-Grid" dir="ltr">
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm1">
                                    <IconButton
                                        className="topaz-blue-icon-button"
                                        data-automation-id="edit_button"
                                        onClick={() => edit()}
                                        allowDisabledFocus={true}
                                        iconProps={{ iconName: "ChevronRightSmall" }}
                                        data-cy={"adjustment_info_"}
                                    />
                                </div>
                                <div className="ms-Grid-col ms-sm6">
                                    <b>Water Usage Calculation</b>
                                    <div>Date of search reading : {waterUsage && formatToLocaleDateString(waterUsage["searchDate"])}</div>
                                </div>
                                <div className="ms-Grid-col ms-sm3"></div>
                            </div>
                            <Separator></Separator>
                            <div className="ms-Grid-row">

                                <div className="ms-Grid-col ms-sm12 calculationcenter">
                                    <b>Average Daily Usage</b><br />

                                    {waterUsage["showJulyCalculation"] === true &&
                                        <>
                                            Days from date paid to 30 June = {waterUsage["numberOfDaysToJune"]}<br />
                                            Days from 1 July to settlement = {waterUsage["numberOfDaysFromJuly"]}<br />
                                        </>
                                    }
                                    {waterUsage["showJulyCalculation"] === false &&
                                        <>
                                            Days from date paid to settlement = {waterUsage["numberOfDays"]}<br />
                                        </>
                                    }
                                </div>
                            </div>

                            <Separator></Separator>
                            <div className="ms-Grid-row">
                                <b>Water Adjustment</b>
                            </div>
                            {waterUsage["showJulyCalculation"] === true ?
                                <>
                                    <div className="ms-Grid-row state-row-no-padding">
                                        <div className="ms-Grid-col ms-sm6">
                                            <span>Water Usage</span>
                                        </div>
                                        <div className="ms-Grid-col ms-sm4">
                                            {waterUsage['numberOfDaysToJune']}/{waterUsage['numberOfDays']}days x ${waterUsage['waterUsage']}
                                        </div>
                                        <div className="ms-Grid-col ms-sm2  content-right-align">
                                            {toFormatLocalString(waterUsage["waterUsage30June"], "en-AU", 2, 2)}
                                        </div>
                                    </div>

                                    <div className="ms-Grid-row state-row-no-padding">
                                        <div className="ms-Grid-col ms-sm6">
                                            <span>Sewer Usage</span>
                                        </div>
                                        <div className="ms-Grid-col ms-sm4">
                                            {waterUsage['numberOfDaysToJune']}/{waterUsage['numberOfDays']}days x ${waterUsage['sewerUsage']}
                                        </div>
                                        <div className="ms-Grid-col ms-sm2  content-right-align">
                                            {toFormatLocalString(waterUsage["sewerUsage30June"], "en-AU", 2, 2)}
                                        </div>
                                    </div>

                                    <div className="ms-Grid-row state-row-no-padding">
                                        <div className="ms-Grid-col ms-sm4">
                                            <b>from 1 July</b>
                                        </div>
                                    </div>

                                    <div className="ms-Grid-row state-row-no-padding">
                                        <div className="ms-Grid-col ms-sm6">
                                            <span>Water Usage</span>
                                        </div>
                                        <div className="ms-Grid-col ms-sm4">
                                            {waterUsage['numberOfDaysFromJuly']}/{waterUsage['numberOfDays']}days x ${waterUsage['waterUsageIncrease']}
                                        </div>
                                        <div className="ms-Grid-col ms-sm2  content-right-align">
                                            {toFormatLocalString(waterUsage["waterUsageResult"], "en-AU", 2, 2)}
                                        </div>
                                    </div>

                                    <div className="ms-Grid-row state-row-no-padding">
                                        <div className="ms-Grid-col ms-sm6">
                                            <span>Sewer Usage</span>
                                        </div>
                                        <div className="ms-Grid-col ms-sm4">
                                            {waterUsage['numberOfDaysFromJuly']}/{waterUsage['numberOfDays']}days x ${waterUsage['sewerUsageIncrease']}
                                        </div>
                                        <div className="ms-Grid-col ms-sm2  content-right-align">
                                            {toFormatLocalString(waterUsage["sewerUsageResult"], "en-AU", 2, 2)}
                                        </div>
                                    </div>
                                </>
                                :
                                <>
                                    <div className="ms-Grid-row state-row-no-padding">
                                        <div className="ms-Grid-col ms-sm6">
                                            <span>Water Usage</span>
                                        </div>
                                        <div className="ms-Grid-col ms-sm4">
                                            {waterUsage['numberOfDays']}days x ${waterUsage['waterUsage']}
                                        </div>
                                        <div className="ms-Grid-col ms-sm2  content-right-align">
                                            {toFormatLocalString(waterUsage["waterUsageFull"], "en-AU", 2,2)}
                                        </div>
                                    </div>

                                    <div className="ms-Grid-row state-row-no-padding">
                                        <div className="ms-Grid-col ms-sm6">
                                            <span>Sewer Usage</span>
                                        </div>
                                        <div className="ms-Grid-col ms-sm4">
                                            {waterUsage['numberOfDays']}days x ${waterUsage['sewerUsage']}
                                        </div>
                                        <div className="ms-Grid-col ms-sm2  content-right-align">
                                            {toFormatLocalString(waterUsage["sewerUsageFull"], "en-AU", 2, 2)}
                                        </div>
                                    </div>
                                </>
                            }

                            <div className="ms-Grid-col ms-sm12 separator">
                                --------------<br />
                                <b>{waterUsage['ctsOption'] === apportionmentOption.entitlement
                                    ? `$${waterUsage["showJulyCalculation"] === true ?
                                        toFormatLocalString(waterUsage['sumOfResultIncludingJuly'], "en-AU", 2, 2) :
                                        toFormatLocalString(waterUsage['sumOfResultExcludingJuly'], "en-AU", 2, 2)} x ${waterUsage['entitlementValue']}  = $${toFormatLocalString(waterUsage['finalWaterUsageResult'], "en-AU", 2, 2)}`
                                    : waterUsage['ctsOption'] === apportionmentOption.sharedPercentage
                                        ? `$${waterUsage["showJulyCalculation"] === true ?
                                            toFormatLocalString(waterUsage['sumOfResultIncludingJuly'], "en-AU", 2, 2) :
                                            toFormatLocalString(waterUsage['sumOfResultExcludingJuly'], "en-AU", 2, 2)} x ${waterUsage['percentage']}% = $${toFormatLocalString(waterUsage['finalWaterUsageResult'], "en-AU", 2, 2)}`
                                        : `$${toFormatLocalString(waterUsage['finalWaterUsageResult'], "en-AU", 2, 2)}`
                                }</b><br />
                                --------------
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default WaterUsagesCalculation;