import * as React from 'react';
import { AppState } from 'app.types';
import { connect } from 'react-redux';
import { MessageBar, MessageBarType } from 'office-ui-fabric-react'
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import { PrimaryButton } from "office-ui-fabric-react/lib/Button";
import { CommandBarButton, DefaultButton } from '@fluentui/react/lib/Button';
import { IIconProps } from '@fluentui/react/lib/Icon';
import * as CONSTANTS from 'containers/ecaf/redux/constants';
import 'containers/ecaf/orderlistPage.css';
import { renderCustomerStatus, renderStatus } from './util/util';
import EditOrder from './editOrder';
import ResendNotificationComponent from './components/eCafResendNotification';

import {
    getECAFOrderlist,
    getECAFOrderStatusPage,
    deleteEnvelope,
    deleteCustomer
} from 'containers/ecaf/redux/actions';

import { Redirect } from 'react-router-dom';
import { ECAFOrderResult, ECAFEnvelopeStatus, EnvelopeDetailsCustomerViewModel, EnvelopeDetailsViewModel } from '../../utils/wcaApiTypes';
import { Dialog, DialogFooter, DialogType, Modal, Separator } from '@fluentui/react/lib';

interface IAppProps {
}

type AppStates = {
    isLoading: boolean,
    pageError: string | undefined,
    redirect: string,
    resendStatus: boolean | undefined,
    openDeleteModal: boolean,
    openCustomerDeleteModal: boolean,
    envelopeId: string,
    customerId: string,
    orgKey: string,
    showResendMessageBar: boolean,
    showCannotDeleteMessageBar: boolean,
    editOrderData: {
        showEditOrderModal: boolean,
        ecafOrder: ECAFOrderResult,
        customer: EnvelopeDetailsCustomerViewModel,
        showClientsModal: boolean
    }

}

type AppProps = IAppProps & ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

class OrderListPage extends React.Component<AppProps, AppStates> {

    constructor(props: Readonly<AppProps>) {
        super(props);

        this.state = {
            isLoading: true,
            pageError: undefined,
            redirect: "",
            openDeleteModal: false,
            openCustomerDeleteModal: false,
            envelopeId: "",
            customerId: "",
            orgKey: "",
            resendStatus: undefined,
            showResendMessageBar: false,
            showCannotDeleteMessageBar: false,
            editOrderData: {
                showEditOrderModal: false,
                ecafOrder: new ECAFOrderResult,
                customer: new EnvelopeDetailsCustomerViewModel,
                showClientsModal: false
            }

        }
    }

    public componentDidMount(): void {
        this.loadOrderlist();
    }

    private loadOrderlist(): void {
        const { actionstepContext } = this.props;

        if (actionstepContext === undefined
            || actionstepContext.matterContext === undefined) {
            this.setState({
                pageError: " Actionstep Matter not found "
            });

            return;
        } else {
            this.setState({
                isLoading: true,
                showResendMessageBar: false,
                showCannotDeleteMessageBar: false,
            });
        }

        this.props.getECAFOrderlist(actionstepContext.orgKey, actionstepContext.matterContext.id);
    }

    private redirectToHome = () => {
        this.setState({ redirect: "/matter" });
    }

    private getOrderLink = (order: ECAFOrderResult, customerId: string = ""): string => {

        let orderLink: string = "";
        orderLink = "/ecaf/summary?actionsteporg=" + order.orgKey + "&matterid=" + order.matterId + "&id=" + order.envelopeId + "&customerId=" + customerId;

        let code: string = "";
        let status = order.envelopeDetails?.status;
        let type = "";
        if (status == ECAFEnvelopeStatus.RequiresReview) {
            code = order.voiVerificationCode ?? code;
            type = status;
        }
        else if ((status == ECAFEnvelopeStatus.ReadyToCountersign || status == ECAFEnvelopeStatus.Finalised)) {
            code = order.counterSignVerificationCode ?? code;
            type = status;
        }
        else {
            return orderLink;
        }
        orderLink = "/ecaf/envelope-status?actionsteporg=" + order.orgKey + "&matterid=" + order.matterId + "&id=" + order.envelopeId + "&code=" + code + "&transactionId=" + order.transactionId + "&type=" + type + "&customerId=" + customerId
        return orderLink;
    }

    private handleManageOrder(ecafOrder: ECAFOrderResult, customer: EnvelopeDetailsCustomerViewModel) {
        this.setState(prevState => ({
            ...prevState,
            editOrderData: {
                showEditOrderModal: true,
                ecafOrder: ecafOrder,
                customer: customer,
                showClientsModal: false
            }
        }))
    }

    // private handleCustomer(customer: EnvelopeDetailsCustomerViewModel) {
    //     let orderData = this.state.editOrderData;
    //     orderData.showEditOrderModal = true;
    //     orderData.showClientsModal = true;
    //     orderData.customer = customer;


    //     this.setState(prevState => ({
    //         ...prevState,
    //         editOrderData: orderData
    //     }))
    // }

    private resendNotificationStatus = (status: boolean) => {
        this.setState({
            resendStatus: status,
            showResendMessageBar: true,
        })
    }

    private validateCustomerDelete = (envelope: EnvelopeDetailsViewModel | undefined, customer: EnvelopeDetailsCustomerViewModel) => {
        return (envelope?.customers?.length ?? 0) > 1 && customer.voiReviewStatus != 'Approved';
    }

    private renderOrderListSplittingParties = () => {
        const { actionstepContext, orderList } = this.props;
        const deleteIcon: IIconProps = { iconName: 'Delete' };
        const editIcon: IIconProps = { iconName: 'Edit' };

        if (orderList?.length == 0) {
            return <div className='text-center'>No envelope found</div>
        }

        let item = orderList?.map((order: ECAFOrderResult) => {
            let firmPays = order.envelopeDetails?.payMethod === "AccountHolderPays";

            // details and actions for each document of each user of an envelope
            let userDetails = (customer: EnvelopeDetailsCustomerViewModel) => <tr className="row-wrapper" key={Math.random()}>
                <td className="order-id">
                    <a className="order-link" href={this.getOrderLink(order)}><span className="customer-name">{customer.firstName ?? ''} {customer.lastName ?? ''}</span></a>
                </td>
                <td>
                    {actionstepContext?.matterContext?.id}
                </td>
                <td>
                    {order.envelopeDetails?.createdOn ? new Date(order.envelopeDetails?.createdOn).toLocaleDateString("en-AU") : ""}
                </td>
                <td className={('status-' + customer.voiReviewStatus) + " status-shape-orderlist center-aligned"}>
                    <a className="order-link-status" href={this.getOrderLink(order)}> {renderCustomerStatus(customer.voiReviewStatus)}</a>
                </td>
                <td>
                    <ResendNotificationComponent ecafOrder={order} customerId={customer.id} callbackResendResponse={this.resendNotificationStatus} />
                    <CommandBarButton iconProps={editIcon} text="Edit" onClick={() => this.handleManageOrder(order, customer)} />
                    <CommandBarButton iconProps={deleteIcon} text="Delete Customer" onClick={() => {
                        if (this.validateCustomerDelete(order.envelopeDetails, customer)) {
                            this.toggleCustomerDeleteModalState(true, order.envelopeId ?? "", customer?.id ?? "", actionstepContext?.orgKey ?? "")
                        } else {
                            this.setState({ showCannotDeleteMessageBar: true });
                        }
                    }} />
                </td>
            </tr> ?? [];

            return (
                <div className="envelope-block ">
                    <table className="envelope-table wrapper-content vertical-container orderlist-table">
                        <thead>
                            <tr>
                                <th className="left-aligned-text">
                                    <div>Envelope ID #{order.id}</div>
                                    {firmPays && <div>Total Fee: ${order.envelopeDetails?.totalPrice?.toFixed(2) ?? 0}</div>}
                                </th>
                                <th className={('status-' + order.envelopeDetails?.status) + " status-shape-orderlist"}>
                                    <a className="order-link-status" href={this.getOrderLink(order)}>{renderStatus(order.envelopeDetails?.status)}</a>
                                </th>
                                <th>
                                    <CommandBarButton iconProps={deleteIcon} text="Delete Envelope" onClick={() => { this.toggleDeleteModalState(true, order.envelopeId ?? "", actionstepContext?.orgKey ?? "") }} />
                                </th>
                            </tr>
                        </thead>

                    </table>

                    <table className="customer-table wrapper-content vertical-container orderlist-table">
                        <thead>
                            <tr>
                                <th className="cell-customers">Customer Name</th>
                                <th className="cell-matter">Matter</th>
                                <th className="cell-date-ordered">Date Ordered</th>
                                <th className="cell-status">Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {order.envelopeDetails?.customers?.map(customer => userDetails(customer))}
                        </tbody>
                    </table>
                </div>
            )
        });

        let element = <table className="vertical-container wrapper-content orderlist-table">
            <tbody>
                {item}
            </tbody>
        </table>

        return element;
    }

    // private renderOrderlist = () => {
    //     const { actionstepContext, orderList } = this.props;
    //     const deleteIcon: IIconProps = { iconName: 'Delete' };
    //     const editIcon: IIconProps = { iconName: 'Edit' };

    //     let items = orderList?.map((order: ECAFOrderResult) => {
    //         return (
    //             <tr className="row-wrapper" key={Math.random()}>
    //                 <td className="order-id">
    //                     <a className="order-link" href={this.getOrderLink(order)}><span className="customer-name"> {this.renderCustomerName(order.envelopeDetails?.customers!)}</span></a>
    //                 </td>
    //                 <td>
    //                     {actionstepContext?.matterContext?.id}
    //                 </td>
    //                 <td>
    //                     {order.envelopeDetails?.createdOn ? new Date(order.envelopeDetails?.createdOn).toLocaleDateString("en-AU") : ""}
    //                 </td>
    //                 <td>
    //                     <div className={('status-' + order.envelopeDetails?.status) + " status-shape-orderlist"}>
    //                         <a className="order-link-status" href={this.getOrderLink(order)}> {renderStatus(order.envelopeDetails?.status)}</a></div>
    //                 </td>
    //                 <td>
    //                     {order.envelopeDetails?.totalPrice ? "$" + order.envelopeDetails?.totalPrice.toFixed(2) : ""}
    //                 </td>
    //                 <td>
    //                     <ResendNotificationComponent ecafOrder={order} customerId="" callbackResendResponse={this.resendNotificationStatus} />
    //                     <CommandBarButton iconProps={editIcon} text="Edit" onClick={() => this.handleManageOrder(order)} />
    //                     <CommandBarButton iconProps={deleteIcon} text="Delete" onClick={() => { this.toggleDeleteModalState(true, order.envelopeId ?? "", actionstepContext?.orgKey ?? "") }} />
    //                 </td>
    //             </tr>
    //         )
    //     });

    //     let element = <table className="vertical-container wrapper-content orderlist-table">
    //         <thead>
    //             <tr>
    //                 <th className="cell-customers">Customer Name</th>
    //                 <th className="cell-matter">Matter</th>
    //                 <th className="cell-date-ordered">Date Ordered</th>
    //                 <th className="cell-status">Status</th>
    //                 <th className="cell-total-fee">Total Fee</th>
    //                 <th>Action</th>
    //             </tr>
    //         </thead>
    //         <tbody>
    //             {items}
    //         </tbody>
    //     </table>
    //     return element;
    // }

    // private renderCustomersList = () => {
    //     const { actionstepContext, orderList } = this.props;
    //     const { editOrderData } = this.state;
    //     const eCafOrder = orderList?.find(order => order.envelopeId === editOrderData.ecafOrder.envelopeId)
    //     editOrderData.ecafOrder = eCafOrder ? eCafOrder : editOrderData.ecafOrder;

    //     const deleteIcon: IIconProps = { iconName: 'Delete' };
    //     const editIcon: IIconProps = { iconName: 'Edit' };

    //     let items = editOrderData?.ecafOrder.envelopeDetails?.customers?.map((customer: EnvelopeDetailsCustomerViewModel) => {
    //         let isDissable = false;

    //         if (editOrderData.ecafOrder.envelopeDetails?.customers?.length == 1) {
    //             isDissable = true
    //         } else if (editOrderData.ecafOrder.envelopeDetails?.status === "ReadyToCountersign" || editOrderData.ecafOrder.envelopeDetails?.status === "RequiresReview") {
    //             isDissable = true;
    //         }

    //         return (
    //             <tr className="row-wrapper" key={Math.random()}>
    //                 <td className="order-id">
    //                     <a className="order-link" href={this.getOrderLink(editOrderData?.ecafOrder, customer.id)}><span className="customer-name">{customer.firstName} {customer.lastName}</span></a>
    //                 </td>
    //                 <td>
    //                     {actionstepContext?.matterContext?.id}
    //                 </td>
    //                 <td>
    //                     {editOrderData?.ecafOrder.envelopeDetails?.createdOn ? new Date(editOrderData?.ecafOrder.envelopeDetails?.createdOn).toLocaleDateString("en-AU") : ""}
    //                 </td>
    //                 <td>
    //                     <div className={('status-' + editOrderData?.ecafOrder.envelopeDetails?.status) + " status-shape-orderlist"}>
    //                         <a className="order-link-status" href={this.getOrderLink(editOrderData?.ecafOrder, customer.id)}> {renderStatus(editOrderData?.ecafOrder.envelopeDetails?.status)}</a></div>
    //                 </td>
    //                 <td>
    //                     {customer?.price ? "$" + customer?.price.toFixed(2) : ""}
    //                 </td>
    //                 <td>
    //                     <ResendNotificationComponent ecafOrder={editOrderData?.ecafOrder} customerId={customer.id} callbackResendResponse={this.resendNotificationStatus} />
    //                     <CommandBarButton iconProps={editIcon} text="Edit" onClick={() => this.handleCustomer(customer)} />
    //                     <CommandBarButton iconProps={deleteIcon} disabled={isDissable} text="Delete" onClick={() => { this.toggleCustomerDeleteModalState(true, editOrderData?.ecafOrder.envelopeId ?? "", customer.id ?? "", actionstepContext?.orgKey ?? "") }} />
    //                 </td>
    //             </tr>
    //         )
    //     });

    //     let element = <table className="vertical-container wrapper-content orderlist-table">
    //         <thead>
    //             <tr>
    //                 <th className="cell-customers">Customer Name</th>
    //                 <th className="cell-matter">Matter</th>
    //                 <th className="cell-date-ordered">Date Ordered</th>
    //                 <th className="cell-status">Status</th>
    //                 <th className="cell-total-fee">Total Fee</th>
    //                 <th>Action</th>
    //             </tr>
    //         </thead>
    //         <tbody>
    //             {items}
    //         </tbody>
    //     </table>
    //     return element;
    // }

    private deleteEnvelope = async () => {
        await this.props.deleteEnvelope(this.state.envelopeId, this.state.orgKey);
        this.loadOrderlist();
    }
    toggleDeleteModalState = (openModal: boolean, envelopeId: string, orgKey: string) => {
        this.setState({
            openDeleteModal: openModal ?? false,
            envelopeId: envelopeId,
            orgKey: orgKey
        });
    }

    deleteModal = () => {
        const { openDeleteModal } = this.state;
        let dialogContentProps = {
            type: DialogType.normal,
            title: 'Delete',
            subText: 'Are you sure you want to delete? This action can not be undone.',
        }
        let element =
            <Dialog
                hidden={!openDeleteModal}
                onDismiss={() => this.setState({ openDeleteModal: false })}
                dialogContentProps={dialogContentProps}
            >
                <DialogFooter>
                    <DefaultButton className='btn-danger' onClick={() => { this.setState({ openDeleteModal: false }); this.deleteEnvelope() }} text="Delete" />
                    <DefaultButton onClick={() => { this.setState({ openDeleteModal: false }) }} text="Cancel" />
                </DialogFooter>
            </Dialog>

        return element;
    }

    toggleCustomerDeleteModalState = (openModal: boolean, envelopeId: string, customerId: string, orgKey: string) => {
        this.setState({
            openCustomerDeleteModal: openModal ?? false,
            envelopeId: envelopeId,
            customerId: customerId,
            orgKey: orgKey
        });
    }

    customerDeleteModal = () => {
        const { openCustomerDeleteModal } = this.state;
        let dialogContentProps = {
            type: DialogType.normal,
            title: 'Delete',
            subText: 'Are you sure you want to delete? This action can not be undone.',
        }
        let element =
            <Dialog
                hidden={!openCustomerDeleteModal}
                onDismiss={() => this.setState({ openCustomerDeleteModal: false })}
                dialogContentProps={dialogContentProps}
            >
                <DialogFooter>
                    <DefaultButton className='btn-danger' onClick={() => { this.setState({ openCustomerDeleteModal: false }); this.deleteCustomer() }} text="Delete" />
                    <DefaultButton onClick={() => { this.setState({ openCustomerDeleteModal: false }) }} text="Cancel" />
                </DialogFooter>
            </Dialog>

        return element;
    }

    private deleteCustomer = async () => {
        this.props.deleteCustomer(this.state.envelopeId, this.state.customerId, this.state.orgKey);
    }

    closeEditOrderModal = () => {
        let orderData = this.state.editOrderData;
        orderData.showClientsModal = true;
        orderData.showEditOrderModal = false;

        this.setState({
            editOrderData: orderData
        })
        this.loadOrderlist();
    }

    closeEditCustomerModal = () => {
        this.setState({
            editOrderData: {
                showEditOrderModal: false,
                ecafOrder: new ECAFOrderResult,
                customer: new EnvelopeDetailsCustomerViewModel,
                showClientsModal: false
            }
        })
        this.loadOrderlist();
    }

    static getDerivedStateFromProps(nextProps: AppProps, prevState: AppStates): AppStates {
        let nextState = {} as AppStates;
        const { actionstepContext } = nextProps;
        if (nextProps.gotResponse) {
            switch (nextProps.requestType) {
                case CONSTANTS.GET_ECAF_ORDERLIST_REQUESTED: {
                    nextState.isLoading = false;
                    nextState.pageError = '';
                    break;
                }
                case CONSTANTS.GET_ECAF_ORDERLIST_SUCCESS:
                    nextState.isLoading = false;
                    nextState.pageError = '';
                    break;
                case CONSTANTS.GET_ECAF_ORDERLIST_REQUESTED: {
                    nextState.isLoading = false;
                    nextState.pageError = nextProps.error?.message;
                    break;
                }
                case CONSTANTS.DELETE_ENVELOPE_REQUESTED: {
                    nextState.isLoading = true;
                    break;
                }
                case CONSTANTS.DELETE_ENVELOPE_SUCCESS: {
                    nextState.isLoading = false;
                    if (actionstepContext !== undefined && actionstepContext.matterContext !== undefined) {
                        nextProps.getECAFOrderlist(actionstepContext?.orgKey, actionstepContext?.matterContext?.id);
                    }
                    break;
                }
                case CONSTANTS.DELETE_ENVELOPE_FAILED: {
                    nextState.isLoading = false;
                    break;
                }
                case CONSTANTS.DELETE_CUSTOMER_REQUESTED: {
                    nextState.isLoading = true;
                    break;
                }
                case CONSTANTS.DELETE_CUSTOMER_SUCCESS: {
                    nextState.isLoading = false;
                    if (actionstepContext !== undefined && actionstepContext.matterContext !== undefined) {
                        nextProps.getECAFOrderlist(actionstepContext?.orgKey, actionstepContext?.matterContext?.id);
                    }
                    break;
                }
                case CONSTANTS.DELETE_CUSTOMER_FAILED: {
                    nextState.isLoading = false;
                    break;
                }
                default:
                    return nextState;
            }
        }
        return nextState;
    }

    render() {
        const { isLoading, pageError, resendStatus, showResendMessageBar, showCannotDeleteMessageBar } = this.state;
        const { editOrderData } = this.state;
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }
        // const sectionStackTokens: IStackTokens = { childrenGap: 10 };
        return (
            <div className="vertical-container wrapper-content animated fadeIn app-font" dir="ltr">
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-lg12 ">
                        <h3 className="tittle">
                            <big><b>livesign Transaction List</b></big>
                        </h3>
                        <br />
                        {isLoading ?
                            <Spinner size={SpinnerSize.large} label="loading eCAF Transaction list..." />
                            :
                            <>
                                {pageError &&
                                    <MessageBar messageBarType={MessageBarType.error}>
                                        Error: {pageError}
                                    </MessageBar>
                                }
                                {resendStatus != undefined && resendStatus && showResendMessageBar &&
                                    <MessageBar dismissButtonAriaLabel="Close"
                                        onDismiss={() => this.setState({ showResendMessageBar: false })}
                                        messageBarType={MessageBarType.success}>
                                        Notification sent successfully.
                                    </MessageBar>}
                                {resendStatus != undefined && !resendStatus && showResendMessageBar &&
                                    <MessageBar dismissButtonAriaLabel="Close"
                                        onDismiss={() => this.setState({ showResendMessageBar: false })}
                                        messageBarType={MessageBarType.error}>
                                        An error occured. Unable to send the notification.
                                    </MessageBar>}

                                {showCannotDeleteMessageBar &&
                                    <MessageBar dismissButtonAriaLabel='Close'
                                        onDismiss={() => this.setState({ showCannotDeleteMessageBar: false })}
                                        messageBarType={MessageBarType.error}>
                                        Cannot delete the last customer of an envelope or a completed customer
                                    </MessageBar>}

                                {this.deleteModal()}
                                {this.customerDeleteModal()}
                                {/* {this.renderOrderlist()} */}
                                {this.renderOrderListSplittingParties()}

                                <Modal isOpen={editOrderData.showEditOrderModal}
                                    isBlocking={true}
                                    onDismiss={this.closeEditOrderModal}
                                    containerClassName="edit-order-modal"
                                    className={editOrderData.showEditOrderModal !== null ? "animated fadeIn" : "animated fadeOut"} >
                                    <EditOrder closeEditOrderModal={this.closeEditOrderModal} envelopeId={editOrderData.ecafOrder.envelopeId} customer={editOrderData.customer} />
                                    <footer>
                                        <Separator />
                                    </footer>
                                </Modal>

                                {/* <Modal isOpen={editOrderData.showClientsModal}
                                        isBlocking={true}
                                        onDismiss={this.closeEditCustomerModal}
                                        containerClassName="view-customer-modal"
                                        className={editOrderData.showClientsModal !== null ? "animated fadeIn" : "animated fadeOut"} >
                                        <header>
                                            <Stack tokens={sectionStackTokens} className='modal-header' >
                                                <Stack horizontal disableShrink horizontalAlign="space-between">
                                                    <span>Customers Detail</span>
                                                    <IconButton
                                                        className='modal-close-button'
                                                        ariaLabel="Close popup modal"
                                                        iconProps={{ iconName: 'Cancel' }}
                                                        onClick={this.closeEditCustomerModal}
                                                        allowDisabledFocus={true} />
                                                </Stack>
                                            </Stack>
                                        </header>
                                        {this.renderCustomersList()}
                                        <footer>
                                            <Separator />
                                        </footer>
                                    </Modal> */}

                                <br />
                                <div className="ms-Grid-row" dir="rtl">
                                    <div className="ms-Grid-col">
                                        <PrimaryButton
                                            text="Return to konekta"
                                            onClick={() => this.redirectToHome()}
                                        />
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: AppState) => {
    return {
        error: state.eCaf.error,
        gotResponse: state.eCaf.gotResponse,
        requestType: state.eCaf.requestType,
        actionstepContext: state.common.actionstepContext,
        envelopeDetails: state.eCaf.envelopeDetails,
        orderList: state.eCaf.orderList,
        orderStatusPageUrl: state.eCaf.statusPageUrl
    }
}

const mapDispatchToProps = {
    getECAFOrderlist,
    getECAFOrderStatusPage,
    deleteEnvelope,
    deleteCustomer
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderListPage);