import { call, put, takeLatest } from 'redux-saga/effects';

import * as CONSTANTS from './constants'
import * as ACTIONS from './actions'
import { SecurexchangeWorkspaceInfo } from '../../../utils/wcaApiTypes';
import { GetSecurexchangeWorkspaceInformationFromKonekta } from './actionTypes';
import { getRequest } from '../../../utils/request';


export function* getSecurexchangeWorkspaceInformationFromKonekta(action: GetSecurexchangeWorkspaceInformationFromKonekta) {
    try {
        const response = yield call(getRequest, `/api/securexchange/get-securexchange-workspace-information-from-konekta?actionsteporg=${action.matterInfo.orgKey!}&matterid=${action.matterInfo.matterId}`);

        let data: SecurexchangeWorkspaceInfo = response;

        yield put(ACTIONS.getSecurexchangeWorkspaceInformationFromKonektaSuccess(data));

    } catch (error) {
        yield put(ACTIONS.getSecurexchangeWorkspaceInformationFromKonektaFailed(error?.response?.data));
    }
}

export default function* securexchangeSaga() {
    yield takeLatest(CONSTANTS.GET_SECUREXCHANGE_WORKSPACE_INFORMATION_FROM_KONEKTA_REQUESTED,
        getSecurexchangeWorkspaceInformationFromKonekta);

} 