import * as React from 'react';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { ChoiceGroup } from '@fluentui/react';
import { ModalIDs } from 'containers/calculators/settlement/common';
import { getAdjustmentPeriods } from 'containers/calculators/settlement/Helper';

interface IMapStateToProps { }
interface IMapDispatchToProps { }

interface IProps {
    updatedState: any;
    updateValue: (newValue: any, whichValue: string, needRefresh?: boolean) => void;
    modalName: any;
    adjustmentDate: Date;
}

type AppProps = IMapStateToProps & IProps & IMapDispatchToProps;
type AppStates = {}

export default class CommonField extends React.Component<AppProps, AppStates> {

    private updatePaymentMethod(keyValue: string, billingMethod: string) {
        if (keyValue === "daily-average") {
            this.props.updateValue(0, 'amount', false);
            this.props.updateValue("", 'billingPeriod', false);
            this.props.updateValue("", 'adjustmentPeriod', false);
        }
        this.props.updateValue(keyValue, billingMethod, true)
    }

    handleCheckBox = (checked: boolean, key: string) => {
        if (checked) {
            const datePeriod: any = getAdjustmentPeriods("Quarter", this.props.adjustmentDate);

            this.props.updateValue('Quarter', 'billingPeriod');
            this.props.updateValue('Quarter', 'adjustmentPeriod');
            this.props.updateValue(datePeriod.fromDate, 'from');
            this.props.updateValue(datePeriod.toDate, 'to');
        }
        else {
            this.props.updateValue("", 'billingPeriod');
            this.props.updateValue("", 'adjustmentPeriod');
            this.props.updateValue("", 'from');
            this.props.updateValue("", 'to');
        }
        this.props.updateValue(checked, key, true);
        this.setState({
            refresh: true
        });
    }

    public render(): JSX.Element {
        const updatedState = this.props.updatedState;

        return (
            <>
                <div className="ms-Grid-row modal-row">
                    <div className="ms-Grid-col ms-sm12">
                        <div className="ms-Grid-col ms-sm1" style={{ padding: "10px 10px 0 0" }}>Method:</div>
                        <div className="ms-Grid-col ms-sm9">
                            {this.props.modalName === ModalIDs.recycledWater ?
                                <ChoiceGroup
                                    defaultSelectedKey={updatedState['method']}
                                    options={[
                                        { key: 'search-reading', text: 'Billed Amount\u00A0\u00A0' },
                                        { key: 'daily-average', text: 'Daily Average' }
                                    ]}
                                    required={true}
                                    onChange={(ev, item) => this.props.updateValue(item!.key, 'method', true)}
                                    styles={{ flexContainer: { display: "flex" } }}
                                />
                                :
                                <ChoiceGroup
                                    defaultSelectedKey={updatedState['method']}
                                    options={[
                                        { key: 'billed-amount', text: 'Billed Amount\u00A0\u00A0' },
                                        { key: 'daily-average', text: 'Daily Average' }
                                    ]}
                                    required={true}
                                    onChange={(ev, item) => this.updatePaymentMethod(item!.key, 'method')}
                                    styles={{ flexContainer: { display: "flex" } }}
                                />
                            }
                        </div>
                    </div>
                </div>
                <div className="ms-Grid-row modal-row">
                    <div className="ms-Grid-col ms-sm2">Amount : </div>
                    <div className="ms-Grid-col ms-sm4">
                        <TextField
                            type="number"
                            data-cy="amount_input"
                            defaultValue={updatedState['amount']}
                            disabled={updatedState['method'] === 'daily-average'}
                            onChange={(ev, newText) => this.props.updateValue(newText, 'amount')}
                        />
                    </div>

                    <div className="ms-Grid-col ms-sm2">Average Daily Amount : </div>
                    <div className="ms-Grid-col ms-sm4">
                        <TextField
                            type="number"
                            data-cy="average_Daily_Amount_Input"
                            placeholder='0 kl'
                            disabled={updatedState['method'] === 'billed-amount' || updatedState['method'] === 'search-reading'}
                            defaultValue={updatedState['averageDailyAmount']}
                            onChange={(ev, newText) => this.props.updateValue(newText, 'averageDailyAmount')}
                        />
                    </div>
                </div>

                <div className="ms-Grid-row modal-row">
                    <div className="ms-Grid-col ms-sm2"></div>
                    {this.props.modalName === ModalIDs.SAWaterSupplyAndSewerage
                        ?
                        <div className="ms-Grid-col ms-sm4">
                            <span><input type="checkbox" id="calculateToAnnualAmount" checked={updatedState['calculateToAnnualAmount']} onChange={(event) => this.handleCheckBox(event.target.checked, 'calculateToAnnualAmount')} />Calculate to annual amount</span>
                        </div>
                        :
                        <div className="ms-Grid-col ms-sm6"></div>
                    }
                    <div className="ms-Grid-col ms-sm2">Increase (if app from 1st july) : </div>
                    <div className="ms-Grid-col ms-sm4">
                        <TextField
                            type="number"
                            data-cy="increase_input"
                            placeholder='0'
                            disabled={updatedState['method'] === 'billed-amount' || updatedState['method'] === 'search-reading'}
                            // disabled
                            defaultValue={updatedState['increasedFee'] ?? 0}
                            onChange={(ev, newText) => this.props.updateValue(newText, 'increasedFee')}
                        />
                    </div>
                </div>

            </>
        )
    }
}