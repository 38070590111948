import * as React from 'react'
import { connect } from 'react-redux'
import { AppState } from 'app.types';
import { TextField } from 'office-ui-fabric-react/lib/TextField';

interface IMapStateToProps {
}

interface IMapDispatchToProps { }
interface IProps {
    updatedState: any,
    updateValue: (newValue: any, whichValue: string) => void
}

type AppProps = IMapStateToProps & IProps & IMapDispatchToProps;
type AppStates = {}
export class AddUpdateHeader extends React.Component<AppProps, AppStates> {

    constructor(props: AppProps) {
        super(props);
    }

    public render(): JSX.Element {
        return (
            <div className="modal-body">
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row modal-row">
                        <div className="ms-Grid-col ms-sm4 modal-label">New Header Row:</div>
                        <div className="ms-Grid-col ms-sm8">
						<TextField
							data-cy="header_field"
							id="header_field"
                            defaultValue={this.props.updatedState['groupName'] ?? ''}
							onChange={(_, newText) => this.props.updateValue(newText ?? '', 'groupName')}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state: AppState): IMapStateToProps => {
    return {}
}

export default connect(mapStateToProps)(AddUpdateHeader);