import {
    ErrorViewModel,
    PEXAWorkspaceCreationRequestWithActionstepResponse,
    WorkspaceCreationRequest,
    CreatePexaWorkspaceCommand,
    ActionstepMatterInfo,
    CreatePexaWorkspaceResponse,
    LandTitleReferenceAndJurisdiction,
    LandTitleReferenceVerificationResponseType,
    WorkspaceSummaryResponseType,
    RetrieveWorkspaceSummaryParameters,
    CreateWorkspaceInvitationRequestType,
    CreateWorkspaceInvitationResponseType,
    RetrieveSettlementAvailabilityParams,
    RetrieveSettlementAvailabilityResponseType,
    PexaWorkspaceInfo,
    UpdateSettlementDateTimeActionstepCommand,
    UpdateSettlementDateAndTimeRequestCommand,
    OrgKeyAndMatter
} from "utils/wcaApiTypes";



import { IBasicAction, ReduxData } from "app.types";
import { PartyAddressType } from "containers/pexa/steps/constants";
import {
    CreatePexaKeyInviteCommandParams, PexaWorkspaceIdAndRole, UpdateSettlementDateTimeData, PexaKeyWorkspacePartyIdAndRole,
    CreatePexaKeyInviteResponse, CopyDocumentsToActionStepResponse,
    SavePexaAuditParams, DealingNumberViewModel,
    UpdateDealingNumberActionstepCommand
} from "../../../utils/newWcaApiTypes";
import { ActionstepFinancialLineItems, UpdateFSSLineItems } from "../pexaApiTypes";

export interface ActionFailed extends IBasicAction {
    error: ErrorViewModel;
}

export interface GetDataFromActionstep extends IBasicAction {
    matterInfo: ActionstepMatterInfo
}

export interface GetDataFromActionstepSuccess extends IBasicAction {
    data: PEXAWorkspaceCreationRequestWithActionstepResponse
}

export interface SetPexaWorkspaceCreation extends IBasicAction {
    data: WorkspaceCreationRequest
}

export interface ValidateLandTitle extends IBasicAction {
    params: LandTitleReferenceAndJurisdiction
}

export interface ValidateLandTitleSuccess extends IBasicAction {
    data: LandTitleReferenceVerificationResponseType
}

export interface GetPexaWorkspaceSummary extends IBasicAction {
    params: RetrieveWorkspaceSummaryParameters
}

export interface GetPexaWorkspaceSummarySuccess extends IBasicAction {
    data: WorkspaceSummaryResponseType,
    workspaceId: string
}

export interface GetPexaWorkspaceSummaryFailed extends ActionFailed {
    workspaceId: string
}

export interface GetAvailableSettlementTimes extends IBasicAction {
    params: RetrieveSettlementAvailabilityParams
}

export interface GetAvailableSettlementTimesSuccess extends IBasicAction {
    data: RetrieveSettlementAvailabilityResponseType,
    jurisdiction: string,
    settlementDate: string
}

export interface GetAvailableSettlementTimesFailed extends ActionFailed {
    jurisdiction: string,
    settlementDate: string
}

export interface SendDataToPexa extends IBasicAction {
    params: CreatePexaWorkspaceCommand
}

export interface SendDataToPexaSuccess extends IBasicAction {
    data: CreatePexaWorkspaceResponse
}

export interface CreateInvitation extends IBasicAction {
    params: CreateWorkspaceInvitationRequestType[]
}

export interface CreateInvitationSuccess extends IBasicAction {
    data: CreateWorkspaceInvitationResponseType[]
}

export interface SavePexaFormData extends IBasicAction {
    pexaFormData: WorkspaceCreationRequestWithMatterInfo
}

export interface ClearPexaFormData extends IBasicAction { }

export class WorkspaceCreationRequestWithMatterInfo {
    workspaceCreationRequest: WorkspaceCreationRequest;
    addressTypes: (PartyAddressType | null)[];
    matterInfo: ActionstepMatterInfo;
    validateLandTitleResponse: LandTitleReferenceVerificationResponseType;

    constructor(workspaceCreationRequest: WorkspaceCreationRequest,
        addressTypes: (PartyAddressType | null)[],
        matterInfo: ActionstepMatterInfo,
        validateLandTitleResponse: LandTitleReferenceVerificationResponseType | null
    ) {
        this.workspaceCreationRequest = WorkspaceCreationRequest.fromJS(workspaceCreationRequest.toJSON());
        this.addressTypes = addressTypes;
        this.matterInfo = matterInfo;
        this.validateLandTitleResponse = LandTitleReferenceVerificationResponseType.fromJS(validateLandTitleResponse);
    }
}

export interface ClearPexaState extends IBasicAction { }
export interface ClearPexaStateAndError extends IBasicAction { }

export interface WorkspaceSummaryList {
    [workspaceId: string]: ReduxData<WorkspaceSummaryResponseType>
}

export interface AvailableSettlementTimesForJurisdiction {
    [dateString: string]: ReduxData<RetrieveSettlementAvailabilityResponseType>
}

export interface AvailableSettlementTimes {
    [jurisdiction: string]: AvailableSettlementTimesForJurisdiction
}

export interface GetPexaWorkspaceInformationFromKonekta extends IBasicAction {
    matterInfo: ActionstepMatterInfo
}

export interface GetPexaWorkspaceInformationFromKonektaSuccess extends IBasicAction {
    data: PexaWorkspaceInfo
}

export interface GetPexaWorkspaceInformationFromKonektaFailed extends IBasicAction {
    error: ErrorViewModel;
}

export interface MaintainSettlementDateTime extends IBasicAction {
    params: UpdateSettlementDateAndTimeRequestCommand
}

export interface MaintainSettlementDateTimeSuccess extends IBasicAction {
    data: boolean
}

export interface MaintainSettlementDateTimeFailed extends IBasicAction {
    error: ErrorViewModel;
}

export interface UpdateSettlementDateTimeActionstep extends IBasicAction {
    params: UpdateSettlementDateTimeActionstepCommand
}

export interface UpdateSettlementDateTimeActionstepSuccess extends IBasicAction {
    data: boolean
}

export interface UpdateSettlementDateTimeActionstepFailed extends IBasicAction {
    error: ErrorViewModel;
}

export interface GetInvitationUri extends IBasicAction {
    params: PexaWorkspaceIdAndRole
}

export interface GetInvitationUriSuccess extends IBasicAction {
    data: string
}

export interface GetInvitationUriFailed extends IBasicAction {
    error: ErrorViewModel;
}


export interface SetSettlementDateTimeData extends IBasicAction {
    data: UpdateSettlementDateTimeData
}

export interface ClearStatesForSettlementDateTime extends IBasicAction { }

export interface CreatePexaKey extends IBasicAction {
    params: CreatePexaKeyInviteCommandParams
}

export interface CreatePexaKeySuccess extends IBasicAction {
    data: CreatePexaKeyInviteResponse
}

export interface CreatePexaKeyFailed extends IBasicAction {
    error: ErrorViewModel;
}

export interface GetPexaKey extends IBasicAction {
    params: PexaKeyWorkspacePartyIdAndRole
}

export interface GetPexaKeySuccess extends IBasicAction {
    data: string
}

export interface GetPexaKeyFailed extends IBasicAction {
    error: ErrorViewModel;
}


export interface GetPexaKeyIviteInformationFromKonekta extends IBasicAction {
    matterInfo: ActionstepMatterInfo
}

export interface GetPexaKeyIviteInformationFromKonektaSuccess extends IBasicAction {
    data: string
}

export interface GetPexaKeyIviteInformationFromKonektaFailed extends IBasicAction {
    error: ErrorViewModel;
}

export interface CopyLodgedDocumentsToActionStep extends IBasicAction {
    matterId: number,
    orgKey: string
}

export interface CopyLodgedDocumentsToActionStepSuccess extends IBasicAction {
    data: CopyDocumentsToActionStepResponse
}

export interface CopyLodgedDocumentsToActionStepFailed extends IBasicAction {
    error: ErrorViewModel;
}

export interface SavePexaAudit extends IBasicAction {
    data: SavePexaAuditParams
}

export interface SavePexaAuditSuccess extends IBasicAction {
    data: boolean
}

export interface SavePexaAuditFailed extends IBasicAction {
    error: ErrorViewModel;
}

export interface GetActionstepPropertyDetails extends IBasicAction {
    params: OrgKeyAndMatter
}

export interface GetActionstepPropertyDetailsSuccess extends IBasicAction {
    data: DealingNumberViewModel[]
}

export interface GetActionstepPropertyDetailsFailed extends IBasicAction {
    error: ErrorViewModel;
}

export interface UpdateDealingNumberActionstep extends IBasicAction {
    params: UpdateDealingNumberActionstepCommand
}

export interface UpdateDealingNumberActionstepSuccess extends IBasicAction {
    data: string
}

export interface UpdateDealingNumberActionstepFailed extends IBasicAction {
    error: ErrorViewModel;
}

export interface ClearPexaWorkspaceDealingNumberData extends IBasicAction { }

export interface GetFSSLineItems extends IBasicAction {
    matterId: number,
    orgKey: string
}

export interface GetFSSLineItemsSuccess extends IBasicAction {
    data: ActionstepFinancialLineItems[]
}
export interface GetFSSLineItemsFailed extends IBasicAction {
    error: ErrorViewModel;
}
export interface SetFSSLineItems extends IBasicAction {
    fssLineItems: UpdateFSSLineItems
}

export interface SetFSSLineItemsSuccess extends IBasicAction {
    data: string
}
export interface SetFSSLineItemsFailed extends IBasicAction {
    error: ErrorViewModel;
}
export interface ClearFSSLineItemsState extends IBasicAction { }

export type PexaActionTypes = GetDataFromActionstep
    | GetDataFromActionstepSuccess
    | ValidateLandTitle
    | ValidateLandTitleSuccess
    | GetPexaWorkspaceSummary
    | GetPexaWorkspaceSummarySuccess
    | GetPexaWorkspaceSummaryFailed
    | SetPexaWorkspaceCreation
    | SendDataToPexa
    | SendDataToPexaSuccess
    | ClearPexaState
    | ClearPexaStateAndError
    | ActionFailed
    | GetPexaWorkspaceInformationFromKonekta
    | GetPexaWorkspaceInformationFromKonektaSuccess
    | GetPexaWorkspaceInformationFromKonektaFailed
    | MaintainSettlementDateTime
    | MaintainSettlementDateTimeSuccess
    | MaintainSettlementDateTimeFailed
    | UpdateSettlementDateTimeActionstep
    | UpdateSettlementDateTimeActionstepSuccess
    | UpdateSettlementDateTimeActionstepFailed
    | GetInvitationUri
    | GetInvitationUriSuccess
    | GetInvitationUriFailed
    | SetSettlementDateTimeData
    | ClearStatesForSettlementDateTime
    | CreatePexaKey
    | CreatePexaKeySuccess
    | CreatePexaKeyFailed
    | GetPexaKey
    | GetPexaKeySuccess
    | GetPexaKeyFailed
    | GetPexaKeyIviteInformationFromKonekta
    | GetPexaKeyIviteInformationFromKonektaSuccess
    | GetPexaKeyIviteInformationFromKonektaFailed
    | CopyLodgedDocumentsToActionStep
    | CopyLodgedDocumentsToActionStepSuccess
    | CopyLodgedDocumentsToActionStepFailed
    | SavePexaAudit
    | SavePexaAuditSuccess
    | SavePexaAuditFailed
    | GetActionstepPropertyDetails
    | GetActionstepPropertyDetailsSuccess
    | GetActionstepPropertyDetailsFailed
    | UpdateDealingNumberActionstep
    | UpdateDealingNumberActionstepSuccess
    | UpdateDealingNumberActionstepFailed
    | ClearPexaWorkspaceDealingNumberData
    | GetFSSLineItems
    | GetFSSLineItemsSuccess
    | GetFSSLineItemsFailed
    | SetFSSLineItems
    | SetFSSLineItemsSuccess
    | SetFSSLineItemsFailed
    | ClearFSSLineItemsState



