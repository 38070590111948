import React from "react";
import { connect } from 'react-redux';
import 'containers/rapidBuildingInspections/rapidBuildingInspection.css';
import LoadingWidget from 'components/common/loadingWidget';
import Grid from '@material-ui/core/Grid';
import { AppState } from 'app.types';
import { PrimaryButton } from "office-ui-fabric-react/lib/Button";
import { MessageBar, MessageBarType } from 'office-ui-fabric-react'
import {
    getClientDetails,
    sendRBIData,
    clearRBIDetails
} from 'containers/rapidBuildingInspections/redux/actions'

import {
    ActionstepMatterInfo,
    RBIDataModel,
    ActionstepClientInfo,
} from 'containers/rapidBuildingInspections/utils/rapidBuildingInspectionAPITypes';

import * as CONSTANTS from 'containers/rapidBuildingInspections/redux/constants'
import { DisabledTextField, theme } from "./utils/rabidBuildingInspectionStyle";

import Confirmation from "./components/rbiConfirmation";
import { Box, FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup, ThemeProvider,Checkbox } from "@material-ui/core";

type IProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;
type IState = {
    actionstepClientInfo: ActionstepClientInfo | undefined
    serviceRequire: string,
    isPostRequested: boolean,
    isLoading: boolean,
    optionRequire:boolean,
    errors: {
        isclientFirstNameValid: boolean
        isclientEmailValid: boolean
        isclientMobileValid: boolean
        isclientLastNameValid: boolean
        isclientLocationValid: boolean
        isconveyancingFirmValid: boolean
        isconveyencerValid: boolean
        isstateValid: boolean
        ispostCodeValid: boolean
    }
    matterId: number,
    orgKey: string | undefined,
    redirectToConfirmationPage: boolean,
    rbiResponseDetails: string
}

export class RapidBuildingInspections extends React.Component<IProps, IState> {

    constructor(props: any) {
        super(props);

        this.state = {
            actionstepClientInfo: undefined,
            serviceRequire: "",
            isPostRequested: false,
            matterId: 0,
            orgKey: "",
            redirectToConfirmationPage: false,
            rbiResponseDetails: "",
            isLoading: false,
            optionRequire: true,
            errors: {
                isclientFirstNameValid: false,
                isclientEmailValid: false,
                isclientMobileValid: false,
                isclientLastNameValid: false,
                isclientLocationValid: false,
                isconveyancingFirmValid: false,
                isconveyencerValid: false,
                isstateValid: false,
                ispostCodeValid: false
            }
        }
    }

    public componentDidMount(): void {
        this.loadClientDetail();

    }

    private loadClientDetail(): void {
        const { actionstepContext } = this.props;
        this.setState({
            ...this.state,
            matterId: actionstepContext?.matterContext?.id ?? 0,
            orgKey: actionstepContext?.orgKey
        })

        if (actionstepContext === undefined
            || actionstepContext.matterContext === undefined) {
            return;
        }

        // TODO: refactor this out.
        const matterInfo = new ActionstepMatterInfo({
            orgKey: actionstepContext.orgKey,
            matterId: actionstepContext.matterContext?.id
        });

        this.props.getClientDetails(matterInfo);
    }

    static getDerivedStateFromProps(nextProps: IProps, prevState: IState): IState {
        let nextState = {} as IState;
        if (nextProps.gotResponse === true) {
            switch (nextProps.requestType) {
                case CONSTANTS.GET_CLIENT_DETAILS_REQUESTED: {
                    nextState.isLoading = true;
                    break;
                }
                case CONSTANTS.GET_CLIENT_DETAILS_SUCCESS: {
                    nextState.isLoading = false;
                    if (nextProps.success === true) {
                        if (nextProps.userDetailData != undefined) {
                            nextState = {
                                ...nextState,
                                actionstepClientInfo: nextProps.userDetailData
                            };
                        }
                    }
                    break;
                }
                case CONSTANTS.GET_CLIENT_DETAILS_FAILED: {
                    nextState.isLoading = false;
                    break;
                }
                case CONSTANTS.SEND_RBI_DETAILS_REQUESTED: {
                    nextState.isLoading = true;
                    break;
                }
                case CONSTANTS.SEND_RBI_DETAILS_SUCCESS: {
                    nextState.isLoading = false;
                    if (nextProps.success === true) {
                        if (nextProps.rbiResponseDetails != "") {
                            let isRedirect = false;
                            if (nextProps.rbiResponseDetails == "Success") {
                                isRedirect = true;
                            }
                            nextState = {
                                ...nextState,
                                rbiResponseDetails: nextProps.rbiResponseDetails ?? "",
                                redirectToConfirmationPage: isRedirect,
                                isPostRequested: false
                            };
                            nextProps.clearRBIDetails();
                        }
                    }
                    break;
                }
                case CONSTANTS.SEND_RBI_DETAILS_FAILED: {
                    nextState.isLoading = false;
                    nextState = {
                        ...nextState,
                        rbiResponseDetails: nextProps.rbiResponseDetails ?? "",
                        redirectToConfirmationPage: false,
                        isPostRequested: false
                    }
                    break;
                }
                default:
                    return nextState;
            }
        }
        return nextState;
    }

    onSubmitForm = () => {
        if (!this.isFormValid()) {
            return;
        }
        const postData = new RBIDataModel({
            matterId: this.state.matterId,
            orgKey: this.state.orgKey,
            clientName: this.state.actionstepClientInfo?.clientFirstName + " " + this.state.actionstepClientInfo?.clientLastName,
            clientEmail: this.state.actionstepClientInfo?.clientEmail,
            clientMobile: this.state.actionstepClientInfo?.clientMobile,
            clientLocation: this.state.actionstepClientInfo?.clientLocation,
            serviceRequired: this.state.serviceRequire,
            postCode: this.state.actionstepClientInfo?.postCode,
            state: this.state.actionstepClientInfo?.state,
            option: this.state.optionRequire,
            conveyancingFirm: this.state.actionstepClientInfo?.conveyancingFirm,
            conveyancingName: this.state.actionstepClientInfo?.conveyencer
        });
        this.props.sendRBIData(postData);
        this.setState({
            ...this.state,
            isPostRequested: true
        })
        return true;
    }


    private isFormValid(): boolean {
        const { actionstepClientInfo, serviceRequire, errors } = this.state;
        let controlValid = false;
        let errorsClone = { ...errors };
        let errorsCloneUpdated = { ...errors };
        let clientInfo = actionstepClientInfo ?? {};
        (Object.keys(clientInfo) as (keyof typeof clientInfo)[]).forEach((key, index) => {
            controlValid = clientInfo[key] != null && clientInfo[key] != '';
            let keyName = `is${key}Valid`;
            errorsClone = {
                ...errorsCloneUpdated,
                [keyName]: controlValid
            }
            errorsCloneUpdated = { ...errorsClone };
        });
        this.setState((prevState) => {
            return {
                ...prevState,
                errors: {
                    ...errorsClone
                }
            }
        })
        return (Object.values(errorsClone).every(value => value === true) && serviceRequire != '');
    }


    private changeInfo = (newValue: any, key: string) => {
        if (key === 'serviceRequire') {
            this.setState((prevState) => {
                return {
                    ...prevState,
                    serviceRequire: newValue
                }
            })
        }
        if (key === 'optionRequire') {
            this.setState((prevState) => {
                return {
                    ...prevState,
                    optionRequire: newValue
                }
            })
        }
        
    }

    private renderClientInformation = () => {
        
        const { actionstepClientInfo, rbiResponseDetails, serviceRequire, errors, isLoading, optionRequire } = this.state;
        let element =
            <>
                {rbiResponseDetails != "" && rbiResponseDetails != "Success" ?
                    <MessageBar messageBarType={MessageBarType.error}> {rbiResponseDetails}</MessageBar>
                    : null
                }
                <ThemeProvider theme={theme}>
                    <div className="container" >
                        {isLoading ? <LoadingWidget message="Client data loading...." /> : <div>
                            <div className="txtheader">
                                <p>Request a quote from Rapid Building Inspections</p>
                            </div>
                            <div>
                                <p className="formparagraph">Submitting this form will send a request direct to Rapid Building Inspections for one of their
                                    Team Members to contact the Client direct to arrange an inspection. You will not receive any
                                    further communications</p>
                            </div>
                            <div className="setdivarea">
                                <div className="reffereldiv">
                                    <FormLabel id="demo-radio-buttons-group-label">Referrer Details</FormLabel>
                                    <div className="maindiv">
                                        <div className="lblset">Conveyancing Firm</div>
                                        <div className="txtinput">
                                            <Grid item xs={12} >
                                                <DisabledTextField
                                                    className="text-field txtbackgroundolor"
                                                    size="small"
                                                    variant="outlined"
                                                    name="conveyancingFirm"
                                                    error={!errors.isconveyancingFirmValid && actionstepClientInfo?.conveyancingFirm == ''}
                                                    helperText={!errors.isconveyancingFirmValid && actionstepClientInfo?.conveyancingFirm == '' ? 'Invalid Conveyancing Firm' : ''}
                                                    placeholder="Please Enter Conveyancing Firm"
                                                    value={actionstepClientInfo?.conveyancingFirm}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.changeInfo(event.target.value, "conveyancingFirm")}
                                                    disabled
                                                />
                                            </Grid>
                                        </div>
                                    </div>
                                    <div className="maindiv">
                                        <div className="lblset">Conveyancer</div>
                                        <div className="txtinput">
                                            <Grid item xs={12} >
                                                <DisabledTextField
                                                    className="text-field txtbackgroundolor"
                                                    size="small"
                                                    variant="outlined"
                                                    name="conveyencer"
                                                    error={!errors.isconveyencerValid && actionstepClientInfo?.conveyencer == ''}
                                                    helperText={!errors.isconveyencerValid && actionstepClientInfo?.conveyencer == '' ? 'Invalid Conveyancer' : ''}
                                                    placeholder="Please Enter Conveyancer"
                                                    value={actionstepClientInfo?.conveyencer}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.changeInfo(event.target.value, "conveyencer")}
                                                    disabled
                                                />
                                            </Grid>
                                        </div>
                                    </div>
                                </div>
                                <div className="reffereldiv">
                                    <FormLabel id="demo-radio-buttons-group-label">Customer Details</FormLabel>
                                    <div className="maindiv">
                                        <div className="lblset">Client First Name</div>
                                        <div className="txtinput">
                                            <Grid item xs={12} >
                                                <DisabledTextField
                                                    className="text-field txtbackgroundolor"
                                                    size="small"
                                                    variant="outlined"
                                                    name="clientFirstName"
                                                    error={!errors.isclientFirstNameValid && actionstepClientInfo?.clientFirstName == ''}
                                                    helperText={!errors.isclientFirstNameValid && actionstepClientInfo?.clientFirstName == '' ? 'Invalid Client First Name' : ''}
                                                    placeholder="Please Enter First Name"
                                                    value={actionstepClientInfo?.clientFirstName}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.changeInfo(event.target.value, "clientFirstName")}
                                                    disabled
                                                />
                                            </Grid>
                                        </div>
                                    </div>
                                    <div className="maindiv">
                                        <div className="lblset">Client Last Name</div>
                                        <div className="txtinput">
                                            <Grid item xs={12} >
                                                <DisabledTextField
                                                    className="text-field txtbackgroundolor"
                                                    size="small"
                                                    variant="outlined"
                                                    name="clientLastName"
                                                    error={!errors.isclientLastNameValid && actionstepClientInfo?.clientLastName == ''}
                                                    helperText={!errors.isclientLastNameValid && actionstepClientInfo?.clientLastName == '' ? 'Invalid Client Last Name' : ''}
                                                    placeholder="Please Enter Last Name"
                                                    value={actionstepClientInfo?.clientLastName}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.changeInfo(event.target.value, "clientLastName")}
                                                    disabled
                                                />
                                            </Grid>
                                        </div>
                                    </div>
                                    <div className="maindiv">
                                        <div className="lblset">Client Email</div>
                                        <div className="txtinput">
                                            <Grid item xs={12} >
                                                <DisabledTextField
                                                    className="text-field txtbackgroundolor"
                                                    size="small"
                                                    variant="outlined"
                                                    name="clientEmail"
                                                    type="email"
                                                    error={!errors.isclientEmailValid && actionstepClientInfo?.clientEmail == ''}
                                                    helperText={!errors.isclientEmailValid && actionstepClientInfo?.clientEmail == '' ? 'Invalid Email' : ''}
                                                    placeholder="Please Enter Email"
                                                    value={actionstepClientInfo?.clientEmail}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.changeInfo(event.target.value, "clientEmail")}
                                                    disabled
                                                />
                                            </Grid>

                                        </div>
                                    </div>
                                    <div className="maindiv">
                                        <div className="lblset">Client Mobile</div>
                                        <div className="txtinput">
                                            <Grid item xs={12} >
                                                <DisabledTextField
                                                    className="text-field txtbackgroundolor"
                                                    size="small"
                                                    variant="outlined"
                                                    name="clientMobile"
                                                    error={!errors.isclientMobileValid && actionstepClientInfo?.clientMobile == ''}
                                                    helperText={!errors.isclientMobileValid && actionstepClientInfo?.clientMobile == '' ? 'Invalid Mobile' : ''}
                                                    placeholder="Please Enter Mobile"
                                                    value={actionstepClientInfo?.clientMobile}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.changeInfo(event.target.value, "clientMobile")}
                                                    disabled
                                                />
                                            </Grid>
                                        </div>
                                    </div>
                                </div>
                                <div className="reffereldiv">
                                    <FormLabel id="demo-radio-buttons-group-label">Property Location</FormLabel>
                                    <div className="maindiv">
                                        <div className="lblset">Suburb</div>
                                        <div className="txtinput">
                                            <Grid item xs={12} >
                                                <DisabledTextField
                                                    className="text-field txtbackgroundolor"
                                                    size="small"
                                                    variant="outlined"
                                                    name="clientLocation"
                                                    error={!errors.isclientLocationValid && actionstepClientInfo?.clientLocation == ''}
                                                    helperText={!errors.isclientLocationValid && actionstepClientInfo?.clientLocation == '' ? 'Invalid Client Location' : ''}
                                                    placeholder="Please Enter Address"
                                                    value={actionstepClientInfo?.clientLocation}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.changeInfo(event.target.value, "clientLocation")}
                                                    disabled
                                                />
                                            </Grid>
                                        </div>
                                    </div>
                                    <div className="maindiv">
                                        <div className="lblset">State</div>
                                        <div className="txtinput">
                                            <Grid item xs={12} >
                                                <DisabledTextField
                                                    className="text-field txtbackgroundolor"
                                                    size="small"
                                                    variant="outlined"
                                                    name="state"
                                                    error={!errors.isstateValid && actionstepClientInfo?.state == ''}
                                                    helperText={!errors.isstateValid && actionstepClientInfo?.state == '' ? 'Invalid State' : ''}
                                                    placeholder="Please Enter State"
                                                    value={actionstepClientInfo?.state}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.changeInfo(event.target.value, "state")}
                                                    disabled />
                                            </Grid>
                                        </div>
                                    </div>
                                    <div className="maindiv">
                                        <div className="lblset">Post Code</div>
                                        <div className="txtinput">
                                            <Grid item xs={12} >
                                                <DisabledTextField
                                                    className="text-field txtbackgroundolor"
                                                    size="small"
                                                    variant="outlined"
                                                    name="postCode"
                                                    error={!errors.ispostCodeValid && actionstepClientInfo?.postCode == ''}
                                                    helperText={!errors.ispostCodeValid && actionstepClientInfo?.postCode == '' ? 'Invalid Poscode' : ''}
                                                    placeholder="Please Enter Post Code"
                                                    value={actionstepClientInfo?.postCode}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.changeInfo(event.target.value, "postCode")}
                                                    disabled
                                                />
                                            </Grid>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                <FormLabel id="demo-radio-buttons-group-label">Service Required</FormLabel>
                                </div>
                                <FormControl>
                                    <Box marginLeft={9}>
                                        <FormHelperText error={true} hidden={serviceRequire != '' ? true : false}>Please select a service.</FormHelperText>
                                    </Box>
                                    <RadioGroup aria-required
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        defaultValue="Building and Pest Inspection"
                                        name="radio-buttons-group"
                                        onChange={(event, newValue) => this.changeInfo(newValue, "serviceRequire")}
                                        value={String(serviceRequire)}
                                    >
                                        <FormControlLabel value="BPI" control={<Radio size="small" disableRipple />} label="Building and Pest Inspection" />
                                        <FormControlLabel value="BI" control={<Radio size="small" disableRipple/>} label="Building only Inspection" />
                                        <FormControlLabel value="HO" control={<Radio size="small" disableRipple />} label="Handover Inspection" />
                                        <FormControlLabel value="PI" control={<Radio size="small" disableRipple />} label="Pest only Inspection" />
                                        <FormControlLabel value="PP" control={<Radio size="small" disableRipple />} label="Stage Inspections (New Build)" />
                                        
                                    </RadioGroup>
                                </FormControl>
                                <div className="optionrequire">
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox disableRipple color="primary" checked={optionRequire ?? false}
                                            onChange={(event, newValue) => this.changeInfo(newValue, "optionRequire",)} />
                                        }
                                        label="Customer has agreed to receive marketing communications from Rapid Building Inspections, our related companies and our trusted business partners."/>
                                     {/* <Box marginLeft={9}>
                                        <FormHelperText error={true} hidden={optionRequire === true ? true : false}>Option Required.</FormHelperText>
                                    </Box> */}
                                </Grid>
                                </div>
                                {actionstepClientInfo?.state?.toLocaleLowerCase() == "vic" || actionstepClientInfo?.state?.toLocaleLowerCase() == "victoria" ? <div className="declamercontent">
                                    <h5 className="headdeclaimer">**VICTORIAN REPORTS:</h5>
                                    <p>To ensure that an appropriately qualified inspector is appointed for your report, please notify RBI if you are using a
                                        Contract of Sale prepared by the Law Institute of Victoria and General Conditions 21 and 22 have not been amended.
                                        We recommend that you negotiate to amend the contract to include the words, "duly qualified building inspector"(In General Condition 21)
                                        and a "duty qualified pest inspector" (In General Condition 22).
                                    </p>
                                </div> : null}
                                <div className="btncontroladdgap">
                                    <div className="txtinput">
                                        <div className="ms-Grid-col doc-return-button buttonsize" dir="rtl">
                                            <PrimaryButton
                                                text="Request Quote"
                                                onClick={this.onSubmitForm} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                    </div>
                </ThemeProvider>
            </>
        return element;
    }



    public render(): JSX.Element {
        const { redirectToConfirmationPage, isPostRequested } = this.state;
        return (
            <>
                {redirectToConfirmationPage == true ?
                    <Confirmation /> :
                    isPostRequested == true ? <LoadingWidget message="Request Sending to RBI..." /> : this.renderClientInformation()
                }
            </>

        )
    }
}

const mapStateToProps = (state: AppState) => {
    return {
        state: state.RPI.userdetailData,
        gotResponse: state.RPI.gotResponse,
        userDetailData: state.RPI.userdetailData,
        actionstepContext: state.common.actionstepContext,
        rbiResponseDetails: state.RPI.rbiResponseDetails,
        requestType: state.RPI.requestType,
        success: state.RPI.success,
    }
}

const mapDispatchToProps = {
    getClientDetails,
    sendRBIData,
    clearRBIDetails
}

export default connect(mapStateToProps, mapDispatchToProps)(RapidBuildingInspections);