import {
    FirstTitlePolicyRequestFromActionstepResponse,
    OrderFirstTitlePolicyCommandResponse
} from '../../../utils/wcaApiTypes';

import { ReduxData, ReduxStatus } from 'app.types';
import { IFirstTitleAction } from './actions';
import { FirstTitleEvents } from './events';

export interface FirstTitleState {
    firstTitlePolicyRequestFromActionstepResponse: ReduxData<FirstTitlePolicyRequestFromActionstepResponse> | undefined
    isValidCredentials: ReduxData<boolean> | undefined,
    sendFirstTitlePolicyRequestResponse: ReduxData<OrderFirstTitlePolicyCommandResponse> | undefined
}

const initialState: FirstTitleState = {
    firstTitlePolicyRequestFromActionstepResponse: undefined,
    isValidCredentials: undefined,
    sendFirstTitlePolicyRequestResponse: undefined
}

function firstTitleReducer(state: FirstTitleState = initialState, action: IFirstTitleAction): FirstTitleState {

    switch (action.type) {
        case FirstTitleEvents.CHECK_FIRST_TITLE_CREDENTIALS_REQUESTED:
            return {
                ...state,
                isValidCredentials: new ReduxData<boolean>(ReduxStatus.Requested)
            };

        case FirstTitleEvents.CHECK_FIRST_TITLE_CREDENTIALS_SUCCESS:
            return {
                ...state,
                isValidCredentials: new ReduxData<boolean>().markAsSuccess(action.payload)
            };

        case FirstTitleEvents.CHECK_FIRST_TITLE_CREDENTIALS_FAILED:
            return {
                ...state,
                isValidCredentials: new ReduxData<boolean>().markAsFailed(action.payload)
            };

        case FirstTitleEvents.GET_FIRST_TITLE_POLICY_REQUEST_FROM_ACTIONSTEP_REQUESTED:
            return {
                ...state,
                firstTitlePolicyRequestFromActionstepResponse: new ReduxData<FirstTitlePolicyRequestFromActionstepResponse>(ReduxStatus.Requested)
            };

        case FirstTitleEvents.GET_FIRST_TITLE_POLICY_REQUEST_FROM_ACTIONSTEP_SUCCESS:
            return {
                ...state,
                firstTitlePolicyRequestFromActionstepResponse: new ReduxData<FirstTitlePolicyRequestFromActionstepResponse>().markAsSuccess(action.payload)
            };

        case FirstTitleEvents.GET_FIRST_TITLE_POLICY_REQUEST_FROM_ACTIONSTEP_FAILED:
            return {
                ...state,
                firstTitlePolicyRequestFromActionstepResponse: new ReduxData<FirstTitlePolicyRequestFromActionstepResponse>().markAsFailed(action.payload)
            };

        case FirstTitleEvents.SEND_DATA_TO_FIRST_TITLE_REQUESTED:
            return {
                ...state,
                sendFirstTitlePolicyRequestResponse: new ReduxData<OrderFirstTitlePolicyCommandResponse>(ReduxStatus.Requested)
            };

        case FirstTitleEvents.SEND_DATA_TO_FIRST_TITLE_SUCCESS:
            return {
                ...state,
                sendFirstTitlePolicyRequestResponse: new ReduxData<OrderFirstTitlePolicyCommandResponse>().markAsSuccess(action.payload)
            };

        case FirstTitleEvents.SEND_DATA_TO_FIRST_TITLE_FAILED:
            return {
                ...state,
                sendFirstTitlePolicyRequestResponse: new ReduxData<OrderFirstTitlePolicyCommandResponse>().markAsFailed(action.payload)
            };

        default:
            return state;
    }
}

export default firstTitleReducer;