export class ActionstepClientInfo implements IActionstepClientInfo {
    conveyancingFirm?:string|undefined;
    conveyencer?:string|undefined;
    clientFirstName?: string | undefined;
    clientLastName?: string | undefined;
    clientEmail?: string | undefined;
    clientMobile?: string | undefined;
    clientLocation?: string | undefined;
    state?: string | undefined;
    postCode?: string | undefined;

    constructor(data?: IActionstepClientInfo) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.conveyancingFirm = _data["conveyancingFirm"];
            this.conveyencer = _data["conveyencer"];
            this.clientFirstName = _data["clientFirstName"];
            this.clientLastName = _data["clientLastName"];
            this.clientEmail = _data["clientEmail"];
            this.clientMobile = _data["clientMobile"];
            this.clientLocation = _data["clientLocation"];
            this.state = _data["state"];
            this.postCode = _data["postCode"];

        }
    }

    static fromJS(data: any): ActionstepClientInfo {
        data = typeof data === 'object' ? data : {};
        let result = new ActionstepClientInfo();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["conveyancingFirm"] = this.conveyancingFirm;
        data["conveyencer"] = this.conveyencer;
        data["clientFirstName"] = this.clientFirstName;
        data["clientLastName"] = this.clientLastName;
        data["clientEmail"] = this.clientEmail;
        data["clientMobile"] = this.clientMobile;
        data["clientLocation"] = this.clientLocation;
        data["state"] = this.state;
        data["postCode"] = this.postCode;

    }
}

export interface IActionstepClientInfo {
    conveyancingFirm?:string|undefined;
    conveyancer?:string|undefined;
    clientFirstName?: string | undefined;
    clientLastName?: string | undefined;
    clientEmail?: string | undefined;
    clientMobile?: string | undefined;
    clientLocation?: string | undefined;
    state?: string | undefined;
    postCode?: string | undefined;
}

export class ActionstepMatterInfo implements IActionstepMatterInfo {
    orgKey?: string | undefined;
    matterId!: number;

    constructor(data?: IActionstepMatterInfo) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.orgKey = _data["orgKey"];
            this.matterId = _data["matterId"];

        }
    }

    static fromJS(data: any): ActionstepMatterInfo {
        data = typeof data === 'object' ? data : {};
        let result = new ActionstepMatterInfo();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["orgKey"] = this.orgKey;
        data["matterId"] = this.matterId;

        return data;
    }
}

export interface IActionstepMatterInfo {
    orgKey?: string | undefined;
    matterId: number;
}

export class ErrorViewModel implements IErrorViewModel {
    errorList?: string[] | undefined;
    message?: string | undefined;

    constructor(data?: IErrorViewModel) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            if (Array.isArray(_data["errorList"])) {
                this.errorList = [] as any;
                for (let item of _data["errorList"])
                    this.errorList!.push(item);
            }
            this.message = _data["message"];
        }
    }

    static fromJS(data: any): ErrorViewModel {
        data = typeof data === 'object' ? data : {};
        let result = new ErrorViewModel();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        if (Array.isArray(this.errorList)) {
            data["errorList"] = [];
            for (let item of this.errorList)
                data["errorList"].push(item);
        }
        data["message"] = this.message;
        return data;
    }
}

export interface IErrorViewModel {
    errorList?: string[] | undefined;
    message?: string | undefined;
}

export class RBIDataModel implements IRBIDataModel {
    matterId!: number;
    orgKey?: string | undefined;
    clientName?: string | undefined;
    clientEmail?: string | undefined;
    clientMobile?: string | undefined;
    clientLocation?: string | undefined;
    state?: string | undefined;
    postCode?: string |undefined;
    serviceRequired?: string | undefined;
    option: boolean |undefined;
    conveyancingFirm?: string | undefined;
    conveyancingName?: string | undefined;

    constructor(data?: IRBIDataModel) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.matterId = _data["matterId"];
            this.orgKey = _data["orgKey"];
            this.clientName = _data["clientName"];
            this.clientEmail = _data["clientEmail"];
            this.clientMobile = _data["clientMobile"];
            this.clientLocation = _data["clientLocation"];
            this.state = _data["state"];
            this.postCode = _data["postCode"];
            this.serviceRequired = _data["serviceRequired"];
            this.option = _data["option"];
            this.conveyancingFirm = _data["conveyancingFirm"];
            this.conveyancingName = _data["conveyancingName"];
        }
    }

    static fromJS(data: any): RBIDataModel {
        data = typeof data === 'object' ? data : {};
        let result = new RBIDataModel();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["matterId"] = this.matterId;
        data["orgKey"] = this.orgKey;
        data["clientName"] = this.clientName;
        data["clientEmail"] = this.clientEmail;
        data["clientMobile"] = this.clientMobile;
        data["clientLocation"] = this.clientLocation;
        data["state"] = this.state;
        data["postCode"] = this.postCode;
        data["serviceRequired"] = this.serviceRequired;
        data["option"] = this.option;
        data["conveyancingFirm"] = this.conveyancingFirm;
        data["conveyancingName"] = this.conveyancingName;
        return data;
    }
}

export interface IRBIDataModel {
    matterId: number;
    orgKey?: string | undefined;
    clientName?: string | undefined;
    clientEmail?: string | undefined;
    clientMobile?: string | undefined;
    clientLocation?: string | undefined;
    state?: string | undefined;
    postCode?: string |undefined;
    serviceRequired?: string | undefined;
    option: boolean |undefined;
    conveyancingFirm?: string | undefined;
    conveyancingName?: string | undefined;
}

export class RBIResponseDetail implements IRBIResponseDetail {
    response?: string | undefined;
    constructor(data?: IRBIResponseDetail) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.response = _data["response"];
        }
    }

    static fromJS(data: any): RBIResponseDetail {
        data = typeof data === 'object' ? data : {};
        let result = new RBIResponseDetail();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["response"] = this.response;
        return data;
    }
}

export interface IRBIResponseDetail {
    response?: string | undefined;
}