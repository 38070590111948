import * as React from 'react'
import { connect } from 'react-redux';
import {
    Text,
    Stack,
    PrimaryButton
} from 'office-ui-fabric-react';

import Tools from 'utils/tools'
import { AppState } from 'app.types';


interface IAppProps {
    callback: () => void;
    signIn?: boolean | undefined;
}

type AppProps = IAppProps & ReturnType<typeof mapStateToProps>

export class ConnectToActionstep extends React.Component<AppProps> {
    public render(): JSX.Element {
        const { isFirstTime, signIn } = this.props;

        const tokens = {
            padding: 20,
            childrenGap: 20,
        };

        return (
            <Stack tokens={tokens} data-cy="connect-to-actionstep-box">
                <Text variant={'large'}>
                    {signIn
                        ? <span>You're not logged in to Konekta's <em>smart integrations for Actionstep</em>.</span>
                        : isFirstTime
                                ? "It looks like this is the first time you're using Konekta"
                                : "We need to re-link Konekta to Actionstep for you"
                    }
                </Text>

                <Text variant={'smallPlus'}>
                    Please click the button to continue.
                </Text>

                {isFirstTime && !signIn
                    ? <Text>Before you can use Konekta, you'll need to link your Actionstep and Konekta accounts.</Text>
                    : ''
                }

                <span>
                    <PrimaryButton
                        data-automation-id="delete_button"
                        data-cy="delete_button"
                        text={signIn ? "Log in with Actionstep" : isFirstTime ? "Link to Konekta" : "Re-link Konekta"}
                        onClick={() => this.connectToActionstep()}
                        allowDisabledFocus={true}
                    />
                </span>
                    {!isFirstTime && !signIn &&
                        <Text variant={'small'}>
                            You should only need to do this once. If you continue to see this message, please <a href="https://support.konekta.com.au/support/tickets/new" target="_blank" rel="noopener noreferrer">submit a ticket</a>.
                        </Text>
                    }
            </Stack>
        );
    }

    public connectToActionstep(): void {
        Tools.PopupConnectToActionstep(this.props.callback);
    }
}

const mapStateToProps = (state: AppState) => {
    return {
        isFirstTime: state.common.isFirstTime
    }
}

export default connect(mapStateToProps)(ConnectToActionstep);