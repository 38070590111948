import * as CONSTANTS from './constants';
import { ErrorViewModel } from 'utils/wcaApiTypes';
import {
    GetMatterDetailsFromActionstep,
    GetMatterDetailsFromActionstepSuccess,
    OrderStewartTitlePolicy,
    OrderStewartTitlePolicySuccess,
    GetStewartTitleOrderDetails,
    GetStewartTitleOrderDetailsSuccess,
    IActionFailed,
} from './actionTypes';
import {
    StewartTitleActionstepResponse as StewartTitleActionstepResponse,
    OrderStewartTitlePolicyCommandResponse,
    StewartTitleOrderDetailsQueryResponse,
    OrderStewartTitlePolicyCommand
} from '../utils/stewartTitleWCAApiTypes';


export function getMatterDetailsFromActionstep(orgKey: string, matterId: number): GetMatterDetailsFromActionstep {
    return {
        type: CONSTANTS.GET_STEWART_TITLE_MATTER_FROM_ACTIONSTEP_REQUESTED,
        orgKey,
        matterId
    }
}

export function getMatterDetailsFromActionstepSuccess(data: StewartTitleActionstepResponse): GetMatterDetailsFromActionstepSuccess {
    return {
        type: CONSTANTS.GET_STEWART_TITLE_MATTER_FROM_ACTIONSTEP_SUCCESS,
        data
    }
}

export function getMatterDetailsFromActionstepFailed(error: ErrorViewModel): IActionFailed {
    return {
        type: CONSTANTS.GET_STEWART_TITLE_MATTER_FROM_ACTIONSTEP_FAILED,
        error
    }
}

export function orderStewartTitlePolicy(data: OrderStewartTitlePolicyCommand): OrderStewartTitlePolicy {
    return {
        type: CONSTANTS.ORDER_STEWART_TITLE_POLICY_REQUESTED,
        data
    }
}

export function orderStewartTitlePolicySuccess(data: OrderStewartTitlePolicyCommandResponse): OrderStewartTitlePolicySuccess {
    return {
        type: CONSTANTS.ORDER_STEWART_TITLE_POLICY_SUCCESS,
        data
    }
}

export function orderStewartTitlePolicyFailed(error: ErrorViewModel): IActionFailed {
    return {
        type: CONSTANTS.ORDER_STEWART_TITLE_POLICY_FAILED,
        error
    }
}

export function getStewartTitleOrderDetails(orgKey: string, matterId: number): GetStewartTitleOrderDetails {
    return {
        type: CONSTANTS.GET_STEWART_TITLE_ORDER_DETAILS_REQUESTED,
        orgKey,
        matterId
    }
}

export function getStewartTitleOrderDetailsSuccess(data: StewartTitleOrderDetailsQueryResponse): GetStewartTitleOrderDetailsSuccess {
    return {
        type: CONSTANTS.GET_STEWART_TITLE_ORDER_DETAILS_SUCCESS,
        data
    }
}

export function getStewartTitleOrderDetailsFailed(error: ErrorViewModel): IActionFailed {
    return {
        type: CONSTANTS.GET_STEWART_TITLE_ORDER_DETAILS_FAILED,
        error
    }
}