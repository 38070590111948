import * as React from 'react';
import { TextField, Dropdown, IDropdownOption } from 'office-ui-fabric-react/lib';
import CustomDatePickers from 'components/common/customDatePickers';
import { _onFormatDateDDMMYYYY } from 'utils/dataFormatter'

import {
    ActionstepMatter,
    MatterDetails as MatterDetailsModel
} from 'utils/wcaApiTypes';

const states: IDropdownOption[] = [
    { key: 'General', text: 'General' },
    { key: 'WA', text: 'WA' },
    { key: 'VIC', text: 'VIC' },
    { key: 'QLD', text: 'QLD' },
    { key: 'NSW', text: 'NSW' },
    { key: 'SA', text: 'SA' }
];

interface IMapStateToProps { }

interface IMapDispatchToProps { }

interface IProps {
    updatedState: any;
    updateValue: (newValue: any, whichValue: string, needRefresh?: boolean) => void;
    actionstepData: ActionstepMatter;
    matterDetails: MatterDetailsModel;
}

type AppProps = IMapStateToProps & IProps & IMapDispatchToProps;
type AppStates = {}

export default class MatterDetails extends React.Component<AppProps, AppStates> {

    public render(): JSX.Element {
        let { matterDetails, actionstepData } = this.props;
        let disabled: boolean = actionstepData['matterRef'] !== 0 && actionstepData['matterRef'] !== null;

        return (
            <div className="modal-body">
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row modal-row">
                        <div className="ms-Grid-col ms-sm4 modal-label">Statement Type</div>
                        <div className="ms-Grid-col ms-sm8">
                            <Dropdown
                                placeholder="Select State"
                                data-cy="state_input"
                                label=""
                                options={states}
                                defaultSelectedKey={matterDetails.state}
                                onChange={(ev, newItem) => this.props.updateValue(newItem!.text, "state")}
                            />
                        </div>
                    </div>

                    <div className="ms-Grid-row modal-row">
                        <div className="ms-Grid-col ms-sm4 modal-label">Matter Ref#:</div>
                        <div className="ms-Grid-col ms-sm8">
                            <TextField
                                disabled
                                data-cy="matter_ref_input"
                                defaultValue={matterDetails.matterRef.toString()}
                            />
                        </div>
                    </div>

                    <div className="ms-Grid-row modal-row">
                        <div className="ms-Grid-col ms-sm4 modal-label">Matter Name:</div>
                        <div className="ms-Grid-col ms-sm8">
                            <TextField
                                data-cy="matter_name_input"
                                disabled={disabled}
                                defaultValue={matterDetails.matter && matterDetails.matter.toString()}
                                onChange={(ev, newText) => this.props.updateValue(newText, 'matter')}
                            />
                        </div>
                    </div>

                    <div className="ms-Grid-row modal-row">
                        <div className="ms-Grid-col ms-sm4 modal-label">Property:</div>
                        <div className="ms-Grid-col ms-sm8">
                            <TextField
                                data-cy="property_input"
                                disabled={disabled}
                                defaultValue={matterDetails.property && matterDetails.property.toString()}
                                onChange={(ev, newText) => this.props.updateValue(newText, 'property')}
                            />
                        </div>
                    </div>

                    <div className="ms-Grid-row modal-row">
                        <div className="ms-Grid-col ms-sm4 modal-label">Adjustment Date:</div>
                        <div className="ms-Grid-col ms-sm8">
                            <CustomDatePickers
                                disabled={false}
                                updateValue={this.props.updateValue}
                                updatedState={this.props.updatedState}
                                datacy="adjustment_date_input"
                                datePickerName="adjustmentDate"
                            />
                        </div>
                    </div>

                    <div className="ms-Grid-row modal-row">
                        <div className="ms-Grid-col ms-sm4 modal-label">Settlement Date:</div>
                        <div className="ms-Grid-col ms-sm8">
                            <CustomDatePickers
                                disabled={false}
                                updateValue={this.props.updateValue}
                                updatedState={this.props.updatedState}
                                datacy="settlement_date_input"
                                datePickerName="settlementDate"
                            />
                        </div>
                    </div>

                    <div className="ms-Grid-row modal-row">
                        <div className="ms-Grid-col ms-sm4 modal-label">Settlement Place:</div>
                        <div className="ms-Grid-col ms-sm8">
                            <TextField
                                data-cy="settlement_place_input"
                                defaultValue={matterDetails.settlementPlace && matterDetails.settlementPlace.toString()}
                                onChange={(ev, newText) => this.props.updateValue(newText, 'settlementPlace')}
                            />
                        </div>
                    </div>

                    <div className="ms-Grid-row modal-row">
                        <div className="ms-Grid-col ms-sm4 modal-label">Settlement Time:</div>
                        <div className="ms-Grid-col ms-sm8">
                            <TextField
                                data-cy="settlement_time_input"
                                defaultValue={matterDetails.settlementTime && matterDetails.settlementTime.toString()}
                                onChange={(ev, newText) => this.props.updateValue(newText, 'settlementTime')}
                            />
                        </div>
                    </div>

                    <div className="ms-Grid-row modal-row">
                        <div className="ms-Grid-col ms-sm4 modal-label">Settlement Type:</div>
                        <div className="ms-Grid-col ms-sm8">
                            <TextField
                                disabled={true}
                                data-cy="settlement_type_input"
                                defaultValue={matterDetails.settlementType && matterDetails.settlementType.toString()}
                                onChange={(ev, newText) => this.props.updateValue(newText, 'settlementType')}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}