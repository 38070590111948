import * as React from 'react'
import { FunctionComponent } from 'react'

interface IProps {
    message?: string,
}

const LoadingWidget: FunctionComponent<IProps> = (props) => {
    const message = props.message ? props.message : 'Loading...'

    return (
        <div className="loading-widget">
            <img src="/images/Konekta_loading.svg" alt="Logo" height="150" /> {message}
        </div>
    )
}

export default LoadingWidget;