import { daysOfTheYear, showAdditionalFields, getNumberOfDays, roundToTwoDecimalPlaces, pdfTitle } from './Helper';
import { ModalIDs, apportionmentOption, periodOptions, stateName } from "./common";
import { SettlementInfo as SettlementInfoViewModel } from "utils/wcaApiTypes";
import { IState } from './settlementCalculatorInterface';

export const generateUIData = (stateData: IState) => {
    let contractDebit = 0,
        contractCredit = 0;
    let payableByVendorTotal = 0;
    let payableByPurchaserTotal = 0;
    let contractBalance = 0;
    let adjustmentGstTotal = 0;
    let purchasePriceGst = 0;

    let waterUsage: { [key: string]: any }[] = [],
        hasWaterUsage = false;
    const adjustments =
        (stateData.adjustments &&
            stateData.adjustments
                .map((adjustment, index) => {
                    if (adjustment.type === ModalIDs.addUpdateHeader) {
                        return {
                            ...adjustment,
                            title: ModalIDs.addUpdateHeader.valueOf(),
                            result: 0,
                            total: 0,
                            debit: 0,
                            credit: 0,
                            gst: 0,
                            value: {}
                        }
                    } else {
                        let result = calculate(adjustment, stateData);
                        let gst = 0;

                        if (adjustment.type === "Water Usage" || adjustment.type === "Recycled Water") {
                            waterUsage.push({ ...result, type: adjustment.type, index: index }); // include the index of the water usage entry to be matched with the show water usage button on the entry
                            result = roundToTwoDecimalPlaces(result["finalWaterUsageResult"]);
                            hasWaterUsage = true;
                        }
                        else {
                            result = roundToTwoDecimalPlaces(result);
                        }

                        gst = adjustment.value.gstApplicable ? roundToTwoDecimalPlaces(result * 0.1) : 0;

                        if (adjustment.type === "Contract Price") {
                            if (stateData.contractPriceGstEnabled) {
                                purchasePriceGst =  roundToTwoDecimalPlaces(adjustment.value["price"] * 0.1);
                                gst = purchasePriceGst;
                            }
                            contractDebit += adjustment.value["price"];
                            contractCredit += adjustment.value["deposit"];
                            contractBalance = adjustment.value["price"] - adjustment.value["deposit"];

                        } else if (adjustment.value["status"] === "unpaid" || adjustment.value["status"] === "less") {
                            contractCredit += result;
                            payableByVendorTotal += result;
                            adjustmentGstTotal += gst;
                        }
                        else {
                            contractDebit += result;
                            payableByPurchaserTotal += result;
                            adjustmentGstTotal += gst;
                        }

                        return {
                            ...adjustment,
                            title: adjustment.type,
                            result: result,
                            total: payableByPurchaserTotal - payableByVendorTotal,
                            debit: payableByPurchaserTotal,
                            credit: payableByVendorTotal,
                            gst: gst
                        };
                    }

                })) ||
        [];

    let feeDebit = payableByPurchaserTotal,
        feeCredit = payableByVendorTotal,
        fees: any[] = [];

    if (stateData.matterDetails.state === stateName.SA) {
        fees = stateData.fees.map((fee, index) => {
            let result = calculate(fee, stateData);
         
            if (fee.value["showOnAdjustment"] === true) {
                if (fee.value["status"] === "less") {
                    contractCredit += result;
                    feeCredit += result;
                } else {
                    contractDebit += result;
                    feeDebit += result;
                }

                return {
                    ...fee,
                    title: fee.type,
                    result: result,
                    total: feeDebit - feeCredit,
                    debit: feeDebit,
                    credit: feeCredit,
                };
            } else {
                if (fee.value["status"] === "less") feeCredit += result;
                else feeDebit += result;

                return {
                    ...fee,
                    title: fee.type,
                    result: result,
                    total: feeDebit - feeCredit,
                    debit: feeDebit,
                    credit: feeCredit,
                };
            }
        });
    }

    let additionalDebit = 0,
        additionalCredit = 0;

    const additionalRequirements = stateData.additionalRequirements.map(
        (additionalRequirement, index) => {
            let result = additionalRequirement.value["amount"];
            result = roundToTwoDecimalPlaces(result);
            if (additionalRequirement.value["status"] === "less") {
                additionalCredit += result;
            } else {
                additionalDebit += result;
            }

            return {
                ...additionalRequirement,
                result: result,
                total: additionalDebit - additionalCredit,
                debit: additionalDebit,
                credit: additionalCredit
            };
        }
    );

    let payeeDebit = 0,
        payeeCredit = 0,
        payeeTotal = 0;

    const payees = stateData.payees.map((payee, index) => {
        let result = 0;
        let isNumber = false;

        payee.value["amount"] = payee.value["amount"].toString();
        let value = payee.value["amount"].replace(/\s/g, "");

        if (!isNaN(value)) {
            payee.value["amount"] = value
            isNumber = true;
        }
        else if (isNaN(value) && value.includes(',')) {
            value = value.replaceAll(",", "");
            if (!isNaN(value)) {
                payee.value["amount"] = value;
                isNumber = true;
            }
        }

        if (isNumber) {
            result = parseFloat(payee.value["amount"]);
            payeeCredit += result;
            payeeTotal += result;
            result = Math.abs(result);
        }

        return {
            ...payee,
            result: result,
            total: payeeCredit,
            debit: 0,
            credit: payeeCredit
        };
    });

    let fundDebit = additionalDebit,
        fundCredit = additionalCredit,
        fundTotal = 0;

    const sourceOfFunds = stateData.sourceOfFunds.map((fund, index) => {
        let result = parseFloat(fund.value["amount"]);
        fundCredit += result;
        fundTotal += result;
        result = Math.abs(result);

        return {
            ...fund,
            result: result,
            total: fundCredit,
            debit: 0,
            credit: fundCredit,
        };
    });

    const ourRequirements = stateData.ourRequirements.map(
        (ourRequirement, index) => {
            return {
                ...ourRequirement,
                result: 0,
                total: 0,
            };
        }
    );

    feeDebit = roundToTwoDecimalPlaces(feeDebit);
    feeCredit = roundToTwoDecimalPlaces(feeCredit);

    payableByPurchaserTotal = roundToTwoDecimalPlaces(payableByPurchaserTotal);
    payableByVendorTotal = roundToTwoDecimalPlaces(payableByVendorTotal);

    let sumOfPlusLessAdjustmentAmount = payableByVendorTotal < 0 ? payableByPurchaserTotal + payableByVendorTotal : payableByPurchaserTotal - payableByVendorTotal;
    let balanceDueToTheVendorAtSettlement = contractBalance + sumOfPlusLessAdjustmentAmount + adjustmentGstTotal + purchasePriceGst;

    let showAdditionalRequirements = false;
    let showPayees = false;
    let showSourceOfFunds = false;
    let showOurRequirements = false;
    let showFeesExplained = false;
    let showAdjustment = true;
    let australianTaxationOfficeDeleted = false
    let statementType = stateData.settlementData.additionalInfo?.["statementType"];

    if (stateData.settlementData.additionalInfo !== undefined) {
        showAdditionalRequirements = stateData.settlementData.additionalInfo["showAdditionalRequirements"] !== undefined ? stateData.settlementData.additionalInfo["showAdditionalRequirements"] : false;
        showPayees = stateData.settlementData.additionalInfo["showPayees"] !== undefined ? stateData.settlementData.additionalInfo["showPayees"] : false;
        showSourceOfFunds = stateData.settlementData.additionalInfo["showSourceOfFunds"] !== undefined ? stateData.settlementData.additionalInfo["showSourceOfFunds"] : false;
        showOurRequirements = stateData.settlementData.additionalInfo["showOurRequirements"] !== undefined ? stateData.settlementData.additionalInfo["showOurRequirements"] : false;
        showFeesExplained = stateData.settlementData.additionalInfo["showFeesExplained"] !== undefined ? stateData.settlementData.additionalInfo["showFeesExplained"] : false;
        showAdjustment = stateData.settlementData.additionalInfo["showAdjustment"] !== undefined ? stateData.settlementData.additionalInfo["showAdjustment"] : true;
        australianTaxationOfficeDeleted = stateData.settlementData.additionalInfo["australianTaxationOfficeDeleted"] !== undefined ? stateData.settlementData.additionalInfo!["australianTaxationOfficeDeleted"] : false
    }

    let feesExplainedDescription = stateData.settlementData.additionalInfo?.["feesExplainedDescription"] ?? "";

    return new SettlementInfoViewModel({
        matterDetails: stateData.matterDetails,
        adjustments,
        fees,
        additionalRequirements,
        payees,
        sourceOfFunds,
        ourRequirements,
        waterUsage,
        additionalInfo: {
            title: pdfTitle(statementType ?? ''),
            contractBalance: contractBalance,
            contractDebit,
            contractCredit,
            feeDebit,
            feeCredit,
            totalPayee: payeeCredit,
            payeeCredit: payeeCredit,
            payeeDebit: payeeDebit,
            payeeTotal: payeeTotal,
            fundDebit: fundDebit,
            fundCredit: fundCredit,
            fundTotal: fundTotal,
            totalFund: fundCredit,
            totalAdditionalRequirements: additionalDebit - additionalCredit,
            totalWithAdditionalRequirements: contractDebit - contractCredit,
            additionalDebit: additionalDebit,
            additionalCredit: additionalCredit,
            unallocated: balanceDueToTheVendorAtSettlement + payeeDebit - payeeCredit,
            unallocatedFund: balanceDueToTheVendorAtSettlement + fundDebit - fundCredit,
            showAdditionalRequirements: showAdditionalRequirements,
            showPayees: showPayees,
            showSourceOfFunds: showSourceOfFunds,
            showOurRequirements: showOurRequirements,
            showFeesExplained: showFeesExplained,
            feesExplainedDescription: feesExplainedDescription,
            showAdjustment: showAdjustment,
            hasWaterUsage: hasWaterUsage,
            payableByVendorTotal: payableByVendorTotal,
            payableByPurchaserTotal: payableByPurchaserTotal,
            sumOfPlusLessAdjustmentAmount: sumOfPlusLessAdjustmentAmount,
            balanceDueToTheVendorAtSettlement: balanceDueToTheVendorAtSettlement,
            totalAdditionalFundsRequiredForSettlement: balanceDueToTheVendorAtSettlement + additionalDebit - additionalCredit,
            australianTaxationOfficeDeleted: australianTaxationOfficeDeleted,
            statementType: statementType,
            adjustmentGstTotal: adjustmentGstTotal,
            purchasePriceGst: purchasePriceGst
        },
    });
}

const calculate = (item: any, stateData: IState) => {
    switch (item.type) {
        case ModalIDs.contractPrice:
            return item.value["price"] - item.value["deposit"];

        case ModalIDs.dischargeFee:
        case ModalIDs.releaseFee:
            return item.value["mortgages"] * item.value["each"];

        case ModalIDs.recycledWater:
        case ModalIDs.waterUsage:
            let waterUsageCalcTotal = 0;
            let baseRateResult30June = 0;
            let tier2Result30June = 0;
            let tier3Result30June = 0;
            let balanceResult30June = 0;
            let bulkResult30June = 0;

            let waterBillReadingDate = item.value["paidDate"];
            let waterBillPaidDate = item.value["searchDate"];

            // if (item.type === ModalIDs.recycledWater && item.value["method"] === "daily-average" && (waterBillReadingDate == null || waterBillPaidDate == null)) {
            //     waterBillReadingDate = new Date();
            //     waterBillPaidDate = new Date();
            // }

            let waterReadingAndPaidDateDiff = Math.abs(waterBillPaidDate - waterBillReadingDate);
            waterReadingAndPaidDateDiff = getNumberOfDays(waterReadingAndPaidDateDiff);
            waterReadingAndPaidDateDiff = waterReadingAndPaidDateDiff === 0 ? 1 : waterReadingAndPaidDateDiff; // might cause error here, paid date - reading date doesn't consider the paid date itself
            let diffAmountReading = Math.abs(item.value["searchReadingAmount"] - item.value["paidReadingAmount"]);
            let dailyUsage = waterReadingAndPaidDateDiff ? (diffAmountReading / waterReadingAndPaidDateDiff) : 0;
            dailyUsage = +dailyUsage;
            dailyUsage = Math.round(dailyUsage * 1000) / 1000; // round up to 3 digits so the calculation for dailyAndDays can be correct

            if (item.value["method"] === "daily-average") {
                dailyUsage = +item.value["averageDailyAmount"];
            }

            let diffMs = Math.abs(stateData.matterDetails.adjustmentDate.valueOf() - waterBillReadingDate);

            if (stateData.matterDetails.state === stateName.VIC && item.value["method"] === "daily-average" && item.value["averageDailyAmount"] != 0) {
                diffMs = Math.abs(stateData.matterDetails.adjustmentDate.valueOf() - waterBillPaidDate);
            }

            let adjustmentAndWaterBillReadingDateDiffInDays = getNumberOfDays(diffMs);
            // adjustmentAndWaterBillReadingDateDiffInDays = adjustmentAndWaterBillReadingDateDiffInDays === 0 ? 1 : adjustmentAndWaterBillReadingDateDiffInDays + 1; // comment out since there should be no + 1 date in here
            let dailyAndDays = dailyUsage * adjustmentAndWaterBillReadingDateDiffInDays;
            dailyAndDays = +dailyAndDays;

            item.value["stateBulkWaterUsagesKLJune"] = parseFloat(item.value["stateBulkWaterCharges"]) === 0 ? 0 : dailyAndDays;
            item.value["stateBulkWaterUsagesKLJuly"] = parseFloat(item.value["stateBulkWaterFeeIncrease"]) === 0 ? 0 : dailyAndDays;

            let adjustedBaseRateKLCount = parseFloat(item.value["adjustedBaseRateKLCount"]);
            let baseRateCostPerKL = parseFloat(item.value["baseRateCostPerKL"]);
            let baseRateIncrease = parseFloat(item.value["baseRateIncrease"]);

            let adjustedTier2KLCount = parseFloat(item.value["adjustedTier2KLCount"]);
            let tier2CostPerKL = parseFloat(item.value["tier2CostPerKL"]);
            let tier2FeeIncrease = parseFloat(item.value["tier2FeeIncrease"]);

            let adjustedTier3KLCount = parseFloat(item.value["adjustedTier3KLCount"]);
            let tier3CostPerKL = parseFloat(item.value["tier3CostPerKL"]);
            let tier3FeeIncrease = parseFloat(item.value["tier3FeeIncrease"]);

            let stateBulkWaterUsagesKLJune = parseFloat(item.value["stateBulkWaterUsagesKLJune"]);
            let stateBulkWaterUsagesKLJuly = parseFloat(item.value["stateBulkWaterUsagesKLJuly"]);

            let balanceCalc = 0;

            let tempTotalUsages = dailyAndDays;

            if (tempTotalUsages >= adjustedBaseRateKLCount) {
                tempTotalUsages -= adjustedBaseRateKLCount;
            } else {
                adjustedBaseRateKLCount = tempTotalUsages;
                adjustedTier2KLCount = 0;
                adjustedTier3KLCount = 0;

                item.value["adjustedBaseRateKLCount"] = tempTotalUsages;
                item.value["adjustedTier2KLCount"] = 0;
                item.value["adjustedTier3KLCount"] = 0;
            }

            if (tempTotalUsages >= adjustedTier2KLCount) {
                tempTotalUsages -= adjustedTier2KLCount;
                item.value["adjustedTier3KLCount"] = Math.min(tempTotalUsages, adjustedTier3KLCount);
            } else {
                adjustedTier2KLCount = tempTotalUsages;
                adjustedTier3KLCount = 0;

                item.value["adjustedTier2KLCount"] = tempTotalUsages;
                item.value["adjustedTier3KLCount"] = 0;
            }

            balanceCalc = dailyAndDays - adjustedBaseRateKLCount - adjustedTier2KLCount - adjustedTier3KLCount;
            balanceCalc = balanceCalc < 0 ? 0 : balanceCalc;


            let baseRateResult = 0,
                tier2Result = 0,
                tier3Result = 0,
                balanceResult = 0,
                bulkResult = 0

            let numberOfDaysToJune = 0,
                numberOfDays = 0,
                numberOfDaysToJuly = 0
            if (item.value["method"] === "daily-average" && item.value["averageDailyAmount"] == 0 && stateData.matterDetails.state === stateName.VIC) {
                let NodaysBetweenPaidTODateAndAdjustmentDate = Math.abs(waterBillPaidDate - stateData.matterDetails.adjustmentDate.valueOf());
                NodaysBetweenPaidTODateAndAdjustmentDate = getNumberOfDays(NodaysBetweenPaidTODateAndAdjustmentDate); // might be problem matic, paid date - adjustment date does not consider the paid date itself

                waterUsageCalcTotal = item.value["averageDailyAmountCharge"] * NodaysBetweenPaidTODateAndAdjustmentDate;
            }
            else {

                if (parseFloat(item.value["baseRateIncrease"]) > 0
                    || parseFloat(item.value["tier2FeeIncrease"]) > 0
                    || parseFloat(item.value["tier3FeeIncrease"]) > 0
                    || parseFloat(item.value["balanceWaterChargesFeeIncrease"]) > 0
                    || parseFloat(item.value["stateBulkWaterFeeIncrease"]) > 0) {

                    // all these number of days to june, to july (should have been 'from july'), number of days doesn't factor in the end date itself
                    // this might cause problem in the future and will require adding 1 to the date count
                    let juneMonth = waterBillReadingDate.getUTCFullYear() + '-06-30'
                    let parsedDateJune30 = new Date(Date.parse(juneMonth.toString()));
                    let absoluteDateJune30 = Math.abs(parsedDateJune30.valueOf() - waterBillReadingDate.valueOf());
                    numberOfDaysToJune = getNumberOfDays(absoluteDateJune30);

                    let absoluteDateJuly = Math.abs(parsedDateJune30.valueOf() - stateData.matterDetails.adjustmentDate.valueOf());
                    numberOfDaysToJuly = getNumberOfDays(absoluteDateJuly);

                    let absoluteDateFromJune = Math.abs(stateData.matterDetails.adjustmentDate.valueOf() - waterBillReadingDate.valueOf());
                    numberOfDays = getNumberOfDays(absoluteDateFromJune);

                    if (item.value["method"] === "daily-average" && stateData.matterDetails.state === stateName.NSW) {

                        baseRateResult = dailyUsage * baseRateCostPerKL * numberOfDaysToJune
                        tier2Result = dailyUsage * tier2CostPerKL * numberOfDaysToJune;
                        tier3Result = dailyUsage * tier3CostPerKL * numberOfDaysToJune;
                        balanceResult = dailyUsage * item.value["balanceWaterCharges"] * numberOfDaysToJune;
                        bulkResult = dailyUsage * item.value["stateBulkWaterCharges"] * numberOfDaysToJune;

                        baseRateResult30June = baseRateResult;
                        tier2Result30June = tier2Result;
                        tier3Result30June = tier3Result;
                        balanceResult30June = balanceResult;
                        bulkResult30June = bulkResult;

                        let water_usage_calc_tot_june30 = baseRateResult + tier2Result + tier3Result + balanceResult + bulkResult;

                        // july 1
                        baseRateResult = item.value["baseRateIncrease"] * dailyUsage * numberOfDaysToJuly;
                        tier2Result = item.value["tier2FeeIncrease"] * dailyUsage * numberOfDaysToJuly;
                        tier3Result = item.value["tier3FeeIncrease"] * dailyUsage * numberOfDaysToJuly;
                        balanceResult = item.value["balanceWaterChargesFeeIncrease"] * dailyUsage * numberOfDaysToJuly;
                        bulkResult = item.value["stateBulkWaterFeeIncrease"] * dailyUsage * numberOfDaysToJuly;

                        waterUsageCalcTotal = water_usage_calc_tot_june30 + baseRateResult + tier2Result + tier3Result + balanceResult + bulkResult;
                    }

                    else if (item.value["method"] === "search-reading" && stateData.matterDetails.state === stateName.NSW) {

                        baseRateResult = dailyAndDays * baseRateCostPerKL * numberOfDaysToJune / numberOfDays;
                        tier2Result = dailyAndDays * tier2CostPerKL * numberOfDaysToJune / numberOfDays;
                        tier3Result = dailyAndDays * tier3CostPerKL * numberOfDaysToJune / numberOfDays;
                        balanceResult = dailyAndDays * item.value["balanceWaterCharges"] * numberOfDaysToJune / numberOfDays;
                        bulkResult = stateBulkWaterUsagesKLJune * item.value["stateBulkWaterCharges"] * numberOfDaysToJune / numberOfDays;

                        baseRateResult30June = baseRateResult;
                        tier2Result30June = tier2Result;
                        tier3Result30June = tier3Result;
                        balanceResult30June = balanceResult;
                        bulkResult30June = bulkResult;

                        let water_usage_calc_tot_june30 = baseRateResult + tier2Result + tier3Result + balanceResult + bulkResult;

                        // july 1
                        baseRateResult = dailyAndDays * parseFloat(item.value["baseRateIncrease"]) * parseFloat(numberOfDaysToJuly.toString()) / parseFloat(numberOfDays.toString());
                        tier2Result = dailyAndDays * parseFloat(item.value["tier2FeeIncrease"]) * parseFloat(numberOfDaysToJuly.toString()) / parseFloat(numberOfDays.toString());
                        tier3Result = dailyAndDays * parseFloat(item.value["tier3FeeIncrease"]) * parseFloat(numberOfDaysToJuly.toString()) / parseFloat(numberOfDays.toString());
                        balanceResult = dailyAndDays * parseFloat(item.value["balanceWaterChargesFeeIncrease"]) * parseFloat(numberOfDaysToJuly.toString()) / parseFloat(numberOfDays.toString());
                        bulkResult = stateBulkWaterUsagesKLJuly * item.value["stateBulkWaterFeeIncrease"] * numberOfDaysToJuly / numberOfDays;

                        waterUsageCalcTotal = water_usage_calc_tot_june30 + baseRateResult + tier2Result + tier3Result + balanceResult + bulkResult;
                    }
                    else {
                        baseRateResult = adjustedBaseRateKLCount * baseRateCostPerKL * numberOfDaysToJune / numberOfDays;
                        tier2Result = adjustedTier2KLCount * tier2CostPerKL * numberOfDaysToJune / numberOfDays;
                        tier3Result = adjustedTier3KLCount * tier3CostPerKL * numberOfDaysToJune / numberOfDays;
                        balanceResult = balanceCalc * item.value["balanceWaterCharges"] * numberOfDaysToJune / numberOfDays;
                        bulkResult = stateBulkWaterUsagesKLJune * item.value["stateBulkWaterCharges"] * numberOfDaysToJune / numberOfDays;

                        baseRateResult30June = baseRateResult;
                        tier2Result30June = tier2Result;
                        tier3Result30June = tier3Result;
                        balanceResult30June = balanceResult;
                        bulkResult30June = bulkResult;

                        let water_usage_calc_tot_june30 = baseRateResult + tier2Result + tier3Result + balanceResult + bulkResult;

                        // july 1
                        baseRateResult = adjustedBaseRateKLCount * parseFloat(item.value["baseRateIncrease"]) * parseFloat(numberOfDaysToJuly.toString()) / parseFloat(numberOfDays.toString());
                        tier2Result = adjustedTier2KLCount * parseFloat(item.value["tier2FeeIncrease"]) * parseFloat(numberOfDaysToJuly.toString()) / parseFloat(numberOfDays.toString());
                        tier3Result = adjustedTier3KLCount * parseFloat(item.value["tier3FeeIncrease"]) * parseFloat(numberOfDaysToJuly.toString()) / parseFloat(numberOfDays.toString());
                        balanceResult = parseFloat(balanceCalc.toString()) * parseFloat(item.value["balanceWaterChargesFeeIncrease"]) * parseFloat(numberOfDaysToJuly.toString()) / parseFloat(numberOfDays.toString());
                        bulkResult = stateBulkWaterUsagesKLJuly * item.value["stateBulkWaterFeeIncrease"] * numberOfDaysToJuly / numberOfDays;

                        waterUsageCalcTotal = water_usage_calc_tot_june30 + baseRateResult + tier2Result + tier3Result + balanceResult + bulkResult;
                    }

                } else if (baseRateIncrease === 0
                    && tier2FeeIncrease === 0
                    && tier3FeeIncrease === 0
                    && parseFloat(item.value["balanceWaterChargesFeeIncrease"]) === 0
                    && parseFloat(item.value["stateBulkWaterFeeIncrease"]) === 0) {

                    if (item.value["method"] === "daily-average" && stateData.matterDetails.state === stateName.NSW) {
                        baseRateResult = baseRateCostPerKL * dailyUsage * adjustmentAndWaterBillReadingDateDiffInDays;
                        tier2Result = tier2CostPerKL * dailyUsage * adjustmentAndWaterBillReadingDateDiffInDays;
                        tier3Result = tier3CostPerKL * dailyUsage * adjustmentAndWaterBillReadingDateDiffInDays;
                        balanceResult = balanceCalc * item.value["balanceWaterCharges"];
                        bulkResult = stateBulkWaterUsagesKLJune * item.value["stateBulkWaterCharges"];

                        waterUsageCalcTotal = baseRateResult + tier2Result + tier3Result + balanceResult + bulkResult;
                    }
                    else if (item.value["method"] === "search-reading" && stateData.matterDetails.state === stateName.NSW) {
                        baseRateResult = baseRateCostPerKL * dailyAndDays;
                        tier2Result = tier2CostPerKL * dailyAndDays;
                        tier3Result = tier3CostPerKL * dailyAndDays;
                        balanceResult = dailyAndDays * item.value["balanceWaterCharges"];
                        bulkResult = stateBulkWaterUsagesKLJune * item.value["stateBulkWaterCharges"];

                        waterUsageCalcTotal = baseRateResult + tier2Result + tier3Result + balanceResult + bulkResult;
                    }
                    else {
                        baseRateResult = adjustedBaseRateKLCount * baseRateCostPerKL;
                        tier2Result = adjustedTier2KLCount * tier2CostPerKL;
                        tier3Result = adjustedTier3KLCount * tier3CostPerKL;
                        balanceResult = balanceCalc * item.value["balanceWaterCharges"];
                        bulkResult = stateBulkWaterUsagesKLJune * item.value["stateBulkWaterCharges"];

                        waterUsageCalcTotal = baseRateResult + tier2Result + tier3Result + balanceResult + bulkResult;
                    }
                }

                // if (item.type === ModalIDs.recycledWater && item.value["method"] === "daily-average") {
                //     let juneMonth = waterBillReadingDate.getUTCFullYear() + '-06-30'
                //     let parsedDateJune30 = new Date(Date.parse(juneMonth.toString()));
                //     // can be problematic, this number of days to june here doesn't have the + 1 date for the 30/6 day itself, while the date to july (should be from july) has it
                //     let absoluteDateJune30 = Math.abs(parsedDateJune30.valueOf() - waterBillReadingDate.valueOf());
                //     let numberOfDaysToJune = getNumberOfDays(absoluteDateJune30);

                //     let absoluteDateJuly = Math.abs(parsedDateJune30.valueOf() - waterBillPaidDate.valueOf() + 1);
                //     let numberOfDaysToJuly = getNumberOfDays(absoluteDateJuly);
                    
                //     // get month function returns an index, not the month number itself
                //     if (waterBillReadingDate.getMonth() <= 5 && waterBillPaidDate.getMonth() > 5) {
                //         waterUsageCalcTotal = 0;
                //         waterUsageCalcTotal = ((item.value["averageDailyAmount"] * numberOfDaysToJune) + (item.value["increasedFee"] * numberOfDaysToJuly))
                //     }
                //     else {
                //         waterUsageCalcTotal = 0;
                //         waterUsageCalcTotal = (item.value["averageDailyAmount"]) * (waterReadingAndPaidDateDiff)
                //     }
                // }
            }

            let finalWaterUsageResult = 0;
            if (item.value["ctsOption"] === apportionmentOption.sharedPercentage && item.value["percentage"]) {
                let percent = item.value["percentage"] / 100;
                finalWaterUsageResult = waterUsageCalcTotal * percent;
            } else if (item.value["ctsOption"] === apportionmentOption.doNotApportion) {
                finalWaterUsageResult = waterUsageCalcTotal;
            } else if (item.value["ctsOption"] === apportionmentOption.entitlement && item.value["entitlementValue"] && item.value["entitlementValue"].toString().split("/").length > 1) {
                let entitlement_Final = item.value["entitlementValue"].split("/")[0] / item.value["entitlementValue"].split("/")[1];
                entitlement_Final = parseFloat(entitlement_Final.toString())
                finalWaterUsageResult = waterUsageCalcTotal * entitlement_Final;
            }

            let someOfResultIncludingJuly = 0;
            let someOfResultExcludingJuly = 0;
            let showJulyCalculation = false;

            if (baseRateIncrease === 0 && tier2FeeIncrease === 0 && tier3FeeIncrease === 0 && parseFloat(item.value["balanceWaterChargesFeeIncrease"]) === 0 && parseFloat(item.value["stateBulkWaterFeeIncrease"]) === 0) {
                someOfResultExcludingJuly = baseRateResult + tier2Result + tier3Result + balanceResult + bulkResult;
            }
            else {
                someOfResultIncludingJuly = baseRateResult + tier2Result + tier3Result + balanceResult + bulkResult + baseRateResult30June + tier2Result30June + tier3Result30June + balanceResult30June + bulkResult30June;
                showJulyCalculation = true;
            }

            return {
                ...item.value,
                numberOfDays: waterReadingAndPaidDateDiff,
                partDays: adjustmentAndWaterBillReadingDateDiffInDays,
                dailyAndDays: parseFloat(dailyAndDays.toFixed(3)),
                numberOfDaysToJune,
                numberOfDaysToJuly,
                numberOfDaysFromJune: numberOfDays,
                adjustedBaseRateKLCount: parseFloat(adjustedBaseRateKLCount.toFixed(3)),
                adjustedTier2KLCount: parseFloat(adjustedTier2KLCount.toFixed(3)),
                adjustedTier3KLCount: parseFloat(adjustedTier3KLCount.toFixed(3)),
                baseRateResult: parseFloat(baseRateResult.toFixed(3)),
                tier2Result: parseFloat(tier2Result.toFixed(3)),
                tier3Result: parseFloat(tier3Result.toFixed(3)),
                balanceResult: parseFloat(balanceResult.toFixed(3)),
                bulkResult: parseFloat(bulkResult.toFixed(3)),
                balanceCalc: parseFloat(balanceCalc.toFixed(3)),
                finalWaterUsageResult: parseFloat(finalWaterUsageResult.toFixed(3)),
                dailyUsage: parseFloat(dailyUsage.toFixed(3)),
                diffAmountReading: parseFloat(diffAmountReading.toFixed(3)),
                baseRateResult30June: parseFloat(baseRateResult30June.toFixed(3)),
                tier2Result30June: parseFloat(tier2Result30June.toFixed(3)),
                tier3Result30June: parseFloat(tier3Result30June.toFixed(3)),
                balanceResult30June: parseFloat(balanceResult30June.toFixed(3)),
                bulkResult30June: parseFloat(bulkResult30June.toFixed(3)),
                someOfResultIncludingJuly: parseFloat(someOfResultIncludingJuly.toFixed(3)),
                someOfResultExcludingJuly: parseFloat(someOfResultExcludingJuly.toFixed(3)),
                showJulyCalculation
            };

        case ModalIDs.defaultInterest:
        case ModalIDs.penaltyInterest:
            let contractPrice = stateData.adjustments![0].value.price - stateData.adjustments![0].value.deposit;
            let rateBalance = contractPrice * item.value["rate"] / 100;
            let yearOfadjustment = stateData.matterDetails.adjustmentDate.getFullYear;
            let daysInTheYear = daysOfTheYear(yearOfadjustment);

            let result = 0;
            if (item.value["to"] <= item.value["from"]) {
                item.value["days"] = 0;
            }
            else {
                let diffMs = Math.abs(item.value["to"] - item.value["from"]);
                // can be problematic, no + 1 date here
                let numberOfDays = Math.abs(item.value["days"]) ?? getNumberOfDays(diffMs);
                result = rateBalance * (numberOfDays / daysInTheYear);
            }
            return result;

        case ModalIDs.otherAdjustment:
        case ModalIDs.otherAdjustmentLegacy:
            return item.value["amount"];

        case ModalIDs.fee:
            return item.value["amount"];

        case ModalIDs.waterAccessFee:
        case ModalIDs.waterAndSewerageRates:
        case ModalIDs.waterAvailability:
        case ModalIDs.waterDrainageFee:
        case ModalIDs.waterRatesChargesLevies:
        case ModalIDs.waterServiceCharge:
        case ModalIDs.sewerageAccessFee:
        case ModalIDs.WaterRates:
        case ModalIDs.SewerageRates:
        case ModalIDs.DrainageCharge:
        case ModalIDs.SAWaterSupplyAndSewerage:
            let toDate: Date = item.value["to"] as Date;
            // workaround to deal with situations where 'to' date type cast didn't happen properly (i.e. yield an empty date but not null or undefined)
            // this happens when the 'calculate to annual amount' is checked and the form resets leading to the 'to' field being incorrectly reinitialised
            try {
                toDate.getFullYear();
            } catch (ex) {
                toDate = new Date(2000, 0, 0);
            }

            let noDaysPerYear = daysOfTheYear(toDate.getFullYear() ?? 0);
            // if after 1 july, use next year as the year for financial date
            let noDaysPerFinancialYear = daysOfTheYear(toDate.getMonth() > 5 ? toDate.getFullYear() + 1 : toDate.getFullYear()); 

            if (showAdditionalFields(item.type.toString()) && item.value["method"] === "daily-average") {

                let waterBillReadingDate = item.value["from"];
                let waterBillPaidDate = item.value["to"];


                let waterReadingAndPaidDateDiff = Math.abs(waterBillPaidDate - waterBillReadingDate);
                waterReadingAndPaidDateDiff = getNumberOfDays(waterReadingAndPaidDateDiff);
                waterReadingAndPaidDateDiff = waterReadingAndPaidDateDiff === 0 ? 1 : waterReadingAndPaidDateDiff + 1;

                let juneMonth = waterBillReadingDate.getUTCFullYear() + '-06-30'
                let parsedDateJune30 = new Date(Date.parse(juneMonth.toString()));
                let absoluteDateJune30 = Math.abs(parsedDateJune30.valueOf() - waterBillReadingDate.valueOf());
                let numberOfDaysToJune = getNumberOfDays(absoluteDateJune30) + 1; // + 1 to factor for 30th june itself

                let absoluteDateJuly = Math.abs(parsedDateJune30.valueOf() - waterBillPaidDate.valueOf() + 1);
                let numberOfDaysToJuly = getNumberOfDays(absoluteDateJuly); // this one has the + 1 here already so it's all good

                let amount = 0;
                let finalResult = 0;

                // get month function returns an index, not the month number itself
                // if (false) {
                if ((waterBillReadingDate.getMonth() <= 5) && (waterBillPaidDate.getMonth() > 5)) {
                    let increasedFee = (item.value["increasedFee"] && item.value["increasedFee"]) > 0 ? item.value["increasedFee"] : item.value["averageDailyAmount"];
                    amount = ((item.value["averageDailyAmount"] * numberOfDaysToJune) + (increasedFee * numberOfDaysToJuly))
                }
                else {
                    amount = item.value["averageDailyAmount"] * waterReadingAndPaidDateDiff;
                }

                if (item.value["percentage"] && item.value["ctsOption"] === apportionmentOption.sharedPercentage) {
                    finalResult = amount * item.value["percentage"] / 100;
                }
                else if ((item.value["ctsOption"] === apportionmentOption.entitlement) && item.value["entitlementValue"]) {
                    let entitle_value_final = item.value["entitlementValue"].split("/")[0] / item.value["entitlementValue"].split("/")[1];
                    finalResult = amount * entitle_value_final;
                }
                else {
                    finalResult = amount;
                }
                return finalResult;
            }

            if (item.value["days"] === 0)
                return 0;

            else if (item.value["calculateToAnnualAmount"] && item.value["calculateToAnnualAmount"] === true) {
                return (item.value["amount"] * 4 / noDaysPerFinancialYear * item.value["adjustDays"]);
            }

            else if (item.value["ctsOption"] === apportionmentOption.sharedPercentage && item.value["percentage"] && item.value["adjustmentPeriod"] === periodOptions.Daily && item.value["billingPeriod"] === periodOptions.AnnualFinancial) {
                let adjustAmount = item.value["amount"] / noDaysPerYear * item.value["days"]
                return adjustAmount + (adjustAmount * item.value["percentage"] / 100)
            }

            else if (item.value["ctsOption"] === apportionmentOption.entitlement && item.value["entitlementValue"] && item.value["adjustmentPeriod"] === periodOptions.Daily && item.value["billingPeriod"] === periodOptions.AnnualFinancial) {
                let entitle_value_final = item.value["entitlementValue"].split("/")[0] / item.value["entitlementValue"].split("/")[1];
                return item.value["amount"] * entitle_value_final * item.value["adjustDays"] / item.value["days"] ; 
            }

            else if (item.value["adjustmentPeriod"] === periodOptions.Daily && item.value["billingPeriod"] === periodOptions.AnnualFinancial && item.value["ctsOption"] === apportionmentOption.doNotApportion) {
                return item.value["amount"] / noDaysPerYear * item.value["days"]
            }

            else if (item.value["ctsOption"] === apportionmentOption.sharedPercentage && item.value["percentage"] && item.value["adjustmentPeriod"] && item.value["adjustmentPeriod"] !== periodOptions.Daily) {
                return (item.value["amount"] * item.value["percentage"] / 100 ) * item.value["adjustDays"] / item.value["days"];
            }

            else if (item.value["ctsOption"] === apportionmentOption.entitlement && item.value["entitlementValue"] && item.value["adjustmentPeriod"] && item.value["adjustmentPeriod"] !== periodOptions.Daily) {
                let entitle_value_final = item.value["entitlementValue"].split("/")[0] / item.value["entitlementValue"].split("/")[1];
                return (item.value["amount"] * entitle_value_final) / item.value["days"] * item.value["adjustDays"];
            }

            else {
                return item.value["amount"] * item.value["adjustDays"]/ item.value["days"];
            }

        default:
            if (item.value["days"] === 0)
                return 0;

            if (item.value["ctsOption"] === apportionmentOption.sharedPercentage && item.value["percentage"]) {
                return (item.value["adjustDays"] / item.value["days"]) * (item.value["amount"] * item.value["percentage"] / 100)
            }

            else if (item.value["entitlementValue"] && item.value["ctsOption"] === apportionmentOption.entitlement) {
                let entile_value_final = item.value["entitlementValue"].split('/')[0] / item.value["entitlementValue"].split('/')[1];
                return (item.value["amount"] * entile_value_final) * (item.value["adjustDays"] / item.value["days"])
            }
            else {
                return item.value["amount"] * item.value["adjustDays"] / item.value["days"];
            }
    }
}