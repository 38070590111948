import { apportionmentOption } from "../settlement/common";
import { formatToLocaleDateTimeString, getNumberOfDays } from "../settlement/Helper";
import { MatterDetails, SettlementInfo, SettlementMatterViewModel } from "utils/wcaApiTypes";

export const generateUIData = (updatedState: any, matterDetails: MatterDetails, settlementMatter: SettlementMatterViewModel) => {
    let waterUsagesResult = calculate(updatedState, settlementMatter);

    console.log(waterUsagesResult);

    let newSettlementData = new SettlementMatterViewModel();
    newSettlementData.init({
        ...settlementMatter,
        localDateTime: formatToLocaleDateTimeString(new Date()),
        settlementData: new SettlementInfo({
            ...settlementMatter.settlementData,
            matterDetails: matterDetails,
            vicWaterUsage: waterUsagesResult
        })
    });
    return newSettlementData;
}

const calculate = (item: any, waterUsageData: SettlementMatterViewModel) => {
    let waterUsage30June = 0;
    let sewerUsage30June = 0;

    let searchReadingDate = new Date(item["searchDate"]);

    let waterUsage = item["waterUsage"] ?? 0;
    let waterUsageIncrease = item["waterUsageIncrease"] ?? 0;

    let sewerUsage = item["sewerUsage"] ?? 0;
    let sewerUsageIncrease = item["sewerUsageIncrease"] ?? 0;

    let adjustmentDate = waterUsageData.actionstepData && waterUsageData.actionstepData["adjustmentDate"];

    // these dates might be problematic cause there's no + 1 here
    adjustmentDate = new Date(adjustmentDate!)
    let numberOfDays = getNumberOfDays(Math.abs(searchReadingDate.valueOf() - adjustmentDate.valueOf()));

    let waterUsageFull = waterUsage * numberOfDays;
    let sewerUsageFull = sewerUsage * numberOfDays;

    let waterUsageCalc = waterUsageFull + sewerUsageFull;

    let numberOfDaysToJune = 0,
        numberOfDaysFromJuly = 0;

    // calculate the dates;
    let june30 = adjustmentDate.getUTCFullYear() + '-06-30'
    let startDate = adjustmentDate.valueOf() < searchReadingDate.valueOf() ? adjustmentDate.valueOf() : searchReadingDate.valueOf();
    let endDate = adjustmentDate.valueOf() > searchReadingDate.valueOf() ? adjustmentDate.valueOf() : searchReadingDate.valueOf();

    let parsedDateJune30 = new Date(Date.parse(june30.toString()));

    let absoluteDateJune30 = Math.abs(parsedDateJune30.valueOf() - startDate);
    numberOfDaysToJune = getNumberOfDays(absoluteDateJune30);
    let absoluteDateJuly1 = Math.abs(parsedDateJune30.valueOf() - endDate);
    numberOfDaysFromJuly = getNumberOfDays(absoluteDateJuly1);

     // before june 30
    waterUsage30June = waterUsage * numberOfDaysToJune;
    sewerUsage30June = sewerUsage * numberOfDaysToJune;

    let waterUsageCalcToJune30 = waterUsage30June + sewerUsage30June;

    // from july 1
    let waterUsageResult = waterUsageIncrease * numberOfDaysFromJuly
    let sewerUsageResult = sewerUsageIncrease * numberOfDaysFromJuly

    let waterUsageCalcFromJuly1 = waterUsageResult + sewerUsageResult;

    let sumOfResultIncludingJuly = 0;
    let sumOfResultExcludingJuly = 0;
    let showJulyCalculation = false;

    if (parseFloat(waterUsageIncrease) === 0 && parseFloat(sewerUsageIncrease) === 0) {
        sumOfResultExcludingJuly = waterUsageFull + sewerUsageFull;
    }
    else {
        sumOfResultIncludingJuly = waterUsageResult + waterUsage30June + sewerUsageResult + sewerUsage30June;
        showJulyCalculation = true;
    }

    // total usage
    let waterUsageCalcTotal = showJulyCalculation ? waterUsageCalcToJune30 + waterUsageCalcFromJuly1 : waterUsageCalc;

    let finalWaterUsageResult = 0;
    if (item["ctsOption"] === apportionmentOption.sharedPercentage) {
        let percent = item["percentage"] / 100;
        finalWaterUsageResult = waterUsageCalcTotal * percent;
    } else if (item["ctsOption"] === apportionmentOption.doNotApportion) {
        finalWaterUsageResult = waterUsageCalcTotal;
    } else if (item["ctsOption"] === apportionmentOption.entitlement && (item["entitlementValue"] ? true : false) && item["entitlementValue"].toString().split("/").length > 1) {
        let entitlement_Final = item["entitlementValue"].split("/")[0] / item["entitlementValue"].split("/")[1];
        entitlement_Final = parseFloat(entitlement_Final.toString())
        finalWaterUsageResult = waterUsageCalcTotal * entitlement_Final;
    }

    return {
        ...item,
        numberOfDays,
        numberOfDaysToJune,
        numberOfDaysFromJuly,
        finalWaterUsageResult: parseFloat(finalWaterUsageResult.toFixed(3)),
        waterUsageFull: parseFloat(waterUsageFull.toFixed(3)),
        sewerUsageFull: parseFloat(sewerUsageFull.toFixed(3)),
        waterUsageResult: parseFloat(waterUsageResult.toFixed(3)),
        sewerUsageResult: parseFloat(sewerUsageResult.toFixed(3)),
        waterUsage30June: parseFloat(waterUsage30June.toFixed(3)),
        sewerUsage30June: parseFloat(sewerUsage30June.toFixed(3)),
        sumOfResultIncludingJuly: sumOfResultIncludingJuly,
        sumOfResultExcludingJuly: sumOfResultExcludingJuly,
        showJulyCalculation
    };
}