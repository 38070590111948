import {
    AccountModel,
    UISettings,
    ActionstepConnectionsResponse,
    GetUserAuditTrailDataResponse,
} from 'utils/wcaApiTypes';
import { ICommonAction } from './actions';
import { CommonEvents } from './events';

export enum RequestStatus {
    NotRequested,
    Requested,
    Succeeded,
    Failed,
}

export enum MatterRequestStatus {
    NotRequested,
    Requested,
    Succeeded,
    Failed,
    NotFound,
    NotSupplied,
}

export interface ActionstepMatterContext {
    id: number;
    name?: string;
    actionTypeId?: number;
    actionTypeName?: string;
    timezone?: string;
}

export interface ActionstepCredentials {
    verificationStatus: RequestStatus;
    id?: number;
    lastRefreshedUtc?: Date;
    expiredAtUtc?: Date;
    revokedAtUtc?: Date;
}

export interface ActionstepContext {
    orgKey: string;
    orgName?: string;
    termsEverAccepted?: boolean;
    latestTermsAccepted?: boolean;
    matterContext?: ActionstepMatterContext;
    credentials?: ActionstepCredentials;
}

export interface CommonState {
    accountInfo?: AccountModel,
    accountInfoStatus: RequestStatus,
    matterInfoStatus: MatterRequestStatus,
    actionstepContext?: ActionstepContext,
    actionstepContextHasLoaded: boolean,
    fatalErrorMessages?: string[],
    requestType: string,
    gotResponse: boolean,
    success: boolean,
    uiDefinitions?: UISettings,
    isFirstTime: boolean,
    pexaConnected: boolean | null,
    firstTitleConnected: boolean | null,
    firstTitleMissingCredentials: boolean,
    fullPage: boolean,
    connectedOrgs?: ActionstepConnectionsResponse[],
    disableOrgAutoSelect: boolean,
    getUserAuditTrailDataResponse: GetUserAuditTrailDataResponse | undefined,
    saveUserAuditTrailDataResponse: boolean
}

const initialState: CommonState = {
    accountInfoStatus: RequestStatus.NotRequested,
    matterInfoStatus: MatterRequestStatus.NotRequested,
    actionstepContextHasLoaded: false,
    requestType: "",
    gotResponse: false,
    success: false,
    uiDefinitions: undefined,
    isFirstTime: true,      // isFirstTime for Actionstep
    pexaConnected: null,
    firstTitleConnected: null,
    firstTitleMissingCredentials: true,      // isFirstTime for First Title
    fullPage: false,
    disableOrgAutoSelect: false,
    getUserAuditTrailDataResponse: undefined,
    saveUserAuditTrailDataResponse: false
}

function commonReducer(state: CommonState = initialState, action: ICommonAction): CommonState {
    state = {
        ...state,
        requestType: action.type
    };

    switch (action.type) {
        case CommonEvents.GET_ACCOUNT_INFO_REQUESTED:
            return {
                ...state,
                accountInfoStatus: RequestStatus.Requested,
            };

        case CommonEvents.GET_ACCOUNT_INFO_SUCCESS:
            return {
                ...state,
                accountInfoStatus: RequestStatus.Succeeded,
                accountInfo: action.payload,
            };

        case CommonEvents.GET_ACCOUNT_INFO_FAILED:
            return {
                ...state,
                accountInfoStatus: RequestStatus.Failed,
                fatalErrorMessages: action.payload,
            };

        case CommonEvents.GET_MATTER_INFO_REQUESTED:
            return {
                ...state,
                matterInfoStatus: MatterRequestStatus.Requested,
            };

        case CommonEvents.GET_MATTER_INFO_SUCCESS:
            return {
                ...state,
                matterInfoStatus: MatterRequestStatus.Succeeded,
                actionstepContext: {
                    orgKey: action.payload.orgKey!,
                    orgName: action.payload.orgName,
                    termsEverAccepted: action.payload.termsEverAccepted,
                    latestTermsAccepted: action.payload.latestTermsAccepted,

                    credentials: {
                        verificationStatus: RequestStatus.Succeeded,
                    },

                    matterContext: {
                        id: action.payload.matterId,
                        name: action.payload.matterName,
                    }
                }
            };

        case CommonEvents.GET_MATTER_INFO_FAILED:
            return {
                ...state,
                matterInfoStatus: MatterRequestStatus.Failed,
                fatalErrorMessages: action.payload,
            };

        case CommonEvents.GET_MATTER_INFO_FAILED_NOT_FOUND:
            return {
                ...state,
                matterInfoStatus: MatterRequestStatus.NotFound,
            };

        case CommonEvents.MATTER_INFO_NOT_SUPPLIED:
            return {
                ...state,
                matterInfoStatus: MatterRequestStatus.NotSupplied,
            };

        case CommonEvents.SET_UI_DEFINITIONS:
            return {
                ...state,
                uiDefinitions: action.payload,
            };

        case CommonEvents.SET_ACTIONSTEP_CONTEXT:
            return {
                ...state,
                actionstepContext: action.payload ?? undefined,
                actionstepContextHasLoaded: true,
            };

        case CommonEvents.CLEAR_COMMON_STATE:
            return {
                ...state,
                accountInfoStatus: RequestStatus.NotRequested,
                matterInfoStatus: MatterRequestStatus.NotRequested,
                requestType: "",
                fatalErrorMessages: undefined,
            };

        case CommonEvents.ACTIONSTEP_UNAUTHORIZED_RESPONSE:
            return {
                ...state,
                actionstepContext: {
                    ...state.actionstepContext!,
                    credentials: {
                        verificationStatus: RequestStatus.Failed,
                    }
                },
            };

        case CommonEvents.MARK_ORG_VERIFIED:
            return {
                ...state,
                actionstepContext: {
                    ...state.actionstepContext!,
                    termsEverAccepted: action.payload.termsEverAccepted,
                    latestTermsAccepted: action.payload.latestTermsAccepted,
                    credentials: {
                        verificationStatus: RequestStatus.Succeeded,
                    },
                },
            };

        case CommonEvents.RESET_ORG_AND_MATTER_REQUEST_STATUS:
            return {
                ...state,
                matterInfoStatus: MatterRequestStatus.NotRequested,
                actionstepContext: {
                    ...state.actionstepContext!,
                    credentials: {
                        verificationStatus: RequestStatus.NotRequested,
                    },
                },
                actionstepContextHasLoaded: false,
            };

        case CommonEvents.PEXA_CONNECTED:
            return {
                ...state,
                pexaConnected: true,
            };

        case CommonEvents.PEXA_NOT_CONNECTED:
            return {
                ...state,
                pexaConnected: false,
            };

        case CommonEvents.FIRST_TITLE_CONNECTED:
            return {
                ...state,
                firstTitleConnected: true,
                firstTitleMissingCredentials: false,
            };

        case CommonEvents.FIRST_TITLE_NOT_CONNECTED:
            return {
                ...state,
                firstTitleConnected: false,
                firstTitleMissingCredentials: action.payload,
            };

        case CommonEvents.SET_FULL_PAGE:
            return {
                ...state,
                fullPage: action.payload,
            };

        case CommonEvents.SET_CURRENT_ORG:
            return {
                ...state,
                actionstepContext: {
                    orgKey: action.payload.actionstepOrgKey!,
                    orgName: action.payload.actionstepOrgTitle,
                    termsEverAccepted: action.payload.termsEverAccepted,
                    latestTermsAccepted: action.payload.latestTermsAccepted,
                    credentials: {
                        id: action.payload.actionstepCredentialId,
                        verificationStatus: RequestStatus.NotRequested,
                        lastRefreshedUtc: action.payload.lastRefreshedUtc,
                        expiredAtUtc: action.payload.expiredAtUtc,
                        revokedAtUtc: action.payload.revokedAtUtc,
                   },
                },
                disableOrgAutoSelect: false,
            };

        case CommonEvents.CLEAR_CURRENT_ORG:
            return {
                ...state,
                actionstepContext: undefined,
                disableOrgAutoSelect: true,
            };

        case CommonEvents.GET_USER_AUDIT_TRAIL_DATA_REQUESTED:
        case CommonEvents.SAVE_USER_AUDIT_TRAIL_DATA_REQUESTED:
            return {
                ...state,               
                gotResponse: false,
                success: false,
                requestType: action.type
            };

        case CommonEvents.GET_USER_AUDIT_TRAIL_DATA_FAILED:
        case CommonEvents.SAVE_USER_AUDIT_TRAIL_DATA_FAILED:
            return {
                ...state,
                gotResponse: false,
                success: false,
                requestType: ""
            };

        case CommonEvents.GET_USER_AUDIT_TRAIL_DATA_SUCCESS:
            return {
                ...state,               
                getUserAuditTrailDataResponse: action.payload,
                gotResponse: true,
                success: true
            };

       case CommonEvents.SAVE_USER_AUDIT_TRAIL_DATA_SUCCESS:
            return {
                ...state,
                saveUserAuditTrailDataResponse: action.payload,
                gotResponse: true,
                success: true
            };

        default:
            return state;
    }
}

export default commonReducer;