import * as CONSTANTS from './constants'
import {
    ErrorViewModel,
    ConnectedActionstepOrgsResponse,
    ECAFGetMatterDetailsFromActionstepQueryResponse,
    EnvelopeDetailsViewModel,
    ECAFEnvelopeStatus,
    CreateOrderResponse,
    ECAFOrderResult,
} from 'utils/wcaApiTypes';


import { CountryPhoneCodeViewModel, ECAFReportResponse }
    from 'utils/newWcaApiTypes';
import {
    ECafActionTypes,
    ActionFailed,
    GetMatterDetailsFromActionstepSuccess,
    GetActionstepOrgsSuccess,
    GetECAFCredentialsSuccess,

    GetEnvelopeStatusSuccess,
    GetValidECAFCredentialSuccess,
    CreateOrderSuccess,
    GetEnvelopeSummarySuccess,
    GetECAFOrderlistSuccess,
    GetECAFOrderStatusPageSuccess,
    EditEnvelopeCustomerSuccess,
    GetCountryPhoneCodesSuccess,
    DeleteEnvelopeSuccess,
    ResendCustomerNotificationSuccess,
    DeleteCustomerSuccess,
    GetReportDataSuccess
} from 'containers/ecaf/redux/actionTypes';


export interface ECafState {
    envelopeDetails: EnvelopeDetailsViewModel | undefined;
    message: string | undefined;
    status: ECAFEnvelopeStatus | undefined;
    isValidCredentials: boolean;
    gotResponse: boolean,
    success: boolean,
    error: ErrorViewModel | undefined,
    requestType: string,
    matterDetails: ECAFGetMatterDetailsFromActionstepQueryResponse | undefined
    actionstepOrgs: ConnectedActionstepOrgsResponse[] | undefined,
    eCAFConnected: boolean,
    orderResponse: CreateOrderResponse | undefined,
    orderList: ECAFOrderResult[] | undefined,
    statusPageUrl: string | undefined,
    editCustomerResponse: boolean,
    countryPhoneCodeList: CountryPhoneCodeViewModel[] | undefined,
    deleteEnvelopeStatus: boolean,
    deleteCustomerStatus: boolean,
    resendNotificationStatus: boolean | undefined,
    reportData: ECAFReportResponse | undefined
}

const initialState: ECafState = {
    envelopeDetails: undefined,
    message: "",
    status: undefined,
    isValidCredentials: false,
    gotResponse: false,
    success: false,
    error: undefined,
    requestType: "",
    matterDetails: undefined,
    actionstepOrgs: undefined,
    eCAFConnected: false,
    orderResponse: undefined,
    orderList: undefined,
    statusPageUrl: "",
    editCustomerResponse: false,
    countryPhoneCodeList: undefined,
    deleteEnvelopeStatus: false,
    deleteCustomerStatus: false,
    resendNotificationStatus: false,
    reportData: undefined
}


function eCafReducer(state: ECafState = initialState, action: ECafActionTypes): ECafState {

    switch (action.type) {
        case CONSTANTS.GET_MATTER_DETAILS_FROM_ACTIONSTEP_REQUESTED:
        case CONSTANTS.CREATE_ORDER_REQUESTED:
        case CONSTANTS.GET_ACTIONSTEP_ORGS_REQUESTED:
        case CONSTANTS.CHECK_ECAF_CREDENTIAL_REQUESTED:
        case CONSTANTS.UPDATE_VOI_STATUS_REQUESTED:
        case CONSTANTS.UPDATE_COUNTER_SIGN_STATUS_REQUESTED:
        case CONSTANTS.GET_VALID_ECAF_CREDENTIAL_REQUESTED:
        case CONSTANTS.GET_ECAF_ENVELOPE_STATUS_REQUESTED:
        case CONSTANTS.GET_ECAF_ORDER_STATUS_PAGE_REQUESTED:
        case CONSTANTS.EDIT_ENVELOPE_CUSTOMER_REQUESTED:
        case CONSTANTS.GET_COUNTRY_PHONE_CODES_REQUESTED:
        case CONSTANTS.RESEND_CUSTOMER_NOTIFICATION_REQUESTED:
        case CONSTANTS.GET_ECAF_ENVELOPE_SUMMARY_REQUESTED:
        case CONSTANTS.GET_REPORT_DATA_REQUESTED:
            return {
                ...state,
                requestType: action.type,
                gotResponse: false,
                success: false
            };
        case CONSTANTS.DELETE_ENVELOPE_REQUESTED:
            return {
                ...state,
                requestType: action.type,
                gotResponse: true
            };
        case CONSTANTS.DELETE_CUSTOMER_REQUESTED:
            return {
                ...state,
                requestType: action.type,
                gotResponse: true
            };
        case CONSTANTS.GET_MATTER_DETAILS_FROM_ACTIONSTEP_SUCCESS:
            return {
                ...state,
                gotResponse: true,
                success: true,
                matterDetails: (action as GetMatterDetailsFromActionstepSuccess).data
            };
        case CONSTANTS.GET_VALID_ECAF_CREDENTIAL_SUCCESS:
            return {
                ...state,
                requestType: action.type,
                gotResponse: true,
                success: true,
                eCAFConnected: (action as GetValidECAFCredentialSuccess).data
            };

        case CONSTANTS.CREATE_ORDER_SUCCESS:
            return {
                ...state,
                requestType: action.type,
                gotResponse: true,
                success: true,
                orderResponse: (action as CreateOrderSuccess).data
            };

        case CONSTANTS.CHECK_ECAF_CREDENTIAL_SUCCESS:
            return {
                ...state,
                requestType: action.type,
                gotResponse: true,
                success: true,
                isValidCredentials: (action as GetECAFCredentialsSuccess).data
            };
        case CONSTANTS.GET_ACTIONSTEP_ORGS_SUCCESS:
            return {
                ...state,
                gotResponse: true,
                success: true,
                actionstepOrgs: (action as GetActionstepOrgsSuccess).data
            };
        case CONSTANTS.GET_ECAF_ORDERLIST_SUCCESS:
            return {
                ...state,
                requestType: action.type,
                gotResponse: true,
                success: true,
                orderList: (action as GetECAFOrderlistSuccess).data
            };
        case CONSTANTS.EDIT_ENVELOPE_CUSTOMER_SUCCESS:
            return {
                ...state,
                requestType: action.type,
                gotResponse: true,
                success: true,
                editCustomerResponse: (action as EditEnvelopeCustomerSuccess).data
            };

        case CONSTANTS.GET_MATTER_DETAILS_FROM_ACTIONSTEP_FAILED:
        case CONSTANTS.CREATE_ORDER_FAILED:
        case CONSTANTS.GET_ACTIONSTEP_ORGS_FAILED:
        case CONSTANTS.GET_VALID_ECAF_CREDENTIAL_FAILED:
        case CONSTANTS.GET_ECAF_ENVELOPE_SUMMARY_FAILED:
        case CONSTANTS.GET_ECAF_ORDERLIST_FAILED:
            return {
                ...state,
                gotResponse: true,
                success: false,
                error: (action as ActionFailed).error
            };

        case CONSTANTS.CHECK_ECAF_CREDENTIAL_FAILED:
            return {
                ...state,
                requestType: action.type,
                gotResponse: true,
                success: false,
                error: (action as ActionFailed).error
            };
        case CONSTANTS.GET_MATTER_DETAILS_FROM_ACTIONSTEP_FAILED:
            return {
                ...state,
                requestType: action.type,
                gotResponse: true,
                success: false,
                error: (action as ActionFailed).error
            };
        case CONSTANTS.EDIT_ENVELOPE_CUSTOMER_FAILED:
            return {
                ...state,
                requestType: action.type,
                gotResponse: true,
                success: false,
                error: (action as ActionFailed).error
            };
        case CONSTANTS.GET_ECAF_ENVELOPE_STATUS_SUCCESS:
            return {
                ...state,
                requestType: action.type,
                gotResponse: true,
                envelopeDetails: (action as GetEnvelopeStatusSuccess).data.envelopeDetails,
                message: (action as GetEnvelopeStatusSuccess).data.message,
                status: (action as GetEnvelopeStatusSuccess).data.status,
            };
        case CONSTANTS.GET_ECAF_ENVELOPE_SUMMARY_SUCCESS:
            return {
                ...state,
                requestType: action.type,
                gotResponse: true,
                envelopeDetails: (action as GetEnvelopeSummarySuccess).data.envelopeDetails,
            };
        case CONSTANTS.GET_ECAF_ORDER_STATUS_PAGE_SUCCESS:
            return {
                ...state,
                requestType: action.type,
                gotResponse: true,
                statusPageUrl: (action as GetECAFOrderStatusPageSuccess).data
            };
        case CONSTANTS.GET_COUNTRY_PHONE_CODES_SUCCESS:
            return {
                ...state,
                requestType: action.type,
                gotResponse: true,
                countryPhoneCodeList: (action as GetCountryPhoneCodesSuccess).data
            };
        case CONSTANTS.DELETE_ENVELOPE_SUCCESS:
            return {
                ...state,
                requestType: action.type,
                gotResponse: true,
                deleteEnvelopeStatus: (action as DeleteEnvelopeSuccess).data
            };

        case CONSTANTS.DELETE_CUSTOMER_SUCCESS:
            return {
                ...state,
                requestType: action.type,
                gotResponse: true,
                deleteEnvelopeStatus: (action as DeleteCustomerSuccess).data
            };

        case CONSTANTS.DELETE_ENVELOPE_FAILED:
            return {
                ...state,
                requestType: action.type,
                gotResponse: true,
                success: false,
                error: (action as ActionFailed).error
            };
        case CONSTANTS.DELETE_CUSTOMER_FAILED:
            return {
                ...state,
                requestType: action.type,
                gotResponse: true,
                success: false,
                error: (action as ActionFailed).error
            };
        case CONSTANTS.RESEND_CUSTOMER_NOTIFICATION_SUCCESS:
            return {
                ...state,
                requestType: action.type,
                gotResponse: true,
                resendNotificationStatus: (action as ResendCustomerNotificationSuccess).data
            };

        case CONSTANTS.RESEND_CUSTOMER_NOTIFICATION_FAILED:
            return {
                ...state,
                requestType: action.type,
                gotResponse: true,
                success: false,
                error: (action as ActionFailed).error
            };
        case CONSTANTS.RESEND_CUSTOMER_NOTIFICATION_CLEAR_STATE:
            return {
                ...state,
                requestType: "",
                gotResponse: false,
                success: false,
                resendNotificationStatus: undefined
            };
        case CONSTANTS.GET_REPORT_DATA_SUCCESS:
            return {
                ...state,
                requestType: action.type,
                gotResponse: true,
                success: true,
                reportData: (action as GetReportDataSuccess).data
            };
        case CONSTANTS.GET_REPORT_DATA_FAILED:
            return {
                ...state,
                requestType: action.type,
                gotResponse: true,
                success: false,
                error: (action as ActionFailed).error
            };
        case CONSTANTS.GET_REPORT_DATA_CLEAR_STATE:
            return {
                ...state,
                requestType: action.type,
                gotResponse: false
            };

        default:
            return state;
    }
}


export default eCafReducer;