import * as React from 'react';
import { MessageBar, MessageBarType } from 'office-ui-fabric-react';
import { getRequest } from '../../../../../utils/request';
import Tools from '../../../../../utils/tools';
import RetrieveFromActionstep from 'containers/pexa/steps/retrieveFromActionstep';
import * as IntegrationLink from "../../../integrationLinksConstants";
import RetrieveWorkspaceFromPexa from 'containers/pexa/steps/showWorkspaceSummary/retrieveWorkspaceFromPexa';
import PexaKeyInvite from './PexaKeyInvite';

import {
    SubwayNavNodeState,
    ISubwayNavNodeProps,
    setSubwayState
} from 'components/SubwayNav'

interface IAppProps {
}

type AppProps = IAppProps;
export default class PexaKeyInviteForm extends React.Component<AppProps, any> {
    steps: ISubwayNavNodeProps[] = [];
    constructor(props: AppProps) {
        super(props);

        this.steps = [
            {
                id: '0',
                label: 'Retrieve from Actionstep',
                state: SubwayNavNodeState.Current,
                onClickStep: this._handleClickStep,
                disabled: true
            },
          {
                id: '1',
                label: 'Retrieve Workspace From Pexa',
                state: SubwayNavNodeState.NotStarted,
                onClickStep: this._handleClickStep,
                disabled: true
            },
            {
                id: '2',
                label: 'Pexa Key Invite',
                state: SubwayNavNodeState.NotStarted,
                onClickStep: this._handleClickStep,
                disabled: true
            }
        ];

        this.state = {
            steps: this.steps,
            currentStepId: 0,
            pexaEnabled: false, // Checks if PEXA is enabled
            integrationLink: IntegrationLink.Invite_Pexa_Key
        };
    }
    private async checkPEXAIsConnected(): Promise<void> {
        var pexaStatusFromBackend = (await getRequest(`/api/conveyancing/check-pexa-is-connected`)) as any;

        if (!pexaStatusFromBackend) {
            Tools.PopupConnectToPexa(async () => {
                pexaStatusFromBackend = (await getRequest(`/api/conveyancing/check-pexa-is-connected`)) as any;

                if (!pexaStatusFromBackend) {

                } else {
                    this.setState({
                        pexaEnabled: true
                    })
                }
            })
        } else {
            this.setState({
                pexaEnabled: true
            })
        }
    }

    public async componentDidMount(): Promise<void> {
        await this.checkPEXAIsConnected();
    }

    private _handleClickStep = (step: ISubwayNavNodeProps): void => {
        this.setState({ ...setSubwayState(step, this.state.steps, this.state.currentStepId) });
    }
    
    private _onChangeStep = (newState: SubwayNavNodeState, newStep: number = -1): void => {
        let newSteps = [...this.state.steps];
        let currentStepId = this.state.currentStepId;

        if (newStep >= 0) {
            currentStepId = newStep;
        }
        for (let i = 0; i < currentStepId; i++) {
            newSteps[i].state = SubwayNavNodeState.Completed;
        }
        for (let i = currentStepId + 1; i < newSteps.length; i++) {
            newSteps[i].state = SubwayNavNodeState.NotStarted;
        }

        newSteps[currentStepId].state = newState;
        if (newState === SubwayNavNodeState.Completed && currentStepId < newSteps.length - 1) {
            currentStepId++;
            newSteps[currentStepId].state = SubwayNavNodeState.Current;
        }

        this.setState({
            steps: newSteps,
            currentStepId: currentStepId
        })

        window.scrollTo(0, 0);
    }
    public render() {

        const { currentStepId, pexaEnabled } = this.state;
        return (
                <div className="ms-Grid-row create-pexa-workspace">
                    {pexaEnabled ?
                        <>

                            <div className="create-pexa-content ms-Grid-col ms-lg9">
                                {currentStepId === 0 && <RetrieveFromActionstep integrationLink={this.state.integrationLink} onChangeStep={this._onChangeStep} />}
                                {currentStepId === 1 && <RetrieveWorkspaceFromPexa onChangeStep={this._onChangeStep} />}
                                {currentStepId === 2 && <PexaKeyInvite onChangeStep={this._onChangeStep} />}
                            </div>
                        </>
                        : <div>
                            <MessageBar messageBarType={MessageBarType.error}>
                                PEXA is not connected <span style={{ cursor: 'pointer' }} onClick={() => { this.checkPEXAIsConnected() }}><i><u>click here</u></i> to connect.</span>
                            </MessageBar>
                        </div>}
                </div>           
        );
    }
}


